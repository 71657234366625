import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'uni',
    templateUrl: './uni.component.html',
    styleUrls: ['./uni.component.scss'],
})
export class UniComponent implements OnInit, OnDestroy {

    constructor(public dialogRef: MatDialogRef<UniComponent>) {
        dialogRef.disableClose = true;
    }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

}
