import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CellClickedEvent, ColumnApi, GridOptions, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { Observable, Subscription } from 'rxjs';

import { LoginService } from '../../access';
import { AssetDisplayType, BiblioPeriodical, BiblioType, SourceDetail } from '../../models';
import { BibliothekService } from '../bibliothek.service';

@Component({
    selector: 'slx-journal-bibliothek',
    template: `
        <slx-grid
            [options] = "gridOptions"
            [columnDefs] = "columnDefs"
            [rowTranslatePrefix]="'biblio'"
            [getRowHeight]="bibliothekService.getRowHeight"
            [getRowClass]="bibliothekService.getRowClass"
            [rowData]="journalList"
            [isBiblio]="true"
            (cellClicked)="onCellClicked($event)">
        </slx-grid>
        `,
    styleUrls: ['./journalBibliothek.component.scss'],
})
export class JournalBibliothekComponent implements OnInit, OnDestroy {
    public gridOptions: GridOptions;
    public gridColumnApi: ColumnApi;
    public columnDefs;
    public journalList: Observable<BiblioPeriodical[]>;

    private cacheClearedSubscribtion: Subscription;
    private subscription: Subscription = new Subscription();

    constructor(private translate: TranslateService, public loginService: LoginService, public bibliothekService: BibliothekService) {
        this.onCellClicked = this.onCellClicked.bind(this);
        this.onGridReady = this.onGridReady.bind(this);


        this.gridOptions = <GridOptions>{
            headerHeight: 50,
            cacheQuickFilter: true,
            context: { translate: this.translate, loginService: this.loginService },
        };
    }

    ngOnInit(): void {
        this.columnDefs = [
            { headerName: 'Logo', field: 'logoUrl', suppressSorting: true, minWidth: 100, maxWidth: 100, cellRenderer: this.bibliothekService.logosCellRenderer, suppressMenu: true },
            { headerName: 'Abbrevitation', field: 'abbreviation', sort: 'asc', minWidth: 200, maxWidth: 200, suppressMenu: true },
            {
                headerName: 'Title', field: 'titles', minWidth: 200, cellRenderer: this.bibliothekService.createTitleCellRenderer(SourceDetail.JorunalBiblio),
                suppressMenu: true, getQuickFilterText: this.bibliothekService.getTitleFilterValue, comparator: this.bibliothekService.titleComperator,
            },
            { headerName: 'publisher', field: 'publisher', minWidth: 200, maxWidth: 600, suppressMenu: true, cellRenderer: this.publisherCellRenderer },
            { headerName: 'subscribe', field: 'subscribe', menuTabs: [], cellRenderer: this.newsletterButtonRenderer, minWidth: 200, maxWidth: 200, hide: !this.loginService.isPremium },
            { headerName: 'Since year', field: 'sinceYear', minWidth: 50, maxWidth: 130, suppressMenu: true },
            { headerName: 'Until year', field: 'untilYear', minWidth: 50, maxWidth: 130, suppressMenu: true },
        ];

        this.journalList = this.bibliothekService.getPeriodical(BiblioType.Journals);
        this.cacheClearedSubscribtion = this.bibliothekService.cacheCleared.subscribe(cacheCleared => this.journalList = this.bibliothekService.getPeriodical(BiblioType.Journals));
    }

    public onGridReady(params: GridReadyEvent) {
        this.gridColumnApi = params.columnApi;
        this.subscription = this.loginService.isPremium.subscribe(isPremium =>
            this.gridColumnApi.setColumnVisible('subscribe', isPremium)
        );
    }

    ngOnDestroy() {
        this.cacheClearedSubscribtion.unsubscribe();
    }

    //-------------------------------------------------------------------------------------
    // Formatters, Comperators, CellRenderers Header Translations
    //-------------------------------------------------------------------------------------

    private publisherCellRenderer(params): HTMLElement {
        const title = document.createElement('span');
        title.style.cssText =
            'text-overflow: ellipsis; overflow: hidden; padding-right: 1rem;';
        title.innerHTML = title.title = params.value;
        return title;
    }

    //-------------------------------------------------------------------------------------
    // Events & Listeners
    //-------------------------------------------------------------------------------------

    public onCellClicked($event: CellClickedEvent) {
        const mouseEvent = $event.event as MouseEvent;
        if ($event.colDef.field === 'subscribe' && !$event.data['isUnavailable']) {
            this.bibliothekService.createNewsAbo($event.data.titles[0].key);
        }
        else if (!mouseEvent || !mouseEvent.ctrlKey) {
            if ($event.data.titles.length + ($event.data.titles[0].value.additionalLinks ? $event.data.titles[0].value.additionalLinks.length : 0) === 1) {
                this.bibliothekService.openToc($event.data.titles[0].key, AssetDisplayType.PeriodicalCollection, SourceDetail.JorunalBiblio);
            }
        }
    }

    newsletterButtonRenderer(params: ICellRendererParams): HTMLElement {
        if (params.data['isUnavailable']) return;

        const button = document.createElement('button');
        const translate = params.colDef['field'];
        const text = params.context.translate.instant(`biblio-${translate}`);

        button.innerHTML = `<i class="material-icons">input</i><span>${text}</span>`;
        button.classList.add('slx-icn-btn', 'inside-grid', 'small-icon');
        return button;
    }
}
