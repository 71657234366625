import { Directive, HostListener, Input, OnDestroy } from '@angular/core';

import { AssetDisplayType, AssetRef, AssetRefOptions, SourceDetail } from '../../models';
import { isResponsive } from '../../utility/utilityFunctions';
import { AssetService } from '../asset.service';
import { AssetActions } from '../assetActions';

const debug = require('debug')('docview');

/*  This Directive Handles ClickEvents in the ContentWrapper.
    Body is locked once search back button is used, a click on the hitlist / filters / documentView
    relases lock and collapses search again  */

@Directive({
    selector: '[slx-docview-link]',
})

export class DocviewLinkDirective implements OnDestroy {

    @Input() isPrimary: boolean;

    constructor(private assetService: AssetService) { }

    ngOnDestroy() { }


    openAsset(assetRef: AssetRef, event: MouseEvent) {
        debug('DocLink opening', assetRef);
        this.assetService.openAsset(assetRef, !this.isPrimary, event);
    }

    @HostListener('click', ['$event', '$event.target']) onClick(event, target) {
        if (target.localName === 'img') {
            return;
        }

        if (!event.ctrlKey) {
            event.preventDefault();
        }

        // debug('Clicked', event, target);

        // ##### Find Closest a if there is one #####
        if (target.localName !== 'a') {
            const closestA = target.closest('a');
            if (closestA) {
                target = closestA;
                debug('clicked inside an a => use', target);
            }
        }


        // ##### Open External Links #####
        if (target.localName === 'a' && target.className === 'noSpTransmission') {
            window.open(target.href, '_blank');
        }

        // ##### Links for attachments ####
        if (target.localName === 'a' && (target.href as string).indexOf('attachmentGuid') > -1) {
            const url = target.href.substr(target.href.indexOf('api/'));
            this.assetService.dispatch({ fetch: AssetActions.save_doc_attachment.name, payload: { url, description: target.title } });
        }

        // ##### Actions for Links (Citings) #####

        if (target.localName === 'a' && (target.classList.contains('prompt-document-link') || target.classList.contains('prompt-document-link-eu'))) {

            if (target.classList.contains('link-multiple') || target.classList.contains('link-ambiguous')) {
                debug('is multilink');
            }
            else {
                debug('DocLink', target.pathname);
                const splittedInfo = target.pathname.split('/');
                splittedInfo.splice(0, 2);
                debug('DocLink type', splittedInfo[0]);
                let ref = [];
                switch (splittedInfo[0]) {
                    case AssetDisplayType.ArticleOfLawDocument:
                        ref = [...splittedInfo.splice(0, 2), AssetDisplayType.LawDocument, splittedInfo[0], AssetRefOptions.sourceDetail, SourceDetail.DocumentLink];
                        break;
                    default:
                        ref = [...splittedInfo, AssetRefOptions.sourceDetail, SourceDetail.DocumentLink];
                        break;
                }
                this.openAsset(ref, event);
                this.assetService.cancelAssetPreview();
            }
        }



        // ##### Actions for Links / FootnoteCalls #####

        if (target.localName === 'a' && target.name.includes('footnotecall')) {
            const footnoteCallDown: HTMLElement = target.closest('slx-docview').querySelector(`.footnotes a[href='#${target.name}']`);
            footnoteCallDown.scrollIntoView({ behavior: 'smooth' });
            footnoteCallDown.focus();
        }
        else if (target.localName === 'a' && target.name.includes('fn_')) {
            const footnoteCallUp: HTMLElement = target.closest('slx-docview').querySelector(`a[href='#${target.name}']`);
            footnoteCallUp.scrollIntoView({ behavior: 'smooth' });
            footnoteCallUp.focus();
        }

        if (target.href && target.href.indexOf('#doc_top') > 0) {
            if (isResponsive()) {
                target.closest('.split-view').scrollIntoView({ behavior: 'smooth' });
                return;
            }
            target.closest('slx-docview').scroll({ top: 0, left: 0, behavior: 'smooth' });
        }

        // ##### Actions for MultilanguageGroups #####

        const mlGroup = target.closest('.multilanguagegroup');

        if (mlGroup) {
            switch (target.className) {
                case 'languageLink_de':
                    switchLanguage('only-de');
                    break;
                case 'languageLink_fr':
                    switchLanguage('only-fr');
                    break;
                case 'languageLink_it':
                    switchLanguage('only-it');
                    break;
                case 'languageLink_en':
                    switchLanguage('only-en');
                    break;
                case 'languageLink_ShowAll':
                    switchLanguage('show-all');
                    break;
            }
        }

        function switchLanguage(onlyClass) {
            //IE11 fix, classList.remove() respects only first parameter
            ['only-de', 'only-fr', 'only-it', 'only-en', 'show-all'].forEach(x => {
                mlGroup.classList.remove(x);
            });
            const languageLinks = mlGroup.querySelectorAll('.languageLink_de, .languageLink_fr, .languageLink_it, .languageLink_en, .languageLink_ShowAll');
            for (let index = 0; index < languageLinks.length; index++) {
                const element = languageLinks[index];
                element.classList.remove('active');
            }

            mlGroup.classList.add('userSelection');
            mlGroup.classList.add(onlyClass);
            target.classList.add('active');
        }
    }


}
