module.exports = {
	"jobs-nzzcoorporation": "Dieses Angebot erfolgt in Kooperation mit der NZZ Mediengruppe",
	"jobs-advertise": "Jetzt inserieren",
	"jobs-advertise-full": "Jetzt direkt über Swisslex inserieren",
	"jobs-backButton": "Zurück",
	"jobs-enter": "Enter",
	"jobs-search": "Jobs durchsuchen",
	"jobs-current": "Aktuelle Jobs",
	"jobs-single": "Details",
	"jobs-current-filter": "Text eingeben, um zu filtern",
	"jobs-grid-publishDate": "Datum",
	"jobs-grid-employerName": "Arbeitgeber",
	"jobs-grid-title": "Titel",
	"jobs-grid-workplace": "Arbeitsort",
	"jobs-grid-no-entries": "Keine Einträge vorhanden",
	"jobs-grid-loading": "Inhalte werden geladen",
	"jobs-employer": "Arbeitgeber",
	"jobs-workplace": "Arbeitsort",
	"jobs-publishDate": "Publikationsdatum",
	"jobs-links": "Externe Links",
	"jobs-conditions": "Anforderungen",
	"jobs-aboutEmployer": "Über den Arbeitgeber",
	"jobs-contact": "Kontakt",
	"jobs-application-link": "Bewerbungslink",
	"jobs-more-information": "Weitere Informationen",
	"jobs-details-back-to-overview": "Zurück zu den aktuellen Jobs",
	"jobs-search-title": "Suchen",
	"jobs-hits": "Treffer",
	"jobs-single-job-not-found": "Der gewünschte Job wurde nicht gefunden",
	"jobs-undefined-error": "Unidentifizierbarer Fehler! Wenden Sie bitte sich an unsere Hotline: 0800 587 463   /   +41 (0) 44 365 32 32"
};