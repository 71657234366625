import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import { getThesaurusInformationUrl, SlxHttp } from '../access';
import { catchErrorForAction, LanguageEnum, makeAction, SlxAction } from '../models';
import { AlertType } from '../models/state/commonApp';

import { FieldService } from './field.service';
import { ImmerFieldActions } from './fieldActions';

@Injectable()
export class FieldEffects {

    @Effect() loadThesaurusInformation = this.actions$.pipe(
        ofType(ImmerFieldActions.load_thesaurus_information.name),
        withLatestFrom(this.fieldService.store.select('account') as Observable<any>),
        switchMap(([action, state]) => {
            const payload = (action as SlxAction).payload;

            if (payload.searchText === '' || payload.searchText === null) {
                return of(makeAction(makeAction({ result: ImmerFieldActions.load_thesaurus_information.name })));
            }

            return this.slxHttp.get(`${getThesaurusInformationUrl}${encodeURIComponent(payload.searchText)}&language=${payload.searchLang}`).pipe(
                map(response => makeAction({ result: ImmerFieldActions.load_thesaurus_information.name, payload: response })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' })
            );
        })
    );

    constructor(
        private slxHttp: SlxHttp,
        private actions$: Actions,
        private fieldService: FieldService
    ) {
    }
}
