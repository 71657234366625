import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material';
import { of } from 'rxjs';

import { DocTocNode } from '../../../models';

@Component({
    selector: 'slx-doc-toc',
    templateUrl: './doc-toc.component.html',
    styleUrls: ['./doc-toc.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocTocComponent implements OnInit{
    @Input() assetID: string;
    @Input() docToc: DocTocNode[];
    @Input() isPrimary: boolean;
    @Input() title: string;

    public treeControl: NestedTreeControl<DocTocNode>;
    public treeDataSource: MatTreeNestedDataSource<DocTocNode>;

    public getChildren = (_nodeData: DocTocNode) => { return of(_nodeData.children); };
    public hasChild = (_:number, _nodeData: DocTocNode) => {
        return _nodeData && _nodeData.children && _nodeData.children.length > 0;
    }

    constructor() {
        this.treeControl = new NestedTreeControl<DocTocNode>(this.getChildren);
        this.treeDataSource = new MatTreeNestedDataSource();
    }

    ngOnInit(): void {
        this.treeDataSource.data = this.docToc;
    }
}
