import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AccountService } from '../../../../account';

declare let paypal: any;

@Component({
    selector: 'slx-rak-paypal',
    templateUrl: './rak-paypal.component.html',
    styleUrls: ['./rak-paypal.component.scss'],
})
export class RakPaypalComponent implements OnInit, OnDestroy {

    public rakPaypalForm: FormGroup;
    public rakPaypalSubscription: Subscription;
    public rakAboDurationMonth: any;
    public monthlyCosts: any;
    public amount: any;
    public vat: any;
    public total: any;
    private aboDurationText: any; //Dauer des Abos
    private paymentData: any;
    private portalUserLanguage: any;

    addScript = false;
    paypalLoad = true;

    constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private _location: Location, private accountService: AccountService, private translate: TranslateService, private router: Router) { }

    //only paypal client
    paypalConfig = {
        env: 'production',
        locale: 'de_DE',
        // Specify the style of the button
        style: {
            label: 'paypal',
            size: 'medium',    // small | medium | large | responsive
            shape: 'rect',     // pill | rect
            color: 'blue',     // gold | blue | silver | black
            tagline: false,
        },
        client: {
            sandbox: 'AZngNBReHqzTtrKQopSZvAKAIPOmhGzYk6mrFz3Mo_TmW6peUkir1Db5IP8cmXBBylTOmFP-M4PhFPaU',
            production: 'AdLqCfDTKaWx1wR174ymUzg61rz1LcH7HW80tDXlJcq6jObFij_Mnwvyf-s5HFyQ3UiMvk8wSAvQ-FiW',
        },
        commit: true,
        payment: (data, actions) => {
            return actions.payment.create({
                payment: {
                    application_context: {
                        locale: this.portalUserLanguage,
                        brand_name: 'Swisslex AG',
                        shipping_preference: 'NO_SHIPPING',
                    },
                    transactions: [
                        {
                            amount:
                            {
                                currency: 'CHF',
                                total: this.total,
                                details: {
                                    shipping: '0.00',
                                    tax: this.vat,
                                    subtotal: this.amount,
                                },
                            },
                            item_list:
                            {
                                items: [
                                    {
                                        'quantity': '1',
                                        'name': this.translate.instant('service-reg-business-abo'),
                                        'price': this.amount,
                                        'currency': 'CHF',
                                        'description': this.translate.instant('service-reg-rak-paypal-duration') + ' ' + this.aboDurationText,
                                        'tax': this.vat,
                                    }],
                            },
                            custom: this.paymentData,
                            //notify_url: 'http://a34eea38.ngrok.io/api/AboServices/Ipn',
                            notify_url: 'https://www.swisslex.ch/api/AboServices/Ipn',
                        },
                    ],
                },
            });
        },
        onAuthorize: (data, actions) => {
            return actions.payment.execute().then((payment) => {
                //Do something when payment is successful.
                this.router.navigate(['service/lawexamcandidates/rak-subscription'], { queryParams: { paypal: 'successful' } });
            }).catch(function (err) {
                //Do something when payment is unsuccessful.
                this.router.navigate(['service/lawexamcandidates/rak-subscription'], { queryParams: { paypal: 'unsuccessful' } });
            });
        },
        onError: function (err) {
            //Do something when payment is unsuccessful.
            this.router.navigate(['service/lawexamcandidates/rak-subscription'], { queryParams: { paypal: 'unsuccessful' } });
        },
    };
    ngAfterViewChecked(): void {
        if (!this.addScript) {
            this.addPaypalScript().then(() => {
                paypal.Button.render(
                    this.paypalConfig,
                    '#paypal-checkout-btn'
                );
                this.paypalLoad = false;
            });
        }
    }

    addPaypalScript() {
        this.addScript = true;
        return new Promise((resolve, reject) => {
            const scripttagElement = document.createElement('script');
            scripttagElement.src = 'https://www.paypalobjects.com/api/checkout.js';
            scripttagElement.onload = resolve;
            document.body.appendChild(scripttagElement);
        });
    }

    ngOnInit() {

        this.rakPaypalSubscription = this.accountService.store.select(state => state.aboService.activateRakUserData).subscribe(data => {
            if (data) {
                this.rakAboDurationMonth = data.rakAboDurationMonth;
                this.monthlyCosts = data.monthlyCosts;
                this.amount = data.amount;
                this.vat = data.vat;
                this.total = data.total;
                this.aboDurationText = data.aboDurationText;
                this.paymentData = data.paymentData;
                this.portalUserLanguage = data.portalUser.language;

                if (this.accountService.lang === 'fr') {
                    this.paypalConfig.locale = 'fr_FR';
                } else {
                    this.paypalConfig.locale = 'de_DE';
                }
            } else {
                this.router.navigate(['service/lawexamcandidates/rak-subscription']);
            }
        });
    }

    ngOnDestroy() {
        if (this.rakPaypalSubscription) {
            this.rakPaypalSubscription.unsubscribe();
        }
    }
}
