import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatTreeModule,
} from '@angular/material';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AccountService } from '../account';
import { FieldModule } from '../field';


import { AssetTabsDragDirective } from './asset-tabs-drag.directive';
import { AssetTabsComponent } from './asset-tabs.component';
// import { AssetComponent } from './asset.component';
import { CanHaveCitationsPipe, IsChDocumentPipe, IsCommentaryDocPipe, IsDocumentPipe, IsEuDocumentPipe, IsLawDocPipe,
     IsPreviewPipe, IsTocPipe, LogoFromFavoritePipe, LogoFromTransactionPipe, RefFromFavoritePipe, RefFromTransactionPipe,
     ShortenMetadataNamePipe, TooltipDataForAssetListEntryPipe, TooltipDataForAssetPipe } from './asset.pipes';
import { AssetService } from './asset.service';
import { AssetEffects } from './assetEffects';
import { AutocompleteService } from './autocomplete.service';
import { AddCourtComponent } from './docview-tools/add-court/add-court.component';
import { AssetListComponent } from './docview-tools/assetList/asset-list.component';
import { DocviewToolsComponent } from './docview-tools/docview-tools.component';
import { CanShowTocPipe, GetFollowingAssetRefPipe, GetPrecedingAssetRefPipe, IsActivePipe, ShowAddCourtPipe  } from './docview-tools/docview-tools.pipes';
import { AssetAttachmentComponent } from './docview/asset-attachment/asset-attachment.component';
import { AssetCitingComponent } from './docview/assetCiting/assetciting.component';
import { DocTocComponent } from './docview/DocToc/doc-toc.component';
import { DocTocEntryComponent } from './docview/DocToc/DocTocEntry/doc-toc-entry.component';
import { DocviewLinkDirective } from './docview/docview-link.directive';
import { DocviewComponent } from './docview/docview.component';
import { DocViewContentComponent } from './docview/DocViewContent/docview-content.component';
import { RelatedAssetComponent } from './docview/relatedAssets/relatedAssets.component';
import { LinkPreviewDirective } from './link-preview.directive';
import { LinkPreviewComponent } from './link-preview/link-preview.component';
import { PreviewAssetsComponent } from './previewAssets/previewAssets.component';
import { SimpleTabsComponent } from './simple-tabs/simple-tabs.component';
import { TextSelectionComponent } from './textSelection/text-selection.component';
import { TextSelectionDirective } from './textSelection/text-selection.directive';
import { ToCItemToRefPipe, TocTreeContentComponent } from './toc-content/toc-tree-content.component';
import { TocviewComponent } from './tocview/tocview.component';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        EffectsModule.forFeature([AssetEffects]),
        FieldModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatTabsModule,
        MatTreeModule,
        MatIconModule,
    ],
    exports: [
        AssetCitingComponent,
        RelatedAssetComponent,
        AssetListComponent,
        // AssetComponent,
        DocviewComponent,
        DocviewToolsComponent,
        PreviewAssetsComponent,
        DocViewContentComponent,
        LinkPreviewComponent,
        TocviewComponent,
        TocTreeContentComponent,
        AssetTabsComponent,
        DocviewLinkDirective,
        LinkPreviewDirective,
        RefFromTransactionPipe,
        IsTocPipe,
        LogoFromTransactionPipe,
        LogoFromFavoritePipe,
        RefFromFavoritePipe,
        TextSelectionComponent,
        TextSelectionDirective,
        TooltipDataForAssetPipe,
        TooltipDataForAssetListEntryPipe,
    ],
    declarations: [
        AssetListComponent,
        AssetCitingComponent,
        RelatedAssetComponent,
        // AssetComponent,
        DocviewComponent,
        AddCourtComponent,
        PreviewAssetsComponent,
        DocViewContentComponent,
        LinkPreviewComponent,
        TocviewComponent,
        DocviewLinkDirective,
        AssetTabsDragDirective,
        LinkPreviewDirective,
        AssetTabsComponent,
        SimpleTabsComponent,
        TocTreeContentComponent,
        DocviewToolsComponent,
        DocTocComponent,
        DocTocEntryComponent,
        AssetAttachmentComponent,
        GetPrecedingAssetRefPipe,
        GetFollowingAssetRefPipe,
        RefFromTransactionPipe,
        TooltipDataForAssetPipe,
        TooltipDataForAssetListEntryPipe,
        IsDocumentPipe,
        IsLawDocPipe,
        IsEuDocumentPipe,
        IsChDocumentPipe,
        IsCommentaryDocPipe,
        IsTocPipe,
        IsPreviewPipe,
        CanShowTocPipe,
        IsActivePipe,
        ShowAddCourtPipe,
        ShortenMetadataNamePipe,
        CanHaveCitationsPipe,
        ToCItemToRefPipe,
        LogoFromTransactionPipe,
        LogoFromFavoritePipe,
        RefFromFavoritePipe,
        TextSelectionComponent,
        TextSelectionDirective,
    ],
    providers: [ AssetService, TranslateService, AutocompleteService ],
})
export class AssetModule {
    constructor(private account: AccountService) {
        this.account.addTranslation('rech-asset-',
            require('./rech-asset.de.yaml'),
            require('./rech-asset.fr.yaml')
        );
    }
}
