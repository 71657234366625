import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AccountService } from '../../../account';
import {
    checkboxRequiredValidator, combinedQualityValidator, emailValidator, equalsOtherValidator,
    getValidationMessage, minLengthValidator, numberValidator,
    phoneFaxNumberValidator, requiredValidator, uppercaseValidator, zipCodeValidator
} from '../../../field';
import { BusinessFormElements, SelectListItem } from '../../../models';
import { markFormAsTouched } from '../../../utility/utilityFunctions';
import { AboRegistrationService } from '../abo-services.service';
import { AboServicesActions } from '../abo-servicesActions';

import { RegistrationBusinessDialogComponent } from './registration-business-dialog/registration-business-dialog.component';

@Component({
    selector: 'slx-registration-business',
    templateUrl: './registration-business.component.html',
    styleUrls: ['./registration-business.component.scss'],
})
export class RegistrationBusinessComponent implements OnInit, OnDestroy, BusinessFormElements {
    addressTerms: SelectListItem[];
    addressTitles: SelectListItem[];
    language: string;
    languages: SelectListItem[];
    aboTypes: SelectListItem[];
    countries: SelectListItem[];
    customerTypes: SelectListItem[];
    public form: FormGroup;
    public businessRegistrationSubscription: Subscription;
    public getValidationMessage: (formControl: any, translate: any) => string = getValidationMessage;

    constructor(public aboRegistrationService: AboRegistrationService, private fb: FormBuilder, private accountService: AccountService, private dialog: MatDialog, public translate: TranslateService) { }
    ngOnInit() {
        this.form = this.fb.group({
            customerType: ['1'],
            aboTypes: [''],
            countries: [''],
            addressTerms: [''],
            addressTitles: [''],
            addressSuffix: [''],
            companyName: ['', requiredValidator()],
            postBox: [''],
            street: ['', requiredValidator()],
            zipCode: ['', [requiredValidator(), zipCodeValidator('service-reg-comp-zip-notvalid-val-txt')]],
            city: ['', requiredValidator()],
            country: [''],
            phone: ['', [requiredValidator(), phoneFaxNumberValidator('service-reg-comp-phone-notvalid-val-txt')]],
            fax: ['', phoneFaxNumberValidator('service-reg-comp-fax-notvalid-val-txt')],
            homepage: [''],
            numberOfFlawyers: ['', [requiredValidator(), numberValidator('service-reg-comp-nol-notvalid-val-txt')]],
            addressTerm: [''],
            title: [''],
            firstName: ['', requiredValidator()],
            name: ['', requiredValidator()],
            email: ['', [requiredValidator(), emailValidator('service-reg-userid-notvalid-val-txt')]],
            language: [''],
            password: ['', [
                requiredValidator(),
                combinedQualityValidator(
                    minLengthValidator('service-reg-pwd-min', 6),
                    uppercaseValidator('service-reg-pwd-uppercase', 1)),
            ]],
            confirmPassword: ['', [
                requiredValidator(),
                equalsOtherValidator('service-reg-pwd-equal', 'password')]],
            LanguageEnum: [''],
            aboTyp: [['Standard', 'Premium', 'EU']],
            hasCorporateNewsletter: [''],
            agb: ['', checkboxRequiredValidator('service-reg-agb-notvalid-val-txt')],
        }
        );

        this.businessRegistrationSubscription = this.aboRegistrationService.registerBusinessFormElements.subscribe((data: BusinessFormElements) => {
            if (data) {
                Object.assign(this, data);
                this.setDefaultFormValues();
            }
        })
        .add(this.aboRegistrationService.badEmail.subscribe(badEmail => {
            if (badEmail) {
                this.dialog.open(RegistrationBusinessDialogComponent, {
                    width: '1000px', height: '300px', panelClass: 'publication-select',
                    data: {
                        optionBusinessFn: (function(){this.onSubmit(this.form,false);}).bind(this),
                    },
                });
            }
        }));
    }

    get selectedCustomerType() {
        return this.form.get('customerType');
    }

    private setDefaultFormValues(currentCustomer?: string): void {
        this.form.controls['country'].setValue('CH');
        this.form.controls['aboTyp'].setValue(['Standard', 'Premium', 'EU']);
        this.form.controls['title'].setValue('-');
        this.form.controls['agb'].setValue(null);
        this.form.controls['hasCorporateNewsletter'].setValue(true);

        if (this.accountService.lang === 'de') {
            this.form.controls['addressTerm'].setValue('Herr');
            this.form.controls['language'].setValue('DE');
        } else {
            this.form.controls['addressTerm'].setValue('Monsieur');
            this.form.controls['language'].setValue('FR');
        }

        if (currentCustomer) {
            this.form.controls['customerType'].setValue(currentCustomer);
        } else {
            this.form.controls['customerType'].setValue('1');
        }
    }

    public resetForm(currentCustomer?: string) {
        const currentC = this.selectedCustomerType.value;

        this.form.reset();

        Object.keys(this.form.controls).forEach(
            (key) => {
                // Set errors to null to delete all errors/hints, then let control update itself (check for required etc.)
                this.form.controls[key].setErrors(null);
                this.form.controls[key].updateValueAndValidity();
            });

        if (currentCustomer) {
            this.setDefaultFormValues(currentCustomer);
        } else {
            this.setDefaultFormValues(currentC);
        }
    }

    public changeCustomerType(event) {
        const currentCustomer = event.value;
        this.resetForm(currentCustomer);

        if (event.value === '2') { // Single Customer
            this.form.controls['numberOfFlawyers'].setValidators(null);
            this.form.controls['numberOfFlawyers'].updateValueAndValidity();
            this.form.controls['companyName'].setValidators(null);
            this.form.controls['companyName'].updateValueAndValidity();
        } else {
            this.form.controls['numberOfFlawyers'].setValidators([
                requiredValidator('form-validation-required'),
                numberValidator('service-reg-comp-nol-notvalid-val-txt'),
            ]);
            this.form.controls['numberOfFlawyers'].updateValueAndValidity();
            this.form.controls['companyName'].setValidators([requiredValidator('form-validation-required')]);
            this.form.controls['companyName'].updateValueAndValidity();
        }
    }

    onSubmit(form, check) {
        if (!form.valid) {
             markFormAsTouched(form);
             return;
        }


        let hasPremiumAbo = false;
        let hasEuAbo = false;
        if (form.value.aboTyp.indexOf('Premium') >= 0) {
            hasPremiumAbo = true;
        }
        if (form.value.aboTyp.indexOf('EU') >= 0) {
            hasEuAbo = true;
        }
        const businessCustomer = (
            {
                agb: form.value.agb,
                hasPremiumAbo: hasPremiumAbo,
                hasEu: hasEuAbo,
                checkHasSuspiciousEmailAddress: check,
                portalUser: {
                    addressTerm: form.value.addressTerm,
                    title: form.value.title,
                    firstName: form.value.firstName,
                    name: form.value.name,
                    language: form.value.language,
                    email: form.value.email,
                    loginName: form.value.email,
                    password: form.value.password,
                    hasCorporateNewsletter: form.value.hasCorporateNewsletter,
                    isActivated: false,
                },
                company: {
                    name: form.value.companyName,
                    street: form.value.street,
                    zipCode: form.value.zipCode,
                    city: form.value.city,
                    postBox: form.value.postBox,
                    phone: form.value.phone,
                    fax: form.value.fax,
                    homepage: form.value.homepage,
                    email: form.value.email,
                    customerType: form.value.customerType,
                    numberOfFlawyers: form.value.numberOfFlawyers,
                    countryCode: form.value.country,
                    addressSuffix: form.value.addressSuffix,
                    accountIsActive: false,
                },
            }
        );
        this.aboRegistrationService.dispatch({ save: AboServicesActions.save_add_business_customer_data.name, payload: businessCustomer });
    }

    ngOnDestroy() {
        this.businessRegistrationSubscription.unsubscribe();
    }
}
