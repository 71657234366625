import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AccountService } from '../../account';
import { AppActions } from '../../appActions';
import { HitlistSizeOptions } from '../../models';

@Component({
    selector: 'slx-preferences',
    templateUrl: './preferences.component.html',
    styleUrls: ['./preferences.component.scss'],
})
export class PreferencesComponent implements OnInit, OnDestroy {
    public subscription: Subscription;
    public showErrorMessage = false;
    public inProgress = this.accountService.isActionInProgress(AppActions.save_profile_prefs.name);

    public preferences;

    public hitlistSizeOptions = HitlistSizeOptions;

    constructor(public accountService: AccountService) { }

    ngOnInit() {
        this.subscription = this.accountService.profilePrefs.subscribe(preferences => {
            if (preferences) {
                this.preferences = preferences;
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    hitlistSizeChanged(newSize) {
        const event = { source: { name: 'hitlistSize'}, value: newSize};
        this.onChange(event);
    }

    onChange(event) {
        const name = event.source.name;
        const value = event.value === undefined ? event.checked : event.value;

        const obj = {
            ...this.preferences,
            [name]: value,
        };

        this.accountService.dispatch({ save: AppActions.save_profile_prefs.name, payload: obj });
    }

    resetTips() {
        this.accountService.dispatch({ save: AppActions.save_user_tips.name, payload: { ListTipsViewed: [] } });
    }

}
