import { Component, OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { Subscription } from 'rxjs';

import { LoginService } from '../../../access';
import { HomeService } from '../../home.service';

@Component({
    selector: 'slx-news-tile',
    templateUrl: './newsTile.component.html',
    styleUrls: ['./newsTile.component.scss'],
})
export class NewsTileComponent implements OnInit, OnDestroy {


    loggedInSubscription: Subscription;
    constructor(public loginService: LoginService, public homeService: HomeService) { }

    ngOnDestroy(): void {
        this.loggedInSubscription.unsubscribe();
    }

    ngOnInit() {
        this.loggedInSubscription = this.loginService.loggedIn.subscribe(loggedIn => {
            if (loggedIn) {
                this.homeService.getNewsForHomeTile();
            }
        });
    }

}
