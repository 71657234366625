module.exports = {
	"common-month-january": "Januar",
	"common-month-february": "Februar",
	"common-month-march": "März",
	"common-month-april": "April",
	"common-month-may": "Mai",
	"common-month-june": "Juni",
	"common-month-july": "Juli",
	"common-month-august": "August",
	"common-month-september": "September",
	"common-month-october": "Oktober",
	"common-month-november": "November",
	"common-month-december": "Dezember",
	"common-back-to-top": "Nach oben",
	"common-doc": "Dokumente"
};