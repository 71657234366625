import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

import { AssetTabstrip } from '../models';

import { AssetService } from './asset.service';
import { AssetActions } from './assetActions';

const debug = require('debug')('tabs-drag');

@Directive({
    selector: '[slx-asset-tabs-drag]',
})
export class AssetTabsDragDirective implements OnInit {
    @Input() tabArea: AssetTabstrip;
    @Input() showDebug = false;
    private treshold = 12;
    private moveState: any = null;
    private debugEl: HTMLElement = null;

    constructor(private assetService: AssetService, private containerEl: ElementRef) {
        // Make them global to remove them from Eventlisteners later on
        this.mousemove = this.mousemove.bind(this);
        this.mouseup = this.mouseup.bind(this);
        this.keyup = this.keyup.bind(this);
    }

    ngOnInit() {
        if (this.showDebug) {
            this.debugEl = document.createElement('div');
            this.debugEl.className = 'glb-drag-debug-info';
            this.debugEl.style.display = 'none';
            this.containerEl.nativeElement.appendChild(this.debugEl);
        }
    }

    @HostListener('mousedown', ['$event', '$event.target']) onMousedown(event) {
        const source = event.target.closest('.asset-tab-label');

        // Only Grab the tab-Element, because Directive sits on whole tab-container
        if (!source) { return; }

        // Only handle left mouse down
        if (event.button !== 0) { return; }

        event.preventDefault();
        const sourceTabs = source.closest('slx-asset-tabs');
        this.moveState = {
            source,
            sourceTabs,
            sourceTabIndex: 0,
            sourceRect: source.getBoundingClientRect(),
            downEvent: event,
            dropTargetTabs: null,
            dragStarted: false,
            dropAction: null,
            targetTabs: [],

            get canDrop() {
                return this.dropTargetTabs !== null && this.dragStarted;
            },

            get planDescription() {
                if (!this.dropAction) return { action: 'Cancel when released.' };

                const { drag, insertPoint, sourceTabIndex } = this.dropAction;
                const { assetID, title } = this.dropAction.sourceTab;
                return {
                    action: drag ? 'drag' : 'move',
                    insertPoint,
                    sourceTabIndex,
                    assetID,
                    title,
                };
            },
        };

        document.addEventListener('mousemove', this.mousemove);
        document.addEventListener('mouseup', this.mouseup);
        document.addEventListener('keyup', this.keyup);

        return false;
    }

    private keyup(event) {
        if (event.keyCode === 27 && this.moveState) {
            this.moveState.dropAction = null;
            this.moveState.dragStarted = false;
            this.mouseup();
        }
    }

    private mousemove(event) {
        if (this.showDebug && this.moveState.underMouseCursor) this.moveState.underMouseCursor.style.outline = '';

        this.updateTargetElements(event); // what tab bar is the target (under the mouse pointer)?
        this.moveTab(event);
        this.planDrop(event);

        if (this.showDebug) {
            const { sourceTabIndex, sourceRect, planDescription, canDrop } = this.moveState;
            this.debugEl.style.display = '';

            this.debugEl.innerHTML = `
            <p><label>Source</label>${sourceTabIndex} * ${JSON.stringify(sourceRect)}</p>
            <p><label>CanDrop</label>${canDrop}</p>
            <p><label>Drop</label>${JSON.stringify(planDescription)}</p>
            `;
            if (this.moveState.underMouseCursor) this.moveState.underMouseCursor.style.outline = '1px solid red';
        }

        return false;
    }

    private mouseup() {
        if (this.showDebug && this.moveState.underMouseCursor) this.moveState.underMouseCursor.style.outline = '';
        this.moveState.source.style.border = '';
        this.moveState.source.style.zIndex = '9';
        document.body.classList.remove('tab-drag-started');
        this.moveState.source.classList.remove('dragging');

        const dropArea = document.querySelector('.drop-area');
        if (dropArea) { dropArea.classList.remove('drop-area'); }

        if (this.moveState.canDrop) {
            const docContent = this.moveState.sourceTabs.querySelector(`[id="${this.moveState.dropAction.sourceTab.assetID}"]`);
            if (docContent) {
                this.moveState.dropAction.intitalScrollInformation = { scrollPosition: docContent.parentElement.scrollTop,  pageHeight: docContent.getBoundingClientRect().height };
            }
            this.assetService.dispatch({ type: AssetActions.rearrange_tab.name, payload: this.moveState.dropAction });
        }
        if (this.moveState.dropTargetTabs) {
            this.moveState.dropTargetTabs.classList.remove('will-add-tab');
        }
        if (this.showDebug) {
            this.debugEl.style.display = 'none';
        }

        this.cleanListeners();
    }

    cleanListeners() {
        this.moveState.source.style.removeProperty('transform');
        document.removeEventListener('mouseup', this.mouseup);
        document.removeEventListener('mousemove', this.mousemove);
        this.moveState = null;
    }

    // calculate Target Rects for the TARGET tab bar
    updateTargetElements(event) {
        this.moveState.source.style.display = 'none';
        const underMouseCursor = document.elementFromPoint(event.clientX, event.clientY);
        this.moveState.source.style.display = '';
        if (this.moveState.dropTargetTabs) {
            this.moveState.dropTargetTabs.classList.remove('will-add-tab');
        }

        if (!underMouseCursor) return; // No Element under Mousecursor

        if (underMouseCursor !== this.moveState.underMouseCursor) { // Mouse is actually moved
            const dropTargetTabs = underMouseCursor.closest('.asset-tabs');

            if (dropTargetTabs && dropTargetTabs !== this.moveState.dropTargetTabs) {
                if (this.moveState.dropTargetTabs && this.moveState.dropTargetTabs.firstElementChild) { this.moveState.dropTargetTabs.firstElementChild.classList.remove('drop-area'); }
                this.moveState.targetAreaRect = dropTargetTabs.getBoundingClientRect(); // we assume that the top-left corner of container is the same as for the tab strip
                this.moveState.targetTabs = Array.from(dropTargetTabs.querySelectorAll('.asset-tab-label')).map((el: HTMLElement) => ({
                    rect: el.getBoundingClientRect(), el, left: el.offsetLeft, width: el.offsetWidth, middle: el.offsetLeft + el.offsetWidth / 2,
                }));
                if (this.moveState.sourceTabs !== dropTargetTabs && dropTargetTabs.firstElementChild) {
                    dropTargetTabs.firstElementChild.classList.add('drop-area');
                }
            }

            this.moveState.dropTargetTabs = dropTargetTabs;
            this.moveState.underMouseCursor = underMouseCursor;
        }
    }

    moveTab(event) {
        if (this.moveState) {
            const { sourceRect, downEvent } = this.moveState;
            const xDiff = event.pageX - downEvent.pageX; const yDiff = event.pageY - downEvent.pageY;
            if (!this.moveState.dragStarted && ((xDiff >= this.treshold || xDiff <= -this.treshold) || yDiff >= this.treshold || yDiff <= -this.treshold)) {
                this.moveState.dragStarted = true;
                document.body.classList.add('tab-drag-started');
                this.moveState.sourceTabIndex = this.moveState.targetTabs.reduce(
                    (resultIndex, tab, index) => resultIndex === undefined && tab.el === this.moveState.source ? index : resultIndex,
                    undefined);
                this.moveState.source.classList.add('dragging');
            }

            if (this.moveState.canDrop) {
                this.moveState.source.setAttribute('style', `border-bottom:2px solid #5177AC; transform: translate(${xDiff}px, ${yDiff}px); z-index: 10;`);
                this.moveState.sourceTabNewMiddle = sourceRect.left + xDiff + sourceRect.width / 2;
            }
        }
    }

    // Plan "Where would it be dropped if the mouse is released on mouse UP"
    planDrop(_) {
        if (this.moveState.dropTargetTabs && this.moveState.dropTargetTabs.classList.contains('drop-opposite')) {
            this.moveState.dropAction = {
                isPrimary: this.tabArea.isPrimary,
                insertPoint: -1,
                sourceTab: this.tabArea.assetTabs[this.moveState.sourceTabIndex],
                sourceTabIndex: this.moveState.sourceTabIndex,
                drag: true,
            };
            this.moveState.dropTargetTabs.classList.add('will-add-tab');
            debug('Planning drop opposite', this.moveState.dropAction);
        } else {
            if (this.moveState.canDrop) {
                const insertPoint: number = this.moveState.targetTabs.reduce((result, tab, index) => {
                    const { left, width } = tab;
                    const middle = left + width / 2;
                    const sourceTabmiddle = this.moveState.sourceTabNewMiddle - this.moveState.targetAreaRect.left;

                    // left side of the box
                    if (sourceTabmiddle < middle && sourceTabmiddle >= left) return index;
                    // right side of the box
                    if (sourceTabmiddle >= middle && sourceTabmiddle <= left + width) return index + 1;

                    return result;
                }, undefined);

                this.moveState.dropAction = {
                    isPrimary: this.tabArea.isPrimary,
                    insertPoint: typeof insertPoint === 'number' ? insertPoint : this.moveState.targetTabs.length,
                    sourceTab: this.tabArea.assetTabs[this.moveState.sourceTabIndex],
                    sourceTabIndex: this.moveState.sourceTabIndex,
                    drag: this.moveState.sourceTabs !== this.moveState.dropTargetTabs,
                };
            } else {
                this.moveState.dropAction = null;
            }
        }
    }
}
