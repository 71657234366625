import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { markFormAsTouched } from '../../app/utility/utilityFunctions';
import { AccountService } from '../account';
import { emailValidator, requiredValidator } from '../field';

import { CustomerProfileActions } from './customerProfileActions';

@Component({
    selector: 'slx-forgot-password',
    template: `
    <div style="max-width: 800px; margin: 0 auto;">
    <h1 translate="forgot-pwd-title"></h1>
    <p translate="forgot-pwd-text"></p>
    <form [formGroup]="sendEmailForm" (ngSubmit)="onSubmit(sendEmailForm)">
        <slx-text formControlName="email" placeholder="forgot-pwd-mail" [isRequired]="true"></slx-text>
        <slx-submit-button action="save_send_email_password_forgot" placeholder="forgot-pwd-enter" icon="done" errorPlaceholder="account-error" [locked]="sendEmailForm.invalid"></slx-submit-button>
    </form>
    </div>
    `,
    styles: [
        `:host {
            background: white;
            max-width: 1400px;
            margin: 0 auto;
            padding: 4rem;
            display: block;
            padding 3rem 6rem;
        }`,
        `p { margin: 1rem 0; }`,
        `.locked {
            color: grey;
            pointer-events: none;
        }`],
})
export class ForgotPasswordComponent implements OnInit {
    public sendEmailForm: FormGroup;

    constructor(private formBuilder: FormBuilder, public accountService: AccountService) { }

    ngOnInit() {
        this.sendEmailForm = this.formBuilder.group({
            email: ['', [requiredValidator(), emailValidator('service-reg-userid-notvalid-val-txt')]],
        });
    }

    onSubmit(sendEmailForm) {
        if (!sendEmailForm.valid) {
            markFormAsTouched(sendEmailForm);
            return;
        }
        const sendEmailFormData = { ...sendEmailForm.value };
        this.accountService.dispatch({ save: CustomerProfileActions.save_send_email_password_forgot.name, payload: sendEmailFormData });
    }
}
