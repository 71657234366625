import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { addDays, format, isValid } from 'date-fns';
import { Subscription } from 'rxjs';

import { emailValidator, linkPatternValidator, requiredValidator, timePatternValidator } from '../../field';
import { markFormAsTouched } from '../../utility/utilityFunctions';
import { EventsService } from '../events.service';
import { EventsActions } from '../eventsActions';

@Component({
    selector: 'slx-register-event',
    templateUrl: './register-event.component.html',
    styleUrls: ['./register-event.component.scss'],
})

export class RegisterEventComponent implements OnDestroy {

    private subscription: Subscription;

    public registerEventsForm: FormGroup;
    public selectedLanguage: string;
    public bothSelected = false;

    public languages: Array<{ key: string, value: string }> = [
        { key: 'de', value: 'events-register-german' },
        { key: 'fr', value: 'events-register-french' },
        { key: 'both', value: 'events-register-both' },
    ];

    public inProgress = this.eventService.isActionInProgress(EventsActions.create_event.name);

    constructor(public eventService: EventsService, private formBuilder: FormBuilder) {

        this.registerEventsForm = this.formBuilder.group({
            LanguageKey: ['de'],
            Title: ['', requiredValidator()],
            Title2: [''],
            City: ['', requiredValidator()],
            City2: [''],
            Venue: [''],
            DateFrom: ['', requiredValidator()],
            DateTo: ['', requiredValidator()],
            TimeFrom: ['', [requiredValidator(), timePatternValidator('events-validation-time')]],
            TimeTo: ['', [requiredValidator(), timePatternValidator('events-validation-time')]],
            PracticeAreas: [[]],
            TextShort: [''],
            TextShort2: [''],
            Text: [''],
            Text2: [''],
            Organizer: [''],
            Organizer2: [''],
            Link: ['', linkPatternValidator('events-validation-link')],
            Link2: [''],
            Name: ['', requiredValidator()],
            Mail: ['', [requiredValidator(), emailValidator('events-validation-email-invalid')]],
            RecaptchaReactive: [null, requiredValidator()],
        });

        this.subscription = this.registerEventsForm['controls'].LanguageKey.valueChanges.subscribe(language => {
            this.selectedLanguage = language;

            if (language === 'both') {
                this.bothSelected = true;
                this.registerEventsForm.controls['Title2'].setValidators(requiredValidator());
                this.registerEventsForm.controls['City2'].setValidators(requiredValidator());
                this.registerEventsForm.controls['Link2'].setValidators(linkPatternValidator('events-validation-link'));
            } else {
                this.bothSelected = false;
                this.registerEventsForm.controls['Title2'].setValidators(null);
                this.registerEventsForm.controls['Title2'].updateValueAndValidity();
                this.registerEventsForm.controls['City2'].setValidators(null);
                this.registerEventsForm.controls['City2'].updateValueAndValidity();
                this.registerEventsForm.controls['Link2'].setValidators(null);
                this.registerEventsForm.controls['Link2'].updateValueAndValidity();
            }
        });
    }

    public getDateToday() {
        return format(Date.now(), 'DD.MM.YYYY');
    }

    public getDateTomorrow() {
        return format(addDays(Date.now(), 1), 'DD.MM.YYYY');
    }

    public resetForm() {
        this.registerEventsForm.reset();
        Object.keys(this.registerEventsForm.controls).forEach((key) => {
            this.registerEventsForm.controls[key].setErrors(null);
            this.registerEventsForm.controls[key].updateValueAndValidity();
        });
        this.registerEventsForm.controls['LanguageKey'].setValue('de');
        this.registerEventsForm.controls['DateTo'].setValue(null);

    }

    onSubmit(registerEventsForm) {
        if (!registerEventsForm.valid) {
            markFormAsTouched(registerEventsForm);
            return;
        }
        this.eventService.dispatch({ save: EventsActions.create_event.name, payload: registerEventsForm.value });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
