import { ThesaurusInformation } from '../state/thesaurusInformation';

export interface FieldState {
    loadingThesaurusInformation: boolean;
    thesaurusInformation: ThesaurusInformation;
    tooltipInformation: any;
}

export const defaultFieldState = {
    loadingThesaurusInformation: false,
    thesaurusInformation: null,
    tooltipInformation: null,
};
