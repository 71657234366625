import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { AutocompleteService } from '../../../asset';
import { requiredValidator } from '../../../field';
import { AutocompleteParamter, AutocompleteType, SearchType } from '../../../models';
import { SearchService } from '../../search.service';
import { SearchFormBase } from '../search-form-base';


@Component({
    selector: 'slx-journal',
    templateUrl: './journal.component.html',
    styleUrls: ['./journal.component.scss'],
})
export class JournalComponent extends SearchFormBase {

    private abbrIsFromSelection = false;

    constructor(protected searchService: SearchService, protected autocompleteService: AutocompleteService, protected formBuilder: FormBuilder, protected changeDetectorRef: ChangeDetectorRef, protected translate: TranslateService) {
        super(searchService, SearchType.Journal, changeDetectorRef, translate);
        // bind autocomplete get url functions (this)
        this.getAbbreviationAutocomplete = this.getAbbreviationAutocomplete.bind(this);
        this.getYearOrVolumeAutocomplete = this.getYearOrVolumeAutocomplete.bind(this);
        this.getPagesOrSequenceNumberAutocomplete = this.getPagesOrSequenceNumberAutocomplete.bind(this);
        this.getIssueAutocomplete = this.getIssueAutocomplete.bind(this);
        this.handleIsSelectionFromDropdown = this.handleIsSelectionFromDropdown.bind(this);

        // Create searchForm with Formbuilder
        this.searchForm = this.formBuilder.group({
            abbreviation: [''],
            yearOrVolume: [''],
            issue: [''],
            pagesOrSequenceNumber: [''],
            abbrIsFromSelection: this.abbrIsFromSelection,
        });

        this.controlDependencies = ['abbreviation','yearOrVolume','issue','pagesOrSequenceNumber'];

        this.formDescription = {
            formGroups: [
                {
                    class: 'slx-form-row no-wrap',
                    controls: [
                        {
                            control: 'slx-text', isSelectionFromDropdown: this.handleIsSelectionFromDropdown, name: 'Abbreviation', type: 'text', placeholder: 'rech-pub-abbreviation', formControlName: 'abbreviation', class: 'slx-field-xlarge',
                            makeAutocomplete: this.getAbbreviationAutocomplete, autocompleteMinQueryLength: 0, textChanged: this.resetDependencies('abbreviation'),
                        },
                        { control: 'slx-info-button', filename: 'reference-help-journals', titleTransString: 'home-info-reference-help', class: 'in-form' },
                    ],
                },
                {
                    controls: [
                        {
                            control: 'slx-text', name: 'YearOrVolume', type: 'text', placeholder: 'rech-pub-year', formControlName: 'yearOrVolume',
                            makeAutocomplete: this.getYearOrVolumeAutocomplete, autocompleteMinQueryLength: 0, textChanged: this.resetDependencies('yearOrVolume'),
                        },
                        {
                            control: 'slx-text', name: 'Issue', type: 'text', placeholder: 'rech-pub-issue', formControlName: 'issue',
                            makeAutocomplete: this.getIssueAutocomplete, autocompleteMinQueryLength: 0, textChanged: this.resetDependencies('issue'),
                        },
                        {
                            control: 'slx-text', name: 'PagesOrSequenceNumber', type: 'text', placeholder: 'rech-pub-page', formControlName: 'pagesOrSequenceNumber',
                            makeAutocomplete: this.getPagesOrSequenceNumberAutocomplete, autocompleteMinQueryLength: 0,
                        },
                    ],
                },
            ],
            controls: {
                resetControl: true,
            },
            searchButtonText: 'rech-display',
        };

        this.validationKeys = [
            'abbreviation',
        ];
    }

    onSearchFormChanges(state: any): void {
        this.abbrIsFromSelection = !!state.abbrIsFromSelection;
    }

    handleIsSelectionFromDropdown(selection) {
        this.abbrIsFromSelection = selection;
        this.searchForm.patchValue({ abbrIsFromSelection: selection });
    }

    getAbbreviationAutocomplete() {
        return () => {
            const parameters = [
                new AutocompleteParamter('journalAbbr', this.searchForm.value.abbreviation),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.JournalAbbreviation, true, parameters);
        };
    }

    getYearOrVolumeAutocomplete() {
        return () => {
            const parameters = [
                new AutocompleteParamter('journalAbbr', this.searchForm.value.abbreviation),
                new AutocompleteParamter('valueFromSelection', this.abbrIsFromSelection.toString()),
                new AutocompleteParamter('yearOrVolume', this.searchForm.value.yearOrVolume),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.JournalYearOrVolume, true, parameters);
        };
    }

    getIssueAutocomplete() {
        return () => {
            const parameters = [
                new AutocompleteParamter('journalAbbr', this.searchForm.value.abbreviation),
                new AutocompleteParamter('valueFromSelection', this.abbrIsFromSelection.toString()),
                new AutocompleteParamter('yearOrVolume', this.searchForm.value.yearOrVolume),
                new AutocompleteParamter('issue', this.searchForm.value.issue),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.JournalIssue, true, parameters);
        };
    }

    getPagesOrSequenceNumberAutocomplete() {
        return () => {
            const parameters = [
                new AutocompleteParamter('journalAbbr', this.searchForm.value.abbreviation),
                new AutocompleteParamter('valueFromSelection', this.abbrIsFromSelection.toString()),
                new AutocompleteParamter('yearOrVolume', this.searchForm.value.yearOrVolume),
                new AutocompleteParamter('issue', this.searchForm.value.issue),
                new AutocompleteParamter('page', this.searchForm.value.pagesOrSequenceNumber),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.JournalPagesOrSequenceNumber, true, parameters);
        };
    }
}
