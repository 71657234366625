import { Action, ActionsSubject } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppState } from './appState';
import { ActionState, AlertType, CommonAppState, defaultCommonAppState } from './state/commonApp';

/**
 * Slx actions are regular actions or Resource Request actions.
 *
 * alert: raises an alert in parallel
 * infoAlert: raises a default alert with an info icon
 * navigate: if specified navigate to the given URL
 * save: the action type being saved, updated inProgress transparently
 * fetch: the action type for resource being fetched, updated inProgress transparently
 * result: the action is a result from save/fetch
 * error: payload holds the error info, type is extended with '_error'
 */
export interface SlxAction extends Action {
    payload?: any;

    // Any action dispatch can raise an alert in addition
    alert?: any;
    infoAlert?: any; // shortcut to alert with info icon

    // Router navigate will happen to any action with navigate
    navigate?: string[];

    // Request pattern, alias for `type`
    save?: string;
    fetch?: string;
    result?: string;
    error?: string;
    baseType?: string; // value of either of the above without appended _result / _error

}

export function isRequestAction(action: SlxAction) {
    return action.save || action.fetch || action.result || action.error;
}

export function makeAction(action: any | SlxAction): SlxAction {
    const responseType = (action.result && `${action.result}_result`) || (action.error && `${action.error}_error`.replace('_error_error', '_error'));
    if (!action.baseType) {
        action.baseType = action.save || action.fetch || action.result || action.error || action.type;
        action.type = responseType || action.baseType;
    }
    if (!action.alert && action.infoAlert) {
        action.alert = { type: AlertType.Info, duration: 10, ...action.infoAlert };
    }

    return action as SlxAction;
}

/**
 * Extends Actions objects for modules to support reducers
 *
 * @param obj Actions object
 */
export function enhanceActionsObject(obj: any) {
    for (const n in obj) {
        obj[n] = wrappedAction(obj[n], n);
    }

    // Call API
    obj.call = (state, action: SlxAction) => {
        const e = action.error ? obj[action.type] : obj[action.type] || obj[action.baseType];
        const fn = (e && e.fn) || e;
        return fn ? fn.call(obj, state, action) : state;
    };

    // Easy use of name in IE11
    function wrappedAction(action, name) {
        const fn = obj[name];
        return { fn, name };
    }
}

export function updateLastStateOfActions(state: AppState, action: SlxAction) {
    let app = state.app || defaultCommonAppState;
    let lastStateOfActions;

    app = clearLastStateOfActions(app);

    if (action.save || action.fetch) {
        lastStateOfActions = { ...app.lastStateOfActions, [action.baseType]: ActionState.InProgress };
    } else if (action.result) {
        lastStateOfActions = { ...app.lastStateOfActions, [action.baseType]: ActionState.Success };
    } else if (action.error) {
        lastStateOfActions = { ...app.lastStateOfActions, [action.baseType]: ActionState.Error };
    }

    return lastStateOfActions
        ? { ...state, app: { ...app, lastStateOfActions } }
        : { ...state, app };
}

export function clearLastStateOfActions(app: CommonAppState) {
    Object.keys(app.lastStateOfActions).forEach(key => {
        const value = app.lastStateOfActions[key];
        if (value === ActionState.Success || value === ActionState.Error) {
            delete app.lastStateOfActions[key];
        }
    });

    return app;
}

export function isActionInProgress(state: AppState, ...types) {
    const app = state.app || defaultCommonAppState;
    let inProgress;

    for (const type of types) {
        if (app.lastStateOfActions[type] === ActionState.InProgress) {
            inProgress = true;
            break;
        }
    }

    return !!inProgress;
}

export function stateOfAction(state: AppState, type) {
    const app = state.app || defaultCommonAppState;
    return app.lastStateOfActions[type];
}

// export interface ModuleActionsAPI {
//     call : (state,action) => any;
// }

export function catchErrorObservable(type, key, payloader: (payload) => any = (payload) => payload, alert: any = {}) {
    return (payload) => of(makeAction({
        error: type,
        payload: payloader(payload),
        alert: {
            type: AlertType.Error, duration: 8, key: key || payload.translationKey,  ...alert, issueId: payload.issueId, data: payload.data,
        },
    }));
}

/**
 * Error catcher operator for RXJS Observable.pipe
 * For a given action that was used to initiate the fetch, make another action, but in error.
 * @param action Source action representing the happy path
 * @param alertKey Key for an error alert (if set to null the translationKey in payload will be used)
 * @param id Id for the alert
 * @param alert Specify the full alert yourself, or add additional paramaters
 */
export function catchErrorForAction(action, { type, alertKey, id, alert: customAlert, sticky = false }: { type?: AlertType, alertKey?, id?, alert?, sticky?}, payloader: (payload) => any = (payload) =>  ({ ...action.payload, ...payload})) {
    function effectiveAlert(payload) {
        if (alertKey === undefined && !customAlert) return undefined;

        if (payload.error && payload.error.transactionLimitReached) {
            return makeTransactionLimitReachedAlert(payload.error.limit);
        }

        const alert = { type, duration: 8, id, key: alertKey || payload.translationKey || payload.error.translationKey, sticky, ...customAlert, issueId: payload.issueId, data: payload.data, fromAction: action.baseType };

        // Temporär, bis eine andere Lösung gefunden wird.
        // Wenn vom Backend, sowie vom Frontend kein Key definiert / mitgegeben wird
        if(alertKey === null  && !payload.translationKey && !payload.error.translationKey) {
            alert.key = 'account-undefined-error';
        }

        return alert;
    }

    return catchError((payload) => {
        const errorAction = makeAction({
            error: action.type,
            payload: payloader(payload),
            alert: effectiveAlert(payload),
        });
        return of(errorAction);
    });
}


export function makeTransactionLimitReachedAlert(transactionLimit) {
    return {
        type: AlertType.Error,
        duration: 10,
        key: 'account-transaction-limit-reached-title',
        text: 'account-transaction-limit-reached-text',
        transactionLimit,
    };
}

/**
 * Keep any, because the param is an action
 * @param action The action for which you want the result name
 */
export function getResultNameFromAction(action): string {
    return `${action.name}_result`;
}

export function getErrorNameFromAction(action): string {
    return `${action.name}_error`;
}

// TODO stores errors in state
