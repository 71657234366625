import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { getImageUrl, getUrlForLogo } from '../access';
import { apiBaseUrl } from '../config';

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }
    transform(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }
    transform(html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}

@Pipe({ name: 'stripTags' })
export class StripTagsPipe implements PipeTransform {
    transform(value: any): any {
        return value ? String(value).replace(/<[^>]+>/gm, '') : '';
    }
}

@Pipe({ name: 'truncate' })
export class ShortenStringLengthPipe implements PipeTransform {
    transform(sourceString: string, desiredLength: number): string {
        if (!sourceString || sourceString.length <= desiredLength) { return sourceString; }
        return sourceString && desiredLength ? sourceString.substr(0, desiredLength) + '...' : sourceString;
    }
}

@Pipe({ name: 'getLogoUrl' })
export class GetLogoUrlPipe implements PipeTransform {
    transform(item): string {
        return getUrlForLogo(item.logoUrl);
    }
}

@Pipe({ name: 'urlForTip' })
export class UrlForTipPipe implements PipeTransform {
    transform(tip): string {
        return `${apiBaseUrl}/${getImageUrl}${tip.imageId}`;
    }
}
