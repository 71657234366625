import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AccountService } from '../../account';

@Component({
    selector: 'slx-markdown-page',
    template: `<slx-markdown [mdfilename]="mdfilename" [currentLanguage]="this.accountService.lang"></slx-markdown>`,
    styles: [''],
})
export class MarkdownPageComponent {

    public mdfilename: string;
    public currentLanguage: string;

    constructor(public accountService: AccountService, private route: ActivatedRoute) {
        this.mdfilename = this.route.snapshot.data.filename;
    }

}
