import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'slx-grid-search',
    templateUrl: './gridSearch.component.html',
    styleUrls: ['./gridSearch.component.scss'],
})
export class GridSearchComponent {

    @Input() placeholder: string;
    @Input() searchString: string;
    @Output() inputValueChanged = new EventEmitter<string>();

    private wait: any;
    valueChanged($event, value) {
        if (this.wait) {
            clearTimeout(this.wait);
            this.wait = null;
        }
        this.wait = setTimeout(() => this.inputValueChanged.emit(value), 500);
    }

    resetInput(searchInput) {
        searchInput.value = null;
        this.inputValueChanged.emit(searchInput.value);
    }
}
