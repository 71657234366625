import { PracticeArea } from '../state/practiceArea';


export class Event{
    public title: string;
    public shortText: string;
    public id: string;
    public city: string;
    public showOnStartPage: number;
    public displayDate: string;
    public date: string; // swiss format
    public practiceAreaList: PracticeArea[];
}

export class EventViewModel{
    public beginDate: string;
    public booksLink: string;
    public calendarText: string;
    public city: string;
    public displayDate: string;
    public endDate: string;
    public id: string;
    public link: string;
    public organizers: string;
    public practiceAreaList: PracticeArea[];
    public text: string;
    public title: string;
    public venue: string;
}
