import { format } from 'date-fns';

import { enhanceActionsObject, Event, EventsState, EventViewModel, SlxAction } from '../models';

function isNavigatingFromDetailsToCurrent(from:string, to:string): boolean{
    return (to === 'current' && from === 'details');
}

export const EventsActions = {

    filter_calendar(state: EventsState, { payload }){
        return {
            ...state,
            choosenDateFromCalendar: payload,
        };
    },

    create_event(state: EventsState, { payload }) {
        return state;
    },

    set_events_tab(state: EventsState, { payload }) {
        if(isNavigatingFromDetailsToCurrent(state.eventsSubTab, payload) && !state.filterFromDetail){
            return {
                ...state,
                choosenDateFromCalendar: null,
                eventsSubTab: payload,
            };
        }

        return {
            ...state,
            eventsSubTab: payload,
            filterFromDetail: false,
        };
    },

    load_single_event(state: EventsState, { payload, result }) {
        const event = payload as EventViewModel;

        return result ? {
            ...state,
            eventDetails: event,
            choosenDateFromCalendar: event.beginDate,
        } : {
            ...state,
            eventDetailsID: payload,
        };
    },

    load_current_events(state: EventsState, { payload, result }) {
        if (result){
            const eventList = payload as Event[];
            return {
                ...state,
                currentEventsList: eventList.map(ev => ({...ev, date: format(ev.date,'DD.MM.YYYY')})),
            };
        }

        return state;
    },

    load_practice_areas(state: EventsState, { payload, result }) {
        return result ? {
            ...state,
            practiceAreas: payload,
        } : state;
    },

    set_filterFromDetail(state: EventsState, { payload }){
        return {
            ...state,
            filterFromDetail: payload,
        };
    },
};
enhanceActionsObject(EventsActions);
