import { decorateHitlist, enhanceActionsObject, NewsState, Tile } from '../models';

export const NewsActions = {

    collapse_news_sidebar(state: NewsState) {
        return {
            ...state,
            sidebarClosed: !state.sidebarClosed,
        };
    },

    set_news_practice_areas(state: NewsState, { payload }) {
        return {
            ...state,
            practiceAreas: payload,
        };
    },

    set_news_courts(state: NewsState, { payload }) {
        return {
            ...state,
            courts: payload,
        };
    },

    set_news_journals(state: NewsState, { payload }) {
        return {
            ...state,
            journals: payload,
        };
    },

    set_tile_configuration(state: NewsState, { payload }) {
        return {
            ...state,
            tiles: payload,
        };
    },

    update_tile_configuration(state: NewsState, { payload, result }) {
        const newTile = payload as Tile;
        const tiles = state.tiles.map(t => t.id === newTile.id ? newTile : t);
        return {
            ...state,
            tiles,
        };
    },

    update_and_fetch_tile_configuration(state: NewsState, { payload, result }) {
        if (result) {
            return state;
        } else {
            const newTile = payload as Tile;
            const tiles = state.tiles.map(t => t.id === newTile.id ? newTile : t);
            return {
                ...state,
                tiles,
            };
        }
    },

    fetch_tile_content(state: NewsState, { payload, result }) {
        let res;
        if (result) {
            res = {
                ...state,
                tileContent: { ...state.tileContent, [payload.id]: { content: { ...decorateHitlist(payload.result.hitlist), issue: payload.result.issue, year: payload.result.year }, theDate: Date.now() } },
            };
        } else {
            res = {
                ...state,
                tileContent: { ...state.tileContent, [payload.id]: { theDate: Date.now() } },
            };
        }
        return res;
    },

    remove_tile(state: NewsState, { payload, result }) {
        if (result) {
            const tileContent = { ...state.tileContent };
            delete tileContent[payload];

            return {
                ...state,
                tiles: state.tiles.filter(tile => tile.id !== payload),
                tileContent,
            };
        } else {
            return state;
        }
    },

    add_new_tile(state: NewsState, { payload, result }) {
        return result ? {
            ...state,
            tiles: [...state.tiles, payload],
        } : state;
    },

    send_news_now(state: NewsState) {
        return state;
    },
};
enhanceActionsObject(NewsActions);
