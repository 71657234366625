import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { HomeService } from '../../home.service';
import { HomeActions } from '../../homeActions';

@Injectable()
export class FaqResolver implements Resolve<boolean> {

    constructor(private homeService: HomeService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const path = route.url[0].path;
        const topicID = route.data.topicID;

        this.homeService.dispatch({ fetch: HomeActions.get_faq_by_topic_id.name, payload: { topicID: topicID }});

        return true;
    }
}
