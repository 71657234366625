import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, pipe, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { AccountService } from './account';
import { AppLocationStrategy } from './appLocationStrategy';
import { AppState, getLanguageIndependantUrl, supportedLanguages } from './models';
import { RouterExtService } from './routerext.service';
import { routingDebug } from './utility/utilityFunctions';

@Injectable()
export class SeoService {

    private readonly BASE_URL = 'https://www.swisslex.ch';
    private readonly titleSeparator = ' | ';

    private subscription: Subscription;

    private currentUrl: string = null;
    private currentUrlParts: string[] = null;

    private currentLanguage: string;

    // these resolvers are only combined if they are used in a translate variable (seo.lang.yaml)
    private dynamicResolvers = {
        assetTitle: this.store.select(state => state.recherche.primaryTabState.currentAssetTab).pipe(
            filter(assetTab => assetTab != null && !!assetTab.title),
            map(assetTab => assetTab.title)
        ),
        jobTitle: this.store.select(state => state.jobs.currentJob).pipe(
            filter(job => job != null && !!job.title),
            map(job => job.title)
        ),
        eventTitle: this.store.select(state => state.events.eventDetails).pipe(
            filter(event => event != null && !!event.title),
            map(event => event.title)
        ),
    };

    constructor(private title: Title, private link: Link, private meta: Meta,
                private routerExtension: RouterExtService, private appLocation: AppLocationStrategy,
                private store: Store<AppState>, private account: AccountService, private translate: TranslateService) {

        this.account.addTranslation('seo-',
            require('./seo.de.yaml'),
            require('./seo.fr.yaml')
        );

        this.currentLanguage = translate.currentLang;

        this.appLocation.registerAfterPushStateFunctions((url) => {
            routingDebug('seo', 'afterPushStateFunction', url);
            this.initPage(url);
        });
        this.routerExtension.registerNavigationEndFunction((router, event) => {
            routingDebug('seo', 'navigationEndFunction', router.url);
            this.initPage(this.routerExtension.router.url);
        });

        this.translate.onLangChange.subscribe(event => {
            routingDebug('seo', 'langChange', event.lang);
            this.currentLanguage = event.lang;
            this.refreshPage();
        });
    }

    public initPage(_url: string) {
        routingDebug('seo', 'initPage');

        const { relativeUrl, relativeUrlParts } = getLanguageIndependantUrl(_url);
        if (relativeUrl === this.currentUrl) {
            routingDebug('seo', 'initPage aborted');
            return;
        }
        this.currentUrl = relativeUrl;
        this.currentUrlParts = relativeUrlParts;

        this.refreshPage();
    }

    public refreshPage() {
        routingDebug('seo', 'refreshPage');
        if (this.subscription) {
            routingDebug('seo', 'refreshPage unsub obs');
            this.subscription.unsubscribe();
        }
        this.subscription = null;

        this.updateLanguageTags(this.currentUrl);
        this.updateCanonical(this.currentUrl);

        const { combinedResolverObservables, title, description } = this.getTranslationsForUrl(this.currentUrlParts);
        if (combinedResolverObservables) {
            routingDebug('seo', 'registering obs');
            this.subscription = combinedResolverObservables.subscribe(translateParams => {
                routingDebug('seo', 'subscription translateParams', translateParams);
                const { title, description } = this.getTranslationsForUrl(this.currentUrlParts, translateParams);
                this.updateTitle(title);
                this.updateMetaDescriptionTags(description);
            });
        } else {
            this.updateTitle(title);
            this.updateMetaDescriptionTags(description);
        }
    }

    /**
     * Searches for every part of the url if any variables are required (translateParams), provides them by combining their corresponding resolvers into ONE observable.
     * @param urlParts the parts of the current url '/recherche/search/new' => ['recherche','search',new']
     * @param translateParams the variable values generated by the resolvers. This is optional, if it isn't provided, the combining of the necessary resolvers into the ONE observable will be done.
     */
    private getTranslationsForUrl(urlParts: string[], translateParams?: any): { combinedResolverObservables: Observable<any>, title: string, description: string } {
        const translateKeys = this.generateTranslateKeysForUrl(urlParts);

        let title = null, description = null;
        const tempObservables: Array<Observable<any>> = [];

        for (let i = 0; i < translateKeys.length; i++) {
            const titleTranslatePart = this.tryTranslate('seo-title-' + translateKeys[i], translateParams);

            if (titleTranslatePart != null) {
                const matches = titleTranslatePart.match(/(\{\{(.*?)\}\})/g);
                if (matches && !translateParams) {
                    tempObservables.push(...matches.map(element => {
                        const resolverName = element.replace(/(\{\{)|(\}\})/g,''); // removes the curly braces from the given resolver name
                        let obs: Observable<any> = null;
                        const resolver = this.dynamicResolvers[resolverName]; // resolver: usually a selector from the store, see dynamic and static resolvers above
                        if (resolver != null) {
                            obs = resolver.pipe(
                                map(resolvedValue => {
                                    const res = {};
                                    res[resolverName] = resolvedValue;
                                    return res;
                                }),
                                startWith({ [resolverName]: null })
                            );
                        }
                        return obs;
                    }).filter(e => e != null));
                }

                if (title === null) {
                    title = titleTranslatePart;
                } else {
                    title = `${title}${this.titleSeparator}${titleTranslatePart}`;
                }
            }
            if (description == null) {
                description = this.tryTranslate('seo-description-' + translateKeys[i]);
            }
        }
        let combinedResolverObservables = null;
        if (tempObservables.length > 0) {
            routingDebug('seo', 'combining obs');
            combinedResolverObservables = combineLatest(tempObservables).pipe(map(translateVariables => {
                const result = {};
                translateVariables.forEach(variable => {
                    Object.assign(result, variable);
                });
                Object.keys(result).forEach(key => {
                    if (result[key] == null) {
                        result[key] = '...';
                    }
                });
                return result;
            }));
        }

        return { combinedResolverObservables, title, description };
    }

    /**
     * Generates all possible translate keys for given urlParts, in order from most specific to default.
     * Sample: /de/recherche/search/new/ -> ['recherche-search-new', 'recherche-search', 'recherche', 'default']
     * @param urlParts the urlParts for which to generate the keys
     */
    private generateTranslateKeysForUrl(urlParts: string[]): string[] {
        const result = [];
        for (let i = urlParts.length; i > 0; i--) {
            result.push(urlParts.slice(0, i).join('-'));
        }
        result.push('default');
        return result;
    }

    /**
     * Tries to translate a given key; returns null if no translation is possible
     * @param key the key to translate
     * @param translateParams translate params (variables) in one object e.g. { assetTitle: 'ZGB 1.7a' }
     */
    private tryTranslate(key: string, translateParams?: any): string {
        const result: string = this.translate.instant(key, translateParams);
        if (result !== key) {
            return result;
        }
        return null;
    }

    /**
     * Sets the hreflang link in the header for the given url and all supported languages
     * @param url the url for which to generate the language href alternatives (only nonlocalized urls can safely be passed)
     */
    private updateLanguageTags(cleanedUrl) {
        const hreflangs = [];
        supportedLanguages.forEach(lang => hreflangs.push({
            href: `${this.BASE_URL}/${lang}${cleanedUrl}`,
            hreflang: lang,
        }));
        hreflangs.push({
            href: `${this.BASE_URL}${cleanedUrl}`,
            hreflang: 'x-default',
        });

        hreflangs.forEach(tag => {
            this.link.updateTag(tag);
        });
    }

    private updateCanonical(cleanedUrl) {
        const canonical : LinkDefinition = {
            href: `${this.BASE_URL}/${this.currentLanguage}${cleanedUrl}`,
            rel: 'canonical',
        };
        this.link.updateTag(canonical);
        routingDebug('seo','canonical', canonical.href, window.document.location.href);
    }

    private updateMetaDescriptionTags(content: string) {
        if (content) {
            const description: MetaDefinition = {
                name: 'description',
                content: content,
            };
            this.meta.updateTag(description);
        } else {
            // remove the metatag when none is defined, otherwise it will still reflect the one from the "former" page
            this.meta.removeTag('name="description"');
        }
    }

    private updateTitle(title: string) {
        this.title.setTitle(title);
    }
}

/*
** adapted from https://github.com/angular/angular/issues/15776#issuecomment-436922524
*/
@Injectable()
export class Link {
    constructor(@Inject(DOCUMENT) private readonly document: Document) { }

    /**
     * Create or update a link tag
     * @param  {LinkDefinition} tag
     */
    public updateTag(tag: LinkDefinition): void {
        const selector = this._parseSelector(tag);
        const linkElement = <HTMLLinkElement>this.document.head.querySelector(selector)
            || this.document.head.appendChild(this.document.createElement('link'));

        if (linkElement) {
            Object.keys(tag).forEach((prop: string) => {
                linkElement[prop] = tag[prop];
            });
        }
    }

    /**
     * Remove a link tag from DOM
     * @param  tag
     */
    public removeTag(tag: LinkDefinition): void {
        const selector = this._parseSelector(tag);
        const linkElement = <HTMLLinkElement>this.document.head.querySelector(selector);

        if (linkElement) {
            this.document.head.removeChild(linkElement);
        }
    }

    /**
     * Get link tag
     * @param  tag
     * @return {HTMLLinkElement}
     */
    public getTag(tag: LinkDefinition): HTMLLinkElement {
        const selector = this._parseSelector(tag);

        return this.document.head.querySelector(selector);
    }

    /**
     * Get all link tags
     * @return {NodeListOf<HTMLLinkElement>}
     */
    public getTags(): NodeListOf<HTMLLinkElement> {
        return this.document.head.querySelectorAll('link');
    }

    /**
     * Parse tag to create a selector
     * @param  tag
     * @return {string} selector to use in querySelector
     */
    private _parseSelector(tag: LinkDefinition): string {
        const attr: string = tag.rel ? 'rel' : 'hreflang';
        return `link[${attr}="${tag[attr]}"]`;
    }
}

export type LinkDefinition = {
    charset?: string;
    crossorigin?: string;
    href?: string;
    hreflang?: string;
    media?: string;
    rel?: string;
    rev?: string;
    sizes?: string;
    target?: string;
    type?: string;
} & {
    [prop: string]: string;
};
