module.exports = {
	"biblio-journals": "Revues",
	"biblio-casecollections": "Jurisprudence",
	"biblio-books": "Livres",
	"biblio-books-uni": "Manuels scolaires",
	"biblio-booksuni": "Manuels scolaires",
	"biblio-commentaries": "Commentaires",
	"biblio-serials": "Séries",
	"biblio-logoUrl": "Logo",
	"biblio-abbreviation": "Abréviation",
	"biblio-titles": "Titre de la publication",
	"biblio-publisher": "Éditeur",
	"biblio-sinceYear": "À partir de l'an",
	"biblio-untilYear": "Jusqu'à l'an",
	"biblio-subscribe": "Créer une newsletter",
	"biblio-year": "Année",
	"biblio-filter": "Rechercher",
	"biblio-edition": "Édition",
	"biblio-noresult": "Pas de livre de cette catégorie avec votre abonnement",
	"biblio-volume": "No",
	"biblio-casecollections-logoUrl": " ",
	"biblio-casecollections-categorie": "Canton",
	"biblio-casecollections-abbreviation": "Abréviation",
	"biblio-casecollections-titles": "Titre de la publication",
	"biblio-casecollections-sinceYear": "À partir de l'an",
	"biblio-casecollections-untilYear": "Jusqu'à l'an",
	"biblio-casecollections-published": "A. Jurisprudence fédérale et cantonale (publiée)",
	"biblio-casecollections-unpublished": "B. Jurisprudence fédérale et cantonale (non publiée)",
	"biblio-casecollections-publisher": "Tribunal/Editeur",
	"biblio-title": "Titre",
	"biblio-author": "Auteur/Éditeur",
	"biblio-serial-number": "No",
	"biblio-nobook": "Pas de livres trouvés pour cette catégorie",
	"biblio-loading": "Contenus en cours de chargement",
	"biblio-eu": "Droit UE"
};