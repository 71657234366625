import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import * as config from '../../config';
import { Asset, AssetDisplayType, AssetTab, AssetTabstrip, TocContent } from '../../models';
import { AssetRef } from '../../models/assets';
import { AssetService } from '../asset.service';

const debug = require('debug')('tocview');

@Component({
    selector: 'slx-tocview',
    templateUrl: './tocview.component.html',
    styleUrls: ['./tocview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TocviewComponent implements OnChanges, OnDestroy {
    public showLowMeta = false;
    public showLowMetaText = 'rech-asset-showLowMetaTextMore';
    public asset: Asset;
    public tocContent: TocContent;
    public isEuToc: boolean;

    private assetSubscription: Subscription;


    //public get tocContent(): TocContent { return this.asset ? this.asset.content as TocContent : null; }

    @Input() ref: AssetRef;
    @Input() isPrimary: boolean;
    @Input() inSideBar = false;
    @Input() fontSize: Number;

    @HostBinding('class.isInTab') isInTab: boolean;

    constructor(private translateService: TranslateService, public assetService: AssetService, private changeDetectorRef: ChangeDetectorRef, public domSanitizer: DomSanitizer) {


    }

    ngOnChanges() {

        this.isInTab = !this.inSideBar && this.ref[0] !== AssetDisplayType.EuToc;
        this.asset = this.ref ? this.assetService.getCachedAsset(this.ref) : null;
        this.tocContent = this.asset ? { ...this.asset.content as TocContent } : null;

        if (this.tocContent && this.tocContent.additionalInfo && this.tocContent.additionalInfo.forcedShow && !this.showLowMeta) {
            this.toggleLowMeta();
        }

        if (this.ref && !this.asset) {

            if (this.assetSubscription) {
                this.assetSubscription.unsubscribe();
            }

            this.assetSubscription = this.assetService.getAsset(this.ref).subscribe(asset => {
                this.asset = asset;
                this.tocContent = this.asset ? { ...this.asset.content as TocContent } : null;
                if (this.tocContent && this.tocContent.additionalInfo && this.tocContent.additionalInfo.forcedShow && !this.showLowMeta) {
                    this.toggleLowMeta();
                }
                this.changeDetectorRef.detectChanges();

            });
        }

        Promise.resolve(null).then(() => { this.changeDetectorRef.detectChanges(); });
        this.isEuToc = this.ref && this.ref[0] === AssetDisplayType.EuToc;
    }

    ngOnDestroy() {
        if (this.assetSubscription) {
            this.assetSubscription.unsubscribe();
        }
    }

    public openNewEdition(idToOpen: string, event: MouseEvent) {
        const openInPrimary = this.inSideBar || !this.isPrimary;
        this.assetService.openAsset(AssetRef.create(AssetDisplayType.Book,idToOpen), openInPrimary, event);
    }


    getBookUrl(): string {
        if (!this.tocContent.bookCrossSellingInfo || this.tocContent.bookCrossSellingInfo.bookShopLink === null || this.tocContent.bookCrossSellingInfo.bookShopLink === '' || '') {
            return '';
        }

        return  this.tocContent.bookCrossSellingInfo.langParameterPrefix
            ? `${this.tocContent.bookCrossSellingInfo.bookShopLink}${this.tocContent.bookCrossSellingInfo.langParameterPrefix}=${this.translateService.currentLang}`
            : this.tocContent.bookCrossSellingInfo.bookShopLink;
    }

    getLogoUrl(): string {
        return this.tocContent.logoInfo ? `${config.apiBaseUrl}${this.tocContent.logoInfo.logoUrl}` : undefined;
    }

    toggleLowMeta() {
        debug('toggle low meta');
        if (this.showLowMeta) {
            this.showLowMeta = false;
            this.showLowMetaText = 'rech-asset-showLowMetaTextMore';
        } else {
            this.showLowMeta = true;
            this.showLowMetaText = 'rech-asset-showLowMetaTextLess';
        }
    }

}
