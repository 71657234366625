import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { AssetService } from '../asset';
import { catchErrorForAction, makeAction } from '../models';
import { AlertType } from '../models/state/commonApp';

import { BibliothekActions } from './bibliothekActions';


@Injectable()
export class BibliothekEffects {

    @Effect() loadEuVerzeichnisse = this.actions$.pipe(
        ofType(BibliothekActions.load_eu_verzeichnisse.name),
        switchMap((action) =>
            this.assetService.getEuTocItems().pipe(
                map(payload => makeAction({ result: BibliothekActions.load_eu_verzeichnisse.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: null })
            )
        )
    );

    constructor(private actions$: Actions, private assetService: AssetService) {
    }
}
