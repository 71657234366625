import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

import { TooltipData } from '../../../models';
import { HomeService } from '../../home.service';

@Component({
  selector: 'slx-events-tile',
  templateUrl: './eventsTile.component.html',
  styleUrls: ['./eventsTile.component.scss'],
})
export class EventsTileComponent implements OnInit {

  constructor(public homeService: HomeService) { }

  ngOnInit() {
    this.homeService.getEventsForHomeTile();
  }

  createTooltipData(event: any) : Observable<TooltipData> {
    return of({ tooltipBody: event.shortText, tooltipTitle: event.title});
  }

}
