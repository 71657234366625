export enum SearchTabType {
    'search' = 0,
    'eulaws' = 1,
    'journal' = 2,
    'casePub' = 3,
    'caseNonPub' = 4,
    'commentary' = 5,
    'book' = 6,
    'author' = 7,
    'law' = 8,
    'eudirect' = 9,
}
