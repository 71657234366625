// only imports from models allowed, all others will be circular
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { AssetDisplayType, AssetRef, AssetRefOptions, getLanguageIndependantUrl, HitlistType, RefType } from './models';
// `npm run start:local` to run against local server

const debug = require('debug')('config');

/**
 * DOMAIN NAME for the API
 */
export const apiBaseUrl = (function () {
    switch (process.env.SLX_BACKEND) {
        case 'local':
            return 'http://local.swisslex.ch';

        case 'dev':
        case 'development':
            return 'https://dev.swisslex.ch';

        case 'int':
        case 'integration':
            return 'https://int.swisslex.ch'; //doesn't exist yet

        case 'prod':
        case 'production':
            return 'https://www.swisslex.ch';

        case 'deployed':
        default:
            return ''; // makes all AJAX URLs relative
    }
})();

export const apiAuthentificationUrl = (function () {
    switch (process.env.SLX_BACKEND) {
        case 'local':
            return 'http://local.swisslex.ch/auth';

        case 'dev':
        case 'development':
            return 'https://dev.swisslex.ch/auth';

        case 'int':
        case 'integration':
            return 'https://int.swisslex.ch/auth'; //doesn't exist yet

        case 'prod':
        case 'production':
            return 'https://www.swisslex.ch/auth';

        case 'deployed':
        default:
            return '/auth'; // makes all AJAX URLs relative
    }
})();

export const historyRecentHours = 24;
export const historyMaxNumberOfTransactions = 100;
export const releaseNumber = '2';
export const repositoryLogoHits = '/Content/LogoHits/';
export const newsletterHitlistPagingSize = 100;
export const maxItemsInFrontendCache = 50;
export const maxOpenAssets = maxItemsInFrontendCache - 10;
export const maxAccesTokenFails = 5;
export const maxWaitForRefreshInSeconds = 30;
export const maxRefreshTries = 3;
/**
 * Sentry
 * key@trycatch.swisslex.ch + project number
 */
//export const sentryBaseUrl = 'http://2486b901c1a948b2826adf089d99f11e@10.38.20.26:8080/2';
export const sentryBaseUrl = 'https://2486b901c1a948b2826adf089d99f11e@trycatch.swisslex.ch/2';


/**
 * Accessing ASSET on API DOMAIN
 *
 * [AssetRefType.{Document Type}, docID]
 * [AssetRefType.{Toc Type}, publicationID, AssetRefType.{Document Type}, docID]
 * [AssetRefType.Preview, previewID]
 * [AssetRefType.Search, transactionID]
 * [AssetRefType.Newsletter, newsID]
 */

export function getAssetUrlNoSource(ref: AssetRef) {
    switch (ref[0]) {
        // ToC
        case AssetDisplayType.Book:
        case AssetDisplayType.PeriodicalCollection:
        case AssetDisplayType.PeriodicalPublication:
        case AssetDisplayType.UnknownPublication:
            return `/toc/${ref[0]}/${ref[1]}`;

        // Documents
        case AssetDisplayType.Undefined:
        case AssetDisplayType.MissingDocument:
        case AssetDisplayType.EuCaseLawDocument:
        case AssetDisplayType.EuLawDocument:
        case AssetDisplayType.EuDoc:
        case AssetDisplayType.LawDocument:
        case AssetDisplayType.LawText:
        // case AssetDisplayType.DocumentCH:
        case AssetDisplayType.CaseLawDocument:
        case AssetDisplayType.CaseLawReviewDocument:
        case AssetDisplayType.OfficialPupDocument:
        case AssetDisplayType.CommentaryDocument:
        case AssetDisplayType.EssayDocument:
        case AssetDisplayType.BookDocument:
        case AssetDisplayType.CommentaryDocument:
        case AssetDisplayType.UnknownDocument:
            const hitlistIndex = ref.findIndex(r => RefType.isHitlist(r as RefType));
            if (hitlistIndex > 0) {
                return `/doc/${ref[0]}/${ref[1]}/${ref[hitlistIndex]}/${ref[hitlistIndex + 1]}`;
            }
            return `/doc/${ref[0]}/${ref[1]}`;
        // ArticelOfLaw
        case AssetDisplayType.ArticleOfLawDocument:
            return `/doc/${ref[0]}/${ref[1]}/${ref[3]}`;
        // Preview
        case AssetDisplayType.Previews:
        case AssetDisplayType.PreviewLaw:
        case AssetDisplayType.PreviewDoc:
            return `/doc/${ref[0]}/${ref[1]}`;


        case HitlistType.Search:
        case HitlistType.News:
            return `/recherche/${ref[0]}/${ref[1]}`;

        default:
            return '/doc/unknown';
    }
}

export function getAssetURL(ref: AssetRef, language? : string) {
    const prefix =  language ? `/${language}` : '';
    let url = prefix + getAssetUrlNoSource(ref);
    const sourceIndex = ref.findIndex(r => r === AssetRefOptions.sourceDetail);
    if (sourceIndex > -1) {
        url = `${url}/${ref[sourceIndex]}/${ref[sourceIndex + 1]}`;
    }
    return url;
}

const urlPrefixToMainTab = {
    // 'recherche':'rech'
};

export function registerRoute(routes, tabName) {
    (Array.isArray(routes) ? routes : [routes]).forEach(route => {
        const prefix = route.path.split('/')[0];
        debug('route', `prefix=${prefix}`, tabName);
        urlPrefixToMainTab[prefix] = tabName;
    });
}

export function getMainTabFromUrl(path: string) {
    path = getLanguageIndependantUrl(path).relativeUrl;
    const parts = path.split('/');
    return urlPrefixToMainTab[parts[0] || parts[1]] || 'home';
}

// TODO this could be defined with route registration
export function routeNeedsLogin(url: string): boolean {
    const splitUrl = url.split('/'), firstPart = splitUrl[0] || splitUrl[1];
    switch (firstPart) {
        case 'biblio':
        case 'jobs':
        case 'events':
        case 'service':
        case 'about':
        case 'faq':
        case '':
            // info
            return false;

        // account without password
        case 'account':
            return splitUrl[2] === 'password' || splitUrl[2] === 'forgotpassword' ? false : true;

        // doc toc recherche news
        default:
            return true;
    }
}
