import { Component } from '@angular/core';

import { HomeService } from './../home';
import { SingleJobComponent } from './singleJob/singleJob.component';

@Component({
    selector: 'slx-jobs',
    templateUrl: './jobs.component.html',
    host: { 'class': 'sidebar-layout' },
    styleUrls: ['./jobs.component.scss'],
})

export class JobsComponent {
    public singleJobActive = false;

    constructor(public homeService: HomeService) { }

    public onRouterOutletActivate(event: any) {
        this.singleJobActive = event instanceof SingleJobComponent ? true : false;
    }
}
