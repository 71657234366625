import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { AppActions } from '../appActions';
import { AppState, makeAction } from '../models';
import { AlertType } from '../models/state/commonApp';

@Injectable()
export class NotFoundResolver implements Resolve<boolean> {
    constructor(private store: Store<AppState>) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        this.store.dispatch(makeAction({
            error: 'site-page-not-found',
            alert: {
                type: AlertType.Warning,
                key: 'site-page-not-found',
                duration: 10,
                url: window.location.href.substring(window.location.origin.length,100)},
            })
        );

        // immediate render of the component
        return true;
    }
}

