
import { AssetDisplayType, PlaceHolderEnum } from '../enums';

import { AssetCitation } from './documentContent';

export class AssetCiting {
    citedAssetID: string;
    citationGroups: CitationGroup[];
}

export class RelatedAssetsModel {
    assetID: string;
    assetListGroups: GroupedAssetList[];
}

export class NewerCommentModel {
    assetID: string;
    displayString: string;
}

export class GroupedAssetList {
    groupName: string;
    totalCount: number;
    entries: AssetList[];
    article?: string;
    lawAbbr?: string;
}


export class AssetList {
    toggled = false;
    publicationName: string;
    listEntries: AssetListEntry[];
    maxHeight: number;
}

export class CitationGroup {
    type: string;
    totalCount: number;
    entries: AssetListGeneric<AssetCitation[]>;
}

export class AssetListGeneric<T> {
    toggled = false;
    publicationName: string;
    listEntries: Array<T>;
    maxHeight: number;
}

export class AssetListEntry {
    displayString: string;
    assetID: string;
    assetDisplayType: AssetDisplayType;
    anchor: string;
    citationID: string;
}
// export class CitationItem {
//     toggled = false;
//     publicationName: string;
//     citations: AssetCitation[];
//     maxHeight: number;
// }

// For UserSelction
export interface CitingInfromation {
    footNoteNumber: string;
    pageNumber: string;
    marginNumber: string;
    consNumber: string;
    textSelection: string;
    suffixForMultielments: Map<PlaceHolderEnum,string>;
}
export interface UserCiting {
    assetID: string;
    position: { left: number, top: number };
    citingInformation: CitingInfromation;
}

export interface CitationDisplayInfo {
    text: string;
    placeHolder: string;
    prefix: string;
}
