import { Job } from './job';

export * from './job';

export interface JobsState {
    jobList: Job[];
    topJobList: Job[];
    currentJob: Job;
}

export const defaultJobState = {
    jobList: [],
    topJobList: [],
    currentJob: null,
};
