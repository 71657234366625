import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms'; // FormBuilder, Validators
import { TranslateService } from '@ngx-translate/core';

import { AutocompleteService } from '../../../asset';
import { requiredValidator } from '../../../field';
import { AutocompleteParamter, AutocompleteType, SearchType } from '../../../models';
import { SearchService } from '../../search.service';
import { SearchFormBase } from '../search-form-base';

@Component({
    selector: 'slx-book',
    template: `<slx-search-form *ngIf="formDescription && searchForm" [formDescription]="formDescription" [searchForm]="searchForm" (searchSubmitted)="onSubmit()" (searchReset)="onReset()"></slx-search-form>`,
})
export class BookComponent extends SearchFormBase {

    private serialIsSelectionFromDropdown: Boolean = false;
    private titleIsSelectionFromDropdown: Boolean = false;
    private authorIsSelectionFromDropdown: Boolean = false;

    constructor(protected searchService: SearchService, protected autocompleteService: AutocompleteService, protected formBuilder: FormBuilder, protected changeDetectorRef: ChangeDetectorRef, protected translate: TranslateService) {
        super(searchService, SearchType.Book,changeDetectorRef, translate);
        // bind autocomplete get url functions (this)
        this.getNonLawBookSerialAutocomplete = this.getNonLawBookSerialAutocomplete.bind(this);
        this.getNonLawBookTitleAutocomplete = this.getNonLawBookTitleAutocomplete.bind(this);
        this.getNonLawBookAuthorAutocomplete = this.getNonLawBookAuthorAutocomplete.bind(this);
        this.getNonLawBookPageAutocomplete = this.getNonLawBookPageAutocomplete.bind(this);

        // Create searchForm with Formbuilder
        this.searchForm = this.formBuilder.group({
            serial: [''],
            title: [''],
            author: [''],
            page: [''],
        });

        this.controlDependencies = ['serial','title','author','page'];

        this.formDescription = {
            formGroups: [
                {
                    controls: [
                        {
                            control: 'slx-text', isSelectionFromDropdown: this.handleSerialIsSelectionFromDropdown, name: 'Serial', type: 'text', placeholder: 'rech-series', formControlName: 'serial',
                            makeAutocomplete: this.getNonLawBookSerialAutocomplete, autocompleteMinQueryLength: 0, textChanged: this.resetDependencies('serial'),
                        },
                        {
                            control: 'slx-text', isSelectionFromDropdown: this.handleTitleIsSelectionFromDropdown, name: 'Title', type: 'text', placeholder: 'rech-title', formControlName: 'title',
                            makeAutocomplete: this.getNonLawBookTitleAutocomplete, autocompleteMinQueryLength: 0, textChanged: this.resetDependencies('title'),
                        },
                        {
                            control: 'slx-text', isSelectionFromDropdown: this.handleAuthorIsSelectionFromDropdown, name: 'Author', type: 'text', placeholder: 'rech-author', formControlName: 'author',
                            makeAutocomplete: this.getNonLawBookAuthorAutocomplete, autocompleteMinQueryLength: 0, textChanged: this.resetDependencies('author'),
                        },
                        {
                            control: 'slx-text', name: 'Page', type: 'text', placeholder: 'rech-pages', formControlName: 'page',
                            makeAutocomplete: this.getNonLawBookPageAutocomplete, autocompleteMinQueryLength: 0,
                        },
                    ],
                },
            ],
            controls: {
                resetControl: true,
            },
            searchButtonText: 'rech-display',
        };

        this.validationKeys = [
            'author',
            'title',
            'serial',
        ];
    }

    handleSerialIsSelectionFromDropdown(selection) {
        this.serialIsSelectionFromDropdown = selection;
    }
    handleTitleIsSelectionFromDropdown(selection) {
        this.titleIsSelectionFromDropdown = selection;
    }
    handleAuthorIsSelectionFromDropdown(selection) {
        this.authorIsSelectionFromDropdown = selection;
    }

    getNonLawBookSerialAutocomplete() {
        return () => {
            const parameters = [
                new AutocompleteParamter('serial', this.searchForm.value.serial),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.BookSerial, true, parameters);
        };
    }

    getNonLawBookTitleAutocomplete() {
        return () => {
            const parameters = [
                new AutocompleteParamter('serial', this.searchForm.value.serial),
                new AutocompleteParamter('serialIsFromSelection', this.serialIsSelectionFromDropdown.toString()),
                new AutocompleteParamter('title', this.searchForm.value.title),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.BookTitle, true, parameters);
        };
    }

    getNonLawBookAuthorAutocomplete() {
        return () => {
            const parameters = [
                new AutocompleteParamter('serial', this.searchForm.value.serial),
                new AutocompleteParamter('serialIsFromSelection', this.serialIsSelectionFromDropdown.toString()),
                new AutocompleteParamter('title', this.searchForm.value.title),
                new AutocompleteParamter('titleIsFromSelection', this.titleIsSelectionFromDropdown.toString()),
                new AutocompleteParamter('author', this.searchForm.value.author),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.BookAuthor, true, parameters);
        };
    }

    getNonLawBookPageAutocomplete() {
        return () => {
            const parameters = [
                new AutocompleteParamter('serial', this.searchForm.value.serial),
                new AutocompleteParamter('serialIsFromSelection', this.serialIsSelectionFromDropdown.toString()),
                new AutocompleteParamter('title', this.searchForm.value.title),
                new AutocompleteParamter('titleIsFromSelection', this.titleIsSelectionFromDropdown.toString()),
                new AutocompleteParamter('author', this.searchForm.value.author),
                new AutocompleteParamter('authorIsFromSelection', this.authorIsSelectionFromDropdown.toString()),
                new AutocompleteParamter('page', this.searchForm.value.page),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.BookPage, true, parameters);
        };
    }
}
