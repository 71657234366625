import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ColumnApi, GridApi, GridOptions, GridReadyEvent, RowClickedEvent } from 'ag-grid-community';
import { Observable, Subscription } from 'rxjs';

import { AssetDisplayType, BiblioType, SourceDetail } from '../../models';
import { createHrefWrapperForCellrender } from '../../utility/utilityFunctions';
import { BibliothekService } from '../bibliothek.service';

const debug = require('debug')('biblio');

@Component({
    selector: 'slx-book-bibliothek',
    template: `
        <slx-grid
            [options] = "gridOptions"
            [columnDefs] = "columnDefs"
            [rowTranslatePrefix]="'biblio'"
            [rowData]="bookList"
            [isBiblio]="true"
            [onGridReady]="onGridReady"
            (rowClicked)="onRowClicked($event)">
        </slx-grid>
    `,
    styleUrls: ['./bookBibliothek.component.scss'],
})
export class BookBibliothekComponent implements OnInit {

    public biblioType: BiblioType;
    public bookList: Observable<any>;
    public gridOptions: GridOptions;
    public columnDefs: Array<any>;
    public gridApi: GridApi;
    public gridColumnApi: ColumnApi;

    private cacheClearedSubscribtion: Subscription;
    private initialCategorie: string;

    private categories = {
        'neue-werke': '0.',
        'staats-und-verwaltungsrecht': '1.',
        'privatrecht-zgb': '2.',
        'privatrecht-or': '3.',
        'gesellschaftsrecht': '4.',
        'verfahrensrecht': '5.',
        'datenschutz': '6.',
        'haftpflicht': '7.',
        'bankundfinanzmarktrecht': '8.',
        'steuerrecht': '9.',
        'strafundstrafprozessrecht': '10.',
        'arbeitsundsozialversicherungsrechtgesundheitsrecht': '11.',
        'kommunikationstransportundverkehrsrecht': '12.',
        'raumplanungsundbaurechtumweltrechtenergierecht': '13.',
        'iprinternationalerechtshilfestaatsvertragsrecht': '14.',
        'landesverteidigung': '15.',
        'rechtsgeschichterechtsphilosophie': '16.',
        'eurecht': '17.',
    };

    constructor(public bibliothekService: BibliothekService, private route: ActivatedRoute, private translate: TranslateService) {
        this.onRowClicked = this.onRowClicked.bind(this);
        this.defaultGroupSortComparator = this.defaultGroupSortComparator.bind(this);
        this.titleCellRenderer = this.titleCellRenderer.bind(this);
        this.onGridReady = this.onGridReady.bind(this);

        this.gridOptions = <GridOptions>{
            headerHeight: 50,
            rowHeight: 50,
            rowClass: 'singleTitleRow',
            groupUseEntireRow: true,
            defaultGroupSortComparator: this.defaultGroupSortComparator,
        };
    }

    ngOnInit(): void {

        this.columnDefs = [
            { headerName: 'Categorie', field: 'categorie', suppressMenu: true, rowGroup: true, hide: true },
            { headerName: 'Title', field: 'title', suppressMenu: true, minWidth: 300, cellStyle: { 'padding-left': '4rem' }, cellRenderer: this.titleCellRenderer },
            { headerName: 'Author / Editor', field: 'author', minWidth: 150, maxWidth: 300, suppressMenu: true },
            { headerName: 'Edition', field: 'edition', minWidth: 80, maxWidth: 80, suppressMenu: true },
            { headerName: 'Year', field: 'year', minWidth: 80, maxWidth: 80, suppressMenu: true },
            { headerName: 'Publisher', field: 'publisher', minWidth: 150, maxWidth: 250, suppressMenu: true },
        ];

        this.initialCategorie = this.categories[this.route.snapshot.queryParams['categorieInitial']];
        const path = this.route.snapshot.routeConfig.path;

        switch (path) {
            case 'books':
                this.biblioType = BiblioType.Books;
                break;
            case 'commentaries':
                this.biblioType = BiblioType.Commentaries;
                break;
            case 'serials':
                this.biblioType = BiblioType.Serials;
                this.columnDefs.push({ headerName: 'Volume', field: 'volume', minWidth: 80, maxWidth: 80, suppressMenu: true });
                break;
            default:
                debug('unknown path', path);
        }

        this.bookList = this.bibliothekService.getBooks(this.biblioType);
        this.cacheClearedSubscribtion = this.bibliothekService.cacheCleared.subscribe(cacheCleared => this.bookList = this.bibliothekService.getBooks(this.biblioType));
    }

    //-------------------------------------------------------------------------------------
    // Formatters, Comperators, CellRenderers Header Translations
    //-------------------------------------------------------------------------------------

    private titleCellRenderer(params: any): HTMLElement {
        return createHrefWrapperForCellrender(params.value, `/toc/book/${params.data.publicationId}/source/${this.biblioSource}`);
    }

    private getSortableValue(node) {
        if (this.biblioType === BiblioType.Serials) {
            const splitted = node.key.toLowerCase().split(' - ');
            return splitted.length > 1 ? splitted[1] : splitted[0];
        }
        return parseFloat(node.key.split('.')[0]);
    }

    public defaultGroupSortComparator(nodeA, nodeB) {

        const valA = this.getSortableValue(nodeA);
        const valB = this.getSortableValue(nodeB);

        if (typeof valA === 'string') {
            return valA.localeCompare(valB);
        } else {
            return (valA > valB ? 1 : (valA < valB ? -1 : 0));
        }
    }

    onGridReady(params: GridReadyEvent) {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        if (this.initialCategorie) {
            setTimeout(() => params.api.forEachNode(node => {
                if (node.key && node.key.startsWith(this.initialCategorie)) {
                    node.setExpanded(true);
                }
            }),500);
        }
    }

    //-------------------------------------------------------------------------------------
    // Events & Listeners
    //-------------------------------------------------------------------------------------

    public get biblioSource(): string {
        switch (this.biblioType) {
            case BiblioType.Books:
                return SourceDetail.BooksBiblio;
            case BiblioType.Commentaries:
                return SourceDetail.CommentariesBiblio;
            case BiblioType.Serials:
                return SourceDetail.SerialsBiblio;
            default:
                return SourceDetail.Biblio;
        }
    }

    public onRowClicked(ev: RowClickedEvent): void {
        const mouseEvent = ev.event as MouseEvent;
        if (!mouseEvent || !mouseEvent.ctrlKey) {
            if (ev.data) {
                this.bibliothekService.openToc(ev.data.publicationId, AssetDisplayType.Book, this.biblioSource);
            }
            else {
                ev.node.setExpanded(!ev.node.expanded);
            }
        }
    }
}
