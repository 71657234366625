import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoginService } from '../../access';
import { AccountService } from '../../account';
import { AssetDisplayType, AssetRef, SourceDetail } from '../../models';
import { BibliothekService } from '../bibliothek.service';


@Component({
    selector: 'slx-eu-verzeichnisse',
    templateUrl: './euVerzeichnisse.component.html',
    styleUrls: ['./euVerzeichnisse.component.scss'],
})
export class EuVerzeichnisseComponent implements OnDestroy {

    private subscription = new Subscription();

    public euTocRef: AssetRef = AssetRef.create(AssetDisplayType.EuToc, '', { source: SourceDetail.EuBiblio });
    public hasEuAccess = false;

    constructor(private loginService: LoginService, public accountService: AccountService, public bibliothekService: BibliothekService) {
        this.subscription.add(
            accountService.onLangChange.subscribe(res => {
                this.bibliothekService.loadEuVerzeichnisse();
            })
            .add(
                loginService.hasEu.subscribe(hasEu => {
                    this.hasEuAccess = hasEu;
                })
            )
        );
    }

    get noEuAccessText(): string {
        return 'please-login-standard';
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
