import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'clickable' })
export class ClickablePipe implements PipeTransform {
    transform(item, primaryCurrentAssetID: string, secondaryCurrentAssetID: string): boolean {
        return item.targetID === primaryCurrentAssetID || item.targetID === secondaryCurrentAssetID;
    }
}

@Pipe({ name: 'isActive' })
export class IsActivePipe implements PipeTransform {
    transform(item, primaryCurrentAssetID: string, secondaryCurrentAssetID: string): boolean {
        if (item.targetID) {
            return item.targetID === primaryCurrentAssetID || item.targetID === secondaryCurrentAssetID;
        } else if (item.publicationID) {
            return item.publicationID === primaryCurrentAssetID || item.publicationID === secondaryCurrentAssetID;
        }
    }
}

@Pipe({ name: 'loadingSpinnerFiltersShown' })
export class LoadingSpinnerFiltersShownPipe implements PipeTransform {
    transform(filter, searchInProgress): boolean {
        if (filter.language) {
            return searchInProgress && searchInProgress.languageValue === filter.language.value;
        } else if (filter.assetTypeGroup) {
            return searchInProgress && searchInProgress.assetTypeGroupValue === filter.assetTypeGroup.value;
        }
    }
}

