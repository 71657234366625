module.exports = {
	"about-company": "Entreprise",
	"about-shareholders": "Actionnariat",
	"about-board": "Direction",
	"about-team": "Team",
	"about-direction": "Plan d'accès",
	"about-contact": "Contact",
	"about-question": "Des questions?",
	"about-answers": "Notre hotline répond à vos questions.",
	"about-contact-address": "Adresse postale",
	"about-contact-free": "(gratuit)",
	"about-contact-abroad": "(pour les appels depuis l'étranger)",
	"about-contact-hotlineTeam": "Notre hotline est à votre disposition les jours ouvrables entre 08h30 et 17h00.",
	"about-contact-formEmail": "Courrier électronique",
	"about-contact-message": "Votre message",
	"about-contact-email": "Courrier électronique",
	"about-contact-number": "Téléphone",
	"about-contact-validation-email-invalid": "Adresse électronique non valide",
	"about-contact-form-successful": "Message envoyé avec succès",
	"about-contact-form-error": "Une erreur est survenue lors de l'envoi de votre message.",
	"about-contact-enter": "Envoyer"
};