import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { endOfDay, format, isDate, isValid } from 'date-fns';
import * as deLocale from 'date-fns/locale/de';
import * as frLocale from 'date-fns/locale/fr';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import * as xlsx from 'xlsx';

import { LoginService } from '../../access';
import { AccountService } from '../../account';
import { CustomerProfileActions } from '../../customer-profile/customerProfileActions';
import { requiredValidator } from '../../field';
import { AccessState, TransactionType } from '../../models';
import { SearchService } from '../../recherche';
import { toDate } from '../../utility/date-format';
import { createHrefWrapperForCellrender, markFormAsTouched } from '../../utility/utilityFunctions';


@Component({
    selector: 'slx-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit, OnDestroy {

    private subscription: Subscription = new Subscription();
    private activeRowGroups: any[];

    public tSearchCollapsed = false;

    public statisticsForm: FormGroup;
    public minDate: any;

    public isCorpAdmin: boolean;
    public isDepartmentAdmin: boolean;
    public isSuperUser: boolean;
    public isJointVentureAdmin: boolean;
    public heightOffset: number;

    private accessStateData: AccessState;
    private currentLocale;
    public maxDate: any;

    public nbTransactions = 0;
    public totalChf = 0;

    public gridOptions: GridOptions;
    public rowDataObservable: Observable<any[]>;
    public columnDefs: any[];
    public rowCount: string;
    public autoGroupColumnDef;

    private gridApi: GridApi;
    private gridColumnApi: ColumnApi;
    private initGrid = true;
    public overlayNoRowsTemplate;

    public listPriceShown = false;

    public transactions = [];
    public allTransactions = [
        { key: '', value: 'All' },
        { key: 'Search', value: 'Search' },
        { key: 'RefineSearch', value: 'RefineSearch' },
        { key: 'DocView', value: 'DocView' },
        { key: 'Find', value: 'Find' },
        { key: 'TocView', value: 'TocView' },
        { key: 'LawView', value: 'LawView' },
    ];

    public limitedTransactions = [
        { key: '', value: 'All' },
        { key: 'Search', value: 'Search' },
        { key: 'RefineSearch', value: 'RefineSearch' },
        { key: 'DocView', value: 'DocView' },
    ];

    public order = [
        { key: '-', value: '-' },
        { key: 'createdAtYear', value: 'account-stats-year' },
        { key: 'createdAtMonth', value: 'account-stats-month' },
        { key: 'createdAtDay', value: 'account-stats-day' },
        { key: 'createdAtHour', value: 'account-stats-hour' },
        { key: 'type', value: 'account-stats-tx-type' },
        { key: 'publication', value: 'account-stats-pub' },
    ];

    public locales = {
        de: deLocale,
        fr: frLocale,
    };

    public gridInProgress: Observable<boolean> = this.accountService.isActionInProgress(CustomerProfileActions.trx_statistics.name);

    constructor(private loginService: LoginService, private formBuilder: FormBuilder, public accountService: AccountService, public translate: TranslateService, public searchService: SearchService) {
        this.onGridReady = this.onGridReady.bind(this);
        this.titleCellRenderer = this.titleCellRenderer.bind(this);
        this.renderGroupRow = this.renderGroupRow.bind(this);
        this.accountStatsRequired = this.accountStatsRequired.bind(this);

        this.maxDate = new Date();
        this.minDate = new Date(this.maxDate.getFullYear() - 2, 0, 1);

        this.rowDataObservable = this.accountService.transactionData.pipe(map(result => {
            if (result) {
                const data = result.baseTransactionGroup.allTransactions as Array<any>;
                this.nbTransactions = data.length;
                this.totalChf = 0;
                data.forEach(element => {
                    this.totalChf += element.transactionPrice as number;
                });
                this.tSearchCollapsed = true;
                return data;
            } else {
                return [];
            }
        }));

        this.statisticsForm = this.formBuilder.group({
            // For the time beeing, we disable picking a date on start, so the user has to make a selection
            // dateFrom: [toISODateString(this.dateFromDefault), requiredValidator('account-statistics-validation-date')],
            dateFrom: ['', requiredValidator()],
            dateUntil: [''],
            transactionType: [''],
            companyNumber: [''],
            departmentName: [''],
            userName: [''],
            firstOrderKey: ['type'],
            secondOrderKey: ['-'],
            showZeroPrices: [false],
        }, {
                validators: this.accountStatsRequired,
            });

        this.gridOptions = <GridOptions>{
            enableSorting: true,
            enableFilter: true,
            suppressContextMenu: true,
            enableColResize: true,
            headerHeight: 50,
            rowHeight: 50,
            groupMultiAutoColumn: true,
            showToolPanel: false,
            rowGroupPanelShow: 'never',
            suppressAggFuncInHeader: true,
            groupRowInnerRenderer: this.renderGroupRow,
            groupUseEntireRow: true,
            defaultGroupSortComparator: function (nodeA, nodeB) {
                if (nodeA.field === 'publication' || nodeA.field === 'publisher') {
                    if (nodeA.key === '') {
                        return 1;
                    }
                    if (nodeB.key === '') {
                        return -1;
                    }
                    return nodeA.key.localeCompare(nodeB.key);
                }
                return 0;
            },
            groupRowRendererParams: {
                suppressCount: true,
            },
        };

        // this.autoGroupColumnDef = { suppressMenu: true, enableColResize: true, minWidth: 200};
        this.columnDefs = this.createColumnDefs();
    }

    private renderGroupRow(params) {
        return `<div style="display: flex; justify-content: space-between;">
            <span>${params.valueFormatted ? params.valueFormatted : params.value}</span>
            <span>
                <span>${params.node.allChildrenCount} ${this.translate.instant('account-stats-tx')}</span>
                <span style="display: ${this.listPriceShown ? 'inline-block' : 'none'};"> / ${params.node.aggData.transactionPrice} CHF</span>
            </span>
        </div>`;
    }

    ngOnInit() {
        this.subscription
            .add(this.accountService.store.select(state => state.access).subscribe(data => this.accessStateData = data))
            .add(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                this.currentLocale = this.locales[this.accountService.lang];
                this.gridApi.redrawRows();
            }))
            .add(forkJoin(
                this.loginService.isDepartmentAdmin.pipe(first()),
                this.loginService.isCorpAdmin.pipe(first()),
                this.loginService.isJointVentureAdmin.pipe(first()),
                this.loginService.isSuperUser.pipe(first())).subscribe(([isDep, isCorp, isVent, isSup]) => {
                    this.isDepartmentAdmin = isDep;
                    this.isCorpAdmin = isCorp;
                    this.isJointVentureAdmin = isVent;
                    this.isSuperUser = isSup;

                    this.setUserData();
                    this.setUserName();
                }));

        this.transactions = this.allTransactions;
        this.currentLocale = this.locales[this.accountService.lang];
    }

    public onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        if (this.initGrid) {
            this.gridColumnApi.setRowGroupColumns(['type']);
            this.gridColumnApi.setValueColumns(['transactionPrice']);
            this.initGrid = false;
        }
    }

    public collapseTSearch() {
        this.tSearchCollapsed = !this.tSearchCollapsed;
    }

    private setUserName() {
        if (this.isCorpAdmin) {
            return;
        }
        if (this.isSuperUser) {
            this.statisticsForm.controls['userName'].setValue(this.accessStateData.userId);
        } else if (this.isDepartmentAdmin) {
            this.statisticsForm.controls['userName'].setValue(this.accessStateData.username + ' (' + this.accessStateData.lastName + ', ' + this.accessStateData.firstName + ')');
        }
    }

    private setUserData() {
        if (this.isDepartmentAdmin) {
            this.order.push({ key: 'user', value: 'account-stats-user' });
        }

        if (this.isCorpAdmin) {
            this.order.push({ key: 'departmentName', value: 'account-stats-dept' });
            this.statisticsForm.controls['firstOrderKey'].setValue('user');
        }

        if (this.isJointVentureAdmin) {
            this.order.push({ key: 'publisher', value: 'account-stats-publ' });
        } else {
            this.transactions = this.limitedTransactions;
        }
    }

    private createColumnDefs() {
        return [
            {
                headerName: 'account-stats-date', field: 'createdAt', minWidth: 105, maxWidth: 105,
                valueFormatter: (params) => params.value ? format(params.value, 'DD.MM.YYYY') : '',
                suppressMenu: true, suppressMovable: true, suppressNavigable: true, editable: false,
            },
            {

                headerName: 'account-stats-year', field: 'createdAtYear',
                hide: true,
                valueFormatter: (params) => params.value ? format(params.value, 'YYYY') : '',
                enableRowGroup: true, editable: false, menuTabs: [], suppressMovable: true, suppressNavigable: true, suppressMenu: true,
            },
            {
                headerName: 'account-stats-month', field: 'createdAtMonth',
                hide: true,
                valueFormatter: (params) => params.value ? format(params.value, 'MM.YYYY (MMMM)', { locale: this.currentLocale }) : '',
                enableRowGroup: true, editable: false, menuTabs: [], suppressMovable: true, suppressNavigable: true, suppressMenu: true,
            },
            {
                headerName: 'account-stats-day', field: 'createdAtDay',
                hide: true,
                valueFormatter: (params) => params.value ? format(params.value, 'DD.MM.YYYY (dddd)', { locale: this.currentLocale }) : '',
                enableRowGroup: true, editable: false, menuTabs: [], suppressMovable: true, suppressNavigable: true, suppressMenu: true,
            },
            {
                headerName: 'account-stats-time', field: 'createdAtTime', minWidth: 80, maxWidth: 80,
                valueFormatter: (params) => params.value ? format(params.value, 'HH:mm') : '',
                editable: false, menuTabs: [], suppressMovable: true, suppressNavigable: true, suppressMenu: true,
            },
            {
                headerName: 'account-stats-hour', field: 'createdAtHour',
                valueFormatter: (params) => params.value ? format(params.value, 'DD.MM.YYYY HH:mm (dddd)', { locale: this.currentLocale }) : '',
                enableRowGroup: true, hide: true, editable: false, menuTabs: [], suppressMovable: true, suppressNavigable: true, suppressMenu: true,
            },
            {
                field: 'type', headerName: 'account-stats-tx-type', minWidth: 120, maxWidth: 120,
                valueFormatter: (params) => TransactionType[params.value],
                enableRowGroup: true, editable: false, menuTabs: [], suppressMovable: true, suppressNavigable: true, suppressMenu: true,
            },
            {
                field: 'user', headerName: 'account-stats-user', minWidth: 90,
                colId: 'user', filter: 'agTextColumnFilter', enableRowGroup: true, editable: false, menuTabs: [], suppressMovable: true, suppressNavigable: true, suppressMenu: true,
            },
            {
                field: 'departmentName', headerName: 'account-stats-dept', minWidth: 120,
                filter: 'agTextColumnFilter', enableRowGroup: true, editable: false, menuTabs: [], suppressMovable: true, suppressNavigable: true, suppressMenu: true,
            },
            {
                field: 'title', headerName: 'account-stats-ref-pub', minWidth: 120,
                filter: 'agTextColumnFilter', enableRowGroup: true, editable: false, menuTabs: [], suppressMovable: true, suppressNavigable: true, suppressMenu: true,
                cellRenderer: this.titleCellRenderer,
            },
            {
                field: 'application', headerName: 'account-stats-app', minWidth: 120,
                filter: 'agTextColumnFilter', enableRowGroup: true, hide: true, editable: false, menuTabs: [], suppressMovable: true, suppressNavigable: true, suppressMenu: true,
            },
            {
                field: 'publication', headerName: 'account-stats-pub', minWidth: 200,
                filter: 'agTextColumnFilter', enableRowGroup: true, hide: true, editable: false, menuTabs: [], suppressMovable: true, suppressNavigable: true, suppressMenu: true,
            },
            {
                field: 'publisher', headerName: 'account-stats-publ', minWidth: 200,
                filter: 'agTextColumnFilter', enableRowGroup: true, hide: true, editable: false, menuTabs: [], suppressMovable: true, suppressNavigable: true, suppressMenu: true,
            },
            {
                field: 'transactionPrice', headerName: 'account-stats-price', minWidth: 90, maxWidth: 90,
                hide: true,
                valueFormatter: (params) => params.value ? params.value + ' CHF' : params.value === 0 ? '0 CHF' : '', enableValue: true,
                aggFunc: 'sum', editable: false, menuTabs: [], suppressMovable: true, suppressNavigable: true, suppressMenu: true,
            },
        ];
    }

    public titleCellRenderer(params) {
        const assetRef = this.searchService.createTransactionAssetsRefs(params.data);
        const transactionType = params.data.type as TransactionType;
        let urlPrefix;

        switch (transactionType) {
            case TransactionType.TocView:
                urlPrefix = '/toc/';
                break;
            case TransactionType.DocView:
            case TransactionType.LawView:
                urlPrefix = '/doc/';
                break;
            case TransactionType.Search:
            case TransactionType.EuSearch:
                urlPrefix = '/recherche/';
                break;
        }

        const urlSuffix = assetRef[3] ? `${assetRef[3]}/source/usage-statistics` : 'source/usage-statistics';
        const linkElement = createHrefWrapperForCellrender(params.data.title, `${urlPrefix}${assetRef[0]}/${assetRef[1]}/${urlSuffix}`);

        linkElement.className = 'titleInMultipTitleRow';
        linkElement.addEventListener('click', (event) => {
            if (event && !event.ctrlKey) {
                this.searchService.rerunTransaction(params.data, event);
                this.searchService.switchToMainTab();
            }
        });

        return linkElement;
    }

    public toggleListPrice(event) {
        this.listPriceShown = event.checked;
        this.gridColumnApi.setColumnVisible('transactionPrice', event.checked);
        this.gridApi.redrawRows();
        this.gridApi.sizeColumnsToFit();
    }

    public onSubmit(statisticsForm) {
        this.activeRowGroups = [];

        if (statisticsForm.valid) {
            if (this.isCorpAdmin) {
                this.toggleListPrice({ checked: true });
            }

            if (statisticsForm.value.userName === '') {
                this.statisticsForm.value.userName = null;
            }

            statisticsForm.value.dateUntil = statisticsForm.value.dateUntil ? format(endOfDay(statisticsForm.value.dateUntil), 'YYYY-MM-DD HH:mm:ss') : '';
            // this.accountService.dispatch({ fetch: CustomerProfileActions.trx_statistics.name, payload: statisticsForm.value });
            this.accountService.dispatch({ fetch: CustomerProfileActions.trx_statistics.name, payload: statisticsForm.value });
            this.gridApi.showLoadingOverlay();

            if (this.gridApi) {
                if (statisticsForm.value.firstOrderKey && statisticsForm.value.firstOrderKey !== '-') {
                    this.activeRowGroups.push(statisticsForm.value.firstOrderKey);
                }
                if (statisticsForm.value.secondOrderKey && statisticsForm.value.secondOrderKey !== '-') {
                    this.activeRowGroups.push(statisticsForm.value.secondOrderKey);
                }
                this.gridColumnApi.setRowGroupColumns(this.activeRowGroups);
            }
        } else {
            this.statisticsForm.markAsTouched();
            markFormAsTouched(statisticsForm);
        }

        if (this.gridColumnApi) {
            this.gridColumnApi.setValueColumns(['transactionPrice']);

            if (this.activeRowGroups) {
                this.activeRowGroups.forEach(el => {
                    this.gridColumnApi.setColumnVisible(el, false);
                });
            }

        }
    }

    private accountStatsRequired(group: FormGroup) {
        return  this.isSuperUser && !group.value.companyNumber && !group.value.departmentName && !group.value.userName ? { accountStatsRequired: 'account-stats-required' } : null;
    }

    public onBtExport() {
        this.gridColumnApi.setColumnsVisible(this.activeRowGroups, true);
        const fileName = this.translate.instant('account-excel-filename');
        const params = {
            processCellCallback: function (params) {
                if (params.column.colId === 'createdAt') {
                    if (params.value) {
                        const date = toDate(params.value);
                        if (isDate(date) && isValid(date)) {
                            return format(params.value, 'DD.MM.YYYY');
                        }
                    }
                }

                if (params.column.colId === 'createdAtTime') {
                    if (params.value) {
                        const date = toDate(params.value);
                        if (isDate(date) && isValid(date)) {
                            return format(params.value, 'HH:mm');
                        }
                    }
                }

                if (params.column.colId === 'transactionPrice') {
                    return params.value;
                }

                if (params.column.colId === 'type') {
                    return TransactionType[params.value];
                }

                return params.value;
            },
            fileName: fileName,
            sheetName: fileName,
            skipGroups: true,
            suppressTextAsCDATA: true,
        };
        this.gridApi.exportDataAsExcel(params);
    }

    public reset() {
        this.statisticsForm.reset();
        this.isCorpAdmin ? this.statisticsForm.controls['firstOrderKey'].setValue('user') : this.statisticsForm.controls['firstOrderKey'].setValue('type');
        this.statisticsForm.controls['secondOrderKey'].setValue('-');
        this.statisticsForm.controls['showZeroPrices'].setValue(false);
        this.setUserName();
    }

    departmentAutocomplete() {
        return (departmentName) => {
            return this.accountService.autoCompleteDepartment(this.statisticsForm.value.departmentName);
        };
    }
    userAutocomplete() {
        return (userName) => {
            return this.accountService.autoCompleteUser(this.statisticsForm.value.departmentName, this.statisticsForm.value.userName);
        };
    }

    ngOnDestroy(): void {
        this.accountService.transactionData.next(null);
        this.subscription.unsubscribe();
    }

}
