import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'biblio-info',
    templateUrl: './biblio-info.component.html',
    styleUrls: ['./biblio-info.component.scss'],
})
export class BiblioInfoComponent {
    constructor(public dialogRef: MatDialogRef<BiblioInfoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }
}
