import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';

import { postChangePasswordUrl, SlxHttp } from '../access';
import { AccountService } from '../account';
import { CustomerProfileActions } from '../customer-profile/customerProfileActions';
import { combinedQualityValidator, equalsOtherValidator, minLengthValidator, requiredValidator, uppercaseValidator } from '../field';
import { AlertType } from '../models/state/commonApp';

import { ChangePasswordComponent } from './changePassword.component';

@Component({
    selector: 'slx-change-password-from-profile',
    template: `
        <div class="slx-modal-header bg-blue">
            <strong translate="change-pwd-title"></strong>
            <button class="close-button" (click)="dialogRef.close()">
                 <i class="material-icons">close</i>
            </button>
        </div>
        <div class="window">
            <div class="content">
                <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit(changePasswordForm)">
                    <slx-text formControlName="oldPassword" type="password" placeholder="account-pwd-old" [isRequired]="true"></slx-text>
                    <slx-text formControlName="password" type="password" placeholder="account-pwd" [isRequired]="true" [passwordStrength]="true"></slx-text>
                    <slx-text formControlName="confirmPassword" type="password" placeholder="account-pwd-confirm" [isRequired]="true"></slx-text>
                    <div class="submit">
                        <button class="slx-icn-btn bold" type="submit" [class.locked]="changePasswordForm.invalid"><i class="material-icons">done</i><span translate="account-save"></span></button>
                        <span *ngIf="showError" class="error-span">{{ errorMessage | translate }}</span>
                    </div>
                </form>
                <div>
                    <mat-progress-bar *ngIf="inProgress | async" mode="indeterminate"></mat-progress-bar>
                </div>
            </div>
        </div>`,
    styleUrls: ['./changePasswordFromProfile.component.scss'],
})
export class ChangePasswordFromProfileComponent implements OnDestroy {

    private subscription: Subscription = new Subscription();

    private userId: string;
    public changePasswordForm: FormGroup;
    public showError = false;
    public errorMessage = '';

    public inProgress = this.accountService.isActionInProgress(CustomerProfileActions.change_password.name);

    constructor(private slxHttp: SlxHttp, public accountService: AccountService, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<ChangePasswordComponent>) {
        this.changePasswordForm = this.formBuilder.group({
            oldPassword: ['', requiredValidator()],
            password: ['', [
                requiredValidator(),
                combinedQualityValidator(
                    minLengthValidator('service-reg-pwd-min', 6),
                    uppercaseValidator('service-reg-pwd-uppercase', 1)),
            ]],
            confirmPassword: ['', [
                requiredValidator(),
                equalsOtherValidator('service-reg-pwd-equal', 'password')]],
        }
        );

        this.subscription
            .add(this.accountService.userId.subscribe(id => this.userId = id))
            .add(this.changePasswordForm.controls['oldPassword'].valueChanges.subscribe(changed => this.showError = false));
    }

    onSubmit(changePasswordForm) {

        const changePasswordFormData = {
            ...changePasswordForm.value,
            userId: this.userId,
        };
        this.slxHttp.post(postChangePasswordUrl, changePasswordFormData).subscribe(
            res => {
                this.dialogRef.close();
                this.accountService.dispatch({ type: 'alert', payload: { type: AlertType.Info, key: 'account-pw-changed-successful', duration: 10 } });
            },
            error => {
                this.errorMessage = error.translationKey;
                this.showError = true;
            }
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
