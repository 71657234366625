import { Component } from '@angular/core';

import { HomeService } from './home.service';

@Component({
  selector: 'slx-home',
  template: `
  <slx-intro></slx-intro>
  <slx-home-tiles style="clear: both"></slx-home-tiles>
  <div class="collapsible-sidebar">
    <aside [ngClass]="{'mobile-open': (homeService.activeMobileSidebarComponent | async) === 'home', 'mobile-closed': (homeService.activeMobileSidebarComponent | async) === ''}">
      <slx-subheader-main-navigation [navigationOpen]="true"></slx-subheader-main-navigation>
      </aside>
  </div>
  `,
})
export class HomeComponent {
    constructor(public homeService: HomeService) { }
}
