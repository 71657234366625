import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { AppActions } from '../../appActions';
import { FieldService } from '../../field';
import { PublicationCriteria } from '../../models';

@Component({
  selector: 'slx-publication-select-dialog',
  templateUrl: './publication-select-dialog.component.html',
  styleUrls: ['./publication-select-dialog.component.scss'],
})
export class PublicationSelectDialogComponent {

  public userSelection: PublicationCriteria;

  constructor(private fieldService: FieldService, @Inject(MAT_DIALOG_DATA) public data: PublicationCriteria, public dialogRef: MatDialogRef<PublicationSelectDialogComponent>) {
    this.userSelection = data;
    this.apply = this.apply.bind(this);
  }

  public userSelectionChange(data) {
    this.userSelection = data;
  }

  public apply() {
    this.fieldService.dispatch({
      type: AppActions.apply_selected_publications.name,
      payload: this.userSelection,
    });
    this.dialogRef.close();
  }
}
