import { Component, OnInit } from '@angular/core';

import { HomeService } from '../../home.service';

@Component({
  selector: 'slx-jobs-tile',
  templateUrl: './jobsTile.component.html',
  styleUrls: ['./jobsTile.component.scss'],
})
export class JobsTileComponent implements OnInit {


  constructor(public homeService: HomeService) {
  }

  ngOnInit() {
      this.homeService.getJobsForHomeTiles();
  }

}
