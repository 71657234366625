import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { markFormAsTouched } from '../../app/utility/utilityFunctions';
import { CustomerProfileActions } from '../customer-profile/customerProfileActions';
import { combinedQualityValidator, equalsOtherValidator, minLengthValidator, requiredValidator, uppercaseValidator } from '../field';

import { AccountService } from './../account';

@Component({
    selector: 'slx-change-password',
    template: `
        <h1 translate="change-pwd-title"></h1>
        <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit(changePasswordForm)">
            <slx-text formControlName="password" type="password" placeholder="account-pwd" [isRequired]="true" [passwordStrength]="true"></slx-text>
            <slx-text formControlName="confirmPassword" type="password" placeholder="account-pwd-confirm" [isRequired]="true"></slx-text>
            <slx-submit-button action="change_password" placeholder="account-save" icon="done" errorPlaceholder="account-error" [locked]="changePasswordForm.invalid"></slx-submit-button>
        </form>
        <div>
        </div>`,
    styles: [
        `:host {
            background: white;
            max-width: 1400px;
            margin: 0 auto;
            padding: 4rem;
            display: block;
            padding 3rem 6rem;
        }`,
        `p { margin: 1rem 0; }`,
        `.locked {
            color: grey;
            pointer-events: none;
        }`],
})
export class ChangePasswordComponent {
    public changePasswordSubscription: Subscription;
    public changePasswordForm: FormGroup;

    constructor(private route: ActivatedRoute, public accountService: AccountService, private formBuilder: FormBuilder) {
        const key = route.snapshot.queryParams['token'];
        const lang = route.snapshot.params['lang'];

        // FOR LINK FROM PASSWORD-FORGOT EMAIL
        // When the user clicks Link in E-Mail, we tell Angular through url which language to use
        accountService.use(lang);

        this.changePasswordForm = this.formBuilder.group({
            urlToken: key,
            password: ['', [
                requiredValidator(),
                combinedQualityValidator(
                    minLengthValidator('service-reg-pwd-min', 6),
                    uppercaseValidator('service-reg-pwd-uppercase', 1)),
            ]],
            confirmPassword: ['', [
                requiredValidator(),
                equalsOtherValidator('service-reg-pwd-equal', 'password')]],
        }
        );
    }

    onSubmit(changePasswordForm) {
        if (!changePasswordForm.valid) {
            markFormAsTouched(changePasswordForm);
            return;
        }
        const changePasswordFormData = {
            ...changePasswordForm.value,
        };
        this.accountService.dispatch({ save: CustomerProfileActions.change_password.name, payload: changePasswordFormData });
    }
}
