import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AppActions } from '../../appActions';
import { SearchType } from '../../models';
import { AlertType } from '../../models/state/commonApp';
import { markFormAsTouched } from '../../utility/utilityFunctions';
import { SearchService } from '../search.service';

export abstract class SearchFormBase implements OnInit, OnDestroy {

    protected searchFormSubscription: Subscription;

    public searchForm: FormGroup;
    public formDescription: any;
    protected validationKeys: any[];

    // dependencies should be in order [A,B,C] means C->B->A (-> : depends on)
    // see resetDependencies for usage
    protected controlDependencies = [];

    constructor(protected searchService: SearchService, protected searchType: SearchType, protected changeDetectorRef: ChangeDetectorRef, protected translate: TranslateService) { }

    ngOnInit() {
        this.searchFormSubscription = this.searchService.store
            .select(state => state.recherche.searchForms[SearchType[this.searchType]])
            .subscribe(state => {
                if (Object.keys(state).length === 0) { // default value => empty object
                    this.searchForm.reset();
                }
                else {
                    this.searchForm.patchValue(state);
                }
                this.onSearchFormChanges(state);
            });
    }

    ngOnDestroy() {
        //this.isDestroyed = true;
        this.saveSearchFields(this.searchForm.value);

        if (this.searchFormSubscription) {
            this.searchFormSubscription.unsubscribe();
        }
    }

    validateFormAndReport(): boolean {
        const isValid = this.validationKeys.some(control => this.searchForm.controls[control].value);

        if(!isValid){
            const translatedKeys = [];
            this.validationKeys.forEach(key => translatedKeys.push(this.translate.instant('rech-validation-' + key)));

            const answer = this.translate.instant('rech-search-choose-one') + translatedKeys.join(', ');
            this.searchService.dispatch({ type: AppActions.alert.name, payload: { type: AlertType.Error, key: answer, duration: 20 } });
        }

        return isValid;
    }

    onSubmit() {
        if(this.validationKeys){
            if(!this.validateFormAndReport()){
                return;
            }
        }
        else{
            if (!this.searchForm.valid) {
                markFormAsTouched(this.searchForm);
                return;
            }
        }

        this.submitSearch(this.searchForm.value);
    }

    onReset() {
        this.searchService.dispatch({
            type: this.searchService.actions.clear_search_fields.name,
            payload: this.searchType,
        });
    }

    // this method allows derived classes to tap into the searchform subscription
    // if ngOnInit is overwritten, this will not automatically be invoked
    onSearchFormChanges(state: any): void { }

    saveSearchFields(parameters: any) {
        this.searchService.store.dispatch({
            type: this.searchService.actions.search_fields.name,
            payload: {
                type: this.searchType,
                parameters: { ...parameters },
            },
        });
    }

    submitSearch(parameters: any){
        this.searchService.store.dispatch({
            type: this.searchService.actions.search_fields_submit.name, payload: {
                type: this.searchType,
                parameters: { ...parameters },
            },
        });
    }

    // this function can be bound to a control changedEvent in order to
    // reset all dependent controls, as defined in the controlDependencies array
    public resetDependencies(changedControlName) : () => any {
        return (function(){
            const start = this.controlDependencies.indexOf(changedControlName);
            if(start === -1){
                return;
            }
            for(let i = start+1;i<this.controlDependencies.length;i++){
                this.searchForm.controls[this.controlDependencies[i]].reset();
            }
        }).bind(this);
    }
}
