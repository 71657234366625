import { Directive, HostListener } from '@angular/core';

import { calculatePreviewPosition, isResponsive } from '../utility/utilityFunctions';

import { AssetService } from './asset.service';


@Directive({
    selector: '[slx-link-preview]',
})

export class LinkPreviewDirective {

    private defaultWidth = 350;
    private defaultHeight = 550;

    private timer: any;

    constructor(private assetService: AssetService) {
        this.mouseleave = this.mouseleave.bind(this);
    }

    @HostListener('mouseover', ['$event']) onMouseMove(event): void {
        let target = event.target.className === 'highlight' ? event.target.parentNode : event.target;

        if(!['a','b','i'].includes(target.localName)){
            return;
        }

        if (target.localName !== 'a') {
            const closestA = target.closest('a');
            if (closestA) {
                target = closestA;
            } else {
                return;
            }
        }

        //there is no preview implemented for EuDocs
        if (target.classList.contains('prompt-document-link-eu')) {
            return;
        }

        const slxTabsElement = target.closest('slx-asset-tabs');
        if (!slxTabsElement) { return; }

        // Normal Link or Multiple Link ?
        if (target.classList.contains('prompt-document-link') || target.className === 'prompt-preview-link link-multiple' || target.className === 'prompt-preview-link link-ambiguous' || (target.name && target.name.includes('footnotecall'))) {

            const isPrimary = slxTabsElement.getAttribute('ng-reflect-is-primary');
            const linkPreviewInformation = calculatePreviewPosition(target, this.defaultWidth, this.defaultHeight);
            let footNoteContent;

            if (target.name.includes('footnotecall')) {
                const footNoteCallDown = target.closest('slx-docview').querySelector(`.footnotes a[href='#${target.name}']`);
                footNoteContent = footNoteCallDown.parentElement.nextElementSibling.firstElementChild.textContent;
                // find footNode, then move up to dt (description name), then to sibling (dd), get the p-Tag in there
            }

            this.timer = setTimeout(() => this.assetService.previewAsset({ ...linkPreviewInformation, footNoteContent, isPrimary }), 200);
            target.addEventListener('mouseleave', this.mouseleave);
        }
    }

    private mouseleave(event) {
        clearTimeout(this.timer);

        if (event.target.className !== 'prompt-preview-link link-multiple' && event.target.className !== 'prompt-preview-link link-ambiguous' && !event.target.closest('.link-preview') && !event.target.rel.includes('/PreviewLaws?')) {
            this.assetService.cancelAssetPreview();
        }
        event.target.removeEventListener('mouseleave', this.mouseleave);

    }
}
