import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { AutocompleteService } from '../../../asset';
import { requiredValidator } from '../../../field';
import { AutocompleteParamter, AutocompleteType, SearchType } from '../../../models';
import { SearchService } from '../../search.service';
import { SearchFormBase } from '../search-form-base';

@Component({
    selector: 'slx-case-law-unpub',
    template: `<slx-search-form *ngIf="formDescription && searchForm" [formDescription]="formDescription" [searchForm]="searchForm" [subTabs]="subTabs" (searchSubmitted)="onSubmit()" (searchReset)="onReset()"></slx-search-form>`,
})
export class NotPupCaseCollectionComponent extends SearchFormBase {

    subTabs = [
        { translate: 'rech-published', key: 'casepub' },
        { translate: 'rech-unpublished', key: 'casenonpub' },
    ];

    constructor(protected searchService: SearchService, protected autocompleteService: AutocompleteService, protected formBuilder: FormBuilder, protected changeDetectorRef: ChangeDetectorRef, protected translate: TranslateService) {
        super(searchService, SearchType.NotPublishedCaseCollection, changeDetectorRef, translate);

        this.getNotPubCaseNumberAutocomplete = this.getNotPubCaseNumberAutocomplete.bind(this);

        this.searchForm = this.formBuilder.group({
            caseNumber: [''],
        });

        this.formDescription = {
            formGroups: [{
                class: 'slx-form-row no-wrap',
                controls: [
                    {
                        control: 'slx-text', name: 'CaseNumber', type: 'text', placeholder: 'rech-dossiernumber', formControlName: 'caseNumber', class: 'slx-field-xlarge',
                        makeAutocomplete: this.getNotPubCaseNumberAutocomplete,
                    },
                    { control: 'slx-info-button', filename: 'reference-help-casecollection-notpub', titleTransString: 'home-info-reference-help', class: 'in-form' },
                ],
            }],
            controls: {
                resetControl: true,
            },
            searchButtonText: 'rech-display',
        };

        this.validationKeys = [
            'caseNumber',
        ];
    }

    getNotPubCaseNumberAutocomplete() {
        return () => {
            const paramters = [
                new AutocompleteParamter('notPubCaseCollectionCaseNumber', this.searchForm.value.caseNumber),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.CaseCollectionNotPubCaseNumber, false, paramters);
        };
    }
}
