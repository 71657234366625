import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, Pipe, PipeTransform, SimpleChange, ViewChild } from '@angular/core';
import { MatTree, MatTreeNestedDataSource } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountService } from '../../account';
import { AppActions } from '../../appActions';
import { Asset, AssetDisplayType, AssetRef, AssetRefOptions, AssetTab, AssetType, DocScrollType, SourceDetail, TocContent, TocItem, TocTooltipInformation, TooltipData } from '../../models';
import { ActiveTocTab } from '../../models/research';
import { isEmtpyGuid, isResponsive } from '../../utility/utilityFunctions';
import { AssetService } from '../asset.service';
import { AssetActions } from '../assetActions';


const debug = require('debug')('toc');
const debugScroll = require('debug')('tocScroll');

class TocMapEntry {
    node: TocItem;
    parentId: string;
    constructor(node: TocItem, parentId: string) {
        this.node = node;
        this.parentId = parentId;
    }
}


export function determineTocSource(tocType: AssetDisplayType): string {
    switch (tocType) {
        case AssetDisplayType.EuToc:
            return SourceDetail.EuTocDocumentLink;
        case AssetDisplayType.Book:
            return SourceDetail.BookTocDocumentLink;
        case AssetDisplayType.PeriodicalPublication:
            return SourceDetail.PeriodicalTocDocumentLink;
        default:
            return '';
    }
}

@Pipe({ name: 'tocItemToRef' })
export class ToCItemToRefPipe implements PipeTransform {
    transform(tocItem: TocItem, tocType: AssetDisplayType, isBranch = false): AssetRef {
        const source = determineTocSource(tocType);
        if (!isBranch) {
            return AssetRef.create(tocType === AssetDisplayType.EuToc ? AssetDisplayType.EuDoc : AssetDisplayType.UnknownDocument, tocItem.targetID, { source });
        }

        if (tocType === AssetDisplayType.Book) {
            return AssetRef.create(AssetDisplayType.UnknownDocument, tocItem.targetID, { source });
        }

        return null;
    }
}


@Component({
    selector: 'slx-toc-tree-content',
    templateUrl: './toc-tree-content.component.html',
    styleUrls: ['./toc-tree-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TocTreeContentComponent implements OnChanges, OnInit, OnDestroy {
    @Input() tocContent: TocContent;
    @Input() isPrimary: boolean;
    @Input() isInSideBar: boolean;
    @Input() publicationId: string;
    @Input() tocType: AssetDisplayType;
    @Input() fontSize: Number;

    @ViewChild('tree', { read: ElementRef }) treeElement: ElementRef;

    public treeControl: NestedTreeControl<TocItem>;
    public treeDataSource: MatTreeNestedDataSource<TocItem>;

    public loadInProgress: string;
    public otherAssetID: string;
    public currentEdocTitleElement: string;
    private otherAssetSubscription: Subscription;
    private maxChildrenPerView: number;

    private childItemsSubscription: Subscription;
    private tocItems = new Array<TocItem>();
    private tocItemsMap = new Map<string, TocMapEntry>();
    private targetIDTempIDMap = new Map<string, string>();
    private targetAnchorTempIDMap = new Map<string, string>();

    private isDestroyed = false;

    public getChildren = (nodeData: TocItem) => {
        if (!nodeData.childrenTocItemSubject) {
            nodeData.childrenTocItemSubject = new BehaviorSubject<TocItem[]>(nodeData.childrenTocItems);
        }
        return nodeData.childrenTocItemSubject;
    }

    public hasChild = (_: number, nodeData: TocItem) => {
        return nodeData.hasChildren;
    }

    constructor(private assetService: AssetService, private accountService: AccountService, private changeDetectorRef: ChangeDetectorRef, public translate: TranslateService) {

        this.tooltipCreator = this.tooltipCreator.bind(this);

        this.treeControl = new NestedTreeControl<TocItem>(this.getChildren);
        this.treeDataSource = new MatTreeNestedDataSource();
    }

    ngOnInit(): void {

        this.childItemsSubscription = this.assetService.store.select(state => state.recherche.loadedTocItem).subscribe(tocItem => {
            if (tocItem && tocItem.items) {
                const parent = this.getTocItemById(tocItem.itemId);
                if (parent) {

                    debug('children fetched for node', tocItem.itemId, tocItem.items.length);
                    if (tocItem.items.length > 0) {
                        if (parent.childrenTocItemSubject) {
                            parent.childrenTocItemSubject.next(tocItem.items);
                        }
                        parent.childrenTocItems = tocItem.items;
                        this.addTocItemsToMap(tocItem.items, parent.temporaryItemID);
                    }

                    parent.loadingChildren = false;
                    this.markForCheck();
                }

            }
        });


        if (this.tocType !== AssetDisplayType.EuToc) {
            this.assetService.activeAssetTabForToc.subscribe(assetTab => {

                this.otherAssetID = assetTab && assetTab.assetRef ? AssetRef.toAssetID(assetTab.assetRef) : null;
                this.currentEdocTitleElement = assetTab ? assetTab.currentEdocTitleElement : null;

                if (this.currentEdocTitleElement) {
                    this.openTarget(this.currentEdocTitleElement, this.targetAnchorTempIDMap, true);
                }
                else if (assetTab && assetTab.assetRef && assetTab.assetRef[2] !== AssetRefOptions.EdocTitleRef) {
                    this.openTarget(this.otherAssetID, this.targetIDTempIDMap, true);
                }
                else {
                    Promise.resolve(null).then(() => { this.markForCheck(); });
                }

            });
        }
    }

    private markForCheck() {
        if (!this.isDestroyed) {
            this.changeDetectorRef.markForCheck();
        }
    }

    private openTarget(key: string, tempIdMap: Map<string, string>, scrollIntoView: boolean) {
        debug('opening tocItem with key', key);
        const nodeID = tempIdMap.get(key);
        if (!nodeID) {
            debug('no node found with key', key, tempIdMap);
            this.markForCheck();
            return;
        }
        const idToScroll = this.openTocItem(nodeID) && this.tocType !== AssetDisplayType.Book ? this.targetIDTempIDMap.get(this.otherAssetID) : nodeID;
        Promise.resolve(null).then(() => { this.markForCheck(); });
        if (scrollIntoView) {
            Promise.resolve(null).then(() => { this.scrollIntoView(nodeID, true); });
        }
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }): void {

        this.maxChildrenPerView = this.tocType === AssetDisplayType.Book ? 2 : 3;

        if (changes['tocContent'] && this.tocContent.tocItems) {

            if (changes['publicationId']) {
                this.tocItemsMap.clear();
                this.targetIDTempIDMap.clear();
                this.targetIDTempIDMap.clear();
            }

            if (this.tocItemsMap.size === 0) {
                debug('initializing data');
                this.treeDataSource.data = [...this.tocContent.tocItems];
                this.addTocItemsToMap(this.treeDataSource.data, null);
                debug('map of tocItems created', this.tocItemsMap.size);
            }

            if (this.tocContent.idToOpen) {
                // scroll if one of these conditions are met
                // 1) not in sidebar => open as asset, 2) a book, 3) if it is a new toc => publicationId has changed
                const doScroll = !this.isInSideBar || this.tocType === AssetDisplayType.Book || !!changes['publicationId'];
                this.openTarget(this.tocContent.idToOpen, this.targetIDTempIDMap, doScroll);
                this.tocContent.idToOpen = null;
            }
        }
    }

    ngOnDestroy(): void {
        if (this.childItemsSubscription) {
            this.childItemsSubscription.unsubscribe();
        }
        if (this.otherAssetSubscription) {
            this.otherAssetSubscription.unsubscribe();
        }
        this.isDestroyed = true;
    }

    private addTocItemsToMap(tocItems: TocItem[], parentId: string) {
        tocItems.forEach(node => {

            if (!node.childrenTocItems) {
                node.childrenTocItems = [];
            }

            // if there is a targetID add it to the IDmap
            if (!isEmtpyGuid(node.targetID) && !this.targetIDTempIDMap.has(node.targetID)) {
                this.targetIDTempIDMap.set(node.targetID, node.temporaryItemID);
            }

            // add node to the node map
            this.tocItemsMap.set(node.temporaryItemID, new TocMapEntry(node, parentId));

            // add entry to target_anchor_map
            this.targetAnchorTempIDMap.set(node.targetAnchor, node.temporaryItemID);

            // handle children
            if (node.childrenTocItems.length > 0) {
                this.addTocItemsToMap(node.childrenTocItems, node.temporaryItemID);
            }
        });
    }

    // returns true if the node is already open
    private openTocItem(idToOpen: string): boolean {
        const mapEntry = this.tocItemsMap.get(idToOpen);

        if (!mapEntry) {
            debug('no node found with id', idToOpen);
            return;
        }

        // open parent
        if (mapEntry.parentId) {
            this.openTocItem(mapEntry.parentId);
        }

        // open node
        Promise.resolve(null).then(() => {
            if (!this.treeControl.isExpanded(mapEntry.node)) {
                this.toggleNode(mapEntry.node);
            }
        });

        return this.treeControl.isExpanded(mapEntry.node);
    }

    private determinePanelToScrollTo(openendID: string): TocItem {
        debug('openendItem', openendID);
        const openedNode = this.tocItemsMap.get(openendID);
        if (!openedNode.parentId) {
            debug('has no parents => scroll  itself into view');
            return openedNode.node;
        }

        const parentNode = this.tocItemsMap.get(openedNode.parentId);
        const childIndex = parentNode.node.childrenTocItems.findIndex(child => child.temporaryItemID === openendID);
        if (childIndex < this.maxChildrenPerView) {
            debug('has only few siblings before => scroll parent into view', parentNode.node.temporaryItemID);
            return parentNode.node;
        }

        const siblingToScrollTo = parentNode.node.childrenTocItems[childIndex - this.maxChildrenPerView];
        debug('has many siblings => scroll sibling into view', siblingToScrollTo.temporaryItemID, childIndex);
        return siblingToScrollTo;

    }



    private scrollIntoView(id: string, scrollToParent) {
        // if (!id || (isResponsive() && !this.isInSideBar)) {
        //     return;
        // }
        if (!id || (isResponsive())) {
            return;
        }
        const panelToScrollTo = scrollToParent ? this.determinePanelToScrollTo(id) : this.tocItemsMap.get(id).node;
        const panelElement = <HTMLElement>this.treeElement.nativeElement.querySelector(`[id="target_${panelToScrollTo.temporaryItemID}"]`);
        if (panelElement) {
            debugScroll('trying to scroll', id);
            this.scrollPanelIntoView(panelElement);
        }

    }


    private scrollStack: HTMLElement[] = [];
    private scrollTimeOut;
    private scrollWaitingTime = 100; //ms
    public scrollPanelIntoView(panel: HTMLElement) {
        this.scrollStack.push(panel);
        if (!this.scrollTimeOut) {
            this.scrollTimeOut = setTimeout(() => {
                const panelToScrollTo = this.scrollStack.pop();
                this.scrollStack = [];
                debugScroll('scrolling into view', panelToScrollTo);
                panelToScrollTo.scrollIntoView({ block: 'start', behavior: 'smooth' });
                this.scrollTimeOut = null;
            }, this.scrollWaitingTime);
        }
    }

    private getTocItemById(id: string): TocItem {
        const mapEntry = this.tocItemsMap.get(id);
        return mapEntry ? mapEntry.node : null;
    }

    public toggleNode(node: TocItem, event: MouseEvent = null) {
        debug('toggeling node', node.title, node.temporaryItemID);
        //this.otherAssetID = null;

        if (event) {
            event.stopPropagation();
        }

        // node is closed => node is opening now => load chlidren if necessary
        if (!this.treeControl.isExpanded(node) && node.hasChildren && node.childrenTocItems.length === 0) {
            // const itemId = isEmtpyGuid(node.targetID) ? node.temporaryItemID : node.targetID;
            debug('fetching children for node', node.temporaryItemID, node.targetID, node.getChildrenTocItemsUrl);
            if (node.getChildrenTocItemsUrl) {
                const getItemsUrl = `${node.getChildrenTocItemsUrl}&language=${this.accountService.lang}`;
                node.loadingChildren = true;
                this.assetService.dispatch({
                    fetch: AssetActions.load_toc_items.name,
                    payload: { getItemsUrl, itemId: node.temporaryItemID, targetId: node.targetID, isPrimary: this.isPrimary },
                });
            }
        }
        else if (!isEmtpyGuid(node.targetID)) {
            // dispatch toggeling of node for hitlist to know which issues are open
            this.assetService.dispatch({
                type: AssetActions.toggle_toc_items.name,
                payload: { itemId: node.targetID, isOpen: !this.treeControl.isExpanded(node), isPrimary: this.isPrimary },
            });
        }

        this.treeControl.toggle(node);
    }

    public isEmptyGuid(id: string) {
        return isEmtpyGuid(id);
    }

    onBranchClicked(node, event) {
        event.stopPropagation();

        if (this.tocType === AssetDisplayType.PeriodicalPublication || this.tocType === AssetDisplayType.EuToc) {
            this.toggleNode(node);
            return;
        }

        this.displayAsset(node, event);
    }

    public tooltipCreator(tooltipInformation: TocTooltipInformation): Observable<TooltipData> {
        if (!tooltipInformation || !tooltipInformation.showTooltip) {
            return null;
        }

        return of(tooltipInformation).pipe(map(
            tooltipInfo => {
                const tooltipTitle = tooltipInfo.title;
                let tooltipBody = `<strong>${tooltipInfo.subTitle}</strong><br>`;

                if (tooltipInfo.summary) {
                    tooltipBody += '<div>';
                    tooltipInfo.summary.forEach(s => {
                        tooltipBody += `${s}<br>`;
                    });
                    tooltipBody += '</div>';
                }

                return { tooltipBody, tooltipTitle };
            }));
    }

    public displayAsset(item: TocItem, event: MouseEvent) {

        const source = determineTocSource(this.tocType);

        if (this.tocType === AssetDisplayType.EuToc) {
            this.assetService.openAsset(AssetRef.create(AssetDisplayType.EuDoc, item.targetID, { source }), true, event);
            return;
        }

        const citationInfo = item.targetAnchor ? { type: AssetRefOptions.EdocTitleRef, id: item.targetAnchor } : null;
        const assetRef = AssetRef.create(AssetDisplayType.UnknownDocument, item.targetID, { citationInfo, source });

        if (this.isInSideBar || isResponsive()) {
            this.assetService.openAsset(assetRef, true, event);
            return;
        }

        this.assetService.openAsset([...assetRef, this.tocType, this.publicationId, AssetRefOptions.sourceDetail, source], this.isPrimary, event);
        this.assetService.dispatch({ type: AssetActions.close_tab.name, payload: { isPrimary: this.isPrimary, assetID: this.publicationId } });
        this.assetService.dispatch({ type: AssetActions.set_active_tab_for_toc.name, payload: this.isPrimary ? ActiveTocTab.Primary : ActiveTocTab.Secondary });
        this.assetService.dispatch({ type: AppActions.set_collection_tab.name, payload: { collectionTab: 'toc' } });
    }
}
