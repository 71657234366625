import { Component } from '@angular/core';

import { BibliothekService } from './../bibliothek.service';

@Component({
    selector: 'slx-case-collection-bibliothek',
    templateUrl: './caseCollectionBibliothek.component.html',
    styleUrls: ['./caseCollectionBibliothek.component.scss'],
})
export class CaseCollectionBibliothekComponent {

    constructor(public bibliotheService: BibliothekService) { }
}
