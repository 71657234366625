import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AccountService } from '../../../account';
import { AutocompleteService } from '../../../asset';
import { requiredValidator } from '../../../field';
import { AutocompleteParamter, AutocompleteType, LegalAct, LegalActTypeGroup, SearchType } from '../../../models';
import { SearchService } from '../../search.service';
import { SearchFormBase } from '../search-form-base';

const debug = require('debug')('search');

@Component({
    selector: 'slx-eu-legislation-search',
    template: `
        <form novalidate [formGroup]="this.searchForm" (ngSubmit)="onSubmit()" autocomplete="off">
            <h4 translate="rech-legislation"></h4>
            <div class="slx-form-row relative">
                <slx-select class="slx-field-small" type="dropdown" translateName="translate" placeholder="rech-legislation-type" formControlName="type" [options]="legislationOptions" (change)="typeChanged()"></slx-select>
                <slx-text class="slx-field-small" type="text" placeholder="rech-year" formControlName="year" [autocomplete]="getLegislationYearAutocomplete()" (textChanged)="yearChanged()" [listenToTextChange]="true"></slx-text>
                <slx-text class="slx-field-small" type="text" placeholder="rech-number" formControlName="number" [autocomplete]="getLegislationNumberAutocomplete()"></slx-text>
                <slx-info-button filename="eu_legislation-help" foldername="Eu" titleTransString="rech-info-eu_legislation-help" class="in-form"></slx-info-button>
            </div>
            <div class="controls">
                <button class="slx-icn-btn r-mrgn" type="button" (click)="reset()" [title]="'rech-button-desc-form-reset' | translate"><i class="material-icons">clear</i><span translate="rech-clear"></span></button>
                <button class="slx-icn-btn" type="submit"><i class="material-icons">search</i><span translate="rech-display"></span></button>
            </div>
        </form>
    `,
    styles: [`
        .controls {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
        }
        h4 {
            margin: 0;
        }
    `],
})
export class EuLegislationComponent extends SearchFormBase implements OnInit, OnDestroy {

    public legislationOptions: any;
    public selectedLegislation: string;

    public typeChanged = this.resetDependencies('type');
    public yearChanged = this.resetDependencies('year');

    constructor(protected searchService: SearchService, protected formBuilder: FormBuilder, private autocompleteService: AutocompleteService, private accountService: AccountService, protected changeDetectorRef: ChangeDetectorRef, protected translate: TranslateService) {
        super(searchService, SearchType.EuLegislation, changeDetectorRef, translate);

        // bind autocomplete get url functions (this)
        this.getLegislationYearAutocomplete = this.getLegislationYearAutocomplete.bind(this);
        this.getLegislationNumberAutocomplete = this.getLegislationNumberAutocomplete.bind(this);

        this.searchForm = this.formBuilder.group({
            type: ['', requiredValidator()],
            year: [''],
            number: [''],
        });

        this.controlDependencies = ['type', 'year', 'number'];
    }

    ngOnInit() {
        super.ngOnInit();
        this.initLegislations();

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    getLegislationYearAutocomplete() {
        return (suchtext) => {
            const paramters = [
                new AutocompleteParamter('euLegalAct', this.searchForm.value.type),
                new AutocompleteParamter('year', this.searchForm.value.year),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.EuLegislationYear, false, paramters);
        };
    }

    getLegislationNumberAutocomplete() {
        return (suchtext) => {
            const paramters = [
                new AutocompleteParamter('euLegalAct', this.searchForm.value.type),
                new AutocompleteParamter('year', this.searchForm.value.year),
                new AutocompleteParamter('number', this.searchForm.value.number),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.EuLegislationNumber, false, paramters);
        };
    }

    initLegislations() {
        this.legislationOptions = [];
        for (const legislation of LegalAct.getLegislations()) {
            const opt = {
                value: legislation,
                translate: 'rech-legislation-' + LegalActTypeGroup[legislation],
            };
            this.legislationOptions.push(opt);
        }

        this.searchFormSubscription = this.searchService.store.select(state => state.recherche.searchForms.EuLegislation).subscribe(state => {
            if(state.type && state.type!==undefined) {
                this.selectedLegislation = state.type;
            }else{
                this.selectedLegislation = this.legislationOptions[0].value;
            }
        });

        this.searchForm.controls['type'].setValue(this.selectedLegislation);
        debug(this.legislationOptions);
    }

    reset() {
        this.searchForm.reset();
        this.searchForm.controls['type'].setValue(this.selectedLegislation);
    }
}
