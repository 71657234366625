import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule, MatProgressBarModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';

import { AccessModule, NotFoundResolver } from '../access';
import { AccountModule, AccountService } from '../account';
import { EventsCalendarComponent } from '../events/calendar/events-calendar.component';
import { FieldModule } from '../field';

import { HomeModule } from './../home/';
import { EventDetailsComponent } from './details/event-details.component';
import { GridEventComponent } from './eventGrid/slx-event-grid.component';
import { EventsComponent } from './events.component';
import { EventsResolver } from './events.resolver';
import { EventsService } from './events.service';
import { EventsEffects } from './eventsEffects';
import { RegisterEventComponent } from './registerEvent/register-event.component';

export const eventsRoute = {
    path: 'events',
    component: EventsComponent,
    children: [
        {path: '', redirectTo: 'current', pathMatch: 'full'},
        { path: 'current', component: GridEventComponent, resolve: {
            events: EventsResolver,
        }},
        { path: 'register', component: RegisterEventComponent, resolve: {
            events: EventsResolver,
        }},
        { path: 'details/:id/:ref', component: EventDetailsComponent,  resolve: {
            events: EventsResolver,
        }},
        { path: 'details/:id', component: EventDetailsComponent,  resolve: {
            events: EventsResolver,
        }},
        { path: 'details', component: EventDetailsComponent,  resolve: {
            events: EventsResolver,
        }},
        {
            path: '**', component: GridEventComponent, resolve: {
                events: EventsResolver,
                notFound: NotFoundResolver,
            },
        },
    ],
};

@NgModule({
    imports: [
        CommonModule,
        AccountModule,
        ReactiveFormsModule,
        RouterModule,
        MatDatepickerModule,
        MatProgressBarModule,
        FieldModule,
        AccessModule,
        EffectsModule.forFeature([EventsEffects]),
        RecaptchaModule,
        RecaptchaFormsModule,
        HomeModule,
    ],
    declarations: [
        EventsComponent,
        RegisterEventComponent,
        EventDetailsComponent,
        GridEventComponent,
        EventsCalendarComponent,
    ],
    providers: [EventsService, EventsResolver],
})
export class EventsModule {
    constructor(private account: AccountService) {
        this.account.addTranslation('events-',
            require('./events.de.yaml'),
            require('./events.fr.yaml')
        );
    }
}
