import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import { getBiblioBooksBaseUrl, LoginService, SlxHttp } from '../access';
import { AccountService } from '../account';
import { AppActions } from '../appActions';
import * as config from '../config';
import { AppState, Asset, AssetDisplayType, AssetRef, AssetRefOptions, BiblioBook, BiblioContent, BiblioPeriodical, BiblioType, LanguageEnum, makeAction, SlxAction, SourceDetail } from '../models';
import { FrontendCache } from '../utility/frontendCache';
import { createHrefWrapperForCellrender, isEmtpyGuid } from '../utility/utilityFunctions';

import { BibliothekActions } from './bibliothekActions';


const maxAgeCacheInHours = 12;

@Injectable()
export class BibliothekService implements OnDestroy {

    private biblioCache: FrontendCache<string, BiblioContent[]>;
    private subscription = new Subscription();
    private cacheClearedSubject = new Subject<boolean>();
    public cacheCleared = this.cacheClearedSubject.asObservable();
    public caseLawQuickFilter = new BehaviorSubject(null);

    constructor(private slxHttp: SlxHttp, private accountService: AccountService, private loginService: LoginService, public store: Store<AppState>, private router: Router) {

        this.biblioCache = new FrontendCache<string, BiblioContent[]>(maxAgeCacheInHours, (url: string) => {
            return this.slxHttp.get(url, false);
        });

        this.subscription
            .add(this.accountService.onLangChange.subscribe(() => { this.biblioCache.clear(); this.cacheClearedSubject.next(true); }))
            .add(this.loginService.loggedIn.subscribe(() => { this.biblioCache.clear(); this.cacheClearedSubject.next(true); }));

    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public euTocModel: Observable<any> = this.store.select(state => {
        const asset = new Asset();
        asset.id = undefined;
        asset.content = {
            ...state.biblio.euTocModel.content,
            idToOpen: undefined,
            referringAssetID: undefined,
        };

        return {
            toc: asset,
        };
    });

    dispatch(action) {
        return this.store.dispatch(makeAction(action as SlxAction));
    }

    public quickfilterValueChanged(value) {
        this.caseLawQuickFilter.next(value !== '' ? value : null);
    }


    public openToc(publicationId: string, type: AssetDisplayType, source: string = SourceDetail.Biblio) {
        this.dispatch({
            type: AppActions.set_collection_tab.name,
            payload: {
                collectionTab: null,
            },
        });
        this.dispatch({
            type: AppActions.present_results.name,
            payload: {
                hitlist: null,
                primary: [AssetRef.create(type, null, { publicationId, source })],
                secondary: [],
            },
        });
    }

    private createUrl(type: BiblioType, lang: LanguageEnum) {
        return `${getBiblioBooksBaseUrl(type)}&language=${lang}`;
    }

    public getBooks(type: BiblioType): Observable<BiblioBook[]> {
        const url = this.createUrl(type, this.accountService.langEnum);
        return <Observable<BiblioBook[]>>this.biblioCache.getContent(url);
    }

    public getPeriodical(type: BiblioType): Observable<BiblioPeriodical[]> {
        const url = this.createUrl(type, this.accountService.langEnum);
        return <Observable<BiblioPeriodical[]>>this.biblioCache.getContent(url);
    }

    public getBookUni(type: BiblioType): Observable<BiblioBook[]> {
        const url = this.createUrl(type, this.accountService.langEnum);
        return <Observable<BiblioBook[]>>this.biblioCache.getContent(url);
    }

    public createTitleCellRenderer(source: string): (params) => string {
        return (params) => {
            const length = params.value.length;
            const lengthAdditionalLinks = params.value[0].value.additionalLinks ? params.value[0].value.additionalLinks.length : 0;
            const eDiv = document.createElement('div');
            eDiv.style.width = '100%';

            for (let i = 0; i < length; i++) {
                const publicationId = `${params.value[i].key}`;
                const title = params.value[i].value.title as string;
                const link = `/toc/publication/${publicationId}/${AssetRefOptions.sourceDetail}/${source}`;

                if (length === 1) {
                    if (lengthAdditionalLinks > 0) {
                        return this.addAdditionalLinks(params, eDiv, title);
                    }

                    // is unpublished CaseCollection
                    if (isEmtpyGuid(publicationId)) {
                        const linkElement = document.createElement('span');
                        linkElement.innerHTML = title;
                        return linkElement;
                    }

                    const linkElement = createHrefWrapperForCellrender(title, link);
                    return linkElement;
                }

                const linkElement = createHrefWrapperForCellrender(`${title} <br>`, link);

                if (params.value[i].value.linkDisabled) {
                    linkElement.setAttribute('style', 'overflow: hidden; text-overflow: ellipsis; display: block;');
                }
                else {
                    linkElement.id = publicationId;
                    linkElement.className = 'titleInMultipTitleRow';
                    linkElement.addEventListener('click', (event) => {
                        if (!event || !event.ctrlKey) {
                            this.openToc((<Element>event.target).id, AssetDisplayType.PeriodicalCollection);
                        }
                    });
                }

                eDiv.appendChild(linkElement);
            }
            if (lengthAdditionalLinks > 0) {
                return this.addAdditionalLinks(params, eDiv, null);
            }

            return eDiv;
        };
    }


    addAdditionalLinks(params, eDiv, title) {
        if (title) {
            const eA = document.createElement('a');
            eA.innerHTML = `${params.value[0].value.title} <br>`;
            eA.id = `${params.value[0].key}`;
            eA.className = 'titleInMultipTitleRow';
            eA.addEventListener('click', (event) => this.openToc((<Element>event.target).id, AssetDisplayType.PeriodicalCollection));
            eDiv.appendChild(eA);
        }

        const lengthAdditionalLinks = params.value[0].value.additionalLinks.length;
        for (let i = 0; i < lengthAdditionalLinks; i++) {
            const eA = document.createElement('a');
            eA.innerHTML = `${params.value[0].value.additionalLinks[i].key} <br>`;
            eA.href = `${params.value[0].value.additionalLinks[i].value}`;
            if (!eA.href.includes(config.apiBaseUrl)) {
                eA.target = '_blank';
            }
            eA.className = 'titleInMultipTitleRow';
            // eA.addEventListener('click', (event) => this.openToc(event.srcElement.id, AssetDisplayType.PeriodicalCollection));
            eDiv.appendChild(eA);
        }
        return eDiv;
    }

    logosCellRenderer(params) {
        const url = `${params.value}`;

        if (params.value) {
            const container = document.createElement('span');
            container.style.cssText = 'display: flex; align-items: center; justify-content: center; height: 100%; width: 100%;';

            const image = document.createElement('img');
            image.src = url;
            container.appendChild(image);

            return container;
        }
        return '';
    }

    getTitleFilterValue(params): string {
        const value = params.value.map(data => data.value.title).reduce((accumulator, currentValue) => accumulator + ' ' + currentValue);
        return value;
    }

    titleComperator(titleDict1, titleDict2) {
        return titleDict1[0].value.title.localeCompare(titleDict2[0].value.title);
    }

    public loadEuVerzeichnisse() {
        this.dispatch({ fetch: BibliothekActions.load_eu_verzeichnisse.name });
    }

    getRowHeight(params): number {
        let nTitles = params.data.titles.length;
        if (params.data.titles[0].value.additionalLinks) {
            nTitles += params.data.titles[0].value.additionalLinks.length;
        }

        if (nTitles < 3) {
            return 50;
        }
        return 50 + (nTitles - 2) * 20;
    }

    getRowClass(params): string {
        if (!params.data.titles || params.data.titles.length + (params.data.titles[0].value.additionalLinks ? params.data.titles[0].value.additionalLinks.length : 0) === 1) {
            return 'singleTitleRow';
        }
        return 'multiTitleRow';
    }

    public createNewsAbo(payload) {
        this.dispatch({ type: BibliothekActions.create_news_abo.name, payload: payload, navigate: ['/news'] });
    }
}
