module.exports = {
	"events-current": "Aktuelle Veranstaltungen",
	"events-search": "Suchen",
	"events-practiceAreas": "Rechtsgebiete",
	"events-add": "Eintragen",
	"events-current-date": "Datum",
	"events-current-title": "Titel",
	"events-current-city": "Ort",
	"events-current-practiceAreaList": "Rechtsgebiete",
	"events-search-title": "Suchen in Veranstaltungen",
	"events-search-filter": "Filtern Sie nach Praxisgebieten",
	"events-filter": "Suchen",
	"events-details": "Details",
	"events-details-more": "Weiterführend",
	"events-details-more-name": "Bücher zum Thema (externer Link)",
	"events-details-today": "(Findet heute statt)",
	"events-details-begin-date": "Beginn",
	"events-details-end-date": "Ende",
	"events-details-date": "Datum",
	"events-details-venue": "Standort",
	"events-details-city": "Stadt",
	"events-practicearea": "Rechtsgebiet",
	"events-details-back-to-overview": "Zurück zu allen Veranstaltungen",
	"events-details-add-to-calender": "Zum Kalender hinzufügen",
	"events-details-organization": "Veranstalter",
	"events-details-organization-fr": "Veranstalter (FR)",
	"events-details-link": "Link",
	"events-details-link-fr": "Link (FR)",
	"events-practiceareas-title": "Veranstaltungen nach Rechtsgebieten",
	"events-register": "Veranstaltung eintragen",
	"events-register-language": "Eingabesprache",
	"events-register-event-title": "Titel",
	"events-register-event-title-fr": "Titel (FR)",
	"events-register-city": "Stadt",
	"events-register-city-fr": "Stadt (FR)",
	"events-register-place": "Standort / Adresse",
	"events-register-common-data": "Allgemeine Angaben",
	"events-register-event-data": "Veranstaltunsdaten",
	"events-register-contact": "Kontakt",
	"events-register-begin": "Beginn, z.B.",
	"events-register-end": "Ende, z.B.",
	"events-register-time-start": "Uhrzeit Beginn, z.B. 08:00",
	"events-register-time-end": "Uhrzeit Ende, z.B. 17:00",
	"events-register-date-missing": "Bitte überprüfen Sie die Daten",
	"events-collapse-list": "Liste einklappen",
	"events-register-practice-areas": "Rechtsgebiete der Veranstaltung",
	"events-register-description-short": "Kurze Beschreibung",
	"events-register-description-short-fr": "Kurze Beschreibung (FR)",
	"events-register-description-detailled": "Detaillierte Beschreibung",
	"events-register-description-detailled-fr": "Detaillierte Beschreibung (FR)",
	"events-register-name": "Vor- und Nachname",
	"events-register-email": "E-Mail Adresse",
	"events-register-mandatory": "* Pflichtfelder",
	"events-register-german": "Deutsch",
	"events-register-french": "Französisch",
	"events-register-both": "Beidsprachig",
	"events-validation-email-invalid": "E-Mail Adresse ungültig",
	"events-validation-time": "Zeitformat ungültig",
	"events-validation-link": "Dieser Link ist ungültig",
	"events-register-hint-charcount": "Anzahl empfohlene Zeichen: 500",
	"events-schulthess-cooperation": "Dieses Angebot erfolgt in Kooperation mit der Schulthess Juristische Medien AG",
	"events-date-clear": "Datum zurücksetzen",
	"events-single-event-not-found": "Die gewünschte Veranstaltung wurde nicht gefunden",
	"events-time": "Uhr",
	"events-add-successful-message": "Veranstaltung erfolgreich eingetragen"
};