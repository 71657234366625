import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { AppState, makeAction, SlxAction } from '../models';

import { HomeActions } from './homeActions';



@Injectable()
export class HomeService {
    public actions = HomeActions;
    public eventsForHomeTiles: Observable<any> = this.store.select(state => state.home.eventsForHomeTiles);
    public topDocuments: Observable<any> = this.store.select(state => state.home.topDocuments);
    public homeTileNews: Observable<any> = this.store.select(state => state.home.homeTileNews);
    public topJobs: Observable<any> = this.store.select(state => state.home.jobsForHomeTiles.topJobs);
    public currentJobsForTiles: Observable<any> = this.store.select(state => state.home.jobsForHomeTiles.currentJobs);
    public faq: Observable<any> = this.store.select(state => state.home.faq);
    public activeMobileSidebarComponent: Observable<string> = this.store.select(state => state.home.activeMobileSidebarComponent);

    constructor(public store: Store<AppState>) { }

    dispatch(action) {
        return this.store.dispatch(makeAction(action as SlxAction));
    }

    getEventsForHomeTile() {
        this.dispatch({ type: this.actions.get_events_for_home_tile.name });
    }

    getTopDocuments() {
        this.dispatch({ type: this.actions.get_top_documents_for_home_tiles.name });
    }

    getNewsForHomeTile() {
        this.dispatch({ fetch: this.actions.get_news_for_home_tile.name });
    }

    getJobsForHomeTiles() {
        this.dispatch({ type: this.actions.get_top_jobs_for_start_page.name });
    }

}
