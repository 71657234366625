import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Observable ,  of ,  Subscription } from 'rxjs';


import { AccountService } from '../../../account';
import { AssetAttachment, AssetTab, AssetTabstrip, determineSelectorBasedOnScrollInfo, DocScrollInfo, DocScrollType, DocumentContent, LawContent, LinkDisplayType } from '../../../models';
import {  isResponsive } from '../../../utility/utilityFunctions';
import { AssetService } from '../../asset.service';


const debug = require('debug')('docview');

@Component({
    selector: 'slx-docview-content',
    templateUrl: './docview-content.component.html',
    styleUrls: ['./docview-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 * Manages the HTML content of the document with regular DOM API
 */
export class DocViewContentComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
    @Input() tabArea: AssetTabstrip;
    @Input() assetTab: AssetTab;

    private currentSearchTermClass = 'currentSearchTerm';

    public isCurrentAsset = false;
    public showLowMeta = false;
    public showLowMetaText = 'rech-asset-showLowMetaTextMore';
    public tabState: any;

    private searchCollapsedSub: Subscription;
    private searchIsCollapsed: boolean;

    public docContent: DocumentContent;
    public lawContent: LawContent;
    private assetSub: Subscription;
    private scrollInfo: DocScrollInfo;
    private jumpToFirstSearchTerm: boolean;
    private hasScrolled: boolean;
    private multilanguageGroupCheckedLng: String;
    private userPrefSubs: Subscription;
    private loadedAsset: String;
    private currentSearchTermIndex = -1;
    private searchTerms: any;

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        // prevent scrollToSearch when search is open
        if(!this.searchIsCollapsed) {
            debug('prevented scrollToSearchTerm');
            return;
        }
        switch (event.key) {
            case 'p':
            case 'P':
                this.scrollToSearchTerm(false);
                break;
            case 'n':
            case 'N':
                this.scrollToSearchTerm(true);
                break;
        }
    }

    private removeClass(index: number){
        const searchTerm = this.searchTerms[index];

        if(searchTerm){
            searchTerm.classList.remove(this.currentSearchTermClass);
        }
    }

    private scrollToSearchTerm(next: boolean) {

        const index = next ? this.currentSearchTermIndex + 1 : this.currentSearchTermIndex -1;
        const searchTerm = this.searchTerms ? this.searchTerms[index] : null;
        if (searchTerm) {
            searchTerm.parentElement.scrollIntoView();
            searchTerm.classList.add(this.currentSearchTermClass);
            this.removeClass(this.currentSearchTermIndex);
            this.currentSearchTermIndex = index;
        }
    }


    constructor(private accountService: AccountService, public assetService: AssetService, public elRef: ElementRef, private changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.searchCollapsedSub = this.assetService.store.select(state => state.recherche.searchCollapsed).subscribe(searchCollapsed => {
            this.searchIsCollapsed = searchCollapsed;
        });

        this.userPrefSubs = this.accountService.profilePrefs.subscribe(prefs => {
            if (prefs) {
                this.jumpToFirstSearchTerm = prefs.isJumpToFirstSearchTerm;
            }
        });
        this.hasScrolled = false;
        debug('created docViewContent');
    }

    ngOnChanges() {
        if (this.assetTab) {
            if (!this.assetSub || (this.loadedAsset && this.loadedAsset !== this.assetTab.assetID)) {
                this.assetSub = this.assetService.getAsset(this.assetTab.assetRef).subscribe(asset => {
                    this.loadedAsset = asset.id;
                    this.determineScrollInfoAndNavigateToElement();
                    this.docContent = asset.content as DocumentContent;
                    this.lawContent = this.docContent as LawContent;
                    setTimeout(() => { this.determineSearchTerms(); this.changeDetectorRef.markForCheck(); });
                });
            }
            this.isCurrentAsset = (this.assetTab && this.tabArea && this.tabArea.currentAssetTab) && (this.assetTab.assetID === this.tabArea.currentAssetTab.assetID);

            if (this.isCurrentAsset && this.loadedAsset === this.assetTab.assetID) {
                this.determineScrollInfoAndNavigateToElement();
                setTimeout(() => this.determineSearchTerms());
            }

        }
    }

    ngOnDestroy() {
        this.userPrefSubs.unsubscribe();
        this.assetSub.unsubscribe();
        this.searchCollapsedSub.unsubscribe();
        debug('destroyed docViewContent');
    }

    areSameScrollInfos(info1: DocScrollInfo, info2: DocScrollInfo): boolean {
        return info1.scrollType === info2.scrollType && info1.value === info2.value;
    }

    private determineSearchTerms() {
        const docText = <HTMLElement>document.querySelector(`[id="doc_${this.assetTab.assetID}"]`);
        if (!docText) {
            return;
        }
        this.searchTerms = docText.querySelectorAll('span.highlight');
    }

    private determineScrollInfoAndNavigateToElement() {
        if (!this.assetTab.scrollInfo && this.jumpToFirstSearchTerm && !this.hasScrolled) {
            this.scrollInfo = { scrollType: DocScrollType.FirstSearchTerm, value: '' };
            this.navigateToElement();
        }
        else if (this.isCurrentAsset && this.assetTab.assetID && this.assetTab.scrollInfo && (!this.hasScrolled || !this.areSameScrollInfos(this.scrollInfo, this.assetTab.scrollInfo))) {
            this.scrollInfo = this.assetTab.scrollInfo;
            this.navigateToElement();
        } else if (this.isCurrentAsset && this.assetTab.assetID && isResponsive()){
            this.scrollInfo = { scrollType: DocScrollType.DocumentStart, value: '' };
            this.navigateToElement();
        }
    }

    ngAfterViewChecked(): void {
        if (this.assetTab) {
            const cachedAsset = this.assetService.getCachedAsset(this.assetTab.assetRef);
            if (!cachedAsset) {
                return;
            }
            const docContent = cachedAsset.content as DocumentContent;
            if (docContent.documentID != null) {
                const id = '#edoctitle_' + docContent.documentID;
                window.location.hash = id;
            }
        }
        // if the current accountService.lang is not available in the multilanguagegroup,
        // force .show-all on the group to display all entries to prevent empty display
        // test document: http://localhost:4200/doc/commentarydoc/f07251ca-93af-44a0-9f83-e8dbecd98109
        if (this.multilanguageGroupCheckedLng !== this.accountService.lang) {
            const multilanguagegroup = document.querySelector(`div[id='${this.assetTab.assetID}'] .multilanguagegroup`);
            if (multilanguagegroup) {
                const languagePart = multilanguagegroup.querySelector(`.languageparts .languagePart_${this.accountService.lang}`);
                if (!languagePart) {
                    multilanguagegroup.classList.add('userSelection', 'show-all');
                }
            }
            this.multilanguageGroupCheckedLng = this.accountService.lang;
        }
    }


    navigateToElement() {
        const elementSelector = determineSelectorBasedOnScrollInfo(this.scrollInfo);

        if (elementSelector) {
            setTimeout(() => {
                if (this.scrollInfo) {
                    this.hasScrolled = true;

                    const docText = this.scrollInfo.scrollType === DocScrollType.DocumentStart ? document : <HTMLElement>document.querySelector(`[id="doc_${this.assetTab.assetID}"]`);
                    if (!docText) {
                        return;
                    }
                    const anchor = <HTMLElement>docText.querySelector(elementSelector);
                    if (!anchor) {
                        return;
                    }

                    if (this.scrollInfo.scrollType === DocScrollType.EdocTitleElement || this.scrollInfo.scrollType === DocScrollType.DocumentStart) {
                        anchor.scrollIntoView({ block: 'start', behavior: 'smooth' });
                        return;
                    } else {
                        anchor.parentElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
                        if (this.scrollInfo.scrollType === DocScrollType.FirstSearchTerm) {
                            anchor.classList.add(this.currentSearchTermClass);
                            this.currentSearchTermIndex = 0;
                        }
                    }

                    Promise.resolve(null).then(() => { anchor.classList.add('highlight'); });
                }
            });
        }
    }

    toggleLowMeta(): void {
        this.showLowMeta ? (this.showLowMeta = false, this.showLowMetaText = 'rech-asset-showLowMetaTextMore') :
            (this.showLowMeta = true, this.showLowMetaText = 'rech-asset-showLowMetaTextLess');
    }

    getDocumentAttachments(): Observable<AssetAttachment[]> {
        return this.docContent ? of(this.docContent.attachments.filter(x => x.position === LinkDisplayType.onDocument)) : of([]);
    }
}
