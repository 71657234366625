
import { enhanceActionsObject, FieldState, ThesaurusInformation, ThesaurusToggleInformation, ThesaurusToggleItem, ThesaurusTogglePayload } from '../models';

export const ImmerFieldActions = {

    load_thesaurus_information(draftState: FieldState, { payload, result }: { payload: ThesaurusInformation, result: any }): void {
        if (result) {
            draftState.thesaurusInformation = payload;
        }
    },

    thesaurus_toggle_information(draftState: FieldState, { payload }: { payload: ThesaurusTogglePayload }): void {


        const foundInfo = findToggleInformation(draftState.thesaurusInformation, payload);
        if (!foundInfo) {
            return;
        }

        switch (payload.toggleItem) {
            case ThesaurusToggleItem.Term:
                foundInfo.term.open = !foundInfo.term.open;
                break;

            case ThesaurusToggleItem.BaseForm:
                if (foundInfo.baseForm) {
                    foundInfo.baseForm.isSelected = !foundInfo.baseForm.isSelected;
                }
                break;

            case ThesaurusToggleItem.Translation:
                if (foundInfo.translation) {
                    foundInfo.translation.isSelected = !foundInfo.translation.isSelected;

                    foundInfo.translation.translationResults.forEach(tr => {
                        tr.isSelected = foundInfo.translation.isSelected;
                    });

                }
                break;

            case ThesaurusToggleItem.TranslationResult:
                if (foundInfo.translationResult) {
                    foundInfo.translationResult.isSelected = !foundInfo.translationResult.isSelected;

                    foundInfo.translation.isSelected = foundInfo.translation.translationResults.every(tr => tr.isSelected);
                }
                break;
        }
    },

};
enhanceActionsObject(ImmerFieldActions);

function findToggleInformation(thesaurusInformation: ThesaurusInformation, toggleInformation: ThesaurusToggleInformation): ThesaurusToggleInformation {

    const term = thesaurusInformation.terms.find(t => t.term === toggleInformation.term.term);
    const baseForm = term && toggleInformation.baseForm ? term.baseForms.find(bf => bf.word === toggleInformation.baseForm.word) : null;
    const translation = baseForm && toggleInformation.translation ? baseForm.translations.find(t => t.language === toggleInformation.translation.language) : null;
    const translationResult = translation && toggleInformation.translationResult ? translation.translationResults.find(tr => tr.word === toggleInformation.translationResult.word) : null;

    return term ? { term, baseForm, translation, translationResult } : null;

}


