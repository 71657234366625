import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms'; // FormBuilder, Validators
import { TranslateService } from '@ngx-translate/core';

import { AutocompleteService } from '../../../asset';
import { requiredValidator } from '../../../field';
import { AutocompleteParamter, AutocompleteType, SearchType } from '../../../models';
import { SearchService } from '../../search.service';
import { SearchFormBase } from '../search-form-base';


@Component({
    selector: 'slx-commentary',
    template: `<slx-search-form *ngIf="formDescription && searchForm" [formDescription]="formDescription" [searchForm]="searchForm" (searchSubmitted)="onSubmit()" (searchReset)="onReset()"></slx-search-form>`,
})
export class CommentaryComponent extends SearchFormBase {

    constructor(protected searchService: SearchService, protected autocompleteService: AutocompleteService, protected formBuilder: FormBuilder, protected changeDetectorRef: ChangeDetectorRef, protected translate: TranslateService) {
        super(searchService, SearchType.Commentary, changeDetectorRef, translate);

        // bind autocomplete get url functions (this)
        this.getSerialAutocomplete = this.getSerialAutocomplete.bind(this);
        this.getTitleAutocomplete = this.getTitleAutocomplete.bind(this);
        this.getLawAutocomplete = this.getLawAutocomplete.bind(this);
        this.getArticleAutocomplete = this.getArticleAutocomplete.bind(this);

        // Create searchForm with Formbuilder
        this.searchForm = this.formBuilder.group({
            commentarySerial: [''],
            commentaryTitle: [''],
            commentaryLaw: [''],
            commentaryArticleNumber: [''],
            language: 'de',
        });

        this.controlDependencies = ['commentarySerial','commentaryTitle','commentaryLaw','commentaryArticleNumber'];

        this.formDescription = {
            formGroups: [
                {
                    controls: [
                        {
                            control: 'slx-text', name: 'CommentarySerial', type: 'text', placeholder: 'rech-series', formControlName: 'commentarySerial',
                            makeAutocomplete: this.getSerialAutocomplete, autocompleteMinQueryLength: 0, textChanged: this.resetDependencies('commentarySerial'),
                        },
                        {
                            control: 'slx-text', name: 'CommentaryTitle', type: 'text', placeholder: 'rech-title', formControlName: 'commentaryTitle', textChanged: this.resetDependencies('commentaryTitle'),
                            makeAutocomplete: this.getTitleAutocomplete, autocompleteMinQueryLength: 0,
                        },
                        {
                            control: 'slx-text', name: 'CommentaryLaw', type: 'text', placeholder: 'rech-law', formControlName: 'commentaryLaw', textChanged: this.resetDependencies('commentaryLaw'),
                            makeAutocomplete: this.getLawAutocomplete, autocompleteMinQueryLength: 0,
                        },
                        {
                            control: 'slx-text', name: 'CommentaryArticleNumber', type: 'text', placeholder: 'rech-article', formControlName: 'commentaryArticleNumber',
                            makeAutocomplete: this.getArticleAutocomplete, autocompleteMinQueryLength: 0,
                        },
                    ],
                },
            ],
            controls: {
                resetControl: true,
            },
            searchButtonText: 'rech-display',
        };

        this.validationKeys = [
            'commentarySerial',
            'commentaryTitle',
            'commentaryLaw',
        ];
    }

    getSerialAutocomplete() {
        return () => {
            const paramters = [
                new AutocompleteParamter('commentarySerial', this.searchForm.value.commentarySerial),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.CommentarySerial, true, paramters);
        };
    }

    getTitleAutocomplete() {
        return () => {
            const paramters = [
                new AutocompleteParamter('commentarySerial', this.searchForm.value.commentarySerial),
                new AutocompleteParamter('commentaryTitle', this.searchForm.value.commentaryTitle),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.CommentaryTitle, true, paramters);
        };
    }

    getLawAutocomplete() {
        return () => {
            const paramters = [
                new AutocompleteParamter('commentarySerial', this.searchForm.value.commentarySerial),
                new AutocompleteParamter('commentaryTitle', this.searchForm.value.commentaryTitle),
                new AutocompleteParamter('commentaryLaw', this.searchForm.value.commentaryLaw),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.CommentaryLaw, true, paramters);
        };
    }

    getArticleAutocomplete() {
        return () => {
            const paramters = [
                new AutocompleteParamter('commentarySerial', this.searchForm.value.commentarySerial),
                new AutocompleteParamter('commentaryTitle', this.searchForm.value.commentaryTitle),
                new AutocompleteParamter('commentaryLaw', this.searchForm.value.commentaryLaw),
                new AutocompleteParamter('commentaryArticleNumber', this.searchForm.value.commentaryArticleNumber),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.CommentaryArticle, true, paramters);
        };
    }
}
