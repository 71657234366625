import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AssetRef, AssetTab, AssetTabstrip } from '../models';
import { isResponsive } from '../utility/utilityFunctions';

import { AssetService } from './asset.service';
import { AssetActions } from './assetActions';

@Component({
    selector: 'slx-asset-tabs',
    templateUrl: './asset-tabs.component.html',
    styleUrls: ['./asset-tabs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [   // :enter is alias to 'void => *'
                style({ opacity: 0 }),
                animate(800, style({ opacity: 1 })),
            ]),
            transition(':leave', [   // :leave is alias to '* => void'
                animate(800, style({ opacity: 0 })),
            ]),
        ]),
    ],
})
export class AssetTabsComponent implements OnChanges {

    @Input() tabArea: AssetTabstrip;
    @Input() isPrimary: boolean;

    public currentAssetId: string;

    constructor(private assetService: AssetService) { }

    ngOnChanges() {
        this.currentAssetId = this.tabArea.currentAssetTab.assetID;

        if (!this.tabArea.currentAssetTab || this.tabArea.currentAssetTab.isLoading) {
            return;
        }

        if (isResponsive() && this.tabArea.currentAssetTab.assetID !== this.currentAssetId) {
            const splitView = document.querySelector('.split-view');
            if (splitView) {
                // TODO
                setTimeout(() => { splitView.scrollIntoView({ behavior: 'smooth' }); });
            }
        }
    }

    // Track By - here we can see that is called far too often (I think)
    trackTab(index, tab) {
        return tab.assetID;
    }

    selected(item) {
        const ref = item.assetRef, isPrimary = this.isPrimary;

        this.assetService.store.dispatch({
            type: AssetActions.new_asset_tab_changed.name,
            payload: { ref, isPrimary },
        });
    }

    closeTab(item) {
        const assetID = item.assetID, isPrimary = this.isPrimary;

        this.assetService.store.dispatch({
            type: AssetActions.close_tab.name,
            payload: { assetID, isPrimary },
        });
    }
}
