import { Action } from '@ngrx/store';
import produce from 'immer';
import * as Raven from 'raven-js';

import { environment } from '../environments/environment';

import { AccessActions } from './access';
import { AccountActions } from './account';
import { alert, AppActions, updateAppErrors } from './appActions';
import { AssetActions } from './asset';
import { BibliothekActions } from './bibliothek';
import { CustomerProfileActions } from './customer-profile';
import { EventsActions } from './events';
import { ImmerFieldActions } from './field';
import { HomeActions } from './home';
import { AboServicesActions } from './home/abo-services';
import { JobsActions } from './jobs';
import { defaultAppState, isRequestAction, SlxAction, updateLastStateOfActions } from './models';
import { AlertType } from './models/state/commonApp';
import { NewsActions } from './newsservice';
import { SearchActions } from './recherche';
import { determineRandomKey } from './utility/utilityFunctions';

export function access(state, action: SlxAction) {
 //   if (AccessActions[action.baseType || action.type]) {
        return AccessActions['call'](state, action);
 //   }
  //  return produce(state, drafState => ImmerAccessActions['call'](drafState, action));
}

export function recherche(state, action: SlxAction) {
    return SearchActions['call'](AssetActions['call'](state, action), action);
}
export function customerProfile(state, action: SlxAction) {
    return CustomerProfileActions['call'](state, action);
}
export function account(state, action: SlxAction) {
    return AccountActions['call'](state, action);
}
export function home(state, action: SlxAction) {
    return HomeActions['call'](state, action);
}
export function events(state, action: SlxAction) {
    return EventsActions['call'](state, action);
}
export function jobs(state, action: SlxAction) {
    return JobsActions['call'](state, action);
}
export function biblio(state, action: SlxAction) {
    return BibliothekActions['call'](state, action);
}
export function news(state, action: SlxAction) {
    return NewsActions['call'](state, action);
}
export function aboService(state, action: SlxAction) {
    return AboServicesActions['call'](state, action);
}

export function field(state, action: SlxAction) {
    return produce(state, drafState => ImmerFieldActions['call'](drafState, action));
}


export function app(state, action: SlxAction) { // dummy reducer inorder to get the app propertiy in the state
    return state;
}

export const reducers = {
    app,
    access,
    recherche,
    events,
    jobs,
    biblio,
    news,
    account, customerProfile, home, aboService, field,
};

export function appMetaReducer(reducer) {
    return (state, action) => {
        try {

            state = reducer(state || defaultAppState, action);

            if (isRequestAction(action)) {
                state = updateLastStateOfActions(state, action);
            }

            if (action.alert) {
                state = alert(state, { payload: action.alert });
            }

            if (action.error) {
                state = updateAppErrors(state, action);
            }

            const fn = action.error ? AppActions[action.type] : AppActions[action.type] || AppActions[action.baseType];
            return fn ? (fn.fn || fn).call(AppActions, state, action) : state;
        }
        catch (ex) {
            if (environment.production || !window.confirm(`An Exception in the reducer has occurred:\n${ex}\n\n(OK) proceed without catching (devmode behaviour)\n(Cancel) catch and continue (production mode behaviour)`)) {

                if (!environment.production) {
                    // tslint:disable-next-line:no-console
                    console.error(ex);
                }

                const storeRestoreKey = determineRandomKey();
                Raven.captureException(ex, {
                    tags: { type: 'error in reducer' },
                    extra: { storeRestoreLink: `http://localhost:4200/storerestore?idOrKey=${storeRestoreKey}`, action: action },
                });

                // preventing any effects from happening
                action.type = `${action.type}_ERROR`;

                //displaying an alert that something went wrong
                state = alert(state, { payload: { type: AlertType.Error, key: 'account-undefined-error', duration: 10 } });
                return { ...state, app: { ...state.app, storeRestoreKey } };
            }
            throw ex;
        }
    };
}




export function chainReducers(...chain) {
    return function (startState, action) {
        if (!startState) {
            return defaultAppState.recherche;
        }

        return chain.reduce((state, fn) => fn(state, action), startState);
    };
}
