import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatStepper } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { Subscription } from 'rxjs';

import { requiredValidator } from '../../field';
import { NewsType } from '../../models';
import { SearchActions } from '../../recherche';
import { NewsService } from '../news.service';
import { NewsActions } from '../newsActions';


const debug = require('debug')('slx-wizard');

function requiredAreas(key: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return control.value.length > 0 ? null : { required: { translate: key } };
    };
}

@Component({
    selector: 'slx-news-wizard',
    templateUrl: './news-wizard.component.html',
    styleUrls: ['./news-wizard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsWizardComponent implements OnInit, OnDestroy {

    private subscription: Subscription = new Subscription();

    @ViewChild(MatStepper) activeStepper: MatStepper;

    private wizardPracticeArea: any;
    private wizardJournals: any;

    public title: string;
    public configuration: FormGroup;
    public stepperIsActive = false;

    public stepperId: number;
    public showValidation = false; // Destroys Validationcomponent
    public validationCompleted = false;
    public searchIsLinear = true;
    public newsCourts: any;
    public newsPracticeAreas: any;

    constructor(
        public dialogRef: MatDialogRef<NewsWizardComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public newsService: NewsService, private fb: FormBuilder, private translate: TranslateService, private cd: ChangeDetectorRef) {

        this.configuration = this.fb.group({
            title: ['', requiredValidator()],
            type: this.fb.group({
                description: [''],
                value: ['', requiredValidator('')],
            }),
            practiceAreas: ['', requiredAreas('')],
            courts: ['', requiredValidator('')],
            publication: this.fb.group({
                publicationID: ['', requiredValidator('')],
            }),
            searchFilter: this.fb.group({
                searchText: [''],
                searchLanguage: [''],
                articleOfLawFilterCriterias: this.fb.array([this.initArticleOfLawCriterias()]),
                dateFrom: [''],
                reference: [''],
                author: [''],
                practiceAreaGroupsCriteria: [''],
                assetTypeGroupsCriteria: [''],
                publicationFilter: [''],
                thesaurusType: [''],
            }),
            deliveryConfiguration: this.fb.group({
                sendAsEmail: [true],
                deliveryDay: ['Monday'],
                timeWindow: [28],
                sendOnlyOnHits: [false],
            }),
            docLanguages: [[1, 2, 3, 4], requiredValidator('news-validator-selectLanguage')],
        });
    }

    ngOnInit(): void {
        this.subscription
            .add(this.newsService.newsPracticeAreas.subscribe(res => this.wizardPracticeArea = res))
            .add(this.configuration.valueChanges.subscribe(() => {
                this.validationCompleted = false;
                this.searchIsLinear = true;
            }))
            .add(this.newsService.newsCourts.subscribe(res => this.newsCourts = res))
            .add(this.newsService.newsPracticeAreas.subscribe(res => this.newsPracticeAreas = res));
        if (this.data.wizardType === 'newWithData' || this.data.wizardType === 'edit') {
            this.stepperId = this.data.configuration.type.value;
            this.toggleStepper(true);

            if (this.data.configuration.searchFilter.articleOfLawFilterCriterias) {
                for (let i = 1; i < this.data.configuration.searchFilter.articleOfLawFilterCriterias.length; i++) {
                    this.addArticleOfLawCriteria();
                }
            }

            if (this.data.configuration.searchFilter.bookCriteria || this.data.configuration.searchFilter.journalCriteria || this.data.configuration.searchFilter.caseCollectionCriteria) {
                this.data.configuration.searchFilter.publicationFilter = {
                    bookCriteria: this.data.configuration.searchFilter.bookCriteria,
                    journalCriteria: this.data.configuration.searchFilter.journalCriteria,
                    caseCollectionCriteria: this.data.configuration.searchFilter.caseCollectionCriteria,
                };
            }

            this.configuration.patchValue(this.data.configuration);

            switch (this.stepperId) {
                case NewsType.Journal:
                    if (this.data.wizardType === 'edit') {
                        this.subscription.add(this.publication.valueChanges.subscribe((pub) => {
                            if (this.wizardJournals) {
                                const selectedJournal = this.wizardJournals.filter(journal => journal.id === pub.publicationID);
                                this.configuration.controls.title.setValue(selectedJournal[0].publicationName);
                            }
                        }));
                        this.publication.controls.publicationID.setValue(this.data.configuration.publicationID);
                    }
                    this.languages.setValidators(null);
                    break;
                case NewsType.PracticeArea:
                    this.publication.controls.publicationID.setValidators(null);
                    break;
                case NewsType.Search:
                    this.newsService.dispatch({ type: SearchActions.verify_publication_filter_options.name });
                    this.publication.controls.publicationID.setValidators(null);
                    this.searchIsLinear = false;
                    break;
                case 5: // Court
                    this.publication.controls.publicationID.setValidators(null);
            }
        }
        else {
            this.subscription.add(
                this.typeValue.valueChanges.subscribe(
                    newsletterType => {
                        if (!newsletterType) {
                            return;
                        }

                        this.stepperId = newsletterType;
                        setTimeout(() => this.toggleStepper(true), 50); //On Purpose
                        switch (newsletterType) {
                            case 1: // Journals
                                this.subscription.add(this.publication.valueChanges.subscribe((pub) => {
                                    const selectedJournal = this.wizardJournals.filter(journal => journal.id === pub.publicationID);
                                    this.configuration.controls.title.setValue(selectedJournal[0].publicationName);
                                }));
                                this.languages.setValidators(null);
                                break;

                            case 3: // Search
                                this.newsService.dispatch({ type: SearchActions.verify_publication_filter_options.name });
                                break;
                        }
                    })
            );

        }
    }


    ngAfterViewInit(): void {
        this.subscription
            .add(this.newsService.newsJournals.subscribe(res => {
                this.wizardJournals = res;
                if (this.data.wizardType === 'newWithData' && this.stepperId === 1) {
                    const selectedJournal = this.wizardJournals.filter(journal => journal.id === this.data.configuration.publication.publicationID);
                    if (selectedJournal.length > 0) {
                        this.configuration.controls.title.setValue(selectedJournal[0].publicationName);
                        this.cd.detectChanges();
                    }
                }
            }));
    }

    public get userSelection() {
        if (this.data.configuration) {
            return this.data.configuration.searchFilter.publicationFilter;
        } else {
            return null;
        }
    }

    private get searchText(): FormControl {
        return this.searchFilters.get('searchText') as FormControl;
    }

    public get newsletterTitle(): FormControl {
        return this.configuration.get('title') as FormControl;
    }

    public get type(): FormGroup {
        return this.configuration.get('type') as FormGroup;
    }

    public get typeValue(): FormControl {
        return this.configuration.get('type.value') as FormControl;
    }

    public get practiceAreas(): FormControl {
        return this.configuration.get('practiceAreas') as FormControl;
    }

    public get courts(): FormControl {
        return this.configuration.get('courts') as FormControl;
    }

    public get publication(): FormGroup {
        return this.configuration.get('publication') as FormGroup;
    }

    public get deliveryConfiguration(): FormGroup {
        return this.configuration.get('deliveryConfiguration') as FormGroup;
    }

    public get searchFilters(): FormGroup {
        return this.configuration.get('searchFilter') as FormGroup;
    }

    public get articleOflawFilterCriteras(): FormArray {
        return this.searchFilters.get('articleOfLawFilterCriterias') as FormArray;
    }

    public get languages(): FormControl {
        return this.configuration.get('docLanguages') as FormControl;
    }

    private initArticleOfLawCriterias(): FormGroup {
        return this.fb.group({
            law: [''],
            articleNumber: [''],
            paragraph: [''],
            subParagraph: [''],
        });
    }

    public addArticleOfLawCriteria(): void {
        this.articleOflawFilterCriteras.push(this.initArticleOfLawCriterias());
    }

    public removeArticleOfLawCriterias(index: number): void {
        this.articleOflawFilterCriteras.removeAt(index);
    }

    @HostListener('document:keyup', ['$event'])
    keyUpEvent(event: KeyboardEvent) {
        if (this.activeStepper) {

            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                if (this.activeStepper.selectedIndex + 1 >= this.activeStepper._steps.length) {
                    this.onSubmit();
                }
                else if (this.typeValue.value === 3 && this.activeStepper.selectedIndex === 0) {
                    this.setNewsletterTitleForSearch();
                    this.activeStepper.next();
                }

                else if (this.typeValue.value === 2 && this.activeStepper.selectedIndex === 0) {
                    this.setNewsletterTitleForPAs();
                    this.activeStepper.next();
                }
                else {
                    this.activeStepper.next();
                }
            }
        }
    }

    // prevent first change of pubselect to trigger valuechange
    private publicationSelectChangesCounter = 0;
    public saveChanges(data) {
        this.publicationSelectChangesCounter++;
        this.searchFilters.controls['publicationFilter'].setValue(data, { emitEvent: this.publicationSelectChangesCounter === 1 ? false : true });
    }

    public toggleStepper(value: boolean): void {
        this.stepperIsActive = value;
        this.setTitle();
        this.cd.markForCheck();
    }

    public evaluateSearchResult(error: boolean): void {
        this.validationCompleted = error ? false : true;
    }

    public searchSelectionChanged(step): void {
        if (step.selectedIndex === 3) {
            if (this.searchFilters.value.publicationFilter) {
                this.searchFilters.value.bookCriteria = this.searchFilters.value.publicationFilter.bookCriteria;
                this.searchFilters.value.caseCollectionCriteria = this.searchFilters.value.publicationFilter.caseCollectionCriteria;
                this.searchFilters.value.journalCriteria = this.searchFilters.value.publicationFilter.journalCriteria;
            }
            this.newsService.validateSearch(this.searchFilters.value);
            this.showValidation = true;
            this.setNewsletterTitleForSearch();
        } else {
            this.showValidation = false;
        }
    }
    public setNewsletterTitleForSearch(): void {
        if (!this.data || !this.data.configuration || !this.data.configuration.title) {
            const text = this.searchText.value;
            const searchTitle = this.translate.instant('news-Search') + (text ? `: ${text.substr(0, 60)}` : '');
            this.newsletterTitle.setValue(searchTitle);
        }
    }

    public courtSelectionChanged(step): void {
        if (step.selectedIndex === 1) {
            this.setNewsletterTitleForCourts();
        }
    }
    public setNewsletterTitleForCourts(): void {
        if (!this.data || !this.data.configuration || !this.data.configuration.title) {

            if (this.courts.value.length === 1) {

                for (const nc of this.newsCourts) {
                    const court = nc.children.find(c => c.id === this.courts.value[0]);
                    if (court){
                        this.newsletterTitle.setValue(`${nc.description} - ${court.description}`);
                        break;
                    }
                }
            }
            else {
                this.newsletterTitle.setValue(this.translate.instant('news-CaseLaw'));

            }

        }
    }


    public practiceAreasSelectionChanged(step): void {
        if (step.selectedIndex === 1) {
            this.setNewsletterTitleForPAs();
        }
    }

    private findSelectedPA(paGuid: string, newsPAs) {
        if (newsPAs.children && newsPAs.children.length > 0){
            for (const child of newsPAs.children) {
                const found = this.findSelectedPA(paGuid,child);
                if (found){
                    return found;
                }
            }
        }
        else {
            return newsPAs.id === paGuid ? newsPAs : null;
        }
    }

    public setNewsletterTitleForPAs(): void {

        if (!this.data || !this.data.configuration || !this.data.configuration.title) {
            if(this.practiceAreas.value.length === 1) {
                const selectedPa = this.findSelectedPA(this.practiceAreas.value[0], { children: this.newsPracticeAreas});
                this.newsletterTitle.setValue(selectedPa.description);
            }
            else {
                this.newsletterTitle.setValue(this.translate.instant('news-PracticeArea'));
            }

        }
    }
    private get validWizard(): boolean {
        switch (this.stepperId) {
            case NewsType.Journal:
                return this.publication.valid && this.deliveryConfiguration.valid && this.newsletterTitle.valid;

            case NewsType.PracticeArea:
                return this.practiceAreas.valid && this.deliveryConfiguration.valid && this.newsletterTitle.valid && this.languages.valid;

            case NewsType.Search:
                return this.searchFilters.valid && this.deliveryConfiguration.valid && this.newsletterTitle.valid && this.languages.valid;

            case NewsType.CaseLaw:
                return this.courts.valid && this.deliveryConfiguration.valid && this.newsletterTitle.valid && this.languages.valid;
        }
        return false;
    }

    public setTitle() {
        switch (this.stepperId) {
            case NewsType.Journal:
                this.title = 'news-Journal';
                break;
            case NewsType.PracticeArea:
                this.title = 'news-PracticeArea';
                break;
            case NewsType.Search:
                this.title = 'news-Search';
                break;
            case NewsType.CaseLaw:
                this.title = 'news-CaseLaw';
                break;
            default: this.title = null;
        }
    }

    public onSubmit(): void {

        if (this.validWizard) {
            this.configuration.value.type.description = NewsType[this.configuration.value.type.value];

            const tileConfiguration = {
                id: this.data.id || '',
                x: this.data.x,
                y: this.data.y,
                rows: this.data.rows,
                cols: this.data.cols,
                creationDate: this.data.creationDate || '',
                configuration: this.configuration.value,
            };

            delete tileConfiguration.configuration.publication;
            tileConfiguration.configuration.publicationID = this.publication.controls.publicationID.value;

            switch (this.data.wizardType) {
                case 'new':
                    tileConfiguration.configuration.showAsTile = true;
                    this.newsService.dispatch({ type: NewsActions.add_new_tile.name, payload: tileConfiguration });
                    break;
                case 'newWithData':
                    tileConfiguration.configuration.showAsTile = true;
                    this.newsService.dispatch({ type: NewsActions.add_new_tile.name, payload: tileConfiguration });
                    break;
                case 'edit':
                    tileConfiguration.configuration.showAsTile = this.data.configuration.showAsTile;
                    if (this.propsChanged) {
                        this.newsService.dispatch({ type: NewsActions.update_and_fetch_tile_configuration.name, payload: tileConfiguration });
                    }
                    break;
            }

            this.dialogRef.close();
        }
        else {
            if (!this.configuration.valid) {
                Object.keys(this.configuration.controls).forEach(field => {
                    const control = this.configuration.get(field);
                    control.markAsTouched({ onlySelf: true });
                    return;
                });
            }
        }
    }

    private propsChanged(tileConfiguration: any): boolean {
        return !isEqual(tileConfiguration.configuration.practiceAreaGroups, this.data.configuration.practiceAreaGroups)
            || !isEqual(tileConfiguration.configuration.deliveryConfiguration, this.data.configuration.deliveryConfiguration)
            || (tileConfiguration.configuration.publication !== undefined || this.data.configuration.publication !== undefined)
            && ((tileConfiguration.configuration.publication !== undefined && this.data.configuration.publication === undefined)
                || (tileConfiguration.configuration.publication === undefined && this.data.configuration.publication !== undefined)
                || !(tileConfiguration.configuration.publication.publicationID === this.data.configuration.publication.publicationID)
            );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
