import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AboRegistrationService } from './abo-services.service';

@Injectable()
export class AboServicesResolver implements Resolve<boolean> {

    constructor(private aboRegistrationService: AboRegistrationService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const path = route.url[0].path;
        this.aboRegistrationService.loadDataBasedOnPath(path);
        return true;
    }
}
