export class Image {
    public source: string;
    public mimeType: string;

    constructor(source: string, mimeType: string, base64?: string) {
        this.source = source;
        this.mimeType = mimeType;
        if (!this.source && !this.mimeType && base64) {
            this.setBase64(base64);
        }
    }

    public setBase64(base64: string) {
        const matchSource = /^data:[^\/]+\/[^;]+;base64,/.exec(base64);
        this.source = matchSource ? base64.substring(matchSource.index + matchSource[0].length) : this.source;

        const matchMimeType = /^(data:)([^\/]+\/[^;]+);base64,/.exec(base64);
        this.mimeType = matchMimeType ? base64.substr(matchMimeType.index + matchMimeType[1].length, matchMimeType[2].length) : this.mimeType;
    }

    public getBase64(): string {
        return this.source && this.mimeType ? `data:${this.mimeType};base64,${this.source}` : '';
    }
}
