import { TransactionType } from './enums';
import { SearchType } from './research';

export abstract class TransactionInfoBase {
    transactionType: TransactionType;
}

export class SearchTransactionInfo extends TransactionInfoBase {
    searchFilter: any;
    searchType: SearchType;
}

export class TocViewTransactionInfo extends TransactionInfoBase {
    publicationID: string;
    collectionAssetID: string;
}

export class DocViewTransactionInfo extends TransactionInfoBase {
    assetID: string;
    subID: string;
}

