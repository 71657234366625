import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CellClickedEvent, ColumnApi, GridOptions, GridReadyEvent, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { Observable, Subscription } from 'rxjs';

import { LoginService } from '../../access';
import { AssetDisplayType, BiblioBook, BiblioType, SourceDetail } from '../../models';
import { BibliothekService } from '../bibliothek.service';

@Component({
    selector: 'slx-book-uni-bibliothek',
    template: `
        <slx-grid *ngIf="hasUserTeachingBook"
            [options] = "gridOptions"
            [columnDefs] = "columnDefs"
            [rowTranslatePrefix]="'biblio'"
            [rowData]="bookList"
            [isBiblio]="true"
            (rowClicked)="onRowClicked($event)"
            >
        </slx-grid>
        `,
        // [getRowHeight]="bibliothekService.getRowHeight"
        // [getRowClass]="bibliothekService.getRowClass"
        // (cellClicked)="onCellClicked($event)"
    styleUrls: ['./bookUniBibliothek.component.scss'],
})

export class BookUniBibliothekComponent implements OnInit, OnDestroy {
    public gridOptions: GridOptions;
    public gridColumnApi: ColumnApi;
    public columnDefs;
    public bookList: Observable<BiblioBook[]>;

    private cacheClearedSubscribtion: Subscription;
    private subscription: Subscription = new Subscription();
    public hasUserTeachingBook: boolean;

    constructor(private translate: TranslateService, public loginService: LoginService, public bibliothekService: BibliothekService) {
        this.onGridReady = this.onGridReady.bind(this);

        this.gridOptions = <GridOptions>{
            headerHeight: 50,
            rowHeight: 50,
            rowClass: 'singleTitleRow',
            groupUseEntireRow: true,
        };
    }

    ngOnInit(): void {
        this.columnDefs = [
            { headerName: 'title', field: 'title', minWidth: 350, maxWidth: 1100, suppressMenu: true },
            { headerName: 'Author / Editor', field: 'author', minWidth: 200, maxWidth: 700, suppressMenu: true },
            { headerName: 'edition', field: 'edition', minWidth: 80, maxWidth: 100, suppressMenu: true },
            { headerName: 'year', field: 'year', minWidth: 80, maxWidth: 100, suppressMenu: true },
            { headerName: 'publisher', field: 'publisher', minWidth: 150, maxWidth: 600, suppressMenu: true },
        ];

        this.subscription
        .add(this.loginService.hasUserTeachingBook.subscribe(hasUserTeachingBook => {
            this.hasUserTeachingBook = hasUserTeachingBook;
            this.bookList = this.bibliothekService.getBookUni(BiblioType.BooksUni);
        }));

        if(this.hasUserTeachingBook){
            this.bookList = this.bibliothekService.getBookUni(BiblioType.BooksUni);
            this.cacheClearedSubscribtion = this.bibliothekService.cacheCleared.subscribe(cacheCleared => this.bookList = this.bibliothekService.getBookUni(BiblioType.BooksUni));
        }

    }

    public onGridReady(params: GridReadyEvent) {
        this.gridColumnApi = params.columnApi;
    }

    ngOnDestroy() {
        if(this.cacheClearedSubscribtion){
            this.cacheClearedSubscribtion.unsubscribe();
        }
        this.subscription.unsubscribe();
    }

    //-------------------------------------------------------------------------------------
    // Formatters, Comperators, CellRenderers Header Translations
    //-------------------------------------------------------------------------------------

    private publisherCellRenderer(params): HTMLElement {
        const title = document.createElement('span');
        title.style.cssText =
            'text-overflow: ellipsis; overflow: hidden; padding-right: 1rem;';
        title.innerHTML = title.title = params.value;
        return title;
    }

    //-------------------------------------------------------------------------------------
    // Events & Listeners
    //-------------------------------------------------------------------------------------

    public onRowClicked(ev: RowClickedEvent): void {
        const mouseEvent = ev.event as MouseEvent;
        if (!mouseEvent || !mouseEvent.ctrlKey) {
            if (ev.data) {
                this.bibliothekService.openToc(ev.data.publicationId, AssetDisplayType.Book, SourceDetail.BooksUniBiblio);
            }
            else {
                ev.node.setExpanded(!ev.node.expanded);
            }
        }
    }

}
