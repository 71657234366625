import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { AppService } from '../../app.service';
import { AppActions } from '../../appActions';
import { AppState } from '../../models';
import { Alert, AlertType } from '../../models/state/commonApp';

//NOTE: CyN: https://github.com/angular/angular/issues/10420 work-around for FF. shorthands such as margin, padding and border-width must be written-out
const LEAVE_STYLE = style({ transform: 'scale(.5)', opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0, marginTop: 0, marginBottom: 0, borderTopWidth: 0, borderBottomWidth: 0 });
//
@Component({
    selector: 'slx-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss'],
    animations: [
        trigger('alertAnim', [
            transition(':leave', [
                animate('0.4s ease', LEAVE_STYLE),
            ]),
        ]),
    ],
})
export class AlertsComponent implements OnInit {
    public alerts  = this.store.select(state => state.app.alerts);

    @HostListener('document:click', ['$event'])
    @HostListener('document:keypress', ['$event'])
    onEvent(ev) {
        if (ev.target.closest('.alert')) return;

        this.appService.dismissTransientAlerts();
    }

    constructor(private store: Store<AppState>, private translate: TranslateService, private appService: AppService) { }

    ngOnInit() { }

    trackAlerts(index, item) {
        return item.id;
    }
}
