import { AssetDisplayType, AssetRefOptions, DocScrollType, HitType, RefType } from './../enums';
import { AssetMetaData } from './assetMetaData';
import { DocumentContent } from './documentContent';
import { TocContent } from './tocContent';

export class CachedAsset {
    asset: Asset;
    timeStamp: Date;
}

export class Asset {
    id: string;
    type: AssetType;
    displayType: AssetDisplayType;
    transactionID: number;
    content: AssetContent;
    ref: AssetRef;
    citationAnchor: string;
    // only used for preview
    title: string;
    subTitle: string;
    logoTargetTypeUrl: string;

    constructor(id?: string, type?: AssetType) {
        this.id = id;
        this.type = type;
    }
}

export class AssetLawInfo {
    id: string;
    primary: boolean;
    lawGuid: string;
    aolGuid: string;
}

export class AssetContent {
    documentID: string;
    metaData: AssetMetaData;
    reference: string;
    docTitle: string;
    docCopyright: string;
    assetLanguage: number;
    languageInfos: LanguageInfos[];
    missingContentRights: String[] | null;
    // citedAssets: Citing[] assetID displayString anchor citationID
    otherCitations: String[];
    consolidatedAssets: any[];
    corrections: any[];
    celexNrOfOriginalAsset: string;
    assetContentAsHtml: Object | null;
    bibliographyContentAsHtml: Object | null;
    attachments: any[] | null;
}

export class LanguageInfos {
    language: string;
    default: boolean;
    exists: boolean;
    hasText: boolean;
}

/**
 * [AssetRefType.{Document Type}, docID]
 * [AssetRefType.{Document Type}, docID, AssetDisplayType.Citation, citationId]
 * [AssetRefType.{Toc Type}, publicationID, AssetRefType.{Document Type}, docID]
 * [AssetRefType.Preview, previewID]
 * [AssetRefType.Search, transactionID]
 * [AssetRefType.Newsletter, newsID]
 */
export type AssetRef = Array<string>;

export interface AssetOptions {
    publicationId?: string;
    citationInfo?: any;
    source?: string;
}

export namespace AssetRef {
    export function create(assetType: AssetDisplayType, targetId: string, { publicationId, citationInfo, source}: AssetOptions = {}) : AssetRef {
        assetType = AssetDisplayType[assetType] ? AssetDisplayType[assetType] : assetType;
        let assetRef: AssetRef = [];
        switch (assetType) {
            case AssetDisplayType.PeriodicalCollection:
                assetRef = [AssetDisplayType.PeriodicalPublication, publicationId, AssetDisplayType.PeriodicalCollection, targetId];
                break;
            case AssetDisplayType.Book:
                assetRef = publicationId ? [AssetDisplayType.Book, publicationId, AssetDisplayType.BookDocument, targetId] : [assetType, targetId];
                break;
            case AssetDisplayType.ArticleOfLawDocument:
                assetRef = [AssetDisplayType.ArticleOfLawDocument, targetId, AssetDisplayType.LawDocument, publicationId];
                break;
            default:
                assetRef = citationInfo ? [assetType, targetId, citationInfo.type, citationInfo.id] : [assetType, targetId];
                break;
        }
        return source ? [...assetRef, AssetRefOptions.sourceDetail, source] : assetRef;
    }

    export function determineScrollInfo(ref: AssetRef) {
        if (ref.length < 4) {
            return null;
        }

        switch (ref[2]) {
            case AssetRefOptions.DocumentCites:
                return { scrollType: DocScrollType.ReferringCitationId, value: ref[3] };
            case AssetRefOptions.EdocTitleRef:
                return { scrollType: DocScrollType.EdocTitleElement, value: ref[3] };
            case AssetRefOptions.EdocTitleRefTocOnly:
                return { scrollType: DocScrollType.EdocTitleElementTocOnly, value: ref[3] };
            default:
                return null;
        }
    }

    export function enhancedTextSelectionEnabled(ref: AssetRef): boolean {
        if(!isChDoc(ref)){
            return false;
        }

        switch(ref[0]) {
            case AssetDisplayType.LawDocument:
            case AssetDisplayType.LawText:
            case AssetDisplayType.ArticleOfLawDocument:
                return false;
            default:
                return true;
        }
    }

    // export function equals(ref1: AssetRef | string, ref2: AssetRef | string) {
    //     console.log(ref1,ref2);
    //     const r1 = ref1['join'] ? (<string[]>ref1).join('/') : String(ref1);
    //     const r2 = ref1['join'] ? (<string[]>ref2).join('/') : String(ref2);
    //     return (r1 === r2);
    // }


    export function toAssetID(ref: AssetRef): string {
        const refType = ref[0] as RefType;

        if (RefType.isHitlist(refType)) {
            return null;
        }
        return ref[1];
    }

    export function isDoc(ref: AssetRef): boolean {
        return isEuDoc(ref) || isChDoc(ref);
    }

    export function isEuDoc(ref: AssetRef): boolean {
        switch (ref[0] as AssetDisplayType) {
            case AssetDisplayType.EuCaseLawDocument:
            case AssetDisplayType.EuLawDocument:
            case AssetDisplayType.EuDoc:
                return true;
            default:
                return false;
        }
    }

    export function isLawDoc(ref: AssetRef) : boolean {
        switch(ref[0] as AssetDisplayType) {
            case AssetDisplayType.LawDocument:
            case AssetDisplayType.ArticleOfLawDocument:
                return true;
            default:
                return false;
        }
    }

    export function isChDoc(ref: AssetRef): boolean {
        switch (ref[0] as AssetDisplayType) {
            case AssetDisplayType.CaseLawDocument:
            case AssetDisplayType.CaseLawReviewDocument:
            case AssetDisplayType.OfficialPupDocument:
            case AssetDisplayType.LawDocument:
            case AssetDisplayType.LawText:
            case AssetDisplayType.ArticleOfLawDocument:
            case AssetDisplayType.UnknownDocument:
            case AssetDisplayType.EssayDocument:
            case AssetDisplayType.BookDocument:
            case AssetDisplayType.CommentaryDocument:
                return true;
            default:
                return false;
        }
    }

    export function isToc(ref: AssetRef): boolean {
        switch (ref[0] as AssetDisplayType) {
            case AssetDisplayType.Book:
            case AssetDisplayType.Commentary:
            case AssetDisplayType.PeriodicalCollection:
            case AssetDisplayType.PeriodicalPublication:
            case AssetDisplayType.UnknownPublication:
                return true;
            default:
                return false;
        }
    }

    export function isPreview(ref: AssetRef): boolean {
        switch (ref[0] as AssetDisplayType) {
            case AssetDisplayType.Previews:
            case AssetDisplayType.PreviewLaw:
            case AssetDisplayType.PreviewDoc:
                return true;
            default:
                return false;
        }
    }

    export function titleForAsset(asset: Asset): string {
        //  console.log('title for', asset);
        switch (asset.ref[0] as AssetDisplayType) {

            // ToC
            case AssetDisplayType.Book:
            case AssetDisplayType.PeriodicalCollection:
            case AssetDisplayType.PeriodicalPublication:
            case AssetDisplayType.UnknownPublication:
                return (asset.content as TocContent).title;

            // Documents
            case AssetDisplayType.EuCaseLawDocument:
            case AssetDisplayType.EuLawDocument:
            case AssetDisplayType.CaseLawDocument:
            case AssetDisplayType.CaseLawReviewDocument:
            case AssetDisplayType.OfficialPupDocument:
            case AssetDisplayType.LawDocument:
            case AssetDisplayType.ArticleOfLawDocument:
            case AssetDisplayType.UnknownDocument:
                return (asset.content as DocumentContent).reference ? (asset.content as DocumentContent).reference : (asset.content as DocumentContent).docTitle;

            // documents without references
            case AssetDisplayType.EssayDocument:
            case AssetDisplayType.BookDocument:
            case AssetDisplayType.CommentaryDocument:
                return (asset.content as DocumentContent).docTitle;

            // Preview
            case AssetDisplayType.Previews:
            case AssetDisplayType.PreviewLaw:
            case AssetDisplayType.PreviewDoc:
                return 'rech-asset-preview';

            default:
                return (asset.content as DocumentContent).docTitle ?  (asset.content as DocumentContent).docTitle : `No Title for type ${asset.ref}`;
        }
    }
}


export enum AssetType {
    undefined = 0,
    document = 1,
    euDocument = 2,
    periodicalToc = 3,
    bookToc = 4,
    euToc = 5, // Only used for Bibliothek render, not an actual asset
    docPreview = 6,
    lawPreview = 7,
    law = 8,

    // reusing the number as it isn't typed on server
    previewListInTab = 6,
}

export namespace AssetType {
    export function fromAssetRefType(assetDisplayType: RefType): AssetType {
        switch (assetDisplayType) {
            case AssetDisplayType.MissingDocument: return AssetType.undefined;

            // Law
            case AssetDisplayType.LawDocument:
            case AssetDisplayType.ArticleOfLawDocument:
            case AssetDisplayType.LawText:
                return AssetType.law;
            //? AssetRefType.ArticleOfLaw

            // ToC
            case AssetDisplayType.BookDocument: return AssetType.bookToc;
            case AssetDisplayType.PeriodicalCollection: return AssetType.periodicalToc;
            case AssetDisplayType.PeriodicalPublication: return AssetType.periodicalToc;

            // Documents
            case AssetDisplayType.EuCaseLawDocument: return AssetType.euDocument;
            case AssetDisplayType.EuLawDocument: return AssetType.euDocument; // TODO review if it should be: AssetType.law;

            case AssetDisplayType.CaseLawDocument:
            case AssetDisplayType.CaseLawReviewDocument:
            case AssetDisplayType.OfficialPupDocument:
            case AssetDisplayType.CommentaryDocument:
            case AssetDisplayType.EssayDocument:
                return AssetType.document;

            // Preview
            case AssetDisplayType.PreviewLaw: return AssetType.lawPreview;
            case AssetDisplayType.PreviewDoc: return AssetType.docPreview;

            default:
                return AssetType.undefined;
        }
    }

    export function fromAssetRef(ref: AssetRef): AssetType {
        return AssetType.fromAssetRefType(ref[0] as RefType);
    }

    export function fromAssetDisplayType(ref: AssetRef): AssetType {
        return AssetType.fromAssetRefType(ref[0] as RefType);
    }

    export function fromHitType(hitType: HitType) {
        switch (hitType) {
            case HitType.Document:
                return AssetType.document;
            case HitType.Book:
                return AssetType.bookToc;
            case HitType.PeriodicalCollectionAsset:
            case HitType.PeriodicalPublication:
                return AssetType.periodicalToc;
            case HitType.CaseLaw:
            case HitType.Law:
                return AssetType.euDocument;
            default:
                return AssetType.undefined;
        }
    }

    export function fromDocSubtype(subtype: string) {
        switch (subtype) {
            case 'eu':
                return AssetType.euDocument;
            case 'law':
                return AssetType.law;
            default:
                return AssetType.document;
        }
    }

    export function toDocSubtype(type: AssetType) {
        switch (type) {
            case AssetType.euDocument: return 'eu';
            default: return 'reg';
        }
    }

    export function fromTocSubtype(subtype: string) {
        switch (subtype) {
            case 'book':
                return AssetType.bookToc;
            case 'periodical':
                return AssetType.periodicalToc;
            case 'eu':
                return AssetType.euToc;
            default:
                return AssetType.bookToc;
        }
    }

    export function toTocSubtype(type: AssetType) {
        switch (type) {
            case AssetType.bookToc: return 'book';
            case AssetType.periodicalToc: return 'periodical';
            case AssetType.euToc: return 'eu';
            default: return 'book';
        }
    }

    export function titleForType(asset: Asset) {
        switch (asset.type) {
            case AssetType.law:
            case AssetType.document:
            case AssetType.euDocument:
                return (asset.content as DocumentContent).reference;
            case AssetType.bookToc:
            case AssetType.periodicalToc:
            case AssetType.euToc:
                return (asset.content as TocContent).title;
            case AssetType.previewListInTab:
                return asset.title;
        }
    }

    export function iconForType(asset: Asset) {
        switch (asset.type) {
            //TODO
            default:
                return null;
        }
    }
}
