module.exports = {
	"jobs-nzzcoorporation": "Ce service est proposé en collaboration avec NZZ Mediengruppe",
	"jobs-advertise": "Insérer maintenant",
	"jobs-advertise-full": "Publier maintenant directement sur Swisslex",
	"jobs-backButton": "Retour",
	"jobs-enter": "Enter",
	"jobs-search": "Parcourir les emplois",
	"jobs-current": "Offres d'emplois",
	"jobs-single": "Details",
	"jobs-current-filter": "Entrez le texte pour filtrer",
	"jobs-grid-publishDate": "Date",
	"jobs-grid-employerName": "Employeurs",
	"jobs-grid-title": "Titre",
	"jobs-grid-workplace": "Lieu de travail",
	"jobs-grid-no-entries": "Aucune entrée disponible",
	"jobs-grid-loading": "Contenus en cours de chargement",
	"jobs-employer": "Employeur",
	"jobs-workplace": "Lieu de travail",
	"jobs-publishDate": "Date de publication",
	"jobs-links": "Liens externes",
	"jobs-conditions": "Profil recherché",
	"jobs-aboutEmployer": "Sur l‘employeur",
	"jobs-contact": "Contact",
	"jobs-application-link": "Lien pour postuler",
	"jobs-more-information": "Plus d'informations",
	"jobs-details-back-to-overview": "Retour aux emplois actuels",
	"jobs-search-title": "Rechercher",
	"jobs-hits": "Résultat(s)",
	"jobs-single-job-not-found": "L'offre d'emploi demandée n'a pas été trouvée.",
	"jobs-undefined-error": "Erreur non identifiable ! Veuillez vous adresser à notre hotline - 0800 587 463   /   +41 (0) 44 365 32 32."
};