import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AppContext } from '../../appContext';

@Component({
    selector: 'slx-simple-tabs',
    templateUrl: './simple-tabs.component.html',
    styleUrls: ['./simple-tabs.component.scss'],
})
export class SimpleTabsComponent {

    @Input() tabTitles: Array<any>;
    @Input() currentAssetID: string;
    @Input() tabTrackBy: Function;
    @Output() selected = new EventEmitter();
    @Output() closed = new EventEmitter();

    public trackingActive = false;

    constructor() {
        AppContext.Context.subscribe(options =>
            this.trackingActive = options.get('activateTracking')
        );
    }

    select(item) {
        this.selected.emit(item);
    }

    closeTab(item) {
        this.closed.emit(item);
    }
}
