import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatOptionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatTreeModule,
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';

import { FaqListGroupRendererComponent } from '../home/abo-services/faq/faq-list/faq-list-grouprenderer/faq-list-grouprenderer.component';
import { SLX_DATE_FORMATS, SlxDateAdapter } from '../utility/date-format';

import { DateComponent } from './date/date.component';
import { FieldService } from './field.service';
import { FieldEffects } from './fieldEffects';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FunctionAsPipe, TooltipDataCreatorPipe } from './functionAsPipe';
import { GraphComponent } from './graph/graph.component';
import { GridComponent } from './grid/grid.component';
import { GridSearchComponent } from './gridSearch/gridSearch.component';
import { GridSwitchComponent } from './gridSwitch/gridSwitch.component';
import { InfoButtonComponent } from './infoButton/infoButton.component';
import { InfoWindowComponent } from './infoWindow/infoWindow.component';
import { LinkDirective } from './link.directive';
import { MarkdownComponent } from './markdown.component';
import { MulticheckComponent } from './multicheck/multicheck.component';
import { GetLogoUrlPipe, SafeHtmlPipe, SafeUrlPipe, ShortenStringLengthPipe, StripTagsPipe, UrlForTipPipe } from './pipes';
import { PublicationSelectComponent } from './publication-select/publication-select.component';
import { CurrentTranslationStringDeselectPipe, CurrentTranslationStringSelectPipe } from './publication-select/publication-select.pipes';
import { SelectComponent } from './select/select.component';
import { SpinnerComponent } from './spinner.component';
import { SubmitButtonComponent } from './submitButton/submitButton.component';
import { SwitchComponent } from './switch/switch.component';
import { TextComponent } from './text/text.component';
import { ThesaurusComponent } from './thesaurus/thesaurus.component';
import { TDHasFollowingTipPipe, TDHasPrecedingTipPipe, TipOfTheDayComponent } from './tipOfTheDay/tip-of-the-day.component';
import { SlxMatToolTipDirective } from './tooltip/slx-mat-tooltip.directive';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { TreeMulticheckComponent } from './treemulticheck/treemulticheck.component';
import { UniComponent } from './uni/uni.component';
import { TDHasFollowingWelcomePipe, TDHasPrecedingWelcomePipe, WelcomeComponent } from './welcome/welcome.component';


@NgModule({
    declarations: [
        TextComponent,
        SwitchComponent,
        SelectComponent,
        DateComponent,
        MulticheckComponent,
        TreeMulticheckComponent,
        GridComponent,
        GridSearchComponent,
        GridSwitchComponent,
        StripTagsPipe,
        GetLogoUrlPipe,
        ShortenStringLengthPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        TDHasPrecedingTipPipe,
        TDHasFollowingTipPipe,
        TDHasPrecedingWelcomePipe,
        TDHasFollowingWelcomePipe,
        UrlForTipPipe,
        SpinnerComponent,
        ThesaurusComponent,
        TooltipDirective,
        TooltipComponent,
        FunctionAsPipe,
        TooltipDataCreatorPipe,
        FileUploadComponent,
        MarkdownComponent,
        InfoButtonComponent,
        InfoWindowComponent,
        TipOfTheDayComponent,
        WelcomeComponent,
        SubmitButtonComponent,
        GraphComponent,
        LinkDirective,
        PublicationSelectComponent,
        CurrentTranslationStringDeselectPipe,
        CurrentTranslationStringSelectPipe,
        SlxMatToolTipDirective,
        UniComponent,

    ],
    exports: [
        TextComponent,
        SwitchComponent,
        SelectComponent,
        DateComponent,
        MulticheckComponent,
        TreeMulticheckComponent,
        GridSearchComponent,
        GridSwitchComponent,
        MatSelectModule,
        StripTagsPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        GetLogoUrlPipe,
        ShortenStringLengthPipe,
        TDHasFollowingTipPipe,
        TDHasPrecedingTipPipe,
        TDHasFollowingWelcomePipe,
        TDHasPrecedingWelcomePipe,
        UrlForTipPipe,
        SpinnerComponent,
        ThesaurusComponent,
        TooltipDirective,
        TooltipComponent,
        FunctionAsPipe,
        TooltipDataCreatorPipe,
        FileUploadComponent,
        MarkdownComponent,
        InfoButtonComponent,
        InfoWindowComponent,
        TipOfTheDayComponent,
        WelcomeComponent,
        GridComponent,
        GraphComponent,
        SubmitButtonComponent,
        LinkDirective,
        PublicationSelectComponent,
        CurrentTranslationStringDeselectPipe,
        CurrentTranslationStringSelectPipe,
        SlxMatToolTipDirective,
        UniComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        BrowserAnimationsModule,
        EffectsModule.forFeature([FieldEffects]),
        AgGridModule.withComponents([FaqListGroupRendererComponent]),
        MatMenuModule, MatButtonModule, MatIconModule, MatSliderModule,
        MatInputModule, MatCheckboxModule, MatProgressBarModule,
        MatProgressSpinnerModule, MatExpansionModule, MatFormFieldModule,
        MatSlideToggleModule, MatAutocompleteModule, MatSelectModule, MatRadioModule, MatOptionModule,
        FormsModule, MatDialogModule, ReactiveFormsModule, MatDatepickerModule, MatNativeDateModule, MatTreeModule, MatCardModule, MatStepperModule,
    ],
    providers: [
        FieldService,
        { provide: MAT_DATE_LOCALE, useValue: 'de-CH' },
        { provide: MAT_DATE_FORMATS, useValue: SLX_DATE_FORMATS },
        { provide: DateAdapter, useClass: SlxDateAdapter },
    ],
    bootstrap: [],
    entryComponents: [
        InfoWindowComponent,
        TipOfTheDayComponent,
        WelcomeComponent,
        UniComponent,
    ],
})
export class FieldModule { }
