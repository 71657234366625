import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as FileSaver from 'file-saver';
import * as Raven from 'raven-js';
import { Observable } from 'rxjs';
import { catchError, concatMap, filter, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { deleteFavoriteBaseUrl, getCitationInformation, getEuDocIDBasedOnCelexNr, getLawAndAolIDfromSrUrl, getUserFavoritesUrl, previewAssetsUrl, saveFavoriteUrl, SlxHttp, updateOrderFavoritesUrl, updateTitleFavoriteUrl } from '../access';
import { AccountService } from '../account';
import { AppActions } from '../appActions';
import { AssetDisplayType, AssetRef, AssetTabstrip, AssetType, catchErrorForAction, catchErrorObservable, enumToString, getResultNameFromAction, isRefInList, makeAction, RechercheState, SlxAction, SourceDetail } from '../models';
import { AlertType } from '../models/state/commonApp';
import { responsiveThreshold, routingDebug } from '../utility/utilityFunctions';

import { AssetService } from './asset.service';
import { AssetActions } from './assetActions';
@Injectable()
export class AssetEffects {

    @Effect() handleResize = this.actions$.pipe(
        ofType(AppActions.browserwindow_was_resized.name),
        concatMap((action: SlxAction) => {
            const { resizeStart, resizeEnd } = action.payload;
            // note: changing window full screen mode leads to 'browserwindow_was_resized' with resizeStart === resizeEnd

            // now below threshold => move asset to one tab
            if (resizeStart > responsiveThreshold && resizeEnd < responsiveThreshold
                || (resizeStart === resizeEnd && resizeEnd < responsiveThreshold)) {
                return [
                    makeAction({ type: AssetActions.save_current_asset_tab_state.name }),
                    makeAction({ type: AppActions.move_secondary_to_primary_assets.name }),
                ];
            }

            // now above threshold => restore asset
            if (resizeStart < responsiveThreshold && resizeEnd > responsiveThreshold
                || (resizeStart === resizeEnd && resizeEnd > responsiveThreshold)) {
                return [makeAction({ type: AssetActions.restore_asaset_tab_state.name })];
            }

            // do nothing
            return [];
        })
    );

    @Effect() loadCitationInformation = this.actions$.pipe(
        ofType(AssetActions.get_citation_information.name),
        withLatestFrom(this.assetService.state),
        filter(([action, state]) => !state.citationInformation[(action as SlxAction).payload.assetID]),
        switchMap(([action, state]) => {
            const payload = (action as SlxAction).payload;
            return this.slxHttp.get(`${getCitationInformation}${payload.assetID}&language=${this.accountService.lang}`)
                .pipe(map(result => makeAction({ result: AssetActions.get_citation_information.name, payload: { ...payload, citationInformation: result } })));
        })
    );

    @Effect() handleLegacyLaws = this.actions$.pipe(
        ofType(AssetActions.handle_legacy_law_documents.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.get(`${getLawAndAolIDfromSrUrl}${action.payload.lawSr}&articleNumber=${action.payload.articleNumber}`).pipe(
                map(result => ({
                    type: AppActions.present_results.name, payload: {
                        primary: [result.aolID ? AssetRef.create(AssetDisplayType.ArticleOfLawDocument, result.aolID, { publicationId: result.lawID }) :
                            AssetRef.create(AssetDisplayType.LawDocument, result.lawID)], secondary: [],
                    },
                })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-asset-error' })
            )
        )
    );

    @Effect() getDocBasedOnCelexNr = this.actions$.pipe(
        ofType(AssetActions.load_eu_doc_based_on_celex_nr.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.get(`${getEuDocIDBasedOnCelexNr}${action.payload.celexNr}`).pipe(
                map(result =>
                    this.assetService.createOpenAssetAction(AssetRef.create(AssetDisplayType.EuDoc, result, { source: SourceDetail.EuDocumentFromCelex }),
                        action.payload.openInPrimary)
                ),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-asset-error' })
            )
        )
    );

    @Effect() assetLoad = this.actions$.pipe(
        ofType(AssetActions.load_asset.name),
        //withLatestFrom(this.assetService.state),
        mergeMap(action => {
            return this.assetService.getAsset((action as SlxAction).payload.ref).pipe(
                first(),
                map(payload => makeAction({
                    result: AssetActions.load_asset.name,
                    payload: { asset: payload, ref: [...(action as SlxAction).payload.ref], isPrimary: (action as SlxAction).payload.isPrimary },
                })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-asset-error' })
            );
        })
    );

    @Effect() loadOrginallyWantedAsset = this.actions$.pipe(
        ofType(AssetActions.remove_oldest_document.name),
        filter(action => (action as SlxAction).payload && (action as SlxAction).payload.presentResultsPayload),
        map(action => makeAction({ type: AppActions.present_results.name, payload: (action as SlxAction).payload.presentResultsPayload }))
    );

    @Effect() newAssetLoadCiting = this.actions$.pipe(
        ofType(AssetActions.load_asset.name),
        filter((action: SlxAction) => AssetDisplayType.canHaveCitations(action.payload.ref[0])),
        map((action: SlxAction) => ({ action, url: this.assetService.prepareCitingURL(AssetRef.toAssetID(action.payload.ref)), isPrimary: action.payload.isPrimary })),
        mergeMap(({ action, url, isPrimary }) =>
            this.slxHttp.get(url).pipe(
                map(payload => makeAction({ result: AssetActions.load_citing.name, payload: { assetCiting: payload, isPrimary } }))
                //catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-asset-error' })
            )
        )
    );

    @Effect() lawLoadCommentaries = this.actions$.pipe(
        ofType(AssetActions.load_asset.name),
        filter((action: SlxAction) => AssetRef.isLawDoc(action.payload.ref)),
        map((action: SlxAction) => ({ action, url: this.assetService.prepareCommentariesForCommentariesUrl(action.payload.ref), isPrimary: action.payload.isPrimary })),
        mergeMap(({ action, url, isPrimary }) =>
            this.slxHttp.post(url,this.assetService.getLawGuidsFromAssetRef(action.payload.ref)).pipe(
                map(result =>
                    makeAction({ result: AssetActions.load_commentaries_for_law.name, payload: { ...result, isPrimary } })
                )
            )
        )
    );

    @Effect() commentariesLoadCommentaries = this.actions$.pipe(
        ofType(getResultNameFromAction(AssetActions.load_asset)),
        filter((action: SlxAction) => action.payload.asset.displayType === AssetDisplayType.CommentaryDocument),
        map((action: SlxAction) => ({ action, url: this.assetService.prepareCommentariesForCommentariesUrl(action.payload.ref), isPrimary: action.payload.isPrimary })),
        mergeMap(({ action, url, isPrimary }) =>
            this.slxHttp.post(url,action.payload.asset.content.assignedArticleOfLaws).pipe(
                map(result =>
                    makeAction({ result: AssetActions.load_commentaries_for_law.name, payload: { ...result, isPrimary }})
                )
            )
        )
    );

    // @Effect() assetLoadCiting = this.actions$.pipe(
    //     ofType(AssetActions.load_asset.name/*, AssetActions.open_citation_from_url.name*/),
    //     filter((action: SlxAction) => action.payload.type === AssetType.document),
    //     map((action: SlxAction) => ({ action, url: this.assetService.prepareCitingURL(action.payload.targetID) })),
    //     mergeMap(({ action, url }) =>
    //         this.slxHttp.get(url).pipe(
    //             map(payload => makeAction({ result: AssetActions.load_citing.name, payload })),
    //             catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-asset-error' })
    //         )
    //     )
    // );

    @Effect({ dispatch: false }) assetLoadCurrentSuccessURLUpdate = this.actions$.pipe(
        ofType(AssetActions.rearrange_tab.name, AssetActions.new_asset_tab_changed.name, AssetActions.close_tab.name, getResultNameFromAction(AssetActions.load_asset), AssetActions.set_hitlist.name, AssetActions.clear_recherche_results.name),
        tap((_) => {
            routingDebug('assetEffects', 'assetLoadCurrentSuccessURLUpdate', _);
            this.location.go('auto-url-from-store');
        })
    );

    @Effect() savePdf = this.actions$.pipe(
        ofType(AssetActions.save_doc_pdf.name),
        withLatestFrom(this.assetService.state),
        map(([action, state]) => ({ action, ...this.assetService.preparePdfURL((action as SlxAction).payload.asset, (action as SlxAction).payload.isPrimary, state, (action as SlxAction).payload.tryHighlight) })),
        switchMap(({ action, url, titlePdf, isPrimary, ref }) =>
            this.slxHttp.getPdf(url).pipe(
                map((payload) => {
                    //const fileBlob = payload.blob();
                    const blob = new Blob([payload], { type: 'application/pdf' });
                    FileSaver.saveAs(blob, titlePdf);
                    return makeAction({ result: AssetActions.save_doc_pdf.name, payload: { isPrimary } });
                }),
                // catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-asset-pdf-error'}, payload => ({ pdfIsLoading: false }))
                catchError(catchErrorObservable(AssetActions.doc_pdf_error.name, 'rech-asset-pdf-error', payload => ({ isPrimary, pdfIsLoading: false }))) //payload => ({ isPrimary, fileID, error? }
            )
        )
    );

    @Effect() loadAttachment = this.actions$.pipe(
        ofType(AssetActions.save_doc_attachment.name),
        map((action: SlxAction) => this.assetService.prepareAttachmentURL(action.payload.fileID, action.payload.description, action.payload.isPrimary, action.payload.isEuAttachment, action.payload.url)),
        mergeMap(({ url, isPrimary, fileID, description }) =>
            this.slxHttp.getPdf(url).pipe(
                map((payload) => {
                    // const fileBlob = payload.blob();
                    const blob = new Blob([payload], { type: 'application/pdf' });
                    FileSaver.saveAs(blob, description);
                    return makeAction({ result: AssetActions.save_doc_attachment.name, payload: { isPrimary, fileID } });
                }),
                catchError(catchErrorObservable(AssetActions.load_attachment_error.name, 'rech-asset-attachment-not-found', payload => ({ isPrimary, fileID }))) //payload => ({ isPrimary, fileID, error? }
            )
        )
    );

    @Effect() loadTocItems = this.actions$.pipe(
        ofType(AssetActions.load_toc_items.name),
        mergeMap((action: SlxAction) =>
            this.slxHttp.get(action.payload.getItemsUrl, false).pipe(
                map(payload => makeAction({ result: AssetActions.load_toc_items.name, payload: { items: payload, itemId: action.payload.itemId, isPrimary: action.payload.isPrimary, targetId: action.payload.targetId } })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-asset-error' })
            )
        )
    );

    @Effect() loadAssetsInRech = this.actions$.pipe(
        ofType(AppActions.present_results.name),
        withLatestFrom(this.assetService.state),
        concatMap(([action, state]) => {
            const payload = (action as SlxAction).payload;
            if (payload.tooManyAssets) {
                Raven.captureException('max tabs reached');
                const action = {
                    type: AssetActions.remove_oldest_document.name,
                    linkKey: 'rech-asset-too-many-assets-link',
                    payload: { presentResultsPayload: { ...payload, tooManyAssets: false } },
                    noLinkMargin: true,
                };
                return [makeAction({
                    type: AppActions.alert.name, payload: {
                        type: AlertType.Warning, key: 'rech-asset-too-many-assets-title', text: 'rech-asset-too-many-assets-text', sticky: true, actions: [action],
                    },
                })];
            }
            const actions = this.determineAssetActions(payload.primary, payload.secondary, state.primaryTabState, state.secondaryTabState, state.assetInProgress);
            return actions;
        })
    );

    determineAssetActions(primary: AssetRef[], secondary: AssetRef[], primaryTabState: AssetTabstrip, secondaryTabState: AssetTabstrip, assetInProgress) {
        return primary.map(ref => determineAction(ref, true)).concat(secondary.map(ref => determineAction(ref, false))).filter(action => action);

        function determineAction(ref: AssetRef, isPrimary) {
            function matchAssetId(tab) {
                return tab.assetID === AssetRef.toAssetID(ref);
            }
            const tab = isPrimary ? primaryTabState.assetTabs.find(matchAssetId) : secondaryTabState.assetTabs.find(matchAssetId);

            if (!isRefInList(assetInProgress, ref)) {
                return !tab.isLoading ? { type: AssetActions.new_asset_tab_changed.name, payload: { ref, isPrimary } } : { type: AssetActions.load_asset.name, payload: { ref, isPrimary } };
            }
        }
    }

    @Effect() updateAsset = this.actions$.pipe(
        ofType(AssetActions.update_asset_law.name),
        withLatestFrom(this.assetService.store.select('recherche') as Observable<RechercheState>),
        map(([action, state]) => {
            const { openInPrimary, assetID, lawID, aolID } = (action as SlxAction).payload;
            const currentAssetIndex = openInPrimary ? state.primaryTabState.assetTabs.findIndex(tab => tab.assetID === assetID)
                : state.secondaryTabState.assetTabs.findIndex(tab => tab.assetID === assetID);
            const requestURL = this.assetService.getLawUrl(lawID, aolID);
            const updateAssetAction = { type: this.assetService.actions.update_asset_from_url.name, payload: { assetID, requestURL, openInPrimary, tabIndex: currentAssetIndex } };

            return updateAssetAction;
        })
    );

    @Effect() updateOrderFavorites = this.actions$.pipe(
        ofType(AssetActions.update_order_favorites.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.post(`${updateOrderFavoritesUrl}`, action.payload.map(entry => entry.id)).pipe(
                map(payload => makeAction({ result: AssetActions.update_order_favorites.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-searchfilter-save-error' })
            )
        )
    );

    @Effect() toggleUserFavorite = this.actions$.pipe(
        ofType(AssetActions.toggle_favorite.name),
        withLatestFrom(this.assetService.store),
        mergeMap(([action, state]) => {
            const payload = (action as SlxAction).payload;
            payload.favorite.targetType = enumToString(AssetDisplayType, payload.favorite.targetType);

            // since store contains nested arrays, reduce parents and children before looking if it already exists to make search simple
            const allFavorites = state.recherche.userFavorites.reduce((f, c) => c.children ? f.concat(c.children).concat(c) : f.concat(c), []);
            const favoriteInStore = state.recherche.userFavorites ? allFavorites.find(f => payload.favorite.targetID ? f.targetID === payload.favorite.targetID : f.id === payload.favorite.id) : null;
            const modificationRequest = favoriteInStore
                ? this.slxHttp.delete(`${deleteFavoriteBaseUrl}?favoriteId=${favoriteInStore.id}`)
                : this.slxHttp.post(`${saveFavoriteUrl}?language=${this.accountService.lang}`, payload.favorite);

            return modificationRequest.pipe(map(result => {

                // reload favorites => mainly used for thomas can be removed after courts are fixed (console.log is here as reminder)
                // console.log('Reload Favorites => delete next line AFTER courts are fixed');
                this.slxHttp.get(`${getUserFavoritesUrl}?language=${this.accountService.lang}`)
                    .subscribe(payload => this.assetService.dispatch({ type: AssetActions.user_favorites.name, payload: { favorites: payload } }));

                if (result === 'Favorite added successfully' && !state.customerProfile.profilePrefs.hideSavedFavorite) {
                    const changedProfilePrefs = { ...state.customerProfile.profilePrefs, hideSavedFavorite: true };

                    const alert = {
                        type: AlertType.Success,
                        key: 'rech-asset-save-fav-success',
                        actions: [
                            {
                                type: AppActions.save_profile_prefs.name,
                                linkKey: 'rech-asset-save-fav-show-dial',
                                payload: changedProfilePrefs,
                            },
                        ],
                    };

                    return makeAction({ result: AssetActions.toggle_favorite.name, payload: result, alert });
                }
                else {
                    return makeAction({ result: AssetActions.toggle_favorite.name, payload: result });
                }
            }));
        })
    );

    @Effect() getPreviewAssets = this.actions$.pipe(
        ofType(getResultNameFromAction(AssetActions.preview_assets)),
        mergeMap((action: SlxAction) =>
            this.slxHttp.get(`${previewAssetsUrl}${action.payload}`).pipe(
                map(payload => makeAction({ result: AssetActions.preview_assets.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-asset-load-fav-error' })
            )
        )
    );

    @Effect() getUserFavorites = this.actions$.pipe(
        ofType(getResultNameFromAction(AssetActions.toggle_favorite), AppActions.set_collection_tab.name),
        withLatestFrom<SlxAction, RechercheState>(this.assetService.state),
        filter(([action, state]) => {
            if (action.type === AppActions.set_collection_tab.name) {
                return action.payload.collectionTab === 'favorites' && state.userFavorites === null;
            }
            return true;
        }),
        mergeMap(([action, state]) =>
            this.slxHttp.get(`${getUserFavoritesUrl}?language=${this.accountService.lang}`).pipe(
                map(payload => makeAction({ result: AssetActions.user_favorites.name, payload: { favorites: payload, assetId: action.payload.assetID } })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-asset-load-fav-error' })
            )
        )
    );

    @Effect() updateFavorite = this.actions$.pipe(
        ofType(AssetActions.update_favorite.name),
        withLatestFrom(this.assetService.store),
        mergeMap(([action, state]) => {
            const payload = (action as SlxAction).payload;
            const modificationRequest = this.slxHttp.post(updateTitleFavoriteUrl, payload.favorite);
            return modificationRequest.pipe(
                map(payload => makeAction({ result: AssetActions.update_favorite.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-asset-load-fav-error' })
            );
        })
    );

    sanitizeAsset(asset) {
        if (asset.content && asset.content.assetContentAsHtml) {
            asset.content.assetContentAsHtml = this.sanitizeHtml(asset.content.assetContentAsHtml);
        }
        return asset;
    }

    constructor(
        private slxHttp: SlxHttp,
        private assetService: AssetService,
        private accountService: AccountService,
        private actions$: Actions,
        private location: Location,
        private sanitizer: DomSanitizer
    ) {
    }

    sanitizeHtml(v: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(v);
    }
}
