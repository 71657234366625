import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';

import { assetTooltipInfoUrl } from '../access';
import { AssetDisplayType, HitlistType, SourceDetail, TransactionType, UserFavorite } from '../models';
import { AssetListEntry, AssetRef } from '../models/assets';
import { emptyGuid } from '../utility/utilityFunctions';

import { SlxHttp } from './../access';
import { AccountService } from './../account';

@Pipe({ name: 'refFromTransaction' })
export class RefFromTransactionPipe implements PipeTransform {
    transform(transactionInformation): AssetRef {
        const source = SourceDetail.History;
        switch (transactionInformation.type) {
            case TransactionType.DocView:
                return AssetRef.create(AssetDisplayType.UnknownDocument, transactionInformation.assetID, { source });
            case TransactionType.EuDocView:
                return AssetRef.create(AssetDisplayType.UnknownDocument, transactionInformation.assetID, { source });
            case TransactionType.EuSearch:
                return [HitlistType.EuSearch, transactionInformation.id];
            case TransactionType.LawView:
                return transactionInformation.aolID ?
                    AssetRef.create(AssetDisplayType.ArticleOfLawDocument, transactionInformation.aolID, { publicationId: transactionInformation.lawID, source }) :
                    AssetRef.create(AssetDisplayType.LawDocument, transactionInformation.lawID, { source });
            case TransactionType.Search:
                return [HitlistType.Search, transactionInformation.id];
            case TransactionType.TocView:
                return AssetRef.create(AssetDisplayType.UnknownPublication, transactionInformation.assetID, { source });
            default:
                return [];
        }
    }
}

@Pipe({ name: 'isDocument' })
export class IsDocumentPipe implements PipeTransform {
    transform(assetRef: AssetRef): boolean {
        return assetRef && AssetRef.isDoc(assetRef);
    }
}

@Pipe({ name: 'isLawDoc' })
export class IsLawDocPipe implements PipeTransform {
    transform(assetRef: AssetRef): boolean {
        return assetRef && AssetRef.isLawDoc(assetRef);
    }
}

@Pipe({ name: 'isEuDocument' })
export class IsEuDocumentPipe implements PipeTransform {
    transform(assetRef: AssetRef): boolean {
        return assetRef && AssetRef.isEuDoc(assetRef);
    }
}

@Pipe({ name: 'isChDocument' })
export class IsChDocumentPipe implements PipeTransform {
    transform(assetRef: AssetRef): boolean {
        return assetRef && AssetRef.isChDoc(assetRef);
    }
}

@Pipe({ name: 'isCommentaryDoc' })
export class IsCommentaryDocPipe implements PipeTransform {
    transform(assetRef: AssetRef): boolean {
        return assetRef && assetRef[0] as AssetDisplayType === AssetDisplayType.CommentaryDocument;
    }
}

@Pipe({ name: 'canHaveCitations' })
export class CanHaveCitationsPipe implements PipeTransform {
    transform(assetRef: AssetRef): boolean {
        return assetRef && AssetRef.isChDoc(assetRef) && assetRef[0] !== AssetDisplayType.LawDocument && assetRef[0] !== AssetDisplayType.ArticleOfLawDocument;
    }
}

@Pipe({ name: 'isToc' })
export class IsTocPipe implements PipeTransform {
    transform(assetRef: AssetRef): boolean {
        return assetRef && AssetRef.isToc(assetRef);
    }
}

@Pipe({ name: 'isPreview' })
export class IsPreviewPipe implements PipeTransform {
    transform(assetRef: AssetRef): boolean {
        return assetRef && AssetRef.isPreview(assetRef);
    }
}

const baseLogourl = '/assets/icons/searchhistory-itmes/';
@Pipe({ name: 'logoFromTransaction' })
export class LogoFromTransactionPipe implements PipeTransform {
    transform(transactionType: TransactionType): string {
        let logoName = '';
        switch (transactionType) {
            case TransactionType.EuSearch:
                logoName = TransactionType[TransactionType.Search];
                break;
            case TransactionType.EuDocView:
                logoName = TransactionType[TransactionType.DocView];
                break;
            default:
                logoName = TransactionType[transactionType];
        }
        return `${baseLogourl}${logoName}.svg`;
    }
}

@Pipe({ name: 'logoFromFavorite' })
export class LogoFromFavoritePipe implements PipeTransform {
    transform(displayType: AssetDisplayType) {
        const tempRef = AssetRef.create(displayType, emptyGuid);
        if (AssetRef.isToc(tempRef)) {
            return `${baseLogourl}TocView.svg`;
        }
        return `${baseLogourl}DocView.svg`;
    }
}

@Pipe({ name: 'refFromFavorite' })
export class RefFromFavoritePipe implements PipeTransform {
    transform(favorite: UserFavorite) {
        return AssetRef.create(favorite.targetType, favorite.targetID, { publicationId: favorite.targetAolID, source: SourceDetail.Favorites });
    }
}

@Pipe({ name: 'shortenMetadataName' })
export class ShortenMetadataNamePipe implements PipeTransform {
    constructor() { }
    transform(name: string) {
        const splitted = name.split('_');
        return 'rech-asset-' + splitted[splitted.length - 1];
    }
}

@Pipe({ name: 'tooltipDataForAssetListEntry' })
export class TooltipDataForAssetListEntryPipe implements PipeTransform {
    constructor(private slxHttp: SlxHttp, private accountService: AccountService) {
    }
    transform(entry: AssetListEntry) {
        if (AssetRef.isToc([AssetDisplayType[entry.assetDisplayType]])) {
            return null;
        }

        const url = `${assetTooltipInfoUrl}${entry.assetID}&citationID=${entry.citationID}&language=${this.accountService.langEnum}`;
        return this.slxHttp.get(url).pipe(
            map(result => {
                return {
                    tooltipTitle: result.title,
                    tooltipBody: result.subTitle,
                };
            }));
    }
}

@Pipe({ name: 'tooltipDataForAsset' })
export class TooltipDataForAssetPipe implements PipeTransform {
    constructor(private slxHttp: SlxHttp, private accountService: AccountService) {
    }
    transform(assetID: string, citationID: string) {
        const url = `${assetTooltipInfoUrl}${assetID}&citationID=${citationID}&language=${this.accountService.langEnum}`;
        return this.slxHttp.get(url).pipe(
            map(result => {
                return {
                    tooltipTitle: result.title,
                    tooltipBody: result.subTitle,
                };
            }));
    }
}
