export interface AboServiceState {
    registerBusinessFormElements: BusinessFormElements;
    registerStudentsFormElements: any;
    registerRakFormElements: RakFormElements;
    activateRakUserData: any;
    usersIpAdress: string;
    badEmail: boolean;
}

export const defaultAboServiceState = {
    usersIpAdress: '',
    badEmail: false,
};

export interface RakFormElements {
    addressTerms: Array<SelectListItem>;
    addressTitles: Array<SelectListItem>;
    language: any;
    languages: Array<SelectListItem>;
    rakRuntimes: Array<SelectListItem>;
}

export interface BusinessFormElements{
    addressTerms: Array<SelectListItem>;
    addressTitles: Array<SelectListItem>;
    language: string;
    languages: Array<SelectListItem>;
    aboTypes: Array<SelectListItem>;
    countries: Array<SelectListItem>;
    customerTypes: Array<SelectListItem>;
}

export interface UniversityFormElements{
    addressTerms: Array<SelectListItem>;
    addressTitles: Array<SelectListItem>;
    language: string;
    languages: Array<SelectListItem>;
    universities: Array<any>;
    uniEmailSuffixes: Array<any>;
}

export interface SelectListItem{
    disabled: boolean;
    group: any;
    selected: boolean;
    text: string;
    value: string;
}

