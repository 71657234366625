import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AccountService } from '../account';

@Injectable()
export class CustomerProfileResolver implements Resolve<boolean> {
    constructor(private accountService: AccountService) { }

    resolve(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): boolean {
        const path = route.url[0].path;
        this.accountService.loadDataBasedOnPath(path, route.params.id);
        return true;
    }
}
