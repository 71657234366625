import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';

import { FieldService } from '../../../field';
import { PublicationCriteria } from '../../../models';
import { SearchService } from '../../search.service';

const debug = require('debug')('searchfilter');

@Component({
    selector: 'slx-searchfilter-save',
    templateUrl: './searchfilter-save.component.html',
    styleUrls: ['./searchfilter-save.component.scss'],
})
export class SearchFilterSaveComponent implements OnInit, OnDestroy {
    public saveForm: FormGroup;
    public filters: Array<{ key: string, value: string }>;

    public userSearchFilters: Array<any>;
    private selectedPublications: PublicationCriteria;
    private subscription: Subscription = new Subscription();

    public selected;
    public searchForm;
    public selectedRadio = 'new';

    constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<SearchFilterSaveComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public searchService: SearchService, public fieldService: FieldService) {
        debug('data received: ', this.data);
        this.searchForm = data.search;
        this.saveForm = this.formBuilder.group({
            saveNew: true,
            name: [''],
            id: [''],
        });

        this.filters = [];

        this.subscription
            .add(this.searchService.userSearchFilters.subscribe(
                result => {
                    if (result.length > 0) {
                        this.filters = result.map(el => { return { key: String(el.id), value: el.title }; });
                        this.selected = this.filters[0].key;
                    }
                }
            ))
            .add(this.fieldService.selectedPublications.subscribe(selectedPublications => this.selectedPublications = selectedPublications));
    }

    ngOnInit() {
        this.saveForm.controls['id'].setValue(this.selected);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    get saveNew(): FormControl {
        return this.saveForm.get('saveNew') as FormControl;
    }

    filterForm(searchForm) {
        const result = {
            dateFrom: searchForm.dateFrom,
            dateUntil: searchForm.dateUntil,
            reference: searchForm.reference,
            author: searchForm.author,
            practiceAreaGroupsCriteria: searchForm.practiceAreaGroupsCriteria,
            assetTypeGroupsCriteria: searchForm.assetTypeGroupsCriteria,
        };
        //publication select filter
        ['bookCriteria', 'caseCollectionCriteria', 'journalCriteria'].forEach(prop => result[prop] = this.selectedPublications[prop]);
        return result;

    }

    setSelected(name: string) {
        this.saveNew.setValue(name === 'new');
        this.selectedRadio = name;
        debug('submit: saveForm.value', this.saveForm.value);
    }

    onSubmit(saveForm) {

        const userSearchFilter = {
            retrievalSearch: this.filterForm(this.searchForm.value),
        };

        debug('submit: ', userSearchFilter);
        debug('submit: saveForm.value', this.saveNew);
        if (this.saveNew.value) {
            debug('going to save', { title: saveForm.value.name, ...userSearchFilter });
            this.searchService.store.dispatch({
                type: this.searchService.actions.save_user_search_filter.name,
                payload: { title: saveForm.value.name, ...userSearchFilter },
            });
        } else {
            debug('going to overwrite', { id: saveForm.value.id, ...userSearchFilter });
            this.searchService.store.dispatch({
                type: this.searchService.actions.overwrite_user_search_filter.name,
                payload: { id: saveForm.value.id, ...userSearchFilter },
            });
        }
        this.dialogRef.close();

    }
}
