/**
 * Actions used by all modules. It can only depend on lower layers, no verticals or app imports!
 */
// BEWARE OF IMPORTS!!!!


import { getAssetURL, getMainTabFromUrl, maxOpenAssets } from './config';
import {
    AccessState, AccountState, ActiveTocTab, AppState, AssetRef, AssetTab, defaultAccessState, defaultAccountState, defaultAppState, defaultRechercheState, enhanceActionsObject, HitlistPaging,
    HitlistType, makeAction, NewsType, SlxAction, StoreBackupInformation, UserFavorite, clearLastStateOfActions
} from './models';
import { ActionState, Alert, defaultCommonAppState, isAlertOutdated, withAddedAlert } from './models/state/commonApp';
import { defaultCustomerProfileState, Preferences } from './models/state/customerProfile';
import { isResponsive, splitArray } from './utility/utilityFunctions';

export function alert(state: AppState, { payload }) {
    return {
        ...state,
        app: withAddedAlert(state.app, payload),
    };
}

export function updateAppErrors(state: AppState, action) {
    const app = state.app || defaultCommonAppState;
    const error = {
        time: Date.now(),
        ...action,
        alert: undefined,
        baseType: undefined,
        type: undefined,
    };
    return {
        ...state,
        app: {
            ...app,
            appErrors: app.appErrors.concat(error),
        },
    };
}

export const AppActions = {
    alert(state: AppState, action) {
        return alert(state, action);
    },

    backup_store(state, { payload }: { payload: StoreBackupInformation }) {
        return { ...state, app: { ...state.app, storeRestoreKey: null } };
    },

    reset_store(state: AppState, { payload }) {
        return payload.automaticLogout ? { ...state, account: defaultAccountState, access: { ...defaultAccessState, loggedOut: true } }
            : { ...defaultAppState, access: { ...defaultAccessState, loggedOut: true }};
    },

    restore_store(state: AppState, { payload, result }) {
        return result && payload ? payload : state;
    },

    browserwindow_was_resized(state: AppState, { type, payload }) {
        return {
            ...state,
            app: {
                ...state.app,
                browserWindowSize: payload.resizeEnd,
            },
        };
    },

    move_secondary_to_primary_assets(state: AppState, { payload }) {
        const primary = state.recherche.primaryTabState.assetTabs.concat(state.recherche.secondaryTabState.assetTabs);
        return {
            ...state,
            recherche: {
                ...state.recherche,
                primaryTabState: {
                    ...state.recherche.primaryTabState,
                    assetTabs: primary,
                },
                secondaryTabState: defaultRechercheState.secondaryTabState,
            },
        };
    },

    load_profile_prefs(state, { payload, result }: { payload: Preferences, result?}) {
        const profilePrefs = { ...defaultCustomerProfileState.profilePrefs, ...payload };
        return result ? {
            ...state,
            customerProfile: {
                ...state.customerProfile,
                profilePrefs,
            },
        } : state;
    },

    load_user_tips(state, { payload, result }) {
        return result ? {
            ...state,
            account: {
                ...state.account,
                tipsUser: [...defaultAccountState.tipsUser, ...payload],
            },
        } : state;
    },

    load_tips(state, { payload, result }) {
        return result ? {
            ...state,
            account: {
                ...state.account,
                tips: [...defaultAccountState.tips, ...payload],
            },
        } : state;
    },

    load_welcome(state, { payload, result }) {
        return result ? {
            ...state,
            account: {
                ...state.account,
                welcome: [...defaultAccountState.welcome, ...payload],
            },
        } : state;
    },

    save_user_tips(state, { payload, result }) {
        return result ? {
            ...state,
            account: {
                ...state.account,
                tipsUser: [...defaultAccountState.tipsUser, ...payload],
            },
        } : state;
    },

    set_collection_tab(state, { payload }) {
        const restoreOnToggle = payload.restoreOnToggle && (payload.isPrimary ? state.recherche.activeTabForToc === ActiveTocTab.Primary : state.recherche.activeTabForToc === ActiveTocTab.Secondary);
        const collectionTab = restoreOnToggle && state.recherche.collectionTab === payload.collectionTab ? state.recherche.previousCollectionTab : payload.collectionTab;
        return {
            ...state,
            recherche: {
                ...state.recherche,
                collectionTab,
                previousCollectionTab: state.recherche.collectionTab ? state.recherche.collectionTab : state.recherche.previousCollectionTab,
                searchCollapsed: true,
                hitlistOpen: payload.collectionTab ? true : false,
            },
        };
    },

    /*
        Switches to Recherche tab
        Opens a list of doc/toc in primary if not already there
        Opens a list of doc/toc in secondary if not already there
    */
    present_results(state: AppState, action) {
        const { payload } = action;

        // only one tab is allowed
        if (state.customerProfile.profilePrefs && !state.customerProfile.profilePrefs.isParallelDocView || isResponsive()) {
            payload.primary = payload.primary.concat(payload.secondary);
            payload.secondary = [];
        }
        const { hitlist: hitlistPayload, primary, secondary } = payload; // AssetRef, AssetRef[], AssetRef
        const mainTab = 'rech';
        const ref = primary.length ? primary[0] : hitlistPayload;

        //too many assets
        const assets = state.recherche.primaryTabState.assetTabs.concat(state.recherche.secondaryTabState.assetTabs);
        const newAsset = primary.concat(secondary).filter(r => !assets.find(a => a.assetID === AssetRef.toAssetID(r)));
        if (assets.length + newAsset.length > maxOpenAssets) {
            payload.tooManyAssets = true;
            return state;
        }

        function updatedTabState(tabState, list) {
            const inProgress = list.map(toAssetTab).filter(notAlreadyIn(tabState.assetTabs, 'assetID'));
            if (inProgress.length) {
                return {
                    ...tabState,
                    assetTabs: tabState.assetTabs.concat(inProgress),
                    currentAssetTab: inProgress[inProgress.length - 1],
                };
            }
            return tabState;
        }

        function toAssetTab(tab: AssetRef | AssetTab) {
            const ref = tab as AssetRef;
            if (ref.length && ref.length >= 2) {
                const newTab = AssetTab.create(ref, favoriteForAsset(ref, state));
                return newTab;
            }
            return tab;
        }

        // decorates the action with the URL to apply (don't overuse this ability)
        if (state.home.mainTab !== 'rech' && ref) {
            action.navigate = [getAssetURL([...ref, ...hitlistPayload])];
        }

        return {
            ...state,
            home: {
                ...state.home,
                mainTab,
            },
            recherche: {
                ...state.recherche,
                primaryTabState: updatedTabState(state.recherche.primaryTabState, primary),
                secondaryTabState: updatedTabState(state.recherche.secondaryTabState, secondary),
                searchCollapsed: true,
                euTocCollapsed: true,
            },
        };
    },


    save_profile_prefs(state, { payload, result }) {
        if (result) {
            return {
                ...state,
                customerProfile: {
                    ...state.customerProfile,
                    profilePrefs: {
                        ...state.customerProfile.profilePrefs,
                        ...payload,
                    },
                },
            };
        } else {
            return state;
        }
    },

    set_main_tab(state: AppState, action: SlxAction) {
        const { payload: mainTab } = action;
        const effectiveMainURL = state.home.tabUrl[mainTab];

        if (state.home.mainTab !== mainTab) {
            // decorates the action with the URL to apply (don't overuse this ability)
            action.navigate = effectiveMainURL instanceof Array ? effectiveMainURL : [effectiveMainURL];
        }

        const returnValue = {
            ...state,
            home: {
                ...state.home,
                mainTab,
            },
        };

        if (action.payload === 'rech') {
            returnValue.recherche = {
                ...state.recherche,
                searchCollapsed: false,
            };
        }

        return returnValue;
    },

    set_main_tab_from_url(state: AppState, { payload }) {
        const mainTab = getMainTabFromUrl(payload);
        const tabUrl = { ...state.home.tabUrl, [mainTab]: payload };

        // no memory
        // sessionStorage.setItem('main-tab-url', JSON.stringify(tabUrl));

        return {
            ...state,
            home: {
                ...state.home,
                mainTab,
                tabUrl,
            },
        };
    },

    pin_alert(state: AppState, { payload }) {
        const app = state.app || defaultCommonAppState;

        return {
            ...state,
            app: {
                ...app,
                alerts: app.alerts.map(alert => (payload.id === alert.id) ? { ...alert, dismissAt: null } : alert),
            },
        };
    },

    close_alert(state: AppState, { payload }) {
        const app = state.app || defaultCommonAppState;

        function different(one, two) {
            if (one === two) return false;
            if (one.id === two.id) return false;
            if (typeof two === 'string' && String(one.id) === String(two)) return false;
            return true;
        }

        const splittedAlertArr = splitArray(app.alerts, (val) => different(val, payload));

        for (const alert of splittedAlertArr[0]) {
            if (!alert.fromAction) {
                continue;
            }

            app.lastStateOfActions[alert.fromAction] = ActionState.Cleared;
        }

        return {
            ...state,
            app: {
                ...app,
                alerts: splittedAlertArr[1],
            },
        };
    },

    prune_alerts(state: AppState) {
        const app = state.app || defaultCommonAppState;
        const splittedAlertArr = splitArray(app.alerts, (val) => !isAlertOutdated(val));

        for (const alert of splittedAlertArr[0]) {
            if (!alert.fromAction) {
                continue;
            }

            app.lastStateOfActions[alert.fromAction] = ActionState.Cleared;
        }

        return {
            ...state,
            app: {
                ...app,
                alerts: splittedAlertArr[1],
            },
        };
    },

    // only after 1 second from dispatch
    dismiss_transient_alerts(state: AppState) {
        const app = state.app || defaultCommonAppState;
        if (!app.alerts.length) return state;

        const now = Date.now();
        const splittedAlertArr = splitArray(app.alerts, (val) => !val.transientFrom || val.transientFrom > now);

        for (const alert of splittedAlertArr[0]) {
            if (!alert.fromAction) {
                continue;
            }

            app.lastStateOfActions[alert.fromAction] = ActionState.Cleared;
        }

        return {
            ...state,
            app: {
                ...app,
                alerts: splittedAlertArr[1],
            },
        };
    },
    apply_selected_publications(state, { payload }) {
        return {
            ...state,
            recherche: {
                ...state.recherche,
                selectedPublications: payload,
            },
        };
    },

    clear_state_of_action(state: AppState) {
        const app = state.app || defaultCommonAppState;
        clearLastStateOfActions(app);

        return {
            ...state,
            app,
        };
    },
};
enhanceActionsObject(AppActions);

function favoriteForAsset(ref: AssetRef, state: AppState): UserFavorite | null {
    const assetID = AssetRef.toAssetID(ref);
    const favorite = state.recherche.userFavorites ? state.recherche.userFavorites.reduce((f, c) => c.children ? f.concat(c.children).concat(c) : f.concat(c), []).find(fav => fav.targetID === assetID) : null;
    return favorite;
}


function notAlreadyIn(list, idName) {
    return (entry) => !list.find(e => e[idName] === entry[idName]);
}
