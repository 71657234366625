module.exports = {
	"rech-hitlist-hits": "Résultat(s)",
	"rech-hitlist-deactivateFilter": "Désactiver le filtre",
	"rech-hitlist-change-state": "Activer / Désactiver le filtre",
	"rech-hitlist-searchSubscribtion": "Créer un abonnement de recherche",
	"rech-hitlist-to-news-area": "Aller sur News-Service",
	"rech-hitlist-asPdf": "Télécharger la liste des résultats",
	"rech-hitlist-pdf-error": "Erreur lors du téléchargement du document",
	"rech-hitlist-sort": "Trier par",
	"rech-hitlist-date": "Date",
	"rech-hitlist-relevance": "Pertinence",
	"rech-hitlist-filterType": "Filtrer",
	"rech-hitlist-resetFilter": "Réinitialiser les filtres",
	"rech-hitlist-languages": "Langues",
	"rech-hitlist-premiumhits": "Résultats avec Premium",
	"rech-hitlist-more-hits": "Avec abonnement Premium:",
	"rech-hitlist-more-hist-count": "actuellement {{hitCount}} jurisprudence supplémentaire",
	"rech-hitlist-more-info": "En savoir plus",
	"rech-hitlist-collapse": "Agrandir / Réduire la fenêtre",
	"rech-hitlist-Commentary": "Commentaires",
	"rech-hitlist-Caselaw": "Arrêts",
	"rech-hitlist-OfficialPub": "Documents officiels",
	"rech-hitlist-CaselawReview": "Commentaires d'arrêt",
	"rech-hitlist-EssayNonBook": "Articles",
	"rech-hitlist-EssayOnlyBook": "Livres",
	"rech-hitlist-EssayAndBook": "Articles / Livres",
	"rech-hitlist-result": "résultats",
	"rech-hitlist-result-single": "Résultat(s)",
	"rech-hitlist-of": "de",
	"rech-hitlist-count": "{{subHitCount}} autres résultats dans cet ouvrage",
	"rech-hitlist-pdf": "Liste des résultats PDF",
	"rech-hitlist-filtern": "Filtrer",
	"rech-hitlist-eu-Treaty": "Traités / contrats",
	"rech-hitlist-eu-InternationalAgreement": "Accords internationaux",
	"rech-hitlist-eu-Legislation": "Prescriptions juridiques",
	"rech-hitlist-eu-LegislationRegulation": "Ordonnances",
	"rech-hitlist-eu-LegislationDirective": "Directives",
	"rech-hitlist-eu-LegislationDecision": "Décisions",
	"rech-hitlist-eu-LegislationNonOpposition": "Arrêts",
	"rech-hitlist-eu-ComDocuments": "Documents COM",
	"rech-hitlist-eu-CaseLaw": "Jurisprudence",
	"rech-hitlist-eu-EftaDocuments": "Documents de l'AELE",
	"rech-hitlist-eu-Undefined": "Non défini",
	"rech-hitlist-EUHitlist_Konsolidierte_Fassung": "Dernière version consolidée"
};