import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatCheckboxModule, MatDialogModule, MatIconModule, MatProgressBarModule, MatRadioModule, MatStepperModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';

import { AccessModule, NotFoundResolver } from '../access';
import { AccountModule, AccountService } from '../account';
import { FieldModule, InfoWindowComponent } from '../field';

import {
    AboRegistrationService, AboServiceEffects, AboServicesComponent, AboServicesResolver, AddNewRakUserComponent, RakPaypalComponent, RakSubscriptionComponent,
    RegistrationBusinessComponent, RegistrationLawExamCandidatesComponent, RegistrationStudentsComponent, TrainingComponent
} from './abo-services';
import { FaqDataComponent } from './abo-services/faq/faq-data/faq-data.component';
import { FaqEntryComponent } from './abo-services/faq/faq-entry/faq-entry.component';
import { FaqListGroupComponent } from './abo-services/faq/faq-list/faq-list-group/faq-list-group.component';
import { FaqListGroupRendererComponent } from './abo-services/faq/faq-list/faq-list-grouprenderer/faq-list-grouprenderer.component';
import { FaqListComponent } from './abo-services/faq/faq-list/faq-list.component';
import { FaqComponent } from './abo-services/faq/faq.component';
import { FaqResolver } from './abo-services/faq/faq.resolver';
import { RegistrationBusinessDialogComponent } from './abo-services/registration-business/registration-business-dialog/registration-business-dialog.component';
import { AboutComponent } from './about/about.component';
import { AboutBoardComponent } from './about/aboutBoard/about-board.component';
import { AboutContactComponent } from './about/aboutContact/about-contact.component';
import { AboutDirectionComponent } from './about/aboutDirection/about-direction.component';
import { AboutShareholdersComponent } from './about/aboutShareholder/aboutShareholders.component';
import { AboutTeamComponent } from './about/aboutTeam/about-team.component';
import { AlertsComponent } from './alerts/alerts.component';
import { AlertWindowComponent } from './alerts/alertWindow/alertWindow.component';
import { GetAlertClassPipe, SingleAlertComponent } from './alerts/singleAlert/singleAlert.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home.component';
import { HomeService } from './home.service';
import { HomeEffects } from './homeEffects';
import { EventsTileComponent } from './homeTiles/eventsTile/eventsTile.component';
import { HomeTilesComponent } from './homeTiles/homeTiles.component';
import { JobsTileComponent } from './homeTiles/jobsTile/jobsTile.component';
import { NewsTileComponent } from './homeTiles/newsTile/newsTile.component';
import { HasFollowingTipPipe, HasPrecedingTipPipe, TipOfTheDayTileComponent } from './homeTiles/tipOfTheDayTile/tipOfTheDayTile.component';
import { TopDocsTileComponent } from './homeTiles/topDocsTile/topDocsTile.component';
import { MarkdownPageComponent } from './markdown-page/markdown-page.component';
import { SubHeaderMainNavigationComponent } from './subheader-main-navigation/subheader-main-navigation.component';
import { SubHeaderComponent } from './subheader/subheader.component';


export const homeRoute = {
    path: '', pathMatch: 'full', component: HomeComponent,
};

export const notFoundRoute = { path: '**', resolve: { notFound: NotFoundResolver }, component: HomeComponent };

export const homeRedirect = {
    path: 'home', redirectTo: '', pathMatch: 'full',
};

export const infoRoute = {
    path: 'info',
    children: [
        { path: 'operatorhelp', component: InfoWindowComponent, data: { filename: 'operator-help' } },
        { path: 'filterhelp', component: InfoWindowComponent, data: { filename: 'eu_filter-help', foldername: 'Eu' } },
        { path: 'legislationhelp', component: InfoWindowComponent, data: { filename: 'eu_legislation-help', foldername: 'Eu' } },
        { path: 'treatyhelp', component: InfoWindowComponent, data: { filename: 'eu_treaty-help', foldername: 'Eu' } },
        { path: 'agreementhelp', component: InfoWindowComponent, data: { filename: 'eu_agreement-help', foldername: 'Eu' } },
        { path: 'caselawhelp', component: InfoWindowComponent, data: { filename: 'eu_case-law-help', foldername: 'Eu' } },
        { path: 'celexhelp', component: InfoWindowComponent, data: { filename: 'eu_celex-help', foldername: 'Eu' } },
    ],
};

// TODO Filenames
export const aboAndServicesRoute = {
    path: 'service',
    component: AboServicesComponent,
    children: [
        { path: '', redirectTo: 'standard', pathMatch: 'full' },
        { path: 'standard', component: MarkdownPageComponent, data: { filename: 'services-swisslex_standard' } },
        { path: 'premium', component: MarkdownPageComponent, data: { filename: 'services-swisslex_premium' } },
        { path: 'abofees', component: MarkdownPageComponent, data: { filename: 'services-swisslex_abofees' } },
        { path: 'business', component: RegistrationBusinessComponent, resolve: { aboService: AboServicesResolver } },
        { path: 'students', component: RegistrationStudentsComponent, resolve: { aboService: AboServicesResolver } },
        {
            path: 'lawexamcandidates', component: RegistrationLawExamCandidatesComponent, resolve: {
                aboService: AboServicesResolver,
            }, children: [
                { path: '', redirectTo: 'rak-subscription', pathMatch: 'full' },
                {
                    path: 'rak-subscription', component: RakSubscriptionComponent, resolve: { aboService: AboServicesResolver },
                },
                {
                    path: 'rak-paypal', component: RakPaypalComponent, resolve: { aboService: AboServicesResolver },
                },
                {
                    path: 'add-new-rak-user', component: AddNewRakUserComponent, resolve: {
                        aboService: AboServicesResolver,
                    },
                }],
        },
        { path: 'training', component: TrainingComponent },
        { path: 'agb', component: MarkdownPageComponent, data: { filename: 'swisslex_agb' } },
        { path: 'disclaimer', component: MarkdownPageComponent, data: { filename: 'swisslex_disclaimer' } },
    ],
};

export const faqRoute = {
    path: 'faq',
    children: [
        { path: '', redirectTo: 'common', pathMatch: 'full' },
        { path: 'common', component: FaqComponent, resolve: { faq: FaqResolver }, data: { topicID: 'd08049f6-935a-4ca3-b2fe-38b6d9d6853d' }},
        { path: 'search', component: FaqComponent, resolve: { faq: FaqResolver }, data: { topicID: 'a7215660-fa73-4341-8d9e-277445948b5c' }},
        { path: 'view', component: FaqComponent, resolve: { faq: FaqResolver }, data: { topicID: 'c597f7a0-1322-45c4-b23d-99093950f89d' }},
        { path: 'wizard', component: FaqComponent, resolve: { faq: FaqResolver }, data: { topicID: '4e2a777f-2bca-40c6-a99d-69f570a323cc' }},
        { path: 'workspace', component: FaqComponent, resolve: { faq: FaqResolver }, data: { topicID: '0e70e583-57e9-4810-9add-6d718cfbee3f' }},
        { path: 'settings', component: FaqComponent, resolve: { faq: FaqResolver }, data: { topicID: '7edd4830-82a1-4eec-b28e-2fdebecb21b0' }},
        { path: 'entry', component: FaqComponent, children: [
                { path: 'add', component: FaqEntryComponent },
                { path: 'edit', component: FaqListComponent },
                { path: 'edit/:id', component: FaqEntryComponent },
            ],
        },
    ],
};

export const aboutRoute = {
    path: 'about',
    component: AboutComponent,
    children: [
        { path: '', redirectTo: 'company', pathMatch: 'full' },
        { path: 'company', component: MarkdownPageComponent, data: { filename: 'about-swisslex_company' } },
        { path: 'shareholders', component: AboutShareholdersComponent },
        { path: 'board', component: AboutBoardComponent },
        { path: 'team', component: AboutTeamComponent },
        { path: 'direction', component: AboutDirectionComponent },
        { path: 'contact', component: AboutContactComponent },
    ],
};

@NgModule({
    imports: [
        CommonModule,
        EffectsModule.forFeature([HomeEffects, AboServiceEffects]),
        AccessModule,
        AccountModule,
        RouterModule,
        MatProgressBarModule,
        MatButtonModule,
        MatRadioModule,
        MatIconModule,
        ReactiveFormsModule,
        FormsModule,
        FieldModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        MatStepperModule,
        CdkStepperModule,
        MatDialogModule,
        MatCheckboxModule,
    ],
    entryComponents: [
        AlertWindowComponent,
        RegistrationBusinessDialogComponent,
    ],
    declarations: [
        HomeComponent,
        HeaderComponent,
        AboutComponent,
        AboutBoardComponent,
        AboutTeamComponent,
        MarkdownPageComponent,
        AboServicesComponent,
        RegistrationBusinessComponent,
        RegistrationLawExamCandidatesComponent,
        RegistrationStudentsComponent,
        AboutDirectionComponent,
        AboutContactComponent,
        AlertsComponent,
        AlertWindowComponent,
        SingleAlertComponent,
        RegistrationBusinessComponent,
        RakSubscriptionComponent,
        AddNewRakUserComponent,
        RakPaypalComponent,
        HomeTilesComponent,
        EventsTileComponent,
        JobsTileComponent,
        TopDocsTileComponent,
        NewsTileComponent,
        TipOfTheDayTileComponent,
        AboutShareholdersComponent,
        TrainingComponent,
        FaqComponent,
        FaqEntryComponent,
        FaqListComponent,
        FaqListGroupComponent,
        FaqListGroupRendererComponent,
        FaqDataComponent,
        HasPrecedingTipPipe,
        HasFollowingTipPipe,
        GetAlertClassPipe,
        RegistrationBusinessDialogComponent,
        SubHeaderComponent,
        SubHeaderMainNavigationComponent,
        HeaderComponent,
    ],
    exports: [
        HomeComponent,
        HeaderComponent,
        AboutComponent,
        AboutBoardComponent,
        AboutTeamComponent,
        AboutDirectionComponent,
        AboutContactComponent,
        AlertsComponent,
        HomeTilesComponent,
        AboutShareholdersComponent,
        SubHeaderMainNavigationComponent,
    ],
    providers: [AboServicesResolver, AboRegistrationService, HomeService, FaqResolver],
})
export class HomeModule {
    constructor(private account: AccountService) {
        this.account.addTranslation('',
            require('./home.de.yaml'),
            require('./home.fr.yaml')
        );
        this.account.addTranslation('about-',
            require('./about/about.de.yaml'),
            require('./about/about.fr.yaml')
        );
    }
}
