import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms'; // FormBuilder, Validators
import { TranslateService } from '@ngx-translate/core';
import { Observable ,  Subscription } from 'rxjs';

import { AccountService } from '../../../account';
import { AutocompleteService } from '../../../asset';
import { requiredValidator } from '../../../field';
import { AutocompleteParamter, AutocompleteType, LanguageEnum, SearchType } from '../../../models';
import { SearchService } from '../../search.service';
import { SearchFormBase } from '../search-form-base';


@Component({
    selector: 'slx-eu-search',
    template: `<slx-search-form *ngIf="formDescription && searchForm" [formDescription]="formDescription" [searchForm]="searchForm"
                    [subTabs]="subTabs" (searchSubmitted)="onSubmit()" (searchReset)="onReset()"></slx-search-form>`,
})
export class EuSearchComponent extends SearchFormBase {

    private onLangChangedSubscription: Subscription;

    public languageOptions: any;
    public selectedLanguage: LanguageEnum;

    private initialMultiCheckValues: any;
    private initialValuesSubscription: Subscription;

    subTabs = [
        { translate: 'rech-search', key: 'eulaws' },
        { translate: 'rech-directAccess', key: 'eudirect' },
    ];
    constructor(protected searchService: SearchService, protected autocompleteService: AutocompleteService, protected accountService: AccountService, protected formBuilder: FormBuilder, protected changeDetectorRef: ChangeDetectorRef, protected translate: TranslateService) {
        super(searchService, SearchType.EuRetrieval, changeDetectorRef, translate);
        // bind autocomplete get url functions (this)
        this.getSearchTextAutocomplete = this.getSearchTextAutocomplete.bind(this);
        this.langSelectionChanged = this.langSelectionChanged.bind(this);

        this.searchForm = this.formBuilder.group({
            searchText: ['', requiredValidator()],
            searchLanguage: [''],
            dateFrom: [''],
            dateUntil: [''],
            legalActTypeCriteria: [''],
        });

        this.selectedLanguage = accountService.langEnum;
        this.initLanguages();

        this.formDescription = {
            formGroups: [
                {
                    class: 'slx-form-row no-wrap',
                    controls: [
                        {
                            control: 'slx-text', name: 'Suche', type: 'text', placeholder: 'rech-searchtext', formControlName: 'searchText', class: 'slx-field-xlarge',
                            makeAutocomplete: this.getSearchTextAutocomplete,
                        },
                        { control: 'slx-info-button', filename: 'operator-help', titleTransString: 'home-info-operator-help' },
                        {
                            control: 'slx-select', name: 'SearchLanguage', type: 'dropdown', placeholder: 'rech-language', formControlName: 'searchLanguage',
                            options: this.languageOptions, selected: this.selectedLanguage, selectionChanged: this.langSelectionChanged, class: 'slx-field-medium no-grow',
                        },
                        { control: 'slx-info-button', filename: 'language-help', titleTransString: 'home-info-language-help', class: 'in-form' },
                    ],
                },
                {
                    class: 'slx-form-row',
                    controls: [
                        { control: 'slx-date', name: 'DateFrom', placeholder: 'rech-dateFrom', formControlName: 'dateFrom', class: 'slx-field-large no-grow' },
                        { control: 'slx-date', name: 'DateUntil', placeholder: 'rech-dateUntil', formControlName: 'dateUntil', class: 'slx-field-large no-grow' },
                    ],
                },
                {
                    class: 'slx-form-row no-wrap ',
                    controls: [
                        { control: 'slx-multicheck', name: 'legalActTypeCriteria', placeholder: 'rech-document-category-filter', formControlName: 'legalActTypeCriteria', allChecked: this.searchForm.value.legalActTypeCriteria.length === 0, class: 'slx-field-xlarge margin-bottom-1' },
                    ],
                },
            ],
            controls: {
                resetControl: true,
            },
            searchButtonText: 'rech-searching',
        };

        this.onLangChangedSubscription = accountService.onLangChange.subscribe(() => {
            this.selectedLanguage = accountService.langEnum;
            this.searchForm.controls['searchLanguage'].setValue(this.selectedLanguage);
        });
    }

    ngOnInit() {
        this.initialValuesSubscription = this.searchService.initialValuesForEuMultiCheck.subscribe(values => this.initialMultiCheckValues = values)
            .add(this.searchService.store.select(state => state.recherche.searchForms[SearchType[SearchType.EuRetrieval]]).subscribe(state => {
                if (Object.keys(state).length === 0) { // default value => empty object
                    this.initSearchForm();
                    return;
                }
                this.searchForm.patchValue(state);
            }));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.initialValuesSubscription.unsubscribe();
        this.onLangChangedSubscription.unsubscribe();
    }

    initLanguages() {
        this.languageOptions = [];
        for (const language in LanguageEnum) {
            if (!isNaN(Number(language)) && language !== LanguageEnum.undefined.toString() && language !== LanguageEnum.Rm.toString()) {
                const opt = {
                    value: Number(language),
                    viewValue: LanguageEnum[language],
                };
                this.languageOptions.push(opt);
            }
        }
    }

    getSearchTextAutocomplete() {
        return (): Observable<any> => {
            const paramters = [
                new AutocompleteParamter('searchText', this.searchForm.value.searchText),
                new AutocompleteParamter('language', LanguageEnum[this.searchForm.value.searchLanguage].toLowerCase()),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.RetrievalSearchText, false, paramters);
        };
    }

    initSearchForm() {
        this.searchForm.controls['legalActTypeCriteria'].setValue(this.initialMultiCheckValues.legalActTypeGroupsCriteria);
        this.searchForm.controls['dateFrom'].setValue('');
        this.searchForm.controls['dateUntil'].setValue('');
        this.searchForm.controls['searchLanguage'].setValue(this.selectedLanguage);
    }

    public langSelectionChanged(lang) {
        this.searchForm.controls['searchLanguage'].setValue(lang);
    }
}
