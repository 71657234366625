import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'slx-newslettertype-select',
    templateUrl: './newslettertype-select.component.html',
    styleUrls: ['./newslettertype-select.component.scss'],
})
export class NewslettertypeSelectComponent implements OnInit {

    @Input() form: FormGroup;

    constructor() { }

    ngOnInit() {
        if (this.form.controls['value'].value) {
            this.form.controls['value'].reset();
        }
    }
}
