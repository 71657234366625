import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, zip } from 'rxjs';
import { first, map, pairwise } from 'rxjs/operators';

import { FaqListGroupComponent } from '../faq-list-group/faq-list-group.component';

import { FaqListGroupRendererComponent } from './faq-list-grouprenderer.component';

@Injectable({
    providedIn: 'root',
})
export class FaqListGroupRendererService {
    private components: (FaqListGroupRendererComponent | FaqListGroupComponent)[] = [];

    constructor() { }

    public updateService(updatedComponent: FaqListGroupRendererComponent | FaqListGroupComponent) {
        updatedComponent.index = updatedComponent.index ? updatedComponent.index : this.components.length;
        if (this.components[updatedComponent.index]) {
            this.components[updatedComponent.index] = updatedComponent;
            return;
        }
        this.components.push(updatedComponent);
    }

    public useEditMode(index: number) {
        this.components
            .filter(component => component.index !== index)
            .forEach(component => component.setEditMode(false));
    }
}
