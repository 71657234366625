import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTabsModule,
    MatTooltipModule,
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { SortablejsModule } from 'angular-sortablejs';
import { TreeModule } from 'angular-tree-component';
import { ChartsModule } from 'ng2-charts';

import { AccessModule, NotFoundResolver } from '../access';
import { AccountModule, AccountService } from '../account';
import { AssetModule } from '../asset';
import { BiblioInfoComponent } from '../asset/docview/bilblioInfo/biblio-info.component';
import { FieldModule } from '../field';

import { HomeModule } from './../home';
import { FavoritesComponent } from './favorites/favorites.component';
import {
    AuthorComponent, BookComponent, CommentaryComponent, EuAgreementComponent,
    EuCaseLawComponent, EuCelexNumberComponent, EuDirectSearchComponent, EuLegislationComponent, EuSearchComponent, EuTreatyComponent,
    JournalComponent, LawComponent, NotPupCaseCollectionComponent, PupCaseCollectionComponent, SearchComponent, SearchFormComponent
} from './form';
import { ClickablePipe, IsActivePipe, LoadingSpinnerFiltersShownPipe } from './hitlist/hitlist-pipes';
import { HitlistComponent } from './hitlist/hitlist.component';
import { PublicationSelectDialogComponent } from './publication-select-dialog/publication-select-dialog.component';
import { RechercheComponent } from './recherche.component';
import { RechercheResolver } from './recherche.resolver';
import { SearchCollapseDirective } from './search-collapse.directive';
import { SearchHistoryComponent } from './search-history/search-history.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SearchService } from './search.service';
import { SearchEffects } from './searchEffects';
import { SearchfilterbarComponent } from './searchfilters/searchfilterbar/searchfilterbar.component';
import { SearchfiltersComponent } from './searchfilters/searchfilters.component';
import { SearchFilterSaveComponent } from './searchfilters/searchfiltersave/searchfilter-save.component';
import { SidebarActionsComponent } from './sidebar-actions/sidebar-actions.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TocInSidebarComponent } from './sidebar/toc-in-sidebar/toc-in-sidebar.component';

export const rechercheRoute = {
    path: 'recherche',
    children: [
        {
            path: 'favorites',
            component: RechercheComponent,
            data: {
                type: 'recherche',
                subtype: 'favorites',
            },
            resolve: {
                recherche: RechercheResolver,
            },
        },
        {
            path: 'history',
            component: RechercheComponent,
            data: {
                type: 'recherche',
                subtype: 'history',
            },
            resolve: {
                recherche: RechercheResolver,
            },
        },
        {
            path: ':subtype/:id/source/:source',
            component: RechercheComponent,
            data: {
                type: 'recherche',
            },
            resolve: {
                transaction: RechercheResolver,
            },
        },
        {
            path: ':subtype',
            children: [
                {
                    path: ':id',
                    component: RechercheComponent,
                    data: {
                        type: 'recherche',
                    },
                    resolve: {
                        recherche: RechercheResolver,
                    },
                },
                {
                    path: '**', component: RechercheComponent, resolve: {
                        notFound: NotFoundResolver,
                    },
                },
            ],
        },
        {
            path: '**', redirectTo: '/recherche/search/new',
        },
    ],
};


export const tocRoute = {
    path: 'toc',
    children: [
        {
            path: ':id',
            component: RechercheComponent,
            data: {
                type: 'toc',
            },
            resolve: {
                transaction: RechercheResolver,
            },
        },
        {
            path: ':subtype/:id',
            component: RechercheComponent,
            data: {
                type: 'toc',
            },
            resolve: {
                transaction: RechercheResolver,
            },
        },
        {
            path: ':subtype/:id/source/:source',
            component: RechercheComponent,
            data: {
                type: 'toc',
            },
            resolve: {
                transaction: RechercheResolver,
            },
        },
        {
            path: ':subtype/:id/:subid',
            component: RechercheComponent,
            data: {
                type: 'toc',
            },
            resolve: {
                transaction: RechercheResolver,
            },
        },
        {
            path: ':subtype/:id/:subid/source/:source',
            component: RechercheComponent,
            data: {
                type: 'toc',
            },
            resolve: {
                transaction: RechercheResolver,
            },
        },
        {
            path: ':subtype/:id/:option/:subid',
            component: RechercheComponent,
            data: {
                type: 'toc',
            },
            resolve: {
                transaction: RechercheResolver,
            },
        },
        {
            path: ':subtype/:id/:option/:subid/source/:source',
            component: RechercheComponent,
            data: {
                type: 'toc',
            },
            resolve: {
                transaction: RechercheResolver,
            },
        },
        {
            path: '**', redirectTo: '/recherche/search/new',
        },
    ],
};

export const docRoute = {
    path: 'doc',
    children: [
        // current urls
        {
            path: ':id',
            component: RechercheComponent,
            data: {
                type: 'doc',
            },
            resolve: {
                transaction: RechercheResolver,
            },
        },
        {
            path: ':subtype/:id',
            component: RechercheComponent,
            data: {
                type: 'doc',
            },
            resolve: {
                recherche: RechercheResolver,
            },
        },
        {
            path: ':subtype/:id/source/:source',
            component: RechercheComponent,
            data: {
                type: 'doc',
            },
            resolve: {
                recherche: RechercheResolver,
            },
        },
        {
            path: ':subtype/:id/:subid',
            component: RechercheComponent,
            data: {
                type: 'doc',
            },
            resolve: {
                recherche: RechercheResolver,
            },
        },
        {
            path: ':subtype/:id/:subid/source/:source',
            component: RechercheComponent,
            data: {
                type: 'doc',
            },
            resolve: {
                recherche: RechercheResolver,
            },
        },
        {
            path: ':subtype/:id/:option/:subid',
            component: RechercheComponent,
            data: {
                type: 'doc',
            },
            resolve: {
                recherche: RechercheResolver,
            },
        },
        {
            path: ':subtype/:id/:option/:subid/source/:source',
            component: RechercheComponent,
            data: {
                type: 'doc',
            },
            resolve: {
                recherche: RechercheResolver,
            },
        },
        {
            path: '**', redirectTo: '/recherche/search/new',
        },
    ],
};

@NgModule({
    imports: [
        CommonModule,
        AccessModule,
        AccountModule,
        EffectsModule.forFeature([SearchEffects]),
        AssetModule,
        RouterModule,
        MatButtonModule,
        MatProgressBarModule,
        MatExpansionModule,
        MatTabsModule,
        ReactiveFormsModule,
        FieldModule,
        FormsModule,
        MatProgressSpinnerModule,
        SortablejsModule,
        MatTooltipModule,
        MatCheckboxModule,
        ChartsModule,
        MatRadioModule,
        MatDialogModule,
        MatMenuModule,
        MatIconModule,
        TreeModule.forRoot(),
        HomeModule,
    ],
    entryComponents: [
        SearchFilterSaveComponent,
        SearchfiltersComponent,
        BiblioInfoComponent,
        PublicationSelectDialogComponent,
    ],
    declarations: [
        RechercheComponent,
        SearchComponent,
        ClickablePipe,
        IsActivePipe,
        LoadingSpinnerFiltersShownPipe,
        JournalComponent,
        PupCaseCollectionComponent,
        NotPupCaseCollectionComponent,
        CommentaryComponent,
        BookComponent,
        AuthorComponent,
        LawComponent,
        SearchfiltersComponent,
        SearchfilterbarComponent,
        SearchFormComponent,
        HitlistComponent,
        SearchHistoryComponent,
        EuSearchComponent,
        EuDirectSearchComponent,
        EuTreatyComponent,
        EuLegislationComponent,
        EuCelexNumberComponent,
        EuCaseLawComponent,
        EuAgreementComponent,
        SearchCollapseDirective,
        SearchResultsComponent,
        FavoritesComponent,
        SearchFilterSaveComponent,
        BiblioInfoComponent,
        SidebarComponent,
        TocInSidebarComponent,
        SidebarActionsComponent,
        PublicationSelectDialogComponent,
    ],
    providers: [
        RechercheResolver,
        SearchService,
    ],
})
export class RechercheModule {
    constructor(private account: AccountService) {
        this.account.addTranslation('rech-',
            require('./rech.de.yaml'),
            require('./rech.fr.yaml')
        );
        this.account.addTranslation('rech-hitlist-',
            require('./hitlist/rech-hitlist.de.yaml'),
            require('./hitlist/rech-hitlist.fr.yaml')
        );
    }
}
