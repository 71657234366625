import { Observable, of, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { maxItemsInFrontendCache } from '../config';

const debug = require('debug')('cache');
const maxItemsInCache = maxItemsInFrontendCache;

export class FrontendCache<TKey, TContent> {
    private cache = new Map<TKey, CachedContent<TContent>>();
    private notifiers = new Map<TKey, Notifer<TContent>>();
    private maxAge: number;
    private requestContent: (key: TKey) => Observable<TContent>;
    private keyNormalizer: (key: TKey) => TKey;
    private keyToRemoveSelector: (iterator: Iterator<TKey>) => TKey;

    constructor(maxAge: number, requestContent: (key: TKey) => Observable<TContent>, keyNormalizer?: (key: TKey) => TKey, keyToRemoveSelector?: (iterator: Iterator<TKey>) => TKey) {
        this.maxAge = maxAge;
        this.requestContent = requestContent;
        this.keyNormalizer = keyNormalizer;
        this.keyToRemoveSelector = keyToRemoveSelector;
    }

    public getContent(key: TKey): Observable<TContent> {
    //    debug('Trying to get content', key);

        const normalizedKey = this.keyNormalizer ? this.keyNormalizer(key) : key;
        const content = this.getFromCache(normalizedKey);
        const notifier = this.getNotifier(normalizedKey);

        if (content) {
     //       debug('Found in cache', key, normalizedKey, notifier.subject);
            return of(content);
        }

        // a possible way of resolving possible race conditions (which lead to multiple sent requests)
        Promise.resolve(null).then(() => {
            if (!notifier.requestInProgress) {
                this.load(key, normalizedKey);
            }
        });


        return notifier.subject.asObservable();
    }

    public forceReload(key: TKey): void {
        const normalizedKey = this.keyNormalizer ? this.keyNormalizer(key) : key;
        debug('forcing reload', key, normalizedKey);
        const notifier = this.getNotifier(normalizedKey);
        notifier.requestInProgress = true;
        this.removeFromCache(normalizedKey);
        this.load(key, normalizedKey);
    }

    private load(key: TKey, normalizedKey: TKey) : void {
      //  debug('Requesting content', key);
        const notifier = this.getNotifier(normalizedKey);
        notifier.requestInProgress = true;
        this.requestContent(key).pipe(first()).subscribe(content => {
            this.addToCache(normalizedKey, content);
            debug('content loaded', key, this.cache.size );
            notifier.subject.next(content);
            notifier.requestInProgress = false;
        }, error => {
            debug('content load error', key);
            notifier.subject.error(error);
            notifier.requestInProgress = false;
        });
    }

    public getFromCache(key: TKey): TContent {
        const cachedContent = this.cache.get(this.keyNormalizer ? this.keyNormalizer(key) : key);
        return cachedContent && !this.isTooOld(cachedContent) ? cachedContent.content : null;
    }

    public clear() {
        this.cache.clear();
        debug('cache has been cleared');
    }

    public deleteFromCache(key: TKey) : void {
        const normalizedKey = this.keyNormalizer ? this.keyNormalizer(key) : key;
        debug('removing from cache',normalizedKey);
        this.removeFromCache(normalizedKey);
    }

    private removeFromCache(key: TKey): void {
        this.cache.delete(key);
    }

    private addToCache(key: TKey, content: TContent): void {
        this.cache.set(key, new CachedContent(content));
      //  debug('added content to cache', this.cache);
        if (this.cache.size > maxItemsInCache) {
            const iterator = this.cache.keys();
            const keyOfOldest = this.keyToRemoveSelector ? this.keyToRemoveSelector(iterator): iterator.next().value; //default: this gives the oldest object that has been added
            debug(`too much content => deleting item ${keyOfOldest}`);
            this.removeFromCache(keyOfOldest);
            this.notifiers.delete(keyOfOldest);
        }
    }

    private isTooOld(cachedContent: CachedContent<TContent>): boolean {
        const maxDate = new Date();
        maxDate.setHours(maxDate.getHours() - this.maxAge);
        return cachedContent.timeStamp < maxDate;
    }

    private getNotifier(key: TKey): Notifer<TContent> {
        let notifier = this.notifiers.get(key);

        if (!notifier) {
       //     debug('created notifier', key);
            notifier = new Notifer<TContent>();
            this.notifiers.set(key, notifier);
        }
        return notifier;
    }

}

class CachedContent<TContent> {
    constructor(content: TContent) {
        this.content = content;
        this.timeStamp = new Date();
    }
    content: TContent;
    timeStamp: Date;
}

class Notifer<TContent> {
    constructor() {
        this.requestInProgress = false;
        this.subject = new Subject<TContent>();
    }
    requestInProgress: boolean;
    subject: Subject<TContent>;
}




