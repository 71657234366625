import { LanguageEnum } from '../enums';

import { PracticeArea } from './practiceArea';

export interface Tile {
    id: string;
    x: number;
    y: number;
    rows: number;
    cols: number;
    configuration: {
        title: string;
        type: {
            value: number;
            description: string;
        };

        deliveryConfiguration: {
            sendAsEmail: boolean;
            deliveryDay: number;
            timeWindow: number;
            sendOnlyOnHits: boolean;
        },
        publicationID?: string;
        PracticeAreas?: PracticeArea[];
        DocLanguages?: Array<LanguageEnum>;
        SearchFilter?: any;
        showAsTile: boolean;
    };

}

export interface NewsState {
    tiles: Tile[];
    tileContent: object; // id : hitlist
    practiceAreas: any;
    courts: any;
    journals: any;
    sidebarClosed: boolean;
}

export const defaultNewsState = {
    tiles: [],
    tileContent: {},
    practiceAreas: [],
    courts: [],
    journals: [],
    sidebarClosed: false,
};
