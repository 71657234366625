import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { SearchService } from '../recherche/search.service';
import { isResponsive } from '../utility/utilityFunctions';

import { SearchActions } from './searchActions';

/*  This Directive Handles ClickEvents in the ContentWrapper.
    Body is locked once search back button is used, a click on the hitlist / filters / documentView
    relases lock and collapses search again

    Show scroll back when expanded/collapsed changes for 5s, then show it when hovering over the opposing area.

*/

@Directive({
    selector: '[slx-search-collapse]',
})
export class SearchCollapseDirective implements OnDestroy, OnInit {

    @Input() enableScrollExpand = true;

    private subscription: Subscription;
    private searchCollapsed: boolean;
    private searchResults;

    constructor(private searchService: SearchService, private containerRef: ElementRef) {
        this.subscription = this.searchService.searchCollapsed.subscribe(
            searchCollapsed => {
                this.searchCollapsed = searchCollapsed;
                // if (!searchCollapsed) {
                //     this.showBackToTopInitially();
                // }
                // return searchCollapsed;
            }
        );
    }

    ngOnInit() {
        this.searchResults = this.containerRef.nativeElement.querySelector('slx-search-results');
    }

    @HostListener('click', ['$event', '$event.target']) onClick(event) {
        if (this.searchResults) {
            const overResults = this.searchResults.contains(event.target) || event.target.closest('.back-to-top') != null;
            if (overResults && !this.searchCollapsed && !event.target.closest('button') ) {
                event.preventDefault();
                this.searchService.dispatch({ type: SearchActions.collapse_search.name });
            }
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

