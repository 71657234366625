import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule, MatInputModule, MatTabsModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';

import { NotFoundResolver } from '../access';
import { AccountModule, AccountService } from '../account';
import { AssetModule } from '../asset';
import { FieldModule } from '../field';

import { HomeModule } from './../home';
import { BiblioStaticComponent } from './biblioStatic.component';
import { BibliothekComponent } from './bibliothek.component';
import { BibliothekResolver } from './bibliothek.resolver';
import { BibliothekService } from './bibliothek.service';
import { BibliothekEffects } from './bibliothekEffects';
import { BookBibliothekComponent } from './book/bookBibliothek.component';
import { BookUniBibliothekComponent } from './bookUni/bookUniBibliothek.component';
import { CaseCollectionBibliothekComponent } from './caseCollection/caseCollectionBibliothek.component';
import { CaseCollectionPublishedBibliothekComponent } from './caseCollection/caseCollectionPublishedBibliothek.component';
import { CaseCollectionUnpublishedBibliothekComponent } from './caseCollection/caseCollectionUnpublishedBibliothek.component';
import { EuVerzeichnisseComponent } from './eu/euVerzeichnisse.component';
import { JournalBibliothekComponent } from './journal/journalBibliothek.component';


export const biblioRoute = {
    path: 'biblio',
    component: BibliothekComponent,
    children: [
        { path: '', redirectTo: 'casecollections', pathMatch: 'full' },
        {
            path: 'casecollections',
            component: CaseCollectionBibliothekComponent,
            children: [
                { path: '', redirectTo: 'published', pathMatch: 'full' },
                { path: 'published', component: CaseCollectionPublishedBibliothekComponent, resolve: { biblio: BibliothekResolver } },
                { path: 'unpublished', component: CaseCollectionUnpublishedBibliothekComponent, resolve: { biblio: BibliothekResolver } },
            ],
        },
        {
            path: 'journals', component: JournalBibliothekComponent, resolve: { biblio: BibliothekResolver },
        },
        { path: 'books', component: BookBibliothekComponent, resolve: { biblio: BibliothekResolver } },
        { path: 'commentaries', component: BookBibliothekComponent, resolve: { biblio: BibliothekResolver } },
        { path: 'serials', component: BookBibliothekComponent, resolve: { biblio: BibliothekResolver } },
        { path: 'booksuni', component: BookUniBibliothekComponent, resolve: { biblio: BibliothekResolver } },
        { path: 'eu', component: EuVerzeichnisseComponent, resolve: { biblio: BibliothekResolver } },
        {
            path: '**', component: JournalBibliothekComponent, resolve: {
                biblio: BibliothekResolver,
                notFound: NotFoundResolver,
            },
        },
    ],
};

export const biblioStaticRoutes = [{
    path: ':lang/biblio/:type/:id',
    component: BiblioStaticComponent,
    // resolve: { biblio: BiblioStaticResolver },
}, {
    path: ':lang/test-biblio/:type/:id',
    // data: { lang: 'de' },
    component: BiblioStaticComponent,
    // resolve: { biblio: BiblioStaticResolver },
}];

@NgModule({
    imports: [
        CommonModule,
        AccountModule,
        RouterModule,
        FieldModule,
        AccountModule,
        MatInputModule,
        MatTabsModule,
        MatExpansionModule,
        HomeModule,
        AssetModule,
        EffectsModule.forFeature([BibliothekEffects]),
    ],
    declarations: [
        BibliothekComponent,
        BiblioStaticComponent,
        JournalBibliothekComponent,
        BookBibliothekComponent,
        CaseCollectionBibliothekComponent,
        CaseCollectionPublishedBibliothekComponent,
        CaseCollectionUnpublishedBibliothekComponent,
        EuVerzeichnisseComponent,
        BookUniBibliothekComponent,
    ],
    providers: [
        BibliothekService,
        BibliothekResolver,
        // CanActivateBiblioStatic,
    ],
})
export class BibliothekModule {
    constructor(private account: AccountService) {
        account.addTranslation('biblio-',
            require('./biblio.de.yaml'),
            require('./biblio.fr.yaml')
        );
    }
}
