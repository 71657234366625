module.exports = {
	"rech-asset-share": "Teilen",
	"rech-asset-share-description": "Link zu diesem Dokument kopieren oder per E-Mail versenden",
	"rech-asset-copylink": "Link kopieren",
	"rech-asset-asmail": "Link als E-Mail versenden",
	"rech-asset-document-link": "Dokumentlink von Swisslex",
	"rech-asset-fontsize": "Schrift",
	"rech-asset-fontsize-description": "Schriftgrösse für diese Dokumentansicht anpassen",
	"rech-asset-showtoc": "Inhaltsverzeichnis",
	"rech-asset-showtoc-description": "Inhaltsverzeichnis der Publikation (Zeitschrift/Buch/Kommentar) einblenden",
	"rech-asset-details": "Zitate",
	"rech-asset-details-description": "Dokumente welche dieses Dokument zitieren anzeigen",
	"rech-asset-comment-laws": "Kommentierungen",
	"rech-asset-comment-more-laws": "Dieser Artikel wird ebenfalls kommentiert in",
	"rech-asset-comment-law-description": "Kommentierungen zu diesem Erlass anzeigen",
	"rech-asset-comment-article-description": "Kommentierungen zu diesem Artikel anzeigen",
	"rech-asset-comment-more-laws-description": "Weitere Kommentierungen zu diesem Artikel anzeigen",
	"rech-assets-multiple-articles": "Mehrere Artikel",
	"rech-asset-more-sources": "Weitere Quellen auf legalis.ch",
	"rech-asset-showLowMetaTextMore": "Mehr Dokumentinformationen anzeigen",
	"rech-asset-showLowMetaTextLess": "Weniger Dokumentinformationen anzeigen",
	"rech-asset-back-to-document": "Zurück zum Dokument",
	"rech-asset-getPdf": "PDF",
	"rech-asset-getPdf-description": "Dieses Dokument als PDF herunterladen",
	"rech-asset-favourite": "Favorit",
	"rech-asset-favourite-description": "Dieses Dokument als persönlichen Favoriten speichern",
	"rech-asset-citetdIn": "Dokument wird zitiert in",
	"rech-asset-showAllCitations": "Alle anzeigen",
	"rech-asset-show-less": "Weniger anzeigen",
	"rech-asset-citations": "Dokument zitiert",
	"rech-asset-otherCitations": "Weitere Zitate",
	"rech-asset-overview": "Zur Übersicht",
	"rech-asset-DocIsNotCited": "Wird nicht zitiert",
	"rech-asset-commented-in-article": "Artikel wird kommentiert in",
	"rech-asset-commented-in-law": "Erlass wird kommentiert in",
	"rech-asset-notCommented": "Wird nicht kommentiert",
	"rech-asset-language": "Sprache",
	"rech-asset-language-description": "Sprache des Dokuments auswählen",
	"rech-asset-attachment": "Anhang",
	"rech-asset-attachment-description": "Anhänge dieses Dokuments anzeigen",
	"rech-asset-attachment-title": "Dokument hat folgende Anhänge",
	"rech-asset-attachment-not-found": "Anhang nicht gefunden",
	"rech-asset-Caselaw": "Urteile",
	"rech-asset-CaselawReview": "Urteilsbesprechungen",
	"rech-asset-Essay": "Aufsätze",
	"rech-asset-Commentary": "Kommentare",
	"rech-asset-Book": "Bücher",
	"rech-asset-consolidatedVersion": "Fassungen",
	"rech-asset-versions": "Versionen",
	"rech-asset-versions-description": "Andere Versionen desselben Dokuments anzeigen",
	"rech-asset-corrections": "Korrekturen",
	"rech-asset-original": "Original",
	"rech-asset-abo": "Abonnieren",
	"rech-asset-buy": "Kaufen",
	"rech-asset-document-stash": "Dokumentablage",
	"rech-asset-close": "Schliessen",
	"rech-asset-close-tab": "Tab schliessen",
	"rech-asset-loading": "Laden...",
	"rech-asset-error": "Dokumentanzeige fehlgeschlagen",
	"rech-asset-pdf-error": "Fehler beim Herunterladen des Dokuments",
	"rech-asset-Dokument": "Dokument",
	"rech-asset-Urteilsdatum": "Urteilsdatum",
	"rech-asset-Gericht": "Gericht",
	"rech-asset-SwisslexKommentar": "Betreff",
	"rech-asset-Publikation": "Publikation",
	"rech-asset-Rechtsgebiete": "Rechtsgebiete",
	"rech-asset-Titel": "Titel",
	"rech-asset-SubTitel": "",
	"rech-asset-SerieReihe": "Serie/Reihe",
	"rech-asset-BuchAutoren": "Buchautoren",
	"rech-asset-Jahr": "Jahr",
	"rech-asset-Pages": "Seiten",
	"rech-asset-Page": "Seite",
	"rech-asset-Autoren": "Autoren",
	"rech-asset-Author": "Autor",
	"rech-asset-Autor": "Autor",
	"rech-asset-BuchTitel": "Buchtitel",
	"rech-asset-Herausgeber": "Herausgeber",
	"rech-asset-Redaktion": "Herausgeber / Redaktion",
	"rech-asset-FrühereHerausgeber": "Frühere Herausgeber",
	"rech-asset-ISBN": "ISBN",
	"rech-asset-ISSN": "ISSN",
	"rech-asset-Verlag": "Verlag",
	"rech-asset-KommArtikel": "Artikelkommentar",
	"rech-asset-DocTitle": "Dokumenttitel",
	"rech-asset-KommentarAutoren": "Autoren des Kommentars",
	"rech-asset-Reihe": "Reihe",
	"rech-asset-Auflage": "Auflage",
	"rech-asset-BesprUrteile": "Urteilsbesprechung",
	"rech-asset-DocumentDate": "Datum des Dokuments",
	"rech-asset-PublicationDate": "Publikationsdatum",
	"rech-asset-EnforcementDate": "In Kraft seit",
	"rech-asset-InvalidationDate": "Ausser Kraft seit",
	"rech-asset-Remark": "Anmerkung",
	"rech-asset-Abkurzung": "Abkürzung",
	"rech-asset-SrCode": "SR-Nummer",
	"rech-asset-Date": "Datum",
	"rech-asset-Inkraft": "Inkraft",
	"rech-asset-ArticleNumber": "Artikel",
	"rech-asset-Aktenzeichen": "Aktenzeichen",
	"rech-asset-EcliNummer": "Ecli Nr.",
	"rech-asset-biblioInfo": "Bibliographische Angaben",
	"rech-asset-biblioInfo-description": "Bibliographische Angaben des Dokuments einblenden",
	"rech-asset-StandDatum": "Standdatum",
	"rech-asset-multicitation": "Mehrfachzitat",
	"rech-asset-lawcitation": "Gesetzeszitat",
	"rech-asset-citeFrom": "vom",
	"rech-asset-copy-with-citation": "Auswahl mit Zitierung kopieren",
	"rech-asset-copy-without-citation": "Auswahl kopieren",
	"rech-asset-noContent": "Es konnten keine Inhalte gefunden werden",
	"rech-asset-drop-other": "Zweites Fenster",
	"rech-asset-previews": "Sie haben auf einem Mehrfachzitat geklickt. Sie können jetzt entscheiden welches Dokument Sie anschauen möchten.",
	"rech-asset-preview": "Mehrfachzitat",
	"rech-asset-doctoc-mn": "RZ",
	"rech-asset-doctoc-followingDocuments": "In den folgenden Dokumenten",
	"rech-asset-doctoc-followingDocument": "Im folgenden Dokument",
	"rech-asset-following": "Nächstes",
	"rech-asset-following-description": "Nächstes Dokument aus diesem Werk anzeigen",
	"rech-asset-preceding": "Vorheriges",
	"rech-asset-preceding-description": "Vorheriges Dokument aus diesem Werk anzeigen",
	"rech-asset-doc": "Dokument",
	"rech-asset-doc-preceding": "Vorheriges Dokument",
	"rech-asset-doc-following": "Nächstes Dokument",
	"rech-asset-save-fav-info": "Favorit",
	"rech-asset-save-fav-success": "Dokument als Favorit erfolgreich gespeichert.",
	"rech-asset-save-fav-show-dial": "Diesen Hinweis nicht mehr anzeigen",
	"rech-asset-save-fav-error": "Fehler beim Speichern/Löschen des Dokuments als Favorit.",
	"rech-asset-load-fav-error": "Fehler beim Laden der Favoriten.",
	"rech-asset-details-pdf-yes": "Mit Hervorhebungen (Highlighting)",
	"rech-asset-details-pdf-no": "Ohne Hervorhebungen (Highlighting)",
	"rech-asset-copy-sucess": "Auswahl wurde erfolgreich kopiert",
	"rech-asset-cite-copy-sucess": "Der von Ihnen ausgewählte Textabschnitt inkl. Zitierung wurde erfolgreich kopiert",
	"rech-asset-copy-error": "Auswahl konnte nicht kopiert werden",
	"rech-asset-copy-no-cites": "Zitierungen für diesen Dokumenttyp sind noch nicht implementiert",
	"rech-asset-court-correction": "Gericht Korrigieren",
	"rech-asset-fix-missing-court": "Fehlende Gerichtsinformationen hinzufügen",
	"rech-asset-missing-court": "Gericht nicht in der Datenbank vorhanden",
	"rech-asset-no-access": "Sie verfügen nicht über die notwendigen Rechte, um dieses Dokument zu öffnen",
	"rech-asset-missing-rights": "Fehlende Rechte: ",
	"rech-asset-too-many-assets-title": "Zuviele offene Dokumente",
	"rech-asset-too-many-assets-text": "Sie haben das Maximum an gleichzeitig offenen Dokumenten erreicht. Um ein weiteres Dokument öffnen zu können, schliessen Sie bitte ein Bestehendes. <br>",
	"rech-asset-too-many-assets-link": "Klicken Sie hier, um automatisch das am längsten offene Dokument zu schliessen.",
	"rech-asset-citation-following": "f.",
	"rech-asset-citation-multiple-following": "ff.",
	"rech-asset-link-preview-open-law": "Diesen Erlass anzeigen",
	"rech-asset-link-preview-open-article": "Diesen Gesetzesartikel anzeigen",
	"rech-asset-link-preview-open-commentary-law": "Kommentare zu diesem Erlass",
	"rech-asset-link-preview-open-commentary-article": "Kommentare zu diesem Gesetzesartikel",
	"rech-asset-link-no-comment": "Keine Kommentare gefunden",
	"rech-asset-aol-description": "Art. {{articleNumber}} {{lawAbbr}}",
	"rech-asset-newer-comment": "Für diesen Gesetzesartikel gibt es eine Kommentierung in einer neueren Auflage.",
	"rech-asset-open-newer-comment": "Die aktuelleste Kommentierung anzeigen.",
	"rech-asset-new-edition-toc": "Aktuelleste Auflagen"
};