import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AccountService } from '../../account';
import { LanguageEnum } from '../../models';

import { HomeService } from './../home.service';


@Component({
    selector: 'slx-about',
    templateUrl: './about.component.html',
    host: { 'class': 'sidebar-layout' },
    styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnDestroy {
    public language = this.accountService.langEnum;
    public languageEnum = LanguageEnum;

    public onLangChangeSubscription: Subscription;

    constructor(public accountService: AccountService, public homeService: HomeService) {

        this.onLangChangeSubscription = accountService.onLangChange.subscribe(res => {
            this.language = accountService.langEnum;
        });
    }

    ngOnDestroy(): void {
        this.onLangChangeSubscription.unsubscribe();
    }
}
