import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { getValidationMessage } from '../validators';

@Component({
    selector: 'slx-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FileUploadComponent),
            multi: true,
        },
    ],
})
export class FileUploadComponent implements ControlValueAccessor {
    @Input() placeholder : string;                       // for consistency reasons, gets convertetd to label
    @Input() fileType = 'application/pdf';              // e.g application/pdf or text/plain
    @Output() upload = new EventEmitter();
    @Output() remove = new EventEmitter();

    public filePath = '';
    public fileName = '';
    public formControl: FormControl = null;
    public getValidationMessage: (formControl: any, translate: any) => string = getValidationMessage;
    public showError = false;

    constructor(public translate: TranslateService) { }

    private propagateChange = (_: any) => { };
    private _onTouched = (_: any) => { };
    public writeValue(str: any) {
        this.filePath = str || '';
        // This creates a new refernce, since string literals won´t trigger ChangeDetection
        // tslint:disable-next-line:no-construct
        // this.value = new String('');
    }
    public registerOnChange(fn: any) { this.propagateChange = fn; }
    public registerOnTouched(fn: any): void { this._onTouched = fn; }

    public validate(c: FormControl) {
        this.formControl = c;
        if (c.value === null) {
            this.fileName = '';
        }
        return {};
    }

    public onChange(event) {
        const file = event.target.files[0];
        if (file) {
            this.fileName = file.name;
            this.propagateChange(file);
        }
        this.upload.emit(true);
    }

    public onRemoveClick(options?: { onlySelf?: boolean; emitEvent?: boolean}) {
        this.formControl.reset(undefined, options);
        this.filePath = '';
        this.fileName = '';
        this.remove.emit(true);
    }
}


