import { Component, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';

import { AccountService } from '../../../account';
import { AppActions } from '../../../appActions';

@Pipe({ name: 'hasPrecedingTip' })
export class HasPrecedingTipPipe implements PipeTransform {
    transform(tip, index: number): boolean {
        return index > 0;
    }
}

@Pipe({ name: 'hasfollowingTip' })
export class HasFollowingTipPipe implements PipeTransform {
    transform(tip, index: number, tipLength: number): boolean {
        return index < tipLength - 1;
    }
}


@Component({
    selector: 'slx-tip-of-the-day-tile',
    templateUrl: './tipOfTheDayTile.component.html',
    styleUrls: ['./tipOfTheDayTile.component.scss'],
})
export class TipOfTheDayTileComponent implements OnInit, OnDestroy {
    private tipsSub: Subscription;
    public tips;
    public currentTip;
    public index = 0;

    constructor(public accountService: AccountService) { }
    ngOnInit(): void {
        this.accountService.dispatch({ fetch: AppActions.load_tips.name });
        this.tipsSub = this.accountService.getTips.subscribe(tips => {
            if (tips && tips.length > 0) {
                this.tips = tips;
                this.currentTip = tips[this.index];
            }
        });
    }

    openPrecedingTip() {
        this.index--;
        this.currentTip = this.tips[this.index];
    }

    openFollowingTip() {
        this.index++;
        this.currentTip = this.tips[this.index];
    }

    ngOnDestroy(): void {
        this.tipsSub.unsubscribe();
    }

}
