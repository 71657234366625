
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { LoginService } from './login.service';

@Injectable()
export class AuthGuard implements CanActivate, OnDestroy {

    private loggedInSubscription;
    private loggedIn = false;

    constructor(public loginService: LoginService, public router: Router){
        this.loggedInSubscription = this.loginService.loggedIn.subscribe(loggedIn => this.loggedIn = loggedIn);
    }

    ngOnDestroy() {
        this.loggedInSubscription.unsubscribe();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.loggedIn;
    }
}
