
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable,  throwError as observableThrowError } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';

const debug = require('debug')('access');

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        debug('intercepted', req);
        return next.handle(req).pipe(
            timeout(10 * 1000),
            tap(event => {}, err => {
                if (err instanceof HttpErrorResponse) {
                    observableThrowError(err);
                }
            }
        ));
    }
}
