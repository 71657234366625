export interface HomeState {
    mainTab: string;
    activeMobileSidebarComponent: string;
    userProfileData: any[];
    tabUrl: any;
    effectiveMainURL: string;
    eventsForHomeTiles: {
        startPageEvents: any,
        moreEvents: any,
    };
    topDocuments: any[];
    homeTileNews: any[];
    jobsForHomeTiles: {
        topJobs: any[],
        currentJobs: any[],
    };
    faq: any;
}

export const defaultHomeState = {
    mainTab: 'home',
    tabUrl: {
        rech: '/recherche/search/new',
        news: '/news',
        biblio: '/biblio',
        jobs: '/jobs',
        events: '/events',
        account: '/customerprofile',
    },
    effectiveMainURL: '',
    eventsForHomeTiles: {},
    topDocuments: [],
    homeTileNews: [],
    jobsForHomeTiles: {
        topJobs: [],
        currentJobs: [],
    },
    faq: {},
};
