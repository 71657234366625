import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AccountService } from '../../../../account';
import {
    combinedQualityValidator, emailValidator, equalsOtherValidator, fileSizeValidator,
    fileTypeValidator, minLengthValidator, phoneFaxNumberValidator, requiredValidator, uppercaseValidator, zipCodeValidator
} from '../../../../field';
import { RakFormElements, SelectListItem } from '../../../../models';
import { markFormAsTouched } from '../../../../utility/utilityFunctions';
import { AboRegistrationService } from '../../abo-services.service';
import { AboServicesActions } from '../../abo-servicesActions';

@Component({
    selector: 'slx-add-new-rak-user',
    templateUrl: './add-new-rak-user.component.html',
    styleUrls: ['./add-new-rak-user.component.scss'],
})
export class AddNewRakUserComponent implements OnInit, OnDestroy, RakFormElements {
    addressTerms: SelectListItem[];
    addressTitles: SelectListItem[];
    language: any;
    languages: SelectListItem[];
    rakRuntimes: SelectListItem[];
    public rakSubscriptionForm: FormGroup;
    public rakRegistrationSubscription: Subscription;

    constructor(private fb: FormBuilder, private accountService: AccountService, public aboRegistrationService: AboRegistrationService) { }
    ngOnInit() {
        this.rakSubscriptionForm = this.fb.group({
            street: ['', requiredValidator()],
            zipCode: ['', [
                requiredValidator(),
                zipCodeValidator('service-reg-comp-zip-notvalid-val-txt')]],
            city: ['', requiredValidator()],
            phone: ['', [
                requiredValidator(),
                phoneFaxNumberValidator('service-reg-comp-phone-notvalid-val-txt')]],
            addressTerm: [''],
            rakRuntime: [''],
            title: [''],
            firstName: ['', requiredValidator()],
            name: ['', requiredValidator()],
            email: ['', [
                requiredValidator(),
                emailValidator('service-reg-userid-notvalid-val-txt')]],
            language: [''],
            password: ['', [
                requiredValidator(),
                combinedQualityValidator(
                    minLengthValidator('service-reg-pwd-min', 6),
                    uppercaseValidator('service-reg-pwd-uppercase', 1)),
            ]],
            confirmPassword: ['', [
                requiredValidator(),
                equalsOtherValidator('service-reg-pwd-equal', 'password')]],
            LanguageEnum: [''],
            hasCorporateNewsletter: [''],
            fileUpload: ['', [
                fileSizeValidator('service-reg-rak-upload-notvalid-size-val-txt', 20000000),
                fileTypeValidator('service-reg-rak-upload-notvalid-typ-val-txt', 'application/pdf')]],
        }
        );

        this.rakRegistrationSubscription = this.aboRegistrationService.registerRakFormElements.subscribe((data: RakFormElements) => {
            if (data) {
                Object.assign(this, data);
                this.setDefaultFormValues();
            }
        });
    }

    onSubmit(rakSubscriptionForm) {

        if (!rakSubscriptionForm.valid) {
            markFormAsTouched(rakSubscriptionForm);
            return;
        }

        const formData: FormData = new FormData();

        if (rakSubscriptionForm.value.fileUpload !== null) {
            formData.append('File', rakSubscriptionForm.value.fileUpload);
        }

        const rakCustomer = {
            rakRuntime: rakSubscriptionForm.value.rakRuntime,
            portalUser: {
                addressTerm: rakSubscriptionForm.value.addressTerm,
                title: rakSubscriptionForm.value.title,
                firstName: rakSubscriptionForm.value.firstName,
                name: rakSubscriptionForm.value.name,
                language: rakSubscriptionForm.value.language,
                email: rakSubscriptionForm.value.email,
                loginName: rakSubscriptionForm.value.email,
                password: rakSubscriptionForm.value.password,
                hasCorporateNewsletter: rakSubscriptionForm.value.hasCorporateNewsletter,
                isActivated: false,
                company: {
                    street: rakSubscriptionForm.value.street,
                    zipCode: rakSubscriptionForm.value.zipCode,
                    city: rakSubscriptionForm.value.city,
                    phone: rakSubscriptionForm.value.phone,
                },
            },
        };
        formData.append('RakSubscription', JSON.stringify(rakCustomer));
        this.aboRegistrationService.dispatch({ save: AboServicesActions.save_rak_user_profile_data.name, payload: formData });
    }

    private setDefaultFormValues(): void {
        if (this.accountService.lang === 'de') {
            this.rakSubscriptionForm.controls['addressTerm'].setValue('Herr');
            this.rakSubscriptionForm.controls['language'].setValue('DE');
        } else {
            this.rakSubscriptionForm.controls['addressTerm'].setValue('Monsieur');
            this.rakSubscriptionForm.controls['language'].setValue('FR');
        }
        this.rakSubscriptionForm.controls['title'].setValue('-');
        this.rakSubscriptionForm.controls['hasCorporateNewsletter'].setValue(true);
        this.rakSubscriptionForm.controls['rakRuntime'].setValue('1');
    }

    public resetForm(): void {
        this.rakSubscriptionForm.reset();
        Object.keys(this.rakSubscriptionForm.controls).forEach(
            (key) => {
                this.rakSubscriptionForm.controls[key].setErrors(null);
                this.rakSubscriptionForm.controls[key].updateValueAndValidity();
            });

        this.setDefaultFormValues();
    }

    ngOnDestroy(): void {
        this.rakRegistrationSubscription.unsubscribe();
    }
}
