import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule, MatRadioModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { AgGridModule } from 'ag-grid-angular/main';

import { NotFoundResolver } from './../access';
import { AccountService, TranslateModule } from './../account';
import { FieldModule, GridSwitchComponent } from './../field';
import { HomeModule } from './../home';
import { ChangePasswordComponent } from './changePassword.component';
import { ChangePasswordFromProfileComponent } from './changePasswordFromProfile.component';
import { CustomerComponent } from './customer/customer.component';
import { CustomerProfileComponent } from './customerProfile.component';
import { CustomerProfileResolver } from './customerProfile.resolver';
import { CustomerProfileEffects } from './customerProfileEffects';
import { AddNewDepartmentComponent } from './departments/addNewDepartment/addNewDepartment.component';
import { AllDepartmentsComponent } from './departments/allDepartments/allDepartments.component';
import { DepartmentsComponent } from './departments/departments.component';
import { SingleDepartmentComponent } from './departments/singleDepartment/singleDepartment.component';
import { ForgotPasswordComponent } from './forgotPassword.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { ProfileComponent } from './profile/profile.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { AddNewUserComponent } from './users/addNewUser/addNewUser.component';
import { AllUsersComponent } from './users/allUsers/allUsers.component';
import { SingleUserComponent } from './users/singleUser/singleUser.component';
import { UsersComponent } from './users/users.component';

export const accountRoute = {
    path: 'account',
    children: [
        { path: 'forgotpassword', component: ForgotPasswordComponent },
        { path: 'password/:lang', component: ChangePasswordComponent },
    ],
};

export const customerprofileRoute = {
    path: 'customerprofile',
    component: CustomerProfileComponent,
    children: [
        { path: '', redirectTo: 'userprofile', pathMatch: 'full' },
        {
            path: 'userprofile',
            component: ProfileComponent,
            resolve: {
                customerProfile: CustomerProfileResolver,
            },
        },
        {
            path: 'preferences',
            component: PreferencesComponent,
            resolve: {
                customerProfile: CustomerProfileResolver,
            },
        },
        {
            path: 'customer',
            component: CustomerComponent,
            resolve: {
                customerProfile: CustomerProfileResolver,
            },
        },
        {
            path: 'statistics',
            component: StatisticsComponent,
            resolve: {
                customerProfile: CustomerProfileResolver,
            },
        },
        {
            path: 'departments',
            component: DepartmentsComponent,
            resolve: {
                customerProfile: CustomerProfileResolver,
            },
            children: [
                { path: '', redirectTo: 'alldepartments', pathMatch: 'full' },
                {
                    path: 'addnewdepartment',
                    component: AddNewDepartmentComponent,
                },
                {
                    path: 'singledepartment/:id',
                    component: SingleDepartmentComponent,
                },
                {
                    path: 'alldepartments',
                    component: AllDepartmentsComponent,
                    resolve: {
                        customerProfile: CustomerProfileResolver,
                    },
                },
            ],
        },
        {
            path: 'users',
            component: UsersComponent,
            resolve: {
                customerProfile: CustomerProfileResolver,
            },
            children: [
                { path: '', redirectTo: 'allusers', pathMatch: 'full' },
                {
                    path: 'singleuser/:id',
                    component: SingleUserComponent,
                },
                {
                    path: 'addnewuser/:id',
                    component: AddNewUserComponent,
                },
                {
                    path: 'addnewuser',
                    component: AddNewUserComponent,
                },
                {
                    path: 'allusers',
                    component: AllUsersComponent,
                    resolve: {
                        customerProfile: CustomerProfileResolver,
                    },
                },
            ],
        },
        {
            path: '**',
            component: ProfileComponent,
            resolve: {
                customerProfile: CustomerProfileResolver,
                notFound: NotFoundResolver,
            },
        },
    ],
};

@NgModule({
    imports: [
        CommonModule,
        FieldModule,
        MatProgressBarModule,
        MatRadioModule,
        ReactiveFormsModule,
        RouterModule,
        HomeModule,
        AgGridModule.withComponents([GridSwitchComponent]),
        EffectsModule.forFeature([CustomerProfileEffects]),
        TranslateModule.forChild(),
    ],
    declarations: [
        ProfileComponent,
        PreferencesComponent,
        CustomerProfileComponent,
        CustomerComponent,
        DepartmentsComponent,
        AllDepartmentsComponent,
        SingleDepartmentComponent,
        AllUsersComponent,
        SingleUserComponent,
        AddNewUserComponent,
        AddNewDepartmentComponent,
        UsersComponent,
        StatisticsComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent,
        ChangePasswordFromProfileComponent,
    ],
    entryComponents:[
        ChangePasswordFromProfileComponent,
    ],
    providers: [
        CustomerProfileResolver,
    ],
})
export class CustomerProfileModule {
    constructor(private account: AccountService) {
        this.account.addTranslation('customerprofile-',
            require('./customerprofile.de.yaml'),
            require('./customerprofile.fr.yaml')
        );
    }
}

