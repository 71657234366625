import { Directive, ElementRef, HostListener, Input, OnChanges, Renderer2 } from '@angular/core';

import { getAssetURL } from '../config';
import { AssetRef, RefType } from '../models';

@Directive({
    selector: '[slx-link]',
})
export class LinkDirective implements OnChanges {
    @Input() ref: AssetRef;
    @Input() currentLang: string;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    }

    ngOnChanges(): void {
        if (this.ref) {
            const prefix = this.currentLang ? `/${this.currentLang}` : '';
            const url = RefType.isHitlist(this.ref[0] as RefType) ? `${prefix}/recherche/${this.ref[0]}/${this.ref[1]}` : getAssetURL(this.ref, this.currentLang);
            this.renderer.setAttribute(this.elementRef.nativeElement, 'href', url);
        }
    }

    @HostListener('click', ['$event']) onClick(event) {
       if (!event.ctrlKey) {
           event.preventDefault();
       }
    }
}
