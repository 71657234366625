import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import * as config from '../../config';
import { SearchHistoryEntry, SourceDetail, TooltipData } from '../../models';
import { toDate } from '../../utility/date-format';
import { SearchService } from '../search.service';

@Component({
    selector: 'slx-search-history',
    templateUrl: './search-history.component.html',
    styleUrls: ['./search-history.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchHistoryComponent {

    recentHours = config.historyRecentHours;

    constructor(public searchService: SearchService, public translate: TranslateService) {
        this.tooltipDataCreator = this.tooltipDataCreator.bind(this);
    }

    public recentTransactions = this.searchService.recentTransactions;
    public olderTransactions = this.searchService.olderTransactions;

    public source = SourceDetail.History;

    public tooltipDataCreator(entry: SearchHistoryEntry): Observable<TooltipData> {
        return of(entry).pipe(map(entry => {

            const tooltipTitle = `<span>${this.translate.instant(entry.tooltipTitle)}</span><span>${format(toDate(entry.createdAt), 'DD.MM.YYYY HH:mm:ss')}</span>`;

            if (entry.tooltipBody) {
                return { tooltipBody: entry.tooltipBody, tooltipTitle };
            }

            const bodyParts = entry.tooltipBodyParts ?
                entry.tooltipBodyParts.map(bp => ({
                    ...bp,
                    firstValueTranslated: bp.firstValueTranslate ? this.translate.instant(bp.firstValueTranslate) : null,
                    secondValuesTranslated: bp.secondValueTranslates && bp.secondValueTranslates.length > 0 ? bp.secondValueTranslates.map(st => this.translate.instant(st)) : [],
                }))
                : null;

            const tooltipBody = bodyParts ?
                bodyParts.map(
                    part => `<strong>${(part.firstValueTranslated)}${part.seperator}</strong>&nbsp;${part.secondValue}${part.secondValuesTranslated.join(',')}`
                ).join('<br/>')
                : 'error no body found';

            return { tooltipBody, tooltipTitle };
        }));
    }

    // private translateEnties(entries: Array<SearchHistoryEntry>): Array<SearchHistoryEntry> {

    //     return entries.map(entry => {
    //         const e = { ...entry };
    //         if (e.tooltipTitle === 'rech-tooltip-title-EuFindLegalAct') {
    //             const texts = e.text.split(';');
    //             if (texts[0].search('-') === -1 && texts[0].search('Legislation') !== -1) {
    //                 texts[0] = this.translate.instant('rech-legislation-' + texts[0].trim());
    //                 const bodys = e.tooltipBody.split(';');
    //                 bodys[0] = this.translate.instant('rech-legislation-' + bodys[0].trim());
    //                 return { ...e, text: texts.join(';'), tooltipBody: bodys.join(';') };
    //             }
    //         }

    //         e.tooltipTitle = `<span>${this.translate.instant(e.tooltipTitle)}</span><span>${format(toDate(e.createdAt), 'DD.MM.YYYY HH:mm:ss')}</span>`;

    //         if (e.tooltipBodyParts) {
    //             return {
    //                 ...e, tooltipBodyParts: e.tooltipBodyParts.map(bp => ({
    //                     ...bp,
    //                     firstValueTranslated: bp.firstValueTranslate ? this.translate.instant(bp.firstValueTranslate) : null,
    //                     secondValuesTranslated: bp.secondValueTranslates && bp.secondValueTranslates.length > 0 ? bp.secondValueTranslates.map(st => this.translate.instant(st)) : [],
    //                 })),
    //             };
    //         }

    //         return e;

    //     });
    // }


    // public tooltipBodyCreator(item: SearchHistoryEntry): string {

    //     if (item.tooltipBody) {
    //         return item.tooltipBody;
    //     }

    //     let tooltipBody = '';
    //     if (!item.tooltipBodyParts) {
    //         return '';
    //     }
    //     item.tooltipBodyParts.forEach(bodyPart => {
    //         tooltipBody += `<strong>${(bodyPart.firstValueTranslated)}${bodyPart.seperator}</strong>&nbsp;${bodyPart.secondValue}${bodyPart.secondValuesTranslated.join(',')}<br/>`;
    //     });
    //     return tooltipBody;
    // }
}
