
import { AfterViewInit, Component, ElementRef, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { FieldService } from '../field.service';

const debug = require('debug')('tooltip');
@Component({
    selector: 'slx-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements AfterViewInit, OnDestroy {

    private subscription: Subscription;
    public current: any = null;
    private timer = null;
    private listener = null;

    @ViewChild('tooltip') tooltipElement: ElementRef;

    constructor(public fieldService: FieldService, private renderer: Renderer2) {

        this.subscription = fieldService.tooltipInformation.subscribe(
            result => {
                debug('Component: Tooltip info changed', !!result, !!this.current);
                // not there and being shown
                if (!this.current && result !== null) {
                    this.current = result;
                    debug('Component: Showing tooltip', result.title);
                    // updating tooltip or hiding tooltip
                } else {
                    this.current = result;
                }

                if (this.current) {
                     //Promise.resolve(null).then(() => {
                    setTimeout(() => {
                        if (!this.listener) {
                            if(this.tooltipElement){
                                debug('Component: Add listener', this.tooltipElement.nativeElement);
                                this.listener = this.renderer.listen(this.tooltipElement.nativeElement, 'mouseleave', () => {
                                    this.current = null;
                                    this.removeListener();
                                });
                            }
                        }
                    });
                }
                else {
                    this.removeListener();
                }
            });
    }

    private removeListener(): void {
        if (this.listener) {
            debug('Component: Remove listener');
            this.listener();
            this.listener = null;
        }
    }

    ngAfterViewInit(): void {

    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
