
import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterEvent, UrlSegmentGroup, UrlTree } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { SearchService } from '../../recherche';

import { RouterExtService } from './../../routerext.service';
import { HomeService } from './../home.service';
import { HomeActions } from './../homeActions';

@Component({
    selector: 'slx-subheader',
    templateUrl: './subheader.component.html',
    styleUrls: ['./subheader.component.scss'],
})
export class SubHeaderComponent implements OnDestroy {

    private subscription: Subscription = new Subscription();
    mainArea: string;
    subArea: Observable<string>;
    subSubArea: Observable<string>;
    public sidebarToggled = false;

    constructor(private homeService: HomeService, private searchService: SearchService, private router: Router) {
        this.clicker = this.clicker.bind(this);

        // TODO: move this to routerext.service by implementing a destroy function there to allow unsubscribe
        this.subscription
            .add(
                this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)).subscribe((event: RouterEvent) => {

                    this.homeService.dispatch({ type: HomeActions.toggle_mobile_sidebar.name, payload: null });

                    this.mainArea = null;
                    this.subArea = null;
                    this.subSubArea = null;

                    if (router.url === '/') {
                        this.mainArea = 'home';
                        return;
                    }

                    if (router.url.includes('doc') || router.url.includes('recherche') || router.url.includes('toc')) {
                        this.mainArea = 'recherche';
                        this.subArea = this.searchService.activeRechercheTab.pipe(map(res => `rech-${res}`));
                        return;
                    }


                    const tree: UrlTree = router.parseUrl(router.url);
                    const group: UrlSegmentGroup = tree.root.children.primary;

                    if (group) {
                        this.mainArea = group.segments[0].path;
                        this.subArea = of(group.segments[1] ? group.segments[0].path + '-' + group.segments[1].path : null);

                        if (router.url.includes('biblio')) {
                            this.subSubArea = of(group.segments[1] && group.segments[2] ? group.segments[0].path + '-' + group.segments[1].path + '-' + group.segments[2].path : null);
                        }
                    }
                })
            );
    }

    public toggleMobileSidebar(event) {
        event.stopPropagation();
        this.sidebarToggled = !this.sidebarToggled;
        this.homeService.dispatch({ type: HomeActions.toggle_mobile_sidebar.name, payload: this.sidebarToggled ? this.mainArea : '' });

        if (this.sidebarToggled) {
            document.addEventListener('click', this.clicker);
        }
    }

    private clicker(event) {
        const aside: HTMLElement = event.target.closest('aside');
        if (aside && aside.contains(event.target) && !(event.target.localName === 'li' || event.target.localName === 'a')) {
            return;
        }

        this.sidebarToggled = false;
        document.removeEventListener('click', this.clicker);
        this.homeService.dispatch({ type: HomeActions.toggle_mobile_sidebar.name, payload: '' });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}

