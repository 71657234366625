import { getAssetUrlNoSource, historyRecentHours } from '../config';
import {
    areaForType, decorateHitlist,
    // tslint:disable-next-line:ordered-imports
    defaultPublicationCriteria, defaultRechercheState, DrilldownType, enhanceActionsObject, Hitlist, HitlistPaging, HitlistType, NewsHitlist, RechercheState, Search, SearchHistoryEntry, SearchType, TransactionType, LemmaType
} from '../models';
import { toDate } from '../utility/date-format';

export const SearchActions = {
    get_practice_areas_for_filter(state: RechercheState, { payload, result }) {
        return result ? {
            ...state,
            practiceAreaFilters: payload,
        } : state;
    },
    verify_practice_areas_for_filter(state: RechercheState,{ payload }){
        return state;
    },
    get_document_categories_for_filter(state: RechercheState, { payload, result }) {
        return result ? {
            ...state,
            documentCategoryFilters: payload,
        } : state;
    },

    get_legal_act_categories_for_filter(state: RechercheState, { payload, result }) {
        return result ? {
            ...state,
            legalActCategoryFilters: payload,
        } : state;
    },

    verify_publication_filter_options(state: RechercheState, { payload, result }) {
        return state;
    },

    get_publication_filter_options(state: RechercheState, { payload, result }) {
        return result ? {
            ...state,
            publicationFilterContent: payload,
        } : state;
    },

    create_search_abo(state: RechercheState, { payload }) {
        return state;
    },

    search_fields_loaded(state: RechercheState, action) {
        return loadSearchFields(state, action);
    },

    search_fields_loaded_with_submit(state: RechercheState, action) {
        const loadedState = loadSearchFields(state, action);

        return {
            ...loadedState,
            searchInProgress: true,
            // ...cleanHitlist(),
        };
    },

    search_fields_loaded_with_news(state: RechercheState, action) {
        const loadedState = loadSearchFields(state, action);

        return {
            ...loadedState,
            searchInProgress: true,
            // ...cleanHitlist(),
        };
    },

    rerun_search_transaction(state: RechercheState, { payload }) {
        return state;
    },

    get_transaction_info(state: RechercheState, { payload }) {
        return state;
    },

    search_fields(state: RechercheState, { payload }: { payload: Search }) {
        // SENTRY TEST => generate error
        if(payload.parameters && payload.parameters.searchText === 'sentryerrortest'){
            throw new Error('this is a sample Sentry error');
        }
        return {
            ...state,
            searchForms: {
                ...state.searchForms,
                [SearchType[payload.type]]: { ...cleanSearchFieldsPayload(payload, state) },
            },
        };
    },
    clear_search_fields(state: RechercheState, { payload }: { payload: SearchType }) {
        let result: any = {
            ...state,
            searchForms: {
                ...state.searchForms,
                [SearchType[payload]]: {},
            },
        };
        // retrieval search needs those additional fields stored
        // separately from the searchform so we need to reset those as well
        if (payload === SearchType.Retrieval) {
            result = {
                ...result,
                selectedSearchFilter: null,
                selectedPublications: {
                    ...applySelectedPublications(null),
                },
            };
        }
        return result;
    },

    load_newsletter(state: RechercheState, { payload }) {
        if (state.hitlist.id === payload.id) {
            return state;
        }
        return {
            ...state,
            searchCollapsed: false,
            searchInProgress: true,
        };
    },

    search_fields_submit(state: RechercheState, { payload } /*: { payload: Search }*/) {
        const drillDownFilter = { sortOrder: 0 };

        // SENTRY TEST => generate error
        // payload.quatsch.length();

        const search = {
            ...payload,
            parameters: { ...cleanSearchFieldsPayload(payload, state) },
        };
        let result = {
            ...state,
            paging: { ... state.paging, currentPage: 1 },
            searchInProgress: true,
            transactionID: null,
            transactionType: TransactionType.Search,
            drillDownFilter,
            search: search,
        };

        if(payload.forceFormSearchTextFromSearch){
            result = {
                ...result,
                searchForms: {
                    ...state.searchForms,
                    [SearchType[SearchType.Retrieval]]: {
                        ...state.searchForms[SearchType[SearchType.Retrieval]],
                        searchText: payload.parameters.searchText,
                    },
                },
            };
        }
        return result;
    },

    search_has_no_results(state: RechercheState, { payload }) {
        return {
            ...state,
            searchInProgress: false,
            hitlist: defaultRechercheState.hitlist,
            // only keep the sidebar (hitlist sic!) open if it is currently open and not showing the hitlist (from a previous search)
            hitlistOpen: state.hitlistOpen && (state.collectionTab !== 'hitlist'),
            collectionTab: state.collectionTab === 'hitlist' ? null : state.collectionTab,
        };
    },

    search_result(state: RechercheState, { payload }) {
        const transactionType = TransactionType.Search;
        const { hits, numberOfHits, numberOfHitsWithPremium, assetTypeFacet, languageFacet, transactionID, type, id, issue, year, hitlistSize } = payload;
        const { paging } = state;

        const currentDrillDownFilter =  payload.appliedDrillDownFilter ? payload.appliedDrillDownFilter : state.drillDownFilter;

        return {
            ...state,
            paging: new HitlistPaging(numberOfHits, paging.currentPage, hitlistSize),
            hitlist: { ...decorateHitlist({ hits, numberOfHits, numberOfHitsWithPremium, type }), issue, year, id },
            effectiveDrillDownFilter: currentDrillDownFilter,
            drillDownFilter: currentDrillDownFilter,
            searchInProgress: false,
            drilldownfilterLoading: DrilldownType.undefined,
            searchCollapsed: true,
            assetTypeFacet,
            languageFacet,
            transactionType,
            transactionID,
            hitlistOpen: true,
            filterOpen: true,
            previousFilterOpen: true,
            collectionTab: 'hitlist',
            error: null,
        };
    },

    search_error(state: RechercheState, { payload }) {
        const error = payload;
        return { ...state, error, searchInProgress: null };
    },

    // internal_error(state: RechercheState, { payload }) {
    //     return { ...state, error: payload };
    // },

    collapse_search(state: RechercheState, { payload }) {
        return {
            ...state,
            searchCollapsed: true,
        };
    },

    expand_search(state: RechercheState, { payload }) {
        return {
            ...state,
            searchCollapsed: false,
        };
    },

    recent_transactions(state: RechercheState, { payload } : { payload: SearchHistoryEntry[] }) {
        if (payload.length === 0) {
            // no new distnc transaction
            return state;
        }
        const newTransactions = [ ...payload, ...state.recentTransactions, ...state.olderTransactions];
        const separatedTransactions = separateTransactionInOldAndRecent(newTransactions);
        return {
            ...state,
            recentTransactions: separatedTransactions.recentTransactions,
            olderTransactions: separatedTransactions.olderTransactions,
        };
    },

    recent_eu_transactions(state: RechercheState, { payload } : { payload: SearchHistoryEntry[] }) {
        if (payload.length === 0) {
            // no new distnc transaction
            return state;
        }
        const newTransactions = [ ...payload, ...state.recentEuTransactions, ...state.olderEuTransactions];
        const separatedTransactions = separateTransactionInOldAndRecent(newTransactions);
        return {
            ...state,
            recentEuTransactions: separatedTransactions.recentTransactions,
            olderEuTransactions: separatedTransactions.olderTransactions,
        };
    },


    toggle_hitlist_open(state: RechercheState, { type, payload }) {
        return {
            ...state,
            hitlistOpen: !state.hitlistOpen,
        };
    },

    toggle_filter_open(state: RechercheState, { type, payload }) {
        return {
            ...state,
            filterOpen: !state.filterOpen,
        };
    },

    resize_sidebar(state: RechercheState, { payload }) {
        return {
            ...state,
            sidebarSize: { ...state.sidebarSize, ...payload},
        };
    },

    set_vertical_hitlist(state: RechercheState, { payload }) {
        return {
            ...state,
            verticalHitlist: payload,
        };
    },

    hitlist_page_submit(state: RechercheState, { payload }) {
        const currentPage = payload.currentPage ? payload.currentPage : state.paging.currentPage;
        const hitlistSize = payload.hitlistSize ? payload.hitlistSize : state.paging.hitsPerPage;

        if (currentPage < 1 || currentPage > state.paging.totalPages) { return state; }

        return {
            ...state,
            search: {
                ...state.search,
                parameters: {
                    ...state.search.parameters,
                    sourceDetails : `paging-${currentPage}`,
                },
            },
            searchInProgress: { page: currentPage, newHitlistSize: payload.hitlistSize },
            paging: new HitlistPaging(state.hitlist.numberOfHits, currentPage, hitlistSize),
        };
    },

    hitlist_drilldown_submit(state: RechercheState, { type, payload }) {
        const drillDownFilter = payload ? { ...state.drillDownFilter, ...payload } : {};

        if (drillDownFilter.languageValue === null) {
            delete drillDownFilter.languageValue;
        }
        if (drillDownFilter.assetTypeGroupValue === null) {
            delete drillDownFilter.assetTypeGroupValue;
        }
        const searchInProgress = { ...drillDownFilter };
        const drilldownfilterLoading = drillDownFilter.drilldownType;
        // Possible change for only having one filter spinner; state.drillDownFilter vs drillDownFilter -> searchInProgress
        return {
            ...state,
            searchInProgress,
            drilldownfilterLoading,
            paging: new HitlistPaging(state.hitlist.numberOfHits, 1, state.paging.hitsPerPage),
            drillDownFilter,
        };
    },

    save_hitlist_pdf(state: RechercheState) {
        return state;
    },

    change_visibility_advanced_search(state: RechercheState) {
        return {
            ...state,
            advancedSearch: !state.advancedSearch,
        };
    },

    // ##### Search Filters ##### //

    user_search_filters(state: RechercheState, { payload }) {
        return {
            ...state,
            userSearchFilters: payload,
        };
    },

    apply_user_search_filter(state: RechercheState, { payload, result }) {
        if (result) {
            return {
                ...state,
                searchForms: {
                    ...state.searchForms,
                    [SearchType[SearchType.Retrieval]]: {
                        ...applySearchFilter(state.searchForms[SearchType[SearchType.Retrieval]], payload),
                        userSearchFilterId: state.selectedSearchFilter.id,
                    },
                },
                selectedPublications: {
                    ...applySelectedPublications(payload),
                },
            };
        }
        return {
            ...state,
            selectedSearchFilter: { id: payload.id },
        };
    },
    reset_user_search_filter(state: RechercheState, { payload }) {
        return {
            ...state,
            selectedSearchFilter: null,
            searchForms: {
                ...state.searchForms,
                [SearchType[SearchType.Retrieval]]: {
                    ...(applySearchFilter(state.searchForms[SearchType[SearchType.Retrieval]], null)),
                },
            },
            selectedPublications: {
                ...applySelectedPublications(null),
            },
        };
    },

    update_order_user_search_filter(state: RechercheState, { payload, result }) {
        return result ? state : { ...state, userSearchFilters: payload };
    },

    update_title_user_search_filter(state: RechercheState, { payload, result }) {
        return result ? state : {
            ...state, userSearchFilters: state.userSearchFilters.map(entry => {
                if (entry.id === payload.id) { return payload; }
                return entry;
            }),
        };
    },

    delete_user_search_filter(state: RechercheState, { payload, result }) {
        if (result) {
            //make a hardcopy to prevent store changes at this point
            const searchFilterCopy = state.userSearchFilters.slice();
            const index = searchFilterCopy.findIndex(filter => filter.id === payload);
            searchFilterCopy.splice(index, 1);
            return {
                ...state,
                userSearchFilters: searchFilterCopy,
            };
        }
        return { ...state, payload };
    },

    undelete_user_search_filter(state: RechercheState, { payload, result }) {
        return result ? {
            ...state,
            userSearchFilters: payload,
        } : state;
    },

    save_user_search_filter(state: RechercheState, { payload }) {
        return { ...state, payload };
    },

    overwrite_user_search_filter(state: RechercheState, { payload }) {
        return { ...state, payload };
    },

    set_rech_tab(state, { payload }) {
        // TODO remove alert id=searchHasNoResults
        return {
            ...state,
            rechTab: payload,
            searchHasNoResults: false,
        };
    },

    restore_recherche(state, { payload }) {
        const { area, rechTab } = payload;

        return {
            ...state,
            area,
            rechTab,
        };
    },

    init_search(state: RechercheState, { payload }) {
        return {
            ...state,
            search: {
                ...state.search,
                ...payload,
            },
        };
    },

    no_transaction_info(state: RechercheState) {
        return {
            ...state,
            transactionID: 0,
            hitlist: <Hitlist>{ numberOfHits: 0, numberOfHitsWithPremium: 0, hits: [] },
        };
    },

    get_news_config_by_id(state: RechercheState, { payload, result }){
        if (result) {
            return {
                ...state,
                hitlist: <NewsHitlist>{
                    ...state.hitlist,
                    configuration: payload,
                },
            };
        }
        return {
            ...state,
        };
    },

};
enhanceActionsObject(SearchActions);

function applySearchFilter(retrievalSearchForm: any, payload: any) {
    const result = {
        ...retrievalSearchForm,
        dateFrom: payload ? payload.dateFrom : null,
        dateUntil: payload ? payload.dateUntil : null,
        reference: payload ? payload.reference : null,
        author: payload ? payload.author : null,
        practiceAreaGroupsCriteria: payload ? payload.practiceAreaGroupsCriteria : [],
        assetTypeGroupsCriteria: payload ? payload.assetTypeGroupsCriteria : [],
        userSearchFilterId: null,
    };
    return { ...result };
}

function separateTransactionInOldAndRecent(transactions: SearchHistoryEntry[]) : { recentTransactions : SearchHistoryEntry[], olderTransactions: SearchHistoryEntry[] } {
    const dateRecent = new Date();
    dateRecent.setHours(dateRecent.getHours() - historyRecentHours);

    const recentTransactions = [];
    const olderTransactions = [];

    let oldTransactionsFound = false;
    transactions.forEach(t => {
        if (!oldTransactionsFound && toDate(t.createdAt) <= dateRecent) {
            oldTransactionsFound = true;
        }

        if (oldTransactionsFound) {
            olderTransactions.push(t);
        }
        else {
            recentTransactions.push(t);
        }
    });
    return { recentTransactions, olderTransactions};
}

export function applySelectedPublications(payload: any) {
    let result: any;
    if (payload) {
        result = {
            nSelected: 0
                + (payload.journalCriteria ? payload.journalCriteria.length : 0)
                + (payload.caseCollectionCriteria ? payload.caseCollectionCriteria.length : 0)
                + (payload.bookCriteria ? payload.bookCriteria.length : 0),
            journalCriteria: payload.journalCriteria ? payload.journalCriteria : [],
            bookCriteria: payload.bookCriteria ? payload.bookCriteria : [],
            caseCollectionCriteria: payload.caseCollectionCriteria ? payload.caseCollectionCriteria : [],
        };
    }
    else {
        result = {
            ...defaultPublicationCriteria,
        };
    }
    return result;
}

export function searchRestore(recherche: RechercheState) {
    let payload = {};
    const type = SearchActions.restore_recherche.name, params = '';
    const { hitlist, primaryTabState, rechTab } = recherche;
    const hitlistID = (hitlist.id || recherche.transactionID) as string;

    function result(url, title = 'Recherche') {
        return {
            url,
            params,
            restore: {
                type,
                title,
                payload,
            },
        };
    }


    if (primaryTabState.currentAssetTab && primaryTabState.currentAssetTab.assetRef && !primaryTabState.currentAssetTab.isLoading) {
        payload = {
            currentAsset: {
                id: primaryTabState.currentAssetTab.assetID,
                type: primaryTabState.currentAssetTab.assetRef[1],
            },
            rechTab: rechTab,
        };
        const ref = recherche.hitlist ? [...primaryTabState.currentAssetTab.assetRef, recherche.hitlist.type, hitlistID] : primaryTabState.currentAssetTab.assetRef;
        return result(getAssetUrlNoSource(ref), primaryTabState.assetTabs.find(tab => tab.assetID === primaryTabState.currentAssetTab.assetID).title);
    }

    switch (recherche.collectionTab) {
        case 'history':
            return result('/recherche/history');
        case 'favorites':
            return result('/recherche/favorites');

        // case null:
        // case 'hitlist':
    }

    if (hitlist.type === HitlistType.News && hitlistID) {
        return result(`/recherche/news/${hitlistID}`, `Recherche: News ${hitlistID}`);
    }

    // we do nothing if the hitlist is coming from news.(redirect already done)
    // the rest is for null / 'hitlist'
    if (hitlist.hits && hitlist.hits.length > 0 && hitlistID) {
        payload = {
            transactionID: hitlistID,
            rechTab: rechTab,
        };
        return result(`/recherche/${hitlist.type}/${hitlistID}`, `Recherche ${hitlistID}`);
    }
    else {
        payload = {
            rechTab: rechTab,
        };
        return result(`/recherche/${rechTab}/new`);
    }
}

function cleanHitlist(state) {
    return {
        ...state,
        hitlist: { numberOfHits: 0, numberOfHitsWithPremium: 0, hits: [] },
        hitlistOpen: false,
        filterOpen: false,
        previousFilterOpen: null,
        paging: { currentPage: 1, hitsPerPage: 10 },
    };
}

function cleanSearchFieldsPayload(search: Search, state: RechercheState) {
    if (search.type === SearchType.Retrieval) {
        return {
            ...search.parameters,
            ...shrinkPracticeAreaAndAssetType(search.parameters, state),
            ...shrinkArticleOfLawFilterCriterias(search.parameters),
        };
    } else {
        return { ...search.parameters };
    }
}

function shrinkPracticeAreaAndAssetType(search, state: RechercheState) {
    const allPracticeAreasSelected = search.practiceAreaGroupsCriteria === null || search.practiceAreaGroupsCriteria.length === (state.practiceAreaFilters || []).length;
    const allAssetTypesSelected = search.assetTypeGroupsCriteria === null || search.assetTypeGroupsCriteria.length === (state.documentCategoryFilters || []).length;

    //const allPracticeAreasSelected = search.practiceAreaGroupsCriteria.length === state.practiceAreaFilters.length;
    //const allAssetTypesSelected = search.assetTypeGroupsCriteria.length === state.documentCategoryFilters.length;

    return {
        practiceAreaGroupsCriteria: allPracticeAreasSelected ? [] : search.practiceAreaGroupsCriteria,
        assetTypeGroupsCriteria: allAssetTypesSelected ? [] : search.assetTypeGroupsCriteria,
    };
}

function shrinkArticleOfLawFilterCriterias(searchState) {
    return {
        articleOfLawFilterCriterias: searchState.articleOfLawFilterCriterias.filter(entry => {
            for (const n in entry) {
                if (entry[n]) { return true; }
            }
            return false;
        }),
    };
}

function makeViewConstraints() {
    const viewConstraints: any = {};

    viewConstraints.windowWidth = window.innerWidth;


    // if (viewConstraints.windowWidth < 900) {
    //     viewConstraints.autoCollapseHitlist = true;
    //     viewConstraints.autoCollapseFilters = true;
    // }

    return viewConstraints;
}

function loadSearchFields(state, { type, payload }) {
    const { searchFilter, searchType, transactionID, hitlistType } = payload;
    const area = areaForType(searchType);
    const rechTab = area;
    const drillDownFilter = searchFilter.drillDownFilter || {};
    delete searchFilter.drillDownFilter;
    const paging = searchFilter.paging || { currentPage: 1, hitsPerPage: 25 };
    delete searchFilter.paging;

    const overwriteSearchForm = payload.overwriteSearchForm === false ? false : true;

    const search = {
        type: searchType,
        parameters: { ...searchFilter },
    };

    return {
        ...state,
        search: hitlistType === HitlistType.News ? null : search,
        searchForms: {
            ...state.searchForms,
            [SearchType[searchType]]: overwriteSearchForm ? { ...searchFilter } : {...(state.searchForms[SearchType[searchType]])},
        },
        selectedPublications: {
            ...applySelectedPublications(searchFilter),
        },
        paging,
        drillDownFilter,
        // transactionType,
        transactionID,
        searchCollapsed: false,
        previousFilterOpen: null,
        rechTab,
    };
}

