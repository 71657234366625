import { Component, Input } from '@angular/core';


@Component({
    selector: 'slx-spinner',
    template: `<mat-spinner [mode]="mode" [diameter]="diameter" [strokeWidth]="strokeWidth"></mat-spinner>`,
})
export class SpinnerComponent {
    @Input() mode = 'indeterminate';
    @Input() diameter = 16;
    @Input() strokeWidth = 2;
}
