import { LanguageEnum } from '../enums';

export interface ThesaurusToggleInformation {
    term: ThesaurusTerm;
    baseForm?: ThesaurusBaseForm;
    translation?: ThesaurusTranslation;
    translationResult?: ThesaurusWord;
}

export interface ThesaurusTogglePayload extends ThesaurusToggleInformation {
    toggleItem: ThesaurusToggleItem;
}

export enum ThesaurusToggleItem {
    Term = 'term',
    BaseForm = 'baseForm',
    Translation = 'translation',
    TranslationResult = 'translationResult',
}

export interface ThesaurusInformation {
    terms: Array<ThesaurusTerm>;
}

export enum LemmaType {
    'Undefined' = 0,
    'Unknown' = 1,
    'Noun' = 2,
    'AdjAdverbPrep' = 3,
    'Verb' = 4,
    'Phrase' = 5,
    'Pronoun' = 6,
    'Adjective' = 7,
    'Adverb' = 8,
    'Preposition' = 9,
    'Article' = 10,
}

export interface ThesaurusTerm {
    term: string;
    baseForms: Array<ThesaurusBaseForm>;
    open?: boolean;
}

export interface ThesaurusBaseForm extends ThesaurusWord {
    types: Array<LemmaType>;
    translations: Array<ThesaurusTranslation>;
}

export interface ThesaurusTranslation {
    language: LanguageEnum;
    isSelected: boolean;
    translationResults: Array<ThesaurusWord>;
}

export interface ThesaurusWord {
    word: string;
    flexisons: Array<string>;
    isSelected: boolean;
   // tooltipBody?: string;
    tooltipTitle?: string;
    tooltipTitleLang?: string;
}


