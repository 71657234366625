module.exports = {
	"news-actions": "Actions",
	"news-tutorial": "Tutoriel News-Service",
	"news-PracticeArea": "Domaines du droit",
	"news-Journal": "Revue",
	"news-Search": "Recherche",
	"news-CaseLaw": "Jurisprudence",
	"news-news-tiles": "Cadres news",
	"news-search-newsletters": "Recherche Newsletter",
	"news-create-newsletter": "Créer cadre news",
	"news-arrange-tiles": "Réorganiser cadre news",
	"news-search-not-available-as-tile": "Recherche Newsletter non disponible en tant que cadre",
	"news-delivery-on": "Envoi courrier électronique à",
	"news-delivery-off": "Envoi courrier électronique de",
	"news-search-in-newsletters": "Rechercher",
	"news-area-selection": "Choisir domaines du droit",
	"news-journal-selection": "Choisir revues",
	"news-case-law-selection": "Choisir tribunaux",
	"news-journal-search": "Rechercher revues",
	"news-search-parameters": "Choisir critères de recherche",
	"news-search-advanced-parameters": "Choisir critères avancés de recherche",
	"news-search-validation": "Valider la recherche",
	"news-delivery": "Envoi",
	"news-hits": "Résultat(s)",
	"news-next": "Suivant",
	"news-back": "Retour",
	"news-save": "Enregistrer",
	"news-save-changes": "Sauvegarder changements",
	"news-close": "Fermer",
	"news-newsletter-name": "Nom de la Newsletter",
	"news-additionalNewsletter": "Newsletter par courrier électronique",
	"news-deliveryOnHits": "Envoi",
	"news-deliverAlways": "Toujours",
	"news-deliverOnlyOnNewHits": "Seulement si nouveaux contenus",
	"news-if-new-content-available": "Édition actuelle",
	"news-issue": "Fascicule",
	"news-year": "Année",
	"news-articles": "Article",
	"news-validator-selectLanguage": "Veuillez choisir une langue au minimum.",
	"news-validator-file-upload-maxSize": "Les données dépassent le volume maximum de 20 Mo.",
	"news-validator-file-ulpoad-fileType": "Le format du fichier est incorrect. Choisissez seulement un document au format PDF.",
	"news-validation-sucess": "La recherche a été effectuée avec succès et envoyée.",
	"news-validation-sucess-remark": "Remarque: l'intervalle n’a pas encore été configuré (options d'envoi) ; par conséquent, cette recherche peut produire moins de résultats, voire aucun.",
	"news-validation-noHits": "La recherche, avec les critères spécifiés, ne donne présentement pas de résultats. Si vous souhaitez toutefois sauvegarder cette Newsletter et recevoir d’éventuels résultats correspondants à votre demande à l’avenir, cliquez sur 'suivant'.",
	"news-validation-error": "La recherche est incorrect. Veuillez vérifier les critères et répéter cette étape à nouveau.",
	"news-dateFrom": "Date du",
	"news-dateTo": "Date au",
	"news-deliveryDay": "Jour d’envoi",
	"news-Monday": "Lu",
	"news-Tuesday": "Ma",
	"news-Wednesday": "Me",
	"news-Thursday": "Je",
	"news-Friday": "Ve",
	"news-Saturday": "Sa",
	"news-Sunday": "Di",
	"news-timeWindow": "Fréquence d'envoi",
	"news-days": "Jours",
	"news-day": "Jour",
	"news-daily": "Quotidien",
	"news-7days": "7 jours",
	"news-14days": "14 jours",
	"news-28days": "28 jours",
	"news-100days": "100 jours",
	"news-german": "Allemand",
	"news-french": "Français",
	"news-italian": "Italien",
	"news-english": "Anglais",
	"news-no-content": "Présentement, il n'y a pas de résultat disponible pour votre Newsletter.",
	"news-one-moment": "Un instant svp.",
	"news-no-tiles": "Vous n'avez pas encore créé de cadre pour la Newsletter.",
	"news-no-visible-tiles": "Tous les cadres sont masqués. Dans la barre latérale située à gauche, il est possible d’afficher à nouveau les caches via le menu actions ou l’icône œil.",
	"news-only-search-newsletters": "Jusqu'à présent, vous avez créé seulement des recherches Newsletter. Celles-ci ne sont pas visibles en tant que cadre.",
	"news-not-premium-title": "Swisslex Premium",
	"news-not-premium-message": "Vous disposez d'un compte Standard Swisslex. Pour utiliser la section Newsletter, vous devez disposer d'un abonnement Swisslex Premium.",
	"news-newsletter-alert-sent": "La newsletter sera envoyée.",
	"news-newsletter-alert-error": "La newsletter n'a pas pu être envoyée.",
	"news-open-hitlist": "Ouvrir en tant que liste de résultats",
	"news-send-news-now": "Envoyer maintenant",
	"news-settings": "Modifier",
	"news-mail-delivery-off": "Désactiver l'envoi",
	"news-mail-delivery-on": "Activer l'envoi",
	"news-toggle-tile-off": "Masquer le cadre",
	"news-toggle-tile-on": "Afficher le cadre",
	"news-remove-item-content-menu": "Supprimer",
	"news-err-tile-content": "Le contenu du cadre n'a pas pu être téléchargé.",
	"news-err-delete": "Le cadre n'a pas pu être supprimé",
	"news-err-add-update": "Erreur lors de la création ou la mise à jour de la configuration news",
	"news-tilename": "Nom (cadre / newsletter)",
	"news-delivery-options": "Options d’envoi",
	"news-language-options": "Paramétrage des langues",
	"news-language": "Langue",
	"news-button-desc-new-tile": "Paramétrer nouveau cadre ou newsletter pour une demande de recherche, des revues ou des domaines du droit",
	"news-button-desc-arrange-tiles": "Classer régulièrement les cadres.  Uniquement effectif en cas d’espace entre les cadres.",
	"news-button-desc-mail-delivery-on": "Activer l’envoi de courriers électroniques pour tous les cadres",
	"news-button-desc-mail-delivery-off": "Désactiver l’envoi de courriers électroniques pour tous les cadres",
	"news-slider-desc-mail-delivery-on": "Activer / Désactiver l’envoi de courriers électroniques pour ces cadres",
	"news-assistant": "Assistant-News"
};