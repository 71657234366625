import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import {
    getRegisterBusinessCustomerFormInputsUrl, getRegisterRakCustomerFormInputsUrl,
    getRegisterUniversityCustomerFormInputsUrl, getUsersIpUrl,
    postActivateRakUserDataUrl, postRegisterBusinessCustomerDataUrl, postRegisterRakUserDataUrl, postRegisterUniversityUserDataUrl, postTrainingDataUrl, SlxHttp
} from '../../access';
import { AccountState, AppState, catchErrorForAction, makeAction, SlxAction } from '../../models';
import { AlertType } from '../../models/state/commonApp';

import { AccountService } from './../../account';
import { AppActions } from './../../appActions';
import { AboRegistrationService } from './abo-services.service';
import { AboServicesActions } from './abo-servicesActions';

@Injectable()
export class AboServiceEffects {

    private hasAlerts = false;

    @Effect() registerBusinessFormInputs = this.actions$.pipe(
        ofType(AboServicesActions.load_register_business_form_elements.name),
        withLatestFrom(this.accountService.store.select(state => state.account) as Observable<AccountState>),
        mergeMap(([action, state]) => this.slxHttp.get(`${getRegisterBusinessCustomerFormInputsUrl}${this.accountService.lang}`, false).pipe(
            map(payload => makeAction({ result: AboServicesActions.load_register_business_form_elements.name, payload })),
            catchErrorForAction(action, { type: AlertType.Error, alertKey: null }))
        )
    );

    @Effect() registerStudentsFormInputs = this.actions$.pipe(
        ofType(AboServicesActions.load_register_students_form_elements.name),
        withLatestFrom(this.accountService.store.select(state => state.account) as Observable<AccountState>),
        mergeMap(([action, state]) => this.slxHttp.get(`${getRegisterUniversityCustomerFormInputsUrl}${this.accountService.lang}`, false).pipe(
            map(payload => makeAction({ result: AboServicesActions.load_register_students_form_elements.name, payload })),
            catchErrorForAction(action, { type: AlertType.Error, alertKey: null }))
        )
    );

    @Effect() registerRakFormInputs = this.actions$.pipe(
        ofType(AboServicesActions.load_register_rak_form_elements.name),
        withLatestFrom(this.accountService.store.select(state => state.account) as Observable<AccountState>),
        mergeMap(([action, state]) => this.slxHttp.get(`${getRegisterRakCustomerFormInputsUrl}${this.accountService.lang}`, false).pipe(
            map(payload => makeAction({ result: AboServicesActions.load_register_rak_form_elements.name, payload })),
            catchErrorForAction(action, { type: AlertType.Error, alertKey: null }))
        )
    );

    @Effect() postBusinessCustomerData = this.actions$.pipe(
        ofType(AboServicesActions.save_add_business_customer_data.name),
        mergeMap((action: SlxAction) => this.slxHttp.post(postRegisterBusinessCustomerDataUrl, action.payload, false)
            .pipe(
                map(payload => makeAction({ result: AboServicesActions.save_add_business_customer_data.name, payload })),
                catchError(error => {
                    if (error.status === 409) {
                        return of(makeAction({ result: AboServicesActions.save_add_business_customer_data_bad_email.name, payload: error.error }));
                    }
                    const errorKey = error.error.translationKey ? error.error.translationKey : error.error;
                    const errorAction = makeAction({error: AboServicesActions.save_add_business_customer_data.name, payload: error, alert: { type: AlertType.Error, key: errorKey }});
                    return of(errorAction);
                }))
        )
    );

    @Effect() postUniUserData = this.actions$.pipe(
        ofType(AboServicesActions.save_uni_user_profile_data.name),
        mergeMap((action: SlxAction) => this.slxHttp.post(postRegisterUniversityUserDataUrl, action.payload, false)
            .pipe(
                map(payload => makeAction({ result: AboServicesActions.save_uni_user_profile_data.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: null }))
        )
    );

    @Effect() postRakUserData = this.actions$.pipe(
        ofType(AboServicesActions.save_rak_user_profile_data.name),
        mergeMap((action: SlxAction) => this.slxHttp.postMultipart(postRegisterRakUserDataUrl, action.payload, false)
            .pipe(
                map(payload => makeAction({ result: AboServicesActions.save_rak_user_profile_data.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: null }))
        )
    );

    @Effect() postActivateRakUser = this.actions$.pipe(
        ofType(AboServicesActions.activate_rak_user.name),
        mergeMap((action: SlxAction) => this.slxHttp.post(postActivateRakUserDataUrl, action.payload, false)
            .pipe(
                map(payload => makeAction({ result: AboServicesActions.activate_rak_user.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: null }))
        )
    );

    @Effect() postTrainingData = this.actions$.pipe(
        ofType(AboServicesActions.save_training_data.name),
        mergeMap((action: SlxAction) => this.slxHttp.post(postTrainingDataUrl, action.payload, false)
            .pipe(
                map(payload => makeAction({ result: AboServicesActions.save_training_data.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: null }))
        )
    );

    @Effect() getUsersIp = this.actions$.pipe(
        ofType(AboServicesActions.get_users_ip.name),
        mergeMap((action: SlxAction) => this.slxHttp.get(getUsersIpUrl, false)
            .pipe(
                map(payload => makeAction({ result: AboServicesActions.get_users_ip.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' }))
        )
    );

    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store<AppState>,
        private slxHttp: SlxHttp,
        private accountService: AccountService,
        private aboRegistrationService: AboRegistrationService
    ) {
    }
}
