import { Component, Input } from '@angular/core';

@Component({
    selector: 'slx-faq-data',
    templateUrl: './faq-data.component.html',
    styleUrls: ['./faq-data.component.scss'],
})
export class FaqDataComponent {
    @Input() faq;
}
