import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import ResizeObserver from 'resize-observer-polyfill';
import { Observable, of, Subscription } from 'rxjs';

import { LoginService } from '../../access';
import { AccountService } from '../../account';
import { AppActions } from '../../appActions';
import { getAssetURL } from '../../config';
import {
    ActiveTocTab, Asset, AssetAttachment, AssetCiting, AssetDisplayType, AssetRef, AssetRefOptions, AssetTab, AssetTabstrip,
    AssetType, CommentaryForLaw, DocumentContent, LanguageEnum, LawContent, makeAction, RelatedAssetsModel, SourceDetail, UserFavorite
} from '../../models';
import { isResponsive } from '../../utility/utilityFunctions';
import { AssetService } from '../asset.service';
import { AssetActions } from '../assetActions';
import { BiblioInfoComponent } from '../docview/bilblioInfo/biblio-info.component';
import { DisplayRelatedAssetEvent } from '../docview/relatedAssets/relatedAssets.component';

import { getFollowingAssetRef, getPrecedingAssetRef } from './docview-tools.helperFunctions';

const debug = require('debug')('tools-details');

const maxLegalisArticlesToShow = 3;

@Component({
    selector: 'slx-docview-tools',
    templateUrl: './docview-tools.component.html',
    styleUrls: ['./docview-tools.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocviewToolsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() assetTab: AssetTab;
    @Input() tabArea: AssetTabstrip;
    @Input() tabIndex: number;

    public assetCiting: RelatedAssetsModel;
    public commentariesForLaw: RelatedAssetsModel;
    public asset: Asset;
    public assetUrl: string;
    public citationCount = -1;
    public lawCount = -1;
    public isPrimary = false;
    public attachmentCount = -1;
    public hasDifferentVersions = false;
    private userPrefSubs: Subscription;
    private assetSub: Subscription;
    private favoriteSub: Subscription;
    public userPrefs;
    public isFavorite;
    private assetIsLoading = false;
    public lawsOfCommentary: any;

    public moreLegalis = false;
    public legalisMaxHeight: number;

    private currentDetailsClickHandler: (event: MouseEvent) => void;
    // public titleTranslationString;

    public commentaryTranslationStrings: { title: string, description: string };

    private observer: ResizeObserver;

    get docContent(): DocumentContent {
        return this.asset ? this.asset.content as DocumentContent : null;
    }

    constructor(public assetService: AssetService, private accountService: AccountService, public loginService: LoginService, public dialog: MatDialog, private element: ElementRef, public translate: TranslateService, private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {
    }

    ngOnInit() {
        this.favoriteSub = this.assetService.store.select(state => state.recherche.userFavorites).subscribe(favorites => {
            this.isFavorite = favorites ? favorites.reduce((f, c) => c.children ? f.concat(c.children).concat(c) : f.concat(c), []).find(fav => fav.targetID === this.tabArea.currentAssetTab.assetID) !== undefined : false;
        });

        this.userPrefSubs = this.accountService.profilePrefs.subscribe(prefs => {
            if (prefs) {
                this.userPrefs = prefs;
            }
        });
    }

    ngAfterViewInit() {

        this.ngZone.runOutsideAngular(() => {
            this.observer = new ResizeObserver((entries, observer) => {

                entries.forEach(entry => {

                    if (entry.contentRect.width <= 850 || entry.contentRect.width > 980) {
                        this.element.nativeElement.classList.add('hide-doc-description');
                    }
                    else {
                        this.element.nativeElement.classList.remove('hide-doc-description');
                    }

                    if (entry.contentRect.width <= 750) {
                        this.element.nativeElement.classList.add('responsive-size');
                    }
                    else {
                        this.element.nativeElement.classList.remove('responsive-size');
                    }

                    if (entry.contentRect.width <= 980) {
                        this.element.nativeElement.classList.add('hide-doc-navigation-doc');
                    } else {
                        this.element.nativeElement.classList.remove('hide-doc-navigation-doc');
                    }
                });
            });
            this.observer.observe(this.element.nativeElement);
        });
    }

    ngOnDestroy() {
        this.userPrefSubs.unsubscribe();
        this.favoriteSub.unsubscribe();
        this.observer.disconnect();

        if (this.assetSub) {
            this.assetSub.unsubscribe();
        }
    }

    ngOnChanges() {

        if (!this.assetIsLoading && (!this.asset || this.assetTab.assetID !== this.asset.id)) {

            this.assetCiting = this.assetTab ? this.assetTab.assetCitedIn : null;
            this.isPrimary = this.tabArea.isPrimary;

            if (this.assetSub) {
                this.assetSub.unsubscribe();
            }
            this.assetIsLoading = true;
            this.assetSub = this.assetService.getAsset(this.assetTab.assetRef).subscribe(asset => {
                this.asset = asset;
                this.assetIsLoading = false;
                this.assetUrl = `${window.location.protocol}//${window.location.host}${getAssetURL(asset.ref)}`;
                if (this.asset.type === AssetType.document || this.asset.type === AssetType.euDocument) {
                    const content = this.asset.content as DocumentContent;
                    this.attachmentCount = content.attachments.length;
                    this.hasDifferentVersions = (content.consolidatedAssets && content.consolidatedAssets.length > 0)
                        || (content.corrections && content.corrections.length > 0)
                        || (content.celexNrOfOriginalAsset && content.celexNrOfOriginalAsset !== '');
                }

                switch (this.asset.ref[0]) {
                    case AssetDisplayType.CommentaryDocument:
                        this.commentaryTranslationStrings = { title: 'rech-asset-comment-more-laws', description: 'rech-asset-comment-more-laws-description' };
                        break;
                    case AssetDisplayType.ArticleOfLawDocument:
                        this.commentaryTranslationStrings = { title: 'rech-asset-commented-in-article', description: 'rech-asset-comment-article-description' };
                        break;
                    case AssetDisplayType.LawDocument:
                        this.commentaryTranslationStrings = { title: 'rech-asset-commented-in-law', description: 'rech-asset-comment-law-description' };
                }
                this.changeDetectorRef.markForCheck();
            });
        }

        if (this.tabArea.currentAssetTab && this.tabArea.currentAssetTab.assetCitedIn) {
            this.citationCount = 0;
            this.tabArea.currentAssetTab.assetCitedIn.assetListGroups.forEach(g => this.citationCount += g.totalCount);
        }

        if (this.tabArea.currentAssetTab && this.tabArea.currentAssetTab.commentariesForLaw) {

            this.commentariesForLaw = {
                assetID: this.tabArea.currentAssetTab.commentariesForLaw.assetID,
                assetListGroups: this.tabArea.currentAssetTab.commentariesForLaw.assetListGroups.map(g => {
                    let selfRemoved = false;
                    return {
                        groupName: g.groupName,
                        entries: g.entries.map(e => ({
                            publicationName: e.publicationName,
                            toggled: false,
                            listEntries: e.listEntries.filter(f => {
                                if (f.assetID === this.tabArea.currentAssetTab.assetID) {
                                    selfRemoved = true;
                                    return false;
                                }
                                return true;
                            }),
                            maxHeight: 0,
                        })),
                        totalCount: selfRemoved ? g.totalCount - 1 : g.totalCount,
                    };
                }).filter(e => e.totalCount > 0),
            };

            this.lawCount = 0;
            this.commentariesForLaw.assetListGroups.forEach(cl => this.lawCount += cl.totalCount);

            this.lawsOfCommentary = this.tabArea.currentAssetTab.commentariesForLaw.assetListGroups.filter(g => g.lawAbbr).map(g => ({
                articleNumber: g.article,
                lawAbbr: g.lawAbbr,
            }));

            this.moreLegalis = false;
            this.setLegalisMaxHeight();
        }

        if (this.favoriteSub) {
            this.favoriteSub.unsubscribe();
        }

        this.favoriteSub = this.assetService.store.select(state => state.recherche.userFavorites).subscribe(favorites => {
            this.isFavorite = favorites ? favorites.reduce((f, c) => c.children ? f.concat(c.children).concat(c) : f.concat(c), []).find(fav => fav.targetID === this.tabArea.currentAssetTab.assetID) !== undefined : false;
        });


    }


    public toggleDetails(event: MouseEvent, detailsName: string): void {
        const togglePropertyName = `${detailsName}Shown`;
        const toggleValue = this.tabArea[togglePropertyName];
        debug(detailsName, `toggling show details to => ${!toggleValue}`);
        if (!toggleValue) {
            debug(detailsName, 'adding click listener');
            // this.currentClickHandler = this.closeDetailsClickHandler.bind(this, detailsName)
            this.currentDetailsClickHandler = (event: MouseEvent) => { this.closeDetailsClickHandler(detailsName, event); };
            document.addEventListener('click', this.currentDetailsClickHandler, true);
        }
        const action = { type: this.assetService.actions.toggle_docviewtool.name, payload: { isPrimary: this.tabArea.isPrimary, toggleValue: !toggleValue, togglePropertyName } };
        this.assetService.dispatch(action);
    }


    private closeDetailsClickHandler(detailsName: string, event: MouseEvent): void {
        const toggle = this.element.nativeElement.querySelector(`.${detailsName}-toggle`);
        const details = this.element.nativeElement.querySelector(`.${detailsName}-details`);

        if (toggle && toggle.contains(event.target)) {
            document.removeEventListener('click', this.currentDetailsClickHandler, true);
            debug(detailsName, 'clicked on toggle => just remove the click listener');
            return;
        }

        if (details && details.contains(event.target)) {
            debug(detailsName, 'clicked in details => do not remove click listener nor close details');
            return;
        }

        debug(detailsName, 'clicked anywhere outside details and not on toggle => close details and remove click listener');
        this.closeDetails(detailsName);
    }

    public closeDetails(detailsName: string) {
        const action = { type: this.assetService.actions.toggle_docviewtool.name, payload: { isPrimary: this.tabArea.isPrimary, toggleValue: false, togglePropertyName: `${detailsName}Shown` } };
        this.assetService.dispatch(action);
        document.removeEventListener('click', this.currentDetailsClickHandler, true);
        debug(detailsName, 'removing click listener & closing details');
    }


    showToc(event: MouseEvent) {
        if (isResponsive()) {
            const source = SourceDetail.Document;
            const ref = AssetRef.create(this.docContent.navigationInfo.collectionAssetType, this.docContent.navigationInfo.collectionAssetID,
                { publicationId: this.docContent.navigationInfo.publicationID, source });
            this.assetService.openAsset(ref, this.isPrimary, event);

        }
        else {
            this.assetService.dispatch({ type: AppActions.set_collection_tab.name, payload: { collectionTab: 'toc', restoreOnToggle: true, isPrimary: this.isPrimary } });
            this.assetService.dispatch({ type: AssetActions.set_active_tab_for_toc.name, payload: this.isPrimary ? ActiveTocTab.Primary : ActiveTocTab.Secondary });
        }
    }

    openEuDocFromCelexNr(event: MouseEvent, celexNr: string) {
        const openInPrimary = (this.tabArea.isPrimary && event.altKey) || (!this.tabArea.isPrimary && !event.altKey);
        this.assetService.dispatch({ type: this.assetService.actions.load_eu_doc_based_on_celex_nr.name, payload: { celexNr, openInPrimary } });
        // toggle the details
        this.closeDetails('cites');
    }

    public toggleFavorite() {
        const favorite: UserFavorite = { targetID: this.asset.id, targetType: this.asset.displayType, title: AssetRef.titleForAsset(this.asset) };
        if (this.asset.displayType === 'aol') {
            favorite.targetAolID = this.asset.ref[3];
        }
        this.assetService.dispatch(makeAction({ type: AssetActions.toggle_favorite.name, payload: { favorite } }));
    }

    public copyToClipboard() {
        this.assetService.copyToClipboard(this.assetUrl);
    }

    public sendEmail() {
        const mailbody = this.translate.instant(this.assetTab.title) + '\n' + this.assetUrl;
        const mail = `mailto:?&subject=${encodeURIComponent(this.translate.instant('rech-asset-document-link'))}&body=${encodeURIComponent(mailbody)}`;
        window.location.href = mail;
    }

    public getPdf(value) {
        if (!this.asset) {
            return;
        }
        if (this.asset.type === AssetType.document || this.asset.type === AssetType.euDocument) {
            const tryHighlight = value === 0 || value === false ? false : true;
            this.assetService.dispatch({ fetch: this.assetService.actions.save_doc_pdf.name, payload: { isPrimary: this.tabArea.isPrimary, asset: this.asset, tryHighlight: tryHighlight } });
        }
        else if (this.asset.type === AssetType.law) {
            window.open((this.asset.content as LawContent).getPdfUrl);
        }
    }

    public isLangActive(language: string) {
        return language.toLowerCase() === LanguageEnum[this.asset.content.assetLanguage].toLowerCase();
    }

    public chooseLanguage(ref, lang) {
        const langOptIndex = ref.findIndex(r => r === AssetRefOptions.assetLanguage);
        let assetRef = [...ref];
        if (langOptIndex > -1) {
            assetRef[langOptIndex + 1] = lang.toLowerCase();
        }
        else {
            assetRef = [...assetRef, AssetRefOptions.assetLanguage, lang.toLowerCase()];
        }
        this.assetService.dispatch({ fetch: this.assetService.actions.load_asset.name, payload: { ref: assetRef } });
    }

    public chooseFontsize(size: number, event: any) {
        this.assetService.dispatch({ type: this.assetService.actions.toggle_docview_fontsize.name, payload: { size, isPrimary: this.tabArea.isPrimary } });
    }

    getAttachments(): Observable<AssetAttachment[]> {
        return of(this.asset.content.attachments);
    }

    public toggleLegalis() {
        this.moreLegalis = !this.moreLegalis;
        this.setLegalisMaxHeight();
    }

    private setLegalisMaxHeight() {
        this.legalisMaxHeight = this.moreLegalis ? this.lawsOfCommentary.length * 21 : maxLegalisArticlesToShow * 21;
    }

    public openBiblioInfo() {
        const data = {
            bibliographyContentAsHtml: this.asset.content.bibliographyContentAsHtml,
            fontSize: this.tabArea.fontSize,
        };
        this.dialog.open(BiblioInfoComponent, {
            width: '800px', height: '800px', panelClass: 'biblio-info', data,
        });
    }

    public reload(ref) {
        this.assetService.dispatch({ fetch: this.assetService.actions.load_asset.name, payload: { ref } });
    }

    openPrecedingAsset(event: MouseEvent) {
        if (this.docContent && this.docContent.navigationInfo && this.asset) {
            const assetRef = getPrecedingAssetRef(this.docContent, this.asset.ref);
            this.openAsset(assetRef, event, SourceDetail.DocumentTocBackward);
        }
    }

    openFollowingAsset(event: MouseEvent) {
        if (this.docContent && this.docContent.navigationInfo && this.asset) {
            const assetRef = getFollowingAssetRef(this.docContent, this.asset.ref);
            this.openAsset(assetRef, event, SourceDetail.DocumentTocForward);
        }
    }

    openAsset(assetRef: AssetRef, event: MouseEvent, source: string) {
        if (assetRef) {
            assetRef = [...assetRef, AssetRefOptions.sourceDetail, source];
            this.assetService.openAsset(assetRef, this.tabArea.isPrimary, event);
        }
    }

    openNewerComment(event: MouseEvent, assetID: string) {
        const assetRef = AssetRef.create(AssetDisplayType.CommentaryDocument,assetID);
        this.openAsset(assetRef,event,'newer-comment');
    }

    closeNewerCommentWarning(event: MouseEvent) {
        this.assetService.dispatch( { type: AssetActions.clear_newer_comment.name, payload: { isPrimary: this.isPrimary, assetID: this.asset.id} });
    }

    openLawForCommentary(event: DisplayRelatedAssetEvent) {
        const assetRef = AssetRef.create(event.entry.assetDisplayType, event.entry.assetID);
        this.assetService.openAsset(assetRef, !this.tabArea.isPrimary, event.event);
        this.closeDetails('commentaries');
    }

    openCitedAsset(displayEvent: DisplayRelatedAssetEvent) {
        //if the citation is opened in the other window => scroll the doc in the window you clicked in to the citation spot
        if (!displayEvent.event.altKey) {
            this.assetService.dispatch({ type: this.assetService.actions.set_citing_anchor.name, payload: { setInPrimary: this.isPrimary, anchor: displayEvent.entry.anchor } });
        }
        const ref = AssetRef.create(AssetDisplayType.UnknownDocument, displayEvent.entry.assetID, {
            citationInfo: { type: AssetRefOptions.DocumentCites, id: displayEvent.entry.citationID },
            source: SourceDetail.DocumentCitedIn,
        });
        this.assetService.openAsset(ref, !this.isPrimary, displayEvent.event);
        // toggle the citations
        this.closeDetails('cites');

    }


}
