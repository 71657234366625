module.exports = {
	"rech-hitlist-hits": "Treffer",
	"rech-hitlist-deactivateFilter": "Filter deaktivieren",
	"rech-hitlist-change-state": "Filter aktivieren / deaktivieren",
	"rech-hitlist-searchSubscribtion": "Als Suchabo einrichten",
	"rech-hitlist-to-news-area": "Zum Newsservice wechseln",
	"rech-hitlist-asPdf": "Trefferliste herunterladen",
	"rech-hitlist-pdf-error": "Fehler beim Herunterladen des Dokuments",
	"rech-hitlist-sort": "Sortieren nach",
	"rech-hitlist-date": "Datum",
	"rech-hitlist-relevance": "Relevanz",
	"rech-hitlist-filterType": "Filtern",
	"rech-hitlist-resetFilter": "Filter zurücksetzen",
	"rech-hitlist-languages": "Sprachen",
	"rech-hitlist-premiumhits": "Treffer mit Premium",
	"rech-hitlist-more-hits": "Mit Premium-Abonnement:",
	"rech-hitlist-more-hist-count": "{{hitCount}}  aktuelle Rechtssprechungen zusätzlich",
	"rech-hitlist-more-info": "Mehr erfahren",
	"rech-hitlist-collapse": "Fenster verkleinern / vergössern",
	"rech-hitlist-Commentary": "Kommentare",
	"rech-hitlist-Caselaw": "Urteile",
	"rech-hitlist-OfficialPub": "Amtliche Dokumente",
	"rech-hitlist-CaselawReview": "Urteilsbesprechungen",
	"rech-hitlist-EssayNonBook": "Aufsätze",
	"rech-hitlist-EssayOnlyBook": "Bücher",
	"rech-hitlist-EssayAndBook": "Aufsätze / Bücher",
	"rech-hitlist-result": "Treffern",
	"rech-hitlist-result-single": "Treffer",
	"rech-hitlist-of": "von",
	"rech-hitlist-count": "{{subHitCount}} weitere Treffer in diesem Werk",
	"rech-hitlist-pdf": "PDF Trefferliste",
	"rech-hitlist-filtern": "Filtern",
	"rech-hitlist-eu-Treaty": "Verträge",
	"rech-hitlist-eu-InternationalAgreement": "Int. Abkommen",
	"rech-hitlist-eu-Legislation": "Rechtsvorschriften",
	"rech-hitlist-eu-LegislationRegulation": "Verordnungen",
	"rech-hitlist-eu-LegislationDirective": "Richtlinien",
	"rech-hitlist-eu-LegislationDecision": "Beschlüsse",
	"rech-hitlist-eu-LegislationNonOpposition": "Entscheidungen",
	"rech-hitlist-eu-ComDocuments": "COM-Dokumente",
	"rech-hitlist-eu-CaseLaw": "Rechtsprechung",
	"rech-hitlist-eu-EftaDocuments": "EFTA-Dokumente",
	"rech-hitlist-eu-Undefined": "Undefiniert",
	"rech-hitlist-EUHitlist_Konsolidierte_Fassung": "Letzte konsolidierte Fassung"
};