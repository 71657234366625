import { Directive, HostListener, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AssetRef, CitingInfromation, makeAction, PlaceHolderEnum } from '../../models';
import { AssetService, selectionMenuListener } from '../asset.service';
import { AssetActions } from '../assetActions';


interface SelectionModifier {
    selector: string;
    modifier: (element: Node) => void;
}

@Directive({
    selector: '[slx-text-selection]',
})
export class TextSelectionDirective implements OnChanges {


    @Input() assetRef: AssetRef;
    private timeout;
    private assetID: string;

    constructor(private assetService: AssetService, private translate: TranslateService) {
        this.mouseUpListener = this.mouseUpListener.bind(this);
    }

    ngOnChanges(): void {
        this.assetID = AssetRef.toAssetID(this.assetRef);
    }

    @HostListener('selectstart', ['$event']) onSelectStart(event): void {
        if (!AssetRef.enhancedTextSelectionEnabled(this.assetRef)) {
            return;
        }
        // Removing Listener that was attached from MenuSelctionComponent (it might not have been resolved when new selectoin starts)
        this.assetService.showTextSelectionMenu(null);
        clearTimeout(this.timeout);
        document.removeEventListener('click', selectionMenuListener);
        document.addEventListener('mouseup', this.mouseUpListener);
        this.assetService.dispatch(makeAction({ fetch: AssetActions.get_citation_information.name, payload: { assetID: this.assetID } }));
    }

    private mouseUpListener(event) {

        if (event.clientX > window.innerWidth || event.clientX < 0 || event.clientY > window.innerHeight || event.clientY < 0) {
            document.removeEventListener('mouseup', this.mouseUpListener);
            return;
        }

        const citingInformation = this.extractCitingInformation();

        if (!citingInformation) {
            document.removeEventListener('mouseup', this.mouseUpListener);
            return;
        }

        const selectionInformation = { citingInformation, assetID: this.assetID, position: this.calculateTextSelectionPosition(event.clientX, event.clientY, 250, 75) };

        document.removeEventListener('mouseup', this.mouseUpListener);
        this.timeout = setTimeout(() => this.assetService.showTextSelectionMenu(selectionInformation), 500);
    }

    calculateTextSelectionPosition(left: number, top: number, width: number, height: number, padding: number = 10): ({ left: number, top: number }) {
        let leftPosition, topPosition;
        //show obove
        if (top + height < window.innerHeight) {
            if (left + width < window.innerWidth - padding) {
                leftPosition = left; topPosition = top; //show on right, default
            } else if (left - width > padding) {
                leftPosition = left - width; topPosition = top; //show on left
            } else {
                leftPosition = window.innerWidth / 2 - width / 2, topPosition = top; //show in middle
            }
        } else {
            if (left + width < window.innerWidth + padding) {
                leftPosition = left; topPosition = top - height;
            } else if (left - width > padding) {
                leftPosition = left - width; topPosition = top - height;
            } else {
                leftPosition = window.innerWidth / 2 - width / 2; topPosition = top - height;
            }
        }
        // Add Small Padding so that window does not appear directly on mousePosition
        return { left: leftPosition + 10, top: topPosition + 10 };
    }


    selectionModifers: SelectionModifier[] = [
        {
            selector: '.footnotecall, .margin', modifier: (element: Element) => element.remove(),
        },
        {
            selector: '.pagebreak', modifier: (element: Element) => {

                if (element.previousSibling && element.previousSibling.previousSibling) {
                    // pagebreak has a sibling which is a text node
                    const relevantNode = element.previousSibling.previousSibling;
                    const lastCharacter = relevantNode.textContent.slice(-1);

                    switch (lastCharacter) {
                        case '-':
                            relevantNode.textContent = relevantNode.textContent.slice(0, -1);
                            break;
                        case ' ':
                            break;
                        default:
                            relevantNode.textContent += ' ';
                            break;
                    }

                    element.remove();
                }
                else if (element.parentElement && element.parentElement.tagName === 'P') {
                    // pagebreak is in its own p-tag
                    element.parentElement.previousSibling.textContent += ' ';
                    element.parentElement.remove();
                }
                else {
                    element.remove();
                }
            },
        },
        {
            selector: 'p, li', modifier: (element: Element) => {
                if (element.nextSibling) {
                    element.textContent += '\n';
                }
            },
        },
    ];

    private determineTarget(startContainer: Node, startContainerParent: HTMLElement): { target: Element, hasFootNote: boolean } {
        let target: Element;
        let targetLocation: string;
        let hasFootNote = false;

        if (startContainerParent.closest('.footnotes')) {
            hasFootNote = true;

            if (/^fn_/.test(startContainerParent.getAttribute('name'))) {
                targetLocation = this.getAttributeKey(startContainerParent);
            } else {
                const closestDD = startContainerParent.closest('dd');
                const footNoteCall = closestDD.previousElementSibling.querySelector('a[name^="fn_"]');
                targetLocation = this.getAttributeKey(footNoteCall);
            }
            target = document.querySelector(`a[name="footnotecall_${targetLocation}"]`);

        } else {
            target = <Element>startContainer.previousSibling || startContainerParent;
        }

        return { target, hasFootNote };
    }

    private extractCitingInformation(): CitingInfromation {
        const userSelection: Selection = window.getSelection();
        const range = userSelection.getRangeAt(0);
        const docFragment = range.cloneContents();
        const startContainer = range.startContainer;
        const startContainerParent = <HTMLElement>startContainer.parentNode;
        const nearestDocView = startContainerParent.closest('.doc-content-innerHTML');

        // for IE => create parent container
        const detachedElement = document.createElement('div');
        detachedElement.appendChild(docFragment.cloneNode(true));

        const nPagesBrakesInSelection = docFragment.querySelectorAll('.pagebreak').length;
        const considerationsInSelection = Array.from(docFragment.querySelectorAll('[id^="cons_"]')).map(el => this.extractConsNumber(el.id));
        const marginNumbersInSelection = Array.from(docFragment.querySelectorAll('a[name^="margin_"]')).map(this.getAttributeKey);

        this.selectionModifers.forEach(selectionModifer => {
            Array.from(detachedElement.querySelectorAll(selectionModifer.selector)).forEach(element => {
                selectionModifer.modifier(element);
            });
        });

        const userTextSelection = detachedElement.textContent;

        if (userTextSelection === '' || !nearestDocView) {
            return null;
        }

        const rootDiv = nearestDocView.querySelector('div > div > div');
        const { target, hasFootNote } = this.determineTarget(startContainer, startContainerParent);

        const marginNumberResult = this.determineAndCheckFoundElements(marginNumbersInSelection, this.findNearestMarginNumber(target, rootDiv));
        const consNumberResult = this.determineAndCheckFoundElements(considerationsInSelection, this.findClosestConsiderationNumber(target, rootDiv));

        const suffixMap = new Map<PlaceHolderEnum, string>();
        suffixMap.set(PlaceHolderEnum.PageBreak, this.determineSuffixForMultiElement(nPagesBrakesInSelection + 1));
        suffixMap.set(PlaceHolderEnum.ConsNumber, this.determineSuffixForMultiElement(consNumberResult.nElementsInCitation));
        suffixMap.set(PlaceHolderEnum.MarginNumber, this.determineSuffixForMultiElement(marginNumberResult.nElementsInCitation));

        return {
            pageNumber: this.findStartPage(target, target),
            marginNumber: marginNumberResult.foundElement,
            consNumber: consNumberResult.foundElement,
            footNoteNumber: hasFootNote ? target.innerHTML : null,
            textSelection: userTextSelection,
            suffixForMultielments: suffixMap,
        };
    }

    private determineAndCheckFoundElements(elementsFoundInCitation: string[], foundRelevantElement: string): { foundElement: string, nElementsInCitation: number } {
        const nElementsInCitation = foundRelevantElement && !elementsFoundInCitation.includes(foundRelevantElement) ?
            elementsFoundInCitation.length + 1 : elementsFoundInCitation.length;

        const foundElement = !foundRelevantElement && elementsFoundInCitation.length > 0 ?
            elementsFoundInCitation[0] : foundRelevantElement;

        return { foundElement, nElementsInCitation };
    }

    private determineSuffixForMultiElement(nElementmentsInSelection: number) {
        switch (nElementmentsInSelection) {
            case 0:
            case 1:
                return '';
            case 2:
                return ` ${this.translate.instant('rech-asset-citation-following')}`;
            default:
                return ` ${this.translate.instant('rech-asset-citation-multiple-following')}`;
        }
    }

    private getAttributeKey(element): string {
        return element.getAttribute('name').split('_')[1];
    }

    private findStartPage(target, rootNode) {
        if (target.classList.contains('doc-content-innerHTML')) {
            return null;
        }

        if ((/^page_/.test(target.getAttribute('name')))) {
            return this.getAttributeKey(target);
        }

        const page = target.querySelector('a[name^="page_"]');
        if (page !== null && !(target.contains(page) && target.contains(rootNode))) {
            return this.getAttributeKey(page);
        }

        return this.findStartPage(target.previousElementSibling || target.parentElement, rootNode);
    }

    private findNearestMarginNumber(target, rootDiv) {

        if (target.classList.contains('doc-content-innerHTML') || target === rootDiv || (/^title_/.test(target.className))) {
            return null;
        }

        if ((/^margin_/.test(target.getAttribute('name')))) {
            return this.getAttributeKey(target);
        }

        const marginNumber = target.querySelector('a[name^="margin_"]');
        if (marginNumber !== null) {
            return this.getAttributeKey(marginNumber);
        }

        return this.findNearestMarginNumber(target.previousElementSibling || target.parentElement, rootDiv);

    }

    private findClosestConsiderationNumber(target, rootDiv) {
        if (target.classList.contains('doc-content-innerHTML') || target === rootDiv) {
            return null;
        }

        if ((/^cons_/.test(target.id))) {
            return this.extractConsNumber(target.id);
        }

        const consNum = target.querySelector('[id^="cons_"]');
        if (consNum) {
            return this.extractConsNumber(consNum.id);
        }

        return this.findClosestConsiderationNumber(target.previousElementSibling || target.parentElement, rootDiv);
    }

    private extractConsNumber(consNumber: string): string {
        const cons = consNumber.split('_').join('.').replace('cons.', '').trim();
        const consRepl = cons.replace(/\.[a-zA-Z]/g, (match) => match.replace('.', '').trim());

        return consRepl;
    }
}
