import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { IActionHandler, ITreeOptions, TREE_ACTIONS, TreeModel, TreeNode } from 'angular-tree-component';
import { Subscription } from 'rxjs';

import { AccountService } from '../../account';
import { AppActions } from '../../appActions';
import { AssetActions, AssetService } from '../../asset';
import { AppState, AssetDisplayType, AssetRef, makeAction, SourceDetail, UserFavorite } from '../../models';
import { AlertType } from '../../models/state/commonApp';
import { SearchService } from '../search.service';

export class UserFavoriteNode {
    children: UserFavoriteNode[];
    userFavorite: UserFavorite;
    name: string;
    id: string;
    hasChildren: boolean;
}

@Component({
    selector: 'slx-favorites',
    templateUrl: 'favorites.component.html',
    styleUrls: ['favorites.component.scss'],
})

export class FavoritesComponent {
    private subscription = new Subscription();

    public favoritesForm: FormGroup;
    public nodes;
    public options: ITreeOptions;
    public FolderAssetDisplayType;
    public addFolder: boolean;
    public newFolderName: string;
    public userPrefs;

    private maxPosition = 0;

    constructor(public searchService: SearchService, private accountService: AccountService, public assetService: AssetService, public translate: TranslateService, private formBuilder: FormBuilder, public store: Store<AppState>) {
        searchService.getUserFavorites();
        this.initializeFavoriteTree();
    }

    initializeFavoriteTree() {
        this.subscription
            .add(this.searchService.userFavorites.subscribe(favorites => {
                const data = this.buildFavoritesTree(favorites);
                this.nodes = data;
            }))
            .add(this.accountService.profilePrefs.subscribe(prefs => {
                if (prefs) {
                    this.userPrefs = prefs;
                }
            }));

        this.favoritesForm = this.formBuilder.group({
            folderName: [''],
        });

        this.FolderAssetDisplayType = AssetDisplayType.Folder;

        this.options = {
            actionMapping: {
                mouse: {
                    drop: (tree: TreeModel, node: TreeNode, $event: any, { from, to }) => {
                        //prevent folders from being dropped into other folders
                        if (!(from.data.userFavorite.targetType === AssetDisplayType.Folder && to.dropOnNode)) {
                            TREE_ACTIONS.MOVE_NODE(tree, node, $event, { from, to });
                        }
                    },
                    click: (tree, node, $event) => {
                        if (node.hasChildren) TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
                    },
                },
            },
            allowDrag: (node) => {
                return true;
            },
            allowDrop: (element, { parent, index }) => {
                return (parent !== undefined && parent.parent === null)
                    || ((element.data.userFavorite.targetType !== AssetDisplayType.Folder && parent !== undefined && parent.data.userFavorite.targetType === AssetDisplayType.Folder));
            },
        };
    }

    trackFavorites(favorite: UserFavorite) {
        return favorite.id;
    }

    openAsset(item: UserFavorite, event: MouseEvent) {
        this.assetService.openAsset(this.getRef(item), true, event);
    }

    getRef(item: UserFavorite) {
        return AssetRef.create(item.targetType, item.targetID, { publicationId: item.targetAolID, source: SourceDetail.Favorites });
    }

    toggleFavorite(item: UserFavorite, event: MouseEvent) {
        if (item.targetType === AssetDisplayType.Folder && !this.userPrefs.hideDeleteFolderAlert) {
            this.store.dispatch({
                type: 'alert',
                payload: {
                    type: AlertType.Warning,
                    text: 'rech-delete-complete-folder-text',
                    key: 'rech-delete-complete-folder',
                    actions: [
                        {
                            type: 'no_dispatch',
                            linkKey: 'rech-delete-complete-folder-link',
                            execute: () => {
                                const changedUserPrefs = { ...this.userPrefs, hideDeleteFolderAlert: true };
                                this.assetService.dispatch({ type: AppActions.save_profile_prefs.name, payload: changedUserPrefs });
                                this.assetService.dispatch({ type: AssetActions.toggle_favorite.name, payload: { favorite: item } });
                            },
                        },
                    ],
                    noLinkMargin: true,
                },
            });
        } else {
            this.assetService.dispatch({ type: AssetActions.toggle_favorite.name, payload: { favorite: item } });
        }
        event.preventDefault();
        event.stopPropagation();
    }

    editItem(event, item) {
        item.editMode = true;
        item.icon = 'save';
        item.original = item.title; // Save old title in Case of Escape Action
        setTimeout(() => event.target.closest('.favorite-item').querySelector('.item-edit-input').focus());
    }

    createNewFolder(event) {
        this.addFolder = true;
        setTimeout(() => event.target.closest('.heading').querySelector('.item-edit-input').focus());
    }

    blurred(item) {
        item.editMode = false;
        const favorite: UserFavorite = { id: item.id, title: item.title, targetID: item.targetID, targetType: item.targetType, parentId: item.parentId };
        this.searchService.dispatch({ save: AssetActions.update_favorite.name, payload: { favorite } });
    }

    onChange(event, item) {
        item.title = event.target.value;
    }

    onMoveNode($event) {
        const node = $event.node;
        const parent = $event.to.parent;
        const favorite: UserFavorite = { id: node.userFavorite.id, title: node.userFavorite.title, targetID: node.userFavorite.targetID, targetType: node.userFavorite.targetType, parentId: parent.id };
        this.searchService.dispatch({ save: AssetActions.update_favorite.name, payload: { favorite } });
        this.assetService.dispatch(makeAction({ type: AssetActions.update_order_favorites.name, payload: this.flattenNodes(this.nodes) }));
    }

    folderKeyHandler(event) {
        switch (event.keyCode) {
            case 13:
                // Enter key
                this.onSubmit();
                break;
            case 27:
                // Escape Key
                this.addFolder = false;
                this.newFolderName = '';
                break;
        }
    }

    keyHandler(event, item) {
        switch (event.keyCode) {
            case 13:
                // Enter key
                item.title = event.target.value;
                item.editMode = false;
                const favorite: UserFavorite = { id: item.id, title: item.title, targetID: item.targetID, targetType: item.targetType, parentId: item.parentId };
                this.searchService.dispatch({ save: AssetActions.update_favorite.name, payload: { favorite } });
                break;
            case 27:
                // Escape Key
                item.title = item.original;
                item.editMode = false;
                break;
        }
    }

    getPreview(item) {
        this.assetService.dispatch({ type: AssetActions.preview_assets.name, payload: item.userFavorite.targetID });
    }

    buildFavoritesTree(obj: { [key: string]: any }): UserFavoriteNode[] {
        return Object.keys(obj).reduce<UserFavoriteNode[]>((accumulator, key) => {
            const value = obj[key];
            const node = new UserFavoriteNode();

            if (value != null) {
                node.userFavorite = value;
                node.userFavorite.targetType = AssetDisplayType[node.userFavorite.targetType] || node.userFavorite.targetType;
                node.id = value.id;
                node.name = value.title;

                this.maxPosition = Math.max(this.maxPosition, node.userFavorite.position || 0);

                if (value.targetType === AssetDisplayType.Folder) {
                    node.hasChildren = true;
                    if (value.children) {
                        node.children = this.buildFavoritesTree(value.children);
                    }
                } else {
                    node.hasChildren = false;
                }
            }

            return accumulator.concat(node);
        }, []);
    }

    public onSubmit() {
        if (this.newFolderName) {
            this.maxPosition++;
            const favorite: UserFavorite = { targetType: AssetDisplayType.Folder, title: this.newFolderName };
            this.assetService.dispatch(makeAction({ type: AssetActions.toggle_favorite.name, payload: { favorite } }));
            const nodes = this.flattenNodes(this.nodes);
            this.assetService.dispatch(makeAction({ type: AssetActions.update_order_favorites.name, payload: nodes }));
        }
        this.addFolder = false;
        this.newFolderName = '';
    }

    public flattenNodes(nodes) {
        return nodes.reduce(function (acc, node) {
            acc.push(node);
            if (node.children) {
                acc = acc.concat(...node.children);
            }
            return acc;
        }, []);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
