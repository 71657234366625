import { Component, Input, OnInit } from '@angular/core';

import { AssetAttachment } from '../../../models';
import { AssetService } from '../../asset.service';

@Component({
  selector: 'slx-asset-attachment',
  templateUrl: './asset-attachment.component.html',
  styleUrls: ['./asset-attachment.component.scss'],
})
export class AssetAttachmentComponent implements OnInit {
  @Input() isPrimary: boolean;
  @Input() isEuAttachment: boolean;
  @Input() attachments: AssetAttachment[];
  @Input() attachmentsLoading: {};

  constructor(public assetService: AssetService) { }

  ngOnInit() {
  }

  public downloadAttachment(fileID: string, description: string) {
    this.assetService.dispatch({ fetch: this.assetService.actions.save_doc_attachment.name, payload: { isPrimary: this.isPrimary, fileID, description, isEuAttachment: this.isEuAttachment } });
  }
}
