import { APP_BASE_HREF, Location, LocationChangeListener, LocationStrategy, PlatformLocation } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AppActions } from './appActions';
import { AppState, getLanguageIndependantUrl } from './models';
import { searchRestore } from './recherche';
import { routingDebug } from './utility/utilityFunctions';

export { LocationStrategy };

/**
 * @whatItDoes Use URL for storing application location data.
 * @description
 * `PathLocationStrategy` is a {@link LocationStrategy} used to configure the
 * {@link Location} service to represent its state in the
 * [path](https://en.wikipedia.org/wiki/Uniform_Resource_Locator#Syntax) of the
 * browser's URL.
 *
 * If you're using `PathLocationStrategy`, you must provide a {@link APP_BASE_HREF}
 * or add a base element to the document. This URL prefix that will be preserved
 * when generating and recognizing URLs.
 *
 * For instance, if you provide an `APP_BASE_HREF` of `'/my/app'` and call
 * `location.go('/foo')`, the browser's URL will become
 * `example.com/my/app/foo`.
 *
 * Similarly, if you add `<base href='/my/app'/>` to the document and call
 * `location.go('/foo')`, the browser's URL will become
 * `example.com/my/app/foo`.
 *
 * ### Example
 *
 * {@example common/location/ts/path_location_component.ts region='LocationComponent'}
 *
 * @stable
 */
@Injectable()
export class AppLocationStrategy extends LocationStrategy {
    private _baseHref: string;
    private stateSubscription: Subscription;
    private _state: AppState;

    private afterPushStateFunctions = new Set();

    constructor(
        private store: Store<AppState>,
        private _platformLocation: PlatformLocation,
        private translate: TranslateService,
        @Optional()
        @Inject(APP_BASE_HREF)
        href?: string
    ) {
        super();
        this.stateSubscription = store.subscribe(
            state => (this._state = state)
        );

        if (href == null) {
            href = this._platformLocation.getBaseHrefFromDOM();
        }

        if (href == null) {
            throw new Error(
                `No base href set. Please provide a value for the APP_BASE_HREF token or add a base element to the document.`
            );
        }
        routingDebug('appLocation', 'Setting base href:', href, 'window.location', window.location);
        this._baseHref = href;

        this.translate.onLangChange.subscribe(event => {
            this._baseHref = `${href}${event.lang}`;
            const { relativeUrl, queryString, fragmentString } = getLanguageIndependantUrl(this._platformLocation.pathname, this._platformLocation.search, this._platformLocation.hash);
            routingDebug('appLocation', 'LanguageChange', { originalUrl: this._platformLocation.pathname, navigateTo: relativeUrl });
            this.pushState({fragment: fragmentString}, null, relativeUrl, queryString);
        });
    }

    setBaseHref(value: string) {
        this._baseHref = value;
    }

    onPopState(fn: LocationChangeListener): void {
        this._platformLocation.onPopState(fn);
        this._platformLocation.onHashChange(fn);
    }

    getBaseHref(): string {
        return this._baseHref;
    }

    prepareExternalUrl(internal: string): string {
        return Location.joinWithSlash(this._baseHref, internal);
    }

    path(includeHash: boolean = false): string {
        const pathname = this._platformLocation.pathname + Location.normalizeQueryParams(this._platformLocation.search);
        const hash = this._platformLocation.hash;
        return hash && includeHash ? `${pathname}${hash}` : pathname;
    }

    registerAfterPushStateFunctions(afterPushStateFn: (url:string) => void) {
        this.afterPushStateFunctions.add(afterPushStateFn);
    }

    pushState(state: any, _title: string, specifiedUrl: string, queryParams: string) {
        const { url, restore } = this.planRestoreForAppState(this._state);
        const title = restore.title || _title;
        let resultUrl = specifiedUrl;

        if (specifiedUrl.includes('auto-url-from-store') || (this.hasSearchOrAsset() && specifiedUrl.includes('recherche'))) {
            resultUrl = url;
        }

        let fragment = null;
        if(state && state.fragment){
            fragment = state.fragment;
        }

        resultUrl = this.prepareExternalUrl(resultUrl + Location.normalizeQueryParams(queryParams) + (fragment ? `#${fragment}` : ''));
        this._platformLocation.pushState(restore, title, resultUrl);

        this.afterPushStateFunctions.forEach(fn => fn(resultUrl));
        this.store.dispatch({ type: AppActions.set_main_tab_from_url.name, payload: resultUrl });
        routingDebug('appLocationStrategy', 'state:', 'URL from planRestore():', url, 'specifiedURL:', specifiedUrl, 'resultUrl:', resultUrl);
    }

    private hasSearchOrAsset(): boolean {
        const asset = this._state.recherche.primaryTabState.assetTabs.find(tab => !tab.isLoading);
        return this._state.recherche.transactionID || asset !== undefined ? true : false;
    }

    replaceState(
        state: any,
        _title: string,
        specifiedUrl: string,
        queryParams: string
    ) {
        const { url, restore } = this.planRestoreForAppState(this._state);
        const title = restore.title || _title;

        if (specifiedUrl === 'auto-url-from-store') {
            this.store.dispatch({ type: AppActions.set_main_tab_from_url.name, payload: url });
            this._platformLocation.replaceState(state || restore, title, url);
        } else {
            const externalUrl = this.prepareExternalUrl(
                specifiedUrl + Location.normalizeQueryParams(queryParams)
            );
            this._platformLocation.replaceState(
                state || restore,
                title,
                externalUrl
            );
        }
    }

    forward(): void {
        this._platformLocation.forward();
    }

    back(): void {
        this._platformLocation.back();
    }

    planRestoreForAppState(state: AppState) {
        const { recherche } = state;
        const url = this._platformLocation.pathname || '',
            payload = {},
            title = '',
            type = 'no action',
            params = this._platformLocation.search || '';

        switch (state.home.mainTab) {
            case 'rech':
                return searchRestore(recherche);
            case 'news':
                // TODO back button action
                break;

            case 'biblio':
                break;
        }

        return {
            url,
            params,
            restore: {
                type,
                title,
                payload,
            },
        };
    }
}

export const LOCATION_STRATEGY = { provide: LocationStrategy, useClass: AppLocationStrategy };
