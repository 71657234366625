import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatStepperModule,
    MatTooltipModule,
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { GridsterModule } from 'angular-gridster2';

import { AccountModule, AccountService } from '../account';
import { FieldModule } from '../field';

import { HomeModule } from './../home';
import { DetailsSelectComponent } from './details-select/details-select.component';
import { NewsWizardComponent } from './news-wizard/news-wizard.component';
import { NewsComponent } from './news.component';
import { NewsService } from './news.service';
import { NewsEffects } from './newsEffects';
import { NewslettertypeSelectComponent } from './newslettertype-select/newslettertype-select.component';
import { WizardJournalComponent } from './wizard-journal/wizard-journal.component';
import { WizardSearchAdvancedComponent } from './wizard-search/wizard-search-advanced/wizard-search-advanced.component';
import { WizardSearchValidationComponent } from './wizard-search/wizard-search-validation/wizard-search-validation.component';
import { WizardSearchComponent } from './wizard-search/wizard-search.component';

export const newsRoute = {
    path: 'news',
    component: NewsComponent,
};

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        AccountModule,
        RouterModule,
        FieldModule,
        MatTooltipModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        GridsterModule,
        EffectsModule.forFeature([NewsEffects]),
        MatStepperModule,
        CdkStepperModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatDialogModule,
        MatInputModule,
        HomeModule,
    ],
    entryComponents: [
        NewsWizardComponent,
    ],
    declarations: [
        NewsComponent,
        NewsWizardComponent,
        DetailsSelectComponent,
        NewslettertypeSelectComponent,
        WizardSearchComponent,
        WizardJournalComponent,
        WizardSearchAdvancedComponent,
        WizardSearchValidationComponent,
    ],
    providers: [
        NewsService,
    ],
})
export class NewsModule {
    constructor(private account: AccountService) {
        this.account.addTranslation('news-',
            require('./news.de.yaml'),
            require('./news.fr.yaml')
        );
    }
}
