module.exports = {
	"biblio-journals": "Zeitschriften",
	"biblio-casecollections": "Rechtsprechung",
	"biblio-books": "Bücher",
	"biblio-books-uni": "Lehrbücher",
	"biblio-booksuni": "Lehrbücher",
	"biblio-commentaries": "Kommentare",
	"biblio-serials": "Reihen",
	"biblio-logoUrl": "Zeichen",
	"biblio-abbreviation": "Abkürzung",
	"biblio-titles": "Titel der Publikation",
	"biblio-publisher": "Verlag",
	"biblio-sinceYear": "Ab Jahr",
	"biblio-untilYear": "Bis Jahr",
	"biblio-year": "Jahr",
	"biblio-subscribe": "Newsletter erstellen",
	"biblio-filter": "Suchen",
	"biblio-edition": "Aufl.",
	"biblio-noresult": "In Ihrem Abonnement gibt es leider keine Bücher unter dieser Kategorie",
	"biblio-volume": "Nr.",
	"biblio-casecollections-logoUrl": " ",
	"biblio-casecollections-categorie": "Kanton",
	"biblio-casecollections-abbreviation": "Abkürzung",
	"biblio-casecollections-titles": "Titel der Publikation (Entscheidsammlung)",
	"biblio-casecollections-sinceYear": "Ab Jahr",
	"biblio-casecollections-untilYear": "Bis Jahr",
	"biblio-casecollections-published": "A. Rechtsprechung Bund und Kantone (publiziert)",
	"biblio-casecollections-unpublished": "B. Rechtsprechung Bund und Kantone (nicht publiziert)",
	"biblio-casecollections-publisher": "Gericht / Herausgeber / Verlag",
	"biblio-title": "Titel",
	"biblio-author": "Hrsg. / Autor",
	"biblio-serial-number": "Nr.",
	"biblio-nobook": "Keine Bücher in dieser Kategorie gefunden",
	"biblio-loading": "Inhalte werden geladen",
	"biblio-eu": "EU-Recht"
};