import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { AccountService } from '../../account';
import { AutocompleteService } from '../../asset';
import { AutocompleteParamter, LanguageEnum } from '../../models';
import { AutocompleteType } from '../../models/research';

@Component({
    selector: 'slx-wizard-search',
    templateUrl: './wizard-search.component.html',
    styleUrls: ['./wizard-search.component.scss'],
})
export class WizardSearchComponent {

    @Input() form: FormGroup;

    @Output() addControls = new EventEmitter;
    @Output() removeControls = new EventEmitter;
    public languageOptions = [];
    public selectedLanguage: LanguageEnum;

    private controlDependencies = ['law', 'articleNumber', 'paragraph', 'subParagraph'];

    constructor(public autocompleteService: AutocompleteService, private accountService: AccountService) { }

    ngOnInit() {
        this.initLanguages();
    }

    public get articleOfLawFilterCriterias(): FormArray {
        return this.form.get('articleOfLawFilterCriterias') as FormArray;
    }

    public addControlsToArray(index: number): void {
        this.addControls.emit(index);
    }

    public removeControlsFromArray(index: number): void {
        this.removeControls.emit(index);
    }

    public getSearchTextAutocomplete() {
        return (suchtext: string): Observable<any> => {
            const paramters = [
                new AutocompleteParamter('searchText', suchtext),
                new AutocompleteParamter('language', LanguageEnum[this.form.value.searchLanguage].toLowerCase()),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.RetrievalSearchText, false, paramters);
        };
    }

    public resetDependencies(changedControlName, index) {
        const start = this.controlDependencies.indexOf(changedControlName);
        if (start === -1) {
            return;
        }
        for (let i = start + 1; i < this.controlDependencies.length; i++) {
            (this.articleOfLawFilterCriterias.controls[index] as FormGroup).controls[this.controlDependencies[i]].reset();
        }
    }

    private initLanguages() {
        this.selectedLanguage = this.accountService.langEnum;
        this.languageOptions = [];
        for (const language in LanguageEnum) {
            if (!isNaN(Number(language)) && language !== LanguageEnum.undefined.toString()) {
                const opt = {
                    value: Number(language),
                    viewValue: LanguageEnum[language],
                };
                this.languageOptions.push(opt);
            }
        }

        if (!this.form || !this.form.controls['searchLanguage'].value) {
            this.form.controls['searchLanguage'].setValue(this.selectedLanguage);
        }
    }

}
