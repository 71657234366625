import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material';
import { Router } from '@angular/router';
import { format } from 'date-fns';
import { Subscription } from 'rxjs';

import { isSameDate, toDate } from '../../utility/date-format';
import { EventsService } from '../events.service';
import { EventsActions } from '../eventsActions';

@Component({
    selector: 'slx-events-cal',
    templateUrl: './events-calendar.component.html',
    styleUrls: ['./events-calendar.component.scss'],
})
export class EventsCalendarComponent implements OnInit, AfterViewInit, OnDestroy {

    private subscription: Subscription = new Subscription();
    private dateFormat = 'DD.MM.YYYY';

    public eventDateList: Date[];
    public selectedDate: any;
    public hiddenClearDateFunc: boolean;
    public minDate: Date;
    public maxDate: Date;

    @ViewChild('slxCal') calendar: MatCalendar<Date>;

    constructor(public eventService: EventsService, public router: Router, private changeDetector: ChangeDetectorRef) {
        this.dateFilter = this.dateFilter.bind(this);
        this.hiddenClearDateFunc = true;
    }

    ngOnInit(): void {
        this.subscription
            .add(this.eventService.currentEventsList.subscribe(eList => {
                this.eventDateList = eList.map(e => toDate(e.date));
                const maxDate = this.eventDateList.sort(this.dateComperator);
                this.minDate = maxDate[0];
                this.maxDate = maxDate[maxDate.length - 1];
            }));

    }

    ngAfterViewInit() {
        this.subscription.add(this.eventService.choosenDate.subscribe(date => {
            if (date) {
                this.setSelectedDateForCalendar(toDate(date));
                this.calendar._goToDateInView(toDate(date), 'month');
                this.changeDetector.detectChanges();
            }
            else {
                this.deleteSelectedDateForCalendar();
                // date is only undefined, when first initialiezed.
                if (date === null) {
                    this.calendar._goToDateInView(new Date(), 'month');
                }
                this.changeDetector.detectChanges();
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    //------------------------------------------------------------------
    //- Private Methods ------------------------------------------------
    //------------------------------------------------------------------

    private dateComperator(date1: Date, date2: Date): number {
        if (date1.getTime() > date2.getTime()) return 1;
        if (date1.getTime() < date2.getTime()) return -1;

        return 0;
    }

    private setSelectedDateForCalendar(date: Date) {
        this.selectedDate = date;
        this.hiddenClearDateFunc = false;
    }

    private deleteSelectedDateForCalendar() {
        this.selectedDate = null;
        this.hiddenClearDateFunc = true;
    }

    //------------------------------------------------------------------
    //- Public Methods -------------------------------------------------
    //------------------------------------------------------------------

    public clearSelectedDate() {
        this.eventService.setSelectedDate(null);
        this.deleteSelectedDateForCalendar();
    }

    public dateFilter(date: Date): boolean {
        return this.eventDateList.findIndex(d => isSameDate(d, date)) !== -1;
    }

    public onDateClick(date) {
        const formattedDate = format(date, this.dateFormat);
        this.eventService.setSelectedDate(formattedDate);

        if (this.router.url.includes('/events/details')) {
            this.eventService.dispatch({ type: EventsActions.set_filterFromDetail.name, payload: true });
            this.router.navigate(['/events/current']);
        }
    }
}
