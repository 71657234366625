import { Component } from '@angular/core';

import { LoginService } from './../access';

@Component({
  selector: 'slx-intro',
  templateUrl: './intro.component.html',
})
export class IntroComponent {

    constructor(public loginService: LoginService) { }
}
