export interface Tip {
    id: string;
    topic: Topic;
    priority: number;
    title: string;
    content: string;
    image: string;
}

export interface Topic {
    id: string;
    name: string;
    priority: number;
    parent: Topic;
}


export interface AccountState {
    tipsUser: any[];
    tips: Tip[];
    welcome: any[];
}

export const defaultAccountState = {
    tipsUser: [],
    tips: [],
    welcome: [],
};
