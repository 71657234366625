module.exports = {
	"rech-search": "Allgemein",
	"rech-journal": "Zeitschriften",
	"rech-caseCollection": "Rechtsprechung",
	"rech-casepub": "Rechtsprechung publiziert",
	"rech-casenonpub": "Rechtsprechung nicht publiziert",
	"rech-commentary": "Kommentare",
	"rech-book": "Bücher",
	"rech-author": "Autor",
	"rech-decree": "Erlasse des Bundes",
	"rech-eulaws": "EU-Recht",
	"rech-searchError": "Fehler - Ungültige Suchparameter",
	"rech-rerunError": "Fehler - Ungültige Suchparameter",
	"rech-historyError": "Beim Laden des Suchverlaufs ist ein Fehler aufgetreten",
	"rech-newsletterError": "Beim Laden der Inhalte ist ein Fehler aufgetreten",
	"rech-newFolder": "Neuer Ordner",
	"rech-enter": "Speichern",
	"rech-defer": "Eingaben zurücksetzen",
	"rech-bookmark": "Lesezeichen",
	"rech-searchHistory": "Verlauf",
	"rech-sidebar-actions": "Aktionen",
	"rech-favorites": "Favoriten",
	"rech-hitlist": "Trefferliste",
	"rech-last-hours": "Letzte {{recentHours}} Stunden",
	"rech-older-hours": "Älter als {{recentHours}} Stunden",
	"rech-emptySearch": "Kein Suchtext",
	"rech-no-favorites-yet": "Es wurden noch keine Favoriten angelegt",
	"rech-delete-complete-folder": "Ordner mit gesamtem Inhalt löschen?",
	"rech-delete-complete-folder-text": "Beim Löschen eines Ordners wird der Ordner inklusive dem <strong>gesamten Inhalt</strong> entfernt! <br> Dies kann nicht rückgängig gemacht werden.",
	"rech-delete-complete-folder-link": "Ich habe verstanden, diese Meldung nicht mehr anzeigen.",
	"rech-no-history-entry": "Es wurden noch keine Aktionen (Suchen/Dokumentöffnungen) getätigt, welche im Verlauf angezeigt werden können",
	"rech-to-statistics": "Zur Nutzungsstatistik",
	"rech-searchtext": "Suchtext",
	"rech-law": "Erlass",
	"rech-lawTitle": "Erlasstitel",
	"rech-arcticle-short": "Art.",
	"rech-paragraph-short": "Abs.",
	"rech-subparagraph-short": "Ziff./lit.",
	"rech-dateFrom": "von Datum",
	"rech-dateUntil": "bis Datum",
	"rech-reference": "Referenz",
	"rech-clear": "Eingabe löschen",
	"rech-searching": "Suchen",
	"rech-show-search": "Suche einblenden",
	"rech-display": "Anzeigen",
	"rech-year": "Jahr",
	"rech-case-abbreviation": "Abkürzung / Name Urteilssammlung",
	"rech-case-year": "Jahr / Band",
	"rech-case-page": "Seite / Entscheidnummer",
	"rech-pub-authors": "Hrsg. / Autor",
	"rech-pub-edition": "Aufl.",
	"rech-pub-yearofpublication": "Jahr",
	"rech-pub-publisher": "Verlag",
	"rech-pub-abbreviation": "Abkürzung / Name Zeitschrift",
	"rech-pub-year": "Jahr / Band",
	"rech-pub-issue": "Heft",
	"rech-pub-page": "Seite",
	"rech-pages": "Seiten",
	"rech-published": "Publiziert",
	"rech-unpublished": "Nicht publiziert",
	"rech-dossiernumber": "Geschäfts-Nr.",
	"rech-series": "Reihe",
	"rech-title": "Titel",
	"rech-article": "Artikel",
	"rech-directAccess": "Direktzugriff",
	"rech-language": "Sprache",
	"rech-treaty": "Verträge",
	"rech-interAgreement": "Internationale Abkommen",
	"rech-legislation": "Rechtsvorschriften",
	"rech-celexNumber": "Zugriff per Celex-Nr.",
	"rech-casenumber": "Rechtssache-Nr.",
	"rech-agreementcopy": "Abkommensabschrift",
	"rech-number": "Nummer",
	"rech-celexnr": "Celex Nummer",
	"rech-legislation-type": "Typ",
	"rech-legislation-LegislationAll": "Alle",
	"rech-legislation-Legislation": "Rechtsvorschriften",
	"rech-legislation-LegislationRegulation": "Verordnungen",
	"rech-legislation-LegislationDirective": "Richtlinien",
	"rech-legislation-LegislationDecision": "Beschlüsse",
	"rech-legislation-LegislationNonOpposition": "Entscheidungen",
	"rech-treaty-important": "Wichtigste Verträge",
	"rech-treaty-others": "Weitere Verträge",
	"rech-no-result": "Keine Treffer für Ihre Suche",
	"rech-no-result-but-spellSuggestion": "Keine Treffer für Ihre Suche, meinten Sie vielleicht:",
	"rech-advanced-allselected": "alle ausgewählt",
	"rech-advanced-selected": "ausgewählt",
	"rech-advanced-more": "Mehr Suchkriterien",
	"rech-advanced-less": "Weniger Suchkriterien",
	"rech-practice-area-filter": "Filtern nach Praxisgebieten",
	"rech-multioptions-all": "Alle markieren",
	"rech-multioptions-num-of-nums": "{{num}} von {{nums}} ausgewählt",
	"rech-no-transaction-info": "Diese Transaktion konnte nicht geladen werden",
	"rech-document-category-filter": "Filtern nach Dokumentkategorien",
	"rech-publication-filter": "Filtern nach Publikationen",
	"rech-asset-type-group-Caselaw": "Urteile",
	"rech-asset-type-group-CaselawCollection": "Urteilssammlung",
	"rech-asset-type-group-CaselawReview": "Urteilsbesprechungen",
	"rech-asset-type-group-Commentary": "Kommentare",
	"rech-asset-type-group-EssayAndBook": "Aufsätze / Bücher",
	"rech-asset-type-group-OfficialPub": "Amtliche Dokumente",
	"rech-asset-type-group-Undefined": "Undefiniert",
	"rech-thesaurus": "Übersetzungsthesaurus",
	"rech-thesaurus-off": "aus",
	"rech-thesaurus-on": "ein",
	"rech-thesaurus-manual": "manuell",
	"rech-thesaurus-type-Undefined": "Undefiniert",
	"rech-thesaurus-type-Unknown": "Unbekannt",
	"rech-thesaurus-type-Noun": "Substantiv",
	"rech-thesaurus-type-AdjAdverbPrep": "Adjektiv / Adverb / Präposition",
	"rech-thesaurus-type-Verb": "Verb",
	"rech-thesaurus-type-Phrase": "Satz",
	"rech-thesaurus-type-Pronoun": "Pronomen",
	"rech-thesaurus-type-Adjective": "Adjektiv",
	"rech-thesaurus-type-Adverb": "Adverb",
	"rech-thesaurus-type-Preposition": "Präposition",
	"rech-thesaurus-type-Article": "Artikel",
	"rech-thesaurus-tooltip-start": "Die",
	"rech-thesaurus-tooltip-base-from": "Grundform",
	"rech-thesaurus-tooltip-tranlsation": "Übersetzung",
	"rech-thesaurus-tooltip-find": "findet",
	"rech-thesaurus-tooltip-not-find": "keine weiteren Wortformen",
	"rech-tooltip-title-SearchStandard": "Standard Suche",
	"rech-tooltip-title-EuSearchStandard": "EU Standard Suche",
	"rech-tooltip-title-SearchExtended": "Erweiterte Suche",
	"rech-tooltip-title-FindAuthor": "Suche nach Autor",
	"rech-tooltip-title-FindBook": "Suche nach Buch",
	"rech-tooltip-title-FindPeriodical": "Suche nach Zeitschrift",
	"rech-tooltip-title-FindJurisprudence": "Suche nach Rechtsprechung",
	"rech-tooltip-title-FindCommentary": "Suche nach Kommentar",
	"rech-tooltip-title-LawView": "Erlasse des Bundes",
	"rech-tooltip-title-EuDocViewStandard": "Dokument",
	"rech-tooltip-title-EuDocViewByToc": "Dokument",
	"rech-tooltip-title-EuFindLegalAct": "Suche nach Weitere Rechtsakte",
	"rech-tooltip-title-EuFindAgreement": "Suche nach Internationale Abkommen",
	"rech-tooltip-title-EuFindCaseLaw": "Suche nach EU-Rechtsprechung",
	"rech-tooltip-title-EuFindTreaty": "Suche nach EU-Verträge",
	"rech-tooltip-title-EuFindCelexNumber": "Suche nach EU-Verträge",
	"rech-tooltip-title-EuRefineDocType": "Verfeinerte Suche - Sprachen",
	"rech-tooltip-title-EuRefindeDocLanguage": "Verfeinerte Suche - Typ",
	"rech-tooltip-title-EuRefineHitlistOrder": "Verfeinerte Suche - Sortieren",
	"rech-tooltip-Bücher": "Bücher",
	"rech-tooltip-Dokumentenkategorie": "Dokumentenkategorie",
	"rech-tooltip-Filter_Aktenzeichen": "Dokument",
	"rech-tooltip-Filter_CelexNr": "Celex Nummer",
	"rech-tooltip-Filter_Dokumententyp": "Filter nach Dokumententyp",
	"rech-tooltip-Filter_Gericht": "Gericht",
	"rech-tooltip-Filter_TitleBetreff": "Titel / Betreff",
	"rech-tooltip-Filter_Urteilsdatum": "Urteilsdatum",
	"rech-tooltip-Filter_Vertrag": "Vertrag",
	"rech-tooltip-Filterung_kombiert": "Komibinierte Filterung",
	"rech-tooltip-Praxisgebietsfilter": "Praxisgebietsfilter",
	"rech-tooltip-Publikationsfilter": "Publikationsfilter",
	"rech-tooltip-Rechtssprechung": "Rechtsprechung",
	"rech-tooltip-Sprache_Dokumente": "Sprache der Dokumente",
	"rech-tooltip-Suchsprache": "Suchsprache",
	"rech-tooltip-Suchtext": "Suchtext",
	"rech-tooltip-Thesaurus": "Übersetzungsthesaurus",
	"rech-tooltip-aus": "aus",
	"rech-tooltip-manuell": "manuell",
	"rech-tooltip-verschiedene": "verschiedene",
	"rech-tooltip-verschiedene_publikationen": "verschiedene Publikationen",
	"rech-tooltip-verschiedene_Bücher": "verschiedene Bücher",
	"rech-tooltip-verschiedene_Rechstprechungen": "verschiedene Rechtsprechungen",
	"rech-tooltip-verschiedene_Zeitschriften": "verschiedene Zeitschriften",
	"rech-tooltip-Zeitschrifetn": "Zeitschriften",
	"rech-searchfilter-title": "Suchfilter",
	"rech-searchfilter-use": "Suchfilter anwenden",
	"rech-searchfilter-none": "Keine Suchfilter vorhanden",
	"rech-searchfilter-manage": "Verwalten",
	"rech-searchfilter-delete": "Der Suchfilter wurde gelöscht",
	"rech-searchfilter-delete-cancel": "Rückgängig machen",
	"rech-searchfilter-undelete": "Suchfilter wird wiederhergestellt",
	"rech-searchfilter-create": "Speichern",
	"rech-searchfilter-create-new": "Als neuen Suchfilter speichern",
	"rech-searchfilter-create-overwrite": "Bestehenden Suchfilter aktualisieren",
	"rech-searchfilter-create-placeholder": "Name Suchfilter",
	"rech-searchfilter-save-error": "Fehler beim Speichern des Suchfilters",
	"rech-searchfilter-load-error": "Fehler beim Laden der Suchfilter",
	"rech-searchfilter-apply-error": "Fehler beim Anwenden der Suchfilter",
	"rech-searchfilter-delete-error": "Fehler beim Löschen der Suchfilter",
	"rech-pub-type": "",
	"rech-pub-description": "Titel",
	"rech-pub-practiceAreas": "Praxisgebiete",
	"rech-pub-location": "Ort",
	"rech-pup-series": "Reihen",
	"rech-pub-parentGroup": "Übergeordnet",
	"rech-pup-caseCollections": "Rechtsprechungen",
	"rech-pup-showAll": "Alle Publikationen",
	"rech-pup-selection": "Auswahl",
	"rech-pup-selection-num": "{{num}} von {{nums}}",
	"rech-pup-apply": "Anwenden",
	"rech-pup-saveAsFilter": "Als Suchfilter speichern",
	"rech-pup-selectAll": "Alles auswählen",
	"rech-pup-selectAllBooks": "Alle Bücher auswählen",
	"rech-pup-selectAllCaseCollections": "Alle Rechtsprechungen auswählen",
	"rech-pup-selectAllJournals": "Alle Zeitschriften auswählen",
	"rech-pup-selectAllSerials": "Alle Reihen auswählen",
	"rech-pup-selectAllCommentaries": "Alle Kommentare auswählen",
	"rech-pup-reapplySelection": "Auswahl Wiederherstellen",
	"rech-pup-deselectAll": "Alles abwählen",
	"rech-pup-deselectAllBooks": "Alle Bücher abwählen",
	"rech-pup-deselectAllCaseCollections": "Alle Rechtsprechungen abwählen",
	"rech-pup-deselectAllJournals": "Alle Zeitschriften abwählen",
	"rech-pup-deselectAllSerials": "Alle Reihen abwählen",
	"rech-pup-deselectAllCommentaries": "Alle Kommentare abwählen",
	"rech-pup-resetSelection": "Auswahl Zurücksetzen",
	"rech-pup-no-serial-commentary": "Kommentare ohne Reihe",
	"rech-info-thesaurus-help": "Übersetzungsthesaurus",
	"rech-info-eu_filter-help": "EU-Dokumenttypen",
	"rech-info-eu_case-law-help": "Rechtsprechung",
	"rech-info-eu_treaty-help": "Verträge",
	"rech-info-eu_agreement-help": "Internationale Abkommen",
	"rech-info-eu_legislation-help": "Rechtsvorschriften",
	"rech-info-eu_celex-help": "Celex Nummer",
	"rech-suchfilter-delete": "Der Suchfilter wurde gelöscht",
	"rech-suchfilter-cancel": "Rückgängig machen",
	"rech-hits-limit-news": "Nur die neusten {{limit}} Treffer werden angezeigt",
	"rech-tip-of-the-day": "Tipp des Tages",
	"rech-hide-tip-of-the-day": "'Tipp des Tages' nicht mehr anzeigen",
	"rech-hide-welcome": "'Willkommen bei Swisslex 4.0' nicht mehr anzeigen",
	"rech-tip-previous": "Vorheriger Tipp",
	"rech-tip-next": "Nächster Tipp",
	"rech-toc-year": "Jahrgang",
	"rech-toc-issue": "Heft",
	"rech-validation-commentarySerial": "Reihe",
	"rech-validation-commentaryTitle": "Titel",
	"rech-validation-commentaryLaw": "Erlass",
	"rech-validation-law": "Erlass",
	"rech-validation-lawTitle": "Erlasstitel",
	"rech-validation-author": "Autor",
	"rech-validation-title": "Titel",
	"rech-validation-serial": "Reihe",
	"rech-validation-assetTitle": "Titel",
	"rech-validation-authorName": "Autor",
	"rech-validation-abbreviation": "Abkürzung",
	"rech-validation-caseNumber": "Celex Nummer",
	"rech-search-choose-one": "Wählen Sie mindestens eines der folgenden Felder aus: ",
	"rech-no-toc": "Für diese Publikation (Erlasse des Bundes, nicht-publizierte Rechtsprechung) ist kein Inhaltverzeichnis verfügbar.",
	"rech-no-toc-title": "Kein Inhaltsverzeichnis",
	"rech-button-desc-form-reset": "Eingabeformular auf Standardwerte zurücksetzen",
	"rech-button-desc-empty-workspace": "Gesamter Arbeitsbereich, inklusive Ttrefferliste, geöffnete Dokumente etc. zurücksetzen",
	"rech-button-desc-more-searchcriteria": "Mehr Suchkriterien wie Datum und spezische Filter unterhalb öffnen",
	"rech-button-desc-add-law-artcicle": "Weiteren Gesetzesartikel hinzufügen",
	"rech-button-desc-remove-law-artcicle": "Diesen Gesetzesartikel entfernen",
	"rech-button-desc-searchfilter-save": "Ausgewählte Suchkriterien als Suchfilter speichern",
	"rech-button-desc-searchfilter-manage": "Bestehende Suchfilter anordnen, umbenennen oder löschen",
	"rech-sidebaractions-workbench": "Arbeitsbereich",
	"rech-sidebaractions-close-all-documents": "Alle Dokumente schliessen",
	"rech-sidebaractions-close-primary-documents": "Alle Dokumente im Hauptfenster schliessen",
	"rech-sidebaractions-close-secondary-documents": "Alle Dokumente im sekundären Fenster schliessen",
	"rech-sidebaractions-merge-documents": "Alle Dokumente auf ein Fenster reduzieren.",
	"rech-sidebaractions-same-size-for-docs": "Fenster anordnen",
	"rech-sidebaractions-misc": "Sonstiges",
	"rech-more-nav": "Weitere Links",
	"rech-asset-open": "Dokument in Swisslex ansehen",
	"rech-top-docs-practiceAreas": "Die meistgelesenen Dokumente der letzten sieben Tage, nach Praxisgebieten geordnet",
	"rech-tip": "Hinweis",
	"rech-tip-info": "Klicken Sie auf die Kreise um detailierte Dokumenteninformationen zu sehen",
	"rech-practiceArea-order": "Praxisgebiete <br> nach Anzahl Ansichten",
	"rech-load-pubselect-error": "Fehler beim Laden des Publikationsfilters"
};