import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoginService } from '../access';
import { AccountService } from '../account';

@Component({
  selector: 'slx-bibliothek-static',
  template: ``,
})
export class BiblioStaticComponent implements OnInit, OnDestroy {
    private sub;

    constructor(private router: Router, private route: ActivatedRoute, private loginService: LoginService, private accountService: AccountService) {}

    ngOnInit() {
        const { type, lang } = this.route.snapshot.params;
        const id = this.route.snapshot.params.id.replace('.html', '');

        if (lang) {
            this.accountService.use(lang);
        }

        if (this.router.url.includes('test-biblio')) {
            return;
        }

        this.sub = this.loginService.loggedIn.subscribe(loggedIn => {
            if (loggedIn && type && id) {
                this.router.navigate(['toc', type, id]);
            }
        });
    }

    ngOnDestroy() {
        // remove the biblio-summary information when leaving Static page
        document.querySelector('static-info').innerHTML = '';

        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}

// @Injectable()
// export class BiblioStaticResolver implements Resolve<boolean> {

//     constructor(private authService: AuthService, private router: Router, private accountService: AccountService) {}

//     resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> {
//         // if logged in, redirect to the TOC page
//         const { type, id, lang } = route.params;
//         if (lang) {
//             this.accountService.use(lang);
//         }
//         return this.authService.loggedIn.pipe(map(loggedIn => {
//             if (loggedIn) {
//                 location.href = `/toc/${type}/${id}/`;
//                 // this.router.navigate(['/toc', t1, id])
//                 //     .then((err) => {
//                 //       // tslint:disable-next-line:no-console
//                 //       if (err) console.error(err);
//                 //     });
//             }
//             return true;
//         }));
//     }
// }
