import { Component } from '@angular/core';

import { LoginService } from '../../access';
import { AppActions } from '../../appActions';
import { AssetActions, AssetService } from '../../asset';
import { StoreBackupInformation, StoreBackupType } from '../../models';
import { Alert, AlertType } from '../../models/state/commonApp';
import { determineRandomKey } from '../../utility/utilityFunctions';


@Component({
    selector: 'slx-sidebar-actions',
    templateUrl: './sidebar-actions.component.html',
    styleUrls: [ './sidebar-actions.component.scss' ],
})
export class SidebarActionsComponent {
    constructor(public loginService: LoginService, public assetService: AssetService) {}

    public resetSearch() {
        this.assetService.dispatch({ type: AssetActions.clear_recherche_results.name });
    }

    public moveToPrimary() {
        this.assetService.dispatch({ type: AppActions.move_secondary_to_primary_assets.name });
    }

    public clearAssets(primary: boolean, secondary: boolean) {
        this.assetService.dispatch({ type: AssetActions.clear_assets.name, payload : { primary, secondary}});
    }

    public resetAssetSplit() {
        this.assetService.dispatch({ type: AssetActions.set_asset_split.name, payload: 0.5 });
    }

    public backupStore() {
        const payload: StoreBackupInformation = { key: determineRandomKey(), type: StoreBackupType.CustomBackup, description: 'TODO' };
        const alert: Alert = {type: AlertType.Info, key: `Store succesfully backuped: key = ${payload.key}`, duration: 10};
        this.loginService.dispatch({ type: AppActions.backup_store.name, payload, alert});
    }
}
