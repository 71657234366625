import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms'; // FormBuilder, Validators
import { TranslateService } from '@ngx-translate/core';

import { AutocompleteService } from '../../../asset';
import { requiredValidator } from '../../../field';
import { AutocompleteParamter, AutocompleteType, SearchType } from '../../../models';
import { SearchService } from '../../search.service';
import { SearchFormBase } from '../search-form-base';


@Component({
    selector: 'slx-author',
    template: `<slx-search-form *ngIf="formDescription && searchForm" [formDescription]="formDescription" [searchForm]="searchForm" (searchSubmitted)="onSubmit()" (searchReset)="onReset()"></slx-search-form>`,
})
export class AuthorComponent extends SearchFormBase {

    private abbrIsFromSelection = false;

    constructor(protected searchService: SearchService, protected autocompleteService: AutocompleteService, protected formBuilder: FormBuilder, protected changeDetectorRef: ChangeDetectorRef, protected translate: TranslateService) {
        super(searchService, SearchType.Author, changeDetectorRef, translate);

        // bind autocomplete get url functions (this)
        this.getAuthorAutocomplete = this.getAuthorAutocomplete.bind(this);
        this.getAutorTitleAutocomplete = this.getAutorTitleAutocomplete.bind(this);

        // Create searchForm with Formbuilder
        this.searchForm = this.formBuilder.group({
            authorName: [''],
            assetTitle: [''],
        });

        this.controlDependencies = ['authorName','assetTitle'];

        this.formDescription = {
            formGroups: [
                {
                    controls: [
                        {
                            control: 'slx-text', name: 'AuthorName', type: 'text', placeholder: 'rech-author', formControlName: 'authorName', isSelectionFromDropdown: this.handleIsSelectionFromDropdown,
                            makeAutocomplete: this.getAuthorAutocomplete, autocompleteMinQueryLength: 0, textChanged: this.resetDependencies('authorName'),
                        },
                        {
                            control: 'slx-text', name: 'AssetTitle', type: 'text', placeholder: 'rech-title', formControlName: 'assetTitle',
                            makeAutocomplete: this.getAutorTitleAutocomplete, autocompleteMinQueryLength: 0,
                        },
                    ],
                },
            ],
            controls: {
                resetControl: true,
            },
            searchButtonText: 'rech-display',
        };

        this.validationKeys = [
            'authorName',
            'assetTitle',
        ];
    }

    onSearchFormChanges(state:any) : void {
        this.abbrIsFromSelection = !!state.abbrIsFromSelection;
    }
    handleIsSelectionFromDropdown(selection) {
        this.abbrIsFromSelection = selection;
    }

    getAuthorAutocomplete() {
        return () => {
            const parameters = [
                new AutocompleteParamter('authorName', this.searchForm.value.authorName),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.AuthorName, false, parameters);
        };
    }

    getAutorTitleAutocomplete() {
        return () => {
            const parameters = [
                new AutocompleteParamter('authorName', this.searchForm.value.authorName),
                new AutocompleteParamter('assetTitle', this.searchForm.value.assetTitle),
                new AutocompleteParamter('valueFromSelection', this.abbrIsFromSelection.toString()),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.AuthorTitle, false, parameters);
        };
    }
}
