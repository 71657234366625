import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { JobsService } from './jobs.service';

@Injectable()
export class JobsResolver implements Resolve<boolean> {
    constructor(private jobsService: JobsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (route.params.id) {
            this.jobsService.loadCurrentJob(route.params.id);
        } else {
            this.jobsService.loadJobs();
            this.jobsService.loadTopJobs();
            this.jobsService.setCurrentJob(null);
        }

        // immediate render of the component
        return true;
    }
}
