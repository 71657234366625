import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { format, getHours, isSameDay, isToday } from 'date-fns';
import { Subscription } from 'rxjs';

import { AccountService } from '../../account';
import { toDate } from '../../utility/date-format';
import { EventsService } from '../events.service';

import { createVEvents } from './ics';

@Component({
    selector: 'slx-event-details',
    templateUrl: './event-details.component.html',
    styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent implements OnInit, OnDestroy {

    private paramsSubscription: Subscription;
    private eventSubscription: Subscription;

    public ref: string;
    public returnUrl: string;
    public event: any;
    public beginDate: string;
    public endDate: string;
    public sameDate: string;

    constructor(private eventsService: EventsService, private route: ActivatedRoute, private account: AccountService, public location: Location, private translateService: TranslateService) { }

    ngOnInit() {
        this.paramsSubscription = this.route.params.subscribe(params => {
            this.ref = params['ref'];
            this.returnUrl = '/events/current';
        });

        this.eventSubscription = this.eventsService.singleEvent.subscribe(event => {
            if (Object.keys(event).length > 0) {
                this.event = event;
            }
            this.getDateFrom();
        });
    }

    ngOnDestroy() {
        this.paramsSubscription.unsubscribe();
        this.eventSubscription.unsubscribe();
    }


    //--------------------------------------------------------------------------------------------------------
    // Private Methods
    //--------------------------------------------------------------------------------------------------------

    private getDateFrom() {
        if (this.event) {
            const eventStartDate = toDate(this.event.beginDate);
            const eventEndDate = toDate(this.event.endDate);

            if (isSameDay(eventStartDate, eventEndDate)) {
                this.sameDate = `${format(eventStartDate, 'DD.MM.YYYY')}${this.hasStartHours(eventStartDate)}  ${this.hasEndHours(eventEndDate)}`;
                this.beginDate = null;
                this.endDate = null;
            } else {
                this.sameDate = null;
                this.beginDate = getHours(eventStartDate) > 0 ? `${format(eventStartDate, 'DD.MM.YYYY, HH:mm')} ${this.translateService.instant('events-time')}` : format(eventStartDate, 'DD.MM.YYYY');
                this.endDate = getHours(eventEndDate) > 0 ? `${format(eventEndDate, 'DD.MM.YYYY, HH:mm')} ${this.translateService.instant('events-time')}` : format(eventEndDate, 'DD.MM.YYYY');
            }
        }
    }

    private hasStartHours(date: Date) {
        return getHours(date) > 0 ? `, ${format(date, 'HH:mm')} ${this.translateService.instant('events-time')}` : '';
    }

    private hasEndHours(date: Date) {
        return getHours(date) > 0 ? ` - ${format(date, 'HH:mm')} ${this.translateService.instant('events-time')}` : '';
    }


    //--------------------------------------------------------------------------------------------------------
    // Public Methods
    //--------------------------------------------------------------------------------------------------------

    public downloadCalenderEntry() {
        const event = {
            start: this.event.beginDate,
            end: this.event.endDate,
            title: this.event.title,
            description: (this.event.calendarText || this.event.text),
            location: (this.event.venue ? this.event.venue : '') + (this.event.venue ? ', ' : '') + this.event.city,
            lang: this.account.lang,
            organizer: { name: this.event.organizers }, // email: ?
            url: `https://swisslex.ch/events/details/${this.event.id}`,
            orgUrl: this.event.link,
        };

        createVEvents(event).download(this.event.title);
    }

    public eventIsToday(): boolean {
        if (this.event) {
            return isToday(this.event.beginDate);
        } else {
            return false;
        }
    }
}
