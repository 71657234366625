module.exports = {
	"about-company": "Unternehmen",
	"about-shareholders": "Aktionäre",
	"about-board": "Geschäftsleitung",
	"about-team": "Team",
	"about-direction": "Lageplan",
	"about-contact": "Kontakt",
	"about-question": "Fragen ?",
	"about-answers": "Unsere Hotline beantwortet Ihre Fragen",
	"about-contact-address": "Postanschrift",
	"about-contact-free": "(kostenlos)",
	"about-contact-abroad": "(für Anrufe aus dem Ausland)",
	"about-contact-hotlineTeam": "Unser Hotline-Team nimmt Ihre Anrufe an Werktagen zwischen 08.30 und 17.00 Uhr gerne entgegen.",
	"about-contact-formEmail": "E-Mail",
	"about-contact-message": "Ihre Mitteilung",
	"about-contact-email": "E-Mail",
	"about-contact-number": "Telefon",
	"about-contact-validation-email-invalid": "E-Mail Adress ungültig",
	"about-contact-form-successful": "Mitteilung wurde versendet",
	"about-contact-form-error": "Es gab einen Fehler beim versenden Ihrer Mitteilung",
	"about-contact-enter": "Senden"
};