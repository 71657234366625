import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'slx-info-window',
    templateUrl: './infoWindow.component.html',
    styleUrls: ['./infoWindow.component.scss'],
})
export class InfoWindowComponent {
    public showButton: boolean;
    public mdfilename: string;
    public currentLanguage: string;
    public foldername: string;
    public title: string;

    constructor( @Optional() public dialogRef: MatDialogRef<InfoWindowComponent>, private route: ActivatedRoute, @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any, public translate: TranslateService) {
        if (route.snapshot.data.filename) {
            this.showButton = false;
            this.mdfilename = route.snapshot.data.filename;
            this.foldername = route.snapshot.data.foldername ? route.snapshot.data.foldername : '';
            this.title = this.translate.instant(route.snapshot.data.titleTransString);
        } else if (dialogRef) {
            this.showButton = true;
            this.mdfilename = dialogData.filename;
            this.foldername = dialogData.foldername ? dialogData.foldername : '';
            this.title = this.translate.instant(dialogData.titleTransString);
        }
    }

    get lang() {
        return this.translate.currentLang || this.translate.getDefaultLang();
    }
}
