import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, Input, OnInit, Optional, Pipe, PipeTransform } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { AppService } from '../../../app.service';
import { AppActions } from '../../../appActions';
import { Alert, AlertType } from '../../../models/state/commonApp';
import { AlertWindowComponent } from '../alertWindow/alertWindow.component';


@Pipe({ name: 'getAlertClass' })
export class GetAlertClassPipe implements PipeTransform {
    transform(alert: Alert): any {
        const classObj = {};
        classObj[alert.text ? 'big' : 'small'] = true;
        classObj[alert.type ? alert.type : 'info'] = true;

        return classObj;
    }
}

@Component({
    selector: 'slx-single-alert',
    templateUrl: './singleAlert.component.html',
    styleUrls: ['./singleAlert.component.scss'],
})
export class SingleAlertComponent implements OnInit {
    private dialogWidth = '1200px';
    private dialogHeight = '800px';

    public icon: string;
    public title: string;
    public message: string;

    @Input() alert: Alert;

    constructor(public appService: AppService, private translate: TranslateService, private dialog: MatDialog) {}

    ngOnInit() {
        switch(this.alert.type) {
            case AlertType.Info:
                this.icon = 'info_outline';
                break;
            case AlertType.Success:
                this.icon = 'done';
                break;
            case AlertType.Warning:
                this.icon = 'slx_warning';
                break;
            case AlertType.Error:
                this.icon = 'error';
                break;
            default:
                this.icon = 'info';
                break;
        }

        if (this.alert.key && this.alert.issueId) {
            this.title = '(ID: ' + this.alert.issueId + '): ' + this.translate.instant(this.alert.key, this.alert);
        } else if (this.alert.key) {
            this.title = this.translate.instant(this.alert.key, this.alert);
        } else {
            this.title = '';
        }

        //TODO: CyN: injectActions not fully implemented. Will later be used for AlertWindowDialog
        //as a list of actions a user may do like sending a message to its SlxAdministrator
        const injected = {};
        for (const name in this.alert.injectActions) {
            //injected[name] = this.actionLink(this.alert.injectActions[name]);
        }

        const base = { ...this.alert, ...injected };

        if (this.alert.text) {
            this.message = this.translate.instant(this.alert.text, base);
        }
    }

    private actionLink(action) {
        const text = this.getActionText(action);
        return `<a class="glb-alert-action" rel="${action.id}"">${text}</a>`;
    }

    getActionText(action) {
        return action.linkKey || action.key || action.externUrlKey ? this.translate.instant(action.linkKey || action.key || action.externUrlKey, action) : action.linkText || action.text;
    }

    doAction(ev, action) {
        this.appService.dispatch({ type: AppActions.close_alert.name, payload: this.alert });
        if (action.type === 'no_dispatch') {
            action.execute();
            return;
        }
        this.appService.dispatch(action);
    }

    openDialog() {
        const dialogRef = this.dialog.open(AlertWindowComponent, {
            width: this.dialogWidth, height: this.dialogHeight, panelClass: 'info-window-modal', data: {
                alert: this.alert, type: this.alert.type, icon: this.icon, title: this.title, message: this.message,
            },
        });
    }

    close(event: Event) {
        this.appService.dispatch({ type: AppActions.close_alert.name, payload: this.alert });
        event.stopPropagation();
        return false;
    }
}
