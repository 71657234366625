module.exports = {
	"news-actions": "Aktionen",
	"news-tutorial": "News-Service Tutorial",
	"news-PracticeArea": "Praxisgebiete",
	"news-Journal": "Zeitschrift",
	"news-Search": "Suche",
	"news-CaseLaw": "Rechtsprechung",
	"news-news-tiles": "News-Kacheln",
	"news-search-newsletters": "Such-Newsletter",
	"news-create-newsletter": "News-Kachel erstellen",
	"news-arrange-tiles": "News-Kacheln anordnen",
	"news-search-not-available-as-tile": "Such-Newsletter sind nicht als Kachel verfügbar",
	"news-delivery-on": "E-Mail Zustellung an",
	"news-delivery-off": "E-Mail Zustellung aus",
	"news-search-in-newsletters": "Suchen",
	"news-area-selection": "Auswahl Praxisgebiete",
	"news-journal-selection": "Auswahl Zeitschriften",
	"news-case-law-selection": "Auswahl Gerichte",
	"news-journal-search": "Zeitschriften durchsuchen",
	"news-search-parameters": "Suchparameter",
	"news-search-advanced-parameters": "Erweitere Suchparameter",
	"news-search-validation": "Validierung der Suche",
	"news-delivery": "Zustellung",
	"news-hits": "Treffer",
	"news-next": "Weiter",
	"news-back": "Zurück",
	"news-save": "Speichern",
	"news-save-changes": "Änderungen speichern",
	"news-close": "Schliessen",
	"news-newsletter-name": "Name des Newsletters",
	"news-additionalNewsletter": "Newsletter per E-Mail",
	"news-deliveryOnHits": "Zustellen",
	"news-deliverAlways": "Immer",
	"news-deliverOnlyOnNewHits": "Nur bei neuen Inhalten",
	"news-if-new-content-available": "Aktuelle Ausgabe",
	"news-issue": "Heft",
	"news-year": "Jahr",
	"news-articles": "Artikel",
	"news-validator-selectLanguage": "Bitte wählen Sie mindestens eine Sprache aus",
	"news-validator-file-upload-maxSize": "Die Datei überschreitet die maximal zulässige Gesamtgrösse von 20 MB.",
	"news-validator-file-ulpoad-fileType": "Das Dateiformat ist nicht korrekt. Bitte wählen Sie nur .pdf-Dokumente aus.",
	"news-validation-sucess": "Die Suche war erfolgreich und lieferte",
	"news-validation-sucess-remark": "Anmerkung: Das Zeitfenster ist noch nicht konfiguriert (Zustelloptionen) und die Suche könnte dann weniger oder kein Treffer liefern.",
	"news-validation-noHits": "Die Suche lieferte mit den angegebenen Parametern zum jetzigen Zeitpunkt keine Treffer. Möchten Sie den Newsletter dennoch speichern, um in der Zukunft möglicherweise Treffer für Ihre Eingaben zu erhalten, klicken Sie bitte auf 'Weiter'.",
	"news-validation-error": "Die Suche ist fehlerhaft. Bitte überprüfen Sie Ihre Eingaben und wiederholen Sie diesen Schritt erneut.",
	"news-dateFrom": "von Datum",
	"news-dateTo": "bis Datum",
	"news-deliveryDay": "Zustelltag",
	"news-Monday": "Mo",
	"news-Tuesday": "Di",
	"news-Wednesday": "Mi",
	"news-Thursday": "Do",
	"news-Friday": "Fr",
	"news-Saturday": "Sa",
	"news-Sunday": "So",
	"news-timeWindow": "Zeitfenster",
	"news-days": "Tage",
	"news-day": "Tag",
	"news-daily": "Täglich",
	"news-7days": "7 Tage",
	"news-14days": "14 Tage",
	"news-28days": "28 Tage",
	"news-100days": "100 Tage",
	"news-german": "Deutsch",
	"news-french": "Französisch",
	"news-italian": "Italienisch",
	"news-english": "Englisch",
	"news-no-content": "Zum jetzigen Zeitpunkt sind leider keine Treffer für Ihren Newsletter verfügbar.",
	"news-one-moment": "Augenblick, bitte.",
	"news-no-tiles": "Sie haben noch keine Newsletterkacheln angelegt.",
	"news-no-visible-tiles": "Alle Kacheln sind ausgeblendet. In der Seitenleiste links können Sie die Kacheln über das Aktionenmenü oder das Auge-Icon wieder einblenden.",
	"news-only-search-newsletters": "Sie haben bisher nur Such-Newsletter angelegt. Diese erscheinen nicht als Kachel.",
	"news-not-premium-title": "Swisslex Premium",
	"news-not-premium-message": "Sie sind  Swisslex-Standard Kunde. Für die Benützung des News-Bereichs müssen Sie ein Swisslex-Premium Abonnement abschliessen.",
	"news-newsletter-alert-sent": "Newsletter wird zugestellt",
	"news-newsletter-alert-error": "Newsletter konnte nicht versendet werden",
	"news-open-hitlist": "Als Trefferliste öffnen",
	"news-send-news-now": "Jetzt zustellen",
	"news-settings": "Bearbeiten",
	"news-mail-delivery-off": "Zustellung deaktivieren",
	"news-mail-delivery-on": "Zustellung aktivieren",
	"news-toggle-tile-off": "Kachel ausblenden",
	"news-toggle-tile-on": "Kachel einblenden",
	"news-remove-item-content-menu": "Löschen",
	"news-err-tile-content": "Kachelinhalte konnten nicht geladen werden",
	"news-err-delete": "Die Kachel konnte nicht gelöscht werden",
	"news-err-add-update": "Fehler beim Erstellen oder Speichern der Newskonfiguration",
	"news-tilename": "Name (Kachel / Newsletter)",
	"news-delivery-options": "Zustelloptionen",
	"news-language-options": "Spracheinstellungen",
	"news-language": "Sprache",
	"news-button-desc-new-tile": "Neue Kachel oder Newsletter für einen Suchauftrag, Zeitschriften oder Praxisgebiete erstellen",
	"news-button-desc-arrange-tiles": "Kacheln gleichmässig anordnen. Nur effektiv, wenn Leerraum zwischen Kacheln vorhanden ist",
	"news-button-desc-mail-delivery-on": "E-Mail Zustellung für alle Kacheln aktivieren",
	"news-button-desc-mail-delivery-off": "E-Mail Zustellung für alle Kacheln dekaktivieren",
	"news-slider-desc-mail-delivery-on": "E-Mail Zustellung für diese Kachel aktivieren / deaktivieren",
	"news-assistant": "News-Assistent"
};