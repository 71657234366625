import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { JobsService } from '../jobs.service';

@Component({
    selector: 'slx-single-job',
    templateUrl: './singleJob.component.html',
    styleUrls: ['./singleJob.component.scss'],
})

export class SingleJobComponent implements OnInit, OnDestroy {

    private jobSubscription: Subscription;
    public currentJob;

    constructor(private jobsService: JobsService, private route: ActivatedRoute) {

    }

    ngOnInit() {
        this.jobSubscription = this.jobsService.currentJob.subscribe(cj => {
            this.currentJob = cj;
        });
    }

    ngOnDestroy(): void {
        this.jobSubscription.unsubscribe();
    }

}
