import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';


import { BibliothekService } from './bibliothek.service';


@Injectable()
export class BibliothekResolver implements Resolve<boolean> {

    constructor(private bibliothekService: BibliothekService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // immediate render of the component
        return true;
    }
}
