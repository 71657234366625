import { StoreBackup } from '../storeBackup';

export interface AccessState {
    loggedIn: boolean;
    loggedOut: boolean;
    hasValidAccesToken: boolean;
    accessTokenBegin?: string;
    authTokenExpirationDate: Date;
    username: string;
    fullName: string;
    firstName: string;
    lastName: string;
    userId: string;
    userLanguage: string;
    departmentId: string;
    isUniUser: boolean;
    userLoginRights: string[];
    userFunctionRights: string[];
    userContentRights: string[];
    customStoreBackups?: StoreBackup[];
}

export const defaultAccessState: AccessState = {
    loggedIn: false,
    loggedOut: false,
    hasValidAccesToken: false,
    authTokenExpirationDate: new Date(localStorage.getItem('expirationDate')),
    username: '',
    fullName: '',
    firstName: '',
    lastName: '',
    userId: '',
    userLanguage: '',
    departmentId: '',
    isUniUser: false,
    userLoginRights:[],
    userFunctionRights:[],
    userContentRights:[],
};
