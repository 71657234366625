import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SortablejsOptions } from 'angular-sortablejs/dist';
import { Observable ,  Subscription } from 'rxjs';

import { SearchService } from '../search.service';
import { SearchActions } from '../searchActions';

const debug = require('debug')('searchfilter');

@Component({
    selector: 'slx-searchfilters',
    templateUrl: './searchfilters.component.html',
    styleUrls: ['./searchfilters.component.scss'],
})
export class SearchfiltersComponent implements OnDestroy {
    private subscription: Subscription = new Subscription();
    private lastDeletedItem: any;

    public editMode = false;
    public showUndoMessage: Boolean = false;
    public userSearchFilters: Array<any>;
    public advancedSearch: boolean;
    public options: SortablejsOptions;
    public sorting: Boolean = false;

    public undeleteInProgress : Observable<boolean> = this.searchService.isActionInProgress(SearchActions.undelete_user_search_filter.name);

    constructor(public dialogRef: MatDialogRef<SearchfiltersComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public searchService: SearchService) {

        this.subscription
            .add(this.searchService.userSearchFilters.subscribe(filters => this.userSearchFilters = filters ))
            .add(this.searchService.advancedSearch.subscribe(advancedSearch => this.advancedSearch = advancedSearch ));

        this.options = {
            animation: 0,
            onStart: () => { this.sorting = true;},
            onEnd: () => { this.sorting = false;},
            onUpdate: (event: object) => {
                this.searchService.dispatch({ save: SearchActions.update_order_user_search_filter.name, payload: this.userSearchFilters });
            },
        };
    }

    ngOnDestroy() {
       this.subscription.unsubscribe();
    }

    editItem(event, item) {
        item.editMode = true; item.icon = 'save'; item.original = item.title; // Save old title in Case of Escape Action
        setTimeout(() => event.target.parentNode.parentNode.querySelector('.searchfilter_input').focus());
    }

    onChange(event, item) {
        item.title = event.target.value;
    }

    keyHandler(event, item) {
        switch (event.keyCode) {
            case 13:
                // Enter key
                item.title = event.target.value; item.editMode = false;
                this.searchService.dispatch({ save: SearchActions.update_title_user_search_filter.name, payload: { id: item.id, title: item.title } });
                break;
            case 27:
                // Escape Key
                item.title = item.original;
                item.editMode = false;
                break;
        }
    }

    blurred(event, item) {
        item.editMode = false;
        this.searchService.dispatch({ save: SearchActions.update_title_user_search_filter.name, payload: { id: item.id, title: item.title } });
    }

    undoRemove() {
        this.showUndoMessage = false;
        this.searchService.dispatch({ save: SearchActions.undelete_user_search_filter.name, payload: { id: this.lastDeletedItem.id } });
    }

    removeItem(event, item) {
        this.lastDeletedItem = { ...item };

        this.showUndoMessage = true;
        this.searchService.dispatch({ save: SearchActions.delete_user_search_filter.name, payload: item.id });
    }
}
