import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { first, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

import { createNewsUrl, deleteNewsUrl, sendNewsNowUrl, SlxHttp, updateNewsUrl } from '../access';
import { BibliothekActions } from '../bibliothek';
import * as config from '../config';
import { catchErrorForAction, getResultNameFromAction, makeAction, SlxAction } from '../models';
import { AlertType } from '../models/state/commonApp';
import { SearchActions } from '../recherche';

import { NewsService } from './news.service';
import { NewsActions } from './newsActions';

@Injectable()
export class NewsEffects {
    constructor(private actions$: Actions, private newsService: NewsService, private slxHttp: SlxHttp ) { }

    @Effect() fetchTileContent = this.actions$.pipe(
        ofType(NewsActions.fetch_tile_content.name),
        //tap(_ => console.log(_)),
        mergeMap((action: SlxAction) => {
            let id = action.payload;
            if(action.payload.forceReload){
                id = action.payload.id;
                this.newsService.forceReloadNews(id);
            }
            return this.newsService.getNews(id).pipe(
                first(),
                //tap(_ => console.log('getNewsEffect')),
                map(result => makeAction({ result: NewsActions.fetch_tile_content.name, payload: { id, result } })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'news-err-tile-content' })
            );
        })
    );

    @Effect({ dispatch: false }) expandSearch = this.actions$.pipe(
        ofType(SearchActions.create_search_abo.name),
        withLatestFrom(this.newsService.store.select(state => state.recherche)),
        tap(([action,state]) => {
            this.newsService.searchAbosToCreate.push(state.search);
        })
    );

    @Effect({ dispatch: false }) expandJournal = this.actions$.pipe(
        ofType(BibliothekActions.create_news_abo.name),
        tap((action: SlxAction) => {
            this.newsService.journalAbosToCreate.push(action.payload);
        })
    );

    @Effect() removeTile = this.actions$.pipe(
        ofType(NewsActions.remove_tile.name),
        mergeMap((action: SlxAction) =>
            this.slxHttp.delete(`${deleteNewsUrl}${action.payload}`).pipe(
                map(result => makeAction({ result: NewsActions.remove_tile.name, payload: result })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'news-err-delete' })
            )
        )
    );

    @Effect() addTile = this.actions$.pipe(
        ofType(NewsActions.add_new_tile.name),
        mergeMap((action: SlxAction) =>
            this.slxHttp.post(`${createNewsUrl}`, action.payload).pipe(
                map(result => makeAction({ result: NewsActions.add_new_tile.name, payload: { ...action.payload, id: result } })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'news-err-add-update' })
            )
        )
    );

    @Effect() addTileResult = this.actions$.pipe(
        ofType(getResultNameFromAction(NewsActions.add_new_tile)),
        map((action: SlxAction) =>
            makeAction({ type: NewsActions.fetch_tile_content.name, payload: action.payload.id })
        )
    );

    @Effect() updateTileConfiguration = this.actions$.pipe(
        ofType(NewsActions.update_tile_configuration.name),
        mergeMap((action: SlxAction) =>
            this.slxHttp.put(`${updateNewsUrl}`, action.payload).pipe(
                map(result => makeAction({ result: NewsActions.update_tile_configuration.name, payload: action.payload.id })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'news-err-add-update' })
            )
        )
    );

    @Effect() updateAndFetchTileConfiguration = this.actions$.pipe(
        ofType(NewsActions.update_and_fetch_tile_configuration.name),
        mergeMap((action: SlxAction) =>
            this.slxHttp.put(`${updateNewsUrl}`, action.payload).pipe(
                map(result => makeAction({ result: NewsActions.update_and_fetch_tile_configuration.name, payload: action.payload.id })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'news-err-add-update' })
            )
        )
    );

    @Effect() updateAndFetchTileConfigurationSucess = this.actions$.pipe(
        ofType(getResultNameFromAction(NewsActions.update_and_fetch_tile_configuration)),
        map((action: SlxAction) =>
            makeAction({ type: NewsActions.fetch_tile_content.name, payload: {id: action.payload, forceReload: true} })
        )
    );

    @Effect() sendNewsNow = this.actions$.pipe(
        ofType(NewsActions.send_news_now.name),
        mergeMap((action: SlxAction) =>
            this.slxHttp.get(`${sendNewsNowUrl}${action.payload}`).pipe(
                map(result => makeAction({ result: NewsActions.send_news_now.name })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'news-newsletter-alert-error' })
            )
        )
    );
}
