import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { euTreatyDropdownBaseUrl } from '../../../access';
import { AccountService } from '../../../account';
import { SearchType } from '../../../models';
import { SearchService } from '../../search.service';
import { SearchFormBase } from '../search-form-base';

const debug = require('debug')('search');

@Component({
    selector: 'slx-eu-treaty-search',
    template: `
        <form novalidate class="slx-form" [formGroup]="searchForm" (ngSubmit)="onSubmit()" autocomplete="off">
            <h4 translate="rech-treaty"></h4>
            <div class="slx-form-row relative">
                <slx-select class="slx-field-small" type="dropdown" formControlName="treatyId" [optionGroups]="treatyOptionGroupLists.optionGroups"></slx-select>
                <slx-info-button filename="eu_treaty-help" foldername="Eu" titleTransString="rech-info-eu_treaty-help" class="in-form"></slx-info-button>
            </div>
            <div class="controls">
                <button class="slx-icn-btn r-mrgn" type="button" (click)="onReset()" [title]="'rech-button-desc-form-reset' | translate"><i class="material-icons">clear</i><span translate="rech-clear"></span></button>
                <button class="slx-icn-btn" type="submit"><i class="material-icons">search</i><span translate="rech-display"></span></button>
            </div>
        </form>
    `,
    styles: [`
        .controls {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
        }
        h4 {
            margin: 0;
        }
    `],
})
export class EuTreatyComponent extends SearchFormBase {


    public treatyOptionGroupLists: any;
    public treatyOptionGroups: any;
    public selectedTreaty: string;
    public treatyImportantOptionGroups: any;
    public treatyOtherOptionGroups: any;

    private language;
    private onLangChangedSubscription: Subscription;

    constructor(protected searchService: SearchService, private accountService: AccountService, protected formBuilder: FormBuilder, protected changeDetectorRef: ChangeDetectorRef, protected translate: TranslateService) {
        super(searchService, SearchType.EuTreaty, changeDetectorRef, translate);

        this.searchForm = this.formBuilder.group({
            treatyId: [''],
            language: '',
        });

        this.treatyOptionGroupLists = {
            optionGroups: [],
        };
        this.treatyOptionGroups = {
            name: '',
            options: [],
        };
    }

    // overwrites the SearchFormBase.ngOnInit
    ngOnInit(){
        this.searchFormSubscription = this.searchService.store.select(state => state.recherche.searchForms.EuTreaty).subscribe(state => {
            this.selectedTreaty = state.treatyId;
            if(!state.treatyId) {
                this.initTreaties();
            }
        });
        this.language = this.accountService.lang;
        this.onLangChangedSubscription = this.accountService.onLangChange.subscribe(res => {
            this.language = this.accountService.lang;
            this.initTreaties();
            this.searchForm.controls['language'].setValue(this.language);
        });
        this.initTreaties();
    }

    ngOnDestroy(){
        this.onLangChangedSubscription.unsubscribe();
        super.ngOnDestroy();
    }

    initTreaties() {
        this.searchService.dropdownSearch(`${euTreatyDropdownBaseUrl}${this.language}`).subscribe(res => {
            this.treatyOptionGroupLists = {
                optionGroups: [],
            };
            this.treatyOptionGroups = {
                name: '',
                options: [],
            };

            for (const option of res.importantTreatyNames) {
                const opt = {
                    value: option.key,
                    viewValue: option.value,
                };
                this.treatyOptionGroups.options.push(opt);
            }
            this.treatyOptionGroups.name = 'rech-treaty-important';
            this.treatyOptionGroupLists.optionGroups.push(this.treatyOptionGroups);

            this.treatyOptionGroups = {
                name: '',
                options: [],
            };

            for (const option of res.otherTreatyNames) {
                const opt = {
                    value: option.key,
                    viewValue: option.value,
                };
                this.treatyOptionGroups.options.push(opt);
            }

            this.treatyOptionGroups.name = 'rech-treaty-others';
            this.treatyOptionGroupLists.optionGroups.push(this.treatyOptionGroups);
            debug('treaty options', this.treatyOptionGroupLists);

            if (!this.selectedTreaty || this.selectedTreaty === '00000000-0000-0000-0000-000000000000') {
                this.selectedTreaty = this.treatyOptionGroupLists.optionGroups[0].options[0].value;
            }

            this.searchForm.controls['treatyId'].setValue(this.selectedTreaty);
        });
    }
}
