import { CommonModule, Location } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { AuthConfig, AuthInterceptor, AuthService } from '@slx/authentification';

import * as config from '../config';

import { AccessEffects } from './accessEffects';
import { LoginService } from './login.service';
import { NotFoundResolver } from './notFound.resolver';
import { StoreRestoreComponent } from './storeRestore/storeRestore.component';

@Injectable()
export class SlxAuthConfig implements AuthConfig {
  public loginServerUrl = config.apiAuthentificationUrl;
  public requiredLoginRight = 'Swisslex';
  public maxAccessTokenFails = config.maxAccesTokenFails;
  public maxWaitForRefreshInSeconds = config.maxWaitForRefreshInSeconds;
  public maxRefreshTries = config.maxRefreshTries;
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    EffectsModule.forFeature([AccessEffects]),
  ],
  declarations: [
      StoreRestoreComponent,
  ],
  exports: [],
  providers: [
      //AuthGuard,
      AuthService,
      LoginService,
      Location,
      NotFoundResolver,
      {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
      },
      {
        provide: AuthConfig,
        useClass: SlxAuthConfig,
      },
  ],
})
export class AccessModule { }

export const storeRestoreRoute = {
    path: 'storerestore',
    component: StoreRestoreComponent,
};

