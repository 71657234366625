import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable ,  Subject ,  Subscription } from 'rxjs';
import { filter} from 'rxjs/operators';

import { getCourtsForNewsUrl, getNewsConfigurationsUrl, getPracticeAreasForNewsUrl, getPrintJournalsForNewsUrl, SlxHttp, validateSearchNewsUrl } from '../access';
import { AccountService } from '../account';
import { AppActions } from '../appActions';
import { AppState, HitlistType, isActionInProgress, makeAction, RefType, SlxAction, Tile } from '../models';

import { SearchService } from './../recherche';
import { NewsActions } from './newsActions';


@Injectable()
export class NewsService implements OnDestroy {

    private initialInfoSubscription: Subscription;
    public loadInfos = false;
    public actions = NewsActions;
    public searchAbosToCreate = [];
    public journalAbosToCreate = [];
    public searchValidation = new Subject<any>();
    public tiles: Observable<Array<Tile>> = this.store.select((state) => state.news.tiles);
    public tileContent: Observable<any> = this.store.select((state) => state.news.tileContent);
    public newsPracticeAreas: Observable<any> = this.store.select((state) => state.news.practiceAreas);
    public newsCourts: Observable<any> = this.store.select((state) => state.news.courts);
    public newsJournals: Observable<any> = this.store.select((state) => state.news.journals);
    public practiceAreaFilters: Observable<any> = this.store.select((state) => state.recherche.practiceAreaFilters || []);
    public documentCategoryFilters: Observable<any> = this.store.select((state) => state.recherche.documentCategoryFilters);
    public sidebarClosed: Observable<boolean> = this.store.select((state) => state.news.sidebarClosed);

    constructor(public store: Store<AppState>, private slxHttp: SlxHttp, private accountService: AccountService, private searchService: SearchService, private translateService: TranslateService) {


        this.initialInfoSubscription = merge(
            this.translateService.onLangChange,
            this.store.select((state: AppState) => {
                const loggedIn = state.access.loggedIn;
                const loggedOut = state.access.loggedOut;
                const mainTabRech = state.home.mainTab === 'news';
                const restoreIsInProgress = isActionInProgress(state, AppActions.restore_store.name);
                //  const storeRestored = state.app.storeRestored;
                const newsLoaded = state.news.tiles.length > 0;
                this.loadInfos = mainTabRech && loggedIn/*&& !restoreIsInProgress && !newsLoaded*/;
                return this.loadInfos;
            })
        ).pipe(
            filter(x =>  { return !!x;}) // onLangChange: x = {lang: string}, onStore x: boolean
        ).subscribe(x => {
            if (this.loadInfos) {
                this.fetchTilesConfiguration();
                this.fetchNewsPracticeAreas();
                this.fetchNewsJournals();
                this.fetchCourts();
            }
        });
    }

    public getNews(id: string): Observable<any[]> {
        return this.searchService.getNews(id); // cache for news is in searchService because of accessibility
    }
    public forceReloadNews(id: string){
        this.searchService.forceReloadNews(id);
    }

    public dispatch(action) {
        return this.store.dispatch(makeAction(action as SlxAction));
    }

    public validateSearch(sarchFilters: any) {
        this.slxHttp.post(validateSearchNewsUrl, sarchFilters).subscribe(validationMessage => this.searchValidation.next(validationMessage));
    }

    private fetchTilesConfiguration() {
        const url = `${getNewsConfigurationsUrl}?language=${this.accountService.lang}`;
        this.slxHttp.get(url).subscribe(payload => this.dispatch({ fetch: NewsActions.set_tile_configuration.name, payload }));
    }

    private fetchNewsPracticeAreas() {
        const url = `${getPracticeAreasForNewsUrl}?language=${this.accountService.lang}`;
        this.slxHttp.get(url).subscribe(payload => this.dispatch({ fetch: NewsActions.set_news_practice_areas.name, payload }));
    }

    private fetchCourts() {
        const url = `${getCourtsForNewsUrl}?language=${this.accountService.lang}`;
        this.slxHttp.get(url).subscribe(payload => this.dispatch({ fetch: NewsActions.set_news_courts.name, payload}));
    }

    private fetchNewsJournals() {
        const url = `${getPrintJournalsForNewsUrl}?language=${this.accountService.lang}`;
        this.slxHttp.get(url).subscribe(payload => this.dispatch({ fetch: NewsActions.set_news_journals.name, payload }));
    }

    public openInRecherche(newsID: string, assetRef: RefType, shouldOpenInPrimary: boolean) {
        const hitlist = [HitlistType.News, newsID];
        let primary, secondary;

        if (!assetRef) {
            primary = [];
            secondary = [];
        } else {

            primary = shouldOpenInPrimary ? [assetRef] : [];
            secondary = shouldOpenInPrimary ? [] : [assetRef];
        }

        this.dispatch({
            type: AppActions.present_results.name,
            payload: {
                hitlist,
                primary,
                secondary,
            },
        });
    }

    ngOnDestroy(): void {
        this.initialInfoSubscription.unsubscribe();
    }
}

