import { enhanceActionsObject, HomeState } from '../models';

const debug = require('debug')('field-select');

export const HomeActions = {

    toggle_mobile_sidebar(state: HomeState, { payload }) {
        return {
            ...state,
            activeMobileSidebarComponent: payload,
        };
    },

    set_initial_main_tab_urls(state: HomeState, { payload }) {
        return {
            ...state,
            tabUrl: payload,
        };
    },

    reload_language_dependent_data(state: HomeState) {
        return state;
    },

    get_faq_by_topic_id(state: HomeState, { payload, result }) {
        return result ? {
            ...state,
            faq: {
                ...state.faq,
                ...payload,
            },
        }
            : state;
    },

    // for home Tiles
    get_events_for_home_tile(state: HomeState) {
        return state;
    },

    get_news_for_home_tile(state: HomeState, { payload, result }) {
        return result ? { ...state, homeTileNews: payload } : state;
    },

    get_top_documents_for_home_tiles(state: HomeState) {
        return state;
    },

    get_top_documents_for_home_tiles_result(state: HomeState, { payload }) {
        return {
            ...state,
            topDocuments: payload,
        };
    },

    set_home_tile_start_page_events(state: HomeState, { payload }) {
        return {
            ...state,
            eventsForHomeTiles: {
                ...state.eventsForHomeTiles,
                startPageEvents: payload,
            },
        };
    },

    set_home_tile_more_events(state: HomeState, { payload }) {
        return {
            ...state,
            eventsForHomeTiles: {
                ...state.eventsForHomeTiles,
                moreEvents: payload,
            },
        };
    },

    get_top_jobs_for_start_page(state: HomeState, { payload }) {
        return state;
    },

    get_top_jobs_for_start_page_result(state: HomeState, { payload }) {
        return {
            ...state,
            jobsForHomeTiles: {
                ...state.jobsForHomeTiles,
                topJobs: payload,
            },
        };
    },

    set_jobs_for_start_page(state: HomeState, { payload }) {
        return {
            ...state,
            jobsForHomeTiles: {
                ...state.jobsForHomeTiles,
                currentJobs: payload,
            },
        };
    },

    send_contact_form(state: HomeState) {
        return state;
    },
};
enhanceActionsObject(HomeActions);
