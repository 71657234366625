import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { AssetList, AssetListEntry, AssetType, GroupedAssetList, RelatedAssetsModel } from '../../../models';
import { AssetService } from '../../asset.service';

export class DisplayRelatedAssetEvent {
    event: MouseEvent;
    entry: AssetListEntry;
}

@Component({
    selector: 'slx-related-assets',
    templateUrl: 'relatedAssets.component.html',
    styleUrls: ['relatedAssets.component.scss'],
})
export class RelatedAssetComponent implements OnInit, OnChanges {
    @Input() relatedAssets: RelatedAssetsModel;
    @Input() assetType: AssetType;
    @Input() isPrimary: boolean;
    @Input() showLabel: boolean;
    @Input() translationPrefix= '';
    @Input() titleTranslationString: string;
    @Input() noElementTranslationString: string;


    @Output() displayRelatedAsset = new EventEmitter<DisplayRelatedAssetEvent>();

    public showOverview = true;
    public showAll = false;
    public groupedAssetListToShow: GroupedAssetList;
    public hasAssets: boolean;

    //public tooltipDelay = 150;

    selectedItem: any;

    constructor(public assetService: AssetService) { }

    ngOnInit(): void { }

    ngOnChanges(): void {

        // reset
        this.showOverview = true;
        this.showAll = false;
        this.groupedAssetListToShow = undefined;


        if (this.relatedAssets) {

            this.hasAssets = this.relatedAssets.assetListGroups.findIndex(g => g.totalCount > 0) > -1;

            // exactly one group => show all
            if (this.hasAssets && this.relatedAssets.assetListGroups.length === 1) {
                this.showAll = true;
                this.showOverview = false;
                this.groupedAssetListToShow = undefined;
            }
        }


    }

    openRelatedAsset(entry: AssetListEntry, event: MouseEvent): void {
        this.displayRelatedAsset.emit({ event, entry});
    }

    showListOverview(): void {
        this.showOverview = true;
        this.showAll = false;
        this.groupedAssetListToShow = undefined;
    }

    showDetails(groupedAssetList: GroupedAssetList): void {
        this.groupedAssetListToShow = groupedAssetList;
        this.showOverview = false;
    }

    showAllEntries(): void {
        this.showAll = true;
        this.showOverview = false;
    }

    toggleItem(entry: AssetList): void {
        entry.toggled = !entry.toggled;
        entry.maxHeight = entry.toggled ? entry.listEntries.length * 26 : 0;
    }
}
