import { FormGroup } from '@angular/forms';
import { ICellRendererParams } from 'ag-grid-community';

export const emptyGuid ='00000000-0000-0000-0000-000000000000';
export const responsiveThreshold = 768; //px

export function isEmtpyGuid(guid: string): boolean {
    return guid === emptyGuid;
}

export function isResponsive(): boolean {
    return window.innerWidth <= responsiveThreshold;
}
export const routingDebug = require('debug')('routing');

export function calculatePreviewPosition(target, defaultWidth: number, defaultHeight: number) {
    const size = target.getBoundingClientRect();    // Get all Position Information about target
    let left, top, right, bottom, width;           // Wether tooltip can be positioned in that areas
    let previewSizeAndPosition: any = {};

    width = isResponsive() ? 280 : defaultWidth;

    // Since bottom and right are buggy, thoe values are calculated manually from the elements Information
    top = size.top - defaultHeight < window.innerHeight;
    left = size.left - width > 0;
    right = size.left + size.width + width < window.innerWidth;
    bottom = window.innerHeight - (size.top + size.height + defaultWidth) > 0;

    if (bottom) {
        if (right) {
            return previewSizeAndPosition = {
                rel: target.rel,
                top: size.top,
                left: size.left + size.width,
                width: width,
            };
        }

        else if (left) {
            return previewSizeAndPosition = {
                rel: target.rel,
                top: size.top,
                left: size.left - width - 20,
                width: width,
            };
        }

        else {
            return previewSizeAndPosition = {
                rel: target.rel,
                top: size.top + size.height,
                left: window.innerWidth / 2 - width / 2,
                width: width,
            };
        }
    }

    else if (top) {
        if (right) {
            return previewSizeAndPosition = {
                rel: target.rel,
                bottom: window.innerHeight - size.bottom,
                left: size.left + size.width,
                width: width,
            };
        }

        else if (left) {
            return previewSizeAndPosition = {
                rel: target.rel,
                bottom: window.innerHeight - size.bottom,
                left: size.left - width - 20,
                width: width,
            };
        }

        else {
            return previewSizeAndPosition = {
                rel: target.rel,
                bottom: window.innerHeight - size.bottom + size.height,
                left: window.innerWidth / 2 - width / 2,
                width: width,
            };
        }
    }
}

export function gridButtonRenderer(params: ICellRendererParams): HTMLElement {
    const button = document.createElement('button');
    const translate = params.colDef['headerName'];
    const text = params.context.translate.instant(translate);
    const icon = params.colDef['field'];

    button.innerHTML = `<i class="material-icons">${icon}</i><span>${text}</span>`;
    button.classList.add('slx-icn-btn', 'inside-grid', 'small-icon');
    return button;
}

export function markFormAsTouched(form: FormGroup){
    Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        control.markAsTouched({ onlySelf: true });
    });
}

// condition true: array[1] & condition false: array[0]
export function splitArray<T>(array: T[], condition: (val) => boolean): T[][] {
    return array.reduce((acc: T[][], currVal: T) => {
        acc[condition(currVal) ? 1 : 0].push(currVal);
        return acc;
    }, [[], []]);
}

const MAX_UINT32 = 4294967295;
export function safeRandom() {
    if (window.crypto) {
        const arr = new Uint32Array(1);
        window.crypto.getRandomValues(arr);
        return arr[0] / (MAX_UINT32 + 1);
    }
    return Math.random();
}

export function determineRandomKey(){
    const rand = safeRandom();
    return rand.toString(36).substr(2, 10);
}

export function createHrefWrapperForCellrender(value: string, link: string): HTMLAnchorElement{
    const aElement = document.createElement('a');
    aElement.innerHTML = aElement.title = value;
    aElement.setAttribute('href', link);
    aElement.addEventListener('click', (event) => {
        if (!event.ctrlKey) {
            event.preventDefault();
        }
    });

    return aElement;
}

export function cleanDownloadFileName(fileName: string): string {
    return fileName.replace(/(\\)|(\/)|(\:)|(\*)|(\?)|(\")|(\<)|(\>)|(\|)/gi,'_');
}
