import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginState } from '@slx/authentification';

import { LoginService } from './access';
import { AccountService } from './account';
import { AppActions } from './appActions';
import { SearchService } from './recherche';
import { isResponsive } from './utility/utilityFunctions';


@Component({
    selector: 'slx-lead',
    template: `
    <slx-header [showPrimary]="!primaryOff"></slx-header>
    <slx-alerts></slx-alerts>
    <slx-tooltip></slx-tooltip>
    <slx-text-selection></slx-text-selection>
    <slx-markdown class="please-login" *ngIf="!pleaseLoginOff && (loginService.routeNeedsLogin | async) && (loginService.loginResult | async).state !== loginState.LoggedIn && router.url !== '/'" [mdfilename]="notLoggedInText" [currentLanguage]="account.lang"></slx-markdown>
    `,
})
export class AppLeadComponent {
    primaryOff = false;
    pleaseLoginOff = false;

    loginState = LoginState;

    constructor(public router: Router, private elementef: ElementRef, public loginService: LoginService, public account: AccountService) {
        this.primaryOff = this.elementef.nativeElement.hasAttribute('primaryOff');
        if (this.primaryOff) {
            document.body.classList.add('primary-nav-off');
        } else {
            document.body.classList.remove('primary-nav-off');
        }

        this.pleaseLoginOff = this.elementef.nativeElement.hasAttribute('pleaseLoginOff');
    }

    get notLoggedInText(): string {
        const route = this.router.url.split('/')[1];
        return route === 'news' ? 'please-login-premium' : 'please-login-standard';
    }
}

@Component({
    selector: 'slx-tail',
    styleUrls: ['./app-tail.component.scss'],
    template: `
        <button class="back-to-top-btn" [class.shown]="this.showBackToTopButton" (click)="this.backToTop($event);">
        <span class="material-icons">keyboard_arrow_up</span>
        <span translate="common-back-to-top"></span>
        </button>
    `,
})
export class AppTailComponent implements OnInit {
    private resize;
    private previousSize;
    private resizeStart = 0;
    private oldScrollPos = 0;
    private ankerPos = 0;
    public showBackToTopButton = false;

    constructor(private searchService: SearchService) {
        this.switchBackToTopButton = this.switchBackToTopButton.bind(this);
    }

    ngOnInit() {
        if (isResponsive()) {
            document.addEventListener('scroll', this.switchBackToTopButton);
        }
    }

    @HostListener('window:resize', ['$event']) onResize(event) {
        if (!this.resize) {
            this.resizeStart = event.target.innerWidth;
        }
        clearTimeout(this.resize);
        this.resize = setTimeout(finishedResizing.bind(this), 200);

        if (isResponsive()) {
            document.addEventListener('scroll', this.switchBackToTopButton);
        } else {
            document.removeEventListener('scroll', this.switchBackToTopButton);
            this.showBackToTopButton = false;
        }

        function finishedResizing() {
            this.resize = null;
            const resizeEnd = window.innerWidth;

            // Since mobile devices trigger regular unnecessary resizes that might cause scroll recalculations,
            // filter out all resizes in responsive mode that do not actually change the size of the window
            if(!isResponsive() || this.resizeStart !== resizeEnd || resizeEnd !== this.previousSize) {
                this.previousSize = this.resizeStart;
                this.searchService.dispatch({ type: AppActions.browserwindow_was_resized.name, payload: {resizeStart: this.resizeStart, resizeEnd} });
            }
        }
    }

    private switchBackToTopButton(event) {
        if (this.oldScrollPos < window.pageYOffset) {
            this.ankerPos = window.pageYOffset;
            this.showBackToTopButton = false;
        } else {
            if ((this.ankerPos - window.pageYOffset) > 250) {
                this.showBackToTopButton = true;
            }
        }

        if (window.pageYOffset === 0) {
            this.showBackToTopButton = false;
        }

        this.oldScrollPos = window.pageYOffset;
    }

    public backToTop(event) {
        window.scroll({ top: 0, behavior: 'smooth' });
    }
}

@Component({
    selector: 'slx-root',
    template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

    constructor(public loginService: LoginService, private searchService: SearchService, public router: Router) {
        // const subscription = this.auth.isAutoIPLoginEnabled().subscribe(
        //     res => {
        //         if (res === true) {
        //             const subscription = this.auth.loginIP().subscribe(
        //                 res => { },
        //                 err => { },
        //                 () => subscription.unsubscribe()
        //             );
        //         }
        //     },
        //     err => { },
        //     () => subscription.unsubscribe()
        // );
    }

    ngOnInit() {
        this.loginService.updateAuthentication();
        const size  = window.innerWidth;
        this.searchService.dispatch({ type: AppActions.browserwindow_was_resized.name, payload: {resizeStart: size, resizeEnd: size}});
    }
}
