import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActionsSubject } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GridsterConfig } from 'angular-gridster2';
import { Subscription } from 'rxjs';

import { LoginService } from '../access';
import { AccountService } from '../account';
import { AssetService } from '../asset';
import { AssetRefOptions, HitlistType, NewsType, Tile } from '../models';

import { HomeService } from './../home';
import { AlertType } from './../models/state/commonApp';
import { NewsWizardComponent } from './news-wizard/news-wizard.component';
import { NewsService } from './news.service';
import { NewsActions } from './newsActions';


@Component({
    selector: 'slx-newsservice',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsComponent implements OnDestroy {

    private scrollThrottle: any;
    private subscription: Subscription = new Subscription();
    private isPremium: boolean;

    public tiles: Tile[];
    public tilesCopy: Tile[];
    public tilesToShow: Tile[];
    public sidebarTiles: Tile[];
    public searchAbos: Tile[];
    public tileContent: any;
    public currentTile: Tile;
    public newsType = NewsType;

    @ViewChild('sidebarTileList') sidebarTileList: ElementRef;
    @ViewChild('gridstergrid') gridsterGrid: ElementRef;


    public options: GridsterConfig = {
        gridType: 'verticalFixed',
        displayGrid: 'onDrag&Resize',
        fixedRowHeight: 200,
        itemChangeCallback: this.itemChange.bind(this),
        mobileBreakpoint: 950,
        keepFixedHeightInMobile: true,
        margin: 14,
        outerMargin: false,
        minCols: 16,
        maxCols: 16,
        minRows: 12,
        maxRows: 1000,
        maxItemCols: 16,
        minItemCols: 4,
        maxItemRows: 6,
        minItemRows: 2,
        defaultItemCols: 4,
        defaultItemRows: 2,
        draggable: { enabled: true, ignoreContentClass: 'ignore' },
        resizable: { enabled: true },
        swap: true,
        pushResizeItems: true,
        pushItems: true,
        pushDirections: { north: false, east: true, south: true, west: true },
    };

    constructor(public newsService: NewsService, public homeService: HomeService, public loginService: LoginService, public assetService: AssetService, public accountService: AccountService, public translate: TranslateService,
                public dialog: MatDialog, private actionsSubject: ActionsSubject, private cd: ChangeDetectorRef) {

        this.subscription
            .add(this.newsService.tileContent.subscribe(result => {
                this.tileContent = result;
                // update the recently viewed
                const keys = Object.keys(this.tileContent);
                if (keys.length !== 0) {
                    keys.forEach(tileId => {
                        const tileContent = this.tileContent[tileId];
                        if (tileContent.content) {
                            this.assetService.updateRecentlyViewed(tileContent.content);
                        }
                    });
                }
                this.cd.markForCheck();
            }))
            .add(this.newsService.tiles.subscribe(
                result => {
                    const previousResult = this.tiles;
                    this.tiles = [...result];

                    if (previousResult && previousResult.length !== 0 && result.length > previousResult.length) {
                        setTimeout(() => { this.scrollToItem(result[result.length - 1]); }, 400);
                    }

                    this.sidebarTiles = this.tiles.sort((a: any, b: any) => b.configuration.type.value - a.configuration.type.value || a.configuration.title.localeCompare(b.configuration.title))
                        .filter(t => t.configuration.type.description !== 'Search');

                    this.tilesToShow = this.tiles.filter(t => t.configuration.type.description !== 'Search' && t.configuration.showAsTile);
                    this.searchAbos = this.tiles.filter(t => t.configuration.type.description === 'Search');

                    if (Object.keys(this.tileContent).length === 0 && this.tilesToShow.length > 0) {
                        setTimeout(() => this.fetchMissingTileContent(), 500);
                    }
                    this.cd.markForCheck();
                }
            ))
            .add(this.loginService.isPremium.subscribe(isPremium =>
                this.isPremium = isPremium
            ));

        const seachAboToCreate = this.newsService.searchAbosToCreate.pop();
        if (seachAboToCreate) {
            const data = {
                configuration: { searchFilter: seachAboToCreate.parameters, practiceAreaGroups: [], type: { value: 3 } },
                wizardType: 'newWithData',
            };

            if(this.isPremium){
                this.dialog.open(NewsWizardComponent, {
                    width: '1000px', panelClass: 'news-wizard', data,
                });
            }
        }
    }

    public ngAfterViewInit(){
        const journalAboToCreate = this.newsService.journalAbosToCreate.pop();
        if (journalAboToCreate) {
            const position = this.calculateViableTilePosition({ x: 0, y: 0, cols: 4, rows: 4 });
            const data = {
                ...position,
                configuration: { publication: { publicationID: journalAboToCreate }, searchFilter: {}, practiceAreaGroups: [], type: { value: 1 } },
                wizardType: 'newWithData',
            };

            setTimeout(() => this.dialog.open(NewsWizardComponent, {
                width: '1000px', panelClass: 'news-wizard', data,
            }));
        }
    }

    private calculateViableTilePosition(tileMeta) {
        this.options.api.getNextPossiblePosition(tileMeta);
        return tileMeta;
    }

    private itemChange(item: Tile) {
        this.saveTileConfiguration(item);
        setTimeout(() => this.fetchMissingTileContent(), 500);
    }

    private saveTileConfiguration(tile: Tile) {
        this.newsService.dispatch({ type: NewsActions.update_tile_configuration.name, payload: tile });
    }

    // IntersectionObserverAPI might be used for this in Future
    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    private calculateVisibleTileIds() {
        return Array.from(document.querySelectorAll('.grid-item'))
            .filter(item => (this.isInViewport(item)))
            .map(elem => elem.id);
    }

    private isInViewport(element) {
        const elementRects = element.getBoundingClientRect();
        const vertInView = (elementRects.top <= window.innerHeight) && ((elementRects.top + elementRects.height) >= 0);
        const horInView = (elementRects.left <= window.innerWidth) && ((elementRects.left + elementRects.width) >= 0);
        return (vertInView && horInView);
    }

    private fetchMissingTileContent() {
        this.calculateVisibleTileIds()
            .filter(tileId => (!this.tileContent[tileId] || this.tileContent[tileId].theDate + 86400000 <= Date.now())) // 86400000 ms = 24h
            .forEach(tileId => this.newsService.dispatch({ type: NewsActions.fetch_tile_content.name, payload: tileId }));
    }

    // Sets a currentTile when context-menu-action is triggerd since we hve no possibility to access the tileInformation from there.
    public setItemToEdit(tile: Tile) { this.currentTile = tile; }

    openDialogFromSidebar(): void {
        const position = this.calculateViableTilePosition({ x: 0, y: 0, cols: 4, rows: 4 });
        this.dialog.open(NewsWizardComponent, {
            width: '1000px', panelClass: 'news-wizard', data: { ...position, wizardType: 'new' },
        });
    }

    openDialogFromTile(): void {
        this.dialog.open(NewsWizardComponent, {
            width: '1000px', panelClass: 'news-wizard',
            data: { ...this.currentTile, wizardType: 'edit' },
        });
    }

    public rearrangeGrid() {
        this.options = { ...this.options, compactType: 'compactUp&Left', pushDirections: { north: true, east: true, south: true, west: true } };
        setTimeout(() => { this.options = { ...this.options, compactType: 'none', pushDirections: { north: false, east: true, south: true, west: true } }; }, 500);
    }

    public search(event, value) {
        const filterValue = value.toUpperCase();
        const elems = Array.from(this.sidebarTileList.nativeElement.querySelectorAll('li')).map((el: HTMLElement) => el);
        elems.forEach(el => el.style.display = 'flex');

        const filtered = elems.filter(el => !(el.children[1].textContent.toUpperCase().indexOf(filterValue) > -1));
        filtered.forEach(el => el.style.display = 'none');
    }

    // Output-Event from GridsterItem
    public itemWasResized(gridsterTile: Tile) {
        const tile = document.getElementById(`${gridsterTile.id}`);
        setTimeout(() => {
            if (tile.clientWidth <= 280 && !tile.classList.contains('mobile')) {
                tile.classList.add('hide-subheading');
            } else {
                tile.classList.remove('hide-subheading');
            }
        }, 50);
    }

    public getHitInfos(ref, newsID) {
        return { ref, newsID };
    }

    public getRefFromHit(hit) {
        const ref = [...hit.ref];
        const sourceIndex = ref.findIndex(r => r === AssetRefOptions.sourceDetail);
        if (sourceIndex < 0) {
            return [...ref, HitlistType.News, hit.newsID];
        }
        const source = ref.splice(sourceIndex);
        return [...ref, HitlistType.News, hit.newsID, ...source];
    }

    public mailDeliveryForAllNewsletters(active: boolean) {
        this.tiles.filter(tile => tile.configuration.deliveryConfiguration.sendAsEmail === !active).forEach(tile => {
            tile.configuration.deliveryConfiguration.sendAsEmail = active;
            this.saveTileConfiguration(tile);
        }
        );
    }

    // This function can be called from tileItem or from Menu. If it´s called from the tileItem directly we have all the tileInformation,
    // otherwise we have to take the informtion we have in this.currentTile that will hold the tileInformation when the context-menu is clicked.
    public toggleMailDelivery(tileInfo?: Tile) {
        const tile = tileInfo ? tileInfo : this.currentTile;
        const newPosition = this.calculateViableTilePosition({ x: tile.x, y: tile.y, rows: tile.rows, cols: tile.cols });
        const newTilePosition = { ...tile, ...newPosition };

        tile.configuration.deliveryConfiguration.sendAsEmail = !tile.configuration.deliveryConfiguration.sendAsEmail;
        this.saveTileConfiguration(tile);
    }

    // This function can be called from tileItem or from Menu. If it´s called from the tileItem directly we have all the tileInformation,
    // otherwise we have to take the informtion we have in this.currentTile that will hold the tileInformation when the context-menu is clicked.
    public toggleTileVisablity(tileInfo?: Tile) {
        const tile = tileInfo ? tileInfo : this.currentTile;
        const newPosition = this.calculateViableTilePosition({ x: tile.x, y: tile.y, rows: tile.rows, cols: tile.cols });
        const newTilePosition = { ...tile, ...newPosition };

        tile.configuration.showAsTile = !tile.configuration.showAsTile;
        this.saveTileConfiguration(newTilePosition);
        if (tile.configuration.showAsTile && !this.tileContent[tile.id]) {
            this.newsService.dispatch({ type: NewsActions.fetch_tile_content.name, payload: tile.id });
        }
    }

    public toggleSidebar() {
        this.newsService.dispatch({ type: NewsActions.collapse_news_sidebar.name });
        this.options.api.resize();
    }

    // Gets called when gridster is scrolled (Output-Prop Gridster)
    public gridsterOnScroll(event) {
        clearTimeout(this.scrollThrottle);
        this.scrollThrottle = setTimeout(() => this.fetchMissingTileContent(), 500);
    }

    public scrollToItem(item, event?) {
        this.setItemToEdit(item.id);
        const tile: HTMLElement = document.getElementById(item.id);

        if (tile) {
            tile.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => tile.focus());
            setTimeout(() => tile.blur(), 7000);
        }
    }

    public openAsHitlist(event): void {
        this.newsService.openInRecherche(this.currentTile.id, null, !event.altKey);
    }

    public removeItemFromContentMenu(): void {
        this.removeItem(this.currentTile.id);
    }

    public removeItem(tileId): void {
        this.newsService.dispatch({ type: NewsActions.remove_tile.name, payload: tileId });
    }

    public sendNewsNow(): void {
        this.newsService.dispatch({ type: NewsActions.send_news_now.name, payload: this.currentTile.id, alert: { type: AlertType.Info, key: 'news-newsletter-alert-sent', duration: 10 } });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
