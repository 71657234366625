import { Action } from '@ngrx/store';

import { AboServiceState, enhanceActionsObject, SlxAction } from '../../models';

const uniqueAlertId = 1111;

export const AboServicesActions = {

    load_register_business_form_elements(state: AboServiceState, { payload, result }) {
         return result ? {
             ...state,
             registerBusinessFormElements: payload,
             badEmail: false,
         } : state;
    },

    load_register_students_form_elements(state: AboServiceState, { payload, result }) {
        return result ? {
            ...state,
            registerStudentsFormElements: payload,
            badEmail: false,
        } : state;
    },

    set_badmail(state: AboServiceState, { payload, result }) {
        return {
            ...state,
            badEmail: false,
        };
    },

    load_register_rak_form_elements(state: AboServiceState, { payload, result }) {
        return result ? {
            ...state,
            registerRakFormElements: payload,
            badEmail: false,
        } : state;
    },

    save_add_business_customer_data(state: AboServiceState, { payload, result }) {
        return result ? state: { ...state, badEmail: false };
    },

    save_add_business_customer_data_bad_email(state: AboServiceState, { payload }) {
        return { ...state, badEmail: true };
    },

    save_uni_user_profile_data(state: AboServiceState, { payload, result }) {
        return state;
    },

    save_rak_user_profile_data(state: AboServiceState, { payload }) {
        return state;
    },

    save_training_data(state: AboServiceState, { payload }) {
        return state;
    },

    activate_rak_user(state: AboServiceState, { payload, result }) {
        return result ? {
            ...state,
            activateRakUserData: payload,
        } : state;
    },

    get_users_ip(state: AboServiceState, { payload, result }) {
        return result ? {
            ...state,
            usersIpAdress: payload,
        } : state;
    },
};
enhanceActionsObject(AboServicesActions);
