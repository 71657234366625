import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';

import { AccountService } from '../../account';
import { CustomerProfileActions } from '../../customer-profile/customerProfileActions';
import { emailValidator, requiredValidator } from '../../field';
import { markFormAsTouched } from '../../utility/utilityFunctions';
import { ChangePasswordFromProfileComponent } from '../changePasswordFromProfile.component';

@Component({
    selector: 'slx-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {

    private subscription: Subscription = new Subscription();

    public userProfileForm: FormGroup;
    public addressTerms: Array<{ key: string, value: string }>;
    public addressTitles: Array<{ key: string, value: string }>;
    public languages: Array<{ key: string, value: string }> = [
        { key: 'DE', value: 'account-german' },
        { key: 'FR', value: 'account-french' },
    ];
    public userData: any;
    public usersIpAdress: string;
    public departmentID: string;

    constructor(private fb: FormBuilder, public accountService: AccountService, private dialog: MatDialog) {

        this.userProfileForm = this.fb.group({
            language: [''],
            addressTerm: [''],
            title: [''],
            firstName: ['', requiredValidator()],
            name: ['', requiredValidator()],
            email: ['', [requiredValidator(), emailValidator('service-reg-email-notvalid-val-txt')]],
            hasCorporateNewsletter: [''],
        }
        );
    }

    ngOnInit() {
        this.subscription
            .add(this.accountService.onLangChange.subscribe(() => this.setDefaultFormValues()))
            .add(this.accountService.userProfileData.subscribe(data => {
                if (data) {
                    this.userData = data;
                    this.setDefaultFormValues();
                }
            }));
    }

    private get addressTerm(): FormControl {
        return this.userProfileForm.get('addressTerm') as FormControl;
    }

    private setDefaultFormValues(): void {
        // Function Guard because it´s possible that a lang change gets triggered without the user is actuallay changing the language.
        // (At The moemnt thrugh handleAuth Sucess - it makes a load language request that triggers lang change Event)
        if (!this.userData) {
            return;
        }

        this.userData.title = this.userData.title === null ? '-' : this.userData.title;

        if (this.accountService.lang === 'de') {

            this.addressTerms = [
                { key: 'Herr', value: 'Herr' },
                { key: 'Frau', value: 'Frau' },
            ];

            this.addressTitles = [
                { key: '-', value: '-' },
                { key: 'RA', value: 'RA' },
                { key: 'Dr.', value: 'Dr.' },
                { key: 'Prof.', value: 'Prof.' },
            ];

            switch (this.userData.addressTerm) {
                case 'Monsieur':
                case 'Maître':
                    this.addressTerm.setValue('Herr');
                    break;
                case 'Madame':
                    this.addressTerm.setValue('Frau');
                    break;
                default:
                    this.addressTerm.setValue(this.userData.addressTerm);
                    break;
            }
        } else {
            this.addressTerms = [
                { key: 'Monsieur', value: 'Monsieur' },
                { key: 'Maître', value: 'Maître' },
                { key: 'Madame', value: 'Madame' },
            ];

            this.addressTitles = [
                { key: null, value: '-' },
                { key: 'av.', value: 'av.' },
            ];

            switch (this.userData.addressTerm) {
                case 'Herr':
                    this.addressTerm.setValue('Monsieur');
                    break;
                case 'Frau':
                    this.addressTerm.setValue('Madame');
                    break;
                default:
                    this.addressTerm.setValue(this.userData.addressTerm);
                    break;
            }
        }

        this.departmentID = this.userData.departmentID;
        this.userProfileForm.controls['language'].setValue(this.userData.language);
        this.userProfileForm.controls['title'].setValue(this.userData.title);
        this.userProfileForm.controls['firstName'].setValue(this.userData.firstName);
        this.userProfileForm.controls['name'].setValue(this.userData.name);
        this.userProfileForm.controls['email'].setValue(this.userData.email);
        this.userProfileForm.controls['hasCorporateNewsletter'].setValue(this.userData.hasCorporateNewsletter);
    }

    public openPwDialog() {
        this.dialog.open(ChangePasswordFromProfileComponent, {
            height: 'auto',
            width: '700px',
            panelClass: 'publication-select',
        });
    }

    onSubmit(userProfileForm) {
        if (!userProfileForm.valid) {
            markFormAsTouched(userProfileForm);
            return;
        }

        const userProfileFormData = {
            id: this.userData.id,
            level: this.userData.level,
            isActivated: this.userData.isActivated,
            creditLimit: this.userData.creditLimit,
            departmentId: this.departmentID,
            ...userProfileForm.value,
        };

        this.accountService.dispatch({ save: CustomerProfileActions.save_user_profile.name, payload: userProfileFormData });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
