import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { TransactionType } from '../models';

import { SearchService } from './search.service';

const debug = require('debug')('search-transaction');

@Injectable()
export class RechercheResolver implements Resolve<boolean> {
    constructor(private searchService: SearchService) { }

    resolve(route: ActivatedRouteSnapshot): boolean {
        this.searchService.resolveRoute({
            type: route.data.type || TransactionType.Search,
            subtype: route.params.subtype || route.data.subtype,
            id: route.params.id,
            subid: route.params.subid,
            list: route.params.list,
            option: route.params.option,
            source: route.params.source,
            queryParams: route.queryParams,
        });

        // immediate render of the component
        return true;
    }
}
