import { AssetDisplayType, LanguageEnum } from '../enums';

import { AssetContent } from './asset';

export class DocumentContent extends AssetContent {
    referingAssetID: string;
    assetContentAsHtml: string;
    bibliographyContentAsHtml: string;
    citedAssets: AssetCitation[];
    otherCitations: string[];
    consolidatedAssets: any;
    reference: string;
    navigationInfo: AssetNavigationInfo;
    docTitle: string;
    assetLanguage: LanguageEnum;
    documentID: string;
    attachments: AssetAttachment[];
    logoBannerUrl: string;
    assignedArticleOfLaws: LawGuids[];
}

export class LawContent extends DocumentContent {
    lawGuids: LawGuids;
    getPdfUrl: string;
    isSrLaw: boolean;
}

export class LawGuids {
    lawId: string;
    aolId: string;
}

export class AssetNavigationInfo {
    collectionAssetID: string;
    publicationID: string;
    collectionAssetType: AssetDisplayType;
    canShowToc: boolean;
    docToc: DocTocNode[];
    precedingAssetID: string;
    followingAssetID: string;
}

export class DocTocNode {
    edocTitleID: string;
    assetID: string;
    title: string;
    marginNumber: string;
    children: DocTocNode[];
    isFirstInNewDoc: boolean;
    multiFollow: boolean;
}

export class AssetCitation {
    assetID: string;
    displayString: string;
    anchor: string;
    citationID: string;
}


export class AssetAttachment {
    attachment: {
        fileID: string;
        description: string;
    };
    position: LinkDisplayType;
}

export enum LinkDisplayType {
    undefined = 0,
    onDocument = 1,
}
