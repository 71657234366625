import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

import { getApplicationConfigUrl } from './access';

@Injectable()
export class AppContext {
  public static Context = new BehaviorSubject<Map<string, any>>(new Map<string, any>());

  constructor(private http: HttpClient) { }

  private header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authenticated': 'false',
  };

  load() {
    return this.http.get<any>(`${getApplicationConfigUrl}frontend`, { headers: new HttpHeaders(this.header) }).pipe(
      first(),
      map(res => {
        AppContext.Context.next(new Map<string, any>(res.map(x => [x.setting, JSON.parse(x.value)] as [string, any])));
      }),
      catchError(error => (
        Observable.throw(error))
      )).subscribe();
  }
}
