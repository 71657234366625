import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActionsSubject } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AccountService } from '../../account';
import { AssetActions } from '../../asset';
import { CasePubTabSubTypes, EuTabSubTypes, MulticheckItem } from '../../models';
import { SearchService } from '../search.service';
import { SearchActions } from '../searchActions';

import { MulticheckComponent } from './../../field';

@Component({
    selector: 'slx-search-form',
    templateUrl: './search-form.component.html',
    styleUrls: ['./search-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFormComponent implements OnDestroy {

    @Input() formDescription;
    @Input() searchForm: FormGroup;
    @Input() subTabs = [];

    @Output() searchSubmitted = new EventEmitter();
    @Output() searchReset = new EventEmitter();

    @ViewChild('practiceAreaGroupsCriteria') practiceAreaGroupsCriteria: MulticheckComponent;
    @ViewChild('assetTypeGroupsCriteria') assetTypeGroupsCriteria: MulticheckComponent;

    private subscription: Subscription = new Subscription();
    private isOpenAdvancedSearchOnSearchFilter: boolean;

    public advancedSearch = false;
    public practiceAreaFilters: Array<MulticheckItem>;
    public documentCategoryFilters: Array<MulticheckItem>;
    public legalActCategoryFilters: Array<MulticheckItem>;

    constructor(public searchService: SearchService, public translateService: TranslateService, public accountService: AccountService, private actionsSubject: ActionsSubject, private formBuilder: FormBuilder, private dialog: MatDialog, private changeDetectorRef: ChangeDetectorRef) {

        this.subscription
            .add(this.searchService.practiceAreaFilters.subscribe(filters => {
                this.practiceAreaFilters = [...filters] as Array<MulticheckItem>;
                this.changeDetectorRef.markForCheck();
            }))
            .add(this.searchService.documentCategoryFilters.subscribe(filters => this.documentCategoryFilters = [...filters] as Array<MulticheckItem>))
            .add(this.searchService.legalActCategoryFilters.subscribe(filters => this.legalActCategoryFilters = [...filters] as Array<MulticheckItem>))
            .add(this.searchService.advancedSearch.subscribe(value => this.advancedSearch = value))
            .add(this.accountService.profilePrefs.subscribe(prefs => {
                if (prefs) {
                    this.isOpenAdvancedSearchOnSearchFilter = prefs.isOpenAdvancedSearchOnSearchFilter;
                }
            }
            ))
            .add(this.searchService.store.select(state => state.recherche.searchForms).subscribe(state => {
                //console.log('search-form-base: markForCheck');
                this.changeDetectorRef.markForCheck();
            }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onSubmit(searchForm) {
        this.searchSubmitted.emit(searchForm);
    }

    public reset() {
        this.searchReset.emit();
        //this.changeDetectorRef.markForCheck();
    }

    collapseComponent(event: string) {
        switch (event) {
            case 'assetTypeGroupsCriteria': {
                this.practiceAreaGroupsCriteria.hideCheckboxes();
                break;
            }
            case 'practiceAreaGroupsCriteria': {
                this.assetTypeGroupsCriteria.hideCheckboxes();
                break;
            }
        }
    }

    selectSearchFilter(id: string) {
        const searchFilterId = Number(id);

        if (!this.advancedSearch && this.isOpenAdvancedSearchOnSearchFilter) {
            this.searchService.dispatch({ type: SearchActions.change_visibility_advanced_search.name });
        }
        this.searchService.dispatch({ fetch: SearchActions.apply_user_search_filter.name, payload: { id: searchFilterId } });
    }

    public dispatchSubTab(name: CasePubTabSubTypes | EuTabSubTypes){
        this.searchService.dispatch({ type: SearchActions.set_rech_tab.name, payload: name });
    }

    public clearRecherche() {
        this.searchService.dispatch({ type: AssetActions.clear_recherche_results.name });
    }
}
