// tslint:disable-next-line:ordered-imports
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { LoginService } from '../../../access';
import { AccountService } from '../../../account';
import { CustomerProfileActions } from '../../../customer-profile/customerProfileActions';
import { emailValidator, maxLengthValidator, numberValidator, requiredValidator } from '../../../field';
import { ActionState } from '../../../models/state/commonApp';
import { markFormAsTouched } from '../../../utility/utilityFunctions';

@Component({
    selector: 'slx-add-new-user',
    templateUrl: './addNewUser.component.html',
    styleUrls: ['./addNewUser.component.scss'],
})
export class AddNewUserComponent implements OnInit, OnDestroy {

    private subscription: Subscription = new Subscription();
    private userData: any;
    private isCorpAdmin: boolean;
    private isJointVentureAdmin: boolean;
    private isSuperUser: boolean;
    private portalUserDepartmentId: string;
    private portalUserId: string;
    private userFormData;

    public userForm: FormGroup;
    public addressTerms: Array<{ key: string, value: string }>;
    public addressTerm: string;
    public addressTitles: Array<{ key: string, value: string }>;
    public departments: Array<{ key: string, value: string }>;
    public permissionLevels: Array<{ key: string, value: string }>;
    public languages: Array<{ key: string, value: string }> = [
        { key: 'DE', value: 'account-german' },
        { key: 'FR', value: 'account-french' },
    ];

    constructor(public dialogRef: MatDialogRef<AddNewUserComponent>, @Inject(MAT_DIALOG_DATA) private departmentID: string, private route: ActivatedRoute, private formBuilder: FormBuilder,
                public accountService: AccountService, public loginService: LoginService) {

        this.userForm = this.formBuilder.group({
            addressTerm: [''],
            title: [''],
            firstName: ['', [requiredValidator(), maxLengthValidator('service-name-max-length',30)]],
            name: ['', [requiredValidator(), maxLengthValidator('service-name-max-length',30)]],
            departmentID: [''],
            email: ['', [requiredValidator(), emailValidator('service-reg-email-notvalid-val-txt')]],
            isActivated: [''],
            hasCorporateNewsletter: [''],
            level: [''],
            language: [''],
            creditLimit: ['', numberValidator('service-reg-comp-nol-notvalid-val-txt')],
        });
    }

    ngOnInit(): void {
        this.accountService.dispatch({ fetch: CustomerProfileActions.load_user_profile_data.name });

        this.subscription
            .add(this.accountService.userProfileData.subscribe(data => {
                if (data) {
                    this.userData = data;
                    this.setDefaultFormValues();
                }
            }))
            .add(this.accountService.onLangChange.subscribe(() => this.setDefaultFormValues()))
            .add(this.loginService.isCorpAdmin.subscribe(right => this.isCorpAdmin = right))
            .add(this.loginService.isJointVentureAdmin.subscribe(right => this.isJointVentureAdmin = right))
            .add(this.loginService.isSuperUser.subscribe(right => this.isSuperUser = right))
            .add(this.loginService.portalUserDepartmentId.subscribe(id => this.portalUserDepartmentId = id))
            .add(this.loginService.userId.subscribe(id => this.portalUserId = id))

            .add(this.accountService.stateOfAction(CustomerProfileActions.add_user.name).subscribe(res => {
                if (res === ActionState.Success) {
                    setTimeout(() => {
                        // Timeout is on Purpose so that the dialog does not close immediately
                        this.dialogRef.close(this.userFormData);
                    }, 2000);
                }
            }));
    }

    private setDefaultFormValues(): void {
        if (!this.userData) {
            return;
        }

        this.permissionLevels = [];
        if (this.accountService.lang === 'de') {
            this.determinePermissionLevels('de');
            this.addressTerm = 'Herr';
            this.addressTerms = [
                { key: 'Herr', value: 'Herr' },
                { key: 'Frau', value: 'Frau' },
            ];
            this.addressTitles = [
                { key: '-', value: '-' },
                { key: 'RA', value: 'RA' },
                { key: 'Dr.', value: 'Dr.' },
                { key: 'Prof.', value: 'Prof.' },
            ];
        } else {
            this.determinePermissionLevels('fr');
            this.addressTerm = 'Monsieur';
            this.addressTerms = [
                { key: 'Monsieur', value: 'Monsieur' },
                { key: 'Maître', value: 'Maître' },
                { key: 'Madame', value: 'Madame' },
            ];
            this.addressTitles = [
                { key: '-', value: '-' },
                { key: 'av.', value: 'av.' },
            ];
        }

        if (this.userData.company) {
            if (this.isCorpAdmin || this.isSuperUser || this.isJointVentureAdmin) {
                this.departments = this.userData.company.departments;
                if (this.departmentID !== null) {
                    this.userForm.controls['departmentID'].setValue(this.departmentID);
                }
                else if (this.userData.departmentID !== null) {
                    this.userForm.controls['departmentID'].setValue(this.userData.departmentID);
                } else {
                    this.userForm.controls['departmentID'].setValue('00000000-0000-0000-0000-000000000000');
                }
            }
            else {
                this.departments = this.userData.company.departments.filter(d => d.id === this.userData.departmentID);
                this.userForm.controls['departmentID'].setValue(this.userData.departmentID);
            }
        }
        this.userForm.controls['language'].setValue(this.accountService.lang.toUpperCase());
        this.userForm.controls['addressTerm'].setValue(this.addressTerm);
        this.userForm.controls['title'].setValue('-');
        this.userForm.controls['isActivated'].setValue(true);
        this.userForm.controls['hasCorporateNewsletter'].setValue(true);
        this.userForm.controls['level'].setValue('20 - Corporate User');
    }

    private determinePermissionLevels(lang: string) {
        lang === 'de' ?
            this.permissionLevels.push(
                { key: '20 - Corporate User', value: 'Benutzer' },
                { key: '25 - Department Administrator', value: 'Abteilungsverwalter' }
            ) :
            this.permissionLevels.push(
                { key: '20 - Corporate User', value: 'Corporate User' },
                { key: '25 - Department Administrator', value: 'Department Administrator' }
            );

        if (this.isSuperUser) {
            lang === 'de' ?
                this.permissionLevels.push(
                    { key: '30 - Corporate Administrator', value: 'Firmaverwalter' },
                    { key: '40 - Joint Venture Administrator', value: 'Joint Venture Administrator' },
                    { key: '50 - Super User', value: 'Super User' }
                ) :
                this.permissionLevels.push(
                    { key: '30 - Corporate Administrator', value: 'Corporate Administrator' },
                    { key: '40 - Joint Venture Administrator', value: 'Joint Venture Administrator' },
                    { key: '50 - Super User', value: 'Super User' }
                );
        } else {
            if (this.isCorpAdmin) {
                lang === 'de' ?
                    this.permissionLevels.push(
                        { key: '30 - Corporate Administrator', value: 'Firmaverwalter' }
                    ) :
                    this.permissionLevels.push(
                        { key: '30 - Corporate Administrator', value: 'Corporate Administrator' }
                    );
            }

            if (this.isJointVentureAdmin) {
                this.permissionLevels.push(
                    { key: '40 - Joint Venture Administrator', value: 'Joint Venture Administrator' }
                );
            }
        }
    }

    onSubmit(userForm) {

        if (!userForm.valid) {
            markFormAsTouched(userForm);
            return;
        }

        this.userFormData = {
            company: this.userData.company,
            isUni: false,
            ...userForm.value,
        };

        this.accountService.dispatch({ save: CustomerProfileActions.add_user.name, payload: this.userFormData });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
