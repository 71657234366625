import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AssetDisplayType, AssetRef, AssetRefOptions, DocTocNode } from '../../../../models';
import { AssetService } from '../../../asset.service';


@Component({
    selector: 'slx-doc-toc-entry',
    templateUrl: './doc-toc-entry.component.html',
    styleUrls: ['./doc-toc-entry.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocTocEntryComponent {

    @Input() node: DocTocNode;
    @Input() isPrimary: boolean;
    @Input() assetID: string;
    @Input() chlidren: string;
    @Input() treeControl: NestedTreeControl<DocTocNode>;


    constructor(private assetService: AssetService, public translate: TranslateService) {
    }

    public openAsset(node: DocTocNode,  event: MouseEvent) : void {
        const citationInfo = { type: AssetRefOptions.EdocTitleRef, id: `edoctitle_${node.edocTitleID}`};
        const assetRef = AssetRef.create(AssetDisplayType.UnknownDocument, node.assetID, {citationInfo, source: 'doc-toc-document-link'});
        this.assetService.openAsset(assetRef, this.isPrimary, event);
    }
}
