import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CellClickedEvent, ColumnApi, ColumnResizedEvent, GridApi, GridOptions, GridReadyEvent, GridSizeChangedEvent, RowClickedEvent, RowDataChangedEvent, RowGroupOpenedEvent, RowSelectedEvent, SelectionChangedEvent } from 'ag-grid-community';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AccountService } from '../../account';
import { isResponsive } from '../../utility/utilityFunctions';



@Component({
    selector: 'slx-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit, OnDestroy {
    @Input() options;
    @Input() domLayout;
    @Input() columnDefs;
    @Input() autoGroupColumnDef;
    @Input() title = '';
    @Input() showSearch = true;
    @Input() rowTranslatePrefix: string;
    @Input() rowData: Observable<Array<any>>;
    @Input() additionalOffset = 0;
    @Input() fixedHeight;
    @Input() getRowHeight: (params) => number;
    @Input() getRowClass: (params) => string;

    @Input() onGridReady: (params: GridReadyEvent) => void;
    @Input() additionalLangUpdate: () => void;
    @Input() translateKeyLoading: string;
    @Input() translateKeyNoData: string;
    @Input() gridSearchWidth: any;
    @Input() isBiblio: boolean;

    @Output() rowDataChanged = new EventEmitter<RowDataChangedEvent>();
    @Output() rowClicked = new EventEmitter<RowClickedEvent>();
    @Output() gridSizeChanged = new EventEmitter<GridSizeChangedEvent>();
    @Output() selectionChanged = new EventEmitter<SelectionChangedEvent>();
    @Output() rowSelected = new EventEmitter<RowSelectedEvent>();
    @Output() cellClicked = new EventEmitter<CellClickedEvent>();

    public gridOptions: GridOptions;
    public overlayNoRowsTemplate: string;
    public overlayLoadingTemplate: string;

    public gridApi: GridApi;

    private gridColumnApi: ColumnApi;
    private subscription: Subscription;

    constructor(private translate: TranslateService, private accountService: AccountService) {
        this.subscription = this.accountService.onLangChange.subscribe(res => {
            this.updateHeaderTranslations();
            if (this.additionalLangUpdate) {
                this.additionalLangUpdate();
            }
            this.gridApi.redrawRows();
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (!this.gridApi && !this.gridColumnApi) return;

        const columns = this.gridColumnApi.getAllColumns();

        if (!isResponsive()) {
            if (columns[0].getColDef().suppressResize === true) {
                for (const column of columns) {
                    const colDef = column.getColDef();
                    colDef.suppressResize = false;
                    colDef.suppressMovable = false;
                }

                this.gridApi.refreshHeader();
            }

            this.gridOptions.api.sizeColumnsToFit();
        } else {
            if (columns[0].getColDef().suppressResize === false) {
                for (const column of columns) {
                    const colDef = column.getColDef();
                    colDef.suppressResize = true;
                    colDef.suppressMovable = true;
                }

                this.gridApi.refreshHeader();
            }
        }
    }

    ngOnChanges(changes) {
        if (changes.domLayout && this.gridApi) {
            this.gridApi.redrawRows();
        }
    }

    ngOnInit(): void {
        this.columnDefs = this.columnDefs.map(def => ({ ...def, suppressResize: isResponsive(), suppressMovable: isResponsive() }));

        this.gridOptions = {
            onGridReady: params => {
                this.gridApi = params.api;
                this.gridColumnApi = params.columnApi;
                this.updateHeaderTranslations();
                if (this.onGridReady) {
                    this.onGridReady(params);
                }
            },
            defaultColDef: {
                comparator: function (a, b) {
                    if (typeof a === 'string') {
                        return a.localeCompare(b);
                    } else {
                        return (a > b ? 1 : (a < b ? -1 : 0));
                    }
                },
            },
            enableSorting: true,
            enableFilter: true,
            suppressContextMenu: true,
            enableColResize: true,
            suppressDragLeaveHidesColumns: true,
            ...this.options,
        };

        this.overlayNoRowsTemplate = `<span class="ag-overlay-loading-center">${this.translate.instant(this.translateKeyNoData ? this.translateKeyNoData : 'account-grid-nodata')}</span>`;
        this.overlayLoadingTemplate = `<span class="ag-overlay-loading-center">${this.translate.instant(this.translateKeyLoading ? this.translateKeyLoading : 'account-grid-loading')}</span>`;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    // translation
    private getTranslatioName(field: string): string {
        return `${this.rowTranslatePrefix}-${field}`;
    }

    private updateHeader(colID: string, newValue: string): void {
        if (this.gridColumnApi) {
            const col = this.gridColumnApi.getColumn(colID);
            col.getColDef().headerName = newValue;
            this.gridApi.refreshHeader();
        }
    }

    private updateHeaderTranslations(): void {
        this.columnDefs
            .filter(def => def.field)
            .forEach(def =>
                this.updateHeader(
                    def.field,
                    this.translate.instant(
                        this.rowTranslatePrefix
                            ? this.getTranslatioName(def.field)
                            : def.headerName
                    )
                )
            );
    }

    public onRowGroupOpened($event: RowGroupOpenedEvent) {
        $event.api.sizeColumnsToFit();
    }

    // Event Emitters
    public onRowDataChanged($event: RowDataChangedEvent) {
        $event.api.sizeColumnsToFit();
        this.rowDataChanged.emit($event);
    }

    public onRowClicked($event: RowClickedEvent) {
        this.rowClicked.emit($event);
    }

    public onGridSizeChanged($event: GridSizeChangedEvent) {
        $event.api.sizeColumnsToFit();
        this.gridSizeChanged.emit($event);
    }

    public onColumnResized(event: ColumnResizedEvent) {
        if (!(event.source === 'uiColumnDragged' && event.finished)) {
            return;
        }
        event.api.sizeColumnsToFit();
    }

    public onSelectionChanged($event: SelectionChangedEvent) {
        this.selectionChanged.emit($event);
    }

    public onRowSelected($event: RowSelectedEvent) {
        this.rowSelected.emit($event);
    }

    public onCellClicked($event: CellClickedEvent) {
        this.cellClicked.emit($event);
    }

    public onQuickFilterChanged(value): void {
        this.gridApi.setQuickFilter(value);
    }
}

