export interface Preferences {
    hideSavedFavorite: boolean;
    hideTipOfTheDay: boolean;
    hideWelcome: boolean;
    hideIEWarning: boolean;
    isJumpToFirstSearchTerm: boolean;
    isOpenAdvancedSearchOnSearchFilter: boolean;
    isParallelDocView: boolean;
    pdfDocTryHighlight: number;
    pdfHitListTryHighlight: boolean;
    hitlistSize: Number;
    hideUniUsing: boolean;
}

export const defaultPreferences: Preferences = {
    hideSavedFavorite: false,
    hideTipOfTheDay: false,
    hideWelcome: true,
    hideIEWarning: false,
    isJumpToFirstSearchTerm: false,
    isOpenAdvancedSearchOnSearchFilter: false,
    isParallelDocView: true,
    pdfDocTryHighlight: 0,
    pdfHitListTryHighlight: false,
    hitlistSize: 25,
    hideUniUsing: true,
};

export interface CustomerProfileState {
    departmentsData?: any[];
    singleDepartmentData: any;
    usersData: any[];
    customerProfileData: any;
    userProfileData: any;
    statistics?: any;
    trxsType: any;
    trxsTypeForOrder: any;
    profilePrefs: Preferences;
}

export const defaultCustomerProfileState = {
    singleDepartmentData: {},
    usersData: undefined, // So that allUseresGrid shows "Inhalte werden geladen"
    customerProfileData: {},
    profilePrefs: defaultPreferences,
    userProfileData: {},
    trxsType: {},
    trxsTypeForOrder: {},
};
