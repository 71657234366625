import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AppActions } from '../appActions';
import { HomeState, makeAction, SlxAction } from '../models';
import { AlertType } from '../models/state/commonApp';

import { AccessActions } from './accessActions';
import { getBackupOfStoreUrl, getCustomBackupsUrl, postBackupOfStoreUrl } from './apiUrls';
import { LoginService } from './login.service';
//import { AuthService } from './auth.service';
import { SlxHttp } from './slx-http.service';

@Injectable()
export class AccessEffects {

    @Effect({ dispatch: false }) backupStore = this.actions$.pipe(
        ofType(AppActions.backup_store.name),
        withLatestFrom(this.loginService.store),
        tap(([action, appState]) => {
            this.http.post(postBackupOfStoreUrl, {
                jsonValue: JSON.stringify(appState),
                type: (action as SlxAction).payload.type,
                frontendKey: (action as SlxAction).payload.key,
                description: (action as SlxAction).payload.description,
            }).subscribe();
        })
    );

    @Effect() restoreStore = this.actions$.pipe(
        ofType(AppActions.restore_store.name),
        switchMap((action) => {
            const idOrKey = (action as SlxAction).payload;
            return this.http.get(`${getBackupOfStoreUrl}?idOrKey=${idOrKey}`).pipe(
                map(result => {
                    const payload = result && result.jsonValue ? JSON.parse(result.jsonValue) : null;
                    const navigate = payload ? [this.getNavigateBasedOnHomeState(payload.home)] : ['home'];
                    const alert = payload ? null : { type: AlertType.Warning, key: 'home-storerestore-failed', duration: 5 };
                    return makeAction({ result: AppActions.restore_store.name, payload, navigate, alert });
                }));
        })
    );

    @Effect() loadCustomStoreBackups = this.actions$.pipe(
        ofType(AccessActions.load_custom_store_backups.name),
        switchMap(action => {
            return this.http.get(getCustomBackupsUrl).pipe(
                map(response => makeAction({ result: AccessActions.load_custom_store_backups.name, payload: response }))
            );
        })
    );

    constructor(private actions$: Actions, private loginService: LoginService, private http: SlxHttp) { }

    getNavigateBasedOnHomeState(homeState: HomeState): string {
        return homeState.tabUrl[homeState.mainTab];
    }
}
