import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AccountService } from '../../../account';
import { unCryptMailto } from '../../../field';

@Component({
    selector: 'slx-about-team',
    templateUrl: './about-team.component.html',
    styleUrls: ['./about-team.component.scss'],
})
export class AboutTeamComponent implements OnDestroy {
    private subscription: Subscription;
    public language = this.accountService.lang;

    constructor(private accountService: AccountService) {
        this.subscription = accountService.onLangChange.subscribe(res => { this.language = res.lang; });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    linkToUnCryptMailto(s) {
        location.href = unCryptMailto(s);
    }
}


