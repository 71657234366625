import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable ,  of } from 'rxjs';

import { MulticheckItem } from '../../models';
import { getValidationMessage } from '../validators';

@Component({
    selector: 'slx-multicheck',
    templateUrl: './multicheck.component.html',
    styleUrls: ['./multicheck.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MulticheckComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => MulticheckComponent),
        multi: true,
    }],
})
export class MulticheckComponent implements OnInit, ControlValueAccessor, Validator {

    @Input('options') set options(val: any) { val instanceof Observable ? this._options = val : this._options = of(val); this.reflectAllChanged(); }
    @Input('placeholder') _placeholder: string;
    @Input() keyName = 'key';
    @Input() labelName = 'value';
    @Input() translateName;
    @Input() layout = 'column';
    @Input() color: string;
    @Input() allChecked = false;
    @Input() checkboxesVisible = false;
    @Input() formControlName: string;
    @Input() name: string;
    @Input() checkAllCheckbox = true;

    @Output() change = new EventEmitter<string>();
    @Output() hideShow = new EventEmitter<string>();

    @ViewChild('slider') slider: ElementRef;
    @ViewChild('sliderContent') sliderContent: ElementRef;


    private _options: Observable<MulticheckItem>;
    private checkedValues: Array<string> = [];

    public formControl = null;
    public allCheckboxChecked = false;
    public getValidationMessage: (formControl: any, translate: any) => string = getValidationMessage;

    constructor(public translate: TranslateService) { }

    private initialValues;
    ngOnInit() {
        this.initialValues = {
            checkAllCheckbox: this.checkAllCheckbox,
            checkboxesVisible: this.checkboxesVisible,
        };
    }

    get placeholder() { return this._placeholder ? this.translate.instant(this._placeholder) : null; }
    get options() { return this._options; }
    get numberOfOptions() { return this.options.value.length; }
    get numberOfCheckedValues() { return this.checkedValues.length; }

    public validate(c: FormControl) {
        this.formControl = c;
        return {};
    }
    private setOptionsCheckedValues(value: boolean) {
        this.checkedValues = [];
        if (value) {
            this.options.value.filter(option => !option.disabled || option.disabled).forEach(option => this.checkedValues.push(option[this.keyName]));
        } else {
            this.options.value.filter(option => option.disabled).forEach(option => this.checkedValues.push(option[this.keyName]));
        }
    }

    public getViewValue(option: object) {
        if (this.translateName) {
            const translate = option[this.translateName];
            if (translate) {
                return this.translate.stream(translate);
            }
        }
        return of(option[this.labelName]);
    }

    public toggleCollapse() {
        const slider: HTMLElement = this.slider.nativeElement;
        const sliderContent: HTMLElement = this.sliderContent.nativeElement;
        const transitionEndedCallback = () => {
            slider.removeEventListener('transitionend', transitionEndedCallback);
            // Remove maxHeight-Property when slider was opened, for responsive reasons / line-breaks
            slider.style.maxHeight = 'none';
        };

        if (this.checkboxesVisible) {
            slider.style.maxHeight = sliderContent.offsetHeight + 'px';
            setTimeout(() => slider.style.maxHeight = '0px');
        } else {
            slider.addEventListener('transitionend', transitionEndedCallback);
            slider.style.maxHeight = sliderContent.offsetHeight + 'px';
        }

        this.checkboxesVisible = !this.checkboxesVisible;
        this.hideShow.emit(this.name);

    }

    public hideCheckboxes() {
        this.checkboxesVisible = false;
        this.slider.nativeElement.style.maxHeight = '0px';
    }

    public isOptionChecked(option: any) {
        return this.checkedValues.find(value => String(value) === option[this.keyName] || value === option[this.keyName]) != null;
    }

    public onChange(event, option) {
        if (event.checked) {
            this.checkedValues.push(option[this.keyName]);
        }
        else {
            this.checkedValues = this.checkedValues.filter(item => item !== option[this.keyName] && String(item) !== option[this.keyName]);
            this.allCheckboxChecked = false;
        }

        if (this.options.value.length === this.checkedValues.length) {
            this.allCheckboxChecked = true;
        }

        this.allChecked = false;

        this.propagateChange(this.checkedValues);
    }

    public onChangeAll(isChecked: boolean) {
        this.allCheckboxChecked = isChecked;
        this.setOptionsCheckedValues(isChecked);
        this.propagateChange(this.checkedValues);
    }

    propagateChange = (_: any) => { };

    registerOnChange(fn: any) { this.propagateChange = fn; }

    registerOnTouched(fn: any): void { }

    setDisabledState(isDisabled: boolean): void { }

    writeValue(checkedValues: any): void {
        this.checkedValues = checkedValues ? checkedValues.reduce((prev, curr) => { prev.push(curr); return prev; }, []) : [];
        if (!this.options.value) {
            return;
        }
        this.allCheckboxChecked = (this.options.value.length === this.checkedValues.length) && this.checkedValues.length !== 0;

        // reset case
        if (checkedValues == null) {
            this.allChecked = this.initialValues.checkAllCheckbox;
            if (this.initialValues.checkboxesVisible !== this.checkboxesVisible) {
                this.toggleCollapse();
            }
        }

        this.reflectAllChanged();
    }

    reflectAllChanged() {
        if (this.allChecked && this.checkedValues.length === 0) {
            this.setOptionsCheckedValues(true);
            this.allCheckboxChecked = true;
        }
    }
}
