import { BiblioState, defaultBiblioState } from './bibliothek';
import { defaultEventState, EventsState } from './events';
import { defaultJobState, JobsState } from './jobs';
import { defaultRechercheState, RechercheState } from './research';

import { AboServiceState, defaultAboServiceState } from './state/aboService';
import { AccessState, defaultAccessState } from './state/access';
import { AccountState, defaultAccountState } from './state/account';
import { CommonAppState, defaultCommonAppState } from './state/commonApp';
import { CustomerProfileState, defaultCustomerProfileState } from './state/customerProfile';
import { defaultFieldState, FieldState } from './state/field';
import { defaultHomeState, HomeState } from './state/home';
import { defaultNewsState, NewsState } from './state/news';

export type Guid = string;

export interface AppState {
    home: HomeState;
    recherche: RechercheState;
    news: NewsState;
    biblio: BiblioState;
    events: EventsState;
    jobs: JobsState;
    access: AccessState;
    account: AccountState;
    customerProfile: CustomerProfileState;
    aboService: AboServiceState;
    field: FieldState;
    app: CommonAppState;
}

export const defaultAppState = {
    home: defaultHomeState,
    recherche: defaultRechercheState,
    news: defaultNewsState,
    biblio: defaultBiblioState,
    events: defaultEventState,
    jobs: defaultJobState,
    access: defaultAccessState,
    account: defaultAccountState,
    customerProfile: defaultCustomerProfileState,
    aboService: defaultAboServiceState,
    field: defaultFieldState,
    app: defaultCommonAppState,
};

// export interface ViewConstraints {
//     windowWidth: number;
// }

export function getInitialState() {
    return defaultAppState;
}
