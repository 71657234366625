import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

import { LoginService } from '../../access';

@Component({
    selector: 'slx-users',
    template: '<router-outlet></router-outlet>',
})
export class UsersComponent implements OnInit {

    constructor(private loginService: LoginService, private router: Router) { }

    ngOnInit() {
        forkJoin(
            this.loginService.isCorpAdmin.pipe(take(1)),
            this.loginService.isDepartmentAdmin.pipe(take(1)))
            .subscribe(([isCorp, isDep]) => {
                if (isCorp && !isDep) {
                    this.router.navigate(['/customerprofile/userprofile']);
                }
            });
    }
}
