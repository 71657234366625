import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import {
    eventsCreateEventUrl, eventsGetAllPracticeAreasBaseUrl, eventsGetCurrentEventsBaseUrl,
    eventsGetEventBaseUrl, SlxHttp
} from '../access';
import { AccountActions, AccountService } from '../account';
import { catchErrorForAction, EventsState, makeAction, SlxAction } from '../models';
import { AlertType } from '../models/state/commonApp';

import { EventsService } from './events.service';
import { EventsActions } from './eventsActions';

@Injectable()
export class EventsEffects {

    private numberOfEvents = 30;

    @Effect() createEvent = this.actions$.pipe(
        ofType(EventsActions.create_event.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.post(eventsCreateEventUrl, action.payload, false).pipe(
                map(payload => makeAction({ result: EventsActions.create_event.name, payload, alert: { key: 'account-successful', duration: 10 } })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' })
            )
        )
    );

    @Effect() reloadLanguageDependentData = this.actions$.pipe(
        ofType(AccountActions.reload_language_dependent_data_events.name),
        withLatestFrom(this.eventsService.store.select('events') as Observable<EventsState>),
        map(([action, state]) => this.eventsService.getActionForPath(state.eventsSubTab, state.eventDetailsID))
    );

    @Effect() loadCurrentEvents = this.actions$.pipe(
        ofType(EventsActions.load_current_events.name),
        switchMap(action =>
            this.slxHttp.get(`${eventsGetCurrentEventsBaseUrl}?language=${this.accountService.lang}`, false).pipe(
                map(payload => makeAction({ result: EventsActions.load_current_events.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' })
            )
        )
    );

    @Effect() loadSingleEvent = this.actions$.pipe(
        ofType(EventsActions.load_single_event.name),
        withLatestFrom(this.eventsService.store.select('events') as Observable<EventsState>),
        switchMap(([action, state]) =>
            this.slxHttp.get(`${eventsGetEventBaseUrl}?eventId=${state.eventDetailsID}&language=${this.accountService.lang}`, false).pipe(
                map(payload => makeAction({ result: EventsActions.load_single_event.name, payload }))
            )
        )
    );

    @Effect() loadPracticeAreas = this.actions$.pipe(
        ofType(EventsActions.load_practice_areas.name),
        switchMap(action =>
            this.slxHttp.get(`${eventsGetAllPracticeAreasBaseUrl}?language=${this.accountService.lang}`, false).pipe(
                map(payload => makeAction({ result: EventsActions.load_practice_areas.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' })
            )
        )
    );

    constructor(private eventsService: EventsService, private actions$: Actions, private slxHttp: SlxHttp, private accountService: AccountService) {
    }
}
