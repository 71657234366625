import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const EMAIL_PREFIX_REGEXP = /[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]$/;
//const ZIPCODE_REGEXP = /(?:^[*]{1,20}$)/;
const ZIPCODE_MAX_LENGTH = 20;
const PHONE_FAX_REGEXP = /^(\+|00[1-9]\d{0,3}|0 ?[1-9]|\(00? ?[1-9][\d ]*\))[\d\-\(\)\/\. ]*$/;
const NUMBER_REGEXP = /(?:^[0-9]+$)/;
const TIMEPATTERN_REGEXP = /([01]?[0-9]|2[0-3]):[0-5][0-9]/;
const LINKPATTERN_REGEXP = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.\@\-]*)*\/?$/;



function isEmptyInputValue(value: any): boolean {
    return value == null || value.length === 0;
}

export function timePatternValidator(key: string): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        return isEmptyInputValue(control.value) ? null : TIMEPATTERN_REGEXP.test(control.value) ? null : { 'timepattern': { translate: key } };
    };
}

export function linkPatternValidator(key: string): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        return isEmptyInputValue(control.value) ? null : LINKPATTERN_REGEXP.test(control.value) ? null : { 'linkpattern': { translate: key } };
    };
}

export function emailValidator(key: string): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        return isEmptyInputValue(control.value) ? null : EMAIL_REGEXP.test(control.value) ? null : { 'email': { translate: key } };
    };
}

export function emailPrefixValidator(key: string): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        return EMAIL_PREFIX_REGEXP.test(control.value) ? null : { 'email-prefix': { translate: key } };
    };
}

export function zipCodeValidator(key: string): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        return isEmptyInputValue(control.value) || control.value.length <= ZIPCODE_MAX_LENGTH ? null : { 'zip-code': { translate: key } };
    };
}

export function phoneFaxNumberValidator(key: string): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        return isEmptyInputValue(control.value) ? null : PHONE_FAX_REGEXP.test(control.value) ? null : { 'phone-fax-number': { translate: key } };
    };
}

export function numberValidator(key: string): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        if (isEmptyInputValue(control.value)) return null;
        return NUMBER_REGEXP.test(control.value) ? null : { 'only-numbers': { translate: key } };
    };
}

export function requiredValidator(key?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return isEmptyInputValue(control.value) ? {
            required: { translate: key ? key : 'form-validation-required' },
        } : null;
    };
}

export function checkboxRequiredValidator(key: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return control.value === false || control.value === '' || control.value === null ? {
            required: { translate: key },
        } : null;
    };
}

export function equalsOtherValidator(key: string, other) {
    return (control: FormControl): ValidationErrors | null => {
        if (!control) return null;
        return (control as any)._parent === undefined || control.value === (control as any)._parent.controls[other].value ? null : {
            equals: { translate: key },
        };
    };
}

export function equalsInGroupValidator(key: string, ...names) {
    return (group: FormGroup) => {
        const first = group.controls[names[0]].value;
        let valid = true;
        names.forEach(name => {
            const val = group.controls[name].value;
            if (val === first) { valid = false; }
        });
        return valid ? null : {
            equals: { translate: key },
        };
    };
}

export function minLengthValidator(key: string, minLength: number, ignoreBlank = false): ValidatorFn {
    return (control: FormControl, quality = false): ValidationErrors | null => {
        const length: number = control.value ? control.value.length : 0;
        if (ignoreBlank && isEmptyInputValue(control.value)) return null;
        if (quality) {
            return {
                quality: Math.min(minLength, length),
                maxQuality: minLength,
            };
        }
        if (isEmptyInputValue(control.value)) {
            return null;  // don't validate empty values to allow optional controls
        }
        return length < minLength ?
            {
                'minlength': {
                    translate: key,
                    requiredLength: minLength,
                    actualLength: length,
                    quality: Math.min(minLength, length),
                    maxQuality: minLength,
                },
            } :
            null;
    };
}



export function maxLengthValidator(key: string, maxLength: number): ValidatorFn {
    return (control: FormControl, quality = false): ValidationErrors | null => {
        const length: number = control.value ? control.value.length : 0;
        if (quality) {
            return {
                quality: Math.max(length - maxLength, 3), // 0..3
                maxQuality: 3,
            };
        }
        return length > maxLength ?
            {
                'maxlength': {
                    translate: key,
                    requiredLength: maxLength,
                    actualLength: length,
                    quality: Math.max(length - maxLength, 3), // 0..3
                    maxQuality: 3,
                },
            } :
            null;
    };
}

export function uppercaseValidator(key: string, min: number = 1, ignoreBlank = false): ValidatorFn {
    return (control: FormControl, quality = false): ValidationErrors | null => {
        const uppercase = (control.value || '').split('').reduce((count, char) => Number.isNaN(Number.parseInt(char)) && char === char.toUpperCase() ? count + 1 : count, 0);
        if (ignoreBlank && isEmptyInputValue(control.value)) return null;
        if (quality) {
            return {
                quality: Math.min(min, uppercase), // 0..min
                maxQuality: min,
            };
        }
        return uppercase < min ? {
            uppercase: {
                translate: key,
                count: uppercase,
                quality: Math.min(min, uppercase), // 0..min
                maxQuality: min,
                weight: 1,
            },
        } : null;
    };
}

export function combinedQualityValidator(...validators) {
    return (control: FormControl): ValidationErrors | null => {
        const checks = validators.map(v => v(control, true)).filter(r => r);
        const totals = checks.reduce((combined, e) => ({
            quality: combined.quality + e.quality,
            maxQuality: combined.maxQuality + (e.maxQuality || 1),
        }), { quality: 0, maxQuality: 0 });
        const withKeys = validators.map(v => v(control)).filter(r => r);
        const errors = withKeys.reduce((combined, e) => ({
            ...combined,
            ...e,
        }), {});

        return totals.quality < totals.maxQuality ? {
            ...errors,
            // ...checks[0],
            totals,
        } : null;
    };
}

export function fileSizeValidator(key: string, fileSize: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return isEmptyInputValue(control.value) ? null : control.value.size > fileSize ? { fileSize: { translate: key } } : null;
    };
}
export function fileTypeValidator(key: string, fileTypes: string | string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const controlType: string = control.value ? control.value.type : undefined;
        if (isEmptyInputValue(control.value) || controlType === undefined) {
            return null;
        }
        if (!(fileTypes instanceof Array)) {
            return controlTypeIsValid(controlType, fileTypes) ? null : { fileType: { translate: key } };
        }
        for (const fileType of fileTypes) {
            if (controlTypeIsValid(controlType, fileType)) {
                return null;
            }
        }
        return { fileType: { translate: key } };
    };
}

function controlTypeIsValid(controlType: string, fileType: string) {
    const match = /(^[a-z]+)\/\*$/.exec(fileType);
    const fileTypesRoot = match ? fileType.substring(0, match[1].length) : undefined;
    return controlType === fileType || (fileTypesRoot && controlType.length > fileTypesRoot.length && fileTypesRoot === controlType.substring(0, fileTypesRoot.length));
}

export function getValidationMessage(formControl, translate) {
    const errors = formControl.errors || {};
    const errorTexts = Object.keys(errors).map(key => {
        const error = errors[key];

        if (error && error.translate) {
            const translated = translate.instant(error.translate, error);
            if (translated !== error.translate) return translated;
        }
        return null;
    }).filter(value => value);

    return errorTexts.join('. ');
}





