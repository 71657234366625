import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { NewsService } from './../../news.service';

@Component({
  selector: 'slx-wizard-search-advanced',
  templateUrl: './wizard-search-advanced.component.html',
})

export class WizardSearchAdvancedComponent implements OnDestroy {

  @Input() form: FormGroup;

  private paSub: Subscription;
  private docCatSub: Subscription;

  public paFilters: Array<any>;
  public docCatFilters: Array<any>;

  constructor(private newsService: NewsService) {
    this.paSub = this.newsService.practiceAreaFilters.subscribe(filters => this.paFilters = filters);
    this.docCatSub = this.newsService.documentCategoryFilters.subscribe(filters => this.docCatFilters = filters);
  }

  get practiceAreaGroupsCriteria(): FormControl {
    return this.form.get('practiceAreaGroupsCriteria') as FormControl;
  }

  get assetTypeGroupsCriteria(): FormControl {
    return this.form.get('assetTypeGroupsCriteria') as FormControl;
  }

  get publicationFilter(): FormControl {
    return this.form.get('publicationFilter') as FormControl;
  }

  ngOnDestroy(): void {
    this.paSub.unsubscribe();
    this.docCatSub.unsubscribe();
  }
}
