import { AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy } from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';
import { Subscription } from 'rxjs';
import { filter, skip } from 'rxjs/operators';

import { AppActions } from '../../appActions';
import { AssetActions, AssetService } from '../../asset';
import { AssetTab, DocumentContent } from '../../models';
import { isResponsive } from '../../utility/utilityFunctions';
import { SearchService } from '../search.service';
import { SearchActions } from '../searchActions';

@Component({
    selector: 'slx-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnDestroy, AfterViewInit {

    @Input() sidebarWidth;

    private subscription: Subscription = new Subscription();
    private observer: ResizeObserver;

    public favAnimation: boolean;
    public collectionTab: string;
    public sidebarOpen: boolean;
    public verticalHitlist: boolean;
    public activeAssetTab: AssetTab;

    constructor(public searchService: SearchService, public assetService: AssetService, private containerRef: ElementRef, private ngZone: NgZone) {

        this.subscription
            .add(this.searchService.collectionTab.subscribe(tab => this.collectionTab = tab))
            .add(this.searchService.hitlistOpen.subscribe(sidebarOpen => {
                this.sidebarOpen = sidebarOpen;
                // this.verticalHitlist = this.sidebarWidth <= 510 ? true : false;
            }))
            .add(this.searchService.userFavorites.pipe(filter(favs => favs.length > 0), skip(1)).subscribe(favs => {
                this.favAnimation = true;
                setTimeout(() => { this.favAnimation = false; }, 550);
            }))
            .add(this.assetService.activeAssetTabForToc.subscribe(tab => {
                setTimeout(() => {
                    const asset = tab ? this.assetService.getCachedAsset(tab.assetRef) : null;
                    const navigationInfo = asset ? (asset.content as DocumentContent).navigationInfo : null;
                    this.activeAssetTab = navigationInfo && navigationInfo.canShowToc ? tab : null;
                });
            }));
    }


    public switchTab(name) {
        this.searchService.dispatch({ type: AppActions.set_collection_tab.name, payload: { collectionTab: name } });
    }

    public toggleResize() {
        // Set Default as Favorites, when nothing is defnied - i.e after Reload or when starting with a doc-view
        if (!this.collectionTab) {
            this.collectionTab = 'favorites';
        }

        this.searchService.dispatch({ type: SearchActions.toggle_hitlist_open.name });
    }

    public isResponsive() {
        return isResponsive();
    }
    public clearRecherche() {
        this.searchService.dispatch({ type: AssetActions.clear_recherche_results.name });
    }

    ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular(() => {
            this.observer = new ResizeObserver((entries, observer) => {
                entries.forEach(entry => {
                    const verticalH = entry.contentRect.width <= 520 ? true : false;

                    if (this.verticalHitlist === verticalH) {
                        return;
                    }

                    this.verticalHitlist = verticalH;
                    this.searchService.dispatch({ type: SearchActions.set_vertical_hitlist.name, payload: verticalH });
                });
            });
            this.observer.observe(this.containerRef.nativeElement);
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.observer.disconnect();
    }
}
