import { PracticeArea } from '../state/practiceArea';

import { Event } from './event';

export * from './event';
export * from '../state/practiceArea';

export interface EventsState {
    eventsSubTab: string;
    currentEventsList: Event[];
    practiceAreas: PracticeArea[];
    eventDetails: any;
    eventDetailsID: string;
    choosenDateFromCalendar: string;
    filterFromDetail: boolean;
}
export const defaultEventState = {
    eventsSubTab: '',
    currentEventsList: [],
    practiceAreas: [],
    eventDetails: {},
    eventDetailsID: '',
    choosenDateFromCalendar: undefined,
    filterFromDetail: false,
};
