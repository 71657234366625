import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { filter, first } from 'rxjs/operators';

import { AccountService } from '../../../account';
import { AppActions } from '../../../appActions';
import { CustomerProfileActions } from '../../../customer-profile/customerProfileActions';
import { numberValidator, requiredValidator } from '../../../field';
import { ActionState } from '../../../models/state/commonApp';
import { markFormAsTouched } from '../../../utility/utilityFunctions';

@Component({
    selector: 'slx-add-new-department',
    templateUrl: './addNewDepartment.component.html',
    styleUrls: ['./addNewDepartment.component.scss'],
})
export class AddNewDepartmentComponent implements OnInit {

    public departmentForm: FormGroup;

    constructor(public dialogRef: MatDialogRef<AddNewDepartmentComponent>, @Inject(MAT_DIALOG_DATA) public company, private formBuilder: FormBuilder, public accountService: AccountService) {
        this.departmentForm = this.formBuilder.group({
            name: ['', requiredValidator()],
            isActivated: [true],
            creditLimit: ['', numberValidator('service-reg-comp-nol-notvalid-val-txt')],
        });
    }

    ngOnInit() {
        this.accountService.dispatch({ type: AppActions.clear_state_of_action.name });
        this.accountService.stateOfAction(CustomerProfileActions.save_add_department.name).pipe(filter(res => res === ActionState.Success), first())
            .subscribe(() => {
                this.accountService.singleDepartmentData.pipe(first()).subscribe(department => {
                    setTimeout(() => { this.dialogRef.close(department.id); }, 2000);
                });
            });
    }

    onSubmit(departmentForm) {
        if (!departmentForm.valid) {
            markFormAsTouched(departmentForm);
            return;
        }

        const departmentFormData = {
            company: this.company,
            ...departmentForm.value,
        };
        this.accountService.dispatch({ save: CustomerProfileActions.save_add_department.name, payload: departmentFormData });
    }
}
