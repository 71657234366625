import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AccountService } from '../../../account';
import { AutocompleteService } from '../../../asset';
import { requiredValidator } from '../../../field';
import { AutocompleteParamter, AutocompleteType, SearchType } from '../../../models';
import { SearchService } from '../../search.service';
import { SearchFormBase } from '../search-form-base';

@Component({
    selector: 'slx-eu-case-law-search',
    template: `
        <form novalidate class="slx-form" [formGroup]="this.searchForm" (ngSubmit)="onSubmit()" autocomplete="off">
            <h4 translate="rech-caseCollection"></h4>
            <div class="slx-form-row relative">
                <slx-text class="slx-field-small" type="text" placeholder="rech-casenumber" formControlName="searchText" [autocomplete]="getAutocomplete()"></slx-text>
                <slx-info-button filename="eu_case-law-help" foldername="Eu" titleTransString="rech-info-eu_case-law-help" class="in-form"></slx-info-button>
            </div>
            <div class="controls">
                <button class="slx-icn-btn r-mrgn" type="button" (click)="this.searchForm.reset()" [title]="'rech-button-desc-form-reset' | translate"><i class="material-icons">clear</i><span translate="rech-clear"></span></button>
                <button class="slx-icn-btn" type="submit"><i class="material-icons">search</i><span translate="rech-display"></span></button>
            </div>
        </form>
    `,
    styles: [`
        .controls {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
        }
        h4 {
            margin: 0;
        }
    `],
})
export class EuCaseLawComponent extends SearchFormBase {

    private language;
    private onLangChangedSubscription: Subscription;

    constructor(protected searchService: SearchService, protected formBuilder: FormBuilder, private autocompleteService: AutocompleteService, private accountService: AccountService, protected changeDetectorRef: ChangeDetectorRef, protected translate: TranslateService) {
        super(searchService, SearchType.EuCaseReference,changeDetectorRef, translate);

        // bind autocomplete get url functions (this)
        this.getAutocomplete = this.getAutocomplete.bind(this);

        this.searchForm = this.formBuilder.group({
            searchText: ['', requiredValidator()],
            language: '',
        });

        this.language = this.accountService.lang;
        this.onLangChangedSubscription = this.accountService.onLangChange.subscribe(res => {
            this.language = this.accountService.lang;
            this.searchForm.controls['language'].setValue(this.language);
        });
    }

    ngOnDestroy(){
        this.onLangChangedSubscription.unsubscribe();
        super.ngOnDestroy();
    }

    getAutocomplete() {
        return (suchtext) => {
            const parameters = [
                new AutocompleteParamter('reference', this.searchForm.value.searchText),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.EuCaseReference, false, parameters);
        };
    }
}
