import { CustomerProfileGoBackType } from './../../../models/enums';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { LoginService } from '../../../access';
import { AccountService } from '../../../account';
import { CustomerProfileActions } from '../../../customer-profile/customerProfileActions';
import { emailValidator, numberValidator, requiredValidator } from '../../../field';
import { markFormAsTouched } from '../../../utility/utilityFunctions';

@Component({
    selector: 'slx-single-user',
    templateUrl: './singleUser.component.html',
    styleUrls: ['./singleUser.component.scss'],
})
export class SingleUserComponent implements OnInit, OnDestroy {

    private subscription: Subscription = new Subscription();

    public userForm: FormGroup;
    public addressTerms: Array<{ key: string, value: string }>;
    public addressTitles: Array<{ key: string, value: string }>;
    public departments: Array<{ key: string, value: string }>;
    public permissionLevels: Array<{ key: string, value: string }>;
    public languages: Array<{ key: string, value: string }> = [
        { key: 'DE', value: 'account-german' },
        { key: 'FR', value: 'account-french' },
    ];
    public userData: any;
    public userID: string;
    public loginUserID: string;
    public isDepartmentAdmin: boolean;
    public isCorpAdmin: boolean;
    public isJointVentureAdmin: boolean;
    public isSuperUser: boolean;
    public goBackRoute: string;

    constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, public accountService: AccountService, public loginService: LoginService) {
        this.userID = this.route.snapshot.params['id'];
        this.goBackRoute = this.getGoBackUrl();

        this.userForm = this.formBuilder.group({
            addressTerm: [''],
            title: [''],
            firstName: ['', requiredValidator()],
            name: ['', requiredValidator()],
            departmentID: [''],
            email: ['', [requiredValidator(), emailValidator('service-reg-email-notvalid-val-txt')]],
            isActivated: [''],
            hasCorporateNewsletter: [''],
            level: [''],
            language: [''],
            creditLimit: ['', numberValidator('service-reg-comp-nol-notvalid-val-txt')],
        });
    }

    ngOnInit() {
        this.accountService.dispatch({ fetch: CustomerProfileActions.users_data.name });

        this.subscription
            .add(this.accountService.store.select(state => state.customerProfile.usersData ? state.customerProfile.usersData.find(usr => usr.id === this.userID) : null)
                .subscribe(data => {
                    this.userData = data;
                    this.setDefaultFormValues();
                }))
            .add(this.accountService.onLangChange.subscribe(res => this.setDefaultFormValues()))
            .add(this.accountService.userId.subscribe(id => this.loginUserID = id))
            .add(this.accountService.store.select((state) => state.access.userFunctionRights.includes('RF:DepartmentAdministrator'))
                .subscribe(data => this.isDepartmentAdmin = data))
            .add(this.accountService.store.select((state) => state.access.userFunctionRights.includes('RF:CorporateAdministrator'))
                .subscribe(data => this.isCorpAdmin = data))
            .add(this.accountService.store.select((state) => state.access.userFunctionRights.includes('RF:JointVentureAdministrator'))
                .subscribe(data => this.isJointVentureAdmin = data))
            .add(this.accountService.store.select((state) => state.access.userFunctionRights.includes('RF:SuperUser'))
                .subscribe(data => this.isSuperUser = data));
    }

    private get addressTerm(): FormControl {
        return this.userForm.get('addressTerm') as FormControl;
    }

    //TODO: funktioniert in diesem Fall key/value dropdown nicht.
    // private get language(): FormControl {
    //     return this.userForm.get('language') as FormControl;
    // }

    private get isActivated(): FormControl {
        return this.userForm.get('isActivated') as FormControl;
    }

    private getGoBackUrl(): string{
        const goBack = this.route.snapshot.queryParams['goBack'];

        if(goBack === CustomerProfileGoBackType.SingleDepartment){
            const departmentID = this.route.snapshot.queryParams['departmentID'];
            return `/customerprofile/departments/singledepartment/${departmentID}`;
        }
        else{
            return '/customerprofile/users/allusers';
        }
    }

    private setDefaultFormValues(): void {
        // Function Guard because it´s possible that a lang change gets triggered without the user is actuallay changing the language.
        // (At The moemnt thrugh handleAuth Sucess - it makes a load language request that triggers lang change Event)
        if (!this.userData) {
            return;
        }

        this.userData.title = this.userData.title === null ? '-' : this.userData.title;

        if (this.accountService.lang === 'de') {

            this.addressTerms = [
                { key: 'Herr', value: 'Herr' },
                { key: 'Frau', value: 'Frau' },
            ];

            this.addressTitles = [
                { key: '-', value: '-' },
                { key: 'RA', value: 'RA' },
                { key: 'Dr.', value: 'Dr.' },
                { key: 'Prof.', value: 'Prof.' },
            ];

            switch (this.userData.addressTerm) {
                case 'Monsieur':
                case 'Maître':
                    this.addressTerm.setValue('Herr');
                    break;
                case 'Madame':
                    this.addressTerm.setValue('Frau');
                    break;
                default:
                    this.addressTerm.setValue(this.userData.addressTerm);
                    break;
            }

            //this.userData.language === 'FR' ? this.language.setValue('DE') : this.language.setValue(this.userData.language);

            if (this.isDepartmentAdmin) {
                this.permissionLevels = [
                    { key: '20 - Corporate User', value: 'Benutzer' },
                    { key: '25 - Department Administrator', value: 'Abteilungsverwalter' },
                ];
            }

            if (this.isCorpAdmin) {
                if (this.userID === this.loginUserID) {
                    this.permissionLevels = [
                        { key: '30 - Corporate Administrator', value: 'Firmaverwalter' },
                    ];
                } else {
                    this.permissionLevels = [
                        { key: '20 - Corporate User', value: 'Benutzer' },
                        { key: '25 - Department Administrator', value: 'Abteilungsverwalter' },
                        { key: '30 - Corporate Administrator', value: 'Firmaverwalter' },
                    ];
                }
            }

            if (this.isJointVentureAdmin || this.isSuperUser) {
                this.permissionLevels = [
                    { key: '20 - Corporate User', value: 'Benutzer' },
                    { key: '25 - Department Administrator', value: 'Abteilungsverwalter' },
                    { key: '30 - Corporate Administrator', value: 'Firmaverwalter' },
                    { key: '40 - Joint Venture Administrator', value: 'Joint Venture Administrator' },
                    { key: '50 - Super User', value: 'Super User' },
                ];
            }
        } else {

            this.addressTerms = [
                { key: 'Monsieur', value: 'Monsieur' },
                { key: 'Maître', value: 'Maître' },
                { key: 'Madame', value: 'Madame' },
            ];

            this.addressTitles = [
                { key: null, value: '-' },
                { key: 'av.', value: 'av.' },
            ];

            switch (this.userData.addressTerm) {
                case 'Herr':
                    this.addressTerm.setValue('Monsieur');
                    break;
                case 'Frau':
                    this.addressTerm.setValue('Madame');
                    break;
                default:
                    this.addressTerm.setValue(this.userData.addressTerm);
                    break;
            }

            //this.userData.language === 'DE' ? this.language.setValue('FR') : this.language.setValue(this.userData.language);

            if (this.isDepartmentAdmin) {
                this.permissionLevels = [
                    { key: '20 - Corporate User', value: 'Corporate User' },
                    { key: '25 - Department Administrator', value: 'Department Administrator' },
                ];
            }
            if (this.isCorpAdmin) {
                if (this.userID === this.loginUserID) {
                    this.permissionLevels = [
                        { key: '30 - Corporate Administrator', value: 'Corporate Administrator' },
                    ];
                } else {
                    this.permissionLevels = [
                        { key: '20 - Corporate User', value: 'Corporate User' },
                        { key: '25 - Department Administrator', value: 'Department Administrator' },
                        { key: '30 - Corporate Administrator', value: 'Corporate Administrator' },
                    ];
                }
            }
            if (this.isJointVentureAdmin || this.isSuperUser) {
                this.permissionLevels = [
                    { key: '20 - Corporate User', value: 'Corporate User' },
                    { key: '25 - Department Administrator', value: 'Department Administrator' },
                    { key: '30 - Corporate Administrator', value: 'Corporate Administrator' },
                    { key: '40 - Joint Venture Administrator', value: 'Joint Venture Administrator' },
                    { key: '50 - Super User', value: 'Super User' },
                ];
            }
        }

        if (this.userData.company) {
            this.departments = this.userData.company.departments;
            this.userForm.controls['departmentID'].setValue(this.userData.departmentID);
        }

        this.userForm.controls['language'].setValue(this.userData.language);
        this.userForm.controls['title'].setValue(this.userData.title);
        this.userForm.controls['firstName'].setValue(this.userData.firstName);
        this.userForm.controls['name'].setValue(this.userData.name);
        this.userForm.controls['email'].setValue(this.userData.email);
        this.userForm.controls['isActivated'].setValue(this.userData.isActivated);
        this.userForm.controls['hasCorporateNewsletter'].setValue(this.userData.hasCorporateNewsletter);
        this.userForm.controls['level'].setValue(this.userData.level);
        this.userForm.controls['creditLimit'].setValue(this.userData.creditLimit);
    }

    onSubmit(userForm) {
        if (!userForm.valid) {
            markFormAsTouched(userForm);
            return;
        }

        const userFormData = {
            company: this.userData.company,
            isUni: false,
            id: this.userData.id,
            ...userForm.value,
        };
        this.accountService.dispatch({ save: CustomerProfileActions.save_user_profile.name, payload: userFormData });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
