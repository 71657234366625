import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CellClickedEvent, GridApi, GridOptions, GridReadyEvent, ICellRenderer, ICellRendererParams, RowNode } from 'ag-grid-community';
import { BehaviorSubject, forkJoin, Observable, of, Subject, Subscription, zip } from 'rxjs';
import { map, single } from 'rxjs/operators';

import { deleteFaqEntryUrl, deleteTopicUrl, errorToAlert, getAllFaqsUrl, getFaqOptionsUrl, putTopicNameUrl, SlxHttp } from '../../../../access';
import { DisplayableOption } from '../../../../models/faq/displayableOption';
import { FaqOverviewModel } from '../../../../models/faq/faq-list/faqOverviewViewModel';
import { TopicAction } from '../../../../models/faq/faq-list/topicAction';
import { Option } from '../../../../models/faq/option';
import { gridButtonRenderer } from '../../../../utility/utilityFunctions';


import { AppState } from './../../../../models';
import { AlertType } from './../../../../models/state/commonApp';
import { FaqListGroupRendererComponent } from './faq-list-grouprenderer/faq-list-grouprenderer.component';

@Component({
    selector: 'slx-faq-list',
    templateUrl: './faq-list.component.html',
    styleUrls: ['./faq-list.component.scss'],
})
export class FaqListComponent implements OnInit, OnDestroy {
    public gridOptions: GridOptions;
    public columnDefs: any[];
    private faqsSubject: Subject<FaqOverviewModel[]> = new BehaviorSubject<FaqOverviewModel[]>(null);
    public faqsObservable = this.faqsSubject.asObservable();
    private rows: RowNode[];
    @ViewChild('inputTopicName') inputTopicName: ElementRef;
    public undisplayedOptions: DisplayableOption[];
    public subscription: Subscription = new Subscription();

    constructor(private translate: TranslateService, private slxHttp: SlxHttp, private router: Router, private store: Store<AppState>) { }

    ngOnInit() {
        this.onGridReady = this.onGridReady.bind(this);

        this.gridOptions = <GridOptions>{
            onRowDataChanged: () => {
                if (this.gridOptions && this.rows) {
                    const groups = this.rows.filter(row => row.group);
                    this.gridOptions.api.forEachNode(row => {
                        if (row.group) {
                            const previousGroup = groups.find(group => group.key === row.key);
                            row.setExpanded(previousGroup ? previousGroup.expanded : false);
                        }
                    });
                }
            },
            groupMultiAutoColumn: true,
            enableColResize: true,
            rowHeight: 50,
            headerHeight: 50,
            rowSelection: 'single',
            suppressRowClickSelection: true,
            getRowNodeId: data => data.id,
            context: { translate: this.translate },
            onModelUpdated: params => {
                if (params.newData) {
                    // update
                }
            },
            groupUseEntireRow: true,
            groupRowInnerRendererFramework: FaqListGroupRendererComponent,
            groupRowRendererParams: {
                suppressCount: true,
                onClick: this.rendererAction.bind(this),
                column: {
                    isCellEditable: () => true,
                },
            },
        };

        this.columnDefs = [
            { field: 'id', headerName: 'faq-id', menuTabs: [], minWidth: 300, maxWidth: 300 },
            { field: 'titleDe', headerName: 'faq-title-de', menuTabs: [] },
            { field: 'titleFr', headerName: 'faq-title-fr', menuTabs: [] },
            { field: 'topicName', headerName: 'faq-topic-name', menuTabs: [], rowGroup: true, hide: true, maxWidth: 50 },
            { field: 'delete', headerName: 'faq-delete', menuTabs: [], cellRenderer: gridButtonRenderer, minWidth: 100, maxWidth: 100 },
        ];
        this.updateFaqObservable();
    }

    private rendererAction(params: {
        action: TopicAction,
        passedData: any,
        renderer: ICellRendererParams
    }) {
        this.setTopic(params.action, params.renderer.value, params.passedData);
    }

    private setTopic(action: TopicAction, viewValue: string, otherData?: any) {
        switch (action) {
            case TopicAction.delete:
                this.store.dispatch({
                    type: 'alert',
                    payload: {
                        type: AlertType.Warning,
                        text: 'faq-remove-topic-text',
                        key: 'faq-remove-topic-title',
                        actions: [
                            {
                                type: 'no_dispatch',
                                linkKey: 'faq-remove-topic-confirm',
                                execute: () => this.getOptionIDObservable(viewValue).subscribe(id => this.deleteTopic(id)),
                            },
                        ],
                        noLinkMargin: true,
                    },
                });
                break;
            case TopicAction.save:
                if (otherData && otherData.newValue && otherData.newValue !== viewValue) {
                    this.getOptionIDObservable(viewValue).subscribe(id => this.updateTopicKey(id, otherData.newValue));
                }
                break;
            default:
                break;
        }
    }

    private getOptionIDObservable(viewValue: string): Observable<string> {
        const observable = this.slxHttp.get(`${getFaqOptionsUrl}`, false).pipe(map(((options: Option[]) => options.find(option => option.topic.translateViewValue === `faq-${viewValue}`).topic.value)));
        return observable;
    }

    private updateFaqObservable() {
        if (this.gridOptions.api) {
            this.gridOptions.api.showLoadingOverlay();
        }
        this.subscription.add(forkJoin(
            this.slxHttp.get(`${getAllFaqsUrl}`, false),
            this.slxHttp.get(`${getFaqOptionsUrl}`, false)
        ).subscribe((((res: [FaqOverviewModel[], Option[]]) => {
            const rowData = res[0];
            const options = res[1];
            if (this.gridOptions.api) {
                this.rows = [];
                this.gridOptions.api.forEachNode(row => {
                    this.rows.push(row);
                });
            }
            this.undisplayedOptions = [];
            options.forEach(option => rowData.find(singleRow => `faq-${singleRow.topicName}` === option.topic.translateViewValue) ? null : this.undisplayedOptions.push({
                option,
                editMode: false,
            }));
            this.faqsSubject.next(rowData);
        }))));
    }

    public onGridReady(gridEvent: GridReadyEvent) {
        this.gridOptions.api = gridEvent.api;
    }

    public onCellClicked($event: CellClickedEvent) {
        switch ($event.colDef.field) {
            case 'delete':
                this.store.dispatch({
                    type: 'alert',
                    payload: {
                        type: AlertType.Warning,
                        text: 'faq-remove-text',
                        key: 'faq-remove-title',
                        actions: [
                            {
                                type: 'no_dispatch',
                                linkKey: 'faq-remove-confirm',
                                execute: () => this.deleteFaqEntry($event.data.id),
                            },
                        ],
                        noLinkMargin: true,
                    },
                });
                break;
            default:
                if ($event.data && $event.data.id) {
                    this.router.navigateByUrl(`/de/faq/entry/edit/${$event.data.id}`);
                }
                break;
        }
    }

    private getViewValue(translateViewValue: string) {
        const matchViewValue = /^(faq-)[-A-Za-z0-9]+/.exec(translateViewValue);
        const viewValue = matchViewValue ? translateViewValue.substring(matchViewValue.index + matchViewValue[1].length) : undefined;
        return viewValue;
    }

    private updateTopicKey(id: string, newValue: string) {
        this.slxHttp.put(`${putTopicNameUrl}/${id}?newName=${newValue}`, {}).subscribe(() => this.updateFaqObservable());
    }

    private deleteTopic(id: string) {
        this.slxHttp.delete(`${deleteTopicUrl}/${id}`).subscribe(() => this.updateFaqObservable());
    }

    private deleteFaqEntry(id: string) {
        this.slxHttp.delete(`${deleteFaqEntryUrl}/${id}`).subscribe(() => this.updateFaqObservable());
    }

    public save(event: { translateViewValue: string, newValue: string }) {
        this.setTopic(TopicAction.save, this.getViewValue(event.translateViewValue), { newValue: event.newValue });
    }

    public delete(event: { translateViewValue: string }) {
        this.setTopic(TopicAction.delete, this.getViewValue(event.translateViewValue));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
