import { Component, Inject, OnInit, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { AppActions } from '../../appActions';
import { Tip } from '../../models/state/account';
import { toISODateString } from '../../utility/date-format';
import { FieldService } from '../field.service';

@Pipe({ name: 'tdHasPrecedingTip' })
export class TDHasPrecedingTipPipe implements PipeTransform {
    transform(tip): boolean {
        return tip.priority > 1;
    }
}

@Pipe({ name: 'tdHasfollowingTip' })
export class TDHasFollowingTipPipe implements PipeTransform {
    transform(tip, tipLength: number): boolean {
        return tip.priority < tipLength;
    }
}

@Component({
    selector: 'tip-of-the-day',
    templateUrl: './tip-of-the-day.component.html',
    styleUrls: ['./tip-of-the-day.component.scss'],
})
export class TipOfTheDayComponent implements OnInit {
    public form: FormGroup;
    public currentTip: any;
    public tipsForTopic: any;
    private userPrefs: any;
    private tipsUser: any[];

    constructor(public dialogRef: MatDialogRef<TipOfTheDayComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private fieldService: FieldService) {
        this.form = this.formBuilder.group({
            HideTips: [false],
        });
        this.currentTip = this.data.tipToShow;
        this.tipsForTopic = this.data.tipsForTopic;
        this.tipsUser = this.data.tipsUser;
        this.userPrefs = this.data.userPrefs;
    }

    ngOnInit(): void { }

    onSubmit(form) {
        this.dialogRef.close();
        this.userPrefs.HideTipOfTheDay = form.value.HideTips;
        this.fieldService.dispatch({ type: AppActions.save_profile_prefs.name, payload: this.userPrefs });
    }

    openPrecedingTip() {
        const tip = this.tipsForTopic.find(t => t.priority === this.currentTip.priority - 1);
        if (tip) {
            this.currentTip = tip;
            this.addTipToUserTips(tip);
        }
    }

    openFollowingTip(event) {
        const tip = this.tipsForTopic.find(t => t.priority === this.currentTip.priority + 1);
        if (tip) {
            this.currentTip = tip;
            this.addTipToUserTips(tip);
        }
    }

    //TODO @benjamin: function already defined in accountService but not possible to call it here (circular dependency)
    addTipToUserTips(tip: Tip) {
        const tipToAdd = {
            id: tip.id,
            topic: tip.topic,
            viewedDate: toISODateString(new Date()),
        };
        if (tip && tip.topic) {
            if (!this.tipsUser.find(t => t.id === tip.id)) {
                this.tipsUser.push(tipToAdd);
            }
        }
        this.fieldService.dispatch({ type: AppActions.save_user_tips.name, payload: { ListTipsViewed: this.tipsUser } });
    }

}
