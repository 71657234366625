import { enhanceActionsObject } from '../models';
import { CustomerProfileState } from '../models/state/customerProfile';

export const CustomerProfileActions = {
    change_password(state: CustomerProfileState, { payload, result }) {
        return state;
    },

    save_send_email_password_forgot(state: CustomerProfileState, { payload, result }) {
        return state;
    },

    load_user_profile_data(state, { payload, fetch, result }) {
        return result ? {
            ...state,
            userProfileData: payload,
        } : state;
    },

    load_customer_profile(state, { payload, result }) {
        return result ? {
            ...state,
            customerProfileData: payload,
        } : state;
    },

    load_departments(state, { payload, result }) {
        return result ? {
            ...state,
            departmentsData: payload,
        } : state;
    },

    load_add_department(state, { payload, result }) {
        return result ? {
            ...state,
            singleDepartmentData: payload,
        } : state;
    },

    activate_or_deactivate_user(state: CustomerProfileState, { payload, result }) {
        return state;
    },

    send_login_user(state: CustomerProfileState, { payload, result }) {
        return state;
    },

    send_all_login_user(state: CustomerProfileState, { payload, result }) {
        return state;
    },

    activate_or_deactivate_department(state: CustomerProfileState, { payload, result }) {
        return result ? {
            ...state,
            departmentsData: applySingleDepartmentData(state.departmentsData, payload),
        } : state;
    },

    add_user(state: CustomerProfileState, { payload, result }) {
        return state;
    },

    save_add_department(state: CustomerProfileState, { payload, result }) {
        return result ? {
            ...state,
            singleDepartmentData: payload,
            departmentsData: state.departmentsData.concat(payload),
        } : state;
    },

    save_single_department_data(state: CustomerProfileState, { payload, result }) {
        return result ? {
            ...state,
            departmentsData: applySingleDepartmentData(state.departmentsData, payload),
        } : state;
    },

    user_data_change(state, { payload, result }) {
        return {
            ...state,
            usersData: applySingleUserData(state.usersData, payload),
        };
    },

    users_data(state: CustomerProfileState, { payload, result }) {
        return result ? {
            ...state,
            usersData: payload,
        } : state;
    },

    export_user_excel(state: CustomerProfileState, { result }) {
        return state;
    },

    save_user_profile(state, { payload, result }) {
        // return result ? {
        //     ...state,
        //     userProfileData: payload,
        // } : {
        //         ...state,
        //         userProfileData: {
        //             ...state.userProfileData,
        //             ...payload,
        //         },
        //     };

        return result ? {
            ...state,
            userProfileData: {
                ...state.userProfileData,
                ...payload,
            },
        } : {
            ...state,
        };
    },

    save_customer_profile(state, { payload, result }) {
        return result ? {
            ...state,
            customerProfileData: payload,
        } : {
                ...state,
                customerProfileData: {
                    ...state.customerProfileData,
                    ...payload,
                },
            };
    },

    trx_statistics(state: CustomerProfileState, { payload, result }) {
        return state;
    },

    trxs_type(state: CustomerProfileState, { payload, result }) {
        return result ? {
            ...state,
            trxsTypeStatistics: payload,
        } : state;
    },
    trxs_type_for_order(state: CustomerProfileState, { payload, result }) {
        return result ? {
            ...state,
            trxsTypeForOrderStatistics: payload,
        } : state;
    },
};
enhanceActionsObject(CustomerProfileActions);


function applySingleDepartmentData(departments, singleDepartment) {
    return departments.map(dep => {
        if (dep.id === singleDepartment.id) {
            return {
                ...dep,
                ...singleDepartment,
            };
        }
        return dep;
    });
}

function applySingleUserData(users, singleUser) {
    return users ? users.map(usr => {
        if (usr.id === singleUser.id) {
            return {
                ...usr,
                ...singleUser,
            };
        }
        return usr;
    }) : users;
}
