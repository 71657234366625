import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { TooltipData } from '../models';

@Pipe({ name: 'functionAsPipe' })
export class FunctionAsPipe implements PipeTransform {
    transform(args: any, bodyCreator: (any) => string ) : string {
        const body = bodyCreator(args);
        return body;
    }
}


@Pipe({ name: 'tooltipDataCreatorPipe' })
export class TooltipDataCreatorPipe implements PipeTransform {
    transform(args: any, dataCreator: (any) => Observable<TooltipData> ) : Observable<TooltipData> {
        const observable = dataCreator(args);
        return observable;
    }
}
