import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginState } from '@slx/authentification';
import { Observable, Subscription } from 'rxjs';

import { LoginService } from '../../access';
import { AccountService } from '../../account';
import { AppActions } from '../../appActions';
import { AssetService } from '../../asset';
import * as config from '../../config';
import { requiredValidator } from '../../field';
import { getLanguageIndependantUrl } from '../../models';
import { HomeActions } from '../homeActions';

@Component({
    selector: 'slx-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

    @Input() public showPrimary = true;

    public contextMenuShown = false;
    public smallLoginShown = false;
    public releaseNumber = config.releaseNumber;
    public loggingIn: boolean;
    actions = HomeActions;

    public loginForm: FormGroup;

    private subscription = new Subscription();
    public isUniUser = false;

    public mainTab: Observable<string>;
    public loginState: LoginState;
    public routeNeedsLogin = false;

    public LoginState = LoginState;

    constructor(public router: Router, public loginService: LoginService, public account: AccountService, public translate: TranslateService, public assetService: AssetService, private formBuilder: FormBuilder) {
        this.listener = this.listener.bind(this);
        this.mainTab = this.loginService.store.select(state => state.home.mainTab);
    }

    ngOnInit() {
        const stayLoginDefault = localStorage.getItem('stayLoggedIn') === 'true';
        this.loginForm = this.formBuilder.group({
            username: ['', requiredValidator()],
            password: ['', requiredValidator()],
            stayLoggedIn: [stayLoginDefault],
        });
        this.loggingIn = false;


        this.subscription.add(this.loginService.routeNeedsLogin.subscribe(needsLogin => {
            this.routeNeedsLogin = needsLogin;
            this.updateSmallLogin();
        })).add(this.loginService.loginResult.subscribe(loginResult => {
            this.loggingIn = false;
            this.loginState = loginResult.state;
            this.updateSmallLogin();
            this.loginForm.reset();
            this.isUniUser = loginResult.user ? loginResult.user.isUniUser : false;
        }))
        // .add(this.account.store.select(state => state.access).subscribe(data => {
        //     this.isUniUser = JSON.parse(data.isUniUser);
        // }))
        ;
    }

    updateSmallLogin() {
        if (this.routeNeedsLogin && this.loginState === LoginState.LoggedOut) {
            this.showSmallLogin();
        }
        else {
            this.closeSmallLogin();
        }
    }


    toggleContextMenu(event) {
        event.stopPropagation();

        const listener = () => {
            this.contextMenuShown = false;
            document.removeEventListener('click', listener);
        };

        if (!this.contextMenuShown) {
            document.addEventListener('click', listener);
            this.contextMenuShown = true;
        }
        else {
            document.removeEventListener('click', listener);
            this.contextMenuShown = false;
        }
    }

    private showSmallLogin() {
        this.smallLoginShown = true;
        this.loggingIn = false;

        const inputUserName: HTMLElement = document.querySelector('#username-login > input');
        if (inputUserName) { setTimeout(() => inputUserName.focus()); }

        document.addEventListener('click', this.listener);
    }


    private closeSmallLogin() {
        this.smallLoginShown = false;
        document.removeEventListener('click', this.listener);
    }

    private listener(event) {
        const loginContainer = document.querySelector('.submenu-login');
        if (loginContainer && loginContainer.contains(event.target)) return;

        this.closeSmallLogin();
    }

    toggleSmallLogin(event) {
        event.stopPropagation();
        this.smallLoginShown ? this.closeSmallLogin() : this.showSmallLogin();
    }

    onSubmit(loginForm) {
        if (!loginForm.valid) {
            Object.keys(loginForm.controls).forEach(field => {
                const control = loginForm.get(field);
                control.markAsTouched({ onlySelf: true });
            });
            return;
        }

        this.loggingIn = true;
        const { username, password, stayLoggedIn } = loginForm.value;
        this.loginService.login(username, password, stayLoggedIn);
    }

    logout() {
        this.loginService.logout(false);
    }

    changeLanguage(language: string) {
        this.account.use(language);
        const { relativeUrlParts, queryStringObject, fragmentString } = getLanguageIndependantUrl(this.router.url);
        this.router.navigate(relativeUrlParts, { replaceUrl: true, queryParams: queryStringObject, fragment: fragmentString });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    changeMaintab(tab: string, event: MouseEvent): void {
        event.stopPropagation();
        if (!event || !event.ctrlKey) {
            this.loginService.store.dispatch({ type: AppActions.set_main_tab.name, payload: tab });
        }
    }
}
