import { Component, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

import { Subscription } from '../../../../node_modules/rxjs';
import { first } from '../../../../node_modules/rxjs/operators';
import { AppActions } from '../../appActions';
import { CitationDisplayInfo, PlaceHolderEnum, UserCiting } from '../../models';
import { AlertType } from '../../models/state/commonApp';
import { AssetService, selectionMenuListener } from '../asset.service';

@Component({
    selector: 'slx-text-selection',
    templateUrl: './text-selection.component.html',
    styleUrls: ['./text-selection.component.scss'],
})

export class TextSelectionComponent implements OnDestroy {

    private subscription = new Subscription();
    private citationInformationSelector: Observable<Array<CitationDisplayInfo>>;
    private currentDocview;
    private replaceMapping = new Map<PlaceHolderEnum, string>();

    public citing: UserCiting;
    public showMenu = false;

    constructor(private assetService: AssetService) {
        this.selectionMenuScrollListener = this.selectionMenuScrollListener.bind(this);

        this.subscription.add(
            assetService.textSelection.subscribe(((citing: UserCiting) => {
                if (citing) {
                    this.showMenu = true;
                    this.citing = citing;
                    this.replaceMapping.set(PlaceHolderEnum.PageBreak, this.citing.citingInformation.pageNumber);
                    this.replaceMapping.set(PlaceHolderEnum.MarginNumber, this.citing.citingInformation.marginNumber);
                    this.replaceMapping.set(PlaceHolderEnum.ConsNumber, this.citing.citingInformation.consNumber);

                    this.citationInformationSelector = this.assetService.getCitationInformationSelector(citing.assetID);
                    setTimeout(() => document.addEventListener('click', selectionMenuListener));

                    this.currentDocview = document.getElementById(citing.assetID).parentElement;
                    this.currentDocview.addEventListener('scroll', this.selectionMenuScrollListener);
                }
                else {
                    this.showMenu = false;
                }
            })));
    }

    private determineSuffixBasedOnPlaceHolder(placeHolder: PlaceHolderEnum): string {
        return this.citing.citingInformation.suffixForMultielments.has(placeHolder) ?
            `${this.citing.citingInformation.suffixForMultielments.get(placeHolder)}` : '';
    }

    public copySelctionToClipboard(withCiting: boolean): void {
        const area = document.createElement('textarea');
        if (withCiting) {
            this.citationInformationSelector.pipe(first(val => val !== null)).subscribe(citeAttachment => {
                const citeArray = citeAttachment.map(citeInfo => {
                    if (citeInfo.text) {
                        return citeInfo.text;
                    }
                    const foundValue = this.replaceMapping.get(citeInfo.placeHolder as PlaceHolderEnum);
                    const suffix = this.determineSuffixBasedOnPlaceHolder(citeInfo.placeHolder as PlaceHolderEnum);
                    return foundValue ? `${citeInfo.prefix}${foundValue}${suffix}` : '';
                });
                const finalCite = citeArray.join('');
                area.value = `${this.citing.citingInformation.textSelection}\n\n[${finalCite}]`;
            }, error => {
                this.assetService.dispatch({ type: AppActions.alert.name, payload: { type: AlertType.Error, key: 'rech-asset-copy-no-cites', duration: 10 } });
                AssetService.hideTextSelectionMenu();
                return;
            });
        } else {
            area.value = this.citing.citingInformation.textSelection;
        }

        document.body.appendChild(area);
        area.focus();
        area.select();

        const result = document.execCommand('copy');

        if (result) {
            this.assetService.dispatch({ type: AppActions.alert.name, payload: { type: AlertType.Success, key: 'rech-asset-cite-copy-sucess', duration: 5 } });
        } else {
            this.assetService.dispatch({ type: AppActions.alert.name, payload: { type: AlertType.Error, key: 'rech-asset-copy-error', duration: 10 } });
        }

        document.body.removeChild(area);
        AssetService.hideTextSelectionMenu();
    }

    private selectionMenuScrollListener(): void {
        AssetService.hideTextSelectionMenu();
        this.currentDocview.removeEventListener('scroll', this.selectionMenuScrollListener);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
