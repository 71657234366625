import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AgRendererComponent } from 'ag-grid-angular/dist/interfaces';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

// const debug = require('debug')('field-switch');

@Component({
    selector: 'slx-grid-switch', //TODO rename to field-switch
    templateUrl: './gridSwitch.component.html',
    styleUrls: ['./gridSwitch.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GridSwitchComponent),
            multi: true,
        },
    ],
})
export class GridSwitchComponent implements AgRendererComponent , OnInit, ControlValueAccessor {
    refresh(params: any): boolean {
        throw new Error('Method not implemented.');
    }
    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
        throw new Error('Method not implemented.');
    }
    private params: any;
    agInit(params:any):void {
        this.params = params;
        this.type = 'onoff';
        this.color = 'primary';
        this.checked = this.params.value;
    }

    @Input() type: string;
    @Input() name: string;
    @Input() class: string;
    @Input() icon: string;
    @Input('label') _label: string;
    @Input() color: string;
    @Input() checked: boolean;
    @Input() disabled: boolean;

    @Output() change = new EventEmitter();

    effectiveClass: object;

    private propagateChange = (_: any) => { };

    get label() {
        return this._label ? this.translate.instant(this._label) : null;
    }

    constructor(private translate: TranslateService) { }

    ngOnInit() {
        // Make the corresponding class available based on the input types, such as
        // input="onff" -> the class then will be switch__onoff or a combination of one or more classes.
        this.effectiveClass = [
            'switch__' + this.type,
            this.icon ? 'switch__with-icon' : '',
            this.label ? 'switch__with-label' : '',
            this.class  || '',
        ];
    }

    public onChange(event) {
        this.checked = event.checked;
        this.propagateChange(this.checked);
    }

    // Sets initial value, if there is one, otherwise empty string
    public writeValue(checked: boolean) {
        this.checked = checked;
    }

    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    // not used yet, touch support
    public registerOnTouched() {
    }

}
