import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
LicenseManager.setLicenseKey('Swisslex_AG_MultiApp_1Devs31_October_2019__MTU3MjQ4MDAwMDAwMA==ef8d75cb3b0d2dc3ef9df472c2ca4171');

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
