import { BehaviorSubject } from 'rxjs';

import { AssetContent } from './asset';

export class TocContent extends AssetContent {
    title: string;
    idToOpen: string;
    referringAssetID: string;
    logoInfo: TocLogoInfo;
    bookCrossSellingInfo: TocBookCrossSellingInfo;
    tocItems: TocItem[];
    additionalInfo: TocAdditionalInfo;
    newerEditions: NewerEditions[];
    showMarginNumbers: boolean;
}

export class NewerEditions {
    publicationID: string;
    displayString: string;
}

export class TocItem {
    targetID: string;
    title: string;
    titleKey: string;
    subTitle: string;
    firstLineAbstract: string;
    startPage: string;
    marginNumber: string;
    childrenTocItems: TocItem[];
    hasChildren: boolean;
    getChildrenTocItemsUrl: boolean;
    targetAnchor: string;
    temporaryItemID: string;
    childrenTocItemSubject: BehaviorSubject<TocItem[]>;
    loadingChildren = false;
    isFirstTitleOnNewAsset: boolean;
    tooltipInformation: TocTooltipInformation;
}

export class TocTooltipInformation {
    showTooltip: boolean;
    title: string;
    subTitle: string;
    summary: string[];
}

export class TocLogoInfo {
    externalLink: string;
    logoUrl: string;
    additionalInfo: any;
}

export class TocBookCrossSellingInfo {
    bookShopLink: string;
    coverSrc: string;
    availability: BookAvailability;
    langParameterPrefix: string;
}

export class TocAdditionalInfo {
    type: string;
    forcedShow: boolean;
    htmlContent: string;
    position: string;
}

export enum BookAvailability {
    Available,
    NotYetReleased,
    NotAnyMoreDeliverable,
    Unknown,
}
