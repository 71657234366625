import { PracticeArea } from '../state/practiceArea';
export * from './biblioContent';



export interface BiblioState {
    isCaselawPublished: boolean;
    euTocModel: any;
    loadingInProgress: boolean;
    error: any;
}

export const defaultBiblioState = {
    isCaselawPublished: true,
    euTocModel: {},
    loadingInProgress: false,
};
