import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SlxHttp } from './access';
import { AppActions } from './appActions';
import { AppState, makeAction, SlxAction, StoreBackupType } from './models';
import { AlertType } from './models/state/commonApp';

@Injectable()
export class AppService implements OnDestroy {
    private hasTransientAlerts = false;
    private subscription = new Subscription();

    public alertType = AlertType;
    public browserWindowSize: Observable<number> = this.store.select(state => state.app.browserWindowSize);

    constructor(private store: Store<AppState>, private http: SlxHttp) {
        this.subscription
            .add(this.store.select(state => state.app.alerts.filter(alert => !alert.sticky && alert.type !== AlertType.Error)).subscribe(transients => {
                this.hasTransientAlerts = (transients.length > 0);
            }))
            .add(this.store.select(state => state.app.alerts).subscribe(alerts => {
                const alert = alerts[alerts.length - 1];

                if (alert && alert.dismissAt) {
                    const delay = alert.dismissAt - Date.now() + 10;
                    if (delay >= 0) {
                        setTimeout(() => {
                            this.store.dispatch({ type: AppActions.prune_alerts.name });
                        }, delay);
                    }
                }
            }))
            .add(this.store.select(state => state.app.storeRestoreKey).pipe(filter(key => !!key)).subscribe(key => {
                this.dispatch({ type: AppActions.backup_store.name, payload: { type: StoreBackupType.ExceptionBackup, key } });
            }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    dispatch(action) {
        return this.store.dispatch(makeAction(action as SlxAction));
    }

    dismissTransientAlerts() {
        if (this.hasTransientAlerts) {
            this.store.dispatch({ type: AppActions.dismiss_transient_alerts.name });
        }
    }
}
