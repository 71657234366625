import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { AutocompleteService } from '../../../asset';
import { requiredValidator } from '../../../field';
import { AutocompleteParamter, AutocompleteType, SearchType } from '../../../models';
import { SearchService } from '../../search.service';
import { SearchFormBase } from '../search-form-base';

@Component({
    selector: 'slx-case-law',
    template: `<slx-search-form *ngIf="formDescription && searchForm" [formDescription]="formDescription" [searchForm]="searchForm" [subTabs]="subTabs" (searchSubmitted)="onSubmit()" (searchReset)="onReset()"></slx-search-form>`,
}) export class PupCaseCollectionComponent extends SearchFormBase {

    private abbrIsFromSelection = false;

    subTabs = [
        { translate: 'rech-published', key: 'casepub' },
        { translate: 'rech-unpublished', key: 'casenonpub' },
    ];

    constructor(protected searchService: SearchService, protected autocompleteService: AutocompleteService, protected formBuilder: FormBuilder, protected changeDetectorRef: ChangeDetectorRef, protected translate: TranslateService) {
        super(searchService, SearchType.CaseCollection, changeDetectorRef, translate);

        this.getPubAbbreviationAutocomplete = this.getPubAbbreviationAutocomplete.bind(this);
        this.getPubYearAutocomplete = this.getPubYearAutocomplete.bind(this);
        this.getPubPageAutocomplete = this.getPubPageAutocomplete.bind(this);
        this.handleIsSelectionFromDropdown = this.handleIsSelectionFromDropdown.bind(this);

        this.searchForm = this.formBuilder.group({
            abbreviation: [''],
            yearOrVolume: [''],
            pagesOrSequenceNumber: [''],
            abbrIsFromSelection: this.abbrIsFromSelection,
        });

        this.controlDependencies = ['abbreviation','yearOrVolume','pagesOrSequenceNumber'];

        this.formDescription = {
            formGroups: [
                {
                    class: 'slx-form-row no-wrap',
                    controls: [
                        {
                            control: 'slx-text', isSelectionFromDropdown: this.handleIsSelectionFromDropdown, name: 'Abbreviation', type: 'text', placeholder: 'rech-case-abbreviation', formControlName: 'abbreviation', class: 'slx-field-xlarge',
                            makeAutocomplete: this.getPubAbbreviationAutocomplete, autocompleteMinQueryLength: 0, textChanged: this.resetDependencies('abbreviation'),
                        },
                        { control: 'slx-info-button', filename: 'reference-help-casecollection', titleTransString: 'home-info-reference-help', class: 'in-form'  },
                    ],
                },
                {
                    controls: [
                        {
                            control: 'slx-text', name: 'YearOrVolume', type: 'text', placeholder: 'rech-case-year', formControlName: 'yearOrVolume', textChanged: this.resetDependencies('yearOrVolume'),
                            makeAutocomplete: this.getPubYearAutocomplete, autocompleteMinQueryLength: 0,
                        },
                        {
                            control: 'slx-text', name: 'PagesOrSequenceNumber', type: 'text', placeholder: 'rech-case-page', formControlName: 'pagesOrSequenceNumber',
                            makeAutocomplete: this.getPubPageAutocomplete, autocompleteMinQueryLength: 0,
                        },
                    ],
                },
            ],
            controls: {
                resetControl: true,
            },
            searchButtonText: 'rech-display',
        };

        this.validationKeys = [
            'abbreviation',
        ];
    }

    onSearchFormChanges(state: any): void {
        this.abbrIsFromSelection = !!state.abbrIsFromSelection;
    }
    handleIsSelectionFromDropdown(selection) {
        this.abbrIsFromSelection = selection;
        this.searchForm.patchValue({ abbrIsFromSelection: selection });
    }

    getPubAbbreviationAutocomplete() {
        return (): Observable<any> => {
            const paramters = [
                new AutocompleteParamter('pubCaseCollectionAbbr', this.searchForm.value.abbreviation),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.CaseCollectionPubAbbreviation, true, paramters);
        };
    }

    getPubYearAutocomplete() {
        return (): Observable<any> => {
            const paramters = [
                new AutocompleteParamter('pubCaseCollectionAbbr', this.searchForm.value.abbreviation),
                new AutocompleteParamter('valueFromSelection', this.abbrIsFromSelection.toString()),
                new AutocompleteParamter('yearOrVolume', this.searchForm.value.yearOrVolume),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.CaseCollectionPubYear, true, paramters);
        };
    }

    getPubPageAutocomplete() {
        return (): Observable<any> => {
            const paramters = [
                new AutocompleteParamter('pubCaseCollectionAbbr', this.searchForm.value.abbreviation),
                new AutocompleteParamter('valueFromSelection', this.abbrIsFromSelection.toString()),
                new AutocompleteParamter('yearOrVolume', this.searchForm.value.yearOrVolume),
                new AutocompleteParamter('page', this.searchForm.value.pagesOrSequenceNumber),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.CaseCollectionPubPage, false, paramters);
        };
    }

    onSubmit() {
        this.searchForm.value.abbrIsFromSelection = this.abbrIsFromSelection;
        super.onSubmit();
    }
}
