import { Component, ElementRef, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { AccountService } from '../../account';
import { AssetActions, AssetService } from '../../asset';
import { ResultsViewState } from '../../models';
import { SearchService } from '../../recherche/search.service';
import { isResponsive } from '../../utility/utilityFunctions';
import { SearchActions } from '../searchActions';

import { SidebarSize } from './../../models/research';

const debug = require('debug')('search-results');

@Component({
    selector: 'slx-search-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnDestroy {

    private subscription: Subscription;
    private mouseDownListener: Function;
    private touchStartListener: Function;

    @ViewChild('primaryAssetTab', { read: ElementRef })
    private primaryAssetTab: ElementRef;
    private get primaryTab(): HTMLElement {
        return this.primaryAssetTab.nativeElement;
    }

    @ViewChild('secondaryAssetTab', { read: ElementRef })
    private secondaryAssetTab: ElementRef;
    private get secondaryTab(): HTMLElement {
        return this.secondaryAssetTab.nativeElement;
    }

    private facettePWidth: number;
    private hitlistPWidth: number;

    public sidebarWidth: number;
    public resultsViewState: ResultsViewState;
    public previewInformation: any;
    public isResponsive = isResponsive;

    constructor(public searchService: SearchService, public assetService: AssetService, private containerRef: ElementRef, public accountService: AccountService, private renderer: Renderer2) {

        this.listener = this.listener.bind(this);
        this.startSidebarResize = this.startSidebarResize.bind(this);
        this.stopSidebarResize = this.stopSidebarResize.bind(this);
        this.startDocumentResize = this.startDocumentResize.bind(this);
        this.stopDocumentResize = this.stopDocumentResize.bind(this);
        this.startFacetteResize = this.startFacetteResize.bind(this);
        this.stopFacetteResize = this.stopFacetteResize.bind(this);

        this.subscription = this.searchService.resultsViewState
            .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
            .subscribe(state => {
                this.resultsViewState = state;
                setTimeout(() => {
                    const splitPercentage = state.split * 100;
                    if (state.both && `${splitPercentage}%` !== this.primaryTab.style.width) {
                        this.setAssetSize(splitPercentage);
                    }
                });
            });

        this.mouseDownListener = this.renderer.listen(this.containerRef.nativeElement, 'mousedown', this.listener);
        this.touchStartListener = this.renderer.listen(this.containerRef.nativeElement, 'touchstart', this.listener);
    }

    private listener(event) {
        if (event.target.closest('.sidebar-resizer')) {
            this.initResize(event, this.startSidebarResize, this.stopSidebarResize);
            document.querySelector('.sidebar-resizer').classList.add('line-active');
            return;
        }

        if (event.target.closest('.document-resizer')) {
            this.initResize(event, this.startDocumentResize, this.stopDocumentResize);
            document.querySelector('.document-resizer').classList.add('line-active');
            return;
        }

        if (event.target.closest('.facette-resizer')) {
            this.initResize(event, this.startFacetteResize, this.stopFacetteResize);
            document.querySelector('.facette-resizer').classList.add('line-active');
        }
    }

    private initResize(event, startFunction, stopFunction) {
        this.toggleUserSelect('none');

        document.addEventListener(event.type === 'touchstart' ? 'touchmove' : 'mousemove', startFunction);
        document.addEventListener(event.type === 'touchstart' ? 'touchend' : 'mouseup', stopFunction);

        this.containerRef.nativeElement.classList.add('resizing');
    }

    private finishResize(startFunction, stopFunction) {
        this.toggleUserSelect('auto');

        document.querySelector('.line-active').classList.remove('line-active');
        this.containerRef.nativeElement.classList.remove('resizing');

        document.removeEventListener('mousemove', startFunction);
        document.removeEventListener('mouseup', stopFunction);
        document.removeEventListener('touchmove', startFunction);
        document.removeEventListener('touchend', stopFunction);
    }

    private startFacetteResize(event) {
        const hitlistBody: HTMLElement = document.querySelector('.hitlist_body');
        const hitList: HTMLElement = document.querySelector('slx-hitlist');
        const filters: HTMLElement = document.querySelector('.filters');


        const baseWidth = hitList.offsetWidth;
        const xPosition = event.type === 'touchmove' ? event.targetTouches[0].clientX : event.clientX;

        const actualWidth = xPosition - filters.offsetLeft - 54;
        const percentageWidth = Math.round((actualWidth / baseWidth) * 100);

        // 250 = cssMinsize
        if (actualWidth <= 250 || percentageWidth >= 75) {
            return;
        }

        this.facettePWidth = percentageWidth;
        this.hitlistPWidth = 100 - percentageWidth;
        filters.style.width = percentageWidth + '%';
        hitlistBody.style.width = 100 - percentageWidth + '%';
    }

    private stopFacetteResize() {
        this.finishResize(this.startFacetteResize, this.stopFacetteResize);
        this.searchService.dispatch({ type: SearchActions.resize_sidebar.name, payload: <SidebarSize>{ hitlistPercentage: this.hitlistPWidth, facettePercentage: this.facettePWidth } });
    }

    private startSidebarResize(event) {
        const sidebar: HTMLElement = document.querySelector('slx-sidebar');
        const xPosition = event.type === 'touchmove' ? event.targetTouches[0].clientX : event.clientX;
        const width = xPosition - sidebar.offsetLeft - 17;

        if (width <= 290 || width >= 1440) {
            return;
        }

        this.sidebarWidth = width;
        sidebar.style.width = width + 'px';
    }

    private stopSidebarResize() {
        this.searchService.dispatch({ type: SearchActions.resize_sidebar.name, payload: { sidebarWidth: this.sidebarWidth } });
        this.finishResize(this.startSidebarResize, this.stopSidebarResize);
    }

    private startDocumentResize(event) {
        const { actualWidth, relativeWidth } = this.getAssetSplit(event);
        const percentWidth = relativeWidth * 100;

        if (actualWidth < 400 || percentWidth > 100) {
            return;
        }

        this.setAssetSize(percentWidth);
    }

    private setAssetSize(percentWidth: number) {
        this.primaryTab.style.width = percentWidth.toPrecision(2) + '%';
        this.secondaryTab.style.width = (100 - percentWidth).toPrecision(2) + '%';
    }

    private stopDocumentResize(event) {
        const { actualWidth, relativeWidth } = this.getAssetSplit(event);

        this.finishResize(this.startDocumentResize, this.stopDocumentResize);
        this.searchService.dispatch({ type: AssetActions.set_asset_split.name, payload: relativeWidth.toPrecision(2) });
    }

    private getAssetSplit(event): { actualWidth: number, relativeWidth: number } {
        const splitView: HTMLElement = document.querySelector('.split-view');
        const width: ClientRect = this.primaryTab.getBoundingClientRect();
        const xPosition = event.type === 'touchend' || event.type === 'touchmove' ? event.targetTouches[0].clientX : event.clientX;
        const actualWidth = xPosition - width.left;
        return { actualWidth, relativeWidth: actualWidth / splitView.offsetWidth };
    }

    private toggleUserSelect(userSelect: string) {
        const searchResult: HTMLElement = document.querySelector('slx-search-results');
        searchResult.style.userSelect = userSelect;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();

        // returns removeEventListener
        this.mouseDownListener();
        this.touchStartListener();
    }
}
