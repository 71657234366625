import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ActionState } from '../../models/state/commonApp';
import { FieldService } from '../field.service';

@Component({
    selector: 'slx-submit-button',
    templateUrl: './submitButton.component.html',
    styleUrls: ['./submitButton.component.scss'],
})
export class SubmitButtonComponent implements OnInit, OnDestroy {
    @Input() action: string;
    @Input() icon: string;
    @Input('placeholder') _placeholder: string;
    @Input('successPlaceholder') _successPlaceholder: string;
    @Input('errorPlaceholder') _errorPlaceholder: string;
    @Input() successDuration = 10;
    @Input() errorDuration = 8;
    @Input() locked = false;

    public stateOfAction;
    public submitted = false;
    public actionState = ActionState; //to access enum in html

    private sub;

    private timeoutID;

    get placeholder() {
        return this._placeholder ? this.translate.instant(this._placeholder) : null;
    }

    get successPlaceholder() {
        return this._successPlaceholder ? this.translate.instant(this._successPlaceholder) : this.placeholder;
    }

    get errorPlaceholder() {
        return this._errorPlaceholder ? this.translate.instant(this._errorPlaceholder) : this.placeholder;
    }

    constructor(private fieldService: FieldService, private translate: TranslateService) {}

    ngOnInit() {
        this.sub = this.fieldService.stateOfAction(this.action).subscribe(
            res => {
                if(res) {
                    this.stateOfAction = res;

                    if (this.stateOfAction === ActionState.InProgress) {
                        clearTimeout(this.timeoutID);
                    } else if (this.stateOfAction === ActionState.Success) {
                        this.clearButton(this.successDuration * 1000);
                    } else if (this.stateOfAction === ActionState.Error) {
                        this.clearButton(this.errorDuration * 1000);
                    } else if (this.stateOfAction === ActionState.Cleared) {
                        clearTimeout(this.timeoutID);
                        this.submitted = false;
                    }
                }
            }
        );
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
        clearTimeout(this.timeoutID);
    }

    onClick(event) {
        this.submitted = true;
    }

    private clearButton(timer) {
        this.timeoutID = setTimeout(() => {
            this.submitted = false;
            this.stateOfAction = ActionState.Cleared;
        }, timer);
    }
}
