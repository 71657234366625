
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as marked from 'marked';
import {Observable,  Subject ,  throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getStaticContentMarkdownUrl, SlxHttp } from '../access';
import * as config from '../config';
import { AppState, defaultPublicationFilterContent, isActionInProgress, stateOfAction, ThesaurusInformation } from '../models';
import { ActionState } from '../models/state/commonApp';


@Injectable()
export class FieldService {

    public thesaurusInformation: Observable<ThesaurusInformation>;
    public tooltipInformation: Observable<any>;

    public publicationFilterOptions = this.store.select((state => state.recherche.publicationFilterContent || defaultPublicationFilterContent));


    private tooltipInformationSubject = new Subject<any>();

    constructor(public store: Store<AppState>, private http: SlxHttp) {
        this.thesaurusInformation = this.store.select(state => state.field.thesaurusInformation);
        this.tooltipInformation = this.tooltipInformationSubject.asObservable();
    }

    // Publicationfilter Options
    public selectedPublications = this.store.select(state => state.recherche.selectedPublications);

    public showTooltip(tooltipInformation) {
        this.tooltipInformationSubject.next(tooltipInformation);
    }

    public hideTooltip() {
        this.tooltipInformationSubject.next(null);
    }

    public dispatch(action) {
        this.store.dispatch(action);
    }

    public isActionInProgress(...types): Observable<boolean> {
        return this.store.select(state => isActionInProgress(state, ...types));
    }

    public stateOfAction(type): Observable<ActionState> {
        return this.store.select(state => stateOfAction(state, type));
    }

    public getMarkdown(mdfilename: string, currentLanguage: string, foldername?: string) {
        const requestUrl = foldername
            ? `${getStaticContentMarkdownUrl}${mdfilename}-${currentLanguage}&folderName=${foldername}`
            : `${getStaticContentMarkdownUrl}${mdfilename}-${currentLanguage}`;

        return this.http.getMarkdown(requestUrl,false).pipe(
            map((res) => marked(res)),
            catchError(error => observableThrowError(error))
        );
    }
}

export function unCryptMailto(s) {
    let n = 0;
    let r = '';
    for (let i = 0; i < s.length; i++) {
        n = s.charCodeAt(i);
        if (n >= 8364) {
            n = 128;
        }
        r += String.fromCharCode(n - 1);
    }
    return r;
}
