export class Job {
    public title: string;
    public workplace: string;
    public id: string;
    public employerName: string;
    public description: string;
    public publishDate: string;
    public iFrameUrl: string;
    public pdfImageUrl: string;
    public pdfUrl: string;
    public companyLogoUrl: string;
    public isTopJob: string;
    public jobDescriptionType: string;
}
