import { Injectable } from '@angular/core';
import { MatDateFormats, NativeDateAdapter } from '@angular/material';
import { format, isSameDay, isSameMonth, isSameYear, setHours } from 'date-fns';
const debug = require('debug')('slx-date');


const isoRegex = /(\d{4})-(\d{2})-(\d{2})T00:00:00/;
const dateTimeRegex = /(\d{1,2})\.(\d{1,2})\.(\d{4}) (\d{1,2}):(\d{1,2}):(\d{1,2})/;
const justDateRegex = /^(\d{1,2})\.(\d{1,2})$/; // 01.02 -> 01.02.CurrentYear
const dateRegex = /(\d{1,2})\.(\d{1,2})\.(\d{4})/;
const dateDashRegex = /(\d{1,2})-(\d{1,2})-(\d{4})/;
const dateTimeStringifiedRegex = /(\d{4})-(\d{2})-(\d{2})T(\d{1,2}):(\d{1,2}):(\d{1,2})/;

const THIS_YEAR = (new Date()).getFullYear();

export function toDate(ds: string, options: any = { strict: false }): Date {
    if (<any>ds instanceof Date) return <Date><any>ds;
    if (ds.length > 10) {
        const dtMatch = ds.match(dateTimeRegex);
        if (dtMatch) { return new Date(Number(dtMatch[3]), Number(dtMatch[2]) - 1, Number(dtMatch[1]), Number(dtMatch[4]), Number(dtMatch[5]), Number(dtMatch[6])); }
    }

    const match = ds.match(dateDashRegex);
    if (match) { return new Date(Number(match[3]), Number(match[2]) - 1, Number(match[1])); }
    const dMatch = ds.match(dateRegex);
    if (dMatch) { return new Date(Number(dMatch[3]), Number(dMatch[2]) - 1, Number(dMatch[1])); }
    const dmMatch = ds.match(justDateRegex);
    if (dmMatch) { return new Date(THIS_YEAR, Number(dmMatch[2]) - 1, Number(dmMatch[1])); }
    const isoMatch = ds.match(isoRegex);
    if (isoMatch) { return new Date(Number(isoMatch[1]), Number(isoMatch[2]) - 1, Number(isoMatch[3])); }
    const dtsMatch = ds.match(dateTimeStringifiedRegex);
    if(dtsMatch) { return new Date(Number(dtsMatch[1]),Number(dtsMatch[2]) -1 , Number(dtsMatch[3]),
        Number(dtsMatch[4]), Number(dtsMatch[5]), Number(dtsMatch[6])); }
    if (options.strict) {
        return null;
    }

    return new Date(ds);
}

export function isSameDate(d1: Date, d2: Date): boolean {
    if (isSameDay(d1, d2) && isSameMonth(d1, d2) && isSameYear(d1, d2)) {
        return true;
    }

    return false;
}

export const SLX_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'DD.MM.YYYY',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MM.YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'LL',
    },
};

export function toISODateString(date: Date): string {
    return setHours(date, date.getTimezoneOffset() / -60).toISOString().substring(0,19);
}

@Injectable()
export class SlxDateAdapter extends NativeDateAdapter {
    getFirstDayOfWeek(): number {
        return 1;
    }

    parse(value: any): Date | null {
        if (typeof value === 'string') {
            return toDate(value);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'input') {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${to2digit(day)}.${to2digit(month)}.${year}`;
        } else {
            return format(date, 'DD.MM.YYYY');
        }
    }

}

function to2digit(n: number) {
    return ('00' + n).slice(-2);
}
