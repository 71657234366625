import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AccountService } from '../../../account';
import { emailValidator, requiredValidator, unCryptMailto } from '../../../field';
import { LanguageEnum } from '../../../models';
import { markFormAsTouched } from '../../../utility/utilityFunctions';
import { HomeService } from '../../home.service';
import { HomeActions } from '../../homeActions';

@Component({
    selector: 'slx-about-contact',
    templateUrl: './about-contact.component.html',
    styleUrls: ['./about-contact.component.scss'],
})
export class AboutContactComponent implements OnDestroy {
    private language;
    private languageEnum = LanguageEnum;
    public contactForm: FormGroup;

    public onLangChangeSubscription: Subscription;

    constructor(private accountService: AccountService, private formBuilder: FormBuilder, private homeService: HomeService) {
        this.language = this.accountService.langEnum;

        this.onLangChangeSubscription = this.accountService.onLangChange.subscribe(res => {
            this.language = this.accountService.langEnum;
        });

        this.contactForm = this.formBuilder.group({
            Message: ['', requiredValidator()],
            Email: ['', [requiredValidator(), emailValidator('about-contact-validation-email-invalid')]],
            Number: [''],
            Language: [this.accountService.lang],
            RecaptchaReactive: [null, requiredValidator()],
        });
    }

    public linkToUnCryptMailto(s) {
        location.href = unCryptMailto(s);
    }

    public resetForm() {
        this.contactForm.reset();
    }

    public onSubmit(form) {
        if (!form.valid) {
            markFormAsTouched(form);
            return;
        }

        this.homeService.dispatch({ save: HomeActions.send_contact_form.name, payload: form.value });
    }

    ngOnDestroy(): void {
        this.onLangChangeSubscription.unsubscribe();
    }
}

