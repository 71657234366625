import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AppActions } from '../../../appActions';
import { AssetActions, AssetService } from '../../../asset';
import { getAssetURL } from '../../../config';
import { Asset, AssetDisplayType, AssetRef, DocumentContent, makeAction, SourceDetail, TocContent } from '../../../models';
import { UserFavorite } from '../../../models/research';
import { SearchService } from '../../search.service';
import { SearchActions } from '../../searchActions';

@Component({
    selector: 'slx-toc-in-sidebar',
    templateUrl: './toc-in-sidebar.component.html',
    styleUrls: ['./toc-in-sidebar.component.scss'],
})
export class TocInSidebarComponent implements OnInit, OnDestroy {

    private subscription: Subscription;
    private tocAssetSubscription: Subscription;
    public tocRef: AssetRef;
    public tocTitle: string;
    private assetUrl: string;
    private tocAsset: Asset;
    public isFavorite: boolean;
    public canShowToc: boolean;

    constructor(public assetService: AssetService, public searchService: SearchService, public translate: TranslateService, private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {

        this.subscription = this.assetService.activeAssetTabForToc.subscribe(tab => {
            const asset = tab ? this.assetService.getCachedAsset(tab.assetRef) : null;
            this.canShowToc = true;
            if (!asset) {
                if (tab) {
                    const pupIndex = tab.assetRef.findIndex(type => type === AssetDisplayType.Book || type === AssetDisplayType.PeriodicalPublication);
                    if (pupIndex > -1) {
                        setTimeout(() => {
                            const ref = [tab.assetRef[pupIndex], tab.assetRef[pupIndex + 1]];
                            this.changeRefForToc(ref);
                        });
                    }
                }
                return;
            }

            const navigationInfo = asset ? (asset.content as DocumentContent).navigationInfo : null;
            this.canShowToc = navigationInfo && navigationInfo.canShowToc;
            if (!this.canShowToc) {
                this.tocTitle = this.translate.instant('rech-no-toc-title');
                return;
            }



            let targetId;
            switch (AssetDisplayType[navigationInfo.collectionAssetType]) {
                case AssetDisplayType.PeriodicalCollection:
                    targetId = navigationInfo.collectionAssetID;
                    break;
                case AssetDisplayType.Book:
                    targetId = asset.id;
                    break;
            }

            //const targetId = asset.id;
            const ref = AssetRef.create(AssetDisplayType[navigationInfo.collectionAssetType], targetId,
                { publicationId: navigationInfo.publicationID, source: SourceDetail.Document });
            this.changeRefForToc(ref);
            //this.changeDetectorRef.detectChanges();
        });

        this.subscription.add(this.searchService.userFavorites.subscribe(
            favorites => this.isFavorite = this.tocRef && favorites.reduce((f, c) => c.children ? f.concat(c.children).concat(c) : f.concat(c), []).findIndex(fav => fav.targetID === AssetRef.toAssetID(this.tocRef)) !== -1
        ));
    }

    private changeRefForToc(ref: AssetRef) {
        if (!this.tocRef || AssetRef.toAssetID(ref) !== AssetRef.toAssetID(this.tocRef)) {
            this.assetService.dispatch({ type: AssetActions.set_sidebar_toc_ref.name, payload: ref });
        }
        this.tocRef = ref;
        this.determineTocTitle();
        this.determineAssetUrl();
    }

    public toggleResize() {
        this.assetService.dispatch({ type: SearchActions.toggle_hitlist_open.name });
    }

    public sendEmail() {
        const mailbody = this.tocTitle + '\n' + this.assetUrl;
        const mail = `mailto:?&subject=${encodeURIComponent(this.translate.instant('rech-asset-document-link'))}&body=${encodeURIComponent(mailbody)}`;
        window.location.href = mail;
    }

    public copyUrlToClipboard() {
        this.assetService.copyToClipboard(this.assetUrl);
    }

    public chooseFontSize(size: number, event: any) {
        event.stopPropagation();
        this.assetService.dispatch({ type: this.assetService.actions.toggle_docview_fontsize.name, payload: { size, inSideBar: true } });
    }

    public toggleFavorite() {
        const favorite: UserFavorite = { targetID: this.tocAsset.id, targetType: this.tocAsset.displayType, title: AssetRef.titleForAsset(this.tocAsset) };
        this.assetService.dispatch(makeAction({ type: AssetActions.toggle_favorite.name, payload: { favorite } }));
        //  this.isFavorite = !this.isFavorite;
    }

    private determineAssetUrl() {
        this.assetUrl = `${window.location.protocol}//${window.location.host}${getAssetURL(this.tocRef)}`;
    }

    private determineTocTitle() {
        if (this.tocAssetSubscription) {
            this.tocAssetSubscription.unsubscribe();
        }
        this.tocAssetSubscription = this.assetService.getAsset(this.tocRef).subscribe(tocAsset => {
            this.tocTitle = (tocAsset.content as TocContent).title;
            this.tocAsset = tocAsset;
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        if (this.tocAssetSubscription) {
            this.tocAssetSubscription.unsubscribe();
        }
    }
}
