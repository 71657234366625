import { apiAuthentificationUrl, apiBaseUrl } from '../config';
import { AssetDisplayType, AssetRef, AssetRefOptions, AutocompleteType, BiblioType, HitlistType, RefType, SearchType } from '../models';

export const oauthLoginEndPointUrl = `${apiAuthentificationUrl}/login`;
export const getIsAutoIPLoginEnabled = `${apiAuthentificationUrl}/auth/GetIsAutoIPLoginEnabled`;
export const deleteRefreshTokenUrl =  `${apiAuthentificationUrl}/auth/DeleteRefreshToken?userName=`;
export const getApplicationConfigUrl = `${apiBaseUrl}/api/ApplicationContext/GetApplicationContext?applicationName=`;

export const searchUrls = {
    [SearchType.Retrieval]: 'api/retrieval/postSearch',
    [SearchType.Journal]: 'api/journalSearch/postJournalSearch',
    [SearchType.CaseCollection]: 'api/CaseCollectionSearch/PostCaseCollectionSearch',
    [SearchType.NotPublishedCaseCollection]: 'api/CaseCollectionSearch/PostCaseCollectionNotPublishedSearch',
    [SearchType.Commentary]: 'api/commentarySearch/postCommentarySearch',
    [SearchType.Book]: 'api/bookSearch/PostBookSearch',
    [SearchType.Author]: 'api/authorSearch/postAutorSearch',
    [SearchType.Law]: 'api/LawSearch/postLawSearch',
    [SearchType.EuRetrieval]: 'api/euRetrieval/postSearch',
    [SearchType.EuCaseReference]: 'api/euDirectAccess/postCaseReferenceSearch',
    [SearchType.EuTreaty]: 'api/euDirectAccess/postTreaty',
    [SearchType.EuInternationalAgreement]: 'api/euDirectAccess/postInternationalAgreementSearch',
    [SearchType.EuLegislation]: 'api/euDirectAccess/postLegislationSearch',
    [SearchType.EuCelexNumber]: 'api/euDirectAccess/postCelexNumberSearch',
};

export const retrievalPdfUrl = 'api/pdf/pdfHitList';
export const euRetrievalPdfUrl = 'api/pdf/pdfEuHitList';
export const newsConfigurationUrl = 'api/news/GetNewsConfigurationByNewsId';

export const autoCompleteBaseUrls = {
    // retrieval autocompletes (=> search tab)
    [AutocompleteType.RetrievalSearchText]: 'api/retrieval/getSearchTextAutocomplete',
    [AutocompleteType.RetrievalLaw]: 'api/retrieval/getLawAutocomplete',
    [AutocompleteType.RetrievalArticleNumber]: 'api/retrieval/getArticleNumberAutocomplete',
    [AutocompleteType.RetrievalReference]: 'api/retrieval/getReferenceAutocomplete',
    [AutocompleteType.RetrievalAuthor]: 'api/retrieval/getAuthorAutocomplete',

    // journal search autocompletes
    [AutocompleteType.JournalAbbreviation]: 'api/journalSearch/GetAbbreviationAutocomplete',
    [AutocompleteType.JournalYearOrVolume]: 'api/journalSearch/getYearAutocomplete',
    [AutocompleteType.JournalIssue]: 'api/journalSearch/getIssueAutoComplete',
    [AutocompleteType.JournalPagesOrSequenceNumber]: 'api/journalSearch/getPageAutoComplete',

    // caseCollection search autocomplete
    [AutocompleteType.CaseCollectionPubAbbreviation]: 'api/CaseCollectionSearch/GetPubAbbreviationAutocomplete',
    [AutocompleteType.CaseCollectionPubYear]: 'api/CaseCollectionSearch/GetPubYearAutocomplete',
    [AutocompleteType.CaseCollectionPubPage]: 'api/CaseCollectionSearch/GetPubPageAutocomplete',
    [AutocompleteType.CaseCollectionNotPubCaseNumber]: 'api/CaseCollectionSearch/GetNotPubCaseNumberAutocomplete',

    // commentary search autocompletes
    [AutocompleteType.CommentarySerial]: 'api/commentarySearch/GetSerialAutocomplete',
    [AutocompleteType.CommentaryTitle]: 'api/commentarySearch/GetTitleAutocomplete',
    [AutocompleteType.CommentaryLaw]: 'api/commentarySearch/GetLawAutocomplete',
    [AutocompleteType.CommentaryArticle]: 'api/commentarySearch/GetArticleOfLawAutocomplete',

    // book search autocompletes
    [AutocompleteType.BookSerial]: 'api/bookSearch/GetNonLawBookSerialAutocomplete',
    [AutocompleteType.BookTitle]: 'api/bookSearch/GetNonLawBookTitleAutocomplete',
    [AutocompleteType.BookAuthor]: 'api/bookSearch/GetNonLawBookAuthorAutocomplete',
    [AutocompleteType.BookPage]: 'api/bookSearch/GetNonLawBookPageAutocomplete',

    // author search autocompletes
    [AutocompleteType.AuthorName]: 'api/authorSearch/GetAuthorAutocomplete',
    [AutocompleteType.AuthorTitle]: 'api/authorSearch/GetAutorTitleAutocomplete',

    // law search autocompletes
    [AutocompleteType.LawLaw]: 'api/LawSearch/GetLawAutocomplete',
    [AutocompleteType.LawLawTitle]: 'api/LawSearch/GetLawTitleAutocomplete',
    [AutocompleteType.LawArticleNumber]: 'api/LawSearch/GetLawArticleNumberAutocomplete',

    // eu search autocompletes
    [AutocompleteType.EuCaseReference]: 'api/euDirectAccess/GetCaseReferenceAutocomplete',
    [AutocompleteType.EuIntAgreement]: 'api/euDirectAccess/GetInternationalAgreementAutocomplete',
    [AutocompleteType.EuLegislationYear]: 'api/euDirectAccess/GetLegislationYearAutocomplete',
    [AutocompleteType.EuLegislationNumber]: 'api/euDirectAccess/GetLegislationNumberAutocomplete',
    [AutocompleteType.EuCelexNUmber]: 'api/euDirectAccess/GetCelexNumberAutocomplete',

    // customer statistics autocompletes
    [AutocompleteType.StatisticsDepartment]: 'api/Transaction/GetDepartmentAutocomplete',
    [AutocompleteType.StatisticsUser]: 'api/Transaction/GetUserAutocomplete',

};

export const transactionInfoBaseUrl = 'api/transaction/getRerunTransactionInfo?transactionID=';

export const euTreatyDropdownBaseUrl = 'api/euDirectAccess/GetTreatyNames?language=';

export function getBiblioBooksBaseUrl(type: BiblioType) {
    switch (type) {
        case BiblioType.Journals:
        case BiblioType.PublishedCaseLaws:
        case BiblioType.UnpublishedCaseLaws:
            return `api/Bibliothek/GetBibliothekPeriodicals?type=${type}`;
        case BiblioType.Serials:
        case BiblioType.Books:
        case BiblioType.Commentaries:
            return `api/Bibliothek/GetBibliothekBooks?type=${type}`;
        case BiblioType.BooksUni:
            return `api/Bibliothek/GetBibliothekBooksUni?type=${type}`;
    }
}

export const bibliothekGetJournalsBaseUrl = 'api/Bibliothek/GetJournals';
export const bibliothekGetCaseCollectionBaseUrl = 'api/Bibliothek/GetCaseCollectionByFilter';
export const bibliothekGetBooksByCategoryBaseUrl = 'api/Bibliothek/GetBookByCategory';
export const bibliothekGetPracticeAreasUrl = 'api/Bibliothek/GetPracticeAreas';
export const bibilothekGetCommentaryByCategoryBaseUrl = 'api/Bibliothek/GetCommentaryByCategory';
export const bibliothekGetSerialCategoriesBaseUrl = 'api/Bibliothek/GetSerialCategories';
export const bibliothekGetSerialForCategoryBaseUrl = 'api/Bibliothek/GetSerialsByName';
export const bibliothekGetBooksUniBaseUrl = 'api/Bibliothek/GetBibliothekBooksUni';

export const topDocumentsUrl = 'api/doc/GetTopDocuments';
export const topDocumentsHomeGraphUrl = 'api/Graph/GetTopDocuments';

export const eventsGetAllPracticeAreasBaseUrl = 'api/events/GetAllPracticeAreas';
export const eventsGetCurrentEventsBaseUrl = 'api/events/GetAllEventsIncludingPracticeAreas';
export const eventsGetEventsForStartPage = 'api/events/GetEventsStartPage';
export const eventsGetSpecificAmountOfEventsUrl = 'api/events/GetSpecificAmountOfEvents';
export const eventsGetEventBaseUrl = 'api/events/GetEvent';
export const eventsCreateEventUrl = 'api/Events/PostSaveEvent';

export const currentJobUrl = 'api/Jobs/GetCurrentJob?id=';
export const allJobsUrl = 'api/Jobs/GetAllJobs';
export const topJobsUrl = 'api/Jobs/GetTopJobs';
export const jobsForStartPage = 'api/Jobs/GetJobsForStartPage?numberOfJobs=';

export const searchHistoryUrl = 'api/SearchHistory';
export const searchFiltersUrl = 'api/retrieval';
export const favoritesUrl = 'api/favorite';

// CustomerProfile urls
export const portalUserDataUrl = 'api/CustomerProfile/GetPortalUserData';
export const postUserProfileDataUrl = 'api/CustomerProfile/PostUpdateUser';
export const customerProfileDataUrl = 'api/CustomerProfile/GetCustomerProfile';
export const postCustomerProfileDataUrl = 'api/CustomerProfile/PostUpdateCustomerProfile';
export const getUserPreferencesUrl = 'api/CustomerProfile/GetUserPreferences';
export const getUserTipsViewed = 'api/userHint/GetUserTipsViewed';
export const getTips = 'api/userHint/GetTips?language=';
export const getWelcome = 'api/userHint/GetWelcome?language=';
export const getImageUrl = 'api/Image/GetImage?id=';
export const postUpdateUserTipsUrl = 'api/userHint/PostUpdateUserTipsViewed';
export const postUpdateUserPreferencesUrl = 'api/CustomerProfile/PostUpdateUserPreferences';
export const departmentsDataUrl = 'api/CustomerProfile/GetDepartments?language=';
export const postDepartmentDataUrl = 'api/CustomerProfile/PostEditDepartment';
export const usersDataUrl = 'api/CustomerProfile/GetPortalUserListByCompanyID?language=';
export const exportUsersDataToExcelUrl = 'api/CustomerProfile/GetExportUserToExcel?language=';
export const activateOrDeactivateUser = 'api/CustomerProfile/PostActivateOrDeactivateUser';
export const sendUserLogin = 'api/CustomerProfile/PostPortalUserLoginSend';
export const sendAllUserLogin = 'api/CustomerProfile/GetPortalUserLoginSendAll?departmentID=';
export const activateOrDeactivateDepartment = 'api/CustomerProfile/PostActivateOrDeactivateDepartment';
export const postAddNewUserDataUrl = 'api/CustomerProfile/PostAddPortalUser';
export const getAddNewDepartmentDataUrl = 'api/CustomerProfile/GetAddDepartment';
export const postAddNewDepartmentDataUrl = 'api/CustomerProfile/PostAddDepartment';
export const postSearchTransactionsStatistics = 'api/Transaction/FindTransactionsStatistics?language=';
export const getTransactionsType = 'api/Transaction/GetAllTransactionTypesForUser';
export const getTransactionsTypeForOrder = 'api/Transaction/GetTransactionGroupTypesForOrder';

// Info Controller urls
export const postChangePasswordUrl = 'api/Info/PostPasswordChange';
export const postChangePasswordDataUrl = 'api/Info/PostPasswordChangeForm';
export const postSendEmailPasswordForgot = 'api/Info/PostPasswordForgot';

// News
export const getNewsConfigurationsUrl = 'api/news/getNewsByUser';
export const getNewsContent = 'api/news/GetNewsContent?newsId=';
export const validateSearchNewsUrl = 'api/news/ValidateSearchNews';
export const getPracticeAreasForNewsUrl = 'api/news/GetMultiLevelPracticeAreasForNews';
export const getCourtsForNewsUrl = 'api/news/GetCourtsForNews';
export const getPrintJournalsForNewsUrl = 'api/news/GetPrintJournalsForNews';

export const deleteNewsUrl = 'api/news/DeleteNews?newsId=';
export const createNewsUrl = 'api/news/CreateNews';
export const updateNewsUrl = 'api/news/UpdateNews';

export const sendNewsNowUrl = 'api/news/SendNewsNow?newsId=';

// SearchFilter
export const userSearchFiltersUrl = 'api/retrieval/getUserSearchFilters';
export const getSearchFilterUrl = 'api/retrieval/getSearchFilter?id=';
export const updateSearchFilterOrderUrl = 'api/retrieval/SearchFilterUpdateOrder';
export const updateSearchFilterTitleUrl = 'api/retrieval/SearchFilterUpdateTitle';
export const deleteSearchFilterUrl = 'api/retrieval/DeleteSearchFilter?id=';
export const unDeleteSearchFilterUrl = 'api/retrieval/UnDeleteSearchFilter';
export const saveSearchFilterUrl = 'api/retrieval/SaveSearchFilter';
export const overwriteSearchFilterUrl = 'api/retrieval/OverwriteSearchFilter';

// About Swisslex
export const aboutSwisslexSendContactFormUrl = 'api/AboutSwisslex/PostContact';

// FAQ urls
export const getFaqOptionsUrl = 'api/userHint/GetFAQOptions';
export const getFaqByTopicIDUrl = 'api/userHint/GetFAQByTopicID';
export const getFaqTopicIDAmountUrl = 'api/userHint/GetFAQTopicAmount';
export const getFaqByIDUrl = 'api/userHint/GetFAQByID';
export const putTopicNameUrl = 'api/userHint/putTopicName';
export const postFaqEntryUrl = 'api/userHint/PostFAQEntry';
export const deleteTopicUrl = 'api/userHint/DeleteTopic';
export const deleteFaqEntryUrl = 'api/userHint/DeleteFAQEntry';
export const getAllFaqsUrl = 'api/userHint/GetAllFAQs';
export const deleteImageUrl = 'api/userHint/DeleteImageByID';

// Abo and Services urls
export const getUsersIpUrl = 'api/AboServices/GetUsersIp';
export const getRegisterBusinessCustomerFormInputsUrl = 'api/AboServices/GetRegisterBusinessCustomerFormInputs?language=';
export const postRegisterBusinessCustomerDataUrl = 'api/AboServices/PostBusinessCustomer';
export const getRegisterUniversityCustomerFormInputsUrl = 'api/AboServices/GetRegisterUniversityCustomerFormInputs?language=';
export const postRegisterUniversityUserDataUrl = 'api/AboServices/PostAddUniversityPortalUser';
export const getRegisterRakCustomerFormInputsUrl = 'api/AboServices/GetRegisterRakSubscriptionFormInputs?language=';
export const postRegisterRakUserDataUrl = 'api/AboServices/PostRakSubscription';
export const postActivateRakUserDataUrl = 'api/AboServices/PostRakActivation';
export const postTrainingDataUrl = 'api/AboServices/PostTrainingData';

export const getThesaurusInformationUrl = 'api/retrieval/getThesaurusInformation?searchText=';

export const postBackupOfStoreUrl = 'api/storeBackup/postStoreBackup';
export const getBackupOfStoreUrl = 'api/storeBackup/getStoreBackup';
export const getCustomBackupsUrl = 'api/storeBackup/getCustomBackups';

export const lawViewBaseUrl = 'api/doc/ShowLawViewByGuid?lawGuid=';
export const docViewBaseUrl = 'api/doc/getAssetContent?assetID=';
export const docViewComingFromCitationBaseUrl = 'api/doc/ShowDocComingFromCitation/';
export const euDocViewBaseUrl = 'api/doc/GetEuAssetContent?euAssetID=';
export const getEuDocIDBasedOnCelexNr = 'api/doc/GetEuDocIDBasedOnCelexNr?celexnr=';
export const docViewCitingBaseUrl = 'api/doc/getAssetCiting?assetID=';
export const previewAssetsUrl = 'api/doc/PreviewAssets?previewAssetIDsCsv=';
export const assetTooltipInfoUrl = 'api/doc/GetAssetTooltipInfo?assetID=';
export const periodicalTocUrl = 'api/toc/GetPeriodicalContent?publicationId=';
export const unknownTocUrl = 'api/toc/GetTocForUnkownPuplicationType?publicationId=';
export const tocItemsUrl = 'api/toc/GetTocItems?collectionAssetID=';
export const euTocItemsUrl = 'api/euToc/GetTocItems?language=';
export const euAvailableYearsByCourtUrl = 'api/euToc/GetAvailableYearsByCourt?courtId=';
export const euLawsByTreatyUrl = 'api/euToc/GetEuLawsByTreaty?treatyId=';
export const euAvailableYearsUrl = 'api/euToc/GetAvailableYears?euLegalActTypeId=';
export const euLawsByYearUrl = 'api/euToc/GetEuLaws?euLegalActTypeId=';
export const euYearsByLegislationUrl = 'api/euToc/GetAvailableYearsByLegislation?euLegalActTypeId=';
export const euCaseLawsByYearUrl = 'api/euToc/GetEuCaseLaws?courtId=';
export const tocBookUrl = 'api/toc/GetBookContent?publicationId=';
export const previewUrl = 'api/doc/GetPreviews?guidCsv=';
export const saveFavoriteUrl = 'api/favorite/PostUserFavorite';
export const updateTitleFavoriteUrl = 'api/favorite/PostUpdateFavorite';
export const updateOrderFavoritesUrl = 'api/favorite/PostUpdateOrderFavorites';
export const deleteFavoriteBaseUrl = 'api/favorite/DeleteUserFavorite';
export const getUserFavoritesUrl = 'api/favorite/GetUserFavorites';
export const publicationFilterOptionsUrl = 'api/retrieval/GetPublicationFilterContent?language=';
export const practiceAreaFilterOptionsUrl = 'api/retrieval/GetPraticeAreasForFilter';
export const showLawViewUrl = 'api/doc/ShowLawViewByGuid?lawGuid=';
export const getCommentariesForLawUrl = 'api/doc/GetCommentariesForLaw?lawGuid=';
export const postGetCommentariesForLawsUrl = 'api/doc/PostGetCommentariesForLaws?assetID=';
export const getLawAndAolIDfromSrUrl = 'api/doc/GetLawAndAolIdFromSr?lawSr=';
export const getCitationInformation = 'api/doc/getCitationInformation?assetID=';

export const getStaticContentMarkdownUrl = 'api/Content/GetStaticContent?markDownFileName=';

export function getAssetRefURL(ref: AssetRef, language) {
    const langOptionIndex = ref.findIndex(r => r === AssetRefOptions.assetLanguage);
    if (langOptionIndex > -1) {
        language = ref[langOptionIndex + 1];
    }
    switch (ref[0] as RefType) {
        case HitlistType.Search:
            return transactionInfoBaseUrl + ref[1];

        // Law
        case AssetDisplayType.LawText:
        case AssetDisplayType.LawDocument:
            return `${lawViewBaseUrl}${ref[1]}&language=${language}`;
        case AssetDisplayType.ArticleOfLawDocument:
            return `${lawViewBaseUrl}${ref[3]}&aolGuid=${ref[1]}&language=${language}`;

        // ToC
        case AssetDisplayType.Book:
        case AssetDisplayType.Commentary:
           return `${tocBookUrl}${ref[1]}&language=${language}&reRunTransactionId=0`;

        case AssetDisplayType.PeriodicalCollection:
        case AssetDisplayType.PeriodicalPublication:
           return `${periodicalTocUrl}${ref[1]}&language=${language}&reRunTransactionId=0`;

        case AssetDisplayType.UnknownPublication:
            return `${unknownTocUrl}${ref[1]}&language=${language}&reRunTransactionId=0`;

        case AssetDisplayType.EuToc:
            return `${euTocItemsUrl}${language}`;

        // Eu Documents
        case AssetDisplayType.EuDoc:
        case AssetDisplayType.EuCaseLawDocument:
        case AssetDisplayType.EuLawDocument:
            return `${euDocViewBaseUrl}${ref[1]}&language=${language}`;

        // CH Documents
        case AssetDisplayType.CaseLawDocument:
        case AssetDisplayType.CaseLawReviewDocument:
        case AssetDisplayType.OfficialPupDocument:
        case AssetDisplayType.CommentaryDocument:
        case AssetDisplayType.EssayDocument:
        case AssetDisplayType.BookDocument:
        case AssetDisplayType.CommentaryDocument:
        case AssetDisplayType.UnknownDocument:
            const minimalLength = ref.find(r => r === AssetRefOptions.sourceDetail) ? 6 : 4;
            return ref.length >= minimalLength && ref[2] === AssetRefOptions.CitedDocument ?
                `${docViewComingFromCitationBaseUrl}${ref[1]}?citationID=${ref[3]}&language=${language}` // c63a8aea-1ea2-4b78-9196-8e18be1d1bb8?citationId=78085533-b5d5-45bb-b9b6-cb36e23c750d&source=document-link
                : `${docViewBaseUrl}${ref[1]}&language=${language}`;


        // Preview
        case AssetDisplayType.Previews:
        case AssetDisplayType.PreviewLaw:
        case AssetDisplayType.PreviewDoc:
            return previewUrl + ref[1];

        default:
            return '?unknown-asset-type';
    }
}


export function getUrlForLogo(logoUrl: string): string {
    return `${apiBaseUrl}${logoUrl}`;
}

