import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { NewsService } from '../../news.service';

@Component({
    selector: 'slx-wizard-search-validation',
    templateUrl: './wizard-search-validation.component.html',
    styleUrls: ['./wizard-search-validation.component.scss'],
})
export class WizardSearchValidationComponent implements OnDestroy {

    private sub: Subscription;

    public numberOfHits: number;
    public spinnerActive = true;
    public searchSuccessful = false;
    public searchNoHits = false;
    public searchError = false;

    @Output() searchErrorNotify = new EventEmitter();

    constructor(public newsService: NewsService) {
        this.sub = this.newsService.searchValidation.subscribe(validationInformation => this.validationResult(validationInformation));
    }
    private validationResult(result) {

        this.spinnerActive = true;
        this.searchSuccessful = false;
        this.searchNoHits = false;
        this.searchError = false;

        switch (result.validationResult) {
            case 'Succesfull':
                this.searchSuccessful = true;
                this.spinnerActive = false;
                this.numberOfHits = result.noOfHits;
                this.searchErrorNotify.emit(false);
                break;

            case 'NoHits':
                this.searchNoHits = true;
                this.spinnerActive = false;
                this.searchErrorNotify.emit(false);
                break;

            case 'Error':
                this.searchError = true;
                this.spinnerActive = false;
                this.searchErrorNotify.emit(true);
                break;
        }
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
