import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable ,  of ,  pipe } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { MatAutocomplete } from '../../../../node_modules/@angular/material';
import { environment } from '../../../environments/environment';
import { getValidationMessage } from '../validators';

const debug = require('debug')('textcomponent');

@Component({
    selector: 'slx-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TextComponent),
            multi: true,
        },
    ],
})
export class TextComponent implements ControlValueAccessor, OnInit, Validator {

    @Input() name: string;
    @Input() type: string;
    @Input() pattern: string;
    @Input() textClass = '';
    @Input() value = '';
    @Input('placeholder') _placeholder: string;
    @Input() disabled: boolean;
    @Input() autocompleteName: string;
    @Input() listenToTextChange: any;
    @Input() formControlName: String;
    @Input() validatorValue: boolean;
    @Input() isRequired = false;
    @Input() passwordStrength = false;
    @Input() showCount = false;
    @Input('adviceText') _adviceText: string = null;

    @Input() autocomplete: Function;
    @Input() autocompleteOnFocus: boolean;
    @Input() autocompleteMinQueryLength: number;

    @ViewChild('autocomp') autocomp: MatAutocomplete;

    public readonly autocompleteDefaultOnFocus = true;
    public readonly autocompleteDefaultOnFocusRefresh = true;
    public readonly autocompleteDefaultMinQueryLength = 1;

    get _autocompleteOnFocus(): boolean {
        return this.autocompleteOnFocus == null ? this.autocompleteDefaultOnFocus : this.autocompleteOnFocus;
    }
    get _autocompleteMinQueryLength(): number {
        return this.autocompleteMinQueryLength == null ? this.autocompleteDefaultMinQueryLength : this.autocompleteMinQueryLength;
    }

    get showDebug(): boolean {
        return environment.forms;
    }

    public isIE11 = false;

    @Output() isSelectionFromDropdown = new EventEmitter<Boolean>();
    @Output() textChanged = new EventEmitter<String>();

    public getValidationMessage: (formControl: any, translate: any) => string = getValidationMessage;

    public showIndicator = false;
    public options: Observable<Array<string>>;
    public formControl = null;
    public showValidationResults = false;

    private preventFormSubmit = false;

    bar = [];
    private colors = ['#F00', '#F00', '#FF0', '#9F0', '#0F0', '#DDD'];

    constructor(public translate: TranslateService, private changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11\./);
    }

    get placeholder() {
        if (this._placeholder) {
            return this.isRequired ? `${this.translate.instant(this._placeholder)} *` : this.translate.instant(this._placeholder);
        } else {
            return null;
        }
    }
    get adviceText() {
        return this._adviceText ? this.translate.instant(this._adviceText) : null;
    }

    private propagateChange = (_: any) => { };
    private _onTouched = (_: any) => { };

    public writeValue(str: any) {
        debug(this.name, 'writeValue');
        // reset autocomplete options as the value the current options based on has changed from outside
        if (this.autocomplete) {
            this.resetAutocompleteOptions();
        }
        this.value = str || '';
    }
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }
    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    private querySuggests = pipe(
        filter((input: string) => {
            debug(this.name, 'querySuggests', 'filter', input);
            return (input || []).length >= this._autocompleteMinQueryLength;
        }),
        switchMap((input: string) => {
            debug(this.name, 'querySuggests', 'switchMap', input);
            this.showIndicator = true;
            return this.autocomplete(input);
        }),
        map((options: string[]) => {
            debug(this.name, 'querySuggests', 'map', options);
            setTimeout(() => {
                this.showIndicator = false;
                this.changeDetectorRef.markForCheck();
            }, 250);
            return options;//.slice(0,100);
        })
    );

    public onFocus() {
        debug(this.name, 'onFocus', 'runAutoComplete');
        if (this.autocomplete && this._autocompleteOnFocus) {
            if (this.options == null) {
                this.options = of(this.value).pipe(this.querySuggests);
            } else {
                // allow showing of autocomplete by not modifying last result
                return;
            }
        } else {
            // prevent material from showing the autocomplete
            this.resetAutocompleteOptions();
        }
    }

    public onArrow(event) {
        if (this.autocomplete && this.autocomp._keyManager.activeItem) {
            const el = this.autocomp.panel.nativeElement;
            setTimeout(function () { el.querySelectorAll('.mat-option.mat-active')[0].scrollIntoView(); });
        }
    }

    public onInput(event) {
        const oldValue = this.value;
        this.value = event.target.value;
        //IE 11 fires input change on focus & blur when input has a placeholder and no value, so prevent this case
        if ((oldValue == null || oldValue === '') && (this.value == null || this.value === '')) {
            debug(this.name, 'onInput prevent start', `value: ${this.value}, newValue: ${oldValue}`);
            return true;
        }

        debug(this.name, 'onInput', 'start', event, event.target);

        this.showValidationResults = false;
        this.propagateChange(this.value);
        this.isSelectionFromDropdown.emit(false);
        if (this.listenToTextChange) {
            this.textChanged.emit(this.value);
        }
        if (this.passwordStrength) {
            setTimeout(() => {
                const strength = measureStrength(this.formControl.value, this.formControl.errors);
                if (this.formControl.value) {
                    const c = this.getStrengthIndexAndColor(strength);
                    this.setBarColors(c.count, c.col, this.colors[5]);
                } else {
                    this.setBarColors(5, this.colors[5], this.colors[5]);
                }
            }, 30);
        }

        if (this.autocomplete) {
            debug(this.name, 'onInput', 'autocomplete', this.value);
            this.options = of(this.value).pipe(
                delay(500), //debounceTime does not work as we overwrite the observable
                distinctUntilChanged(),
                this.querySuggests
            );
        }
    }

    get showError() {
        return this.formControl.invalid && (this.showValidationResults || this.formControl.touched);
    }

    public onBlur(event) {
        //checkStatus
        this.showValidationResults = event.target.value.length > 0 ? true : false;
    }

    public validate(c: FormControl) {
        this.formControl = c;
        if (c.value === null) {
            this.showValidationResults = false;
            this.setBarColors(5, this.colors[5], this.colors[5]);
        }
        return {};
    }

    public onOptionSelected() {
        debug(this.name, 'onOptionSelected');
        if (this.autocomp.isOpen) {
            this.preventFormSubmit = true;
        }
    }
    public onEnter(event) {
        debug(this.name, 'onEnter');
        const prevent = this.preventFormSubmit;
        this.setValueFromSelection(event.target.value);
        if (prevent) {
            event.stopPropagation();
            return false;
        }
    }

    public onTab(event) {
        debug(this.name, 'onTab');
        if (this.autocomplete && !!this.autocomp._keyManager.activeItem) {
            this.setValueFromSelection(this.autocomp._keyManager.activeItem.value);
        }
    }

    public onOptionClick(event: any, option) {
        debug(this.name, 'onOptionClick');
        this.setValueFromSelection(option);
        event.stopPropagation();
        return false;
    }

    public setValueFromSelection(option: string) {
        debug(this.name, 'setValueFromSelection');
        // Because options array consists pure html, we need to strip off all the tags
        this.value = option.replace(/<[^>]+>/gm, '');
        if (this.listenToTextChange) {
            this.textChanged.emit(this.value);
        }
        this.propagateChange(this.value);
        this.resetAutocompleteOptions();
        this.isSelectionFromDropdown.emit(true);
        this.preventFormSubmit = false;
    }

    public resetAutocompleteOptions() {
        debug(this.name, 'resetAutocompleteOptions');
        this.options = null;
        this.showIndicator = false;
    }

    // 0 = fail, 1 = pass
    public getStrengthIndexAndColor(strength: number) {
        let idx = 0;

        if (strength < 0.2) {
            idx = 0;
        } else if (strength < 0.4) {
            idx = 1;
        } else if (strength < 0.8) {
            idx = 2;
        } else if (strength < 1) {
            idx = 3;
        } else {
            idx = 4;
        }

        return {
            count: idx + 1,
            col: this.colors[idx],
        };
    }
    private setBarColors(count: number, col: string, grey: string) {
        for (let n = 0; n < 5; ++n) {
            this.bar[n] = n < count ? col : grey;
        }
    }
}

function measureStrength(password: string, errorsObj: any): number {
    if (!errorsObj) return 1;

    const withQuality = Object.keys(errorsObj).map(name => ({
        quality: errorsObj[name].quality,
        maxQuality: (errorsObj[name].maxQuality || 1),
    })
    ).filter((w) => typeof w.quality === 'number');

    const force = withQuality.reduce((sum, w) => sum + w.quality, 0) / (withQuality.reduce((sum, w) => sum + w.maxQuality, 0) || 1);

    return force;
}
