import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { LoginService } from '../access';
import { AppActions } from '../appActions';
import { RechTabTypes, SearchType } from '../models';

import { AppService } from './../app.service';
import { HomeService } from './../home';
import { SearchService } from './search.service';
import { SearchActions } from './searchActions';

@Component({
    selector: 'slx-recherche',
    templateUrl: './recherche.component.html',
    styleUrls: ['./recherche.component.scss'],
    providers: [SearchService],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(0, style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate(600, style({ opacity: 0 })),
            ]),
        ]),
    ],
})

export class RechercheComponent implements AfterViewInit, OnDestroy {
    @ViewChild('rechercheTop') rechercheTop: ElementRef;

    private subscription: Subscription = new Subscription();

    private currentlyCollapsed;
    public marginTop = '0px';
    public minHeight = '0px';

    constructor(public searchService: SearchService, public loginService: LoginService, private route: ActivatedRoute, public homeService: HomeService, private appService: AppService, public router: Router) {
        const legacyJournal = this.route.snapshot.queryParams['d'];
        if (legacyJournal) {
            const journalParameters = legacyJournal.split('-');

            if (journalParameters.length === 3 || journalParameters.length === 4) {
                const inputAbbreviation = journalParameters[0];
                const inputYearOrVolume = journalParameters[1];

                const inputPageOrSequenceNumber = journalParameters.length === 1 ?
                    journalParameters[2] + '-' + journalParameters[3] :
                    journalParameters[2];

                this.searchService.dispatch({
                    type: SearchActions.search_fields_submit.name,
                    payload: {
                        type: SearchType.Journal,
                        parameters: {
                            abbreviation: inputAbbreviation,
                            yearOrVolume: inputYearOrVolume,
                            pagesOrSequenceNumber: inputPageOrSequenceNumber,
                        },
                    },
                });
            }
        }
    }


    // The Coponent checks in the Template with an *ngIf whether it should be rendered or not. Because we want to access a viewCHild in the inner Container,
    // it is sometimes not possible when the template is not rendered through *ngIf in the first place. (native Element of... is undefined).
    // This way, we have a subscription here that checks additionally for the loggedInState and then executes the subscription for searchCollapse.

    ngAfterViewInit() {
        this.subscription
            .add(this.loginService.loggedIn.subscribe(loggedIn => {
                if (loggedIn) {
                    this.subscription.add(this.searchService.searchCollapsed.subscribe(collapsed => { this.currentlyCollapsed = collapsed; this.getMarginTop(collapsed); }));
                }

            }))
            .add(this.appService.browserWindowSize.subscribe(res => { if (this.currentlyCollapsed) { this.getMarginTop(true); } }));
    }

    public getMarginTop(searchIsCollapsed: Boolean) {
        const mainTop = parseInt(window.getComputedStyle(document.querySelector('main')).paddingTop);
        const navHeight: HTMLElement = document.querySelector('slx-header');

        setTimeout(() => {
            if (searchIsCollapsed) {
                this.marginTop = - (this.rechercheTop.nativeElement.offsetHeight + mainTop - navHeight.offsetHeight - 28) + 'px'; // (always subscract current $top-gutter + 7px(0.5rem))
                this.minHeight = this.rechercheTop.nativeElement.offsetHeight + 'px';
            } else {
                this.marginTop = '0px';
                this.minHeight = '0px';
            }
        });
    }

    public switchCollectionTab(name) {
        this.searchService.dispatch({ type: AppActions.set_collection_tab.name, payload: { collectionTab: name } });
    }
    public switchRechercheTab(name : RechTabTypes) {
        this.searchService.dispatch({ type: SearchActions.set_rech_tab.name, payload: name });
        this.searchService.dispatch({type: SearchActions.expand_search.name});
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
