import { AccountState, enhanceActionsObject } from '../models';

export const AccountActions = {
    reload_language_dependent_data(state: AccountState) {
        return state;
    },

    language_changed(state: AccountState) {
        return state;
    },

    reload_language_dependent_data_rech(state: AccountState) {
        return state;
    },

    reload_language_dependent_data_news(state: AccountState) {
        return state;
    },

    reload_language_dependent_data_biblio(state: AccountState) {
        return state;
    },

    reload_language_dependent_data_jobs(state: AccountState) {
        return state;
    },

    reload_language_dependent_data_events(state: AccountState) {
        return state;
    },

    reload_language_dependent_data_customer_profile(state: AccountState) {
        return state;
    },

    reload_language_dependent_data_abo(state: AccountState) {
        return state;
    },

    reload_language_dependent_data_about(state: AccountState) {
        return state;
    },
};

enhanceActionsObject(AccountActions);


