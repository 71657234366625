module.exports = {
	"common-month-january": "Janvier",
	"common-month-february": "Février",
	"common-month-march": "Mars",
	"common-month-april": "Avril",
	"common-month-may": "Mai",
	"common-month-june": "Juin",
	"common-month-july": "Juillet",
	"common-month-august": "Août",
	"common-month-september": "Septembre",
	"common-month-october": "Octobre",
	"common-month-november": "Novembre",
	"common-month-december": "Décembre",
	"common-back-to-top": "Vers le haut",
	"common-doc": "Documents"
};