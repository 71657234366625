import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GridApi, GridOptions, RowClickedEvent, RowDataChangedEvent } from 'ag-grid-community';
import { differenceInMilliseconds } from 'date-fns';
import { Subscription } from 'rxjs';

import { EventsService } from '../../events/events.service';
import { GridComponent } from '../../field/grid/grid.component';
import { toDate } from '../../utility/date-format';
import { createHrefWrapperForCellrender } from '../../utility/utilityFunctions';

@Component({
    selector: 'slx-event-grid',
    template: `
        <slx-grid
            [options] = "gridOptions"
            [columnDefs] = "columnDefs"
            [title]="'events-current'"
            [rowTranslatePrefix]="'events-current'"
            [rowData]="eventService.currentEventsListForGrid"
            [getRowHeight]="getRowHeight"
            [getRowClass]="getRowClass"
            (rowDataChanged)="dataChanged($event)"
            [domLayout]="domLayout"
            (rowClicked)="onRowClicked($event)">
        </slx-grid>
        `,
    styles: [
        `
        :host{
            display: block;
            width: 95%;
            max-width: 1600px;
            height: 100%;
            margin: 0 auto;
        }
    `,
    ],
})
export class GridEventComponent implements OnDestroy {
    @ViewChild(GridComponent) grid: GridComponent;

    public gridOptions: GridOptions;
    public columnDefs;
    public domLayout;

    private filterDateSubs: Subscription;
    private choosenDateForCalendar: string;

    private get gridApi(): GridApi {
        return this.grid ? this.grid.gridApi : null;
    }

    constructor(private router: Router, public eventService: EventsService) {
        this.prFormatter = this.prFormatter.bind(this);

        this.gridOptions = <GridOptions>{
            headerHeight: 50,
        };

        this.columnDefs = [
            {
                headerName: 'Date',
                field: 'date',
                filter: 'agSetColumnFilter',
                sort: 'asc',
                sortingOrder: ['asc', 'desc'],
                minWidth: 110,
                maxWidth: 110,
                comparator: this.dateComparator,
                suppressMenu: true,
            },
            {
                headerName: 'Title',
                field: 'title',
                minWidth: 300,
                suppressMenu: true,
                cellRenderer: this.titleCellRenderer,
            },
            {
                headerName: 'PracticeArea',
                field: 'practiceAreaList',
                maxWidth: 500,
                minWidth: 220,
                valueGetter: this.prFormatter,
                cellRenderer: this.practiceAreaCellRenderer,
                suppressMenu: true,
            },
            {
                headerName: 'Place',
                field: 'city',
                minWidth: 150,
                maxWidth: 200,
                suppressMenu: true,
                cellRenderer: this.locationCellRenderer,
            },
        ];

        this.filterDateSubs = this.eventService.choosenDate.subscribe(date => {
            this.choosenDateForCalendar = date;
            // Prevent calling function when there is no date in the first place
            if (date !== '' && this.gridApi) {
                this.filterForCalenderDate();
            }
        });
    }

    ngOnDestroy() {
        this.filterDateSubs.unsubscribe();
    }

    //-------------------------------------------------------------------------------------
    // Formatters, Comperators, CellRenderers Header Translations
    //-------------------------------------------------------------------------------------

    private prFormatter(params) {
        return params.data.practiceAreaList.reduce((prev, curr) => {
            prev.push(curr.description);
            return prev;
        }, []);
    }

    private dateComparator(date1: string, date2: string): number {
        return differenceInMilliseconds(toDate(date1), toDate(date2));
    }

    private titleCellRenderer(params: any): HTMLAnchorElement {
        return createHrefWrapperForCellrender(params.value, `/events/details/${params.data.id}`);
    }

    private locationCellRenderer(params: any): HTMLElement {
        const title = document.createElement('span');
        title.style.cssText = 'text-overflow: ellipsis; overflow: hidden; padding-right: 1rem;';
        title.innerHTML = title.title = params.value;
        return title;
    }

    private practiceAreaCellRenderer(params) {
        if (length === 1) {
            return params.value[0];
        }

        const eDiv = document.createElement('div');
        eDiv.style.cssText = 'width: 100%';
        for (let i = 0; i < params.value.length; i++) {
            const eA = document.createElement('span');
            eA.style.cssText =
                'text-overflow: ellipsis; overflow: hidden; display: block; padding-right: 1rem';
            eA.innerHTML = `${params.value[i]} <br>`;
            eA.title = params.value[i];
            eDiv.appendChild(eA);
        }
        return eDiv;
    }

    private filterForCalenderDate(): void {
        let filterModelArray = null;
        if (this.choosenDateForCalendar) {
            filterModelArray = [this.choosenDateForCalendar];
        }

        const filter = this.gridApi.getFilterInstance('date');
        filter.setModel(filterModelArray);

        this.gridApi.onFilterChanged();
    }

    //-------------------------------------------------------------------------------------
    // RowHeight, Gridheight
    //-------------------------------------------------------------------------------------

    public getRowHeight(params): number {
        if (params.node.level === 0) {
            const nPa = params.data.practiceAreaList.length;
            if (nPa < 3) {
                return 50;
            }
            return 50 + (nPa - 2) * 20;
        }
    }

    public getRowClass(params): string {
        if (!params.data.practiceAreaList || params.data.practiceAreaList.length < 3) {
            return 'singleTitleRow';
        }
        return 'multiTitleRow';
    }

    //-------------------------------------------------------------------------------------
    // Events & Listeners
    //-------------------------------------------------------------------------------------

    public dataChanged(event: RowDataChangedEvent): void {
        if (this.gridApi) {
            this.filterForCalenderDate();
        }
    }

    public onRowClicked(rowEvent: RowClickedEvent): void {
        const mouseEvent = rowEvent.event as MouseEvent;
        if (!mouseEvent || !mouseEvent.ctrlKey) {
            this.router.navigate(['events/details', rowEvent.data.id]);
        }
    }
}
