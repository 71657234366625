import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, concat, Observable, of ,  Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { AccountService } from '../../../account';
import { AssetService, AutocompleteService } from '../../../asset';
import { FieldService, ImmerFieldActions } from '../../../field';
import { defaultPublicationCriteria, LanguageEnum, SearchType, ThesaurusInformation } from '../../../models';
import { AutocompleteParamter, AutocompleteType } from '../../../models/research';
import { PublicationSelectDialogComponent } from '../../publication-select-dialog/publication-select-dialog.component';
import { SearchService } from '../../search.service';
import { SearchActions } from '../../searchActions';
import { SearchfiltersComponent } from '../../searchfilters/searchfilters.component';
import { SearchFilterSaveComponent } from '../../searchfilters/searchfiltersave/searchfilter-save.component';
import { SearchFormBase } from '../search-form-base';
import { SearchFormComponent } from '../search-form.component';

const debug = require('debug')('search');

@Component({
    selector: 'slx-search',
    template: `
        <slx-search-form *ngIf="formDescription && searchForm"
            [formDescription]="formDescription" [searchForm]="searchForm"
            (searchSubmitted)="onSubmit(searchForm)" (searchReset)="onReset()" #slxSearchForm>
        </slx-search-form>`,
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent extends SearchFormBase {

    @ViewChild('slxSearchForm') slxSearchForm: SearchFormComponent;

    public searchForm: FormGroup;
    public formDescription: any;
    public languageOptions = [];
    public selectedLanguage: LanguageEnum;
    public selectedPublications = defaultPublicationCriteria;

    private thesaurusInformation: ThesaurusInformation;
    private initialMultiCheckValues: any;

    searchButton = true;
    backButton = false;
    value = 0;

    private advancedSearchChanges = new BehaviorSubject<number>(0);

    constructor(
        private autocompleteService: AutocompleteService,
        protected searchService: SearchService,
        private assetService: AssetService,
        private accountService: AccountService,
        protected formBuilder: FormBuilder,
        private fieldService: FieldService,
        public dialog: MatDialog,
        protected changeDetectorRef: ChangeDetectorRef,
        protected translate: TranslateService
    ) {
        super(searchService, SearchType.Retrieval, changeDetectorRef, translate);

        this.addArticleOfLawCriterias = this.addArticleOfLawCriterias.bind(this);
        this.removeArticleOfLawCriterias = this.removeArticleOfLawCriterias.bind(this);
        this.searchTextChanged = this.searchTextChanged.bind(this);
        this.thesaursStateChanged = this.thesaursStateChanged.bind(this);
        this.langSelectionChanged = this.langSelectionChanged.bind(this);
        this.createSearchFilter = this.createSearchFilter.bind(this);
        this.editSearchFilter = this.editSearchFilter.bind(this);
        this.resetSearchFilter = this.resetSearchFilter.bind(this);
        this.openPublicationDialog = this.openPublicationDialog.bind(this);
        this.getSearchTextAutocomplete = this.getSearchTextAutocomplete.bind(this);

        // Create searchForm with Formbuilder
        this.searchForm = this.formBuilder.group({
            searchText: [''],
            searchLanguage: [''],
            articleOfLawFilterCriterias: this.formBuilder.array([
                this.initArticleOfLawCriterias(),
            ]),
            dateFrom: [''],
            dateUntil: [''],
            reference: [''],
            author: [''],
            practiceAreaGroupsCriteria: [''],
            assetTypeGroupsCriteria: [''],
            thesaurusType: [''],
            userSearchFilterId: [''],
        });

        this.controlDependencies = ['law', 'articleNumber', 'paragraph', 'subParagraph'];

        this.selectedLanguage = accountService.langEnum;
        this.initLanguages();

        this.formDescription = {
            isSearch: true,
            formGroups: [
                {
                    advancedSearch: false,
                    class: 'slx-form-row no-wrap',
                    controls: [
                        {
                            control: 'slx-text', name: 'suche', type: 'text', placeholder: 'rech-searchtext', formControlName: 'searchText', textChanged: this.searchTextChanged, class: 'slx-field-xlarge',
                            makeAutocomplete: this.getSearchTextAutocomplete,
                        },
                        { control: 'slx-info-button', filename: 'operator-help', titleTransString: 'home-info-operator-help' },
                        {
                            control: 'slx-select', name: 'SearchLanguage', type: 'dropdown', placeholder: 'rech-language', formControlName: 'searchLanguage',
                            options: this.languageOptions, selected: this.selectedLanguage, selectionChanged: this.langSelectionChanged, class: 'slx-field-medium no-grow',
                        },
                        { control: 'slx-info-button', filename: 'language-help', titleTransString: 'home-info-language-help', class: 'in-form' },
                    ],
                },
                {
                    advancedSearch: false,
                    formArrayName: 'articleOfLawFilterCriterias',
                    formArrayControl: this.searchForm.controls.articleOfLawFilterCriterias,
                    addingArrayItem: this.addArticleOfLawCriterias,
                    removeArrayItem: this.removeArticleOfLawCriterias,
                    class: 'slx-form-row no-wrap law-row',
                    controls: [
                        {
                            name: 'law', type: 'text', placeholder: 'rech-law', formControlName: 'law', class: 'slx-field-medium',
                            makeAutocomplete: this.autocompleteService.getLawAutocomplete,
                            articleOfLawDependencies: this.resetDependencies('law'),
                        },
                        {
                            name: 'articleNumber', type: 'text', placeholder: 'rech-arcticle-short', formControlName: 'articleNumber', class: 'slx-field-small',
                            makeAutocomplete: this.autocompleteService.getArticleNumberAutocomplete, autocompleteMinQueryLength: 0, articleOfLawDependencies: this.resetDependencies('articleNumber'),
                        },
                        { name: 'paragraph', type: 'text', placeholder: 'rech-paragraph-short', formControlName: 'paragraph', class: 'slx-field-small' },
                        { name: 'subParagraph', type: 'text', placeholder: 'rech-subparagraph-short', formControlName: 'subParagraph', class: 'slx-field-small' },
                    ],
                },
                {
                    advancedSearch: true,
                    class: 'slx-form-row',
                    controls: [
                        { control: 'slx-date', name: 'DateFrom', placeholder: 'rech-dateFrom', formControlName: 'dateFrom', class: 'slx-field-small' },
                        { control: 'slx-date', name: 'DateUntil', placeholder: 'rech-dateUntil', formControlName: 'dateUntil', class: 'slx-field-small' },
                    ],
                },
                {
                    advancedSearch: true,
                    class: 'slx-form-row',
                    controls: [
                        {
                            control: 'slx-text', name: 'Reference', type: 'text', placeholder: 'rech-reference', formControlName: 'reference', class: 'slx-field-small',
                            makeAutocomplete: this.autocompleteService.getReferenceAutocomplete,
                        },
                        {
                            control: 'slx-text', name: 'Author', type: 'text', placeholder: 'rech-author', formControlName: 'author', class: 'slx-field-small',
                            makeAutocomplete: this.autocompleteService.getAuthorAutocomplete, autocompleteMinQueryLength: 0,
                        },
                    ],
                },
                {
                    advancedSearch: true,
                    controls: [
                        { control: 'slx-multicheck', name: 'practiceAreaGroupsCriteria', placeholder: 'rech-practice-area-filter', formControlName: 'practiceAreaGroupsCriteria', allChecked: this.searchForm.value.practiceAreaGroupsCriteria.length === 0, click: (function(event){
                            this.searchService.dispatch({ type: SearchActions.verify_practice_areas_for_filter.name });
                        }).bind(this) },
                        { control: 'slx-multicheck', name: 'assetTypeGroupsCriteria', placeholder: 'rech-document-category-filter', formControlName: 'assetTypeGroupsCriteria', allChecked: this.searchForm.value.assetTypeGroupsCriteria.length === 0 },
                        { name: 'publicationFilter', placeholder: 'rech-publication-filter', selectedPublications: this.fieldService.selectedPublications, openDialog: this.openPublicationDialog },

                    ],
                },
                {
                    advancedSearch: true,
                    class: 'thesaurus-searchfilters',
                    controls: [
                        { control: 'slx-thesaurus', name: 'thesaurusType', formControlName: 'thesaurusType', stateChanged: this.thesaursStateChanged },
                        { control: 'slx-searchfilterbar', createAction: this.createSearchFilter, editAction: this.editSearchFilter },
                    ],
                },
            ],
            controls: {
                resetControl: true,
                searchfilter: { options: this.searchService.userSearchFilters, placeholder: 'rech-searchfilter-use', formControlName: 'userSearchFilterId', resetAction: this.resetSearchFilter },
            },
            advancedSearchChanges: this.advancedSearchChanges,
            searchButtonText: 'rech-searching',
        };

        this.searchFormSubscription = new Subscription()
            .add(accountService.onLangChange.subscribe(res => {
                this.selectedLanguage = accountService.langEnum;
                this.searchForm.controls['searchLanguage'].setValue(this.selectedLanguage);
                this.searchService.getPracticeAreaFilters();
            }));
    }

    public getSearchTextAutocomplete() {
        return (suchtext: string): Observable<any> => {
            const paramters = [
                new AutocompleteParamter('searchText', suchtext),
                new AutocompleteParamter('language', LanguageEnum[this.searchForm.value.searchLanguage].toLowerCase()),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.RetrievalSearchText, false, paramters);
        };
    }

    // overrides SearchFormBase.ngOnInit
    ngOnInit() {
        this.searchFormSubscription
            .add(this.searchService.initialValuesForMultiChecks.subscribe(values => this.initialMultiCheckValues = values))
            .add(this.fieldService.thesaurusInformation.subscribe(ti => this.thesaurusInformation = ti))
            .add(this.fieldService.selectedPublications.subscribe(sp => this.selectedPublications = sp))
            .add(this.searchService.store.select(state => state.recherche.selectedSearchFilter).subscribe(state => {
                if (state === null) { // default value => empty object
                    debug('reseting searchfilter');
                    this.searchForm.controls['userSearchFilterId'].reset();
                    this.changeDetectorRef.markForCheck();
                    return;
                }
            }))
            .add(this.searchService.store.select(state => state.recherche.searchForms[SearchType[SearchType.Retrieval]]).subscribe(state => {

                if (Object.keys(state).length === 0) { // default value => empty object
                    this.init();
                    return;
                }

                this.initArticleOfLaw();
                if (state.articleOfLawFilterCriterias) {
                    for (let i = 1; i < state.articleOfLawFilterCriterias.length; i++) {
                        this.addArticleOfLawCriteria();
                    }
                }

                const assetTypeGroupsCount = state.assetTypeGroupsCriteria ? state.assetTypeGroupsCriteria.length : 0;
                const practiceAreaGroupsCount = state.practiceAreaGroupsCriteria ? state.practiceAreaGroupsCriteria.length : 0;

                const newState = {
                    ...state,
                    assetTypeGroupsCriteria: assetTypeGroupsCount === 0 ? this.initialMultiCheckValues.assetTypeGroupsCriteria : state.assetTypeGroupsCriteria,
                    practiceAreaGroupsCriteria: practiceAreaGroupsCount === 0 ? this.initialMultiCheckValues.practiceAreaGroupsCriteria : state.practiceAreaGroupsCriteria,
                };
                this.searchForm.patchValue(newState);
                //console.log('search-component: markForCheck');
                this.changeDetectorRef.markForCheck();
            }))
            .add(
                combineLatest(
                    this.searchForm.valueChanges.pipe(startWith(null)),
                    this.fieldService.selectedPublications.pipe(startWith(null)),
                    this.searchService.store.select(state => state.recherche.searchForms[SearchType[SearchType.Retrieval]]).pipe(startWith(null))
                ).subscribe(([userFormChanges, pubSelectChanges, storeSearchForm]) => {
                    const form = userFormChanges ? userFormChanges : storeSearchForm; // if user has not yet manipulated the form (userFormChanges is null), we have to rely on the searchForm from the store
                    let counter = 0;
                    if (form) {
                        counter += (form.thesaurusType === 1 || form.thesaurusType === undefined ? 0 : 1);
                        counter += (form.author ? 1 : 0);
                        counter += (form.dateFrom ? 1 : 0);
                        counter += (form.dateUntil ? 1 : 0);
                        counter += (form.reference ? 1 : 0);
                        counter += ((form.practiceAreaGroupsCriteria && form.practiceAreaGroupsCriteria.length !== 0 && form.practiceAreaGroupsCriteria.length !== this.initialMultiCheckValues.practiceAreaGroupsCriteria.length) ? 1 : 0);
                        counter += ((form.assetTypeGroupsCriteria && form.assetTypeGroupsCriteria.length !== 0 && form.assetTypeGroupsCriteria.length !== this.initialMultiCheckValues.assetTypeGroupsCriteria.length) ? 1 : 0);
                    }
                    if (pubSelectChanges) {
                        counter += pubSelectChanges.nSelected !== 0 ? 1 : 0;
                    }
                    this.advancedSearchChanges.next(counter);
                })
            );
    }

    // overrides SearchFormBase.ngOnDestroy
    ngOnDestroy(): void {
        super.saveSearchFields(this.getSearchParameters());
        this.searchFormSubscription.unsubscribe();
    }

    // override SearchFormBase.resetDependencies
    resetDependencies(changedControlName) {
        return (function (index) {
            const start = this.controlDependencies.indexOf(changedControlName);
            if (start === -1) {
                return;
            }
            for (let i = start + 1; i < this.controlDependencies.length; i++) {
                this.searchForm.controls['articleOfLawFilterCriterias'].controls[index].controls[this.controlDependencies[i]].reset();
            }
        }).bind(this);
    }

    private initLanguages() {
        this.languageOptions = [];
        for (const language in LanguageEnum) {
            if (!isNaN(Number(language)) && language !== LanguageEnum.undefined.toString() && language !== LanguageEnum.Rm.toString()) {
                const opt = {
                    value: Number(language),
                    viewValue: LanguageEnum[language],
                };
                this.languageOptions.push(opt);
            }
        }

        this.searchForm.controls['searchLanguage'].setValue(this.selectedLanguage);
    }

    public openPublicationDialog(): void {
        this.searchService.dispatch({ fetch: SearchActions.verify_publication_filter_options.name });
        this.dialog.open(PublicationSelectDialogComponent, {
            width: '1400px', height: '800px', panelClass: 'publication-select',
            data: this.selectedPublications,
        });
    }

    private createSearchFilter(): void {
        const position = { x: 0, y: 0, cols: 4, rows: 4 };
        const data = { ...position, search: this.searchForm };
        this.dialog.open(SearchFilterSaveComponent, { width: '500px', data, panelClass: 'searchFilter' });
    }

    private editSearchFilter(): void {
        const position = { x: 0, y: 0, cols: 4, rows: 4 };

        const data = {
            ...position,
            search: this.searchForm,
        };

        this.dialog.open(SearchfiltersComponent, { width: '500px', data, panelClass: 'searchFilter' });
    }

    private resetSearchFilter() {
        debug('resetting searchFilter');
        this.searchService.dispatch({ type: SearchActions.reset_user_search_filter.name });
    }

    public langSelectionChanged(lang) {
        this.loadThesaurusData(this.searchForm.value.thesaurusType, this.searchForm.value.searchText, lang);
    }

    public searchTextChanged(searchText) {
        this.loadThesaurusData(this.searchForm.value.thesaurusType, searchText, this.searchForm.value.searchLanguage);
    }

    public thesaursStateChanged(thesaurusState) {
        this.loadThesaurusData(thesaurusState, this.searchForm.value.searchText, this.searchForm.value.searchLanguage);
    }

    private loadThesaurusData(thesaurusType, searchText, searchLanguage) {
        if (thesaurusType === 2) {
            this.searchService.dispatch({ fetch: ImmerFieldActions.load_thesaurus_information.name, payload: { searchText, searchLang: searchLanguage } });
        }
    }

    private initArticleOfLawCriterias() {
        return this.formBuilder.group({
            law: [''],
            articleNumber: [''],
            paragraph: [''],
            subParagraph: [''],
        });
    }

    private addArticleOfLawCriterias(e) {
        e.preventDefault();
        this.addArticleOfLawCriteria();
    }

    private addArticleOfLawCriteria() {
        const control = <FormArray>this.searchForm.controls['articleOfLawFilterCriterias'];
        const addrCtrl = this.initArticleOfLawCriterias();

        control.push(addrCtrl);
    }

    private removeArticleOfLawCriterias(index: number, e) {
        e.preventDefault();
        const control = <FormArray>this.searchForm.controls['articleOfLawFilterCriterias'];
        control.removeAt(index);
    }

    get articleOfLawFilterCriterias(): FormArray {
        return this.searchForm.get('articleOfLawFilterCriterias') as FormArray;
    }

    getSearchParameters() {
        return {
            ...this.searchForm.value,
            thesaurusInformation: this.searchForm.value.thesaurusType === 2 /* manual */ ? this.thesaurusInformation : null,
            ...this.selectedPublications,
        };
    }
    // overrides SearchFormBase.onSubmit
    onSubmit() {
        super.submitSearch(this.getSearchParameters());
    }

    initArticleOfLaw() {
        const control = <FormArray>this.searchForm.controls['articleOfLawFilterCriterias'];
        if (control) {
            const length = control.controls.length;
            if (length > 1) {
                for (let i = length - 1; i > 0; i--) {
                    control.removeAt(i);
                }
            }
        }
    }

    init() {
        debug('init');
        this.searchForm.reset();
        this.initArticleOfLaw();
        this.initLanguages();
        this.searchForm.controls['thesaurusType'].setValue(1);
    }
}
