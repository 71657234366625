import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';

import { TooltipData } from '../../models';
import { calculatePreviewPosition } from '../../utility/utilityFunctions';

import { FieldService } from './../field.service';

const debug = require('debug')('tooltip');
@Directive({
    selector: '[slx-tooltip]',
})
export class TooltipDirective implements OnDestroy {

    @Input() tooltipWidth = 400;   // Base Width, determines the overall width, should be consistent
    @Input() tooltipExpectedHeight = 200;  // Base Height only for calculation purposes, porbably more?!
    @Input() tooltipDelay = 500; // in ms. that long the user has to hover over the element in order to activate the tooltip

    @Input() tooltipData: Observable<TooltipData>;

    private tooltipDataValue: TooltipData;

    private tooltipIsShowing = false;
    private timer: any;
    private target: HTMLElement;
    private subscription: Subscription;

    constructor(private fieldService: FieldService) {
        this.mouseleave = this.mouseleave.bind(this);
        this.click = this.click.bind(this);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    @HostListener('mouseover', ['$event']) onMouseMove(event): void {
        if (!this.tooltipIsShowing && this.tooltipData) {
            this.target = event.target.closest('[slx-tooltip]');
            this.tooltipIsShowing = true;
            this.handleTooltip(this.target);
        }

    }

    private handleTooltip(slxToolTip: any) {
        const tooltipInformation = {
            ...calculatePreviewPosition(slxToolTip, this.tooltipWidth, this.tooltipExpectedHeight),

        };
        this.timer = setTimeout(() => {
            if (!this.tooltipDataValue) {
                this.subscription = this.tooltipData.subscribe(tooltipData => {
                    this.tooltipDataValue = tooltipData;
                    this.showTooltip(tooltipInformation);
                });
            }
            else {
                this.showTooltip(tooltipInformation);
            }
        }, this.tooltipDelay);
        slxToolTip.addEventListener('mouseleave', this.mouseleave);
        slxToolTip.addEventListener('click', this.click);
    }

    private showTooltip(tooltipInformation) {
        this.fieldService.showTooltip({ ...tooltipInformation, title: this.tooltipDataValue.tooltipTitle, body: this.tooltipDataValue.tooltipBody});
    }


    private clearTooltip(event) {
        clearTimeout(this.timer);
        this.fieldService.hideTooltip();
        this.tooltipIsShowing = false;
        event.target.removeEventListener('mouseleave', this.mouseleave);
        event.target.removeEventListener('click', this.click);
    }

    private mouseleave(event) {
        this.clearTooltip(event);
    }

    private click(event) {
        this.clearTooltip(event);
    }
}
