import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CellClickedEvent, GridApi, GridOptions, RowDataChangedEvent } from 'ag-grid-community';
import { Observable, of, Subscription } from 'rxjs';

import { LoginService } from '../../../access';
import { AccountService } from '../../../account';
import { AppActions } from '../../../appActions';
import { CustomerProfileActions } from '../../../customer-profile/customerProfileActions';
import { GridSwitchComponent } from '../../../field';
import { AppState, CustomerProfileGoBackType } from '../../../models';
import { AlertType } from '../../../models/state/commonApp';
import { gridButtonRenderer } from '../../../utility/utilityFunctions';

import { AddNewUserComponent } from './../addNewUser/addNewUser.component';

@Component({
    selector: 'slx-all-users',
    templateUrl: './allUsers.component.html',
    styleUrls: ['./allUsers.component.scss'],
})
export class AllUsersComponent implements OnInit, OnDestroy {

    private subscription: Subscription = new Subscription();
    private portalUserId: string;
    private allUsersForCompany: any[] = [];
    private showOnlyActiveUsers = true;
    private newUserEmail: string;

    private gridApi: GridApi;

    public gridOptions: GridOptions;
    public columnDefs: any[];
    public shownUsersObservable: Observable<any[]>;
    public inProgress = this.accountService.isActionInProgress(CustomerProfileActions.export_user_excel.name, CustomerProfileActions.activate_or_deactivate_user.name, CustomerProfileActions.send_login_user.name, CustomerProfileActions.users_data.name);

    constructor(private router: Router, public accountService: AccountService, private loginService: LoginService, private translate: TranslateService, private dialog: MatDialog) {
        this.onGridReady = this.onGridReady.bind(this);

        this.gridOptions = <GridOptions>{
            enableColResize: true,
            rowHeight: 50,
            headerHeight: 50,
            rowSelection: 'single',
            suppressRowClickSelection: true,
            suppressContextMenu: true,
            getRowNodeId: (data) => data.id,
            context: { translate: this.translate },
            getRowClass: params => {
                if (params.data.addedUser === true) {
                    return 'row-added-user-animation';
                }
            },
        };

        this.columnDefs = [
            { field: 'name', headerName: 'account-name', sort: 'asc', menuTabs: [], minWidth: 80 },
            { field: 'firstName', headerName: 'account-first-name', menuTabs: [], minWidth: 120 },
            { field: 'loginName', headerName: 'account-userid', menuTabs: [], minWidth: 200 },
            { field: 'isActivated', headerName: 'account-dept-enabled', menuTabs: [], cellRendererFramework: GridSwitchComponent, minWidth: 90, maxWidth: 90 },
            { field: 'edit', headerName: 'account-edit', menuTabs: [], cellRenderer: gridButtonRenderer, minWidth: 120, maxWidth: 120 },
            { field: 'send', headerName: 'account-users-send-login', menuTabs: [], cellRenderer: gridButtonRenderer, minWidth: 170, maxWidth: 170 },
        ];
    }

    ngOnInit() {
        this.accountService.dispatch({ fetch: CustomerProfileActions.users_data.name });

        this.subscription
            .add(this.accountService.store.select(state => state.customerProfile.usersData).subscribe(data => {
                if (data) {
                    this.allUsersForCompany = data;
                    this.shownUsersObservable = this.getUserToShow();
                }
            }))
            .add(this.loginService.userId.subscribe(id => this.portalUserId = id));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private getUserToShow(): Observable<any[]> {
        return of(this.showOnlyActiveUsers ? this.allUsersForCompany.filter(u => u.isActivated) : this.allUsersForCompany);
    }

    public toggleUsersToShow() {
        this.showOnlyActiveUsers = !this.showOnlyActiveUsers;
        this.shownUsersObservable = this.getUserToShow();
    }

    public excelExport() {
        this.accountService.dispatch({ fetch: CustomerProfileActions.export_user_excel.name });
    }

    public onGridReady(params) {
        this.gridApi = params.api;
    }

    public addNewUser() {
        const dialogRef = this.dialog.open(AddNewUserComponent, {
            width: '1000px', height: '600px', panelClass: 'add-new-user',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.newUserEmail = result.email;
                this.accountService.dispatch({ fetch: CustomerProfileActions.users_data.name });
            }
        });
    }

    public rowDataChanged() {
        if (this.newUserEmail) {
            const addedUser = this.allUsersForCompany.find(user => user.email === this.newUserEmail);
            if (addedUser) {
                const addedUserNode = this.gridApi.getRowNode(addedUser.id);
                this.gridApi.ensureIndexVisible(addedUserNode.rowIndex, 'top');
                addedUserNode.setData({ ...addedUserNode.data, addedUser: true });
                this.newUserEmail = undefined;

                setTimeout(() => {
                    addedUserNode.setData({ ...addedUserNode.data, addedDepartment: false });
                }, 6000);
            }
        }
    }

    public onQuickFilterChanged(value): void {
        this.gridApi.setQuickFilter(value);
    }

    public onCellClicked($event: CellClickedEvent) {
        switch ($event.colDef.field) {
            case 'edit':
                this.router.navigate(['customerprofile/users/singleuser', $event.data.id], { queryParams: { goBack: CustomerProfileGoBackType.AllUsers } });
                break;

            case 'isActivated':
                if ($event.data.id === this.portalUserId) {
                    const currentRowNode = $event.api.getRowNode($event.data.id);
                    $event.api.redrawRows({ rowNodes: [currentRowNode] });
                    this.accountService.dispatch({ type: AppActions.alert.name, payload: { type: AlertType.Warning, key: 'account-not-disable', duration: 10 } });
                } else {
                    this.accountService.dispatch({ fetch: CustomerProfileActions.activate_or_deactivate_user.name, payload: $event.data });
                }
                break;

            case 'send':
                this.accountService.dispatch({ fetch: CustomerProfileActions.send_login_user.name, payload: $event.data });
                break;
        }
    }
}
