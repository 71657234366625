
import { range } from 'lodash';

import { AssetCiting, AssetRef, AssetTab } from '../assets';
import { AssetDisplayType, DrilldownType, HitlistType, SourceDetail, TransactionType } from '../enums';

import { SearchHistoryEntry } from './searchHistoryEntry';
import { SearchType } from './searchType';

export interface UserSearchFilterDesc {
    id: number;
    title: string;
}

export interface UserFavorite {
    id?: string;
    targetID?: string;
    targetAolID?: string;
    title?: string;
    targetType: AssetDisplayType;
    editMode?: boolean;
    tooltipBody?: string;
    tooltipTitle?: string;
    position?: number;
    parentId?: string;
    children?: UserFavorite[];
}


type Guid = string;
export interface Search {
    type: SearchType;
    parameters: any;
}

export interface Hitlist {
    id: string | number;
    hits: Array<any>;
    numberOfHits: number;
    numberOfHitsWithPremium: number;
    searchType: SearchType;
    type: HitlistType;
}

export interface NewsHitlist extends Hitlist{
    configuration: any;
}

export interface PublicationFilterContent {
    books: Array<PublicationOption>;
    periodicals: Array<PublicationOption>;
}

export const defaultPublicationFilterContent: PublicationFilterContent = {
    books: [],
    periodicals: [],
};

export interface PublicationOption {
    id: string;
    description: string;
    isSelected: boolean;
    types: Array<string>;
    serial: string;
    practiceAreas: Array<string>;
    location: string;
    isCommentary: boolean;
}

export const searchFormsDefault: any = {
    // these children are intenionally written this way
    // to match SearchType enum string value
    Retrieval: {},
    EuRetrieval: {},
    Journal: {},
    CaseCollection: {},
    NotPublishedCaseCollection: {},
    Commentary: {},
    Book: {},
    Author: {},
    Law: {},
    EuCaseReference: {},
    EuTreaty: {},
    EuInternationalAgreement: {},
    EuLegislation: {},
    EuCelexNumber: {},
};

export const emptyHitlist = { hits: [], numberOfHits: 0, numberOfHitsWithPremium: 0 } as Hitlist;

export type HitlistHits = Array<any>;

export const HitlistSizeOptions = [
    { value: 10, viewValue: '10' },
    { value: 25, viewValue: '25' },
    { value: 50, viewValue: '50'},
    { value: 100, viewValue: '100'},
];

export class HitlistPaging {
    currentPage: number;
    hitsPerPage: number;
    totalPages: number;
    totalNumberOfHits: number;
    totalNumberOfPages: number;
    shownHits: number[];
    startPage: number;
    endPage: number;

    constructor(totalNumberOfHits: number, currentPage: number, hitsPerPage: number = 10) {
        this.totalNumberOfPages = Math.ceil(totalNumberOfHits / hitsPerPage);
        let startPage: number; // Gets freshly calculated every time on setPage(page)
        let endPage: number;

        if (this.totalNumberOfPages <= 5) {
            startPage = 1;
            endPage = this.totalNumberOfPages;
        } else {
            // more than 5 total pages so calculate start and end pages
            if (currentPage <= 3) {
                startPage = 1;
                endPage = 5;
            } else if (currentPage + 2 >= this.totalNumberOfPages) {
                startPage = this.totalNumberOfPages - 4;
                endPage = this.totalNumberOfPages;
            } else {
                startPage = currentPage - 3;
                endPage = currentPage + 1;
            }
        }

        // At the moment, underscore "range" is used
        this.shownHits = range(startPage, endPage + 1);

        this.totalNumberOfHits = totalNumberOfHits;
        this.currentPage = currentPage;
        this.startPage = startPage;
        this.endPage = endPage;
        this.hitsPerPage = hitsPerPage;
    }
}

export class MulticheckItem {
    key: string;
    value: string;
    translate: string;
}

export enum ActiveTocTab {
    None = 'none',
    Primary = 'primary',
    Secondary = 'secondary',
}

export interface AssetTabstrip {
    assetTabs: Array<AssetTab>;
    currentAssetTab: AssetTab;
    inProgress: Array<AssetTab>;
    shareShown: boolean;
    fontsShown: boolean;
    citesShown: boolean;
    pdfShown: boolean;
    addCourtShown: boolean;
    attachmentsShown: boolean;
    commentariesShown: boolean;
    languagesShown: boolean;
    attachmentsLoading: {};
    referringCitationId: string;
    fontSize: number;
    isPrimary?: boolean;
    citationCount: number;
    pdfIsLoading: boolean;
}

export const defaultAssetTabstrip: AssetTabstrip = {
    assetTabs: [],
    currentAssetTab: null,
    inProgress: [],
    shareShown: false,
    fontsShown: false,
    citesShown: false,
    commentariesShown: false,
    pdfShown: false,
    addCourtShown: false,
    attachmentsShown: false,
    languagesShown: false,
    attachmentsLoading: {},
    referringCitationId: null,
    fontSize: 14,
    citationCount: -1,
    pdfIsLoading: false,
};

export type RechTabTypes = 'search' | CasePubTabSubTypes | 'journal' | 'commentary' | 'book' | 'author' | 'law' | EuTabSubTypes;
export type CasePubTabSubTypes = 'casepub' | 'casenonpub';
export type EuTabSubTypes = 'eulaws' | 'eudirect';

export interface SidebarSize{
    sidebarWidth: number;
    facettePercentage: number;
    hitlistPercentage: number;
}

export interface RechercheState {
    transactionType: TransactionType;
    transactionID: number;

    searchInProgress: boolean | any; // null, true (full search), object (paging / drilldown detail)
    searchCollapsed: boolean;
    hitlistOpen: boolean;
    filterOpen: boolean;
    previousFilterOpen: boolean;
    hitlist: Hitlist;
    sidebarSize: SidebarSize;
    verticalHitlist: boolean;
    drillDownFilter: any;
    effectiveDrillDownFilter: any;
    assetTypeFacet: Array<any>;
    languageFacet: Array<any>;
    paging: HitlistPaging;
    hitlistPdfLoading: boolean;
    drilldownfilterLoading: DrilldownType;
    publicationFilterContent: PublicationFilterContent;
    selectedPublications: PublicationCriteria;
    fontSizeInSideBar: number;

    tocItems: any;
    loadedTocItem: any;

    assetInProgress: any[]; //TODO switch to primary/secondary.inProgress
    assetCiting?: AssetCiting;
    error: any;
    citationInformation: any;

    assetSplit: number;

    rechTab: RechTabTypes;
    sidebarTocRef?: AssetRef;

    search: Search;
    searchForms: {
        // these children are intenionally written this way
        // to match SearchType enum string value
        Retrieval: any,
        EuRetrieval: any,
        Journal: any,
        CaseCollection: any,
        NotPublishedCaseCollection: any,
        Commentary: any,
        Book: any,
        Author: any,
        Law: any,
        EuCaseReference: any,
        EuTreaty: any,
        EuInternationalAgreement: any,
        EuLegislation: any,
        EuCelexNumber: any,
    };

    recentTransactions: SearchHistoryEntry[];
    olderTransactions: SearchHistoryEntry[];
    recentEuTransactions: SearchHistoryEntry[];
    olderEuTransactions: SearchHistoryEntry[];

    collectionTab: string;
    previousCollectionTab: string;
    activeTabForToc: ActiveTocTab;
    userSearchFilters: UserSearchFilterDesc[];
    userFavorites: UserFavorite[];
    practiceAreaFilters: MulticheckItem[];
    documentCategoryFilters: MulticheckItem[];
    legalActCategoryFilters: MulticheckItem[];
    advancedSearch: boolean;
    selectedSearchFilter: any;

    primaryTabState: AssetTabstrip;
    secondaryTabState: AssetTabstrip;
    secondaryAssetsBackup: string[]; // guids are stored in this array
}

export interface PublicationCriteria {
    nSelected: number;
    journalCriteria: string[];
    caseCollectionCriteria: string[];
    bookCriteria: string[];
}

export const defaultPublicationCriteria: PublicationCriteria = {
    nSelected: 0,
    journalCriteria: [],
    caseCollectionCriteria: [],
    bookCriteria: [],
};




export const defaultRechercheState: RechercheState = {
    transactionType: TransactionType.Search,
    transactionID: 0,
    searchInProgress: false,
    searchCollapsed: false,
    hitlistOpen: false,
    filterOpen: false,
    previousFilterOpen: null,
    hitlist: <Hitlist>{ numberOfHits: 0, numberOfHitsWithPremium: 0, hits: [] },
    sidebarSize: <SidebarSize>{},
    verticalHitlist: false,
    drillDownFilter: { sortOrder: 0 },
    effectiveDrillDownFilter: {},
    assetTypeFacet: [],
    languageFacet: [],
    paging: <HitlistPaging>{ currentPage: 1, hitsPerPage: 10 },
    hitlistPdfLoading: false,
    drilldownfilterLoading: DrilldownType.undefined,
    publicationFilterContent: null,
    selectedPublications: defaultPublicationCriteria,
    fontSizeInSideBar: 14,

    tocItems: {},
    loadedTocItem: null,

    assetInProgress: [],
    error: null,
    citationInformation: {},

    assetSplit: 0.5,

    rechTab: 'search',

    search: <Search>{
        type: SearchType.Retrieval,
        parameters: {},
    },
    searchForms: {
        ...searchFormsDefault,
    },

    recentTransactions: [],
    olderTransactions: [],
    recentEuTransactions: [],
    olderEuTransactions: [],

    collectionTab: null,
    previousCollectionTab: 'favorites',
    activeTabForToc: ActiveTocTab.None,
    userSearchFilters: [],
    userFavorites: null,
    practiceAreaFilters: null,
    documentCategoryFilters: [],
    legalActCategoryFilters: [],
    advancedSearch: false,
    selectedSearchFilter: null,

    primaryTabState: { ...defaultAssetTabstrip, isPrimary: true },
    secondaryTabState: { ...defaultAssetTabstrip, isPrimary: false },
    secondaryAssetsBackup: [],

};

export function areaForType(searchType): string {
    switch (searchType) {
        case SearchType.Retrieval: return 'search';
        case SearchType.Journal: return 'journal';
        case SearchType.CaseCollection: return 'casepub';
        case SearchType.NotPublishedCaseCollection: return 'casenonpub';
        case SearchType.Commentary: return 'commentary';
        case SearchType.Book: return 'book';
        case SearchType.Author: return 'author';
        case SearchType.Law: return 'law';

        case SearchType.EuRetrieval: return 'eulaws';

        case SearchType.EuCaseReference:
        case SearchType.EuTreaty:
        case SearchType.EuInternationalAgreement:
        case SearchType.EuLegislation:
        case SearchType.EuCelexNumber:
            return 'eudirect';

        default:
            return 'search';
    }
}

export function decorateHitlist(content) {

    const hitlistType = HitlistType[content.type] ? HitlistType[content.type] : content.type;
    const source = determineSourceHitlist(hitlistType);

    return {
        ...content,
        type: hitlistType,
        hits: content.hits.map(hit => ({
            ...decorateHit(hit,source),
            subHits: hit.subHits ? hit.subHits.map(subhit => decorateHit(subhit,source)) : hit.subHits,
        })),
    };

    function decorateHit(hit,source) {
        return {
            ...hit,
            targetType: AssetDisplayType[hit.targetType],
            ref: AssetRef.create(hit.targetType, hit.targetID, { publicationId: hit.publicationID, source}),
        };
    }

    function determineSourceHitlist(hitlistType: HitlistType): string {
        switch(hitlistType) {
            case HitlistType.Search:
                return SourceDetail.SearchHitlist;
            case HitlistType.EuDirectSearch:
                return SourceDetail.EuFindHitlist;
            case HitlistType.EuSearch:
                return SourceDetail.EuSearchHitlist;
            case HitlistType.AuthorSearch:
                return SourceDetail.FindAuthorHitlist;
            case HitlistType.BookSearch:
                return SourceDetail.FindBookHitlist;
            case HitlistType.CaseLawNonPubSearch:
            case HitlistType.CaseLawPubSearch:
                return SourceDetail.FindCaseCollectionHitlist;
            case HitlistType.CommetarySearch:
                return SourceDetail.FindCommentaryHitlist;
            case HitlistType.JournalSearch:
                return SourceDetail.FindJournalHitlist;
            case HitlistType.LawSearch:
                return SourceDetail.FindJournalHitlist;
            case HitlistType.News:
                return SourceDetail.NewsHitlist;
            default:
                return '';
        }
    }
}

export function markHitlistInProgress(hitlist: Hitlist, inProgressList: any[]) {
    return {
        ...hitlist,
        hits: hitlist.hits.map(markHit),
    };

    function markHit(hit) {
        const inProgress = isInProgress(inProgressList, hit.ref);
        if (inProgress !== hit.inProgress) {
            return {
                ...hit,
                inProgress,
            };
        }
        return hit;
    }
}

export function isInProgress(list: AssetRef[], ref: AssetRef): boolean {
    for (let i = 0; i < list.length; ++i) {
        const itemRef = list[i];
        let found = false;
        found = ref[1] === itemRef[1];
        if (found) {
            return true;
        }
    }
    return false;
}


export function isRefInList(list: AssetRef[], ref: AssetRef): boolean {
    for (let i = 0; i < list.length; ++i) {
        const itemRef = list[i];
        if (itemRef[1] === ref[1]) {
            return true;
        }
    }
    return false;
}

// export function stateSetterUpdatesTab(ref, update) {
//     function updatesTab(tab) {
//         if (tab.isLoading && AssetRef.equals(tab.assetRef, ref)) {
//             return {
//                 ...tab, ...update,
//             };
//         }
//         return tab;
//     }

//     return (tabState, otherState) => {
//         return [{
//             ...tabState,
//             assetTabs: tabState.assetTabs.map(updatesTab),
//             inProgress: tabState.inProgress.filter(tab => !AssetRef.equals(tab.assetRef, ref)), //TODO filter out or add in
//         }, {
//             ...otherState,
//             assetTabs: otherState.assetTabs.map(updatesTab),
//             inProgress: otherState.inProgress.filter(tab => !AssetRef.equals(tab.assetRef, ref)), //TODO filter out or add in
//         }];
//     };
// }

// export function removeInProgressFromState(ref) {
//     function switchInProgressOff(tab) {
//         if (tab.isLoading && AssetRef.equals(tab.assetRef, ref)) {
//             return {
//                 ...tab,
//                 isLoading: false,
//             };
//         }
//         return tab;
//     }

//     return (tabState, otherState) => {
//         return [{
//             ...tabState,
//             assetTabs: tabState.assetTabs.map(switchInProgressOff),
//             inProgress: tabState.inProgress.filter(tab => !AssetRef.equals(tab.assetRef, ref)),
//         }, {
//             ...otherState,
//             assetTabs: otherState.assetTabs.map(switchInProgressOff),
//             inProgress: otherState.inProgress.filter(tab => !AssetRef.equals(tab.assetRef, ref)),
//         }];
//     };
// }

// Use this to modify tab states (just one or both) in Actions
export function modifiedTabState(primary: boolean, state: RechercheState, fn: ((state: AssetTabstrip, other: AssetTabstrip) => any[])) {
    const main = primary ? state.primaryTabState : state.secondaryTabState;
    const other = primary ? state.secondaryTabState : state.primaryTabState;

    const [mainModified, otherModified] = fn(main, other);

    const newStates = {
        primaryTabState: primary ? mainModified || main : otherModified || other,
        secondaryTabState: primary ? otherModified || other : mainModified || main,
    };

    if (newStates.primaryTabState.assetTabs.length === 0 && newStates.secondaryTabState.assetTabs.length > 0) {
        const primary = newStates.primaryTabState;
        newStates.primaryTabState = { ...newStates.secondaryTabState, isPrimary: true };
        newStates.secondaryTabState = { ...primary, isPrimary: false };
    }

    return newStates;
}

// export function immerModifyTabState(primary: boolean, draftState: RechercheState, modifyMain: (AssetTabstrip) => void, modifyOther?: (AssetTabstrip) => void) {
//     const main = primary ? draftState.primaryTabState : draftState.secondaryTabState;
//     const other = primary ? draftState.secondaryTabState : draftState.primaryTabState;

//     modifyMain(main);
//     if (modifyOther) {
//         modifyOther(other);
//     }

//     // TODO: Test this!!
//     if (draftState.primaryTabState.assetTabs.length === 0 && draftState.secondaryTabState.assetTabs.length > 0) {
//         draftState.primaryTabState = { ...draftState.secondaryTabState, isPrimary: true};
//         draftState.secondaryTabState = defaultAssetTabstrip;
//     }


// }


export function filterExistingAssetsFn(primaryTabState: AssetTabstrip, secondaryTabState: AssetTabstrip) {
    return (ref: AssetRef) => (isRefInList(primaryTabState.assetTabs.map(at => at.assetRef), ref) || isRefInList(secondaryTabState.assetTabs.map(at => at.assetRef), ref));
}

