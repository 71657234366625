import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnApi, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { Subscription } from 'rxjs';

import * as config from '../../config';
import { defaultPublicationCriteria, PublicationCriteria, PublicationFilterContent } from '../../models';
import { FieldService } from '../field.service';
import { ShortenStringLengthPipe } from '../pipes';

const debug = require('debug')('pupSelect');

export enum PupSelectMode {
    'All' = 'all',
    'Books' = 'books',
    'Serial' = 'serial',
    'Journal' = 'journal',
    'CaseCollection' = 'caseCollection',
    'Commentaries' = 'commentaries',
    'Selection' = 'selection',
}

@Component({
    selector: 'slx-publication-select',
    templateUrl: './publication-select.component.html',
    styleUrls: ['./publication-select.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicationSelectComponent implements OnDestroy {

    private subscription: Subscription;
    public selectedIds = new Set<string>();
    public mode: PupSelectMode = PupSelectMode.All;
    private publicationContent: PublicationFilterContent;
    private numberOfPublications: number;

    private gridColumnApi: ColumnApi;
    private gridApi: GridApi;
    private gridIsReady = false;

    private changeCounter = 0;
    private selectionChangedCounter = 0;

    private groupedPracticeAreas = [];
    private journals = [];
    private caseLaws = [];
    private groupedLocations = [];
    private groupedSerials = [];

    public gridOptions: GridOptions;
    public selectedGrouping = 'all';
    public columnDefs;

    @Input() public userSelection: PublicationCriteria;
    @Output() userSelectionChange = new EventEmitter();
    @Input() public applyFunction;

    constructor(private fieldService: FieldService, private translate: TranslateService) {
        this.selectionChanged = this.selectionChanged.bind(this);
        this.onGridReady = this.onGridReady.bind(this);

        this.subscription = this.fieldService.publicationFilterOptions.subscribe(
            result => {
                if (result) {
                    this.publicationContent = result;
                    this.journals = this.publicationContent.periodicals.filter(p => p.types.find(t => t === 'Journal'));
                    this.caseLaws = this.publicationContent.periodicals.filter(p => p.types.find(t => t === 'CaseLaw'));
                    this.numberOfPublications = this.publicationContent.books.length + this.publicationContent.periodicals.length;
                    if (this.gridIsReady) {
                        this.setRowDataBasedOnMode();
                    }
                }
            }
        );

        this.gridOptions = <GridOptions>{
            headerHeight: 50,
            rowSelection: 'multiple',
            animateRows: true,
            groupUseEntireRow: true,
            groupSelectsFiltered: true,
            enableFilter: true,
            enableColResize: true,
            enableSorting: true,
            suppressRowClickSelection: true,
            allowContextMenuWithControlKey: false,
            suppressContextMenu: true,
            groupSelectsChildren: true,
            groupRowRenderer: 'agGroupCellRenderer',
            groupRowInnerRenderer: this.counter,
            groupRowRendererParams: {
                suppressCount: true,
                checkbox: true,
            },
        };

        this.columnDefs = [
            {
                headerName: '',
                minWidth: 60,
                maxWidth: 60,
                checkboxSelection: true,
                suppressSorting: true,
                suppressMenu: true,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
            },
            {
                headerName: 'rech-pub-type',
                field: 'types',
                cellRenderer: this.iconCellRenderer,
                minWidth: 80,
                maxWidth: 80,
                suppressMenu: true,
            },
            {
                headerName: 'rech-pub-description',
                field: 'description',
                suppressMenu: true,
                cellRenderer: this.noOverflowTextRenderer,
                sort: 'asc',
                minWidth: 400,
            },
            {
                headerName: 'rech-pub-authors',
                field: 'involvedPersons',
                suppressMenu: true,
                cellRenderer: this.noOverflowTextRenderer,
                minWidth: 300,
            },
            {
                headerName: 'rech-pub-edition',
                field: 'edition',
                suppressMenu: true,
                cellRenderer: this.noOverflowTextRenderer,
                minWidth: 110,
                maxWidth: 110,
            },
            {
                headerName: 'rech-pub-yearofpublication',
                field: 'year',
                suppressMenu: true,
                cellRenderer: this.noOverflowTextRenderer,
                minWidth: 100,
                maxWidth: 100,
            },
            {
                headerName: 'rech-pub-publisher',
                field: 'publisher',
                suppressMenu: true,
                cellRenderer: this.noOverflowTextRenderer,
                minWidth: 300,
            },
            {
                headerName: 'rech-pup-series',
                field: 'serial',
                cellRenderer: this.noOverflowTextRenderer,
                enableRowGroup: true,
                suppressMenu: true,
                minWidth: 300,
            },
            {
                headerName: 'rech-pub-practiceAreas',
                field: 'practiceAreas',
                cellRenderer: this.practiceAreaCellRenderer,
                cellStyle: { 'white-space': 'nowrap' },
                enableRowGroup: true,
                minWidth: 300,
                maxWidth: 400,
                suppressMenu: true,
            },
            {
                headerName: 'rech-pub-location',
                field: 'location',
                cellRenderer: this.noOverflowTextRenderer,
                cellStyle: { 'white-space': 'nowrap' },
                suppressMenu: true,
                minWidth: 100,
                maxWidth: 200,
            },
            {
                headerName: 'rech-pub-parentGroup',
                field: 'parentGroup',
                cellRenderer: this.noOverflowTextRenderer,
                minWidth: 100,
                maxWidth: 200,
                hide: true,
                suppressMenu: true,
            },
        ];
    }

    public onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        if (!this.userSelection) {
            this.selectedIds = new Set([]);
        }
        else {
            this.selectedIds = new Set([
                ...this.userSelection.bookCriteria,
                ...this.userSelection.journalCriteria,
                ...this.userSelection.caseCollectionCriteria,
            ]);

            if (this.userSelection.nSelected > 0 || this.selectedIds.size > 0) {
                this.mode = PupSelectMode.Selection;
            }
        }

        this.setRowDataBasedOnMode();
        this.gridIsReady = true;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
    // ------------------------------  Translation | Count / Misc ------------------------------ //

    public setMode(mode) {
        this.mode = mode;
        this.setRowDataBasedOnMode();
    }

    private counter(params) {
        const childCount = params.node.allChildrenCount;
        const selectedEntries = params.node.childrenAfterFilter.filter(child => child.isSelected());

        return `<span style="display: inline-block; width: 450px;">${params.value}</span>
        <span style="margin-left: 2rem">${selectedEntries.length} von ${childCount} ausgewählt</span>`;
    }

    // ------------------------------  Math / Height-Calculations  ------------------------------ //

    public getRowHeight(params): number {
        if (params.data && Array.isArray(params.data.practiceAreas) && params.data.practiceAreas.length > 2) {
            return 50 + (params.data.practiceAreas.length - 2) * 20;
        }
        return 50;
    }

    public getRowClass(params): string {
        if (params.data && params.data.practiceAreas && params.data.practiceAreas.length > 2) {
            return 'multiTitleRow';
        }
        return 'singleTitleRow';
    }

    // ------------------------------  CellRenderers  ------------------------------ //

    private iconCellRenderer(props) {
        if (!props.value) return;
        if (Array.isArray(props.value)) {
            const images = props.value.reduce(
                (a, b) => a.concat(
                    `<img height="24px" width="24px" style="margin-right: 1rem;" src="${config.apiBaseUrl}${config.repositoryLogoHits}${b}.svg" alt="${b}" title="${props.value}">`), ''
            );
            return `<div style="height: 100%; display: flex; align-items: center;">${images}</div>`;
        } else {
            return `<div style="height: 100%; display: flex; align-items: center;"><img height="24px" width="24px" src="${config.apiBaseUrl}${config.repositoryLogoHits}${props.value}.svg" alt="${props.value}" title="${props.value}"></div>`;
        }
    }

    private noOverflowTextRenderer(params): HTMLDivElement {
        if (!params.value) {
            return;
        }
        const paContainer = document.createElement('div');
        paContainer.style.cssText = 'text-overflow: ellipsis;overflow: hidden;';
        const eA = document.createElement('span');
        eA.title = `${params.value}`;
        eA.innerHTML = `${params.value} <br>`;
        eA.id = `${params.node.id}`;
        paContainer.appendChild(eA);
        return paContainer;
    }

    private practiceAreaCellRenderer(params): HTMLDivElement {
        if (!params.value) {
            return;
        }

        if (Array.isArray(params.value) && params.value.length === 1) {
            return params.value[0];
        } else if (typeof params.value === 'string') {
            return params.value;
        } else {
            const paContainer = document.createElement('div');
            paContainer.style.cssText =
                'text-overflow: ellipsis;overflow: hidden;';

            for (let i = 0; i < params.value.length; i++) {
                const eA = document.createElement('span');
                eA.title = `${params.value[i]}`;
                eA.innerHTML = `${params.value[i]} <br>`;
                eA.id = `${params.node.id}_${i}`;
                paContainer.appendChild(eA);
            }
            return paContainer;
        }
    }

    // ------------------------------  Selection  ------------------------------ //

    public changeAll() {
        const selectedNodes = this.selectedNodes;
        if (selectedNodes.length > 0) {
            selectedNodes.forEach(node =>
                this.selectedIds.delete(node.data.id)
            );
            this.gridApi.deselectAll();
        } else {
            this.gridApi.selectAll();
            this.selectedNodes.forEach(node =>
                this.selectedIds.add(node.data.id)
            );
        }
    }

    public get selectedNodes() {
        if (!this.gridApi) {
            return [];
        }
        return this.gridApi.getSelectedNodes();
    }

    public get showSelection() {
        return (
            this.selectedIds.size > 0 &&
            this.selectedIds.size <
            this.publicationContent.periodicals.length +
            this.publicationContent.books.length
        );
    }

    public getPupOfType(type: string) {
        switch (type) {
            case 'Book':
                return this.publicationContent.books;
            case 'Journal':
                return this.journals;
            case 'CaseLaw':
                return this.caseLaws;
        }
    }

    public getSelected(publications) {
        return publications.filter(pup => this.selectedIds.has(pup.id));
    }

    public saveSelectedPublications() {
        if (this.applyFunction) {
            this.applyFunction();
        }
    }

    private createPublicationCriteria(): PublicationCriteria {
        if (
            this.selectedIds.size ===
            this.publicationContent.books.length +
            this.publicationContent.periodicals.length
        ) {
            return defaultPublicationCriteria;
        }

        return {
            nSelected: this.selectedIds.size,
            bookCriteria: this.publicationContent.books
                .filter(b => this.selectedIds.has(b.id))
                .map(b => b.id),
            caseCollectionCriteria: this.publicationContent.periodicals
                .filter(
                    p => this.selectedIds.has(p.id) && p.types[0] === 'CaseLaw'
                )
                .map(c => c.id),
            journalCriteria: this.publicationContent.periodicals
                .filter(
                    p => this.selectedIds.has(p.id) && p.types[0] === 'Journal'
                )
                .map(j => j.id),
        };
    }

    public rowSelected(event) {
        if (!event.data) return;

        const isSelected = this.selectedIds.has(event.data.id);
        if (event.node.selected && !isSelected) {
            this.selectedIds.add(event.data.id);
        } else if (!event.node.selected && isSelected) {
            this.selectedIds.delete(event.data.id);
        }
    }

    public selectionChanged(event): void {
        // Counter counts how often the Selection Changed Event was triggered, since the events get put on a event-stack that starts working after
        // the last element has been put in.
        this.selectionChangedCounter++;
        //     if (this.mode === Mode.Books && (this.changeCounter === this.selectionChangedCounter || this.changeCounter + 1 === this.selectionChangedCounter)) {
        if (this.selectionChangedCounter > this.changeCounter) {
            debug('selectionChanged: new change detected', this.mode);
            switch (this.mode) {
                case PupSelectMode.Books:
                    debug('selectionChanged:in mode books => apply Selection');
                    this.applySelection();
                    break;
                case PupSelectMode.CaseCollection:
                case PupSelectMode.Serial:
                case PupSelectMode.Commentaries:
                    debug('mode with grouping but not books => redrawRows to update the counter');
                    this.gridApi.redrawRows();
                    break;
                default:
                    debug('mode with no grouping => do nothing');
            }
        }
        this.userSelection = this.createPublicationCriteria();
        this.userSelectionChange.emit(this.userSelection);
    }

    private get allAreSelected() {
        return this.selectedIds.size === this.numberOfPublications;
    }

    private adaptSelection(node) {
        const isSelected = this.selectedIds.has(node.data.id);
        if (isSelected !== node.isSelected()) {
            node.setSelected(isSelected);
            this.changeCounter++;
        }
    }

    private applySelection() {
        if (this.allAreSelected) {
            debug(
                'applySelection: all are selected => no need the loop through all nodes'
            );
            this.gridApi.selectAll();
            this.changeCounter = 1; // greater than selectionChangedCounter + 1
        } else {
            debug('applySelection: looping through every element');
            this.changeCounter = 0;
            this.gridApi.forEachNode(node => {
                if (node.allLeafChildren) {
                    node.allLeafChildren.forEach(child =>
                        this.adaptSelection(child)
                    );
                } else {
                    this.adaptSelection(node);
                }
            });
        }
        this.selectionChangedCounter = 0;
        debug('applySelection: selction applied', this.changeCounter);
        this.gridApi.redrawRows(); // Leads to updating selectedChildCount
        debug('applySelection: rows redrawn');
    }

    // ------------------------------  Filtering / Grouping / Search  ------------------------------ //

    private setRowDataBasedOnMode() {
        debug('setRowDataBasedOnMode', this.mode);
        let relevantPublications = [];
        switch (this.mode) {
            case PupSelectMode.Books:
                this.groupColumns('practiceAreas');
                this.gridColumnApi.setColumnVisible('location', false);
                this.gridColumnApi.setColumnVisible('serial', false);
                relevantPublications = this.publicationContent.books;
                break;
            case PupSelectMode.CaseCollection:
                this.groupColumns('location');
                this.gridColumnApi.setColumnVisible('practiceAreas', false);
                this.gridColumnApi.setColumnVisible('serial', false);
                this.gridColumnApi.setColumnVisible('involvedPersons', false);
                this.gridColumnApi.setColumnVisible('edition', false);
                this.gridColumnApi.setColumnVisible('publisher', false);
                this.gridColumnApi.setColumnVisible('year', false);
                relevantPublications = this.groupedLocations;
                break;
            case PupSelectMode.Journal:
                this.selectedGrouping = 'all';
                this.gridColumnApi.resetColumnState();
                this.gridColumnApi.setColumnVisible('practiceAreas', false);
                this.gridColumnApi.setColumnVisible('serial', false);
                this.gridColumnApi.setColumnVisible('involvedPersons', false);
                this.gridColumnApi.setColumnVisible('edition', false);
                this.gridColumnApi.setColumnVisible('publisher', false);
                this.gridColumnApi.setColumnVisible('year', false);
                relevantPublications = this.publicationContent.periodicals.filter(
                    p => p.types.find(t => t === 'Journal')
                );
                this.gridApi.setRowData(relevantPublications);
                break;
            case PupSelectMode.All:
                this.selectedGrouping = 'all';
                this.gridColumnApi.resetColumnState();
                this.gridColumnApi.setColumnVisible('serial', false);
                this.gridColumnApi.setColumnVisible('location', false);
                relevantPublications = [
                    ...this.publicationContent.books,
                    ...this.publicationContent.periodicals,
                ];
                this.gridApi.setRowData(relevantPublications);
                break;
            case PupSelectMode.Serial:
                this.groupColumns('serial');
                this.gridColumnApi.setColumnVisible('location', false);
                this.gridColumnApi.setColumnVisible('practiceAreas', false);
                this.gridColumnApi.setColumnVisible('involvedPersons', false);
                this.gridColumnApi.setColumnVisible('edition', false);
                this.gridColumnApi.setColumnVisible('publisher', false);
                this.gridColumnApi.setColumnVisible('year', false);
                relevantPublications = this.groupedSerials;
                break;
            case PupSelectMode.Commentaries:
                relevantPublications = this.publicationContent.books.filter(b => b.isCommentary)
                    .map(b => b.serial ? b : { ...b, serial: this.translate.instant('rech-pup-no-serial-commentary') });
                this.gridColumnApi.resetColumnState();
                this.gridColumnApi.setColumnVisible('location', false);
                this.gridColumnApi.setColumnVisible('practiceAreas', false);
                this.gridColumnApi.setColumnVisible('serial', false);
                this.gridColumnApi.setRowGroupColumns(['serial']);
                this.gridApi.setRowData(relevantPublications);
                break;
            case PupSelectMode.Selection:
                this.selectedGrouping = 'all';
                this.gridColumnApi.resetColumnState();
                relevantPublications = [
                    ...this.publicationContent.books.filter(b =>
                        this.selectedIds.has(b.id)
                    ),
                    ...this.publicationContent.periodicals.filter(p =>
                        this.selectedIds.has(p.id)
                    ),
                ];
                this.gridApi.setRowData(relevantPublications);
        }

        if (relevantPublications.filter(b => this.selectedIds.has(b.id)).length > relevantPublications.length / 2) {
            this.gridApi.selectAll();
        } else {
            this.gridApi.deselectAll();
        }

        this.applySelection();
        this.changeCounter++; // in the case of changing the tab there is a additional change registered
    }

    public groupColumns(column) {
        this.selectedGrouping = column;
        this.gridColumnApi.resetColumnState();

        switch (column) {
            case 'practiceAreas':
                this.groupPracticeAreas();
                this.gridApi.setRowData(this.groupedPracticeAreas);
                break;
            case 'location':
                this.groupCourts();
                this.gridApi.setRowData(this.groupedLocations);

                break;
            case 'serial':
                this.groupSerials();
                this.gridApi.setRowData(this.groupedSerials);
        }

        this.gridColumnApi.setRowGroupColumns([column]);
        this.gridColumnApi.setColumnVisible(column, false);
    }

    private groupPracticeAreas() {
        this.groupedPracticeAreas =
            this.groupedPracticeAreas.length < 1
                ? this.publicationContent.books.reduce((prev, curr) => {
                    if (curr.practiceAreas) {
                        curr.practiceAreas.forEach(element => {
                            prev.push({
                                ...curr,
                                practiceAreas: element,
                                parentGroup: 'practiceAreas',
                            });
                        });
                    }
                    return prev;
                }, [])
                : this.groupedPracticeAreas;
    }
    private groupCourts() {
        this.groupedLocations =
            this.groupedLocations.length < 1
                ? this.publicationContent.periodicals
                    .filter(elem => elem.location)
                    .map(elem => ({
                        ...elem,
                        parentGroup: 'Kantonale Rechtsprechung',
                    }))
                : this.groupedLocations;
    }

    private groupSerials() {
        const stringShortenPipe = new ShortenStringLengthPipe();
        this.groupedSerials =
            this.groupedSerials.length < 1
                ? this.publicationContent.books
                    .filter(elem => elem.serial)
                    .map(elem => ({
                        ...elem,
                        serial: stringShortenPipe.transform(elem.serial, 65),
                        parentGroup: 'Reihe',
                    }))
                : this.groupedSerials;
    }

    public onQuickFilterChanged(value): void {
        this.gridApi.setQuickFilter(value);
        this.gridApi.redrawRows(); // updating the count
    }

    public rowDataChanged(event): void {
        // This recalculates rowHeight, calls getRowHeight again
        this.gridApi.sizeColumnsToFit();

        if (this.selectedGrouping === 'all' || undefined) {
            return;
        }
        event.api.resetRowHeights();
    }
}
