import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, ResolveStart, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { AppActions } from './appActions';
import { AppState, getLanguageIndependantUrl } from './models';
import { routingDebug } from './utility/utilityFunctions';

@Injectable()
export class RouterExtService {

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  private resolveStartFunctions = new Set();
  private navigationEndFunctions = new Set();

  constructor(public router: Router, private store: Store<AppState>) {
    this.currentUrl = this.router.url;

    router.events.subscribe(event => {
      if (event instanceof ResolveStart) {
        routingDebug('routerext.service.ts', 'ResolveStart', event, this.router.url);
        this.store.dispatch({ type: AppActions.set_main_tab_from_url.name, payload: event.url });

        // Component Functions
        this.resolveStartFunctions.forEach(fn => fn(this.router));
      }

      if (event instanceof NavigationStart) {
        routingDebug('routerext.service.ts', 'NavigationStart', event, this.router.url);
        const { relativeUrlParts, urlLanguage, queryStringObject, fragmentString } = getLanguageIndependantUrl(event.url);
        if (urlLanguage) {
          routingDebug('routerext.service.ts', 'NavigationStart with languagePrefix', { originalUrl: event.url, navigateTo: relativeUrlParts, queryParams: queryStringObject, fragmentString: fragmentString });
          this.router.navigate(relativeUrlParts, { replaceUrl: true, queryParams: queryStringObject, fragment: fragmentString });
        }
      }

      if (event instanceof NavigationEnd) {
        routingDebug('routerext.service.ts', 'NavigationEnd', event, this.router.url);
        // Moved from AppModule
        document.body.style.height = this.router.url === '/' ? 'auto' : '100%';

        // This functionality was existent before my changes
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;

        this.navigationEndFunctions.forEach(fn => fn(this.router, event));
      }
    });
  }

  /**
   * (!) Only use this function in singletons and not in components
   *     For temporary use, a unregister function must be implemented.
   * @param resolveStartFn
   */
  public registerResolveStartFunction(resolveStartFn: (router: Router) => void) {
    this.resolveStartFunctions.add(resolveStartFn);
  }

  /**
   * (!) Only use this function in singletons and not in components
   *     For temporary use, a unregister function must be implemented.
   * @param navigationEndFn
   */
  public registerNavigationEndFunction(navigationEndFn: (router: Router, event: NavigationEnd) => void) {
    this.navigationEndFunctions.add(navigationEndFn);
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }
}
