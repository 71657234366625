import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';

import { SlxHttp } from '../../../access';
import { AccountService } from '../../../account';
import { AppActions } from '../../../appActions';
import { AssetRef, makeAction } from '../../../models';
import { AlertType } from '../../../models/state/commonApp';
import { AssetService } from '../../asset.service';
import { AssetActions } from '../../assetActions';

interface CourtDisplay {
    displayName: string;
    courtID: string;
}

@Component({
    selector: 'add-court',
    templateUrl: 'add-court.component.html',
    styleUrls: ['add-court.component.scss'],
})
export class AddCourtComponent {

    @Input() assetRef: AssetRef;
    @Input() isPrimary: boolean;

    public courtForm: FormGroup;

    private getAllCourtUrl = 'api/Court/GetAllCourts';
    private addCourtUrl = 'api/Court/PostNewCourtForAsset';
    private allCourts: Array<CourtDisplay>;

    constructor(private formBuilder: FormBuilder, private http: SlxHttp, private accountService: AccountService, private assetService: AssetService) {
        this.courtForm = this.formBuilder.group({
            court: [''],
            missingCourtName: [''],
        });

        this.http.get(`${this.getAllCourtUrl}?lang=${this.accountService.lang}`)
            .pipe(first()).subscribe(courts => this.allCourts = courts);

    }

    public addCourt() {
        const court = this.allCourts.find(c => c.displayName === this.courtForm.value.court);
        const missingCourtName = this.courtForm.value.missingCourtName;

        if (!court && !missingCourtName) {
            this.accountService.dispatch(makeAction({ type: AppActions.alert.name, payload: { type: AlertType.Warning, key: 'rech-choose-court', duration: 5 } }));
            return;
        }


        this.http.post(`${this.addCourtUrl}?courtID=${court ? court.courtID : ''}&assetID=${AssetRef.toAssetID(this.assetRef)}&missingCourtName=${missingCourtName}`, null).subscribe(
            res => {
                this.accountService.dispatch(makeAction({ type: AppActions.alert.name, payload: { type: AlertType.Success, key: 'rech-court-added', duration: 5 } }));

                // close court component
                this.accountService.dispatch({ type: AssetActions.toggle_docviewtool.name, payload: { isPrimary: this.isPrimary, toggleValue: false, toggleName: 'addCourtShown' } });
                // relaod asset to see the court meta data
                this.assetService.forceReloadAsset(this.assetRef);
            },
            error => {
                this.accountService.dispatch(makeAction({ type: AppActions.alert.name, payload: { type: AlertType.Error, key: 'rech-court-adding-failed', duration: 5 } }));
            });
    }

    public getAutocomplete(): (string) => Observable<string[]> {
        return (suchtext) => {
            const lowerSuchText = suchtext.toLowerCase();
            const courts = this.allCourts.filter(c => c.displayName.toLowerCase().includes(lowerSuchText)).map(c => c.displayName).sort();
            return of(courts);
        };
    }
}
