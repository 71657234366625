import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { emailValidator, phoneFaxNumberValidator, requiredValidator } from '../../../field';
import { markFormAsTouched } from '../../../utility/utilityFunctions';
import { AboRegistrationService } from '../abo-services.service';
import { AboServicesActions } from '../abo-servicesActions';

@Component({
    selector: 'slx-training',
    templateUrl: './training.component.html',
    styleUrls: ['./training.component.scss'],
})
export class TrainingComponent {
    public form: FormGroup;

    constructor(private fb: FormBuilder, public aboRegistrationService: AboRegistrationService) {
        this.form = this.fb.group({
            dateSuggestion1: ['', requiredValidator()],
            dateSuggestion2: [''],
            dateSuggestion3: [''],
            company: ['', requiredValidator()],
            participants: ['', requiredValidator()],
            location: ['', requiredValidator()],
            contactPerson: ['', requiredValidator()],
            phone: ['', [requiredValidator(), phoneFaxNumberValidator('service-reg-comp-phone-notvalid-val-txt')]],
            email: ['', [requiredValidator(), emailValidator('service-reg-userid-notvalid-val-txt')]],
            training: ['inhouse'],
            beamerComputer: [true],
            language: ['de'],
            message: [''],
            RecaptchaReactive: [null, requiredValidator()],
        });
    }

    public resetForm() {
        this.form.reset();
        Object.keys(this.form.controls).forEach(key => { this.form.controls[key].setErrors(null); this.form.controls[key].updateValueAndValidity(); });
        this.form.controls['training'].setValue('inhouse');
        this.form.controls['beamerComputer'].setValue(true);
        this.form.controls['language'].setValue('de');
    }

    onSubmit(form) {
        if (!form.valid) {
            markFormAsTouched(form);
            return;
        }
        this.aboRegistrationService.dispatch({ save: AboServicesActions.save_training_data.name, payload: this.form.value });
    }

}
