
import { Directive, Input, OnInit } from '@angular/core';

import { MatTooltip, TooltipPosition } from '@angular/material';


@Directive({
    selector: '[slx-mat-tooltip]',
})
export class SlxMatToolTipDirective extends MatTooltip implements OnInit {

    @Input('slx-mat-tooltip') set title(value: string) { this.message = value; }
    @Input('delay') set delay(value: number) { this.showDelay = value; }
    @Input('tooltip-position') set tooltipPosition(value: TooltipPosition) { this.position = value; }

    ngOnInit() {
        if (!this.showDelay) {
            this.showDelay = 500;
        }
}
}
