import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { DisplayableOption } from '../../../../../models/faq/displayableOption';
import { FaqListGroupRendererService } from '../faq-list-grouprenderer/faq-list-grouprenderer.service';

@Component({
    selector: 'slx-faq-list-group',
    templateUrl: './faq-list-group.component.html',
    styleUrls: ['./faq-list-group.component.scss'],
})
export class FaqListGroupComponent implements OnInit {
    @Input() displayOpt: DisplayableOption;
    @ViewChild('inputTopicName') inputTopicName: any;
    public index: number;

    @Output() save = new EventEmitter<{ translateViewValue: string, newValue: string }>();
    @Output() delete = new EventEmitter<{ translateViewValue: string }>();

    constructor(private thisService: FaqListGroupRendererService) { }

    ngOnInit() {
        this.thisService.updateService(this);
    }

    public getViewValue() {
        const translateViewValue = this.displayOpt.option.topic.translateViewValue;
        const matchViewValue = /^(faq-)[-A-Za-z0-9]+/.exec(translateViewValue);
        const viewValue = matchViewValue ? translateViewValue.substring(matchViewValue.index + matchViewValue[1].length) : undefined;
        return viewValue;
    }

    public setEditMode(value: boolean) {
        this.displayOpt.editMode = value;

        this.thisService.updateService(this);
    }

    public onKeyUp(event: any) {
        switch (event.key) {
            case 'Escape':
                this.setEditMode(false);
                break;
            case 'Enter':
                this.onClickSave(event.target.value);
                break;
            default:
                break;
        }
    }

    public onClickEdit() {
        this.setEditMode(!this.displayOpt.editMode);
        if (this.displayOpt.editMode) {
            setTimeout(() => {
                this.inputTopicName.nativeElement.focus();
                this.thisService.updateService(this);
                this.thisService.useEditMode(this.index);
            });
        }
    }

    public onClickSave(newValue: string) {
        this.save.emit({ translateViewValue: this.displayOpt.option.topic.translateViewValue, newValue });
        this.setEditMode(false);

        this.thisService.updateService(this);
    }

    public onClickDelete() {
        this.delete.emit({ translateViewValue: this.displayOpt.option.topic.translateViewValue });

        this.thisService.updateService(this);
    }
}
