import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { AccountService } from '../account/account.service';
import { HomeState } from '../models';

import { AccountActions } from './accountActions';

@Injectable()
export class AccountEffects {

    @Effect() langChanged = this.actions$.pipe(
        ofType(AccountActions.language_changed.name),
        withLatestFrom(this.accountService.store.select('home') as Observable<HomeState>),
        map(([action, state]) => {
            // TODO @Adreas account, about and customer_profile
            switch (state.mainTab) {
                case 'rech':
                    return { type: AccountActions.reload_language_dependent_data_rech.name };
                case 'news':
                    return { type: AccountActions.reload_language_dependent_data_news.name };
                case 'biblio':
                    return { type: AccountActions.reload_language_dependent_data_biblio.name };
                case 'jobs':
                    return { type: AccountActions.reload_language_dependent_data_jobs.name };
                case 'events':
                    return { type: AccountActions.reload_language_dependent_data_events.name };
                case 'abo':
                    return { type: AccountActions.reload_language_dependent_data_abo.name };
                default:
                    return { type: AccountActions.reload_language_dependent_data.name };
            }
        })
    );

    constructor(private actions$: Actions, private accountService: AccountService) { }
}
