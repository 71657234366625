import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SlxHttp } from '../access';
import { AppState, isActionInProgress, Job, makeAction, SlxAction } from '../models';

import { JobsActions } from './jobsActions';

@Injectable()
export class JobsService {

    public jobList: Observable<Job[]> = this.store.select(state => state.jobs.jobList);
    public jobListForGrid: Observable<Job[]> = this.store.select(state => state.jobs.jobList.length > 0 ? state.jobs.jobList : undefined);
    public topJobList: Observable<Job[]> = this.store.select(state => state.jobs.topJobList);
    public currentJob: Observable<Job> = this.store.select(state => state.jobs.currentJob);

    constructor(private slxHttp: SlxHttp, private store: Store<AppState>) { }

    dispatch(action) {
        return this.store.dispatch(makeAction(action as SlxAction));
    }

    isActionInProgress(...types) {
        return this.store.select(state => isActionInProgress(state, ...types));
    }

    loadJobs() {
        this.dispatch({ fetch: JobsActions.load_jobs.name });
    }

    loadTopJobs() {
        this.dispatch({ fetch: JobsActions.load_top_jobs.name });
    }

    loadCurrentJob(id: string) {
        this.dispatch({ fetch: JobsActions.load_current_job.name, payload: id });
    }

    setCurrentJob(job: Job) {
        this.dispatch({ type: JobsActions.set_current_job.name, payload: job });
    }
}
