import { Pipe, PipeTransform } from '@angular/core';

import { ActiveTocTab, Asset, AssetDisplayType, AssetRef, AssetType, DocumentContent } from '../../models';

import { getFollowingAssetRef, getPrecedingAssetRef } from './docview-tools.helperFunctions';


@Pipe({ name: 'getPrecedingAssetRef' })
export class GetPrecedingAssetRefPipe implements PipeTransform {
    transform(docContent: DocumentContent, currentAssetRef: AssetRef): AssetRef {
        return getPrecedingAssetRef(docContent,currentAssetRef);
    }
}

@Pipe({ name: 'getFollowingAssetRef' })
export class GetFollowingAssetRefPipe implements PipeTransform {
    transform(docContent: DocumentContent, currentAssetRef: AssetRef): AssetRef {
        return getFollowingAssetRef(docContent,currentAssetRef);
    }
}

@Pipe({ name: 'canShowToc' })
export class CanShowTocPipe implements PipeTransform {
    transform(asset: Asset): boolean {
        return (asset && asset.content && asset.type !== AssetType.law) ? (asset.content as DocumentContent).navigationInfo.canShowToc : false;
    }
}

@Pipe({ name: 'isActive' })
export class IsActivePipe implements PipeTransform {
    transform(activeTab: ActiveTocTab, isPrimary: boolean): boolean {
        const currentTocTab = isPrimary ? ActiveTocTab.Primary : ActiveTocTab.Secondary;
        return activeTab === currentTocTab;
    }
}

@Pipe({ name: 'showAddCourt' })
export class ShowAddCourtPipe implements PipeTransform {
    transform(asset: Asset): boolean {
        return asset && asset.displayType === AssetDisplayType.CaseLawDocument && !asset.content.metaData.topMetaData.find(md => md.name.includes('Gericht'));
    }
}
