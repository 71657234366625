import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import * as config from '../config';


const debug = require('debug')('access');
@Injectable()
export class SlxHttp {

    constructor(private http: HttpClient, private translate: TranslateService) { }

    private createOptions(contentType: string, accept: string, authenticated: boolean, responseType = null, extra = {}) {
        const headers = {
            'Content-Type': contentType,
            'Accept': accept,
            'Authenticated': authenticated ? 'true' : 'false',
            'Accept-Language': this.getAcceptLanguage(),
            ...extra,
        };

        if (!headers['Content-Type']) {
            delete headers['Content-Type'];
        }

        if (!headers['Accept']) {
            delete headers['Accept'];
        }
        return responseType ? { headers: new HttpHeaders(headers), responseType } : { headers: new HttpHeaders(headers) };
    }

    private getAcceptLanguage() {
        switch (this.translate.currentLang) {
            case 'de': return 'de-CH';
            case 'fr': return 'fr-CH';
        }
        return 'de-CH';
    }

    private creatUrl(relUrl: string): string {
        return relUrl.indexOf('://') >= 0 ? relUrl : `${config.apiBaseUrl}/${relUrl}`;
    }

    public getMarkdown(relUrl: string, authenticated = true) {
        return this.http.get<any>(this.creatUrl(relUrl), this.createOptions(null, null, authenticated,'text'));
    }

    public get(relUrl: string, authenticated = true): Observable<any> {
        return this.http.get<any>(this.creatUrl(relUrl), this.createOptions('application/json', null, authenticated));
    }

    public getPdf(relUrl: string, authenticated = true): Observable<any> {
        return this.http.get<any>(this.creatUrl(relUrl), this.createOptions('application/json', 'application/pdf', authenticated, 'blob'));
    }

    public getExcel(relUrl: string, authenticated = true) {
        return this.http.get<any>(this.creatUrl(relUrl), this.createOptions('application/json', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', authenticated, 'blob'));
    }

    public put(relUrl: string, body: Object, authenticated = true): Observable<any> {
        return this.http.put<any>(this.creatUrl(relUrl), body, this.createOptions('application/json', null, authenticated));
    }

    public postMultipart(relUrl: string, body: Object, authenticated = true): Observable<any> {
        return this.http.post<any>(this.creatUrl(relUrl), body, this.createOptions('application/json', null, authenticated, null, { 'Content-Type': false }));
    }

    public post(relUrl: string, body: Object, authenticated = true): Observable<any> {
        const bodyString = JSON.stringify(body);
        return this.http.post<any>(this.creatUrl(relUrl), bodyString, this.createOptions('application/json', null, authenticated));
    }

    public delete(relUrl: string, authenticated = true): Observable<any> {
        return this.http.delete<any>(this.creatUrl(relUrl), this.createOptions('application/json', null, authenticated));
    }

    public postPdf(relUrl: string, body: Object, authenticated = true): Observable<any> {
        const bodyString = JSON.stringify(body);
        return this.http.post<any>(this.creatUrl(relUrl), bodyString, this.createOptions('application/json', 'application/pdf', authenticated, 'blob'));
    }
}
