module.exports = {
	"account-profile": "Profil",
	"account-clientprofile-successful-message": "Votre mot de passe a été modifié",
	"account-save": "Enregistrer",
	"account-add-user": "Ajouter un nouvel utilisateur",
	"account-add-new-user": "Ajouter un utilisateur",
	"account-add-department": "Ajouter un nouveau département",
	"account-add-new-department": "Ajouter un département",
	"account-questions": "Des questions?",
	"account-hotlinetext": "Notre hotline répond volontiers à vos questions.",
	"account-filter": "Rechercher",
	"account-german": "Allemand",
	"account-french": "Français",
	"account-language": "Langue",
	"account-term": "Civilité",
	"account-title": "Titre",
	"account-first-name": "Prénom",
	"account-name": "Nom",
	"account-street": "Rue / N°",
	"account-post-box": "Case postale",
	"account-zip-code": "Code postal",
	"account-city": "Lieu",
	"account-phone": "Téléphone",
	"account-fax": "Fax",
	"account-homepage": "Site internet",
	"account-email": "E-mail",
	"account-userid": "Nom d'utilisateur",
	"account-pwd": "Mot de passe",
	"account-pwd-change": "Changer mot de passe",
	"account-pwd-old": "Mot de passe actuel",
	"account-pwd-confirm": "Confirmer le mot de passe",
	"account-has-news": "Swisslex News",
	"account-kdnr": "Numéro de client",
	"account-Co": "Etude / Société",
	"account-dept": "Département",
	"account-autorisations": "Aperçu des autorisations d'accès personnelles",
	"account-successful": "L'enregistrement a réussi.",
	"account-error": "Erreur",
	"account-dept-active-users": "Utilisateurs actifs",
	"account-dept-inactive-users": "Utilisateurs inactifs",
	"account-dept-enabled": "Activé",
	"account-undefined-error": "Une erreur inattendue s’est produite! Si des problèmes persistent, veuillez télécharger à nouveau cette page ou vous adresser à notre hotline.",
	"account-unresponsive-error": "FR --- Swisslex ist momentan überlastet! Bitte versuchen Sie es in ein paar Minuten nochmals oder wenden Sie sich an unsere Hotline ----FR.",
	"account-tips-error": "Erreur concernant 'Tip of the day'",
	"account-dept-activate": "Activé",
	"account-dept-credit-limit": "Limite de crédit (CHF)",
	"account-show-inactive-users": "Afficher les utilisateurs inactifs",
	"account-show-inactive-departments": "Afficher les départements inactifs",
	"account-user-edit": "Traiter les données de l'utilisateur",
	"account-edit": "Modifier",
	"account-back-to-overview": "Retour à l’aperçu",
	"account-user-level": "Niveau d'autorisation",
	"account-excel-export": "Export Excel",
	"account-excel-export-successful": "Export Excel réalisé avec succès",
	"account-excel-filename": "Statistique d'utilisation",
	"account-preferences-position": "Positionner la vue dans le document",
	"account-preferences-position-searchTerm": "Sur le premier terme de recherche",
	"account-preferences-position-beginDoc": "Au début du document",
	"account-preferences-parallelDocView": "Affichage parallèle du document",
	"account-preferences-openSearchOnFilter": "Ouvrir «Autres critères de recherche» lors de l'utilisation de filtres de recherche définis",
	"account-preferences-hideSavedFavorite": "Pas de confirmation après la sauvegarde d'un document en tant que favori",
	"account-preferences-hideTipOfTheDay": "Ne pas afficher les conseils d'utilisation",
	"account-preferences-hideWelcome": "Désactiver l’écran de bienvenue",
	"account-preferences-hideIEWarning": "Ne plus afficher l’information concernant les navigateurs obsolètes",
	"account-preferences-docview": "Affichage du document",
	"account-preferences-pdf-download": "Téléchargement PDF",
	"account-preferences-general": "Général",
	"account-preferences-reset-tips": "Réinitialiser les conseils d'utilisation qui ont déjà été vus.",
	"account-preferences-pdf-hitList-try-highlight": "Mettre en évidence les termes de recherche",
	"account-preferences-pdf-hitList-try-highlight-no": "Ne pas mettre en évidence les termes de recherche",
	"account-preferences-pdf-doc-try-highlight": "Mettre en évidence les termes de recherche dans le document",
	"account-preferences-pdf-doc-try-highlight-no": "Ne jamais mettre en évidence les termes de recherche",
	"account-preferences-pdf-doc-try-highlight-yes": "Mettre toujours en évidence les termes de recherche",
	"account-preferences-pdf-doc-try-highlight-manuel": "Décider pour chaque téléchargement",
	"account-preferences-hitlistSize": "Résultats par page",
	"account-stats-suche": "Recherche de transactions",
	"account-stats-show": "Afficher",
	"account-stats-tx": "Transactions",
	"account-stats-dept-id": "ID du département",
	"account-stats-first-order": "Tri (1)",
	"account-stats-second-order": "Tri (2)",
	"account-stats-freetx": "Inclure les transactions sans frais",
	"account-stats-date": "Date",
	"account-stats-year": "Année",
	"account-stats-month": "Mois",
	"account-stats-day": "Jour",
	"account-stats-hour": "Heure",
	"account-stats-time": "Heure",
	"account-stats-tx-type": "Type",
	"account-stats-app": "Application",
	"account-stats-pub": "Publication",
	"account-stats-dept": "Département",
	"account-stats-user": "Utilisateur",
	"account-stats-publ": "Éditeur",
	"account-stats-required": "Le numéro de client, l’ID du département, l’ID de l’utilisateur sont vides. Un de ces champs doit être rempli.",
	"account-stats-price": "Prix",
	"account-stats-show-price": "Afficher les prix catalogue",
	"account-stats-ref-pub": "Référence / Publication",
	"account-stats-tx-total": "Total {{nbTransactions}} transactions",
	"account-stats-filter": "Filtre",
	"account-users-send-login": "Envoyer login",
	"account-dept-comp": "Sans affectation de département",
	"account-own-dept": "Vous ne pouvez pas désactiver le département dans lequel vous vous trouvez.",
	"account-root-dept": "La société ne peut pas être désactivée.",
	"account-not-disable": "Vous ne pouvez pas désactiver votre propre compte.",
	"account-send-all-logins": "Envoyer tous les logins",
	"account-stats-export": "Fichier Excel",
	"account-stats-date-required": "Date du est vide. Ce champ doit être rempli.",
	"account-stats-reset-form": "Supprimer l’entrée",
	"account-stats-no-entries": "Aucune entrée disponible",
	"account-stats-loading": "Contenus en cours de chargement",
	"account-stats-search-hide": "Réduire recherche",
	"account-stats-search-show": "Agrandir recherche",
	"account-add-user-successful": "Le nouvel utilisateur a été ajouté avec succès.",
	"account-customer-your-ip": "Votre IP",
	"account-pw-changed-successful": "Votre mot de passe a été modifié avec succès.",
	"account-pw-changed-confirmation-failed": "Votre mot de passe ne correspond pas à la confirmation.",
	"account-pw-changed-old-pw-nomatch": "Votre mot de passe actuel est incorrect.",
	"account-pw-exception": "Une erreur s’est produite. Veuillez réessayer ultérieurement ou contactez-nous au 0800 587 463.",
	"account-copy-sucess": "Le lien du document a été copié dans un presse-papier.",
	"account-copy-error": "Le lien du document n’a pas pu être copié.",
	"account-grid-loading": "Contenus en cours de chargement",
	"account-grid-nodata": "Aucune entrée disponible",
	"account-grid-statistics-nodata": "Aucune entrée n’est actuellement disponible ; veuillez indiquer la période souhaitée et d’éventuels critères de filtrage.",
	"account-department-back-to-all": "Retour à tous les départements",
	"account-users-back-to-all": "Retour à tous les utilisateurs",
	"account-department-excel-export": "Departement-export",
	"account-not-enrolled-yet": "Pas encore abonné? <br> Testez <strong>gratuitement</strong> le nouveau Swisslex durant 4 semaines.",
	"account-slx-number-one": "Le numéro un en Suisse",
	"account-register-now": "Enregistrer maintenant",
	"account-TokenRevoked-title": "Votre session active a été terminée",
	"account-TokenRevoked-text": "Une nouvelle session vient d'être initiée avec l'identifiant que vous utilisez. Une seule session active par identifiant est possible. En cas de problèmes ou si vous avez des questions, veuillez vous adresser à votre administrateur Swisslex au sein de votre société. Vous pouvez aussi contacter la hotline",
	"account-TokenExpired-title": "RefreshToken arrivé à expiration",
	"account-TokenExpired-text": "Votre temps d’inactivité était trop long. Veuillez à nouveau vous connecter.",
	"account-InvalidIP-title": "IP invalide",
	"account-InvalidIP-text": "Votre session active a été terminée. Votre adresse IP se situe en dehors du domaine prédéfini. Veuillez vérifier la configuration de votre réseau ou adressez-vous à votre service informatique.",
	"account-stats-hide-price": "Masquer les prix catalogue",
	"account-simultaneous-session-title": "Sessions actives simultanées",
	"account-simultaneous-session-text": "Une session est déjà ouverte avec cet identifiant. Il n’est pas possible d’utiliser simultanément le même identifiant (nom d’utilisateur + mot de passe, également appelé «login») pour plusieurs sessions",
	"account-simultaneous-session-link": "Identifiez-vous malgré tout. La session déjà ouverte sera alors fermée.",
	"account-transaction-limit-reached-title": "Limite d'usage",
	"account-transaction-limit-reached-text": "<br>La limite d'usage ({{transactionLimit}} CHF) a été depassé <br>  Veuillez vous adresser directement à votre administrateur Swisslex interne ou à notre Hotline."
};