import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms'; // FormBuilder, Validators
import { TranslateService } from '@ngx-translate/core';

import { AutocompleteService } from '../../../asset';
import { requiredValidator } from '../../../field';
import { AutocompleteParamter, AutocompleteType, SearchType } from '../../../models';
import { SearchService } from '../../search.service';
import { SearchFormBase } from '../search-form-base';


@Component({
    selector: 'slx-law',
    template: `<slx-search-form *ngIf="formDescription && searchForm" [formDescription]="formDescription" [searchForm]="searchForm" (searchSubmitted)="onSubmit(searchForm)" (searchReset)="onReset()"></slx-search-form>`,
})
export class LawComponent extends SearchFormBase {

    constructor(protected searchService: SearchService, protected autocompleteService: AutocompleteService, protected translateService: TranslateService, protected formBuilder: FormBuilder, protected changeDetectorRef: ChangeDetectorRef, protected translate: TranslateService) {
        super(searchService, SearchType.Law, changeDetectorRef, translate);
        // bind autocomplete get url functions (this)
        this.getLawAutocomplete = this.getLawAutocomplete.bind(this);
        this.getLawTitleAutocomplete = this.getLawTitleAutocomplete.bind(this);
        this.getArticleNumberAutocomplete = this.getArticleNumberAutocomplete.bind(this);

        // Create searchForm with Formbuilder
        this.searchForm = this.formBuilder.group({
            law: [''],
            lawTitle: [''],
            articleNumber: [''],
            searchLanguage: [this.translateService.currentLang],
        });

        this.controlDependencies = ['law','lawTitle','articleNumber'];

        this.formDescription = {
            formGroups: [
                {
                    controls: [
                        {
                            control: 'slx-text', name: 'Law', type: 'text', placeholder: 'rech-law', formControlName: 'law',
                            makeAutocomplete: this.getLawAutocomplete, textChanged: this.resetDependencies('law'),
                        },
                        {
                            control: 'slx-text', name: 'LawTitle', type: 'text', placeholder: 'rech-lawTitle', formControlName: 'lawTitle',
                            makeAutocomplete: this.getLawTitleAutocomplete, autocompleteMinQueryLength: 0, textChanged: this.resetDependencies('lawTitle'),
                        },
                        {
                            control: 'slx-text', name: 'ArticleNumber', type: 'text', placeholder: 'rech-article', formControlName: 'articleNumber',
                            makeAutocomplete: this.getArticleNumberAutocomplete, autocompleteMinQueryLength: 0,
                        },
                    ],
                },
            ],
            controls: {
                resetControl: true,
            },
            searchButtonText: 'rech-display',
        };

        this.validationKeys = [
            'law',
            'lawTitle',
        ];
    }

    getLawAutocomplete() {
        return () => {
            const parameters = [
                new AutocompleteParamter('law', this.searchForm.value.law),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.LawLaw, true, parameters);
        };
    }

    getLawTitleAutocomplete() {
        return () => {
            const parameters = [
                new AutocompleteParamter('law', this.searchForm.value.law),
                new AutocompleteParamter('lawTitle', this.searchForm.value.lawTitle),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.LawLawTitle, true, parameters);
        };
    }

    getArticleNumberAutocomplete() {
        return () => {
            const parameters = [
                new AutocompleteParamter('law', this.searchForm.value.law),
                new AutocompleteParamter('lawTitle', this.searchForm.value.lawTitle),
                new AutocompleteParamter('articleNumber', this.searchForm.value.articleNumber),
            ];
            return this.autocompleteService.autoCompleteSearch(AutocompleteType.LawArticleNumber, true, parameters);
        };
    }
}
