import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import marked from 'marked';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';

import { FileUploadComponent } from '../../../../../app/field/file-upload/file-upload.component';
import { deleteFaqEntryUrl, deleteImageUrl, getFaqByIDUrl, getFaqOptionsUrl, postFaqEntryUrl, SlxHttp } from '../../../../access';
import { fileSizeValidator, fileTypeValidator, requiredValidator } from '../../../../field';
import { markFormAsTouched } from '../../../../utility/utilityFunctions';

import { AppState, LanguageEnum } from './../../../../models';
import { Direction } from './../../../../models/faq/direction';
import { Entry } from './../../../../models/faq/entry';
import { Form } from './../../../../models/faq/form';
import { Image } from './../../../../models/faq/image';
import { Option } from './../../../../models/faq/option';
import { PriorityOption } from './../../../../models/faq/priorityOption';
import { RawEntry } from './../../../../models/faq/rawEntry';
import { Start } from './../../../../models/faq/start';
import { TopicOption } from './../../../../models/faq/topicOption';
import { AlertType } from './../../../../models/state/commonApp';
@Component({
    selector: 'slx-faq-entry',
    templateUrl: './faq-entry.component.html',
    styleUrls: ['./faq-entry.component.scss'],
})
export class FaqEntryComponent implements OnInit, OnDestroy {

    @Input() formData: Form;
    @ViewChild('imageUpload') imageUpload: FileUploadComponent;

    public title: string;
    public submit: string;
    public isOriginal: boolean;
    public topicOptions: TopicOption[];
    public priorityOptions: BehaviorSubject<PriorityOption[]> = new BehaviorSubject<PriorityOption[]>(null);
    public imagePositionOptions = [
        { value: 'top', translateViewValue: Direction.top },
        { value: 'left', translateViewValue: Direction.left },
        { value: 'right', translateViewValue: Direction.right },
        { value: 'bottom', translateViewValue: Direction.bottom },
    ];
    public faqPreview: any;
    public faqEntryForm: FormGroup;
    public formReady: boolean;
    public routeID: string;
    public hasGermanImg: boolean;
    public hasFrenchImg: boolean;

    private imageDe: Image;
    private imageFr: Image;
    private initialData: Start;
    private subscription: Subscription = new Subscription();

    constructor(private fb: FormBuilder, private route: ActivatedRoute, private slxHttp: SlxHttp, private store: Store<AppState>, private router: Router) { }

    ngOnInit() {
        this.routeID = this.route.snapshot.paramMap.get('id');
        this.faqEntryForm = this.createFaqEntryForm();

        if (this.routeID) {
            this.title = 'faq-edit';
            this.submit = 'faq-edit-submit';

            forkJoin(
                this.slxHttp.get(`${getFaqOptionsUrl}`, false),
                this.slxHttp.get(`${getFaqByIDUrl}?id=${this.route.snapshot.paramMap.get('id')}`, false)
            ).subscribe((data: [Option[], RawEntry]) => {
                const options = data[0];
                const entry = this.rawToEntry(data[1]);
                const faq = this.setStartForm(entry, options);
                this.initialData = this.setInitialData(faq);

                this.imageDe = this.initialData.imageDe;
                this.imageFr = this.initialData.imageFr;
                this.topicOptions = faq.options.map(option => option.topic);
                this.priorityOptions.next(faq.options.find(faqOption => faqOption.topic.value === faq.form.topicID).priorities);

                this.faqEntryForm.patchValue(faq.form, { emitEvent: false });
                this.faqEntryForm.controls['useImage'].setValue(entry.isImageEnabled);
                this.onFaqEntryValueChanges({ prevFormData: null, nextFormData: faq.form });
                this.formReady = true;
            });
        } else {
            this.title = 'faq-add';
            this.submit = 'faq-add-submit';

            this.slxHttp.get(`${getFaqOptionsUrl}`, false).subscribe((options: Option[]) => {
                this.topicOptions = options.map(option => option.topic);
                const currentPriorities = options[0].priorities;
                const additionalPriority = currentPriorities.length
                    ? { value: currentPriorities
                        ? currentPriorities[currentPriorities.length - 1].value + 1
                        : 1, translateViewValue: `${currentPriorities ? +currentPriorities[currentPriorities.length - 1].translateViewValue + 1 : 1}` }
                    : { value: 1, translateViewValue: `${1}` };
                this.priorityOptions.next([
                    ...currentPriorities,
                    additionalPriority,
                ]);
                this.faqEntryForm.patchValue({ topicID: options[0].topic.value, priority: additionalPriority.value });
                this.initialData = {
                    id: undefined,
                    options,
                    form: this.faqEntryForm.value,
                    imageDe: undefined,
                    imageFr: undefined,
                };
                this.onFaqEntryValueChanges({ prevFormData: null, nextFormData: this.faqEntryForm.value });
                this.formReady = true;
            });
        }

        this.subscription.add(this.faqEntryForm.valueChanges
            .pipe(startWith(this.faqEntryForm.value), pairwise())
            .subscribe(([prevFormValue, nextFormValue]) => {
                this.onFaqEntryValueChanges({ prevFormData: prevFormValue, nextFormData: nextFormValue });
            }));
    }

    //---PUBLIC--------------------------------------------------------------------------------------------------------------------

    public onSubmit() {
        if (!this.faqEntryForm.valid) {
            markFormAsTouched(this.faqEntryForm);
            return;
        }
        const md = marked.setOptions({});
        const { useImage, imageFile, ...postForm } = this.faqEntryForm.value;
        const finalFormData = {
            id: this.initialData.id,
            ...postForm,
            contentDe: md.parse(this.faqEntryForm.value.contentDe),
            contentFr: md.parse(this.faqEntryForm.value.contentFr),
            imageDe: this.imageDe,
            imageFr: this.imageFr,
            isImageEnabled: useImage,
        };

        this.slxHttp.post(`${postFaqEntryUrl}`, finalFormData).subscribe(() => this.router.navigateByUrl('/de/faq/entry/edit'));
    }

    public removeFAQEntry() {
        this.store.dispatch({
            type: 'alert',
            payload: {
                type: AlertType.Warning,
                text: 'faq-remove-text',
                key: 'faq-remove-title',
                actions: [
                    {
                        type: 'no_dispatch',
                        linkKey: 'faq-remove-confirm',
                        execute: () => this.slxHttp.delete(`${deleteFaqEntryUrl}?id=${this.initialData.id}`).subscribe(() => this.router.navigateByUrl('/de/faq/entry/edit')),
                    },
                ],
                noLinkMargin: true,
            },
        });
    }

    public removeImage(language: String){
        switch(language){
            case 'De':
                this.slxHttp.delete(`${deleteImageUrl}?id=${this.initialData.id}&language=De`).subscribe(() => this.router.navigateByUrl('/de/faq/entry/edit'));
                break;
            case 'Fr':
                this.slxHttp.delete(`${deleteImageUrl}?id=${this.initialData.id}&language=Fr`).subscribe(() => this.router.navigateByUrl('/de/faq/entry/edit'));
                break;
            default:
                break;
        }
    }

    public resetForm() {
        this.faqEntryForm.reset(undefined, { emitEvent: false });
        this.imageUpload.onRemoveClick({ emitEvent: false });
        this.faqEntryForm.setValue(this.initialData.form);
    }

    public showOriginalPreview(isOriginal: boolean): void {
        this.isOriginal = isOriginal;
    }

    //---PRIVATE------------------------------------------------------------------------------------------------------------------

    private createFaqEntryForm(): FormGroup{
        return this.fb.group({
            topicID: ['', requiredValidator()],
            priority: [0, requiredValidator()],
            titleDe: ['', requiredValidator()],
            titleFr: ['', requiredValidator()],
            contentDe: ['', requiredValidator()],
            contentFr: ['', requiredValidator()],
            useImage: [true, requiredValidator()],
            imageFileDe: ['', [
                fileSizeValidator('faq-upload-notvalid-size-val-txt', 20000000),
                fileTypeValidator('faq-image-upload-only', 'image/*')]],
            imageFileFr: ['', [
                fileSizeValidator('faq-upload-notvalid-size-val-txt', 20000000),
                fileTypeValidator('faq-image-upload-only', 'image/*')]],
            imagePosition: [this.imagePositionOptions[0].value, requiredValidator()],
        });
    }

    private onFaqEntryValueChanges({ prevFormData, nextFormData }: { prevFormData: Form; nextFormData: Form; }): void {
        if (prevFormData && prevFormData.topicID !== nextFormData.topicID) {
            const found = this.initialData ? this.initialData.options.find(option => option.topic.value === nextFormData.topicID).priorities : undefined;
            if (found && this.initialData.form) {
                if (!this.initialData.id || this.initialData.form.topicID !== nextFormData.topicID) {
                    const currentLastPriorityOption: PriorityOption = found.length ? found[found.length - 1] : { value: 0, translateViewValue: `${0}` };
                    this.priorityOptions.next([...found, { value: currentLastPriorityOption.value + 1, translateViewValue: `${+currentLastPriorityOption.translateViewValue + 1}` }]);
                    this.faqEntryForm.controls.priority.setValue(currentLastPriorityOption.value + 1, { onlySelf: true });
                    return;
                }
                this.priorityOptions.next(found);
                this.faqEntryForm.controls.priority.setValue(this.initialData.form.priority, { onlySelf: true });
            }
        }
        if (prevFormData) {
            if(prevFormData.imageFileDe !== nextFormData.imageFileDe){
                this.onFileChange(LanguageEnum.De);
            }
            else if(prevFormData.imageFileFr !== nextFormData.imageFileFr){
                this.onFileChange(LanguageEnum.Fr);
            }
            else if(prevFormData.useImage !== nextFormData.useImage){
                this.onFileChange(LanguageEnum.De);
            }
        }
        this.updateFaqPreview();
    }

    private onFileChange(lang: LanguageEnum): void {
        if (!this.faqEntryForm.value.imageFileDe &&!this.faqEntryForm.value.imageFileFr && this.initialData) {
            this.imageDe = this.initialData.imageDe;
            this.imageFr = this.initialData.imageFr;
            return;
        }

        const reader = new FileReader();

        switch(lang){
            case LanguageEnum.De:
                reader.readAsDataURL(this.faqEntryForm.value.imageFileDe);
                reader.onloadend = () => {
                    this.imageDe = new Image(undefined, undefined, reader.result.toString());
                    this.updateFaqPreview();
                };
                break;
            case LanguageEnum.Fr:
                reader.readAsDataURL(this.faqEntryForm.value.imageFileFr);
                reader.onloadend = () => {
                    this.imageFr = new Image(undefined, undefined, reader.result.toString());
                    this.updateFaqPreview();
                };
                break;
            default:
                break;
        }
    }

    private rawToEntry(rawEntry: RawEntry): Entry {
        const { id, imageDe, imageFr, isImageEnabled, ...form } = rawEntry;
        const entry: Entry = {
            id,
            form: { ...form, useImage: true, imageFileDe: '', imageFileFr: '' },
            imageDe: (imageDe.source == null ? imageFr : imageDe),
            imageFr: (imageFr.source == null ? imageDe : imageFr),
            isImageEnabled,
        };

        if(imageDe.source == null){
            this.hasGermanImg = false;
        }
        else{
            this.hasGermanImg = true;
        }
        if(imageFr.source == null){
            this.hasFrenchImg = false;
        }
        else{
            this.hasFrenchImg = true;
        }

        return entry;
    }

    private setInitialData(faq: Start){
        return {
            ...faq,
            imageDe: faq.imageDe.source && faq.imageDe.mimeType ? new Image(faq.imageDe.source, faq.imageDe.mimeType) : undefined,
            imageFr: faq.imageFr.source && faq.imageFr.mimeType ? new Image(faq.imageFr.source, faq.imageFr.mimeType) : undefined,
            options: faq.options,
        };
    }

    private setStartForm(entry: Entry, options: Option[]): Start{
        const faq: Start = {
            id: entry.id,
            options,
            form: {
                ...entry.form,
                imagePosition: entry.form.imagePosition ? entry.form.imagePosition : this.imagePositionOptions[0].value,
            },
            imageDe: entry.imageDe,
            imageFr: entry.imageFr,
        };

        return faq;
    }

    private updateFaqPreview(): void {
        this.faqPreview = [
            {
                itemName: !this.hasGermanImg && this.hasFrenchImg
                    ? 'faq-german-copy-img-fr'
                    : 'faq-german',
                content: this.faqEntryForm.value.contentDe,
                id: this.faqEntryForm.value.fkTopic,
                image: this.imageDe ? this.imageDe.getBase64() : undefined,
                imagePosition: this.faqEntryForm.value.imagePosition,
                title: this.faqEntryForm.value.titleDe,
                isImageEnabled: this.faqEntryForm.value.useImage,
            },
            {
                itemName: !this.hasFrenchImg && this.hasGermanImg
                    ? 'faq-french-copy-img-de'
                    : 'faq-french',
                content: this.faqEntryForm.value.contentFr,
                id: this.faqEntryForm.value.fkTopic,
                image: this.imageFr ? this.imageFr.getBase64() : undefined,
                imagePosition: this.faqEntryForm.value.imagePosition,
                title: this.faqEntryForm.value.titleFr,
                isImageEnabled: this.faqEntryForm.value.useImage,
            },
        ];
    }

    //---DESTROY------------------------------------------------------------------------------------------------------------------

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

