import { Component } from '@angular/core/';
import { Router } from '@angular/router';

import { HomeService } from './../home';


@Component({
  selector: 'slx-events',
  templateUrl: './events.component.html',
  host: { 'class': 'sidebar-layout' },
  styles: [`.event-cal{ padding-top: 0;}`],
})
export class EventsComponent {

    constructor(public router: Router, public homeService: HomeService) { }

}
