import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
@Component({
    selector: 'slx-details-select',
    templateUrl: './details-select.component.html',
    styleUrls: ['./details-select.component.scss'],
})
export class DetailsSelectComponent implements OnInit, OnDestroy {

    @Input() type: string;
    @Input() form: FormGroup;

    private checkEmailSubscription: Subscription;

    public languages = [
        { key: 1, value: 'news-german' },
        { key: 2, value: 'news-french' },
        { key: 3, value: 'news-italian' },
        { key: 4, value: 'news-english' },
    ];

    constructor() { }

    ngOnInit(): void {
        this.checkEmailSubscription = this.sendAsEmail.valueChanges.subscribe((value) => {
            this.sendOnlyOnHits.setValue(value ? false : '');
            this.interval.setValue(value && this.interval.value === 100 ? 28 : this.interval.value);
            this.deliveryDay.setValue(value ? 'Monday' : '');
        });
    }

    public get deliveryConfiguration(): FormGroup {
        return this.form.get('deliveryConfiguration') as FormGroup;
    }

    public get docLanguages(): FormControl {
        return this.form.get('docLanguages') as FormControl;
    }

    public get title(): FormControl {
        return this.form.get('title') as FormControl;
    }

    public get sendAsEmail(): FormControl {
        return this.form.get('deliveryConfiguration.sendAsEmail') as FormControl;
    }

    public get interval(): FormControl {
        return this.form.get('deliveryConfiguration.timeWindow') as FormControl;
    }

    public get deliveryDay(): FormControl {
        return this.form.get('deliveryConfiguration.deliveryDay') as FormControl;
    }

    public get sendOnlyOnHits(): FormControl {
        return this.form.get('deliveryConfiguration.sendOnlyOnHits') as FormControl;
    }

    ngOnDestroy(): void {
        this.checkEmailSubscription.unsubscribe();
    }
}
