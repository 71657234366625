import { Location, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatDialog, MatIconModule, MatIconRegistry } from '@angular/material';
import { BrowserModule, DomSanitizer, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, ResolveStart, Router, UrlSegment, UrlSerializer } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { ActionsSubject, Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateService } from '@ngx-translate/core';
import 'hammerjs'; //  needed for angular material
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import * as Raven from 'raven-js';
import { filter } from 'rxjs/operators';

import { environment } from '../environments/environment';

import { AccessModule, getTips, SlxHttp } from './access';
import { AccountModule, AccountService, TranslateModule } from './account';
import { AppComponent, AppLeadComponent, AppTailComponent } from './app.component';
import { initRouting, routing } from './app.routes';
import { AppService } from './app.service';
import { AppActions } from './appActions';
import { AppContext } from './appContext';
import { AppLocationStrategy } from './appLocationStrategy';
import { AssetModule } from './asset';
import { BibliothekModule } from './bibliothek';
import { sentryBaseUrl } from './config';
import { CustomerProfileModule } from './customer-profile';
import { EventsModule } from './events';
import { FieldModule } from './field';
import { HomeModule } from './home';
import { JobsModule } from './jobs';
import { AppState, defaultAppState, getInitialState, SlxAction, StoreBackupType } from './models';
import { NewsModule } from './newsservice';
import { RechercheModule } from './recherche';
import { appMetaReducer, reducers } from './reducers';
import { RouterExtService } from './routerext.service';
import { Link, SeoService } from './seo.service';
import { SlxUrlSerializer } from './slxUrlSerializer';
import { routingDebug } from './utility/utilityFunctions';

if (environment.production) {
    Raven.config(sentryBaseUrl).install();
}
else {
    Raven.config(sentryBaseUrl, { debug: true, allowDuplicates: true });
}


export class RavenErrorHandler implements ErrorHandler {
    handleError(err: any): void {

        if (environment.production) {
            Raven.captureException(err, { tags: { type: 'angular exception' } });
        }
        else {
            // tslint:disable-next-line:no-console
            console.error(err);
        }
    }
}

export function initializeApp(appContext: AppContext) {
    return () => appContext.load();
}

@NgModule({
    declarations: [
        AppComponent,
        AppLeadComponent,
        AppTailComponent,
    ],

    imports: [
        BrowserModule,
        AssetModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot(),
        StoreModule.forRoot(reducers, { initialState: getInitialState, metaReducers: [appMetaReducer] }),
        EffectsModule.forRoot([]),
        HttpClientModule,
        MatIconModule,
        AccessModule,

        /**
         * Store devtools instrument the store retaining past versions of state
         * and recalculating new states. This enables powerful time-travel
         * debugging.
         *
         * To use the debugger, install the Redux Devtools extension for either
         * Chrome or Firefox
         *
         * See: https://github.com/zalmoxisus/redux-devtools-extension
         */
        environment.redux ? StoreDevtoolsModule.instrument({ maxAge: 25 }) : [],

        routing,
        AccountModule,
        CustomerProfileModule,
        HomeModule,
        FieldModule,
        RechercheModule,
        BibliothekModule,
        EventsModule,
        JobsModule,
        NewsModule,
        RecaptchaModule.forRoot(),
    ],

    providers: [
        MatIconRegistry,
        SlxHttp,
        AppLocationStrategy,
        [Location, { provide: LocationStrategy, useExisting: AppLocationStrategy }],
        { provide: UrlSerializer, useClass: SlxUrlSerializer },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: '6LdqbkcUAAAAAGihvZ2qsLzUgiNEtY6jcSbN10bm' } as RecaptchaSettings,
        },
        AppService,
        RouterExtService,
        Link,
        SeoService,
        { provide: ErrorHandler, useClass: RavenErrorHandler },
        AppContext,
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppContext], multi: true},
    ],
    bootstrap: [AppLeadComponent, AppComponent, AppTailComponent],
})
export class AppModule {
    constructor(private store: Store<AppState>, private router: Router, matIconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private location: Location, private actions: ActionsSubject, private appTitle: Title,
                private translate: TranslateService, private accountService: AccountService, public dialog: MatDialog, private routerExtService: RouterExtService, private seoService: SeoService) {
                    // don't remove unused dependencies here (translate, routerExt, seo, and so on), they need to be initialized at app start

        initRouting();

        this.location.subscribe(ev => {
            // debug('Pop State event', ev, history.state);
            if(!history.state){
                return;
            }
            const { type, payload } = history.state;
            if (type) {
                this.store.dispatch({ type, payload });
            }
        });

        this.actions.pipe(filter((action: SlxAction) => !!action.navigate)).subscribe(action => {
            routingDebug('app.module.ts','action navigate', this.router.url);
            this.router.navigate((action as SlxAction).navigate);
        });

        matIconRegistry.addSvgIcon('send_off', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/action-icons-svg/send_off.svg'));
        matIconRegistry.addSvgIcon('email_off', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/action-icons-svg/email_off.svg'));
        matIconRegistry.addSvgIcon('highlight_off', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/action-icons-svg/highlight_off.svg'));
        matIconRegistry.addSvgIcon('slx_hitlist', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/action-icons-svg/slx_hitlist.svg'));
        matIconRegistry.addSvgIcon('slx_more_vert', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/action-icons-svg/slx_more_vert.svg'));
        matIconRegistry.addSvgIcon('slx_toc', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/action-icons-svg/slx_toc.svg'));
        matIconRegistry.addSvgIcon('slx_warning', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/action-icons-svg/warning.svg'));

        this.accountService.addTranslation('common-',
            require('./common.de.yaml'),
            require('./common.fr.yaml')
        );
    }

    private applyMobileScroll() {
        // document.body.style.overflowY = this.router.url.includes('recherche') || this.router.url.includes('doc') || this.router.url.includes('toc') ? 'hidden' : 'auto';

    }
}
