import { Component } from '@angular/core';

import { JobsService } from '../jobs.service';

@Component({
    selector: 'slx-top-jobs',
    templateUrl: './topJobs.component.html',
    styleUrls: ['./topJobs.component.scss'],
})
export class TopJobsComponent {
    constructor(public jobsService: JobsService) { }
}
