
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material';

import { InfoWindowComponent } from '../infoWindow/infoWindow.component';

@Component({
    selector: 'slx-info-button',
    templateUrl: './infoButton.component.html',
    styleUrls: ['./infoButton.component.scss'],
})
export class InfoButtonComponent {
    @Input() filename;
    @Input() foldername = '';
    @Input() titleTransString;
    @Input() icon = 'info_outline';
    @Input() width = '1200px';
    @Input() height = '800px';
    @Input() extraClass;

    constructor(private dialog: MatDialog) {
        // console.log(this.host);
     }

    public openSearchfilterSelect(): void {
        const dialogRef = this.dialog.open(InfoWindowComponent, {
            width: this.width, height: this.height, panelClass: 'info-window-modal', data: {
                filename: this.filename, foldername: this.foldername, titleTransString: this.titleTransString,
            },
        });
    }
}
