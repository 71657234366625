import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CellClickedEvent, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Observable, of, Subscription } from 'rxjs';

import { LoginService } from '../../../access';
import { AccountService } from '../../../account';
import { CustomerProfileActions } from '../../../customer-profile/customerProfileActions';
import { GridSwitchComponent } from '../../../field';
import { AlertType } from '../../../models/state/commonApp';
import { gridButtonRenderer, isEmtpyGuid } from '../../../utility/utilityFunctions';

import { AddNewDepartmentComponent } from './../addNewDepartment/addNewDepartment.component';

@Component({
    selector: 'slx-all-departments',
    templateUrl: './allDepartments.component.html',
    styleUrls: ['./allDepartments.component.scss'],
})
export class AllDepartmentsComponent implements OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();

    public portalUserDepartmentId: string;
    public gridOptions: GridOptions;
    public columnDefs: any[];
    public overlayNoRowsTemplate;
    public addedDepartmentID: string;

    private gridApi: GridApi;

    private showOnlyActiveUsers = true;
    private allDepartmentsForCompany: any[] = [];
    public shownDepartmentsObservable: Observable<any[]>;

    public inProgress = this.accountService.isActionInProgress(CustomerProfileActions.load_departments.name);

    constructor(private router: Router, public accountService: AccountService, private loginService: LoginService, private translate: TranslateService, public dialog: MatDialog) {
        this.onGridReady = this.onGridReady.bind(this);

        this.gridOptions = <GridOptions>{
            enableColResize: true,
            rowHeight: 50,
            headerHeight: 50,
            rowSelection: 'single',
            suppressRowClickSelection: true,
            getRowNodeId: data => data.id,
            context: { translate: this.translate },
            getRowClass: params => {
                if (params.data.addedDepartment === true) {
                    return 'row-added-user-animation';
                }
            },
            onModelUpdated: params => {
                if (params.newData) {
                    this.updateNoDepartmentName();
                }
            },
        };

        this.columnDefs = [
            { field: 'name', headerName: 'account-dept', menuTabs: [], minWidth: 250 },
            { field: 'countActiveUsers', headerName: 'account-dept-active-users', menuTabs: [], minWidth: 150, maxWidth: 150 },
            { field: 'countInActiveUsers', headerName: 'account-dept-inactive-users', menuTabs: [], minWidth: 150, maxWidth: 150 },
            { field: 'isActivated', headerName: 'account-dept-enabled', menuTabs: [], cellRendererFramework: GridSwitchComponent, minWidth: 100, maxWidth: 100 },
            { field: 'edit', headerName: 'account-edit', menuTabs: [], cellRenderer: gridButtonRenderer, minWidth: 140, maxWidth: 140 },
        ];
    }

    ngOnInit() {
        this.accountService.dispatch({ fetch: CustomerProfileActions.load_departments.name });

        this.subscription
            .add(this.loginService.portalUserDepartmentId.subscribe(id => (this.portalUserDepartmentId = id)))
            .add(this.loginService.isCorpAdmin.subscribe(right => {
                if (!right) {
                    this.router.navigate(['/customerprofile/userprofile']);
                }
            }))
            .add(this.accountService.store.select(state => state.customerProfile.departmentsData).subscribe(data => {
                if (data) {
                    this.allDepartmentsForCompany = data;
                    this.shownDepartmentsObservable = this.getUserToShow();
                }
            }));
    }



    private getUserToShow(): Observable<any[]> {
        return of(this.showOnlyActiveUsers ? this.allDepartmentsForCompany.filter(u => u.isActivated) : this.allDepartmentsForCompany);
    }

    public addNewDepartment() {
        if (!this.allDepartmentsForCompany[0]) {
            return;
        }

        const dialogRef = this.dialog.open(AddNewDepartmentComponent, {
            width: '1000px', height: '600px', panelClass: 'add-new-user',
            // Since all departments belong to the same company, we can take just the first one
            data: this.allDepartmentsForCompany[0].company,
        });

        dialogRef.afterClosed().subscribe(addedDepartmentID => {
            if (addedDepartmentID) {
                this.addedDepartmentID = addedDepartmentID;

                const addedDepartmentNode = this.gridApi.getRowNode(addedDepartmentID);
                this.gridApi.ensureIndexVisible(addedDepartmentNode.rowIndex, 'top');
                addedDepartmentNode.setData({ ...addedDepartmentNode.data, addedDepartment: true });

                // Remove after Animation has finsihed (6 seconds);
                setTimeout(() => {
                    addedDepartmentNode.setData({ ...addedDepartmentNode.data, addedDepartment: false });
                }, 6000);
            }
        });
    }

    public toggleUsersToShow() {
        this.showOnlyActiveUsers = !this.showOnlyActiveUsers;
        this.shownDepartmentsObservable = this.getUserToShow();
    }

    public excelExport() {
        const fileName = this.translate.instant('account-department-excel-export');
        const params = {
            fileName: fileName,
            sheetName: fileName,
        };
        this.gridApi.exportDataAsExcel(params);
    }

    public onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
    }

    public onQuickFilterChanged(value): void {
        this.gridApi.setQuickFilter(value);
    }

    public updateNoDepartmentName() {
        if (this.gridApi) {
            const noDepartmentName = this.gridApi.getRowNode('00000000-0000-0000-0000-000000000000');
            if (noDepartmentName) {
                noDepartmentName.setDataValue('name', this.translate.instant('account-dept-comp'));
            }
        }
    }

    public onCellClicked($event: CellClickedEvent) {
        switch ($event.colDef.field) {
            case 'edit':
                this.router.navigate(['customerprofile/departments/singledepartment', $event.data.id]);
                break;

            case 'isActivated':
                if (isEmtpyGuid($event.data.id)) {
                    //root der Firma
                    this.handleCannotDeactivate($event, 'account-root-dept');
                } else if ($event.data.id === this.portalUserDepartmentId) {
                    //eingeloggter PortalUser befindet sich im selben Department
                    this.handleCannotDeactivate($event, 'account-own-dept');
                } else {
                    // this.redrawOnClickedRow($event,true); // fix for: if user has clicked on cell but not exactly on the button the switch is not active
                    this.accountService.dispatch({
                        fetch: CustomerProfileActions.activate_or_deactivate_department.name,
                        payload: $event.data,
                    });
                }
                break;
        }
    }

    private handleCannotDeactivate($event: CellClickedEvent, reasonTranslate: string) {
        // visually is already activated => redraw with old value to dactivate
        this.redrawOnClickedRow($event);
        this.accountService.dispatch({
            type: 'alert',
            payload: {
                type: AlertType.Warning,
                key: reasonTranslate,
                duration: 10,
            },
        });
    }

    private redrawOnClickedRow($event: CellClickedEvent/*, revert = false */) {
        const currentRowNode = $event.api.getRowNode($event.data.id);
        // if (revert) {
        //     currentRowNode.data.isActivated = !currentRowNode.data.isActivated;
        // }
        $event.api.redrawRows({ rowNodes: [currentRowNode] });
    }



    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
