import { Component } from '@angular/core';

import { EuTabSubTypes } from '../../../models';
import { SearchService } from '../../search.service';
import { SearchActions } from '../../searchActions';

@Component({
    selector: 'slx-eu-direct-search',
    templateUrl: './euDirectSearch.component.html',
    styleUrls: ['./euDirectSearch.component.scss'],
})
export class EuDirectSearchComponent {

    subTabs = [
        { translate: 'rech-search', key: 'eulaws' },
        { translate: 'rech-directAccess', key: 'eudirect' },
    ];

    constructor(public searchService: SearchService) { }

    public dispatchSubTab(name: EuTabSubTypes){
        this.searchService.dispatch({ type: SearchActions.set_rech_tab.name, payload: name });
    }
}
