import { Injectable, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';

import {
    docViewCitingBaseUrl, euTocItemsUrl, getAssetRefURL, getCommentariesForLawUrl, postGetCommentariesForLawsUrl, publicationFilterOptionsUrl, showLawViewUrl, SlxHttp
} from '../access';
import { AppActions } from '../appActions';
import {
    ActiveTocTab, AppState, Asset, AssetCiting, AssetDisplayType, AssetRef, AssetRefOptions, AssetTab, AssetType,
    HitlistType, makeAction, RechercheState, SlxAction, UserFavorite
} from '../models';
import { CitationDisplayInfo, LawGuids, TocContent } from '../models/assets';
import { AlertType } from '../models/state/commonApp';
import { FrontendCache } from '../utility/frontendCache';
import { cleanDownloadFileName } from '../utility/utilityFunctions';

import { AccountService } from './../account';
import { AssetActions } from './assetActions';

const debug = require('debug')('asset');
const maxCacheAgeInHours = 24;
@Injectable()
export class AssetService implements OnDestroy {

    static instance: AssetService;

    public actions = AssetActions;
    public state: Observable<RechercheState>;
    private _state: RechercheState;
    private stateSubscription: Subscription;

    public loggedIn: Observable<boolean>;
    public mainTab: Observable<string>;

    public currentDoc: Observable<Asset>;
    public currentToc: Observable<Asset>;
    public assetInProgress: Observable<AssetRef[]>; //TODO switch to primary/secondary.inProgress

    public currentAssetPreview: Observable<any>;
    private notifyPreview: Function;
    private assetpreviewCache: Object = {};
    private assetPreviewSub : Subscription;

    private currentlyOpenAssets: AssetRef[] = [];

    private assetCache: FrontendCache<string, Asset>;

    public primaryAssetTabLength = this.store.select(state => state.recherche.primaryTabState.assetTabs.filter(tab => !tab.isLoading).length);
    public currentPrimaryAssetTab = this.store.select(state => state.recherche.primaryTabState.currentAssetTab).pipe(filter(tab => !tab || !AssetRef.isToc(tab.assetRef)));

    public activeAssetTabForToc: Observable<AssetTab> = this.store.select(state => {
        switch (state.recherche.activeTabForToc) {
            case ActiveTocTab.None:
                return null;
            case ActiveTocTab.Primary:
                return state.recherche.primaryTabState.currentAssetTab;
            case ActiveTocTab.Secondary:
                return state.recherche.secondaryTabState.currentAssetTab;
        }
    });

    public activeAssetTab = this.store.select(state => state.recherche.activeTabForToc);
    public collectionTab = this.store.select((state) => state.recherche.collectionTab);
    public hasNoSecondaryAssets = this.store.select(state => state.recherche.secondaryTabState.assetTabs.length === 0);
    public hasNoAssets = this.store.select(state => state.recherche.primaryTabState.assetTabs.length === 0);

    public textSelection: Observable<any>;
    private static textSelectionSubject = new Subject<any>();



    constructor(
        private slxHttp: SlxHttp,
        public store: Store<AppState>,
        private translateService: TranslateService,
        private accountService: AccountService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute
    ) {
        if (AssetService.instance) { return AssetService.instance; }
        AssetService.instance = this;

        this.textSelection = AssetService.textSelectionSubject.asObservable();

        this.loadAsset = this.loadAsset.bind(this);
        this.findFirstNotOpenUrl = this.findFirstNotOpenUrl.bind(this);

        this.assetCache = new FrontendCache<string, Asset>(maxCacheAgeInHours, this.loadAsset, this.urlNormalizer, this.findFirstNotOpenUrl);

        this.state = store.select('recherche') as Observable<RechercheState>;
        this.stateSubscription = this.state.subscribe((state) => this._state = state);
        this.stateSubscription.add(store.select(state => {
            const assetRefs = state.recherche.primaryTabState.assetTabs.concat(state.recherche.secondaryTabState.assetTabs).map(a => a.assetRef);
            return assetRefs;
        }).pipe(filter(openAssets => openAssets.length !== this.currentlyOpenAssets.length)).subscribe(openAssets => {
            if (openAssets.length < this.currentlyOpenAssets.length) {
                this.currentlyOpenAssets.forEach(ref => {
                    if (!openAssets.includes(ref)) {
                        this.assetCache.deleteFromCache(this.prepareAssetURL(ref, this.accountService.lang, this._state));
                    }
                });
            }
            this.currentlyOpenAssets = openAssets;
        }));
        this.loggedIn = store.select(state => state.access.loggedIn);
        this.mainTab = store.select(state => state.home.mainTab);
        this.assetInProgress = this.store.select((state) => state.recherche.assetInProgress);

        this.currentAssetPreview = Observable.create((observe) => {
            this.notifyPreview = (info) => observe.next(info);
        });
    }

    ngOnDestroy(): void {
        this.stateSubscription.unsubscribe();
    }

    private urlNormalizer(url: string): string {
        const sourceIndex = url.indexOf('&source');
        return sourceIndex > 0 ? url.substring(0, sourceIndex) : url;
    }

    private findFirstNotOpenUrl(urlItertor: Iterator<string>): string {
        const openedAssets = this.currentlyOpenAssets;
        if (this._state.sidebarTocRef && this._state.collectionTab === 'toc') {
            openedAssets.push(this._state.sidebarTocRef);
        }
        const openedAssetUrls = openedAssets.map(ref => this.urlNormalizer(this.prepareAssetURL(ref, this.accountService.lang, this._state)));

        let index = 0;
        let current = urlItertor.next();
        const firstEntry = current.value;
        while (!current.done) {
            if (openedAssetUrls.length === index || !openedAssetUrls.includes(this.urlNormalizer(current.value))) {
                return current.value;
            }
            current = urlItertor.next();
            index++;
        }

        return firstEntry;
    }

    private loadAsset(url: string): Observable<Asset> {
        debug('loading asset from cache', url);
        return this.slxHttp.get(url).pipe(map(asset => {
            asset.displayType = AssetDisplayType[asset.displayType];
            if (asset.content.languageInfos) {
                asset.content.languageInfos.filter(li => li.exists);
            }
            if (asset.content && asset.content.assetContentAsHtml) {
                asset.content.assetContentAsHtml = this.sanitizer.bypassSecurityTrustHtml(asset.content.assetContentAsHtml as string);
            }
            return asset;
        }), catchError(error => throwError(error)));
    }

    public getCitationInformationSelector(assetID: string): Observable<Array<CitationDisplayInfo>> {
        return this.store.select(state => state.recherche.citationInformation[assetID]).pipe(take(1));
    }

    public getAsset(assetRef: AssetRef): Observable<Asset> {

        let url = this.prepareAssetURL(assetRef, this.accountService.lang, this._state);

        if (AssetRef.isToc(assetRef) && assetRef[0] !== AssetDisplayType.UnknownPublication) {
            const unknownTocRef = [AssetDisplayType.UnknownPublication, ...assetRef.slice(1)];
            const unknownTocUrl = this.prepareAssetURL(unknownTocRef, this.accountService.lang, this._state);
            if (this.assetCache.getFromCache(unknownTocUrl)) {
                url = unknownTocUrl;
            }
        }

        //   debug('requesting asset from cache', url);

        const assetObservable = this.assetCache.getContent(url);
        return assetObservable.pipe(map(asset => { return this.decorateAsset(assetRef, asset); }));
    }

    public getCachedAsset(assetRef: AssetRef): Asset {
        const url = this.prepareAssetURL(assetRef, this.accountService.lang, this._state);
        const asset = this.assetCache.getFromCache(url);
        return asset ? this.decorateAsset(assetRef, asset) : null;
    }

    public getOtherAssetObservable(isPrimary: boolean): Observable<AssetTab> {
        return isPrimary
            ? this.store.select(state => state.recherche.secondaryTabState.currentAssetTab ? state.recherche.secondaryTabState.currentAssetTab : null)
            : this.store.select(state => state.recherche.primaryTabState.currentAssetTab ? state.recherche.primaryTabState.currentAssetTab : null);
    }

    public forceReloadAsset(assetRef: AssetRef) {
        const url = this.prepareAssetURL(assetRef, this.accountService.lang, this._state);
        this.assetCache.forceReload(url);
    }

    private decorateAsset(ref: AssetRef, asset: Asset) {
        switch (asset.displayType) {
            case AssetDisplayType.Book:
            case AssetDisplayType.PeriodicalPublication:
                (asset.content as TocContent).idToOpen = ref.length >= 4 ? ref[3] : null;
                break;
            case AssetDisplayType.Previews:
                asset.id = AssetRef.toAssetID(ref);
                break;
        }
        ref[0] = asset.displayType;
        asset.ref = ref;
        return asset;
    }

    updateRecentlyViewed(hitlist) {

        const viewTransactions = [...this._state.recentTransactions, ...this._state.olderTransactions].filter(t => t.assetID);
        hitlist.hits.forEach(h => {

            if (viewTransactions.find(vt => vt.assetID === h.targetID)) {
                h.recentlyViewed = true;
            }
        });
    }

    cancelAssetPreview() {
        this.notifyPreview(null);
        if(this.assetPreviewSub) {
            this.assetPreviewSub.unsubscribe();
            this.assetPreviewSub = null;
        }
    }


    previewAsset(assetPreview: any) {
        if (assetPreview === null) { this.notifyPreview(null); return; }

        if (assetPreview.footNoteContent) {
            // FootNoteCase (no BackendCall, no Cache), just show footnoteInfo from assetPreviewObject
            this.notifyPreview({ ...assetPreview, linkPreviewContent: null });
            return;
        }

        const linkPreviewContent = this.assetpreviewCache[assetPreview.rel];

        if (linkPreviewContent) {
            this.notifyPreview({ ...assetPreview, linkPreviewContent });
        } else {
            this.assetPreviewSub = this.slxHttp.get(assetPreview.rel).subscribe(
                (result) => {
                    const content = result.content.previews;
                    this.assetpreviewCache[assetPreview.rel] = content;
                    this.notifyPreview({
                        ...assetPreview,
                        linkPreviewContent: content,
                    });
                    this.assetPreviewSub.unsubscribe();
                    this.assetPreviewSub = null;
                }
            );
        }
    }

    getTabState(primary: boolean, state: RechercheState) {
        return primary ? state.primaryTabState : state.secondaryTabState;
    }

    countCitations(citations: AssetCiting) {
        if (!citations || !citations.citationGroups || citations.citationGroups.length === 0) {
            return 0;
        }

        let count = 0;
        citations.citationGroups.forEach(citationGroup => {
            count += citationGroup.totalCount;
        });

        return count;
    }

    getCurrentTabIndex(assetTabs: Array<AssetTab>, currentAssetTab: AssetTab) {
        return assetTabs.findIndex(tab => (currentAssetTab && tab.assetID && currentAssetTab.assetID && tab.assetID === currentAssetTab.assetID));
    }

    dispatch(action) {
        return this.store.dispatch(makeAction(action as SlxAction));
    }

    // assetsToOpenPalyoads is array of tuples {boolean,assetRef}. The boolean is for knowning where to open.
    openAssets(assetsToOpen) {

        const secondary = assetsToOpen.filter(payload => !payload.openInPrimary).map(payload => payload.ref);
        const primary = assetsToOpen.filter(payload => payload.openInPrimary).map(payload => payload.ref);
        this.dispatch({ type: AppActions.present_results.name, payload: { primary, secondary } });
    }

    openAsset(ref: AssetRef, openInPrimary: boolean, event: MouseEvent): void {
        if (!event || !event.ctrlKey) {
            this.dispatch(this.createOpenAssetAction(ref, event && event.altKey ? !openInPrimary : openInPrimary));
            // this.accountService.showTipIfNecessary('/doc');
        }
    }

    createOpenAssetAction(ref, openInPrimary) {
        const primary = openInPrimary ? [ref] : [];
        const secondary = openInPrimary ? [] : [ref];
        return { type: AppActions.present_results.name, payload: { primary, secondary } };
    }

    copyToClipboard(stringToCopy: string) {
        const area = document.createElement('textarea');
        //CyN: fixes bug in IE11 where whole app component moves up when textarea's added
        area.setAttribute('style', 'position: absolute; top: -1px; left: -1px; height: 1px; width: 1px; font-size: 0;');
        area.value = stringToCopy;
        document.body.appendChild(area);
        area.focus();
        area.select();

        const result = document.execCommand('copy');

        if (result) {
            this.dispatch({ type: AppActions.alert.name, payload: { type: AlertType.Success, key: 'account-copy-sucess', duration: 5 } });
        } else {
            this.dispatch({ type: AppActions.alert.name, payload: { type: AlertType.Error, key: 'account-copy-error', duration: 10 } });
        }

        document.body.removeChild(area);
    }


    prepareAssetURL(ref: AssetRef, language: string, state: RechercheState) {
        let url = getAssetRefURL(ref, language);
        if (state.hitlist && (state.hitlist.type === HitlistType.Search || state.hitlist.type === HitlistType.EuSearch) && state.hitlist.hits.find(hit => AssetRef.toAssetID(hit.ref) === AssetRef.toAssetID(ref))) {
            url += `&transactionId=${state.transactionID}`;
        }

        const ignoreAssetState = this.route.snapshot.queryParams['ignoreAssetState'];
        if (ignoreAssetState) {
            url += `&ignoreAssetState=${ignoreAssetState}`;
        }

        const refLength = ref.length;
        if (ref[refLength - 2] === AssetRefOptions.sourceDetail) {
            url += `&source=${ref[refLength - 1]}`;
        }

        return url;
    }

    preparePdfURL(doc, isPrimary, state: RechercheState, tryHighlight: boolean) {
        const { id, type, content } = doc;
        const { assetLanguage, metaData } = content;

        const baseURL = (type === AssetType.euDocument) ? 'api/pdf/getPdfEuDoc' : 'api/pdf/getPdfDoc';
        const source = null;
        // const tryHighlight = false;
        let titlePdf = doc.displayType === AssetDisplayType.CaseLawDocument ? doc.content.reference + '_' : '';
        titlePdf += (doc.content.docTitle ? this.shortenDocTitle(doc.content.docTitle) : 'Swisslex_' + metaData.topMetaData[0].value) + '.pdf';
        titlePdf = cleanDownloadFileName(titlePdf);
        let url = `${baseURL}?id=${id}&source=${source}&tryHighlight=${tryHighlight}&language=${assetLanguage}`;
        url += `&transactionId=${state.transactionID ? state.transactionID : 0}`;
        return { url, titlePdf, isPrimary, ref: [type, id] };
    }

    shortenDocTitle(title) {
        if (title.length <= 40) return title;
        return title.substr(0, title.lastIndexOf(' ', 40));
    }

    prepareAttachmentURL(fileID, description, isPrimary, isEuAttachment, url) {
        if (!url) {
            url = isEuAttachment ? `api/Content/GetEuAttachment?attachmentGuid=${fileID}` : `api/Content/GetAttachment?attachmentGuid=${fileID}`;
        }
        description = cleanDownloadFileName(description);
        return { url, fileID, description, isPrimary };
    }

    getPublicationFilterOptionsUrl() {
        return `${publicationFilterOptionsUrl}${this.translateService.currentLang}`;
    }

    getLawUrl(lawGuid?: string, aolGuid?: string) {
        return `${showLawViewUrl}${lawGuid}&aolGuid=${aolGuid}&language=${this.translateService.currentLang}`;
    }

    //TODO declarative implementation
    getEuTocItems() {
        const url = `${euTocItemsUrl}${this.translateService.currentLang}`;
        return this.slxHttp.get(url, false)
            .pipe(
                map((res: any) => res),
                catchError(e => this.handleError(e))
            );
    }

    prepareCitingURL(assetId: string): string {
        return `${docViewCitingBaseUrl}${assetId}&language=${this.translateService.currentLang}`;
    }

    getLawGuidsFromAssetRef(ref: AssetRef): LawGuids[] {
        const lawId = ref[0] === AssetDisplayType.LawDocument ? ref[1] : ref[3];
        const aolId = ref[0] === AssetDisplayType.ArticleOfLawDocument ? ref[1] : null;
        return [{ lawId, aolId }];
    }

    prepareCommentariesForCommentariesUrl(ref: AssetRef): string {
        return `${postGetCommentariesForLawsUrl}${AssetRef.toAssetID(ref)}&language=${this.translateService.currentLang}`;
    }

    getFavoriteId(targetID: string, userFavs: UserFavorite[]) {
        return userFavs.find(f => f.targetID === targetID).id;
    }

    //TODO log error
    private handleError(error: any) {
        this.store.dispatch({ type: AssetActions.asset_error.name, payload: error });
        if (error.status === 401) {
            debug('ERROR 401: Tried to Contact Database for Searchrequest, but was not authorized');
        }

        return throwError(error);
    }

    public showTextSelectionMenu(selectionInformation) {
        AssetService.textSelectionSubject.next(selectionInformation);
    }

    public static hideTextSelectionMenu() {
        this.textSelectionSubject.next(null);
    }
}

export function selectionMenuListener(event) {
    const selectionMenu = document.querySelector('slx-text-selection');
    if (selectionMenu.contains(event.target)) {
        return;
    }
    AssetService.hideTextSelectionMenu();
    document.removeEventListener('click', selectionMenuListener);
}

