import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable ,  of } from 'rxjs';

import { getValidationMessage } from '../validators';

const debug = require('debug')('field-select');

@Component({
    selector: 'slx-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        }],
})
export class SelectComponent implements ControlValueAccessor, OnInit {

    private _options: Observable<Array<Object>>;
    private _optionGroups: Observable<Array<Object>>;

    public formControl: FormControl;
    public showValidationResults = false;

    @Input() type: string; // dropdown, templated or inline
    @Input() name: string;
    @Input() radio: boolean;
    @Input() multi = false; // multiple selection of values. don't use with radio.
    @Input() disabled: boolean;
    @Input('placeholder') _placeholder: string;
    @Input() color: string;
    @Input() selected: string;
    @Input() keyName = 'value';
    @Input() labelName = 'viewValue';
    @Input() enabledName = 'enabled';
    @Input() translateName = 'translateViewValue';
    @Input() fieldPrefix: any;
    @Input() isRequired = false;
    @Input() isResettable = false;

    @Input('options') set options(val: any) { this._options = val instanceof Observable ? val : of(val); }
    get options() { return this._options; }

    @Input('optionGroups') set optionGroups(val: any) { this._optionGroups = val instanceof Observable ? val : of(val); }
    get optionGroups() { return this._optionGroups; }

    @Output() change = new EventEmitter<string>();
    @Output() reset = new EventEmitter<string>();

    public getValidationMessage: (formControl: any, translate: any) => string = getValidationMessage;

    // Since this Component can be used withour FormControl it´s neccesary to check for the FormControl in the first place
    public get showError() {
        return (this.formControl && this.formControl.invalid) && (this.showValidationResults || this.formControl.touched);
    }

    get placeholder(): string {
        if (this._placeholder) {
            return this.isRequired ? `${this.translate.instant(this._placeholder)} *` : this.translate.instant(this._placeholder);
        } else {
            return null;
        }
    }

    constructor(public translate: TranslateService) { }

    ngOnInit() { this.propagateChange(this.selected); }

    public observableOptions(options) {
        return options instanceof Observable ? options : of(options);
    }

    public validate(c: FormControl) {
        this.formControl = c;
        if (c.value === null) { this.showValidationResults = false; }
        return {};
    }


    private propagateChange = (_: any) => { };

    valueChanged(event) {
        this.showValidationResults = event.value !== '' || event.value !== null ? true : false;
        this.propagateChange(this.selected);
        this.change.emit(this.selected);
    }

    writeValue(str: any): void {
        this.selected = str || '';
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void { }

    setDisabledState(isDisabled: boolean): void { }

    private getValue(option: object) {
        return option[this.keyName];
    }
    private getViewValue(option: object) {
        if (this.translateName) {
            const translate = option[this.translateName];
            if (translate) {
                return this.translate.stream(translate);
            }
        }
        return of(option[this.labelName]);
    }

    private isDisabled(option: object): boolean {
        return !option[this.enabledName];
    }

    private getOptionGroupTranslation(name: string): string {
        return this.translate.instant(name);
    }

    public resetClicked(event: MouseEvent) {
        this.formControl.reset();
        event.stopPropagation();
        this.reset.emit();
        return false;
    }

}
