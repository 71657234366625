import { Component, Inject, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatStepper } from '@angular/material';

import { AppActions } from '../../appActions';
import { FieldService } from '../field.service';

@Pipe({ name: 'TDHasPrecedingWelcome' })
export class TDHasPrecedingWelcomePipe implements PipeTransform {
    transform(tip): boolean {
        //the welcomePage with priority -1 is the introduction
        return tip.step > 0;
    }
}

@Pipe({ name: 'TDHasFollowingWelcome' })
export class TDHasFollowingWelcomePipe implements PipeTransform {
    transform(tip): boolean {
        //the welcomePage with priority 100 is the conclusion
        return tip.priority < 100;
    }
}

@Component({
    selector: 'welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit, OnDestroy {

    @ViewChild(MatStepper) set aStepper(stepper: MatStepper) { setTimeout(() => { this.activeStepper = stepper; }, 0); }

    public form: FormGroup;
    public activeStepper: any;
    public currentWelcome: any;
    public currentStep: any;
    public welcomes: any;
    private index = 0;
    public steps = [];
    private timer;
    public currentTimer = 0;
    public pausing = false;

    // @HostListener('window:keyup.esc') onKeyUp(event) {
    //     event.preventDefault();
    // }

    constructor(public dialogRef: MatDialogRef<WelcomeComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private fieldService: FieldService) {
        dialogRef.disableClose = true;
        this.form = this.formBuilder.group({
            HideWelcome: [false],
        });
        this.currentWelcome = this.data.welcomeToShow[this.index];
        this.welcomes = this.data.welcomeToShow;
        this.initSteps();
        this.currentStep = this.currentWelcome.step;
        clearInterval(this.timer);
        this.timer = setInterval(() => this.showNextSlide(), 1000);
        this.currentTimer = this.currentWelcome.duration;
    }

    ngOnInit(): void { }

    ngOnDestroy(): void {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    public selectionChange(event) {
        event.previouslySelectedStep.completed = false;

        this.index = this.steps[event.selectedIndex];
        this.index = this.welcomes.filter(t => t.step === this.index && t.priority === 1);
        this.currentStep = this.index[0].step;
        this.index = this.welcomes.indexOf(this.index[0]);

        this.currentWelcome = this.welcomes[this.index];
        this.currentTimer = this.currentWelcome.duration;
        clearInterval(this.timer);
        if (!this.pausing) {
            this.timer = setInterval(() => this.showNextSlide(), 1000);
        }
    }

    public closeDialog() {
        this.dialogRef.close();
        const changedUserPrefs = { ...this.data.userPrefs, hideWelcome: true };
        this.fieldService.dispatch({ type: AppActions.save_profile_prefs.name, payload: changedUserPrefs });
    }

    getWelcomesForStep() {
        return this.welcomes.filter(t => t.step === this.currentWelcome.step);
    }

    showNextSlide() {
        this.currentTimer--;
        if (this.currentTimer === 0) {
            if (this.index < this.welcomes.length - 1) {
                this.index++;
                this.currentWelcome = this.welcomes[this.index];
                if (this.currentWelcome.step !== this.currentStep) {
                    this.currentStep = this.currentWelcome.step;
                    this.activeStepper.next();
                }
                this.currentTimer = this.currentWelcome.duration;
            }
            else {
                if (this.timer) {
                    clearInterval(this.timer);
                }
            }
        }
    }

    pause() {
        this.currentWelcome = this.welcomes.find(slide => slide.step === this.currentStep && slide.priority === 1);
        clearInterval(this.timer);
        this.pausing = !this.pausing;
    }

    start() {
        this.timer = setInterval(() => this.showNextSlide(), 1000);
        this.pausing = !this.pausing;
    }

    initSteps() {
        this.welcomes.forEach(element => {
            if (this.steps.indexOf(element.step) === -1) {
                this.steps.push(element.step);
            }
        });
    }

}
