import { Component, ElementRef, OnChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';

import { TopicAction } from '../../../../../models/faq/faq-list/topicAction';

import { FaqListGroupRendererService } from './faq-list-grouprenderer.service';

@Component({
    selector: 'slx-faq-list-grouprenderer',
    templateUrl: './faq-list-grouprenderer.component.html',
    styleUrls: ['./faq-list-grouprenderer.component.scss'],
})

export class FaqListGroupRendererComponent implements ICellRendererAngularComp {
    public params;
    public index: number;
    public editMode = false;
    @ViewChild('inputTopicName') inputTopicName: ElementRef;

    constructor(private thisService: FaqListGroupRendererService) { }


    agInit(params: any): void {
        this.params = params;

        this.thisService.updateService(this);
    }

    refresh(params?: any): boolean {
        return true;
    }

    public getKey(value: string) {
        return `faq-${value}`;
    }

    public getChildrenAmount(): number {
        const children = this.params.node.allLeafChildren.length;
        return children;
    }

    public setEditMode(value: boolean) {
        this.editMode = value;

        this.thisService.updateService(this);
    }

    public onBlur(event) {
        if (event.relatedTarget && event.relatedTarget.className.split(' ').includes('save-button')) {
            return;
        }
        this.setEditMode(false);
    }

    public onKeyUp(event: any) {
        switch (event.key) {
            case 'Escape':
                this.setEditMode(false);
                break;
            case 'Enter':
                this.onClickSave(event.target.value);
                break;
            default:
                break;
        }
    }

    public onClickEdit() {
        this.setEditMode(true);
        setTimeout(() => {
            this.inputTopicName.nativeElement.focus();
            this.thisService.updateService(this);
            this.thisService.useEditMode(this.index);
        });
    }

    public onClickSave(newValue: string) {
        this.output(TopicAction.save, { newValue });
        this.setEditMode(false);

        this.thisService.updateService(this);
    }

    public onClickDelete() {
        this.output(TopicAction.delete);

        this.thisService.updateService(this);
    }

    private output(action: TopicAction, passedData?: any) {
        if (this.params.onClick instanceof Function) {
            const params = {
                action,
                passedData,
                renderer: this.params,
            };
            this.params.onClick(params);
        }
    }
}
