import { Injectable, OnDestroy } from '@angular/core';
import { DateAdapter, MatDialog } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
// import { isSameDay } from 'date-fns';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { BehaviorSubject, of, Subject } from '../../../node_modules/rxjs';
import { autoCompleteBaseUrls, SlxHttp } from '../access';
import { AppActions } from '../appActions';
// tslint:disable-next-line:import-barrels
import { CustomerProfileActions } from '../customer-profile/customerProfileActions';
import { FieldService } from '../field';
import { UniComponent } from '../field/uni/uni.component';
import { WelcomeComponent } from '../field/welcome/welcome.component';
import { AppState, AutocompleteType, getLanguageIndependantUrl, isActionInProgress, LanguageEnum, makeAction, SlxAction, stateOfAction, supportedLanguages } from '../models';
import { ActionState } from '../models/state/commonApp';

import { LoginService } from './../access';
import { Preferences } from './../models/state/customerProfile';
import { AccountActions } from './accountActions';

const debug = require('debug')('account');

@Injectable()
export class AccountService implements OnDestroy {
    de = {};
    fr = {};

    private subscription: Subscription = new Subscription();
    public onLangChange: Subject<LangChangeEvent> = new Subject();

    // private tips: Tip[];
    // private welcome: any[];
    // private tipsUserAccount: any[];
    // private tipsSub: Subscription;
    // private tipsUserSub: Subscription;
    // private welcomeSub: Subscription;

    public userProfileData = this.store.select(state => state.customerProfile.userProfileData);
    public customerProfileData = this.store.select(state => state.customerProfile.customerProfileData);
    public profilePrefs = this.store.select(state => state.customerProfile.profilePrefs);
    public departmentsData = this.store.select(state => state.customerProfile.departmentsData);
    public usersData = this.store.select(state => state.customerProfile.usersData);
    public singleDepartmentData = this.store.select(state => state.customerProfile.singleDepartmentData);
    public accountData = this.store.select(state => state.account);
    public customerProfile = this.store.select(state => state.customerProfile);
    public statistics = this.store.select(state => state.customerProfile.statistics);
    public trxsType = this.store.select(state => state.customerProfile.trxsType);
    public trxsTypeForOrder = this.store.select(state => state.customerProfile.trxsTypeForOrder);
    public usersIpAdress = this.store.select(state => state.aboService.usersIpAdress);
    public userId = this.store.select(state => state.access.userId);
    public getTips = this.store.select(state => state.account.tips);
    public welcome = this.store.select(state => state.account.welcome);
    public showUniUserWarning = this.store.select((state) => state.access.isUniUser && !state.customerProfile.profilePrefs.hideUniUsing);
    public transactionData = new BehaviorSubject<any>(null);

    constructor(private slxHttp: SlxHttp, private translate: TranslateService, private dateAdapter: DateAdapter<Date>, public store: Store<AppState>, private router: Router, private appTitle: Title, private loginService: LoginService, private dialog: MatDialog, private fieldService: FieldService) {
        this.subscription.add(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.onLangChange.next(event);
            document.querySelector('html').setAttribute('lang', event.lang);
        }));

        // this.dispatch({ fetch: AppActions.load_tips.name });

        this.translate.addLangs(supportedLanguages);

        if (!localStorage.getItem('userLanguage')) {
            const urlLanguage = getLanguageIndependantUrl(window.location.pathname).urlLanguage;
            const browserLanguage = this.translate.getBrowserLang();
            const decidingLanguage = urlLanguage || browserLanguage;

            switch (decidingLanguage) {
                case 'fr':
                    localStorage.setItem('userLanguage', 'fr');
                    break;
                default:
                    localStorage.setItem('userLanguage', 'de');
                    break;
            }
        }

        this.use(localStorage.getItem('userLanguage').toLowerCase());

        // Uncomment this for Production + delete 'tk' in use-function
        // translate.setDefaultLang(localStorage.getItem('userLanguage').toLowerCase());

        // this.profilePrefs.subscribe(res => console.log(res, res.hasOwnProperty('hideWelcome'), Object.keys(res).length));

        this.subscription.add(this.profilePrefs.subscribe((profilePrefs: Preferences) => {
            if (profilePrefs.hideWelcome === false) {
                this.dispatch({ fetch: AppActions.load_welcome.name });
                this.welcome.pipe(filter(res => res.length > 0), take(1))
                    .subscribe(welcomeSteps => this.dialog.open(WelcomeComponent, { width: '800px', height: '520px', panelClass: 'welcome', data: { welcomeToShow: welcomeSteps, lang: this.langEnum, userPrefs: profilePrefs } }));
            }
        })).add(this.showUniUserWarning.subscribe(show => {
            if (show) {
                const ref = this.dialog.open(UniComponent, { width: '655px', height: '255px', panelClass: 'welcome' });
                ref.afterClosed().subscribe(res => {
                    if (res) {
                        this.fieldService.dispatch({ type: AppActions.save_profile_prefs.name, payload: { hideUniUsing: true} });
                    } else {
                        this.loginService.logout(false);
                    }
                });
            }
        }));

        // this.tipsSub = this.store.subscribe(state => this.tips = state.account.tips);
        // this.tipsUserSub = this.store.subscribe(state => this.tipsUserAccount = state.account.tipsUser);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        // this.tipsSub.unsubscribe();
        // this.tipsUserSub.unsubscribe();
    }

    addTranslation(prefix, de, fr) {

        // we should test this in build process, not when the app is startet by the user, then we cant do anything about
        // missing prefixes anymore. BONUS: Function runs about twice as fast without testing for missing prefixes.

        // There is also a 'merge'-boolean as a parameter for setTranslation-function, so there would be no Object assign. But Object assign is
        // much faster than using the merge parameter.

        // for (const n in de) {
        //     if (n.indexOf(prefix) === -1) throw Error(`Failed prefix ${prefix} for DE key ${n}`);
        // }
        // for (const n in fr) {
        //     if (n.indexOf(prefix) === -1) throw Error(`Failed prefix ${prefix} for FR key ${n}`);
        // }


        Object.assign(this.de, de);
        Object.assign(this.fr, fr);


        this.translate.setTranslation('de', this.de);
        this.translate.setTranslation('fr', this.fr);
    }

    get lang() {
        return this.translate.currentLang || this.translate.getDefaultLang();
    }

    get langEnum() {

        if (this.lang === 'de') {
            return LanguageEnum.De;
        }

        if (this.lang === 'fr') {
            return LanguageEnum.Fr;
        }

        return LanguageEnum.undefined;
    }

    use(lang: string) {

        // Show translate Keys
        if (lang === 'tk') {
            this.translate.setDefaultLang(null);
            this.translate.use('tk');
            return;
        }

        this.translate.use(lang);

        switch (lang) {
            case 'fr':
                this.dateAdapter.setLocale('fr-FR');
                localStorage.setItem('userLanguage', 'fr');
                break;
            default:
                this.dateAdapter.setLocale('de-CH');
                localStorage.setItem('userLanguage', 'de');
                break;
        }
        debug('Switched language to', lang);

        this.dispatch({ type: AccountActions.language_changed.name, payload: lang });
    }

    dispatch(action) {
        return this.store.dispatch(makeAction(action as SlxAction));
    }

    isActionInProgress(...types): Observable<boolean> {
        return this.store.select(state => isActionInProgress(state, ...types));
    }

    stateOfAction(type): Observable<ActionState> {
        return this.store.select(state => stateOfAction(state, type));
    }

    loadDataBasedOnPath(path: string, departmentID: string) {
        switch (path) {
            case 'userprofile':
                this.dispatch({ fetch: CustomerProfileActions.load_user_profile_data.name });
                break;
            case 'preferences':
                this.dispatch({ type: AppActions.load_profile_prefs.name });
                break;
            case 'customer':
                this.dispatch({ fetch: CustomerProfileActions.load_customer_profile.name });
                break;
            // case 'departments':
            //     this.dispatch({ fetch: CustomerProfileActions.load_departments.name });
            //     break;
            case 'singleDepartment':
                this.dispatch({ fetch: CustomerProfileActions.load_departments.name, payload: departmentID });
                break;
            // case 'users':
            //     this.dispatch({ fetch: AccountActions.users_data.name });
            //     break;
            case 'statistics':
                // this.dispatch({ fetch: AccountActions.trxs_type.name });
                // this.dispatch({ fetch: AccountActions.trxs_type_for_order.name });
                break;
            default:
                break;
        }
    }

    autoCompleteDepartment(department: string): Observable<any> {
        const url = autoCompleteBaseUrls[AutocompleteType.StatisticsDepartment];
        return this.slxHttp.get(`${url}?department=${encodeURIComponent(department)}`);
    }

    autoCompleteUser(department: string, user: string): Observable<any> {
        const url = autoCompleteBaseUrls[AutocompleteType.StatisticsUser];
        return this.slxHttp.get(`${url}?department=${encodeURIComponent(department)}&user=${encodeURIComponent(user)}`);
    }

    // PLease do not remove the comments that have todo with Tips

    //*************Tips Wizard ***********/
    // tipToShow(topic: string) {
    //     const date = new Date();
    //     date.setHours(0, 0, 0, 0);
    //     let tip = null;
    //     let viewedTopicToday = false;

    //     if (this.tips.length !== 0) {
    //         //we filter the tips to take only the ones concerning the current topic
    //         const tipsCurrentTopic = this.tips.filter(t => t.topic.name === topic);

    //         // this.dispatch({ fetch: AppActions.load_profile_prefs.name });

    //         if (this.profilePrefsAccount && this.profilePrefsAccount.hideTipOfTheDay === false) {
    //             //if the user have already seen a tip of the current topic.
    //             const tipsViewed = this.tipsUserAccount;
    //             const viewedCurrentTopic = tipsViewed.filter(tU => tipsCurrentTopic.find(t => t.id === tU.id && t.topic.id === tU.topic));
    //             //if the user has already seen a tip for this topic, today then we do not show a tip.
    //             const tipsViewedToday = viewedCurrentTopic.filter(tU => {
    //                 const tUDate = toDate(tU.viewedDate);
    //                 viewedTopicToday = isSameDay(tUDate, date);
    //                 if (viewedTopicToday) {
    //                     return tU;
    //                 }
    //             });

    //             if (tipsViewedToday.length === 0) {
    //                 tip = tipsCurrentTopic.find(t => !this.tipsUserAccount.find(tU => tU.id === t.id));
    //             }
    //         }

    //     }
    //     return tip;
    // }

    // addTipToUserTips(tip: Tip) {
    //     const dateToday = new Date();
    //     const tipToAdd = {
    //         id: tip.id,
    //         topic: tip.topic.id,
    //         viewedDate: dateToday,
    //     };
    //     if (tip && tip.topic) {
    //         this.tipsUserAccount.push(tipToAdd);
    //     }
    //     this.dispatch({ save: AppActions.save_user_tips.name, payload: { ListTipsViewed: this.tipsUserAccount } });
    // }

    // findTopicForTip(path: string) {
    //     const test = path.split('/', 2);
    //     const topicFromPath = test.length > 0 && test[1] !== '' ? test[1] : 'home';
    //     return this.getTopicFromPath(topicFromPath);
    // }

    // showTipIfNecessary(path: string) {
    //     if (this.authService.hasValidAccesToken) {
    //         const topic = this.findTopicForTip(path);
    //         //show tip of the day and update tipsUserViewed
    //         const tipToShow = this.tipToShow(topic);
    //         if (topic !== '' && tipToShow) {
    //             this.dialog.open(TipOfTheDayComponent, {
    //                 //TODO @benjamin: is there a better to way to handle userPrefs and all the properties which are sent to the TipOfTheDayComponent?
    //                 width: '700px', panelClass: 'tip-of-the-day', data: { tipToShow: tipToShow, tipsForTopic: this.listTipsFromTopic(tipToShow), lang: this.langEnum, tipsUser: this.tipsUserAccount, userPrefs: this.profilePrefsAccount },
    //             });
    //             this.addTipToUserTips(tipToShow);
    //         }
    //     }
    // }

    // listTipsFromTopic(tip: Tip) {
    //     const tipsCurrentTopic = tip.topic.name !== 'home' ? this.tips.filter(t => t.topic.id === tip.topic.id) : this.tips;
    //     return tipsCurrentTopic;
    // }

    // getTopicFromPath(path) {
    //     switch (path) {
    //         case 'doc': return 'workspace';
    //         case 'recherche': return 'search';
    //         default: return path;
    //     }
    // }
}
