import { Pipe, PipeTransform } from '@angular/core';

import { PupSelectMode } from './publication-select.component';

@Pipe({ name: 'currentTranslationStringDeselectPipe' })
export class CurrentTranslationStringDeselectPipe implements PipeTransform {
    transform(mode: PupSelectMode) {
        switch (mode) {
            case PupSelectMode.All:
                return 'rech-pup-deselectAll';
            case PupSelectMode.Books:
                return 'rech-pup-deselectAllBooks';
            case PupSelectMode.CaseCollection:
                return 'rech-pup-deselectAllCaseCollections';
            case PupSelectMode.Journal:
                return 'rech-pup-deselectAllJournals';
            case PupSelectMode.Serial:
                return 'rech-pup-deselectAllSerials';
            case PupSelectMode.Selection:
                return 'rech-pup-resetSelection';
            case PupSelectMode.Commentaries:
                return 'rech-pup-deselectAllCommentaries';
        }
        return '';
    }
}


@Pipe({ name: 'currentTranslationStringSelectPipe' })
export class CurrentTranslationStringSelectPipe implements PipeTransform {
    transform(mode: PupSelectMode) {
        switch (mode) {
            case PupSelectMode.All:
                return 'rech-pup-selectAll';
            case PupSelectMode.Books:
                return 'rech-pup-selectAllBooks';
            case PupSelectMode.CaseCollection:
                return 'rech-pup-selectAllCaseCollections';
            case PupSelectMode.Journal:
                return 'rech-pup-selectAllJournals';
            case PupSelectMode.Serial:
                return 'rech-pup-selectAllSerials';
            case PupSelectMode.Selection:
                return 'rech-pup-reapplySelection';
            case PupSelectMode.Commentaries:
                return 'rech-pup-selectAllCommentaries';
        }
        return '';
    }
}
