import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { AlertType } from '../../../models/state/commonApp';

@Component({
    selector: 'slx-alert-window',
    templateUrl: './alertWindow.component.html',
    styleUrls: [ './alertWindow.component.scss' ],
})
export class AlertWindowComponent implements OnInit {
    public type: AlertType;
    public icon: string;
    public title: string;
    public message: string;

    constructor(public dialogRef: MatDialogRef<AlertWindowComponent>, @Inject(MAT_DIALOG_DATA) private dialogData: any) {
        this.type = this.dialogData.type;
        this.icon = this.dialogData.icon;
        this.title = this.dialogData.title;
        this.message = this.dialogData.message;
    }

    ngOnInit() {}
}
