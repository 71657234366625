import { AssetDisplayType, AssetRef, DocumentContent } from '../../models';
import { isEmtpyGuid } from '../../utility/utilityFunctions';

export function getPrecedingAssetRef(docContent: DocumentContent, currentAssetRef: AssetRef): AssetRef {
    if (!docContent || !docContent.navigationInfo || !currentAssetRef ||  currentAssetRef[0] === AssetDisplayType.LawDocument) {
        return null;
    }

    const source = 'document-toc-backward';

    if(currentAssetRef[0] === AssetDisplayType.ArticleOfLawDocument) {
        return isEmtpyGuid(docContent.navigationInfo.precedingAssetID)  ?
            AssetRef.create(AssetDisplayType.LawDocument,currentAssetRef[3], { source }) :
            AssetRef.create(AssetDisplayType.ArticleOfLawDocument,docContent.navigationInfo.precedingAssetID, { publicationId: currentAssetRef[3], source});
    }


    return !isEmtpyGuid(docContent.navigationInfo.precedingAssetID) ?
        AssetRef.create(AssetDisplayType.UnknownDocument, docContent.navigationInfo.precedingAssetID, { source }) : null;
}

export function getFollowingAssetRef(docContent: DocumentContent, currentAssetRef: AssetRef): AssetRef {
    if (!docContent || !docContent.navigationInfo || !currentAssetRef) {
        return null;
    }

    const source = 'document-toc-forward';

    if (AssetRef.isLawDoc(currentAssetRef)) {
        const lawID = currentAssetRef[0] === AssetDisplayType.LawDocument ? currentAssetRef[1] : currentAssetRef[3];
        return !isEmtpyGuid(docContent.navigationInfo.followingAssetID) ?
            AssetRef.create(AssetDisplayType.ArticleOfLawDocument, docContent.navigationInfo.followingAssetID, { publicationId: lawID, source }) : null;
    }

    return !isEmtpyGuid(docContent.navigationInfo.followingAssetID) ?
        AssetRef.create(AssetDisplayType.UnknownDocument, docContent.navigationInfo.followingAssetID, { source }) : null;
}
