module.exports = {
	"account-profile": "Profil",
	"account-clientprofile-successful-message": "Ihr Passwort wurde erfolgreich geändert",
	"account-save": "Speichern",
	"account-add-user": "Neuen Benutzer anlegen",
	"account-add-new-user": "Benutzer hinzufügen",
	"account-add-department": "Neue Abteilung anlegen",
	"account-add-new-department": "Abteilung hinzufügen",
	"account-questions": "Fragen?",
	"account-hotlinetext": "Unsere Hotline hilft Ihnen gerne weiter.",
	"account-filter": "Suchen",
	"account-german": "Deutsch",
	"account-french": "Französisch",
	"account-language": "Sprache",
	"account-term": "Anrede",
	"account-title": "Titel",
	"account-first-name": "Vorname",
	"account-name": "Name",
	"account-street": "Strasse / Nr.",
	"account-post-box": "Postfach",
	"account-zip-code": "Postleitzahl",
	"account-city": "Ort",
	"account-phone": "Telefon",
	"account-fax": "Fax",
	"account-homepage": "Webseite",
	"account-email": "E-Mail",
	"account-userid": "Benutzer-ID",
	"account-pwd": "Passwort",
	"account-pwd-change": "Passwort ändern",
	"account-pwd-old": "Aktuelles Passwort",
	"account-pwd-confirm": "Passwort bestätigen",
	"account-has-news": "Swisslex News",
	"account-kdnr": "Kundennummer",
	"account-Co": "Kanzlei/Firma",
	"account-dept": "Abteilung",
	"account-autorisations": "Übersicht persönliche Zugriffsberechtigungen",
	"account-successful": "Die Speicherung war erfolgreich",
	"account-error": "Fehler",
	"account-dept-active-users": "Aktive Nutzer",
	"account-dept-inactive-users": "Inaktive Nutzer",
	"account-dept-enabled": "Aktiviert",
	"account-undefined-error": "Es ist ein unerwarteter Fehler aufgetreten! Bestehen weiterhin Probleme, laden Sie die Seite neu oder wenden Sie sich an unsere Hotline.",
	"account-unresponsive-error": "Swisslex ist momentan überlastet! Bitte versuchen Sie es in ein paar Minuten nochmals oder wenden Sie sich an unsere Hotline.",
	"account-tips-error": "Fehler beim 'Tipp des Tages'",
	"account-dept-activate": "aktiviert",
	"account-dept-credit-limit": "Kreditlimite (CHF)",
	"account-show-inactive-users": "inaktive Nutzer einblenden",
	"account-show-inactive-departments": "inaktive Abteilungen einblenden",
	"account-user-edit": "Benutzerdaten bearbeiten",
	"account-edit": "Bearbeiten",
	"account-back-to-overview": "Zurück zur Übersicht",
	"account-user-level": "Autoritätsstufe",
	"account-excel-export": "Excel Export",
	"account-excel-export-successful": "Excel Export war erfolgreich",
	"account-excel-filename": "Nutzungsstatistik",
	"account-preferences-position": "Ansicht im Dokument positionieren",
	"account-preferences-position-searchTerm": "Auf ersten Suchbegriff",
	"account-preferences-position-beginDoc": "Auf Anfang des Dokuments",
	"account-preferences-parallelDocView": "Parallele Dokumentansicht",
	"account-preferences-openSearchOnFilter": "\"Weitere Suchkriterien\" bei Anwendung von definierten Suchfiltern öffnen",
	"account-preferences-hideSavedFavorite": "Keine Bestätigung wenn ein Dokument als Favorit gespeichert wird",
	"account-preferences-hideTipOfTheDay": "Nutzungshinweise deaktivieren",
	"account-preferences-hideWelcome": "Begrüssungsbildschirm deaktivieren",
	"account-preferences-hideIEWarning": "Warnung für veraltete Browser deaktivieren",
	"account-preferences-docview": "Dokumentansicht",
	"account-preferences-pdf-download": "PDF Download",
	"account-preferences-general": "Allgemeines",
	"account-preferences-reset-tips": "Bereits gesehene Nutzungshinweise zurücksetzen",
	"account-preferences-pdf-hitList-try-highlight": "Suchbegriffe hervorheben",
	"account-preferences-pdf-hitList-try-highlight-no": "Suchbegriffe nicht hervorheben",
	"account-preferences-pdf-doc-try-highlight": "Suchbegriffe im Dokument hervorheben",
	"account-preferences-pdf-doc-try-highlight-no": "Suchbegriffe nie hervorheben",
	"account-preferences-pdf-doc-try-highlight-yes": "Suchbegriffe immer hervorheben",
	"account-preferences-pdf-doc-try-highlight-manuel": "Für jeden Download entscheiden",
	"account-preferences-hitlistSize": "Treffer pro Seite",
	"account-stats-suche": "Transaktionssuche",
	"account-stats-show": "Anzeigen",
	"account-stats-tx": "Transaktionen",
	"account-stats-dept-id": "Abteilung ID",
	"account-stats-first-order": "Sortierung (1)",
	"account-stats-second-order": "Sortierung (2)",
	"account-stats-freetx": "Kostenfreie Transaktionen einschliessen",
	"account-stats-date": "Datum",
	"account-stats-year": "Jahr",
	"account-stats-month": "Monat",
	"account-stats-day": "Tag",
	"account-stats-hour": "Stunde",
	"account-stats-time": "Zeit",
	"account-stats-tx-type": "Typ",
	"account-stats-app": "Applikation",
	"account-stats-pub": "Publikation",
	"account-stats-dept": "Abteilung",
	"account-stats-user": "Nutzer",
	"account-stats-publ": "Verlag",
	"account-stats-required": "Kundennummer, Abteilung ID, Nutzer ID sind leer. Eines dieser Felder muss ausgefüllt werden",
	"account-stats-price": "Preis",
	"account-stats-show-price": "Listenpreise einblenden",
	"account-stats-ref-pub": "Referenz / Publikation",
	"account-stats-tx-total": "Total {{nbTransactions}} Transaktionen",
	"account-stats-filter": "Filter",
	"account-users-send-login": "Login versenden",
	"account-dept-comp": "Ohne Abteilungszuordnung",
	"account-own-dept": "Die Abteilung, in der Sie sich befinden, kann nicht deaktiviert werden",
	"account-root-dept": "Die Firma kann nicht deaktiviert werden",
	"account-not-disable": "Sie können sich nicht selbst deaktivieren",
	"account-send-all-logins": "Alle Logins versenden",
	"account-stats-export": "Excel-Datei",
	"account-stats-date-required": "von Datum ist leer. Das Feld muss ausgefüllt werden",
	"account-stats-reset-form": "Eingabe löschen",
	"account-stats-no-entries": "Keine Einträge vorhanden",
	"account-stats-loading": "Inhalte werden geladen",
	"account-stats-search-hide": "Suche einklappen",
	"account-stats-search-show": "Suche ausklappen",
	"account-add-user-successful": "Der neue Benutzer wurde erfolgreich hinzugefügt.",
	"account-customer-your-ip": "Deine IP",
	"account-pw-changed-successful": "Ihr Passwort wurde erfolgreich geändert",
	"account-pw-changed-confirmation-failed": "Ihr Passwort stimmt nicht mit der Bestätigung überein",
	"account-pw-changed-old-pw-nomatch": "Ihr aktuelles Passwort ist nicht korrekt",
	"account-pw-exception": "Ein Fehler ist aufgetreten. Probieren Sie es später nochmal oder kontaktieren Sie unsere Hotline unter 0800 587 463",
	"account-copy-sucess": "Dokumentlink wurde in Zwischenablage kopiert",
	"account-copy-error": "Dokumentlink konnte nicht kopiert werden",
	"account-grid-loading": "Inhalte werden geladen",
	"account-grid-nodata": "Keine Einträge vorhanden",
	"account-grid-statistics-nodata": "Es sind noch keine Einträge vorhanden, bitte geben Sie den gewünschten Zeitraum und allfällige Filterkriterien an",
	"account-department-back-to-all": "Zurück zu allen Abteilungen",
	"account-users-back-to-all": "Zurück zu allen Benutzern",
	"account-department-excel-export": "Abteilungen-Export",
	"account-not-enrolled-yet": "Noch nicht abonniert? <br> Testen Sie das neue Swisslex 4 Wochen <strong>kostenlos.</strong>",
	"account-slx-number-one": "Mit Recht die Nr. 1 <br> der Schweiz",
	"account-register-now": "Jetzt registrieren",
	"account-TokenRevoked-title": "Ihre aktive Sitzung wurde beendet",
	"account-TokenRevoked-text": "Die von Ihnen verwendeten Zugangsdaten wurden gerade zum Starten einer neuen Sitzung verwendet.<br>Für die verwendeten Zugangsdaten ist immer nur <strong>eine</strong> aktive Sitzung zulässig.<br>Bei Fragen oder Problemen wenden Sie sich bitte an Ihren firmeninteren Swisslex Administrator oder kontaktieren Sie die Hotline.",
	"account-TokenExpired-title": "Session abgelaufen",
	"account-TokenExpired-text": "Ihre Session ist abgelaufen. Bitte melden Sie sich wieder an.",
	"account-InvalidIP-title": "Ungültige IP",
	"account-InvalidIP-text": "Ihre aktive Sitzung wurde beendet, weil die IP-Adresse ausserhalb des vordefinierten Bereichs liegt. Bitte überprüfen Sie Ihre Netzwerkeinstellungen oder wenden Sie sich an Ihre IT-Dienste.",
	"account-stats-hide-price": "Listenpreise ausblenden",
	"account-simultaneous-session-title": "Gleichzeitige aktive Sitzungen",
	"account-simultaneous-session-text": "Es besteht mit den eingegebenen Zugangsdaten bereits eine aktive Sitzung.<br>Eine Mehrfachnutzung desselben Logins (Benutzer-ID/Passwort) ist <strong>nicht</strong> möglich.<br>",
	"account-simultaneous-session-link": "Trotzdem anmelden. Die aktive Sitzung wird dann beendet.",
	"account-transaction-limit-reached-title": "Nutzungslimite",
	"account-transaction-limit-reached-text": "<br>Die Nutzungslimite ({{transactionLimit}} CHF) wurde überschritten <br> Bitte wenden Sie sich direkt an Ihren internen Swisslex-Administrator oder an unsere Hotline."
};