import { DefaultUrlSerializer, UrlTree } from '@angular/router';

import { routingDebug } from './utility/utilityFunctions';

export class SlxUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        const splittedUrl = url.replace('?d=', '/legacyjournal?d=').split('?'); //test with http://localhost:4200/?d=sic-2003-731
        splittedUrl[0] = splittedUrl[0].toLowerCase();
        const newUrl = splittedUrl.join('?');
        routingDebug('urlserializer','url:', url, 'newurl:', newUrl);
        return super.parse(newUrl);
    }
}
