import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AppActions } from '../../appActions';
import { AppState } from '../../models';

@Component({
    selector: 'slx-subheader-main-navigation',
    templateUrl: './subheader-main-navigation.component.html',
    styleUrls: ['./subheader-main-navigation.component.scss'],
})
export class SubHeaderMainNavigationComponent {

    @Input() navigationOpen = false;

    public mainTab;

    constructor(public router: Router, private location: Location, public store: Store<AppState>) {
        this.mainTab = this.store.select(state => state.home.mainTab);
    }

     public changeMaintab(tab: string, event: MouseEvent): void {
        if (!event || !event.ctrlKey) {
            this.store.dispatch({type: AppActions.set_main_tab.name, payload:tab});
        }
    }

    public toggleNavigation(){
        this.navigationOpen = !this.navigationOpen;
    }
}
