import { SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Observable ,  of ,  Subscription } from 'rxjs';

import { MulticheckItem } from '../../models';

const debug = require('debug')('tree-multi');

export class SlxTreeNode {
    children: SlxTreeNode[];
    description: string;
    translate: string;
    icon: string;
    id: string;
}

@Component({
    selector: 'slx-treemulticheck',
    templateUrl: './treemulticheck.component.html',
    styleUrls: ['./treemulticheck.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TreeMulticheckComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => TreeMulticheckComponent),
        multi: true,
    }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeMulticheckComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

    @Input('options') set options(val: any) {
        val instanceof Observable ? this._options = val : this._options = of(val);
    }
    @Input() color: string;
    @Input() formControlName: string;
    @Output() change = new EventEmitter<string>();

    private subscription: Subscription;

    private _options: Observable<SlxTreeNode[]>;
    public formControl = null;

    public treeControl: NestedTreeControl<SlxTreeNode>;
    public treeDataSource: MatTreeNestedDataSource<SlxTreeNode>;
    public checklistSelection = new SelectionModel<SlxTreeNode>(true /* multiple */);

    public allCheckboxChecked = false;

    constructor(private translate: TranslateService) {
        this.treeControl = new NestedTreeControl<SlxTreeNode>(this.getChildren);
        this.treeDataSource = new MatTreeNestedDataSource();
    }

    ngOnInit() {
        this.subscription = this._options.subscribe(options => {
            this.treeDataSource.data = options;
        });
        debug('data loaded', this.treeDataSource.data);
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getChildren = (node: SlxTreeNode): Observable<SlxTreeNode[]> => { return of(node.children); };
    hasChild = (_: number, _nodeData: SlxTreeNode) => { return _nodeData.children && _nodeData.children.length > 0; };

    get options() { return this._options; }

    private getAllLeafChildren(node: SlxTreeNode) {
        const children = this.treeControl.getDescendants(node).filter(node => !this.hasChild(0, node));
        return children;
    }

    /** Whether all the descendants of the node are selected */
    allChildrenSelected(node: SlxTreeNode): boolean {
        const descendants = this.getAllLeafChildren(node);
        return descendants.every(child => this.checklistSelection.isSelected(child));
    }

    /** Whether part of the descendants are selected */
    someChildrenSelected(node: SlxTreeNode): boolean {
        const descendants = this.getAllLeafChildren(node);
        const result = descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.allChildrenSelected(node);
    }

    /** Toggle node selection. Select/deselect all the descendants node */
    parentSelectionToggle(node: SlxTreeNode): void {

        const descendants = this.getAllLeafChildren(node);
        this.allChildrenSelected(node)
            ? this.checklistSelection.deselect(...descendants)
            : this.checklistSelection.select(...descendants);
    }

    numberOfCheckedValues(node: SlxTreeNode): number {
        const descendants = this.getAllLeafChildren(node);
        return descendants.filter(d => this.checklistSelection.isSelected(d)).length;
    }

    numberOfOptions(node: SlxTreeNode): number {
        return this.getAllLeafChildren(node).length;
    }

    public validate(c: FormControl) {
        this.formControl = c;
        return {};
    }

    getValidationMessage() {
        const errors = this.formControl.errors || {};
        const errorTexts = Object.keys(errors).map(key => {
            const error = errors[key];

            if (error && error.translate) {
                const translated = this.translate.instant(error.translate, error);
                if (translated !== error.translate) return translated;
            }
            return null;
        }).filter(value => value);

        return errorTexts.join('. ');
    }

    public getViewValue(option: SlxTreeNode) {
        if (option.translate) {
            return this.translate.stream(option.translate);
        }
        return of(option.description);
    }


    propagateChange = (_: any) => { };

    registerOnChange(fn: any) { this.propagateChange = fn; }

    registerOnTouched(fn: any): void { }

    setDisabledState(isDisabled: boolean): void { }

    writeValue(checkedValues: string[]): void {
        const cvSet = new Set(checkedValues);
        if (checkedValues) {
            this.treeDataSource.data.forEach(rootNode => {
                this.checklistSelection.select(...this.treeControl.getDescendants(rootNode).filter(desc => cvSet.has(desc.id)));
            });
        }
        this.checklistSelection.onChange.subscribe(event => this.propagateChange(event.source.selected.filter(node => !this.hasChild(0, node)).map(node => node.id)));
    }

}
