import { saveAs } from 'file-saver';

import { toDate } from '../../../utility/date-format';

export function createVEvents(...events) {
    const uidDomain = events[0].uidDomain || 'default';
    const prodId = events[0].prodId || 'Calendar';
    const UID = events.length + '@' + uidDomain;
    const SEPARATOR = (navigator.appVersion.indexOf('Win') !== -1) ? '\r\n' : '\n';

    const vEvents = `\
BEGIN:VCALENDAR
PRODID:${prodId}
VERSION:2.0
` + events.map(mapEvent).join('') + `\
END:VCALENDAR
`.replace('\r','').replace('\n', SEPARATOR);

    function padZero(n: Number): String {
        return ('00' + (n.toString())).slice(-2);
    }

    function dateToStr(date: Date): string {
        const month = date.getMonth() + 1, day = date.getDate(), hours = date.getHours(), minutes = date.getMinutes(), seconds = date.getSeconds();
        return (hours+minutes+seconds) > 0 ? `${date.getFullYear()}${padZero(month)}${padZero(day)}T${padZero(hours)}${padZero(minutes)}${padZero(seconds)}` : `${date.getFullYear()}${padZero(month)}${padZero(day)}T`;
    }

    function makeContact({ name, email }) {
        let formattedAttendee = 'CN=';
        formattedAttendee += name || 'Unnamed attendee';
        if (email) {
            formattedAttendee += email ? `:mailto:${email}` : '';
        }

        return formattedAttendee;
    }

    function mapEvent(event) {
        const lang = event.lang || 'en-us';
        const start = dateToStr(toDate(event.begin || event.start));
        const end = dateToStr(toDate(event.end || event.stop));
        const now = dateToStr(new Date());
        const organizer = event.organizer ? makeContact(event.organizer) : null;

        function untilStr(until) {
            const uDate = new Date(Date.parse(until)).toISOString();
            return uDate.substring(0, uDate.length - 13).replace(/[-]/g, '') + '000000Z';
        }

        const rrule = [
            event.freq ? `FREQ=${event.freq}` : null,
            event.until ? `UNTIL=${untilStr(event.until)}` : null,
            event.interval ? `INTERVAL=${event.interval}` : null,
            event.count ? `COUNT=${event.count}` : null,
            event.byday && event.byday.length > 0 ? `BYDAY=${event.byday.join(',')}` : null,
        ].filter(v => v);

        let description = event.description.replace(/(?:\r\n|\r|\n)/g, '\\n');
        description = event.orgUrl ? (description + '\\n' + event.orgUrl + '\\n') : (description + '\\n');
        description = event.url ? (description + event.url) : description;
        description = description + (rrule.length > 0 ? ('\\nrrule:' + rrule.join(';')) : '');

        return `\
BEGIN:VEVENT
UID:${UID}
CLASS:PUBLIC
SUMMARY;LANGUAGE=${lang}:${event.title}
DESCRIPTION:${description}` + (rrule.length > 0 ? ('\nrrule:' + rrule.join(';')) : '') + `
DTSTAMP;VALUE=DATE-TIME:${now}
DTSTART;VALUE=DATE-TIME:${start}
DTEND;VALUE=DATE-TIME:${end}
LOCATION:${event.location}` + (organizer ? ('\nORGANIZER:'+ organizer) : '') + `
TRANSP:TRANSPARENT
END:VEVENT
`;
    }

    return {
        /**
         * Download calendar using the saveAs function from filesave.js
         * @param  {string} filename Filename
         * @param  {string} ext      Extention
         */
        download(filename = 'calendar', ext = '.ics') {
            let blob;
            if (navigator.userAgent.indexOf('MSIE 10') === -1) { // chrome or firefox
                blob = new Blob([vEvents]);
            } else { // ie
                const bb = new MSBlobBuilder();
                bb.append(vEvents);
                blob = bb.getBlob('text/x-vCalendar;charset=' + document.characterSet);
            }
            saveAs(blob, filename + ext);

            return vEvents;
        },
    };
}
