import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, LoginError, LoginErrorType, LoginResult, LoginState } from '@slx/authentification';
import * as Raven from 'raven-js';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { AppActions } from '../appActions';
import * as config from '../config';
import { AppState, getLanguageIndependantUrl, makeAction, SlxAction } from '../models';
import { AlertType } from '../models/state/commonApp';
import { RouterExtService } from '../routerext.service';

import { AccessActions } from './accessActions';

@Injectable()
export class LoginService implements OnDestroy {


    private loginStateValue: LoginState;
    private routeNeedsLoginValue: boolean;
    private routeNeedsLoginSubject = new BehaviorSubject<boolean>(false);

    public routeNeedsLogin = this.routeNeedsLoginSubject.asObservable();

    private loginSubscription: Subscription;

    private loginCredentials: { username: string, password: string, stayLoggedIn: boolean, forceTakeSession: boolean };

    // SELECTORS
    public loggedIn = this.store.select((state) => state.access.loggedIn);
    public loggedOut = this.store.select((state) => state.access.loggedOut);

    public fullName = this.store.select((state) => state.access.fullName);
    public userId = this.store.select((state) => state.access.userId);
    public portalUserDepartmentId = this.store.select(state => state.customerProfile.userProfileData ? state.customerProfile.userProfileData.departmentID : null);

    public hasEu = this.store.select((state) => state.access.userLoginRights.includes('RL:EU'));
    public hasLegalint = this.store.select((state) => state.access.userFunctionRights.includes('RF:LegalInt'));
    public isPremium = this.store.select((state) => state.access.userContentRights.includes('RC:Premium'));
    public isCorpAdmin = this.store.select((state) => state.access.userFunctionRights.includes('RF:CorporateAdministrator'));
    public isDepartmentAdmin = this.store.select((state) => state.access.userFunctionRights.includes('RF:DepartmentAdministrator'));
    public isJointVentureAdmin = this.store.select((state) => state.access.userFunctionRights.includes('RF:JointVentureAdministrator'));
    public isSuperUser = this.store.select((state) => state.access.userFunctionRights.includes('RF:SuperUser'));
    public hasUserTeachingBook = this.store.select((state) => state.access.userContentRights.includes('RC:Teachingbook'));

    public customStoreBackups = this.store.select(state => state.access.customStoreBackups);

    constructor(public store: Store<AppState>, private routerExtService: RouterExtService, private authService: AuthService, private router: Router, private translate: TranslateService, private dialog: MatDialog) {
        this.routerExtService.registerNavigationEndFunction((router: Router, event: NavigationEnd) => {
            this.routeNeedsLoginValue = config.routeNeedsLogin(event.urlAfterRedirects);
            this.updateRouteNeedsLogin();
        });

        this.loginSubscription = this.authService.loginResultObservable.subscribe(result => {

            switch (result.state) {
                case LoginState.LoggedIn:
                    this.loginCredentials = null;

                    this.store.dispatch({
                        type: AccessActions.change_login_state.name, payload: {
                            loggedIn: true,
                            loggedOut: false,
                            username: result.user.loginName,
                            firstName: result.user.firstName,
                            lastName: result.user.lastName,
                            userId: result.user.userId,
                            userLanguage: result.user.languageString,
                            departmentId: result.user.customerId,
                            isUniUser: result.user.isUniUser,
                            userLoginRights: result.user.loginRights,
                            userFunctionRights: result.user.functionRights,
                            userContentRights: result.user.contentRights,
                        },
                    });

                    this.translate.use(result.user.languageString);

                    // if on HOME go to default page from account settings
                    if (getLanguageIndependantUrl(this.routerExtService.router.url).relativeUrl === '/') {
                        // TODO account settings for where to go
                        this.store.dispatch({ type: AppActions.set_main_tab.name, payload: 'rech' });
                    }

                    Raven.setUserContext({
                        username: localStorage.getItem('username'),
                        id: result.user.userId,
                    });

                    break;
                case LoginState.LoggedOut:
                    if (this.loginStateValue !== LoginState.NotYetLoggedIn) {
                        this.router.navigate(['/']);
                    }
                    break;
            }

            if (result.error && this.loginStateValue !== LoginState.NotYetLoggedIn) {
                const errorAction = makeAction({ type: 'alert', payload: this.determineAlertBasedOnLoginTokenError(result.error) });
                this.dispatch(errorAction);
                if (result.state === LoginState.LoggedOut && this.loginStateValue !== LoginState.LoggedOut) {
                    this.logout(true);
                }
            }

            this.loginCredentials = null;
            this.loginStateValue = result.state;
            this.updateRouteNeedsLogin();
        });
    }

    determineAlertBasedOnLoginTokenError(error: LoginError) {
        switch (error.type) {
            case LoginErrorType.TokenRevoked:
                return {
                    type: AlertType.Warning,
                    key: `account-TokenRevoked-title`,
                    text: `account-TokenRevoked-text`,
                    duration: 15,
                };
            case LoginErrorType.TokenExpired:
                return {
                    type: AlertType.Warning,
                    key: `account-TokenExpired-title`,
                    text: `account-TokenExpired-text`,
                    duration: 15,
                };
            case LoginErrorType.InvalidIP:
                return {
                    type: AlertType.Warning,
                    key: `account-InvalidIP-title`,
                    text: `account-InvalidIP-text`,
                    duration: 15,
                };
            case LoginErrorType.AccountDeactivated:
                return {
                    type: AlertType.Warning,
                    key: 'AccountInactiv',
                    duration: 15,
                };
            case LoginErrorType.SystemNotResponsive:
                return {
                    type: AlertType.Error,
                    key: 'account-unresponsive-error',
                    duration: 5,
                };
            case LoginErrorType.SimultaneousLogin:
                const { username, password, stayLoggedIn } = this.loginCredentials;
                return {
                    type: AlertType.Warning,
                    text: 'account-simultaneous-session-text',
                    key: 'account-simultaneous-session-title',
                    actions: [
                        {
                            type: 'no_dispatch',
                            linkKey: 'account-simultaneous-session-link',
                            execute: () => { this.login(username,password,stayLoggedIn,true); },
                        },
                    ],
                    noLinkMargin: true,
                };
            case LoginErrorType.InvalidGrant:
                return {
                    type: AlertType.Warning,
                    key: error.message,
                    duration: 5,
                };
            default:
                return {
                    type: AlertType.Error,
                    key: 'account-undefined-error',
                    duration: 5,
                };
        }

    }


    ngOnDestroy(): void {
        this.loginSubscription.unsubscribe();
    }

    private updateRouteNeedsLogin() {
        this.routeNeedsLoginSubject.next(this.routeNeedsLoginValue);
    }

    public get loginResult(): Observable<LoginResult> {
        return this.authService.loginResultObservable;
    }

    public login(username: string, password: string, checkbox: boolean, forceTakeSession = false): void {
        this.loginCredentials = { username, password, stayLoggedIn: checkbox, forceTakeSession };
        this.authService.login(this.loginCredentials);
    }

    public dispatch(action) {
        return this.store.dispatch(makeAction(action as SlxAction));
    }

    public logout(automaticLogout: boolean) {

        this.authService.logout();

        this.store.dispatch({ type: AppActions.reset_store.name, payload: { automaticLogout } });
        Raven.captureBreadcrumb({
            message: 'logout',
            category: 'auth',
            level: 'info',
            data: {
                loggedIn: false,
            },
        });
        Raven.setUserContext();
    }

    public updateAuthentication(): void {
        this.authService.checkLoginStatus();
    }
}
