import { Component, Input } from '@angular/core';

import { SearchService } from '../../search.service';

@Component({
  selector: 'slx-searchfilterbar',
  templateUrl: './searchfilterbar.component.html',
  styleUrls: ['./searchfilterbar.component.scss'],
})
export class SearchfilterbarComponent {

  @Input() createAction: () => void;
  @Input() editAction: () => void;

  constructor(public searchService: SearchService) { }
}
