import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridApi, GridOptions, RowClickedEvent } from 'ag-grid-community';
import { Observable, Subscription } from 'rxjs';

import { AssetDisplayType, BiblioPeriodical, BiblioType, SourceDetail } from '../../models';
import { BibliothekService } from '../bibliothek.service';

@Component({
    selector: 'slx-case-collection-published-bibliothek',
    template: `
        <slx-grid
            [options] = "gridOptions"
            [columnDefs] = "columnDefs"
            [rowTranslatePrefix]="'biblio-casecollections'"
            [getRowHeight]="bibliothekService.getRowHeight"
            [getRowClass]="bibliothekService.getRowClass"
            [onGridReady]="onGridReady"
            [rowData]="caseLawList"
            [showSearch]="false"
            (rowClicked)="onRowClicked($event)">
        </slx-grid>`,
    styles: [`
        :host {
            height: 100%;
        }
    `],
})
export class CaseCollectionPublishedBibliothekComponent implements OnInit, OnDestroy {

    public gridOptions: GridOptions;
    public gridApi: GridApi;
    public columnDefs;
    public caseLawList: Observable<BiblioPeriodical[]>;

    private cacheClearedSubscribtion: Subscription;
    private quickFilterSubscription: Subscription;

    constructor(public bibliothekService: BibliothekService) {
        this.onGridReady = this.onGridReady.bind(this);
        this.gridOptions = <GridOptions>{
            headerHeight: 50,
            cacheQuickFilter: true,
        };

        this.quickFilterSubscription = this.bibliothekService.caseLawQuickFilter.subscribe(value => {
            if (this.gridApi) {
                this.gridApi.setQuickFilter(value);
            }
        });
    }

    ngOnInit(): void {
        this.columnDefs = [
            { headerName: '', field: 'logoUrl', suppressSorting: true, minWidth: 50, maxWidth: 50, cellRenderer: this.bibliothekService.logosCellRenderer, suppressMenu: true },
            { headerName: 'Categorie', field: 'categorie', minWidth: 180, maxWidth: 180, suppressMenu: true },
            { headerName: 'Abbrevitation', field: 'abbreviation', minWidth: 160, maxWidth: 160, suppressMenu: true },
            { headerName: 'Title', field: 'titles', minWidth: 160, cellRenderer: this.bibliothekService.createTitleCellRenderer(SourceDetail.CaseCollectionBiblio),
                suppressMenu: true, getQuickFilterText: this.bibliothekService.getTitleFilterValue, comparator: this.bibliothekService.titleComperator },
            { headerName: 'Since year', field: 'sinceYear', minWidth: 85, maxWidth: 85, suppressMenu: true },
            { headerName: 'Until year', field: 'untilYear', minWidth: 85, maxWidth: 85, suppressMenu: true },
        ];

        this.caseLawList = this.bibliothekService.getPeriodical(BiblioType.PublishedCaseLaws);
        this.cacheClearedSubscribtion = this.bibliothekService.cacheCleared.subscribe(cacheCleared => this.caseLawList = this.bibliothekService.getPeriodical(BiblioType.PublishedCaseLaws));
    }

    ngOnDestroy(): void {
        this.quickFilterSubscription.unsubscribe();
        this.cacheClearedSubscribtion.unsubscribe();
    }

    //-------------------------------------------------------------------------------------
    // Events & Listeners
    //-------------------------------------------------------------------------------------

    public onGridReady(params) {
        this.gridApi = params.api;
    }

    public onRowClicked(ev: RowClickedEvent): void {
        const mouseEvent = ev.event as MouseEvent;
        if (!mouseEvent || !mouseEvent.ctrlKey) {
            if (ev.data.titles.length === 1) {
                this.bibliothekService.openToc(ev.data.titles[0].key, AssetDisplayType.PeriodicalCollection, SourceDetail.CaseCollectionBiblio);
            }
            mouseEvent.stopPropagation();
        }
    }
}
