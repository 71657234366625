import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState, isActionInProgress, makeAction, SlxAction } from '../models';

import { EventsActions } from './eventsActions';

@Injectable()
export class EventsService {
    public currentEventsList: Observable<any> = this.store.select(state => state.events.currentEventsList);
    public currentEventsListForGrid: Observable<any> = this.store.select(state => state.events.currentEventsList.length > 0 ? state.events.currentEventsList : undefined);
    public practiceAreas: Observable<any> = this.store.select(state => state.events.practiceAreas);
    public singleEvent: Observable<any> = this.store.select(state => state.events.eventDetails);
    public choosenDate: Observable<string> = this.store.select(state => state.events.choosenDateFromCalendar);

    constructor(public store: Store<AppState>) { }

    public setSelectedDate(date: string): void {
        this.dispatch({ type: EventsActions.filter_calendar.name, payload: date });
    }

    public isActionInProgress(...types): Observable<boolean> {
        return this.store.select(state => isActionInProgress(state, ...types));
    }

    public dispatch(action) {
        return this.store.dispatch(makeAction(action as SlxAction));
    }

    public loadEventsForPath(path: string, id: string) {
        this.dispatch(this.getActionForPath(path, id));
        if (path === 'details') {
            this.dispatch({ type: EventsActions.load_current_events.name });
        }
    }

    public getActionForPath(path: string, id: string): SlxAction {
        switch (path) {
            case 'current':
                return { type: EventsActions.load_current_events.name } as SlxAction;
            case 'register':
                return { type: EventsActions.load_practice_areas.name } as SlxAction;
            case 'details':
                return makeAction({ fetch: EventsActions.load_single_event.name, payload: id });
        }
    }
}
