import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { InfoWindowComponent } from '../../field';
import { AppState, isActionInProgress, makeAction, RakFormElements, SlxAction, stateOfAction } from '../../models';
import { ActionState } from '../../models/state/commonApp';

import { AboServicesActions } from './abo-servicesActions';

@Injectable()
export class AboRegistrationService {

    public registerBusinessFormElements: Observable<any> = this.store.select(state => state.aboService.registerBusinessFormElements);
    public registerStudentsFormElements: Observable<any> = this.store.select(state => state.aboService.registerStudentsFormElements);
    public registerRakFormElements: Observable<RakFormElements> = this.store.select(state => state.aboService.registerRakFormElements);
    public activateRakUserData: Observable<any> = this.store.select(state => state.aboService.activateRakUserData);
    public badEmail: Observable<boolean> = this.store.select(state => state.aboService.badEmail);

    constructor(public store: Store<AppState>, private dialog: MatDialog) {
    }

    loadDataBasedOnPath(path: string) {
        const action = this.getActionForPath(path);
        if (action.type !== 'no_language_dependent_data') {
            this.dispatch(action as Action);
        }
    }

    dispatch(action) {
        return this.store.dispatch(makeAction(action as SlxAction));
    }

    isActionInProgress(...types) {
        return this.store.select(state => isActionInProgress(state, ...types));
    }

    stateOfAction(type): Observable<ActionState> {
        return this.store.select(state => stateOfAction(state, type));
    }

    getActionForPath(path: string): Action {
        const pathList = path.split('/'), last = pathList[pathList.length - 1];
        switch (last) {
            case 'business':
                return { fetch: AboServicesActions.load_register_business_form_elements.name } as SlxAction;
            case 'students':
                return { fetch: AboServicesActions.load_register_students_form_elements.name } as SlxAction;
            case 'add-new-rak-user':
                return { fetch: AboServicesActions.load_register_rak_form_elements.name } as SlxAction;
            default:
                return { type: 'no_language_dependent_data'};
        }
    }

    loadAgb(){
        const dialogRef = this.dialog.open(InfoWindowComponent, {
            width: '1200px', height: '800px', panelClass: 'info-window-modal', data: {
                filename: 'swisslex_agb', foldername: '', titleTransString: 'service-agb',
            },
        });
    }

}
