module.exports = {
	"seo-title-default": "Swisslex",
	"seo-title-recherche": "Recherche",
	"seo-title-recherche-history": "Historique",
	"seo-title-recherche-favorites": "Favoris",
	"seo-title-recherche-search-new": "Général",
	"seo-title-recherche-casepub-new": "Jurisprudence publiée",
	"seo-title-recherche-casenonpub-new": "Jurisprudence non publiée",
	"seo-title-recherche-journal-new": "Revues",
	"seo-title-recherche-commentary-new": "Commentaires",
	"seo-title-recherche-book-new": "Livres",
	"seo-title-recherche-author-new": "Auteur",
	"seo-title-recherche-law-new": "Législation fédérale",
	"seo-title-recherche-eulaws-new": "Recherche Droit UE",
	"seo-title-recherche-eudirect-new": "Accès direct droit UE",
	"seo-title-doc": "{{assetTitle}} | Recherche",
	"seo-title-toc": "{{assetTitle}} | Recherche",
	"seo-title-news": "News",
	"seo-title-biblio": "Bibliothèque",
	"seo-title-biblio-casecollections-published": "Jurisprudence fédérale et cantonale (publiée)",
	"seo-title-biblio-casecollections-unpublished": "Jurisprudence fédérale et cantonale (non publiée)",
	"seo-title-biblio-journals": "Revues",
	"seo-title-biblio-commentaries": "Commentaires",
	"seo-title-biblio-books": "Livres",
	"seo-title-biblio-serials": "Séries",
	"seo-title-biblio-eu": "Droit UE",
	"seo-title-jobs": "Emplois",
	"seo-title-jobs-single": "{{jobTitle}}",
	"seo-title-events": "Manifestations",
	"seo-title-events-current": "Manifestations actuelles",
	"seo-title-events-register": "Ajouter une manifestation",
	"seo-title-events-details": "{{eventTitle}}",
	"seo-title-account-forgotpassword": "Mot de passe oublié",
	"seo-title-customerprofile": "Profil client",
	"seo-title-customerprofile-userprofile": "Profil utilisateur",
	"seo-title-customerprofile-preferences": "Paramétrages",
	"seo-title-customerprofile-customer": "Données client",
	"seo-title-customerprofile-departments": "Départements",
	"seo-title-customerprofile-users": "Utilisateurs",
	"seo-title-customerprofile-statistics": "Statistique d'utilisation",
	"seo-title-service": "Abo & Services",
	"seo-title-service-standard": "Swisslex Standard",
	"seo-title-service-premium": "Swisslex Premium",
	"seo-title-service-abofees": "Frais d’abonnement",
	"seo-title-service-business": "Clients commerciaux",
	"seo-title-service-students": "Communauté universitaire",
	"seo-title-service-lawexamcandidates-rak-subscription": "Candidats aux examens d'avocat",
	"seo-title-service-training": "Offre de formation",
	"seo-title-service-agb": "CG",
	"seo-title-service-disclaimer": "Exclusion de responsabilité",
	"seo-title-about": "À propos de Swisslex",
	"seo-title-about-company": "Entreprise",
	"seo-title-about-shareholders": "Actionnariat",
	"seo-title-about-team": "Team",
	"seo-title-about-direction": "Plan d'accès",
	"seo-title-about-contact": "Contact",
	"seo-title-faq": "FAQ",
	"seo-title-faq-common": "Général",
	"seo-title-faq-workspace": "Espace travail",
	"seo-title-faq-search": "Filtres de recherche",
	"seo-title-faq-view": "Newsletter",
	"seo-title-faq-wizard": "Assistant de newsletter",
	"seo-description-default": "Swisslex - Le numéro un en Suisse! Testez gratuitement le nouveau Swisslex durant 4 semaines.",
	"seo-description-news": "Toujours à jour de la jurisprudence récente! Nouveaux contenus des tribunaux fédéraux et cantonaux, ainsi que revues spécialisées ou en ligne sur Swisslex ou par courriel.",
	"seo-description-biblio-casecollections-published": "Jurisprudence, Revues, Commentaires, Livres, Séries & Droit UE sont aménagés dans la bibliothèque pour une consultation facile.",
	"seo-description-biblio-casecollections-unpublished": "Jurisprudence fédérale et cantonale non publiée, consultable et triable par canton, titre de la publication, tribunal / éditeur ou par date.",
	"seo-description-biblio-journals": "79 revues spécialisées ou en ligne sur Swisslex en aperçu. Naviguez, triez ou créez directement une newsletter personalisée.",
	"seo-description-biblio-commentaries": "Aperçu de plus de 350 commentaires issus de l'archive Swisslex, classés par domaines du droit, navigable et triable.",
	"seo-description-biblio-books": "Obtenez une vue d'ensemble sur les livres disponible dans Swisslex, classés par domaines du droit, navigable et triable.",
	"seo-description-biblio-serials": "Allant des traités sur le droit fédérale jusqu'aux études zurichoises sur le droit privé, gagnez une vue d'ensemble sur les séries disponibles dans Swisslex.",
	"seo-description-biblio-eu": "Droit UE: Traités, Accords internationaux, Législation, Jurisprudence, propositions législatives et documents de l'AELE - gagnez une vue d'ensemble.",
	"seo-description-jobs-current": "Trouvez ou annoncez des offres d'emplois dans le domaine juridique avec le portail emploi Swisslex. En coopération avec le NZZ, accédez à de nombreuses offres récentes.",
	"seo-description-events-current": "Découvrez des manifestations dans le domaine juridique ou insérez les là où se trouvent les juristes. Classées par domaines du droit et en coopération avec Schulthess.",
	"seo-description-service-standard": "Swisslex Standard: Exploitez des outils de recherche intelligents, pour accéder à plus de 560.000 documents juridiques dans l'archive Swisslex en croissance constante.",
	"seo-description-service-premium": "Swisslex Premium: Recherchez et trouvez dans de la littérature juridique, revues spécialisées & domaines du droit. Avec service de news exhaustif pour un accès facile à la jurisprudence actuelle.",
	"seo-description-service-abofees": "Tarifs juste en fonction de l’utilisation, pour l'accès à la vaste archive Swisslex. Droit UE, Standard-Tax et Swisslex Premium en service supplémentaire.",
	"seo-description-service-business": "Remplissez le formulaire d'enregistrement et recevez un essai gratuit d'un mois avec accès à l'archive Swisslex complet contenant plus de 560.000 documents juridiques.",
	"seo-description-service-students": "Swisslex Standard & Swisslex Premium, gratuit pour étudiants immatriculés.",
	"seo-description-service-lawexamcandidates-rak-subscription": "Conditions avantageuses (CHF 34.- / mois, hors TVA) pour l'accès à Swisslex pour tout les candidats aux examens d'avocat ayant terminé leur stage pratique.",
	"seo-description-service-training": "Avec l'offre de formation gratuite Swisslex, familiarisez-vous avec les nouvelles fonctions du portal, recherchez de manière encore plus efficace et perfectionnez vos connaissances de Swisslex. Fixez un rendez-vous dès maintenant!",
	"seo-description-service-agb": "Conditions générales (CG) de Swisslex - Banque de données juridiques suisse SA.",
	"seo-description-service-disclaimer": "Exclusion de responsabilité de Swisslex - Banque de données juridiques suisse SA.",
	"seo-description-about-company": "Swisslex - le numéro 1 en Suisse. Fournisseur de la plateforme d'informations juridiques leader en Suisse. Trouvez, utilisez et mettez en lien les informations souhaitées.",
	"seo-description-about-shareholders": "Schulthess Médias Juridiques SA, Stämpfli Editions SA et l’avocat genevois Me Jacques Python sont les actionnaires majoritaires de Swisslex - Banque de données juridiques suisse SA.",
	"seo-description-about-team": "La direction et l'équipe Swisslex en aperçu.",
	"seo-description-about-direction": "Les bureaux des Swisslex se trouvent à 8050 Zurich, Schaffhauserstrasse 418 et à 1201 Genève, Rue du Mont-Blanc 21. Cliquez pour un plan d'accès.",
	"seo-description-about-contact": "Vous avez des questions ou souhaitez prendre contact? Notre hotline est à votre disposition les jours ouvrables entre 08h30 et 17h00 au 0800 587 463 ou par courriel."
};