import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable ,  Subscription } from 'rxjs';

import { AccountService } from '../../../account';
import { emailPrefixValidator, requiredValidator } from '../../../field';
import { SelectListItem, UniversityFormElements } from '../../../models';
import { markFormAsTouched } from '../../../utility/utilityFunctions';
import { AboRegistrationService } from '../abo-services.service';
import { AboServicesActions } from '../abo-servicesActions';

@Component({
    selector: 'slx-registration-students',
    templateUrl: './registration-students.component.html',
    styleUrls: ['./registration-students.component.scss'],
})
export class RegistrationStudentsComponent implements OnInit, OnDestroy, UniversityFormElements {
    addressTerms: SelectListItem[];
    addressTitles: SelectListItem[];
    language: string;
    languages: SelectListItem[];
    public studentsRegistrationForm: FormGroup;
    public studentsRegistrationSubscription: Subscription;
    public uniEmailSuffixSubscription: Subscription;
    public universities = [];
    public uniEmailSuffixes = [];
    public filteredUniEmailSuffixes = [];

    constructor(public aboRegistrationService: AboRegistrationService, private formBuilder: FormBuilder, private accountService: AccountService) { }

    ngOnInit() {
        this.studentsRegistrationForm = this.formBuilder.group({
            university: ['', requiredValidator()],
            addressTerm: [''],
            title: [''],
            firstName: ['', requiredValidator()],
            name: ['', requiredValidator()],
            emailPrefix: ['', [requiredValidator(), emailPrefixValidator('service-reg-email-notvalid-val-txt')]],
            emailSuffix: [''],
            hasCorporateNewsletter: [''],
        }
        );

        this.uniEmailSuffixSubscription = this.studentsRegistrationForm.get('university').valueChanges.subscribe(selectedValue => {
            this.filteredUniEmailSuffixes = this.uniEmailSuffixes.filter(suffix => suffix.value === selectedValue);
            if (this.studentsRegistrationForm.controls['university'].value !== null) {
                this.studentsRegistrationForm.controls['emailSuffix'].setValue(this.filteredUniEmailSuffixes.filter(el => el.selected)[0].text);
            }
        });

        this.studentsRegistrationSubscription = this.aboRegistrationService.registerStudentsFormElements.subscribe((data: UniversityFormElements) => {
            if (data) {
                Object.assign(this, data);
                this.setDefaultFormValues();
            }
        });
    }
    onSubmit(studentsRegistrationForm) {

        if (!studentsRegistrationForm.valid) {
            markFormAsTouched(studentsRegistrationForm);
            return;
        }

        const studentsRegistrationFormData = {
            addressTerm: studentsRegistrationForm.value.addressTerm,
            title: studentsRegistrationForm.value.title,
            firstName: studentsRegistrationForm.value.firstName,
            name: studentsRegistrationForm.value.name,
            clientIpAddress: '',
            selectedUniversity: studentsRegistrationForm.value.university,
            SelectedEmailSuffix: studentsRegistrationForm.value.emailSuffix,
            EmailPrefix: studentsRegistrationForm.value.emailPrefix,
            hasCorporateNewsletter: studentsRegistrationForm.value.hasCorporateNewsletter,
            language: this.accountService.lang.toUpperCase(),
        };

        this.aboRegistrationService.dispatch({ save: AboServicesActions.save_uni_user_profile_data.name, payload: studentsRegistrationFormData });
    }

    public resetForm() {
        this.studentsRegistrationForm.reset();
        Object.keys(this.studentsRegistrationForm.controls).forEach(
            (key) => {
                this.studentsRegistrationForm.controls[key].setErrors(null);
                this.studentsRegistrationForm.controls[key].updateValueAndValidity();
            });

        this.setDefaultFormValues();
    }

    private setDefaultFormValues() {
        if (this.accountService.lang === 'de') {
            this.studentsRegistrationForm.controls['addressTerm'].setValue('Herr');
        } else {
            this.studentsRegistrationForm.controls['addressTerm'].setValue('Monsieur');
        }

        this.studentsRegistrationForm.controls['title'].setValue('-');
        this.studentsRegistrationForm.controls['hasCorporateNewsletter'].setValue(true);
    }

    ngOnDestroy() {
        this.studentsRegistrationSubscription.unsubscribe();
        this.uniEmailSuffixSubscription.unsubscribe();
    }

}
