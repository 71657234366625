

import { AssetDisplayType, DocScrollType } from '../enums';
import { UserFavorite } from '../research';

import { Asset, AssetRef, AssetType } from './asset';
import { AssetCiting, AssetList, AssetListGeneric, NewerCommentModel, RelatedAssetsModel } from './assetCiting';

export class AssetTab {
    title: string;
    assetID: string;
    assetRef: AssetRef;
    isLoading: boolean;
    manualReload: boolean;
    logoTargetTypeUrl: string;

    // asset: Asset;
    loadedTime: Date;
    assetCitedIn: RelatedAssetsModel;
    commentariesForLaw: RelatedAssetsModel;
    newerComment: NewerCommentModel;
    intitalScrollInformation: ScrollInformation;
    // citationAnchor: string;
    // refferingCitationId: string;
    currentEdocTitleElement: string;
    scrollInfo: DocScrollInfo;
    favorite: UserFavorite;

    static create(assetRef: AssetRef, favorite: UserFavorite | null, asset?: Asset): AssetTab {
        const title = asset ? AssetRef.titleForAsset(asset) : '...';
        const loadedTime = new Date();
        const assetID = AssetRef.toAssetID(assetRef);
        const assetType = AssetType.fromAssetRef(assetRef);
        // newDefaultTab.refferingCitationId = refferingCitationId;
        const isLoading = true;

        return {
            title,
            assetID,
            assetRef,
            isLoading,
            loadedTime,
            favorite: favorite,
            logoTargetTypeUrl: asset ? asset.logoTargetTypeUrl : null,
            //  asset: asset ? asset: new Asset(assetID, assetType),
        } as AssetTab;
    }
}


export interface CommentaryForLaw {
    assetDisplayType: AssetDisplayType;
    displayString: string;
    assetID: string;
}

export interface CommentaryForLawPayload {
    isPrimary: boolean;
    commentariesForLaw: RelatedAssetsModel;
    newerComment: NewerCommentModel;
}

interface ScrollInformation {
    pageHeight: number;
    scrollPosition: number;
}

export interface DocScrollInfo {
    scrollType: DocScrollType;
    value: string;
}


export function determineSelectorBasedOnScrollInfo(scrollInfo: DocScrollInfo): string {
    switch (scrollInfo.scrollType) {
        case DocScrollType.CitationAnchor:
            return scrollInfo.value;
        case DocScrollType.EdocTitleElement:
            return `[name="${scrollInfo.value}"]`;
        case DocScrollType.FirstSearchTerm:
            return '[id="firstSearchTerm"]';
        case DocScrollType.ReferringCitationId:
            return `[id="${scrollInfo.value}"]`;
        case DocScrollType.DocumentStart:
            return `div.split-view`;
        default:
            return '';
    }
}

