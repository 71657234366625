import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { EventsService } from './events.service';
import { EventsActions } from './eventsActions';

@Injectable()
export class EventsResolver implements Resolve<boolean> {

    constructor(private eventsService: EventsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const path = route.url[0].path;
        this.eventsService.dispatch({ type: EventsActions.set_events_tab.name, payload: path });
        this.eventsService.loadEventsForPath(path, route.params.id);
        // immediate render of the component
        return true;
    }


}
