import { Action } from '@ngrx/store';

import { AccessState, enhanceActionsObject, StoreBackup } from '../models';

export const AccessActions = {

    change_login_state(state, { payload }: { payload: AccessState }) {
        const { firstName, lastName } = payload;
        const fullName = `${lastName || state.lastName} ${firstName || state.firstName}`;

        return {
            ...state,
            ...payload,
            fullName: fullName.trim() ? fullName : payload.username,
        };
    },

    load_custom_store_backups(state: AccessState, { payload, result }: { result: string, payload: StoreBackup[] }) {
        return result ? { ...state, customStoreBackups: payload } : state;
    },

    refresh_acces_token(state: AccessState, { payload, result }) {
        return result ? state : { ...state, hasValidAccesToken: false };
    },

};
enhanceActionsObject(AccessActions);

// export const ImmerAccessActions = {

    // load_custom_store_backups(draftState: AccessState, {payload, result }): void {
    //     if (result) {
    //         draftState.customStoreBackups = payload;
    //     }
    // },

    // refresh_acces_token(draftState: AccessState, { payload, result }) : void {
    //     if (!result) {
    //         draftState.hasValidAccesToken = false;
    //     }
    // },

// };
// enhanceActionsObject(ImmerAccessActions);
