import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import {
    aboutSwisslexSendContactFormUrl, eventsGetEventsForStartPage, eventsGetSpecificAmountOfEventsUrl,
    getFaqByTopicIDUrl, getNewsConfigurationsUrl, jobsForStartPage, SlxHttp, topDocumentsUrl, topJobsUrl
} from '../access';
import { AccountActions, AccountService } from '../account';
import { catchErrorForAction, makeAction, SlxAction } from '../models';
import { AlertType } from '../models/state/commonApp';

import { AboRegistrationService } from './abo-services';
import { HomeActions } from './homeActions';

const numberOfEventsToLoad = 5;

@Injectable()
export class HomeEffects {
    @Effect() reloadLanguageDependentData = this.actions$.pipe(
        ofType(AccountActions.reload_language_dependent_data_abo.name),
        map((action: SlxAction) => makeAction(this.aboRegistrationService.getActionForPath(this.router.url)))
    );

    @Effect() getFaqByTopicID = this.actions$.pipe(
        ofType(HomeActions.get_faq_by_topic_id.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.get(`${getFaqByTopicIDUrl}?topicID=${action.payload.topicID}&language=${this.accountService.lang}`, false).pipe(
                map(payload => makeAction({ result: HomeActions.get_faq_by_topic_id.name, payload })),
                catchErrorForAction(action, {})
            )
        )
    );

    @Effect() getStartPageEventsForHomeTile = this.actions$.pipe(
        ofType(HomeActions.get_events_for_home_tile.name),
        switchMap(action =>
            this.slxHttp.get(`${eventsGetEventsForStartPage}?language=${this.accountService.lang}`, false).pipe(
                map(payload => ({ type: HomeActions.set_home_tile_start_page_events.name, payload })),
                catchErrorForAction(action, {})
            )
        )
    );

    @Effect() getEventsForHomeTile = this.actions$.pipe(
        ofType(HomeActions.get_events_for_home_tile.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.get(`${eventsGetSpecificAmountOfEventsUrl}?language=${this.accountService.lang}&numberOfEvents=${numberOfEventsToLoad}`, false).pipe(
                map(payload => makeAction({ type: HomeActions.set_home_tile_more_events.name, payload })),
                catchErrorForAction(action, {})
            )
        )
    );

    @Effect() getNewsForStartPage = this.actions$.pipe(
        ofType(HomeActions.get_news_for_home_tile.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.get(`${getNewsConfigurationsUrl}?language=${this.accountService.lang}`).pipe(
                map(payload => makeAction({ result: HomeActions.get_news_for_home_tile.name, payload })),
                catchErrorForAction(action, {})
            )
        )
    );

    @Effect() getTopDocumentsForHomeTile = this.actions$.pipe(
        ofType(HomeActions.get_top_documents_for_home_tiles.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.get(`${topDocumentsUrl}?numberOfDocs=10&language=${this.accountService.lang}`, false).pipe(
                map(payload => makeAction({ type: HomeActions.get_top_documents_for_home_tiles_result.name, payload })),
                catchErrorForAction(action, {})
            )
        )
    );

    @Effect() getTopJobsForHomeTile = this.actions$.pipe(
        ofType(HomeActions.get_top_jobs_for_start_page.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.get(`${topJobsUrl}`, false).pipe(
                map(payload => makeAction({ type: HomeActions.get_top_jobs_for_start_page_result.name, payload })),
                catchErrorForAction(action, {})
            )
        )
    );

    @Effect() getCurrentJobsForHomeTile = this.actions$.pipe(
        ofType(HomeActions.get_top_jobs_for_start_page_result.name),
        switchMap((action: SlxAction) => {
            const nJobs = 10 - action.payload.length;
            return this.slxHttp.get(`${jobsForStartPage}${nJobs}`, false).pipe(
                map(payload => makeAction({ type: HomeActions.set_jobs_for_start_page.name, payload })),
                catchErrorForAction(action, {})
            );
        })
    );

    @Effect() sendContactForm = this.actions$.pipe(
        ofType(HomeActions.send_contact_form.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.post(aboutSwisslexSendContactFormUrl, action.payload, false).pipe(
                map(payload => makeAction({ result: HomeActions.send_contact_form.name })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'about-contact-form-error' })
            )
        )
    );

    constructor(
        private actions$: Actions,
        private router: Router,
        private accountService: AccountService,
        private aboRegistrationService: AboRegistrationService,
        private slxHttp: SlxHttp
    ) {
    }
}
