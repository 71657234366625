module.exports = {
	"site-page-not-found": "Nicht Bekannt ({{url}})",
	"about": "Über Swisslex",
	"about-contact": "Kontakt",
	"customerprofile": "Kundenprofil",
	"service": "Abo & Services",
	"account": "Konto",
	"recherche": "Recherche",
	"news-service": "News",
	"biblio": "Bibliothek",
	"jobs": "Jobbörse",
	"events": "Veranstaltungen",
	"faq": "FAQ",
	"faq-id": "ID",
	"faq-top": "Oben",
	"faq-left": "Links",
	"faq-right": "Rechts",
	"faq-bottom": "Unten",
	"login-welcome": "Willkommen bei Swisslex",
	"login-welcome2": "Bitte loggen Sie sich ein",
	"login-register": "Noch nicht registriert?",
	"login-forgot": "Passwort vergessen?",
	"login-login": "Einloggen",
	"login-logout": "Ausloggen",
	"login-stay": "Eingeloggt bleiben",
	"login-username": "Benutzername",
	"login-password": "Passwort",
	"NoAccessForUserPass": "Benutzer-ID oder Passwort fehlerhaft",
	"service-standard": "Swisslex Standard",
	"service-premium": "Swisslex Premium",
	"service-abofees": "Abonnementsgebühren",
	"service-reg": "Registrierung",
	"service-business": "Geschäftskunden",
	"service-students": "Universitätsangehörige",
	"service-lawexamcandidates": "Kandidaten Rechtsanwaltsprüfung",
	"service-lawbooks": "Juristischer Fachbuchhandel",
	"service-agb": "AGB",
	"service-disclaimer": "Haftungsausschluss",
	"service-hotline": "Hotline",
	"service-questions": "Fragen?",
	"service-faq": "FAQ - Häufig gestellte Fragen",
	"service-hotline-txt": "Unsere Hotline hilft Ihnen gerne weiter",
	"service-reg-business": "Registrierung Geschäftskunden",
	"service-reg-business-txt-1": "Durch das Ausfüllen dieses Formulars können Sie einen kostenlosen Testzugang für einen Monat beantragen.",
	"service-reg-business-txt-2": "Falls Sie bereits Swisslex-Kunde sind, wenden Sie sich bitte direkt an Ihren internen Swisslex-Administrator oder an unsere Hotline unter 0800 587 463.",
	"service-reg-comp-name": "Kanzlei / Firma",
	"service-reg-country": "Land",
	"service-reg-abo": "Abonnement:",
	"service-reg-nol": "Anzahl Juristen",
	"service-reg-business-comp": "Kanzlei / Firma",
	"service-reg-business-privat": "Einzelperson",
	"service-reg-comp-suffix": "Adresszusatz",
	"service-name-max-length": "Der Name muss kürzer als 30 Zeichen sein",
	"service-reg-comp-zip-notvalid-val-txt": "Ihre eingegebene Postleitzahl ist zu lang",
	"service-reg-comp-phone-notvalid-val-txt": "Ihre eingegebene Telefonnummer ist ungültig",
	"service-reg-comp-fax-notvalid-val-txt": "Ihre eingegebene Faxnummer ist ungültig",
	"service-reg-comp-nol-notvalid-val-txt": "Bitte geben Sie nur Zahlen an",
	"service-reg-userid-notvalid-val-txt": "Ihre E-Mail/Benutzer-ID ist ungültig",
	"service-reg-email-notvalid-val-txt": "Ihre E-Mail ist ungültig",
	"service-reg-agb": "AGB",
	"service-reg-agb-accept": " gelesen und akzeptiert",
	"service-reg-agb-notvalid-val-txt": "Bitte bestätigen Sie die AGB",
	"service-reg-ok-txt": "Ihre Registrierung war erfolgreich. Weitere Informationen erhalten Sie in Kürze per Mail.",
	"service-reg-pwd-equal": "Muss gleich sein",
	"service-reg-pwd-min": "Minimum {{requiredLength}}",
	"service-reg-pwd-uppercase": "Minimum ein Grossbuchstaben und 6 Zeichen",
	"service-reg-business-dialog-header": "Abschluss Registrierung",
	"service-reg-business-dialog-title": "Die Geschäftskunden-Anmeldung ist fast abgeschlossen. Bitte wählen Sie noch eine der folgenden Optionen: ",
	"service-reg-business-dialog-option1": "Ich möchte die Swisslex-Services als Einzelunternehmen oder Privatperson evaluieren",
	"service-reg-business-dialog-option2": "Ich bin Studentin oder Student an einer Schweizer Universität oder Fachhochschule",
	"service-reg-business-dialog-option3": "Ich bereite mich auf die Anwaltsprüfung vor",
	"service-reg-studs": "Registrierung Universitätsangehörige",
	"service-reg-uni": "Universität",
	"service-reg-studs-txt-1": "Die schweizerischen Rechtsfakultäten haben mit Swisslex eine Vereinbarung getroffen, wonach Swisslex Standard & Premium allen immatrikulierten Studierenden in den meisten Fällen kostenlos zur Verfügung gestellt wird.",
	"service-reg-studs-txt-2": "Die Registrierung erfolgt direkt über die entsprechenden Stellen der Universitäten.",
	"service-reg-studs-userid": "E-Mail/Benutzer-ID",
	"service-reg-txt": "Registrieren",
	"service-to-reg": "Zur Registrierung",
	"service-reg-reset-form": "Eingaben zurücksetzen",
	"service-reg-studs-mail-prefix": "Der Teil ihrer E-Mail-Adresse, der links neben dem @ -Symbol angezeigt wird.",
	"service-reg-studs-excl-unis-txt": "Für die folgende Universität ist keine automatische Registrierung möglich, diese erfolgt direkt über die entsprechende Stelle der Universität. Klicken Sie dazu auf den unten aufgeführten Link.",
	"service-reg-uni-lausanne": "Universität Lausanne",
	"service-reg-uni-neuenburg": "Universität Neuenburg",
	"service-reg-rak-txt": "Registrierung Kandidaten Rechtsanwaltsprüfung",
	"service-reg-rak-txt-1": "Swisslex bietet für Anwaltsprüfungskandidaten, die das Praktikum abgeschlossen haben, einen Zugang zu Swisslex zu vergünstigten Konditionen (CHF 34.- / Monat, exkl. MwSt.) an.",
	"service-reg-rak-txt-2": "Um einen solchen Zugang zu beantragen, registrieren Sie sich und senden Sie uns eine Kopie der Bestätigung für die Prüfungsanmeldung an:",
	"service-reg-rak-txt-3": "Sie erhalten dann von uns einen Aktivierungscode, mit dem Sie Ihr Abonnement über Paypal (American Express-, Visa- oder MasterCard) bezahlen können.",
	"service-reg-rak-txt-4": "Bitte beachten Sie: ",
	"service-reg-rak-txt-5": "Ohne die Bestätigung wird die Registrierung nicht bearbeitet!",
	"service-reg-rak-runtime": "Gewünschte Laufzeit",
	"service-reg-rak-pdf": "Laden Sie Ihre Prüfungsanmeldung per PDF hoch oder schicken Sie diese per Post, Fax oder E-Mail",
	"service-reg-rak-activate": "Aktivierungscode bereits erhalten?",
	"service-reg-rak-activate-txt": "Wenn Sie bereits einen Aktivierungscode von uns erhalten haben, können Sie die Registrierung hier abschliessen:",
	"service-reg-rak-activate-code": "Aktivierungscode",
	"service-reg-rak-activate-account": "Account aktivieren",
	"service-reg-rak-loginname-notvalid-val-txt": "Bitte geben Sie Ihren Benutzernamen ein",
	"service-reg-rak-upload-pdf": "PDF hochladen",
	"service-reg-rak-upload-notvalid-size-val-txt": "PDF ist zu gross. Maximale Grösse sind 20MB.",
	"service-reg-rak-upload-notvalid-typ-val-txt": "Bitte nur PDF heraufladen.",
	"service-reg-rak-paypal": "Registrierung abschliessen",
	"service-reg-rak-paypal-duration": "Abonnementsdauer:",
	"service-reg-rak-paypal-month": "Monatsgebühren:",
	"service-reg-rak-paypal-tax": "MwSt. (7.7 %):",
	"service-reg-rak-paypal-total": "Total:",
	"service-reg-rak-paypal-pay": "Bezahlen",
	"service-reg-rak-paypal-pay-successful": "Ihre Zahlung wurde erfolgreich übermittelt. Bitte loggen Sie sich ein.",
	"service-reg-rak-paypal-pay-unsuccessful": "Während der Bezahlung ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.",
	"service-reg-business-info": "Allgemeine Angaben",
	"service-reg-business-contact": "Kontakt",
	"service-reg-business-abo": "Swisslex Login",
	"service-steampfli": "Link zu Stämpfli Buchhandlung",
	"service-schulthess": "Link zu Schulthess Buchshop",
	"service-training": "Schulungsangebot",
	"service-tr-txt-1": "Wollen Sie Ihre Swisslex Kenntnisse auffrischen und unsere Recherche-Werkzeuge bzw. Portalfunktionen besser kennenlernen und dadurch Swisslex noch effizienter einsetzen?",
	"service-tr-txt-2": "Unseren Kunden bieten wir kostenlose Schulungen an. Vor Ort oder Fernschulungen über eine Internet-Liveschaltung.",
	"service-tr-txt-3": "Richten Sie noch heute Ihre Schulungsanfrage mittels nachfolgendem Formular an uns oder wenden Sie sich an unsere",
	"service-tr-common": "Allgemeine Angaben",
	"service-tr-additional": "Zusatzinformationen",
	"service-tr-date1": "Terminvorschlag 1",
	"service-tr-date2": "Terminvorschlag 2",
	"service-tr-date3": "Terminvorschlag 3",
	"service-tr-company": "Firma",
	"service-tr-participants": "Anz. Teilnehmer",
	"service-tr-person": "Kontaktperson",
	"service-tr-location": "Ort",
	"service-tr-phone": "Telefon",
	"service-tr-mail": "E-Mail",
	"service-tr-tr": "Schulung",
	"service-tr-inhouse": "Schulung vor Ort",
	"service-tr-remote": "Fernschulung",
	"service-tr-computer": "Beamer, Computer, Internet",
	"service-tr-available": "vorhanden",
	"service-tr-not-available": "nicht vorhanden",
	"service-tr-lang": "Sprache",
	"service-tr-de": "Deutsch",
	"service-tr-fr": "Französisch",
	"service-show-ip": "Ihre IP-Adresse anzeigen",
	"service-showing-ip": "Ihre IP-Adresse",
	"TrainingSuccessfulMessage": "Ihre Schulungsanfrage wurde erfolgreich übermittelt.",
	"change-pwd-title": "Ändern des Swisslex- Passworts",
	"forgot-pwd-title": "Sie haben Ihr Passwort für Swisslex vergessen?",
	"forgot-pwd-text": "In diesem Fall geben Sie bitte hier Ihre registrierte E-Mail-Adresse ein:",
	"forgot-pwd-mail": "E-Mail Adresse",
	"forgot-pwd-enter": "Abschicken",
	"NoAccessForIpAddress": "Sie befinden sich nicht in dem für den Zutritt erforderlichen Netzwerk. Sollten Sie von ausserhalb zugreifen, überprüfen Sie bitte Ihre VPN-Einstellungen.",
	"NotInUniNetWork": "Das Einloggen ist nicht möglich, weil die IP-Adresse ausserhalb des vordefinierten Bereichs liegt. Bitte überprüfen Sie Ihre Netzwerkeinstellungen oder wenden Sie sich an Ihre IT-Dienste.",
	"UnknownAccount": "Benutzer-ID oder Passwort fehlerhaft!",
	"AccountExpired": "Ihr Abonnement ist abgelaufen. Bitte wenden Sie sich an die Hotline.",
	"BadPassword": "Benutzer-ID oder Passwort fehlerhaft!",
	"BadHashedPassword": "Benutzer-ID oder Passwort fehlerhaft!",
	"ProductBundleAlreadySubscribed": "Sie sind bereits Abonnent des von Ihnen ausgewählten Produktes.",
	"ProductBundleAlreadyTested": "Ihr kostenloses Testabonnement ist abgelaufen.",
	"EmailaddressAlreadyInUse": "Diese E-Mail Adresse existiert schon in unserem System. Bitte wählen sie eine andere E-Mail-Adresse aus oder wenden Sie sich an unsere Hotline.",
	"UserIdAlreadyInUse": "Diese Benutzer-ID existiert bereits in unserem System. Bitte wählen Sie eine andere E-Mail-Adresse aus oder wenden Sie sich an unsere Hotline - 0800 587 463   /   +41 (0) 44 365 32 32!",
	"AccountInactiv": "Ihr Account ist nicht aktiviert.",
	"UserAccountInactiv": "Ihr Login ist nicht aktiv. Bitte kontaktieren Sie ihren Administrator.",
	"DepartmentInactiv": "Die Abteilung ist inaktiv. Bitte wenden Sie sich an ihren Administrator.",
	"RakAktivierungsCodeInvalid": "Der eingegebene Aktivierungscode ist nicht korrekt.",
	"RakAktivierungsDateInvalid": "Die Bestätigung der Anmeldung für die Rechtsanwaltsprüfung liegt schon mehr als 11 Monate zurück. Bitte nehmen Sie Kontakt mit unserer Hotline auf.",
	"RakAktivierungNotConfirmed": "Kopie der Bestätigung für die Rechtsanwaltsprüfung wurde Swisslex noch nicht zugestellt.",
	"RakAktivierungAlreadyDown": "Die Registrierung wurde schon abgeschlossen.",
	"RequestPasswordUserEmailNotExist": "Falls Ihre E-Mail Adresse in unserem System registriert ist, werden Sie in wenigen Minuten eine E-Mail erhalten.",
	"InvalidSessionId": "Session ist abgelaufen! Bitte loggen Sie sich erneut ein.",
	"UnknownError": "Unidentifizierbarer Fehler! Wenden Sie bitte sich an unsere Hotline - 0800 587 463   /   +41 (0) 44 365 32 32",
	"DoesNotComeViaUniRegistrationForm": "Bitte benutzen Sie für die Registrierung das Formular Registrierung Universitätsangehörige.",
	"LoginExistsInTheUniOfZurichWithThisEmailSuffix": "Auf ihren Namen existiert bereits einen Login im Konto der Universität Zürich. Bitte wenden Sie sich an die Hotline von Swisslex.",
	"AboservicesRegistrationEmailNotValidValidationMessage": "Ihre E-Mail ist ungültig.",
	"DoesNotComeViaBusinessRegistrationForm": "Bitte benutzen Sie für die Registrierung das Formular Registrierung Geschäftskunden.",
	"CompanyNameMissing": "Bitte geben Sie die Kanzlei / Firma an",
	"NumberOfFlawyersMissing": "Bitte geben Sie die Anzahl Juristen in ihrem Unternehmen/in ihrer Kanzlei an. Bitte geben Sie nur Zahlen an.",
	"StreetMissing": "Bitte geben Sie die Strasse an.",
	"ZipCodeMissing": "Bitte geben Sie Ihre Postleitzahl an. Nur die Eingabe von min. 4 bis max. 5 Zahlen ist zulässig.",
	"CityMissing": "Bitte geben Sie den Ort an.",
	"PhoneMissing": "Bitte geben Sie Ihre Telefonnummer an oder Ihre eingegebene Telefonnummer ist ungültig.",
	"FirstNameMissing": "Bitte geben Sie Ihren Vornamen ein.",
	"NameMissing": "Bitte geben Sie Ihren Nachnamen an.",
	"EmailMissing": "Bitte geben Sie Ihre E-Mail/Benutzer-ID an oder Ihre E-Mail/Benutzer-ID ist ungültig.",
	"PasswordMissing": "Bitte geben Sie ein Passwort an oder Ihr Passwort ist ungültig.",
	"AgbMissing": "Bitte bestätigen Sie die AGB.",
	"DoesNotComeViaRakRegistrationForm": "Bitte benutzen Sie für die Registrierung das Formular Registrierung Kandidaten Rechtsanwaltsprüfung.",
	"RakActivationCodeMissing": "Bitte Aktivierungscode eingeben.",
	"DepartmentNameMissing": "Bitte geben Sie einen Abteilungsnamen an.",
	"UserLoginSendSuccessful": "Die Zugangsdaten für diesen Benutzer wurden erfolgreich versendet.",
	"UserLoginSendAllSuccessful": "Die Zugangsdaten für {{value}} Benutzer wurden erfolgreich versendet.",
	"UserLoginSendNoEmail": "Bitte weisen Sie dem Benutzer erst eine E-Mail Adresse zu.",
	"BusinessSuccessfulMessage": "Ihre Registrierung war erfolgreich. Weitere Informationen erhalten Sie in Kürze auf postalischem Weg.",
	"UniversitySuccessfulMessage": "Ihre Registrierung war erfolgreich. Sie werden in Kürze eine E-Mail mit Ihren Zugangsdaten erhalten.",
	"RakSubscribeSuccessfulMessage": "Ihre Registrierung war erfolgreich. Wir werden Ihren Zugang bearbeiten, sobald wir von Ihnen eine Kopie der Bestätigung für die Prüfungsanmeldung erhalten haben.",
	"ForgotPasswordLoginNameNotExist": "Die eingegebene E-Mail Adresse ist nicht registriert. Bitte überprüfen Sie Ihre Eingabe oder wenden Sie sich an unsere Hotline.",
	"ForgotPasswordSuccessful": "Falls Ihre E-Mail Adresse in unserem System registriert ist, werden Sie in wenigen Minuten eine E-Mail erhalten.",
	"ForgotPasswordToLate": "Das Zeitfenster von 24 Stunden wurde überschritten. Bitte rufen Sie die Funktion \"Passwort vergessen\" auf oder wenden Sie sich an unsere Hotline.",
	"ForgotPasswordSecondCall": "Der von uns versandte Link zur Änderung des Passwortes kann nur einmal benutzt werden. Bitte rufen Sie die Funktion \"Passwort vergessen\" auf oder wenden Sie sich an unsere Hotline.",
	"more": "Mehr",
	"more-events": "Weitere Veranstaltungen",
	"all-events": "Alle Veranstaltungen",
	"all-jobs": "Alle Jobs",
	"top-docs-last-days": "Die meistgelesenen Dokumente der letzten 7 Tage auf swisslex.ch",
	"news": "News",
	"news-configurate": "News verwalten",
	"news-currents": "Meine eingerichteten News-Abos",
	"news-description": "Mit dem Swisslex News-Service können Sie sich die neuesten Rechtsinformationen regelmässig per E-Mail zustellen lassen. Einmal konfigurieren - immer auf dem neuesten Stand.",
	"news-premium": "Dieser Service steht nur Nutzern mit einem Abonnement Premium zur Verfügung",
	"home-to-contact": "Zu Kontakt",
	"home-swisslex-training": "Swisslex-Schulung",
	"home-swisslex-training-register": "Jetzt anmelden",
	"home-to-faq": "Zu FAQ",
	"home": "Startseite",
	"faq-common": "Allgemein",
	"faq-search": "Suchfilter",
	"faq-doc": "Dokumente",
	"faq-workspace": "Arbeitsbereich",
	"faq-view": "Newsletter",
	"faq-wizard": "Newsletter-Assistent",
	"faq-recherche": "Recherche",
	"faq-news": "News",
	"faq-profile": "Profil",
	"faq-settings": "Einstellungen",
	"faq-entry": "FAQ Einträge",
	"faq-adjust": "Anpassen",
	"faq-add": "Hinzufügen",
	"faq-edit": "Bearbeiten",
	"faq-remove": "Entfernen",
	"faq-delete": "Löschen",
	"faq-save": "Speichern",
	"faq-fk-topic": "Fremdschlüssel",
	"faq-title-de": "Titel Deutsch",
	"faq-title-fr": "Titel Französisch",
	"faq-topic-name": "Themenname",
	"faq-priority": "Priorität",
	"faq-content-german": "Inhalt Deutsch",
	"faq-content-french": "Inhalt Französisch",
	"faq-content-de": "Inhalt Deutsch (HTML)",
	"faq-content-fr": "Inhalt Französisch (HTML)",
	"faq-use-image": "Bild verwenden",
	"faq-upload-image-de": "Bild hochladen DE",
	"faq-upload-image-fr": "Bild hochladen FR",
	"faq-delete-image-de": "Bild löschen DE",
	"faq-delete-image-fr": "Bild löschen FR",
	"faq-image-upload-only": "Sie dürfen nur Bilder hochladen",
	"faq-upload-notvalid-size-val-txt": "Maximale Grösse sind 20MB.",
	"faq-image-position": "Bildposition",
	"faq-reset-form": "Eingaben zurücksetzen",
	"faq-add-submit": "FAQ Hinzufügen",
	"faq-edit-submit": "FAQ Aktualisieren",
	"faq-add-successful": "FAQ erfolgreich hinzugefügt",
	"faq-add-common": "Allgemeine Angaben",
	"faq-add-title": "Titel",
	"faq-add-content": "Inhalt",
	"faq-add-image": "Bild",
	"faq-remove-topic-title": "Themenname löschen",
	"faq-remove-topic-text": "Sind Sie sicher, dass Sie den Themenname inklusive aller ihm unterliegenden FAQ Einträgen unwiderruflich löschen möchten?",
	"faq-remove-topic-confirm": "Ja",
	"faq-remove-title": "FAQ Eintrag löschen",
	"faq-remove-text": "Sind Sie sicher, dass Sie den FAQ Eintrag unwiderruflich löschen möchten?",
	"faq-remove-confirm": "Ja",
	"faq-adjust-entries": "Einträge bearbeiten",
	"faq-edit-back-to-list": "Zurück zu allen FAQs",
	"faq-preview": "Vorschau",
	"faq-show-original-preview": "Originale Vorschau anzeigen",
	"faq-return": "Zurück",
	"faq-german": "Deutsch",
	"faq-french": "Französisch",
	"faq-german-copy-img-fr": "Deutsch (Es ist nur ein französisches Bild vorhanden)",
	"faq-french-copy-img-de": "Französisch (Es ist nur ein deutsches Bild vorhanden)",
	"old-browser-dectected": "Ihr Webbrowser ist veraltet. Bitte aktualisieren Sie Ihren Browser für mehr Sicherheit, Geschwindigkeit und den besten Komfort.",
	"old-browser-more-info": "Erfahren Sie mehr...",
	"form-validation-required": "Dies ist ein Pflichtfeld",
	"home-info-operator-help": "Operatoren",
	"home-info-language-help": "Suchsprache",
	"home-info-reference-help": "Bibliografische Referenz",
	"home-search-reset": "Arbeitsbereich leeren",
	"home-send-mail-error": "Es gab einen technischen Fehler. Bitte versuchen Sie es später nochmal oder kontaktieren Sie unsere Hotline.",
	"home-help": "Hilfe",
	"home-main-menu": "Hauptmenü",
	"home-uni-using-header": "Universitäre Nutzung",
	"home-uni-using": "Dieser Swisslex-Zugang berechtigt nur zur universitären Nutzung. Jede andere, insbesondere eine kommerzielle Nutzung, ist untersagt. Das systematische Herunterladen grosser Teile von Büchern stellt einen Verstoss gegen den Nutzungsvertrag dar.",
	"home-uni-using-dial": "Information zur universitären Nutzung",
	"home-uni-using-ok": "zustimmen",
	"home-uni-using-no": "ablehnen"
};