import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import * as Raven from 'raven-js';
import { Observable } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { deleteSearchFilterUrl, getSearchFilterUrl, newsConfigurationUrl, overwriteSearchFilterUrl, practiceAreaFilterOptionsUrl, saveSearchFilterUrl, SlxHttp, transactionInfoBaseUrl, unDeleteSearchFilterUrl,
    updateSearchFilterOrderUrl, updateSearchFilterTitleUrl, userSearchFiltersUrl } from '../access';
import { AccountActions } from '../account';
import { AppActions } from '../appActions';
import { AssetActions, AssetService } from '../asset';
import { ActiveTocTab, AppState, Asset, AssetDisplayType, AssetLawInfo, AssetTab, AssetType, catchErrorForAction, catchErrorObservable, getResultNameFromAction,
     HitlistType, LawContent, makeAction, makeTransactionLimitReachedAlert, RechercheState, SearchTransactionInfo, SlxAction } from '../models';
import { AlertType } from '../models/state/commonApp';
import { routingDebug } from '../utility/utilityFunctions';

import { SearchService } from './search.service';
import { SearchActions } from './searchActions';

@Injectable()
export class SearchEffects {

    @Effect() reRunTransaction = this.actions$.pipe(
        ofType(SearchActions.rerun_search_transaction.name),
        withLatestFrom(this.searchService.store.select('recherche') as Observable<RechercheState>),
        filter(([action, state]: [SlxAction, RechercheState]) => state.transactionID !== Number(action.payload.id)),
        mergeMap(([action, state]: [SlxAction, RechercheState]) => {
            const url = `${transactionInfoBaseUrl}${action.payload.id}&isEuTransaction=${action.payload.data.isEuSearch}`;
            return this.slxHttp.get(url).pipe(
                mergeMap((transactionInfo: SearchTransactionInfo) => this.searchService.getRerunSearchTransactionActions(transactionInfo, action.payload.id)),
                catchError(catchErrorObservable(SearchActions.search_error.name, 'rech-rerunError', payload => ({ payload }), { duration: 8, id: 'searchError', icon: 'search' }))
            );
        })
    );

    @Effect() loadNewsletter = this.actions$.pipe(
        ofType(SearchActions.load_newsletter.name),
        withLatestFrom(this.searchService.store as Observable<AppState>),
        filter(([action, state]) => state.recherche.hitlist.id !== (action as SlxAction).payload.id),
        switchMap(([action, state]) => {
            return this.searchService.getNews((action as SlxAction).payload.id)
                .pipe(
                    map(payload => {
                        const configuration = { ...payload.hitlist.configuration };
                        const hitlist = { ...payload.hitlist, configuration, id: (action as SlxAction).payload.id, issue: payload.issue, year: payload.year };
                        return makeAction(this.searchService.searchResultToAction(hitlist, state));
                    }),
                    catchError(catchErrorObservable(SearchActions.search_error.name, 'rech-newsletterError'))
                );
        })
    );

    @Effect() verifyPracticeAreasForFilter = this.actions$.pipe(
        ofType(SearchActions.change_visibility_advanced_search.name, SearchActions.search_result.name),
        map((action: SlxAction) => {
            return { type: SearchActions.verify_practice_areas_for_filter.name };
        })
    );
    @Effect() getPracticeAreasForFilter = this.actions$.pipe(
        ofType(SearchActions.get_practice_areas_for_filter.name, SearchActions.verify_practice_areas_for_filter.name),
        withLatestFrom<SlxAction, RechercheState>(this.searchService.state),
        filter(([action, state]) => {
            if ([SearchActions.verify_practice_areas_for_filter.name].find(x => action.type === x)) {
                return state.practiceAreaFilters === null;
            }
            return true;
        }),
        switchMap(([action, state]) => {
            return this.slxHttp.get(`${practiceAreaFilterOptionsUrl}?language=${this.translateService.currentLang}`)
                .pipe(
                    map(payload => makeAction({ result: SearchActions.get_practice_areas_for_filter.name, payload })),
                    catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' })
                );
        })
    );

    @Effect() getDocumentCategoriesForFilter = this.actions$.pipe(
        ofType(SearchActions.get_document_categories_for_filter.name),
        switchMap((action: SlxAction) =>
            this.searchService.getDocumentCategoriesForFilter().pipe(
                map(payload => makeAction({ result: SearchActions.get_document_categories_for_filter.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' }))
        )
    );

    @Effect() getLegalActCategoriesForFilter = this.actions$.pipe(
        ofType(SearchActions.get_document_categories_for_filter.name),
        switchMap((action: SlxAction) =>
            this.searchService.getLegalActCategoriesForFilter().pipe(
                map(payload => makeAction({ result: SearchActions.get_legal_act_categories_for_filter.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' }))
        )
    );

    @Effect() searchSubmitOrRefine = this.actions$.pipe(
        ofType(SearchActions.search_fields_submit.name,
            SearchActions.hitlist_page_submit.name,
            SearchActions.hitlist_drilldown_submit.name,
            SearchActions.search_fields_loaded_with_submit.name
        ),
        // Map the payload into JSON to use as the request body
        withLatestFrom(this.searchService.store as Observable<AppState>),
        map(([action, state]) => ({ ...this.searchService.prepareSearch(state), state: state })),
        switchMap(({ url, searchParams, state }) =>
            this.slxHttp.post(url, searchParams).pipe(
                // If successful, dispatch success action with result
                map(payload => this.searchService.searchResultToAction(payload, state)),
                // If request fails, dispatch failed action
                catchError(errorPayload => {
                    const alert = errorPayload.error && errorPayload.error.transactionLimitReached
                        ? makeTransactionLimitReachedAlert(errorPayload.error.transactionLimit)
                        : { type: AlertType.Error, duration: 8, id: 'searchError' };
                    return catchErrorObservable(SearchActions.search_error.name, 'rech-searchError', payload => ({ payload }), alert)(errorPayload);
                })
            )
        )
    );

    @Effect() getPublicationSelectFilterOptions = this.actions$.pipe(
        ofType(SearchActions.get_publication_filter_options.name, SearchActions.verify_publication_filter_options.name),
        withLatestFrom<SlxAction, RechercheState>(this.searchService.state),
        filter(([action, state]) => {
            if (action.type === SearchActions.verify_publication_filter_options.name) {
                return state.publicationFilterContent == null;
            }
            return true;
        }),
        switchMap(([action, state]) => {
            const url = this.assetService.getPublicationFilterOptionsUrl();
            return this.slxHttp.get(url).pipe(
                map(payload => makeAction({ result: SearchActions.get_publication_filter_options.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-load-pubselect-error' })
            );
        })
    );

    @Effect({ dispatch: false }) setUrlFromStore = this.actions$.pipe(
        ofType(SearchActions.expand_search.name, SearchActions.search_result.name, SearchActions.set_rech_tab.name, AppActions.set_collection_tab.name, SearchActions.no_transaction_info.name),
        filter((action: SlxAction) => {
            return !(action.payload && action.payload.preventUrlFromStore);
        }),
        tap((_) => {
            routingDebug('searchEffects', 'auto-url-from-store', _);

            // ends up in searchService.searchRestore(), which determines the URL based on Store
            this.location.go('auto-url-from-store');
        })
    );

    @Effect({ dispatch: false }) sentryBreadCrumbNoData = this.actions$.pipe(
        ofType(SearchActions.search_result.name, getResultNameFromAction(AssetActions.load_asset)),
        tap(action => {
            Raven.captureBreadcrumb({
                message: `Action ${action.type} has been dispatched`,
                category: 'action',
                level: 'info',
            });
        })
    );

    @Effect() handleSingleHit = this.actions$.pipe(
        ofType(SearchActions.search_result.name),
        filter(action => (action as SlxAction).payload.numberOfHits === 1
            && HitlistType[(action as SlxAction).payload.type] !== HitlistType.Search
            && HitlistType[(action as SlxAction).payload.type] !== HitlistType.News
            && (action as SlxAction).payload.hits[0].subHitCount === 0),
        withLatestFrom(this.searchService.store.select('recherche') as Observable<RechercheState>),
        mergeMap(([action, state]) => {
            const openAssetAction = { type: AppActions.present_results.name, payload: { primary: [state.hitlist.hits[0].ref], secondary: [] } };
            const type = state.hitlist.hits[0].ref[0];

            return type === AssetDisplayType.Book || type === AssetDisplayType.PeriodicalPublication || type === AssetDisplayType.LawDocument || type === AssetDisplayType.ArticleOfLawDocument ?
                [
                    { type: SearchActions.toggle_hitlist_open.name },
                    openAssetAction,
                ] : [
                    openAssetAction,
                    { type: AppActions.set_collection_tab.name, payload: { collectionTab: 'toc', restoreOnToggle: true, isPrimary: true } },
                    { type: AssetActions.set_active_tab_for_toc.name, payload: ActiveTocTab.Primary },
                ];

        })
    );

    @Effect() handleLawHit = this.actions$.pipe(
        ofType(SearchActions.search_result.name),
        filter(action => HitlistType[(action as SlxAction).payload.type] === HitlistType.LawSearch
            && (action as SlxAction).payload.numberOfHits > 1
            && AssetDisplayType[(action as SlxAction).payload.hits[0].targetType] === AssetDisplayType.LawDocument),
        withLatestFrom(this.searchService.store.select('recherche') as Observable<RechercheState>),
        map(([action, state]) =>
            ({ type: AppActions.present_results.name, payload: { primary: [state.hitlist.hits[0].ref], secondary: [] } })
        )
    );

    @Effect() searchSuccessHistoryAndUrlUpdate = this.actions$.pipe(
        ofType(SearchActions.search_result.name),
        map(action => {
            const type = HitlistType[(action as SlxAction).payload.type];
            return type === HitlistType.EuSearch || type === HitlistType.EuDirectSearch;
        }),
        withLatestFrom(this.searchService.store.select('recherche') as Observable<RechercheState>),
        switchMap(([isEu, state]) => this.fetchHistory(isEu, state))
    );

    @Effect() assetHistoryUpdate = this.actions$.pipe(
        ofType(getResultNameFromAction(AssetActions.load_asset)),
        filter((action: SlxAction) => action.payload.type !== AssetType.previewListInTab),
        map(action => {
            return (action as SlxAction).payload.ref[0] === AssetDisplayType.EuCaseLawDocument || (action as SlxAction).payload.ref[0] === AssetDisplayType.EuLawDocument;
        }),
        withLatestFrom(this.searchService.store.select('recherche') as Observable<RechercheState>),
        switchMap(([isEu, state]) => this.fetchHistory(isEu, state))
    );

    fetchHistory(isEu: boolean, state: RechercheState) {
        const url = this.searchService.prepareHistoryUpdate(isEu, state);
        return this.slxHttp.get(url).pipe(
            map(payload => isEu ? { type: this.searchService.actions.recent_eu_transactions.name, payload } : { type: this.searchService.actions.recent_transactions.name, payload }
            ),
            catchError(catchErrorObservable(SearchActions.search_error.name, 'rech-historyError', payload => ({ payload }), { icon: 'access_time', duration: 5 }))
        );
    }

    @Effect() refreshHistory = this.actions$.pipe(
        ofType(AccountActions.reload_language_dependent_data_rech.name),
        withLatestFrom(this.searchService.store.select('recherche') as Observable<RechercheState>),
        switchMap(([action, state]) => this.fetchHistory(false, state))
    );

    @Effect() refreshHistoryEu = this.actions$.pipe(
        ofType(AccountActions.reload_language_dependent_data_rech.name),
        withLatestFrom(this.searchService.store.select('recherche') as Observable<RechercheState>),
        switchMap(([action, state]) => this.fetchHistory(true, state))
    );

    @Effect() savePdf = this.actions$.pipe(
        ofType(SearchActions.save_hitlist_pdf.name),
        withLatestFrom(this.searchService.store as Observable<AppState>),
        map(([action, state]) => { return { ...this.searchService.prepareSavePdf(null, state), action }; }),
        switchMap(({ action, url }) =>
            this.slxHttp.postPdf(url, null).pipe(
                map((payload) => {
                    this.searchService.savePdfLocal(payload);
                    return makeAction({ result: SearchActions.save_hitlist_pdf.name, payload });
                }),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-hitlist-pdf-error' })
            )
        )
    );

    @Effect() applyUserSearchFilter = this.actions$.pipe(
        ofType(SearchActions.apply_user_search_filter.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.get(`${getSearchFilterUrl}${action.payload.id}`).pipe(
                map(payload => makeAction({ result: SearchActions.apply_user_search_filter.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-searchfilter-apply-error' })
            )
        )
    );

    @Effect() updateOrderUserSearchFilter = this.actions$.pipe(
        ofType(SearchActions.update_order_user_search_filter.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.post(`${updateSearchFilterOrderUrl}`, action.payload.map(entry => entry.id)).pipe(
                map(payload => makeAction({ result: SearchActions.update_order_user_search_filter.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-searchfilter-save-error' })
            )
        )
    );

    @Effect() updateTitleUserSearchFilter = this.actions$.pipe(
        ofType(SearchActions.update_title_user_search_filter.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.post(`${updateSearchFilterTitleUrl}`, action.payload).pipe(
                map(result => makeAction({ result: SearchActions.update_title_user_search_filter.name, payload: result })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-searchfilter-save-error' })
            )
        )
    );

    @Effect() deleteUserSearchFilter = this.actions$.pipe(
        ofType(SearchActions.delete_user_search_filter.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.delete(`${deleteSearchFilterUrl}${action.payload}`).pipe(
                map(result => makeAction({ result: SearchActions.delete_user_search_filter.name, payload: action.payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-searchfilter-delete-error' })
            )
        )
    );
    @Effect() unDeleteUserSearchFilter = this.actions$.pipe(
        ofType(SearchActions.undelete_user_search_filter.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.post(`${unDeleteSearchFilterUrl}`, action.payload).pipe(
                switchMap(result => this.slxHttp.get(userSearchFiltersUrl).pipe(
                    map(payload => makeAction({ result: SearchActions.undelete_user_search_filter.name, payload })),
                    catchError(catchErrorObservable(SearchActions.search_error.name, 'rech-searchfilter-load-error',
                        payload => ({ payload }), { icon: 'filter_list', duration: 5 }))
                )
                )
            )
        )
    );

    @Effect() saveUserSearchFilter = this.actions$.pipe(
        ofType(SearchActions.save_user_search_filter.name),
        switchMap((action: SlxAction) => this.slxHttp.post(`${saveSearchFilterUrl}`, action.payload)),
        switchMap(result => this.slxHttp.get(userSearchFiltersUrl)
            .pipe(
                map(payload => ({ type: SearchActions.user_search_filters.name, payload })),
                catchError(catchErrorObservable(SearchActions.search_error.name, 'rech-searchfilter-save-error',
                    payload => ({ payload }), { icon: 'filter_list', duration: 5 }))
            )
        )
    );

    @Effect() overwriteUserSearchFilter = this.actions$.pipe(
        ofType(SearchActions.overwrite_user_search_filter.name),
        switchMap((action: SlxAction) => this.slxHttp.post(`${overwriteSearchFilterUrl}`, action.payload)
            .pipe(
                map(result => ({ type: SearchActions.apply_user_search_filter.name, payload: { id: result.id } })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'rech-searchfilter-save-error' })
            )
        )
    );

    @Effect() reloadLanguageDependentData = this.actions$.pipe(
        ofType(AccountActions.reload_language_dependent_data_rech.name),
        withLatestFrom(this.searchService.store.select('recherche') as Observable<RechercheState>),
        concatMap(([action, state]) =>
            this.refreshLaws(state).map(payload => this.reloadLawsData(payload)))
    );

    @Effect() getNewsConfigById = this.actions$.pipe(
        ofType(SearchActions.get_news_config_by_id.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.get(`${newsConfigurationUrl}?newsID=${action.payload}`).pipe(
                map(payload => makeAction({ result: SearchActions.get_news_config_by_id.name, payload })),
                catchErrorForAction(action, {})
            )
        )
    );

    private refreshLaws(recherche): AssetLawInfo[] {
        const lawAssetInfosSecondary = this.lawAssetInfosByTabState(recherche.secondaryTabState.assetTabs, false);
        const lawAssetInfosPrimary = this.lawAssetInfosByTabState(recherche.primaryTabState.assetTabs, true);

        return lawAssetInfosSecondary.concat(lawAssetInfosPrimary) || [];
    }

    private lawAssetInfosByTabState(assetTabs: AssetTab[], isPrimary: boolean): AssetLawInfo[] {
        if (assetTabs) {
            return assetTabs.filter(at => this.assetService.getCachedAsset(at.assetRef) && this.assetService.getCachedAsset(at.assetRef).type === AssetType.law)
                .map(t => this.assetService.getCachedAsset(t.assetRef))
                .map(a => this.lawAssetInfoFactory(a, isPrimary));
        }
        return [];
    }

    private lawAssetInfoFactory(asset: Asset, isPrimary: boolean): AssetLawInfo {
        const lawContent = asset.content as LawContent;
        const lawAssetInfo: AssetLawInfo = {
            id: asset.id,
            primary: isPrimary,
            lawGuid: lawContent.lawGuids.lawId,
            aolGuid: lawContent.lawGuids.aolId,
        };
        return lawAssetInfo;
    }

    private reloadLawsData(payload: AssetLawInfo) {
        const shouldOpenInPrimary = payload.primary;
        const assetID = payload.aolGuid && payload.aolGuid !== '00000000-0000-0000-0000-000000000000' ? payload.aolGuid : payload.lawGuid;
        return { type: this.assetService.actions.update_asset_law.name, payload: { assetID, openInPrimary: shouldOpenInPrimary, lawID: payload.lawGuid, aolID: payload.aolGuid } };
    }

    constructor(private slxHttp: SlxHttp, private searchService: SearchService, private actions$: Actions, private location: Location, private assetService: AssetService, private translateService: TranslateService) { }
}
