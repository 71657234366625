export enum SearchType {
    'Retrieval' = 0,
    'EuRetrieval' = 1,
    'Journal' = 2,
    'CaseCollection' = 3,
    'NotPublishedCaseCollection' = 4,
    'Commentary' = 5,
    'Book' = 6,
    'Author' = 7,
    'Law' = 8,
    'EuCaseReference' = 9,
    'EuTreaty' = 10,
    'EuInternationalAgreement' = 11,
    'EuLegislation' = 12,
    'EuCelexNumber' = 13,
}

