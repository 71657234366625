import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

import { PublicationSelectDialogComponent } from '../../../../recherche/publication-select-dialog/publication-select-dialog.component';

@Component({
  selector: 'slx-registration-business-dialog',
  templateUrl: './registration-business-dialog.component.html',
  styleUrls: ['./registration-business-dialog.component.scss'],
})

export class RegistrationBusinessDialogComponent {

    optionBusinessFn;

    constructor( @Inject(MAT_DIALOG_DATA) public data: { optionBusinessFn: Function}, public dialogRef: MatDialogRef<PublicationSelectDialogComponent>, private router: Router) {
        this.optionBusinessFn = data.optionBusinessFn;
    }
    public optionSelected(mode: string) {
        switch (mode) {
            case 'business':
                this.dialogRef.close();
                this.optionBusinessFn();
                break;
            case 'uni':
                this.dialogRef.close();
                this.router.navigate(['service/students']);
                break;
            case 'rak':
                this.dialogRef.close();
                this.router.navigate(['service/lawexamcandidates/rak-subscription']);
                break;
        }
    }
  }
