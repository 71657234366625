import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { LoginService } from '../../access';
import { AccountService } from '../../account';
import { emailValidator, phoneFaxNumberValidator, requiredValidator, zipCodeValidator } from '../../field';
import { markFormAsTouched } from '../../utility/utilityFunctions';
import { CustomerProfileActions } from '../customerProfileActions';

@Component({
    selector: 'slx-customerdata',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit, OnDestroy {

    private subscription: Subscription = new Subscription();

    public customerProfileForm: FormGroup;
    constructor(private router: Router, private formBuilder: FormBuilder, public accountService: AccountService, private loginService: LoginService) {
        this.customerProfileForm = this.formBuilder.group({
            name: ['', requiredValidator()],
            departmentName: [''],
            street: ['', requiredValidator()],
            postBox: [''],
            zipCode: ['', [requiredValidator(), zipCodeValidator('service-reg-comp-zip-notvalid-val-txt')]],
            city: ['', requiredValidator()],
            phone: ['', [requiredValidator(), phoneFaxNumberValidator('service-reg-comp-phone-notvalid-val-txt')]],
            fax: ['', phoneFaxNumberValidator('service-reg-comp-fax-notvalid-val-txt')],
            homepage: [''],
            email: ['', [requiredValidator(), emailValidator('service-reg-email-notvalid-val-txt')]],
        }
        );
    }

    onSubmit(customerProfileForm) {
        if (!customerProfileForm.valid) {
            markFormAsTouched(customerProfileForm);
            return;
        }
        this.accountService.dispatch({ save: CustomerProfileActions.save_customer_profile.name, payload: this.customerProfileForm.value });
    }

    ngOnInit() {
        this.subscription
            .add(this.loginService.isCorpAdmin.subscribe(right => {
                if (!right) {
                    this.router.navigate(['/customerprofile/userprofile']);
                }
            }))
            .add(this.accountService.customerProfileData.subscribe(data => {
                if (data) {
                    this.customerProfileForm.patchValue(data);
                }
            }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
