import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CellClickedEvent, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Observable, of, Subscription } from 'rxjs';

import { LoginService } from '../../../access';
import { AppActions } from '../../../appActions';
import { CustomerProfileActions } from '../../../customer-profile/customerProfileActions';
import { numberValidator, requiredValidator } from '../../../field';
import { CustomerProfileGoBackType } from '../../../models';
import { AlertType } from '../../../models/state/commonApp';
import { gridButtonRenderer, isEmtpyGuid, markFormAsTouched } from '../../../utility/utilityFunctions';
import { AddNewUserComponent } from '../../users/addNewUser/addNewUser.component';

import { AccountService } from './../../../account';
import { GridSwitchComponent } from './../../../field/gridSwitch/gridSwitch.component';

@Component({
    selector: 'slx-single-department',
    templateUrl: './singleDepartment.component.html',
    styleUrls: ['./singleDepartment.component.scss'],
})
export class SingleDepartmentComponent implements OnInit, OnDestroy {

    private subscription: Subscription = new Subscription();
    private showOnlyActiveUsers = true;
    private gridApi: GridApi;
    private newUserEmail: string;

    public departmentForm: FormGroup;
    public inactiveUsersExist = true;
    public singleDepartmentData: any = {};
    public portalUserDepartmentId: string;
    public portalUserId: string;
    public departementID: string;
    public shownUsersObservable: Observable<any[]>;
    public gridOptions: GridOptions;
    public columnDefs: any[];
    companyID: any;

    constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, public accountService: AccountService, private router: Router, private loginService: LoginService, private translate: TranslateService, private dialog: MatDialog) {

        this.onGridReady = this.onGridReady.bind(this);
        this.departementID = route.snapshot.params['id'];

        this.departmentForm = this.formBuilder.group({
            name: ['', requiredValidator()],
            isActivated: [''],
            creditLimit: ['', numberValidator('service-reg-comp-nol-notvalid-val-txt')],
        });

        this.gridOptions = <GridOptions>{
            enableColResize: true,
            rowHeight: 50,
            headerHeight: 50,
            rowSelection: 'single',
            suppressRowClickSelection: true,
            suppressContextMenu: true,
            suppressAnimationFrame: true,
            getRowNodeId: (data) => data.id,
            getRowClass: params => {
                if (params.data.addedUser === true) {
                    return 'row-added-user-animation';
                }
            },
            context: { translate: this.translate },
        };

        this.columnDefs = [
            { field: 'name', headerName: 'account-name', menuTabs: [], sort: 'asc', minWidth: 100 },
            { field: 'firstName', headerName: 'account-first-name', menuTabs: [], minWidth: 100 },
            { field: 'loginName', headerName: 'account-userid', menuTabs: [], minWidth: 140 },
            { field: 'isActivated', headerName: 'account-dept-enabled', menuTabs: [], cellRendererFramework: GridSwitchComponent, minWidth: 90, maxWidth: 90 },
            { field: 'edit', headerName: 'account-edit', menuTabs: [], cellRenderer: gridButtonRenderer, minWidth: 120, maxWidth: 120 },
            { field: 'send', headerName: 'account-users-send-login', menuTabs: [], cellRenderer: gridButtonRenderer, minWidth: 170, maxWidth: 170 },
        ];
    }

    ngOnInit() {
        this.accountService.dispatch({ fetch: CustomerProfileActions.load_departments.name });

        this.subscription
            .add(this.accountService.store.select(state => state.customerProfile.departmentsData ?
                state.customerProfile.departmentsData.find(dep => dep.id === this.departementID) : null).subscribe(data => {
                    if (data) {
                        this.singleDepartmentData = data;
                        this.shownUsersObservable = this.getUserToShow();
                        this.inactiveUsersExist = data.users ? data.users.filter(user => !user.isActivated).length > 0 : false;
                        this.departmentForm.controls['name'].setValue(data.name);
                        this.departmentForm.controls['isActivated'].setValue(data.isActivated);
                        this.departmentForm.controls['creditLimit'].setValue(data.creditLimit);
                    }
                }))
            .add(this.loginService.portalUserDepartmentId.subscribe(id => this.portalUserDepartmentId = id))
            .add(this.loginService.userId.subscribe(id => this.portalUserId = id));
    }

    public isGuidEmpty() {
        return isEmtpyGuid(this.departementID);
    }

    public onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
    }

    public onQuickFilterChanged(value): void {
        this.gridApi.setQuickFilter(value);
    }

    private getUserToShow() {
        if (this.singleDepartmentData.users !== null) {
            //const users = this.singleDepartmentData.users.map(u => ({ ...u, company: { ...u.company, id: this.singleDepartmentData.company.id }}));
            const users = this.singleDepartmentData.users;
            return this.showOnlyActiveUsers ? of(users.filter(u => u.isActivated)) : of(users);
        }
        else {
            return of(null);
        }
    }

    public addNewUser() {

        const dialogRef = this.dialog.open(AddNewUserComponent, {
            width: '1000px', height: '600px', panelClass: 'add-new-user',
            data: this.departementID,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.newUserEmail = result.email;
                this.accountService.dispatch({ fetch: CustomerProfileActions.load_departments.name });
            }
        });
    }

    public rowDataChanged() {
        if (this.newUserEmail) {
            const addedUser = this.singleDepartmentData.users.find(user => user.email === this.newUserEmail);
            if (addedUser) {
                const addedUserNode = this.gridApi.getRowNode(addedUser.id);
                this.gridApi.ensureIndexVisible(addedUserNode.rowIndex, 'top');
                addedUserNode.setData({ ...addedUserNode.data, addedUser: true });
                this.newUserEmail = undefined;

                setTimeout(() => {
                    addedUserNode.setData({ ...addedUserNode.data, addedUser: false });
                }, 6000);
            }
        }
    }

    public toggleUsersToShow(event) {
        this.showOnlyActiveUsers = !event.checked;
        this.shownUsersObservable = this.getUserToShow();
    }

    public portalUserLoginSendAll() {
        this.accountService.dispatch({ fetch: CustomerProfileActions.send_all_login_user.name, payload: this.departementID });
    }

    public onCellClicked($event: CellClickedEvent) {

        switch ($event.colDef.field) {
            case 'edit':
                this.router.navigate(['customerprofile/users/singleuser', $event.data.id], { queryParams: { goBack: CustomerProfileGoBackType.SingleDepartment, departmentID: this.departementID } });
                break;

            case 'isActivated':
                if ($event.data.id === this.portalUserId) {
                    const currentRowNode = $event.api.getRowNode($event.data.id);
                    $event.api.redrawRows({ rowNodes: [currentRowNode] });
                    this.accountService.dispatch({ type: AppActions.alert.name, payload: { type: AlertType.Warning, key: 'account-not-disable', duration: 10 } });
                } else {
                    this.accountService.dispatch({ fetch: CustomerProfileActions.activate_or_deactivate_user.name, payload: $event.data });
                }
                break;

            case 'send':
                this.accountService.dispatch({ fetch: CustomerProfileActions.send_login_user.name, payload: $event.data });
                break;
        }
    }

    onSubmit(departmentForm) {
        if (!departmentForm.valid) {
            markFormAsTouched(departmentForm);
            return;
        }

        const departmentFormData = {
            company: this.singleDepartmentData.company,
            id: this.departementID,
            ...departmentForm.value,
        };
        this.accountService.dispatch({ save: CustomerProfileActions.save_single_department_data.name, payload: departmentFormData });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
