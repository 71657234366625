import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { NewsService } from '../news.service';

@Component({
    selector: 'slx-wizard-journal',
    templateUrl: './wizard-journal.component.html',
    styleUrls: ['./wizard-journal.component.scss'],
})
export class WizardJournalComponent implements OnInit {

    @Input() form: FormGroup;
    @ViewChild('radioList') radioList: ElementRef;

    constructor(public newsService: NewsService) { }

    ngOnInit(): void {
        if (this.publicationID.value !== '') {
            setTimeout(() => { document.getElementById(`${this.publicationID.value}`).scrollIntoView({ behavior: 'smooth' }); });
        }
    }

    get publicationID(): FormControl {
        return this.form.get('publicationID') as FormControl;
    }
    public searchJournals(event, value): void {
        const filterValue = value.toUpperCase();
        const elems = Array.from(this.radioList.nativeElement.querySelectorAll('mat-radio-button')).map((el: HTMLElement) => el);
        elems.forEach(el => el.style.display = 'block');

        const filtered = elems.filter(el => !(el.innerText.toUpperCase().indexOf(filterValue) > -1));
        filtered.forEach(el => el.style.display = 'none');
    }
}
