export enum AutocompleteType {
    RetrievalSearchText = 1,
    RetrievalLaw = 2,
    RetrievalArticleNumber = 3,
    RetrievalReference = 4,
    RetrievalAuthor = 5,

    JournalAbbreviation = 6,
    JournalYearOrVolume = 7,
    JournalIssue = 8,
    JournalPagesOrSequenceNumber = 9,

    CaseCollectionPubAbbreviation = 10,
    CaseCollectionPubYear = 11,
    CaseCollectionPubPage = 12,
    CaseCollectionNotPubCaseNumber = 13,

    CommentarySerial = 14,
    CommentaryTitle = 15,
    CommentaryLaw = 16,
    CommentaryArticle = 18,

    BookSerial = 19,
    BookTitle = 20,
    BookAuthor = 21,
    BookPage = 22,

    AuthorName = 23,
    AuthorTitle = 24,

    LawLaw = 25,
    LawLawTitle = 26,
    LawArticleNumber= 27,

    EuCaseReference = 28,
    EuIntAgreement = 29,
    EuLegislationYear = 30,
    EuLegislationNumber = 31,
    EuCelexNUmber = 32,

    StatisticsDepartment = 33,
    StatisticsUser = 34,
}
