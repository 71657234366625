import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

import { apiBaseUrl } from './../../../config';


@Component({
    selector: 'slx-about-direction',
    templateUrl: './about-direction.component.html',
    styleUrls: ['./about-direction.component.scss'],
})

export class AboutDirectionComponent implements AfterViewInit {

    @ViewChild('gmap') public gmap: ElementRef;

    private map: google.maps.Map;
    private markerZh: google.maps.Marker;
    private markerGe: google.maps.Marker;
    private infoWindowZh: google.maps.InfoWindow;
    private infoWindowGe: google.maps.InfoWindow;

    private locationZh: google.maps.LatLngLiteral = { lat: 47.4153558, lng: 8.5456253 };
    private locationGe: google.maps.LatLngLiteral = { lat: 46.2091817, lng: 6.1435331 };

    private contentZh = `<h4>Swisslex AG</h4><p>Schaffhauserstrasse 418<br/>8050 Zürich <br/>Tel. +41 (0) 44 365 32 32</p>`;
    private contentGe = `<h4>Swisslex SA</h4><p>Rue du Mont-Blanc 21<br/>1201 Genève<br/>'Tel. +41 (0) 22 909 27 40</p>`;

    private icon = `${apiBaseUrl}/Content/ImagesNew/sl-marker.png`;

    private styles: Array<google.maps.MapTypeStyle> = [
        { 'featureType': 'landscape', 'elementType': 'geometry', 'stylers': [{ 'color': '#d7e2eb' }, { 'lightness': 28 }] },
        { 'featureType': 'poi', 'elementType': 'geometry', 'stylers': [{ 'color': '#919fb8' }, { 'saturation': 17 }, { 'lightness': 66 }] },
        { 'featureType': 'road', 'elementType': 'geometry', 'stylers': [{ 'color': '#ffffff' }] },
    ];

    private mapProps = {
        center: this.locationZh,
        zoom: 14,
        styles: this.styles,
    };

    constructor() { }

    ngAfterViewInit() {

        if (!(window['google'] && window['google'].maps)) {
            const head = document.getElementsByTagName('head')[0];
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBQOfF0k_KtejC6b1CXGFRZVan5MuT3yCE&callback=slxInitDirectionGoogleMap';
            head.appendChild(script);

            window['slxInitDirectionGoogleMap'] = this.createMap.bind(this);
        } else {
            this.createMap();
        }
    }

    private createMap() {
        this.map = new google.maps.Map(this.gmap.nativeElement, this.mapProps);
        this.markerZh = new google.maps.Marker({ position: this.locationZh, icon: this.icon, map: this.map });
        this.markerGe = new google.maps.Marker({ position: this.locationGe, icon: this.icon, map: this.map });
        this.infoWindowZh = new google.maps.InfoWindow({ content: this.contentZh });
        this.infoWindowGe = new google.maps.InfoWindow({ content: this.contentGe });
        this.markerZh.addListener('click', () => this.infoWindowZh.open(this.map, this.markerZh));
        this.markerGe.addListener('click', () => this.infoWindowGe.open(this.map, this.markerGe));
    }

    public getMarker(num: number) {
        this.map.setCenter(num === 1 ? this.locationGe : this.locationZh);
    }
}
