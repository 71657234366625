import { Component } from '@angular/core';

import { AccountService } from '../../../account';
import { AboRegistrationService } from '../abo-services.service';
import { AboServicesActions } from '../abo-servicesActions';

@Component({
  selector: 'slx-registration-law-exam-candidates',
  template: `<router-outlet></router-outlet>`,
  styles: [``],
})
export class RegistrationLawExamCandidatesComponent {

  constructor(public aboRegistrationService: AboRegistrationService, private accountService: AccountService) {}

  ngOnInit() {
    this.aboRegistrationService.dispatch({ save: AboServicesActions.set_badmail.name});
  }
}
