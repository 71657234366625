import { Location } from '@angular/common';

import { LanguageEnum } from './enums';

export const supportedLanguages = [LanguageEnum.De, LanguageEnum.Fr].map(lng => LanguageEnum[lng].toLowerCase());

/**
 * Takes a given relativeUrl (may contain a querystring) and splits it in its relevant components
 * @param relativeUrl a url with a querystring (if it isn't seperately provided through queryString param)
 * @param queryString a query string; if this is provided, relativeUrls must NOT contain a queryString!
 * @param fragment a query fragment at the end of the url, separated by #; if this is provided, relativeUrls must NOT contain a fragment!
 */
export function getLanguageIndependantUrl(relativeUrl: string, queryString?: string, fragment?: string) : {
    /**
     * relative url without the languagepart (from supportedLanguages) nor the queryString, properly prefixed with /
     */
    relativeUrl: string;
    /**
    * relative url parts without empty entries at begining
    */
    relativeUrlParts: string[];
    /**
     * the provided query string, normalized with a trailing ?
     */
    queryString: string;
    /**
     * transformed querystring object based on key value pairs like key1=value1&key2=value2 => { key1: value1, key2: value2 }
     */
    queryStringObject: any;
    /**
     * the fragment if available, WITHOUT the hashtag (#example => example), otherwise null
     */
    fragmentString: string;
    /**
     * the relativeUrl with the queryString if provided
     */
    relativeUrlAndQueryString: string;
    /**
     * the detected language in the url (only detects languages from supportedLanguages )
     */
    urlLanguage: string;
} {
    let fragmentString = null;
    if(!fragment){
        const matches = relativeUrl.match(/(\#.*)$/gi);
        if(matches != null && matches.length > 0){
            fragmentString = matches[0].slice(1);
            relativeUrl = relativeUrl.replace(matches[0],'');
        }
        else {
            fragmentString = null;
        }
    }
    if(fragment){
        fragmentString = fragment.startsWith('#') ? fragment.slice(1) : fragment;
    }

    if(!queryString) {
        const matches = relativeUrl.match(/(\?.*)/gi);
        if(matches != null && matches.length > 0){
            queryString = Location.normalizeQueryParams(matches[0]);
            relativeUrl = relativeUrl.replace(matches[0],'');
        }
        else {
            queryString = '';
        }
    } else{
        queryString = Location.normalizeQueryParams(queryString);
    }
    const queryStringObject = {};
    if(queryString) {
        const keyvaluePairs = queryString.slice(1).split('&');
        keyvaluePairs.forEach(x => {
            if(x){
                const pairParts = x.split('=');
                queryStringObject[pairParts[0]] = pairParts.slice(1).join('=');
            }
        });
    }
    const urlPartsWithoutEmptyString = relativeUrl.split('/').filter(x => x !== '');
    let containedLanguage = null;

    if(urlPartsWithoutEmptyString.length > 0 && supportedLanguages.includes(urlPartsWithoutEmptyString[0].toLowerCase())) {
        containedLanguage = urlPartsWithoutEmptyString.shift();
    }
    const result = '/' + urlPartsWithoutEmptyString.join('/');
    return {
        relativeUrl: result,
        relativeUrlParts: urlPartsWithoutEmptyString,
        queryString: queryString,
        queryStringObject,
        fragmentString,
        relativeUrlAndQueryString: relativeUrl + queryString,
        urlLanguage: containedLanguage,
    };
}
