import { AfterContentInit, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Force, forceCollide, ForceLink, forceLink, forceManyBody, forceRadial, forceSimulation, scaleLinear, scaleQuantize, selectAll, Simulation, SimulationLinkDatum, SimulationNodeDatum } from 'd3';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

import { SlxHttp, topDocumentsHomeGraphUrl } from '../../access';
import { getAssetURL } from '../../config';
import { AssetDisplayType, AssetRef, AssetRefOptions, SourceDetail } from '../../models';
import { isResponsive } from '../../utility/utilityFunctions';

const debug = require('debug')('graph');

export interface GraphPracticeArea {
  assetType: number;
  isSingleAssetClassification: boolean;
  id: string;
  level: number;
  classificationID: string;
  practiceAreaID: string;
}
export interface GraphDoc {
  docAssetID: string;
  documentType: number;
  docTitle: string;
  docAuthor: string;
  docReference: string;
  publicationAssetID: string;
  publicationType: number;
  publicationTitle: string;
  viewCounter: number;
  onlinePublicationDate: string;
  logoUrl: string;
  tocImageUrl: string;
  practiceAreas: GraphPracticeArea[];
}
export interface GraphData {
  documents: GraphDoc[];
  practiceAreaGroups: {
    isRoot: boolean;
    numberSelected: number;
    id: string;
    description: string;
    code: string;
    counter: number;
    isSelected: boolean;
    practiceAreas: {
      id: string;
      description: string;
      code: string;
      counter: number;
      isSelected: boolean;
    }[],
  }[];
}

export interface PracticeAreaGroupInformation {
  assets: Map<string, GraphDoc>;
  isRoot: boolean;
  id: string;
  description: string;
  code: string;
}
export interface PracticeAreaInformation {
  id: string;
  description: string;
  code: string;
  assets: Map<string, GraphDoc>;
  parentGroup: PracticeAreaGroupInformation;
}

export interface PracticeAreaNode {
  id: string;
  description: string;
  isPracticeArea: boolean;
  childPracticeAreas: Array<any>;
  code: string;
  assetCount: number;
}
export interface Graph {
  links: Link[];
  nodes: Node[];
}
export interface Node extends SimulationNodeDatum {
  id: string;
  //links: Link[];
  rawDoc: GraphDoc;
  rawPracticeArea: any; //TODO adapt type
  title: string;
  logoUrl: string;
  tocImageUrl: string;
  code: string;
  views: number;
  isPracticeArea: boolean;
  description: string;
  assetCount: number;
  assetNodes: Node[];
  mainPracticeArea: Node;
  hasPrimaryAssets: boolean;
}
export interface Link extends SimulationLinkDatum<Node> {
  source: string | Node;
  target: string | Node;
  level: number;
}
@Component({
  selector: 'slx-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
})

export class GraphComponent implements AfterContentInit, OnDestroy {

  public graphData: GraphData;
  private showViewer: BehaviorSubject<any> = new BehaviorSubject(null);
  public viewInfo = this.showViewer.asObservable();

  public dataIsLoaded = false;
  public hasError = false;

  private practiceAreaMap = new Map<string, PracticeAreaInformation>();
  private practiceAreaGroupMap = new Map<string, PracticeAreaGroupInformation>();
  private practiceAreaNodes: Array<Node> = [];
  private docNodes: Array<Node> = [];

  private nodes: Array<Node> = [];
  private links: Array<Link> = [];

  public simulation;

  private graphStopTimeout;
  private subscription;

  constructor(private slxHttp: SlxHttp, private translate: TranslateService, private router: Router) { }

  ngAfterContentInit() {
    if (!isResponsive()) {
      this.getData(); //TODO move loading to init and keep display on aftercontentinit
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.graphStopTimeout) {
      clearTimeout(this.graphStopTimeout);
    }
    if (this.simulation) {
      this.simulation.stop();
    }
  }

  public openAsset(node: Node, event) {
    this.router.navigate([getAssetURL([AssetDisplayType.UnknownDocument, node.id, AssetRefOptions.sourceDetail, SourceDetail.DocumentGraphLink], this.translate.currentLang)]);
  }

  private getData() {
    this.subscription =
      //this.slxHttp.get('/api/Graph/GetTopDocuments?language=de&dateFrom=2018-10-03&dateUntil=2018-10-11&topAssetCount=60', true)
      //this.slxHttp.get('/api/Graph/GetTopDocuments?language=de&dateFrom=2018-01-01&dateUntil=2018-01-08&topAssetCount=60', true)
      this.slxHttp.get(`${topDocumentsHomeGraphUrl}?language=${this.translate.currentLang}`, false) //TODO use this line for go live
        .pipe(first())
        .subscribe(result => {
          debug('result', result);
          this.graphData = result;
          setTimeout(() => {
            try {
              this.prepGraphData();
              this.dataIsLoaded = true;
              this.run();
            } catch (ex) {
              if (this.simulation) {
                this.simulation.stop();
              }
              this.hasError = true;
              throw ex;
            }
          }, 1500);
        });
  }

  private generateLinking(doc: GraphDoc) {
    const practiceAreas: Array<any> = doc.practiceAreas;
    const paMap = new Map<string, any>();
    practiceAreas.forEach(pa => {
      const entry = this.practiceAreaMap.get(pa.practiceAreaID);
      if (entry) {
        entry.assets.set(doc.docAssetID, doc);
        const parentGroup = entry.parentGroup;
        paMap.set(parentGroup.id, { groupID: parentGroup.id, level: pa.level, code: parentGroup.code });
        parentGroup.assets.set(doc.docAssetID, doc);
      } else {
        debug('notFound', pa.practiceAreaID);
      }
    });
    return paMap;
  }

  private prepGraphData() {
    // Create a Map that has practiceAreaID as key, value is corresponding information, refer to PracticeAreaInformation Interface
    this.graphData.practiceAreaGroups.forEach(practiceAreaGroup => {
      const pag = {
        id: practiceAreaGroup.id,
        code: practiceAreaGroup.code,
        description: practiceAreaGroup.description,
        isRoot: practiceAreaGroup.isRoot,
        assets: new Map<string, GraphDoc>(),
      };
      this.practiceAreaGroupMap.set(practiceAreaGroup.id, pag);
      practiceAreaGroup.practiceAreas.forEach(practiceArea => {
        this.practiceAreaMap.set(practiceArea.id,
          {
            id: practiceArea.id,
            description: practiceArea.id,
            code: practiceArea.code,
            assets: new Map<string, GraphDoc>(),
            parentGroup: pag,
          });
      });
    });

    // create docNodes
    this.graphData.documents.forEach(doc => {
      const docLinks = this.generateLinking(doc);
      // Only push to Array if Doc has References // Only calculate Classification if there are references
      if (docLinks.size > 0) {
        let firstLevelEntry = Array.from(docLinks.values()).find(entry => entry.level === 1);
        if (!firstLevelEntry) {
          firstLevelEntry = Array.from(docLinks.values()).find(entry => entry.level === 2);
          if (firstLevelEntry) {
            firstLevelEntry.level = 1; // pretend there is one level 1 link if there are only level 2 links
          }
        }
        const mainGroupCode = firstLevelEntry.code;

        // prevent assets from having multiple primary pas by downgrading all other to level 2
        Array.from(docLinks.values()).filter(entry => entry.level === 1 && entry.code !== mainGroupCode).forEach(element => {
          element.level = 2;
        });

        const linksForDoc: Array<Link> = [];
        docLinks.forEach(element => {
          // if(element.level === 1) {
          linksForDoc.push({ source: doc.docAssetID, target: element.groupID, level: element.level });
          // }
        });
        this.links.push(...linksForDoc);

        const node: Node = {
          id: doc.docAssetID,
          title: doc.docTitle,
          logoUrl: doc.logoUrl,
          tocImageUrl: doc.tocImageUrl,
          code: mainGroupCode,
          views: doc.viewCounter,
          rawDoc: doc,
          isPracticeArea: false,
          rawPracticeArea: null,
          description: null,
          assetCount: 0,
          mainPracticeArea: null,
          hasPrimaryAssets: null,
          assetNodes: null,
        };
        this.docNodes.push(node);
      } else {
        debug('unexpected doc', doc, docLinks);
      }
    });

    // Create practiceAreaNodes
    this.graphData.practiceAreaGroups.forEach(paGroup => {
      const pa: Node = {
        id: paGroup.id,
        description: paGroup.description,
        isPracticeArea: true,
        rawPracticeArea: paGroup,
        rawDoc: null,
        title: null,
        logoUrl: null,
        tocImageUrl: null,
        views: null,
        code: paGroup.code,
        assetCount: 0,
        assetNodes: new Array<Node>(),
        mainPracticeArea: null,
        hasPrimaryAssets: null,
      };
      this.practiceAreaNodes.push(pa);
    });

    // Remove PracticeAreaNodes that have no linking
    this.practiceAreaNodes = this.practiceAreaNodes
      .filter(pa => this.links.find(link => link.target === pa.id || link.source === pa.id))
      .map(pan => {
        const pa = this.practiceAreaGroupMap.get(pan.id);
        pan.assetCount = pa.assets.size;
        pa.assets.forEach(x => {
          pan.views += x.viewCounter;
          const assetNode = this.docNodes.find(dn => dn.id === x.docAssetID);
          if (assetNode) {
            assetNode.mainPracticeArea = pan;
            pan.assetNodes.push(assetNode);
          }
        });
        pan.hasPrimaryAssets = this.links.find(link => (link.target === pa.id || link.source === pa.id) && link.level === 1) != null;
        return pan;
      });

    this.nodes.push(...this.docNodes);
    this.nodes.push(...this.practiceAreaNodes);

    //solves issue where change detection was triggered all the time:
    //https://stackoverflow.com/questions/38995262/how-to-disable-angular2-change-detection-for-3rd-party-libraries/39626378#39626378
    // this.zone.OutsideAngular(() => this.());
  }

  private run() {

    const graph: Graph = { links: this.links, nodes: this.nodes };

    const svg = selectAll('svg').filter('.graph');
    const width = Number(svg.attr('width'));
    const height = Number(svg.attr('height'));

    let minViews = 1000000, maxViews = 0;
    this.docNodes.forEach(x => {
      const amount = x.views;
      minViews = Math.min(amount, minViews);
      maxViews = Math.max(amount, maxViews);
      debug('doc', amount);
    });

    let minViewsPerPracticeArea = 1000000, maxViewsPerPracticeArea = 0;
    this.practiceAreaGroupMap.forEach(x => {
      let amount = 0;
      x.assets.forEach(a => amount += a.viewCounter);
      minViewsPerPracticeArea = Math.min(amount, minViewsPerPracticeArea);
      maxViewsPerPracticeArea = Math.max(amount, maxViewsPerPracticeArea);
      debug('pa', amount);
    });

    let base = 8, pow = 1.3;
    const scaleViewsPerDocument = scaleLinear()
      .domain([minViews, maxViews])
      .range([0, 100]);
    //.range([base * Math.pow(pow, 0), base * Math.pow(pow, 1), base * Math.pow(pow, 2), base * Math.pow(pow, 3)]);

    base = 8, pow = 2;
    const scaleViewsPerPracticeArea = scaleLinear()
      .domain([minViewsPerPracticeArea, maxViewsPerPracticeArea])
      .range([0, 100]);
    //.range([base * Math.pow(pow, 0), base * Math.pow(pow, 1), base * Math.pow(pow, 2), base * Math.pow(pow, 3)]);

    const slxGraphApiFactory = (function (oiergoerituoi?: Node) {
      const clickedNode = oiergoerituoi;
      let filtered;
      if (clickedNode) {
        filtered = graph.links.filter(l => clickedNode.id === (<Node>l.source).id || clickedNode.id === (<Node>l.target).id);
      }
      const api = {
        layout: {
          node: {
            size: function (node: Node) {
              if (clickedNode) {
                let factor = 1;
                if (!filtered.find(l => (<Node>l.source).id === node.id || (<Node>l.target).id === node.id)) {
                  factor = 1.15;
                } else {
                  factor = 1 / 1.15;
                }
                return nodeRadius(node, clickedNode, filtered) / factor;
              }
              else {
                if (node.isPracticeArea) {
                  return 10 + (10 * scaleViewsPerPracticeArea(node.views) / 100);
                }
                return 12; //10 + (10 * scaleViewsPerDocument(node.views) / 100);
              }
            },
            cursor: function (node: Node) {
              return 'pointer';
            },
            stroke: function (node: Node) {
              if (node.isPracticeArea) {
                return 'gray';
              }
              return 'white';
            },
            strokeWidth: function (node: Node) {
              return '1px';
            },
            fill: function (node: Node) {
              let nodeColor = '#000000';

              if (node.isPracticeArea) {
                nodeColor = '#ffffff';
              }
              else {
                nodeColor = color(Number(node.code));
              }

              const c = hexToRgb(nodeColor);
              if (clickedNode) {
                if (!filtered.find(l => (<Node>l.source).id === node.id || (<Node>l.target).id === node.id)) {
                  const factor = 0.3;
                  c.r = Math.round(c.r + (255 - c.r) * factor);
                  c.g = Math.round(c.g + (255 - c.g) * factor);
                  c.b = Math.round(c.b + (255 - c.b) * factor);
                }
              }

              return `rgb(${c.r},${c.g},${c.b})`;
            },
          },
          link: {
            stroke: function (link: Link) {
              if (clickedNode) {
                return clickedNode.id === (<Node>link.source).id || clickedNode.id === (<Node>link.target).id ? '#275699' : 'lightgrey';
              }
              return link.level === 1 ? 'lightgrey' : 'lightgrey';
            },
            strokeWidth: function (link: Link) {
              if (clickedNode) {
                return clickedNode.id === (<Node>link.source).id || clickedNode.id === (<Node>link.target).id ? '1px' : link.level === 1 ? 1 : 0;
              }
              return link.level === 1 ? 1 : 0;
            },
          },
        },
        forces: {
          link: function (link: Link) {
            return link.level === 1 ? 1 : 0;
          },
          radial: function (x: Node) {
            // if the practiceArea has no primary link of its own, randomly move it next to the first asset
            if (x.isPracticeArea && !x.hasPrimaryAssets) {
              x = x.assetNodes[0];
            }
            const radius = Math.min(width - 100, height - 100) / 2;
            const innerRadiusSpacing = 80;
            return innerRadiusSpacing + (radius / 2 / 100 * scaleViewsPerPracticeArea(x.isPracticeArea ? x.views : x.mainPracticeArea.views));
          },
          collide: function (d: Node) {
            let factor = 1.4;
            if (d.isPracticeArea) {
              factor = 2;
            }
            return api.layout.node.size(d) * factor;
          },
          manyBody: function (n: Node) {
            return -60;
          },
        },
        utils: {
          nodeTitle: function (node: Node) {
            return `${(node.title || node.description)}`;
          },
          preventOverflow: function () {
            const maxRadius = 20;
            node
              .attr('cx', d => Math.max((-1 * width / 2) + maxRadius, Math.min((width / 2) - maxRadius, (<Node>d).x)))
              .attr('cy', d => Math.max((-1 * height / 2) + maxRadius, Math.min((height / 2) - maxRadius, (<Node>d).y)));
            link
              .attr('x1', d => Math.max((-1 * width / 2) + maxRadius, Math.min((width / 2) - maxRadius, (<Node>d.source).x)))
              .attr('y1', d => Math.max((-1 * height / 2) + maxRadius, Math.min((height / 2 - maxRadius), (<Node>d.source).y)))
              .attr('x2', d => Math.max((-1 * width / 2) + maxRadius, Math.min((width / 2) - maxRadius, (<Node>d.target).x)))
              .attr('y2', d => Math.max((-1 * height / 2) + maxRadius, Math.min((height / 2) - maxRadius, (<Node>d.target).y)));
          },
        },
      };
      return api;
    });

    function hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      } : null;
    }

    const slxGraphApi = slxGraphApiFactory();
    const color = scaleQuantize<string>();
    color.domain([1, this.practiceAreaNodes.length]);
    color.range(['#91A5BE', '#2D5A95', '#348EC1', '#61AFD5', '#E4D342', '#23A156', '#4CBC59', '#85D2E3', '#A5CE44', '#B5E2F0', '#DDF4C6']);

    const link = svg.append('g')
      .attr('class', 'links')
      .selectAll('line')
      .data(graph.links)
      .enter().append('line')

      .attr('stroke-width', slxGraphApi.layout.link.strokeWidth)
      .attr('stroke', slxGraphApi.layout.link.stroke);

    const node = svg.append('g')
      .attr('class', 'nodes')
      .selectAll('circle')
      .data(graph.nodes)
      .enter().append('circle')

      .attr('r', slxGraphApi.layout.node.size)
      .attr('cursor', slxGraphApi.layout.node.cursor)
      .attr('stroke', slxGraphApi.layout.node.stroke)
      .attr('stroke-width', slxGraphApi.layout.node.strokeWidth)
      .attr('fill', slxGraphApi.layout.node.fill)
      .on('click', click.bind(this));

    node.append('title')
      .text(slxGraphApi.utils.nodeTitle);


    const svgGraph = document.querySelector('svg.graph');
    const svgGraphWrapper = document.querySelector('slx-graph');

    const resetGraph = (function () {
      this.showViewer.next(null);
      const api = slxGraphApiFactory();
      link
        .attr('stroke', api.layout.link.stroke)
        .attr('stroke-width', api.layout.link.strokeWidth);
      node
        .attr('r', api.layout.node.size)
        // .attr('opacity', api.layout.node.opacity)
        .attr('fill', api.layout.node.fill);
    }).bind(this);

    svgGraph.addEventListener('click', function (event: any) {
      if (event.target && event.target.nodeName === 'svg') {
        resetGraph();
        event.stopPropagation();
        return false;
      }
    });

    svgGraphWrapper.addEventListener('mouseleave', resetGraph);

    function click(clickedNode) {
      debug(clickedNode);
      if (!clickedNode) {
        clickedNode = {};
      }
      this.showViewer.next(clickedNode);

      const api = slxGraphApiFactory(clickedNode);
      link
        .attr('stroke', api.layout.link.stroke)
        .attr('stroke-width', api.layout.link.strokeWidth);


      node
        .attr('r', api.layout.node.size)
        .attr('fill', api.layout.node.fill);
    }
    function nodeRadius(node, clickedNode, filtered) {
      return slxGraphApi.layout.node.size(node);
    }

    const stopIt = (function () {
      debug('stopping simulation');
      this.simulation.stop();
    }).bind(this);



    debug(graph);

    this.simulation = forceSimulation();
    this.simulation.alphaDecay(0.3);
    this.simulation.nodes(graph.nodes);
    this.simulation.on('tick', slxGraphApi.utils.preventOverflow);
    this.simulation.force('radial', forceRadial<Node>(slxGraphApi.forces.radial).strength(1));
    this.simulation.force('charge', forceManyBody<Node>().strength(slxGraphApi.forces.manyBody));
    this.simulation.force('collide', forceCollide<Node>().radius(slxGraphApi.forces.collide));
    this.simulation.force('link', forceLink<Node, Link>().id(d => d.id).strength(slxGraphApi.forces.link).links(graph.links));

    if (document.hidden) {
      const fn = (function () {
        debug('stoping cause not visible');
        this.simulation.stop();
        debug('visibility changed, hidden:', document.hidden);
        if (!document.hidden) {
          debug('restarting simulation');
          this.simulation.restart();
          this.graphStopTimeout = setTimeout(stopIt, 5000);
          document.removeEventListener('visibilithistychange', fn);
        }
      }).bind(this);
      document.addEventListener('visibilitychange', fn);
    }
    else {
      this.graphStopTimeout = setTimeout(stopIt, 5000);
    }
  }
}
