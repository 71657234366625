import { Component, ElementRef, HostListener, Input, OnChanges } from '@angular/core';

import { apiBaseUrl } from '../../config';
import { AssetDisplayType, AssetRef, SourceDetail } from '../../models';
import { isEmtpyGuid } from '../../utility/utilityFunctions';
import { AssetService } from '../asset.service';

@Component({
    selector: 'slx-link-preview',
    templateUrl: './link-preview.component.html',
    styleUrls: ['./link-preview.component.scss'],
})
export class LinkPreviewComponent implements OnChanges {
    ngOnChanges(): void {
        //this.isLawCite = this.previewInformation && this.previewInformation.linkPreviewContent.find(l => l.type === 1);
        this.isSticky = false;
        if (this.previewInformation && this.previewInformation.linkPreviewContent) {

            if (this.previewInformation.linkPreviewContent.find(l => l.type === 1)) {
                this.isSticky = true;
                this.stickyTitleString = 'rech-asset-lawcitation';
            }

            if (this.previewInformation.linkPreviewContent.length > 1) {
                this.isSticky = true;
                this.stickyTitleString = 'rech-asset-multicitation';
            }

        }
    }

    @Input() previewInformation: any;
    isSticky: boolean;
    stickyTitleString: string;

    constructor(private assetService: AssetService, private containerRef: ElementRef) { }

    @HostListener('document:click', ['$event']) onClick(event): void {
        // Close Preview if Document is clicked somewhere but the Preview itself
        if (this.previewInformation && !this.containerRef.nativeElement.contains(event.target)) {
            this.assetService.cancelAssetPreview();
        }
    }


    getLawLogoSource(): string {
       return `${apiBaseUrl}/Content/LogoHits/Law.svg`;
    }

    getAssetLogoSource(assetDisplayType: AssetDisplayType) {
        return AssetDisplayType[assetDisplayType] === AssetDisplayType.CommentaryDocument ? `${apiBaseUrl}/Content/LogoHits/Commentary.svg` : `${apiBaseUrl}/Content/LogoHits/Book.svg`;
    }


    close() {
        this.assetService.cancelAssetPreview();
    }

    openAsset(item, event) {
        const ref = AssetRef.create(AssetDisplayType.UnknownDocument, item.previewTargetId, { source: SourceDetail.DocumentLink });
        this.assetService.openAsset(ref, !this.previewInformation.isPrimary, event);
        this.assetService.cancelAssetPreview();
    }

    openLaw(item, event) {
        const ref = isEmtpyGuid(item.aolId)
            ? AssetRef.create(AssetDisplayType.LawDocument, item.previewTargetId, { source: SourceDetail.DocumentLink })
            : AssetRef.create(AssetDisplayType.ArticleOfLawDocument, item.aolId, { source: SourceDetail.DocumentLink, publicationId: item.previewTargetId });
        this.assetService.openAsset(ref, !this.previewInformation.isPrimary, event);
        // Close Cite
        this.assetService.cancelAssetPreview();
    }

    openCommentary(commentary, event) {
        const ref = AssetRef.create(commentary.assetDisplayType, commentary.assetID, { source: SourceDetail.DocumentLink });
        this.assetService.openAsset(ref, !this.previewInformation.isPrimary, event);
        this.assetService.cancelAssetPreview();
    }


}
