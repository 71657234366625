module.exports = {
	"events-current": "Manifestations actuelles",
	"events-search": "Rechercher",
	"events-practiceAreas": "Domaines du droit",
	"events-add": "Ajouter",
	"events-current-date": "Date",
	"events-current-title": "Titre",
	"events-current-city": "Lieu",
	"events-current-practiceAreaList": "Domaines du droit",
	"events-search-title": "Rechercher dans manifestations",
	"events-search-filter": "Filtrer par domaines du droit",
	"events-filter": "Rechercher",
	"events-details": "Détails",
	"events-details-more": "Pour aller plus loin",
	"events-details-more-name": "Livres sur le sujet (lien externe)",
	"events-details-today": "(ayant lieu aujourd'hui)",
	"events-details-begin-date": "Début",
	"events-details-end-date": "Fin",
	"events-details-date": "Date",
	"events-details-venue": "Lieu",
	"events-details-city": "Ville",
	"events-practicearea": "Domaine juridique concerné",
	"events-details-back-to-overview": "Retour vers toutes les manifestations",
	"events-details-add-to-calender": "Ajouter au calendrier",
	"events-details-organization": "Organisateur",
	"events-details-organization-fr": "Organisateur (FR)",
	"events-details-link": "Lien",
	"events-details-link-fr": "Lien (FR)",
	"events-practiceareas-title": "Manifestations par domaines du droit",
	"events-register": "Inscrire une manifestation",
	"events-register-language": "Langue",
	"events-register-event-title": "Titre",
	"events-register-event-title-fr": "Titre (FR)",
	"events-register-city": "Ville",
	"events-register-city-fr": "Ville (FR)",
	"events-register-place": "Lieu / adresse",
	"events-register-common-data": "Informations générales",
	"events-register-event-data": "Dates de la manifestation",
	"events-register-contact": "Contact",
	"events-register-begin": "Début, p. ex.",
	"events-register-end": "Fin, p. ex.",
	"events-register-time-start": "Heure début, p. ex. 08:00",
	"events-register-time-end": "Heure fin, p. ex. 17:00",
	"events-register-date-missing": "Veuillez vérifier les dates",
	"events-collapse-list": "Réduire la liste",
	"events-register-practice-areas": "Domaines du droit concernés",
	"events-register-description-short": "Description concise",
	"events-register-description-short-fr": "Description concise (FR)",
	"events-register-description-detailled": "Description détaillée",
	"events-register-description-detailled-fr": "Description détaillée (FR)",
	"events-register-name": "Nom et prénom",
	"events-register-email": "Adresse électronique",
	"events-register-mandatory": "*Champs obligatoires",
	"events-register-german": "Allemand",
	"events-register-french": "Français",
	"events-register-both": "Bilingue",
	"events-validation-email-invalid": "Adresse électronique non valide",
	"events-validation-time": "Format horaire non valide",
	"events-validation-link": "Ce lien n’est pas valide.",
	"events-register-hint-charcount": "Nombre de caractères recommandés: 500",
	"events-schulthess-cooperation": "Cette offre est proposée en collaboration avec Schulthess Médias Juridiques SA",
	"events-date-clear": "Réinitialiser la date",
	"events-single-event-not-found": "L’événement demandé n'a pas été trouvé.",
	"events-time": "Heure",
	"events-add-successful-message": "L’événement a été inscrit avec succès."
};