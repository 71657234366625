import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AccountService } from '../../account';

import { HomeService } from './../home.service';
import { HomeActions } from './../homeActions';
import { AboServicesActions } from './abo-servicesActions';

@Component({
    selector: 'slx-abo-services',
    templateUrl: './abo-services.component.html',
    host: { 'class': 'sidebar-layout' },
    styleUrls: ['./abo-services.component.scss'],
})
export class AboServicesComponent implements OnDestroy {

    private subscription: Subscription = new Subscription();
    public usersIpAdress: string;

    constructor(public accountService: AccountService, public homeService: HomeService) {

        this.subscription
            .add(this.accountService.usersIpAdress.subscribe(ip => this.usersIpAdress = ip));
    }

    public getUsersIP() {
        this.accountService.dispatch({ type: AboServicesActions.get_users_ip.name });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
