import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { AssetDisplayType, AssetRef, AssetTab, AssetTabstrip, PreviewAssetContent, PreviewInfo, SourceDetail } from '../../models';
import { AssetService } from '../asset.service';

@Component({
    selector: 'slx-preview-assets',
    templateUrl: './previewAssets.component.html',
    styleUrls: ['./previewAssets.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewAssetsComponent implements OnChanges {
    @Input() assetTab: AssetTab;
    @Input() tabArea: AssetTabstrip;
  //  public asset: Asset;
    public list;

    constructor(public assetService: AssetService) {
     }

    ngOnChanges() {
        this.list = null;
        if (this.assetTab) {
            const cachedAsset = this.assetService.getCachedAsset(this.assetTab.assetRef);
            if (cachedAsset) {
                this.list = (cachedAsset.content as PreviewAssetContent).previews;
            }
        }
    }


    openAsset(item: PreviewInfo, event: MouseEvent) {
        return this.assetService.openAsset(AssetRef.create(AssetDisplayType.UnknownDocument ,item.previewTargetId, {source: SourceDetail.DocumentLink}),!this.tabArea.isPrimary,event);
    }

    openLaw(item: PreviewInfo, event: MouseEvent) {
        const ref = item.aolId === '00000000-0000-0000-0000-000000000000' ? AssetRef.create(AssetDisplayType.LawDocument,item.previewTargetId,{source: SourceDetail.DocumentLink})
            : AssetRef.create(AssetDisplayType.ArticleOfLawDocument,item.aolId, { publicationId: item.previewTargetId, source:SourceDetail.DocumentLink});
        const shouldOpenInPrimary = (this.tabArea.isPrimary && event.altKey) || (!this.tabArea.isPrimary && !event.altKey);
        this.assetService.openAsset(ref,!this.tabArea.isPrimary,event);
    }
}
