import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { autoCompleteBaseUrls, SlxHttp } from '../access';
import { AccountService } from '../account';
import { AutocompleteParamter, AutocompleteType, LanguageEnum } from '../models';

@Injectable()
export class AutocompleteService {
    constructor(private slxHttp: SlxHttp, private accountService: AccountService) {
        this.getReferenceAutocomplete  = this.getReferenceAutocomplete.bind(this);
        this.getAuthorAutocomplete  = this.getAuthorAutocomplete.bind(this);
        this.getLawAutocomplete  = this.getLawAutocomplete.bind(this);
        this.getArticleNumberAutocomplete  = this.getArticleNumberAutocomplete.bind(this);

    }


    public autoCompleteSearch(autoCompleteType: AutocompleteType, useLanguage: boolean, autoCompleteParameters?: AutocompleteParamter[]): Observable<any> {
        const url = this.createAutocompleteUrl(autoCompleteType, useLanguage, autoCompleteParameters);

        return this.slxHttp.get(url);
    }

    private createAutocompleteUrl(autoCompleteType: AutocompleteType, useLanguage: boolean, autoCompleteParameters?: AutocompleteParamter[]): string {
        let url = autoCompleteBaseUrls[autoCompleteType];
        if (!autoCompleteParameters && !useLanguage) {
            return url;
        }

        // paramters + maybe lang
        if (autoCompleteParameters) {
            url += '?';

            autoCompleteParameters.forEach(p => {
                url += `${p.name}=${encodeURIComponent(p.value)}&`;
            });

            url = useLanguage ? `${url}language=${this.accountService.lang}` : url.substring(0, url.length - 1);
            return url;
        }

        // only lang
        return `${url}?language=${this.accountService.lang}`;
    }

    public getReferenceAutocomplete() {
        return (suchtext: string): Observable<any> => {
            const parameters = [
                new AutocompleteParamter('reference', suchtext),
            ];
            return this.autoCompleteSearch(AutocompleteType.RetrievalReference, true, parameters);
        };
    }

    public getAuthorAutocomplete() {
        return (suchtext: string): Observable<any> => {
            const parameters = [
                new AutocompleteParamter('author', suchtext),
            ];
            return this.autoCompleteSearch(AutocompleteType.RetrievalAuthor, false, parameters);
        };
    }

    public getLawAutocomplete() {
        return (suchtext: string): Observable<any> => {
            const paramters = [
                new AutocompleteParamter('law', suchtext),
            ];
            return this.autoCompleteSearch(AutocompleteType.RetrievalLaw, true, paramters);
        };
    }

    public getArticleNumberAutocomplete(index: number, articleOfLawFilterCriterias) {
        return (suchtext: string): Observable<any> => {
            const paramters = [
                new AutocompleteParamter('law', articleOfLawFilterCriterias[index].law),
                new AutocompleteParamter('articleNumber', suchtext),
            ];
            return this.autoCompleteSearch(AutocompleteType.RetrievalArticleNumber, true, paramters);
        };
    }
}
