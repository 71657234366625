module.exports = {
	"site-page-not-found": "Page inconnue ({{url}})",
	"about": "À propos de Swisslex",
	"about-contact": "Contact",
	"customerprofile": "Profil client",
	"service": "Abo & Services",
	"account": "Compte",
	"recherche": "Recherche",
	"news-service": "News",
	"biblio": "Bibliothèque",
	"jobs": "Emplois",
	"events": "Manifestations",
	"faq": "FAQ",
	"faq-id": "Identifiant",
	"faq-top": "Au-dessus",
	"faq-left": "Gauche",
	"faq-right": "Droit",
	"faq-bottom": "Dessous",
	"login-welcome": "Bienvenue chez Swisslex",
	"login-welcome2": "Veuillez vous connecter",
	"login-register": "Pas encore enregistré?",
	"login-forgot": "Mot de passe oublié?",
	"login-login": "Se connecter",
	"login-logout": "Se déconnecter",
	"login-stay": "Rester connecté",
	"login-username": "Nom d'utilisateur",
	"login-password": "Mot de passe",
	"NoAccessForUserPass": "Nom d’utilisateur ou mot de passe incorrect",
	"service-standard": "Swisslex Standard",
	"service-premium": "Swisslex Premium",
	"service-abofees": "Frais d’abonnement",
	"service-reg": "Enregistrement",
	"service-business": "Clients commerciaux",
	"service-students": "Communauté universitaire",
	"service-lawexamcandidates": "Candidats aux examens d'avocat",
	"service-lawbooks": "Librairie spécialisée en ouvrages juridiques",
	"service-agb": "CG",
	"service-disclaimer": "Exclusion de responsabilité",
	"service-hotline": "Hotline",
	"service-questions": "Des questions?",
	"service-faq": "FAQ - Foire aux questions",
	"service-hotline-txt": "Notre hotline répond volontiers à vos questions.",
	"service-reg-business": "Enregistrement clients commerciaux",
	"service-reg-business-txt-1": "En remplissant ce formulaire vous pouvez demander un accès à un test gratuit d’un mois.",
	"service-reg-business-txt-2": "Si vous êtes déjà client Swisslex, veuillez vous adresser directement à votre administrateur Swisslex interne ou à notre hotline (tél.: 0800 587 463).",
	"service-reg-comp-name": "Etude / Société",
	"service-reg-country": "Pays",
	"service-reg-abo": "Frais d’abonnement",
	"service-reg-nol": "Nombre de juristes",
	"service-reg-business-comp": "Étude / Société",
	"service-reg-business-privat": "Personne individuelle",
	"service-reg-comp-suffix": "Complément d'adresse",
	"service-name-max-length": "Le nom doit être plus court que 30 signes",
	"service-reg-comp-zip-notvalid-val-txt": "Votre code postal entré est trop long",
	"service-reg-comp-phone-notvalid-val-txt": "Votre numéro de téléphone entré n’est pas valide.",
	"service-reg-comp-fax-notvalid-val-txt": "Votre numéro de fax entré n’est pas valide.",
	"service-reg-comp-nol-notvalid-val-txt": "Veuillez saisir seulement des chiffres.",
	"service-reg-userid-notvalid-val-txt": "E-mail / Nom d'utilisateur non valide",
	"service-reg-email-notvalid-val-txt": "Votre adresse électronique n’est pas valide.",
	"service-reg-agb": "CG",
	"service-reg-agb-accept": " lues et acceptées",
	"service-reg-agb-notvalid-val-txt": "Veuillez accepter les CG.",
	"service-reg-ok-txt": "Votre inscription a réussi. Vous recevrez prochainement de plus amples informations par e-mail.",
	"service-reg-pwd-equal": "doit être identique",
	"service-reg-pwd-min": "Minimum {{requiredLength}}",
	"service-reg-pwd-uppercase": "Au minimum une majuscule et 6 signes",
	"service-reg-business-dialog-header": "Fin de l’inscription",
	"service-reg-business-dialog-title": "L'enregistrement du client commercial est presque terminé. Veuillez s.v.p. choisir l'une des options suivantes :",
	"service-reg-business-dialog-option1": "Je souhaite tester les services Swisslex en tant qu’entreprise individuelle ou particulier.",
	"service-reg-business-dialog-option2": "Je suis étudiante ou étudiant dans une université ou une haute école suisse.",
	"service-reg-business-dialog-option3": "Je me prépare à l'examen d’avocat.",
	"service-reg-studs": "Enregistrement communauté universitaire",
	"service-reg-uni": "Université",
	"service-reg-studs-txt-1": "Les facultés de droit suisses ont conclu un accord avec Swisslex en vertu duquel les abonnements Standard & Premium sont mis à la disposition de tous les étudiants immatriculés, dans la plupart des cas gratuitement.",
	"service-reg-studs-txt-2": "L'inscription s'effectue directement via les services correspondants de l'université.",
	"service-reg-studs-userid": "E-mail / Nom d'utilisateur",
	"service-reg-txt": "S’inscrire",
	"service-to-reg": "Vers inscription",
	"service-reg-reset-form": "Annuler saisie",
	"service-reg-studs-mail-prefix": "Partie de votre adresse électronique située à gauche du symbole @",
	"service-reg-studs-excl-unis-txt": "Pour l’université suivante, l'inscription n'est pas automatique mais doit s'effectuer via les services correspondants de l'université. Pour cela, cliquez sur le lien indiqué ci-dessous.",
	"service-reg-uni-lausanne": "Université de Lausanne",
	"service-reg-uni-neuenburg": "Université de Neuchâtel",
	"service-reg-rak-txt": "Inscription de candidats aux examens d'avocat",
	"service-reg-rak-txt-1": "Swisslex propose aux candidats aux examens d'avocat qui ont terminé leur stage pratique un accès à Swisslex à des conditions avantageuses (CHF 34.- / mois, hors TVA).",
	"service-reg-rak-txt-2": "Pour obtenir un tel accès, inscrivez-vous et envoyez-nous une copie de la confirmation d'inscription aux examens à :",
	"service-reg-rak-txt-3": "Nous vous enverrons ensuite un code d'activation avec lequel vous pourrez régler le prix de votre abonnement via Paypal (carte American Express, carte Visa ou MasterCard).",
	"service-reg-rak-txt-4": "Veuillez noter :",
	"service-reg-rak-txt-5": "Sans confirmation l'inscription ne sera pas traitée !",
	"service-reg-rak-runtime": "Durée de contrat désirée",
	"service-reg-rak-pdf": "Téléchargez votre inscription aux examens au format PDF ou envoyez-la par courrier postal, fax ou e-mail.",
	"service-reg-rak-activate": "Code d'activation déjà reçu ?",
	"service-reg-rak-activate-txt": "Si vous avez déjà reçu un code d'activation, vous pouvez terminer votre inscription ici :",
	"service-reg-rak-activate-code": "Code d'activation",
	"service-reg-rak-activate-account": "Activer le compte",
	"service-reg-rak-loginname-notvalid-val-txt": "Veuillez indiquer votre nom d'utilisateur",
	"service-reg-rak-upload-pdf": "Télécharger PDF",
	"service-reg-rak-upload-notvalid-size-val-txt": "PDF trop volumineux. Taille maximale 20 Mo.",
	"service-reg-rak-upload-notvalid-typ-val-txt": "Télécharger uniquement le fichier PDF.",
	"service-reg-rak-paypal": "Terminer l'inscription",
	"service-reg-rak-paypal-duration": "Durée de l’abonnement :",
	"service-reg-rak-paypal-month": "Coûts mensuels :",
	"service-reg-rak-paypal-tax": "TVA (7.7 %) :",
	"service-reg-rak-paypal-total": "Total :",
	"service-reg-rak-paypal-pay": "Payer",
	"service-reg-rak-paypal-pay-successful": "Votre paiement a été transmis avec succès. Veuillez vous connecter.",
	"service-reg-rak-paypal-pay-unsuccessful": "Il y a eu un problème lors du paiement. Veuillez réessayer s.v.p.",
	"service-reg-business-info": "Informations générales",
	"service-reg-business-contact": "Contact",
	"service-reg-business-abo": "Swisslex identifiant",
	"service-steampfli": "Lien vers librairie Stämpfli",
	"service-schulthess": "Lien vers librairie Schulthess",
	"service-training": "Offre de formation",
	"service-tr-txt-1": "Voulez-vous rafraîchir vos notions Swisslex et mieux connaître nos outils de recherche ainsi que les fonctions du portail afin d'employer Swisslex plus efficacement ?",
	"service-tr-txt-2": "Nous proposons à nos clients des formations gratuites, sur place ou à distance via une connexion internet en direct.",
	"service-tr-txt-3": "Adressez-nous dès aujourd'hui votre demande de formation au moyen du formulaire suivant ou contactez notre",
	"service-tr-common": "Informations générales",
	"service-tr-additional": "Informations complémentaires",
	"service-tr-date1": "1re date proposée",
	"service-tr-date2": "2e date proposée",
	"service-tr-date3": "3e date proposée",
	"service-tr-company": "Entreprise",
	"service-tr-participants": "Nombre de participants",
	"service-tr-person": "Contact",
	"service-tr-location": "Lieu",
	"service-tr-phone": "Téléphone",
	"service-tr-mail": "E-mail",
	"service-tr-tr": "Formation",
	"service-tr-inhouse": "Formation sur place",
	"service-tr-remote": "Formation à distance",
	"service-tr-computer": "Projecteur, ordinateur, Internet",
	"service-tr-available": "disponible",
	"service-tr-not-available": "non disponible",
	"service-tr-lang": "Langue",
	"service-tr-de": "Allemand",
	"service-tr-fr": "Français",
	"service-show-ip": "Afficher votre adresse IP",
	"service-showing-ip": "Afficher votre adresse IP",
	"TrainingSuccessfulMessage": "Votre demande de formation a été transmise avec succès.",
	"change-pwd-title": "Modification du mot de passe Swisslex",
	"forgot-pwd-title": "Vous avez oublié votre mot de passe Swisslex?",
	"forgot-pwd-text": "Dans ce cas, veuillez entrer ici l’adresse électronique que vous avez enregistrée :",
	"forgot-pwd-mail": "Adresse électronique",
	"forgot-pwd-enter": "Envoyer",
	"NoAccessForIpAddress": "Vous ne vous trouvez pas dans le réseau requis pour obtenir un accès. Si vous désirez un accès en externe, veuillez vérifier vos paramètres VPN.",
	"NotInUniNetWork": "Vous ne pouvez pas vous connecter. Votre adresse IP se situe en dehors du domaine prédéfini. Veuillez vérifier la configuration de votre réseau ou adressez-vous à votre service informatique.",
	"UnknownAccount": "Nom d’utilisateur ou mot de passe incorrect !",
	"AccountExpired": "Votre abonnement a expiré. Veuillez s.v.p. contacter notre hotline.",
	"BadPassword": "Nom d’utilisateur ou mot de passe incorrect !",
	"BadHashedPassword": "Nom d’utilisateur ou mot de passe incorrect !",
	"ProductBundleAlreadySubscribed": "Vous êtes déjà abonné au produit choisi.",
	"ProductBundleAlreadyTested": "Votre abonnement d’essai gratuit est arrivé à expiration.",
	"EmailaddressAlreadyInUse": "Cette adresse électronique existe déjà dans notre système. Veuillez en choisir une autre ou vous adresser à notre hotline.",
	"UserIdAlreadyInUse": "Ce nom d’utilisateur existe déjà dans notre système. Veuillez en choisir un autre ou vous adresser à notre hotline (0800 587 463 / +41 (0) 44 365 32 32 !).",
	"AccountInactiv": "Votre compte n'est pas activé.",
	"UserAccountInactiv": "Votre login n'est pas actif. Veuillez s.v.p. contacter votre administrateur.",
	"DepartmentInactiv": "Le département est inactif. Veuillez s.v.p. contacter votre administrateur.",
	"RakAktivierungsCodeInvalid": "Le code d'activation indiqué n'est pas correct.",
	"RakAktivierungsDateInvalid": "La confirmation de l'inscription à l’examen d'avocat remonte déjà à plus de 11 mois. Veuillez prendre contact avec notre hotline.",
	"RakAktivierungNotConfirmed": "La copie de la confirmation de l'inscription à l’examen d'avocat n'a pas encore été envoyée à Swisslex.",
	"RakAktivierungAlreadyDown": "L’inscription a déjà été clôturée.",
	"RequestPasswordUserEmailNotExist": "Si votre adresse électronique est enregistrée dans notre système, vous recevrez un courrier électronique dans quelques minutes.",
	"InvalidSessionId": "La session a expiré! Veuillez vous reconnecter.",
	"UnknownError": "Erreur non identifiable ! Veuillez vous adresser à notre hotline - 0800 587 463   /   +41 (0) 44 365 32 32.",
	"DoesNotComeViaUniRegistrationForm": "Pour l'inscription, veuillez utiliser le formulaire Inscription communauté universitaire.",
	"LoginExistsInTheUniOfZurichWithThisEmailSuffix": "Il existe déjà un login à votre nom dans le compte de l'université de Zurich. Veuillez-vous adresser à la hotline de Swisslex.",
	"AboservicesRegistrationEmailNotValidValidationMessage": "Votre adresse électronique n’est pas valide.",
	"DoesNotComeViaBusinessRegistrationForm": "Pour l'inscription, veuillez utiliser le formulaire Inscription clients commerciaux.",
	"CompanyNameMissing": "Veuillez saisir votre étude / entreprise",
	"NumberOfFlawyersMissing": "Veuillez saisir le nombre de juristes de votre entreprise / étude en donnant uniquement des chiffres.",
	"StreetMissing": "Veuillez saisir la rue.",
	"ZipCodeMissing": "Veuillez saisir le code postal. Saisie de 4 chiffres min. et 5 max. autorisés.",
	"CityMissing": "Veuillez saisir la ville.",
	"PhoneMissing": "Veuillez saisir votre numéro de téléphone. Ou numéro de téléphone saisi n’est pas valide.",
	"FirstNameMissing": "Veuillez indiquer vos prénoms.",
	"NameMissing": "Veuillez saisir votre nom de famille.",
	"EmailMissing": "Veuillez saisir votre adresse électronique / nom d'utilisateur. Ou votre adresse électronique / nom d'utilisateur n’est pas valide.",
	"PasswordMissing": "Veuillez saisir votre mot de passe. Ou votre mot de passe n’est pas valide.",
	"AgbMissing": "Veuillez accepter les CG.",
	"DoesNotComeViaRakRegistrationForm": "Pour l'inscription, veuillez utiliser le formulaire Inscription candidats examen d’avocat.",
	"RakActivationCodeMissing": "Veuillez saisir le code d'activation.",
	"DepartmentNameMissing": "Veuillez saisir un nom de département.",
	"UserLoginSendSuccessful": "Les données d’accès pour cet utilisateur ont été envoyées avec succès.",
	"UserLoginSendAllSuccessful": "Les données d’accès pour {{value}} utilisateurs ont été envoyées avec succès.",
	"UserLoginSendNoEmail": "Veuillez attribuer dans un premier temps une adresse électronique à l’utilisateur.",
	"BusinessSuccessfulMessage": "Votre inscription a réussi. Vous recevrez prochainement de plus amples informations par voie postale.",
	"UniversitySuccessfulMessage": "Votre inscription a réussi. Vous recevrez dans quelques instants un courrier électronique indiquant vos données d'accès.",
	"RakSubscribeSuccessfulMessage": "Votre inscription a réussi. Nous traiterons votre accès dès que vous nous aurez fait parvenir une copie de la confirmation de votre inscription à l'examen.",
	"ForgotPasswordLoginNameNotExist": "L’adresse électronique que vous avez saisie n’est pas enregistrée. Veuillez vérifier votre saisie ou adressez-vous à notre hotline.",
	"ForgotPasswordSuccessful": "Si votre adresse électronique est enregistrée dans notre système, vous recevrez un e-mail dans quelques minutes.",
	"ForgotPasswordToLate": "La durée de 24 heures a été dépassée. Utilisez la fonction \"mot de passe oublié\" ou contactez notre hotline.",
	"ForgotPasswordSecondCall": "Le lien que nous vous avons envoyé ne peut être utilisé qu’une seule fois. Utilisez la fonction \"mot de passe oublié\" ou contactez notre hotline.",
	"more": "Plus",
	"more-events": "Autres manifestations",
	"all-events": "Toutes les manifestations",
	"all-jobs": "Tous les emplois",
	"top-docs-last-days": "Les documents les plus lus au cours des 7 derniers jours sur swisslex.ch",
	"news": "News",
	"news-configurate": "Gérer newsletters",
	"news-currents": "Mes abonnements aux news définis",
	"news-description": "Grâce au News-Service de Swisslex, vous recevez régulièrement les dernières informations juridiques par courrier électronique. Vous choisissez votre configuration – et vous suivez constamment le fil de l’actualité.",
	"news-premium": "Ce service est uniquement disponible pour les utilisateurs disposant d’un abonnement Premium.",
	"home-to-contact": "Vers Contact",
	"home-swisslex-training": "Formation Swisslex",
	"home-swisslex-training-register": "Inscrivez-vous maintenant",
	"home-to-faq": "FAQ",
	"home": "Page d'accueil",
	"faq-common": "Général",
	"faq-search": "Filtres de recherche",
	"faq-doc": "Documents",
	"faq-workspace": "Espace travail",
	"faq-view": "Newsletter",
	"faq-wizard": "Assistant de newsletter",
	"faq-recherche": "Recherche",
	"faq-news": "News",
	"faq-profile": "Profil utilisateur",
	"faq-settings": "Paramétrages",
	"faq-entry": "Entrées de la FAQ",
	"faq-adjust": "Personnalisation",
	"faq-add": "Ajouter",
	"faq-edit": "Éditer",
	"faq-remove": "Destitution",
	"faq-delete": "Effacer",
	"faq-save": "Enregistrer",
	"faq-fk-topic": "clés étrangères",
	"faq-title-de": "Titre Allemand",
	"faq-title-fr": "Titre Français",
	"faq-topic-name": "Nom du sujet",
	"faq-priority": "Priorité",
	"faq-content-german": "Contenu Allemand",
	"faq-content-french": "Contenu Français",
	"faq-content-de": "Contenu Allemand (HTML)",
	"faq-content-fr": "Contenu Français (HTML)",
	"faq-upload-image-de": "Image téléchargée DE",
	"faq-upload-image-fr": "Image téléchargée FR",
	"faq-delete-image-de": "Image supprimer DE",
	"faq-delete-image-fr": "Image supprimer FR",
	"faq-image-upload-only": "Vous ne pouvez télécharger que des images",
	"faq-upload-notvalid-size-val-txt": "Taille maximale 20 Mo.",
	"faq-use-image": "Avant-première",
	"faq-image-position": "Position de l'image",
	"faq-reset-form": "Annuler saisie",
	"faq-add-submit": "Ajouter une FAQ",
	"faq-edit-submit": "FAQ de mise à jour",
	"faq-add-common": "Informations générales",
	"faq-add-title": "Titre",
	"faq-add-content": "Contenu",
	"faq-add-image": "Photo",
	"faq-remove-topic-title": "Supprimer le nom du sujet",
	"faq-remove-topic-text": "Êtes-vous sûr de vouloir supprimer irrévocablement le nom du sujet, y compris toutes les entrées de la FAQ qui y sont soumises ?",
	"faq-remove-topic-confirm": "Oui",
	"faq-remove-title": "FAQ Supprimer une entrée",
	"faq-remove-text": "Êtes-vous sûr de vouloir supprimer l'entrée de la FAQ de manière irrévocable ?",
	"faq-remove-confirm": "Oui",
	"faq-adjust-entries": "Modifier les entrées",
	"faq-edit-back-to-list": "Retour à toutes les FAQs",
	"faq-preview": "Patron",
	"faq-show-original-preview": "Afficher l'aperçu original",
	"faq-return": "Retour",
	"faq-german": "Allemand",
	"faq-french": "Français",
	"faq-german-copy-img-fr": "Allemand (Il n'y a qu'une photo française disponible)",
	"faq-french-copy-img-de": "Français (Il n'y a qu'une photo en allemand disponible)",
	"old-browser-dectected": "Votre navigateur internet est obsolète. Mettez-le à jour pour plus de sécurité, de rapidité et de confort.",
	"old-browser-more-info": "En savoir plus ...",
	"form-validation-required": "Ce champ est obligatoire",
	"home-info-operator-help": "Opérateurs",
	"home-info-language-help": "Langue de recherche",
	"home-info-reference-help": "Références bibliographiques",
	"home-search-reset": "Vider l’espace travail",
	"home-send-mail-error": "Il y a eu une erreur technique. Veuillez réessayer ultérieurement ou contacter notre hotline.",
	"home-help": "Aide",
	"home-main-menu": "Menu principal",
	"home-uni-using-header": "Utilisation universitaire",
	"home-uni-using": "Ce compte Swisslex est strictement réservé à une utilisation universitaire. Toute autre utilisation, en particulier à fin commerciale, est interdite. Le téléchargement systématique de grandes parties de livres constitue une violation du contrat d'utilisation.",
	"home-uni-using-dial": "Information utilisation universitaire",
	"home-uni-using-ok": "accepter",
	"home-uni-using-no": "refuser"
};