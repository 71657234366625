import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { storeRestoreRoute } from './access';
import { biblioRoute, biblioStaticRoutes } from './bibliothek';
import { registerRoute } from './config';
import { accountRoute, customerprofileRoute } from './customer-profile';
import { eventsRoute } from './events';
import { aboAndServicesRoute, aboutRoute, faqRoute, homeRedirect, homeRoute, infoRoute, notFoundRoute } from './home';
import { jobsRoute } from './jobs';
import { newsRoute } from './newsservice';
import { docRoute, rechercheRoute, tocRoute } from './recherche';

const debug = require('debug')('routes');

const legacyRoutes = [
    { path: 'doc/showdocview/:id', redirectTo: 'doc/unknown/:id', pathMatch: 'full' },
    { path: 'doc/showlawview/:id', redirectTo: 'doc/legacylaw/:id', pathMatch: 'full' },
    { path: 'doc/showlawview/:id/:subid', redirectTo: 'doc/legacylaw/:id/:subid', pathMatch: 'full' },
    { path: 'doc/showlawviewbyguid/:id', redirectTo: 'doc/lawdoc/:id', pathMatch: 'full' },
    { path: 'doc/showlawviewbyguid/:id/00000000-0000-0000-0000-000000000000', redirectTo: 'doc/lawdoc/:id', pathMatch: 'full' },
    { path: 'doc/showlawviewbyguid/:id/:subid', redirectTo: 'doc/aol/:subid/:id', pathMatch: 'full' },
    { path: 'toc/periodical/:id', redirectTo: 'toc/publication/:id', pathMatch: 'full' },
    { path: 'toc/periodicalwithissue/:id/:subid', redirectTo: 'toc/publication/:id/:subid', pathMatch: 'full' },
    { path: 'jobads/viewjobad/:id', redirectTo: 'jobs/single/:id', pathMatch: 'full' },
    { path: 'events/viewevent/:id', redirectTo: 'events/details/:id', pathMatch: 'full' },
    { path: 'home/publikationsliste', redirectTo: 'biblio', pathMatch: 'full' },
    { path: 'legacyjournal', redirectTo: 'recherche/search/new', pathMatch: 'full' },
];

const legacyGoogleResults = [
    { path: 'home/publikationsliste', redirectTo: 'biblio/books', pathMatch: 'full' },
    { path: 'retrieval/search', redirectTo: 'recherche/search/new', pathMatch: 'full' },
    { path: 'aboutswisslex', redirectTo: 'about/company', pathMatch: 'full' },
    { path: 'aboutswisslex/kontakt', redirectTo: 'about/contact', pathMatch: 'full' },
    { path: 'aboutswisslex/lageplan', redirectTo: 'about/direction', pathMatch: 'full' },
    { path: 'aboservices', redirectTo: 'service/standard', pathMatch: 'full' },
    { path: 'aboservices/standardabo', redirectTo: 'service/standard', pathMatch: 'full' },
    { path: 'aboservices/premiumabo', redirectTo: 'service/premium', pathMatch: 'full' },
    { path: 'aboservices/university', redirectTo: 'service/students', pathMatch: 'full' },
    { path: 'newsletter/overview', redirectTo: 'news', pathMatch: 'full' },
    { path: 'actualcases', redirectTo: 'news', pathMatch: 'full' },
];

// CyN: The order has an influence in what route will be first used.
//      This means everything added after noFoundRoute will never be triggered,
//      because noFoundRoute matches with every url.
export const appRoutes: Routes = [
    storeRestoreRoute,
    homeRoute,
    aboAndServicesRoute,
    homeRedirect,
    rechercheRoute,
    tocRoute,
    docRoute,
    newsRoute,
    biblioRoute,
    ...biblioStaticRoutes,
    jobsRoute,
    eventsRoute,
    accountRoute,
    customerprofileRoute,
    aboutRoute,
    infoRoute,
    faqRoute,
];

export const routes: Routes = [
    ...legacyRoutes,
    ...legacyGoogleResults,
    ...appRoutes,
    notFoundRoute,
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: false, enableTracing: false, onSameUrlNavigation: 'reload'});

export function initRouting() {
    registerRoute(rechercheRoute, 'rech');
    registerRoute(docRoute, 'rech');
    registerRoute(tocRoute, 'rech');
    registerRoute(newsRoute, 'news');
    registerRoute(biblioRoute, 'biblio');
    registerRoute(jobsRoute, 'jobs');
    registerRoute(eventsRoute, 'events');
    registerRoute(aboutRoute, 'about');
    registerRoute(aboAndServicesRoute, 'abo');
    registerRoute(accountRoute, 'customer');
    registerRoute(customerprofileRoute, 'customer');
    registerRoute(infoRoute, 'password'); //TODO @Henrik this identifies the Main Tab relation. There shouldn't be this many.
    registerRoute(biblioStaticRoutes, 'biblio');
}

