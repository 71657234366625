module.exports = {
	"rech-asset-share": "Partager",
	"rech-asset-share-description": "Copier le lien vers ce document ou envoyer par e-mail",
	"rech-asset-copylink": "Copier lien",
	"rech-asset-asmail": "Envoyer le lien par e-mail",
	"rech-asset-document-link": "Lien vers document Swisslex",
	"rech-asset-fontsize": "Caractères",
	"rech-asset-fontsize-description": "Ajuster la taille de la police pour cette vue du document",
	"rech-asset-showtoc": "Table des matières",
	"rech-asset-showtoc-description": "Afficher la table des matières de la publication (revue / livre / commentaire)",
	"rech-asset-details": "Citations",
	"rech-asset-details-description": "Afficher les documents citant ce document",
	"rech-asset-comment-laws": "Commentaires",
	"rech-asset-comment-more-laws": "Article aussi commenté dans",
	"rech-asset-comment-law-description": "Afficher les commentaires pour cette législation",
	"rech-asset-comment-article-description": "Afficher les commentaires pour cet article",
	"rech-asset-comment-more-laws-description": "Afficher autres commentaires pour cet article",
	"rech-assets-multiple-articles": "Multiples articles",
	"rech-asset-more-sources": "Autres sources sur legalis.ch",
	"rech-asset-showLowMetaTextMore": "Afficher plus d'informations sur le document",
	"rech-asset-showLowMetaTextLess": "Afficher moins d'informations sur le document",
	"rech-asset-back-to-document": "Retourner au document",
	"rech-asset-getPdf": "PDF",
	"rech-asset-getPdf-description": "Télécharger ce document au format PDF",
	"rech-asset-favourite": "Favori",
	"rech-asset-favourite-description": "Enregistrer ce document comme favori personnel",
	"rech-asset-citetdIn": "Document cité dans",
	"rech-asset-showAllCitations": "Tout voir",
	"rech-asset-show-less": "Moins voir",
	"rech-asset-citations": "Document cité",
	"rech-asset-otherCitations": "Autres citations",
	"rech-asset-overview": "Vue d'ensemble",
	"rech-asset-DocIsNotCited": "N'est pas cité",
	"rech-asset-commented-in-article": "Article commenté dans",
	"rech-asset-commented-in-law": "Législation commenté dans",
	"rech-asset-notCommented": "N'est pas commenté",
	"rech-asset-language": "Langue",
	"rech-asset-language-description": "Sélectionner la langue du document",
	"rech-asset-attachment": "Annexe",
	"rech-asset-attachment-description": "Afficher annexes de ce document",
	"rech-asset-attachment-title": "Ce document possède les annexes suivantes",
	"rech-asset-attachment-not-found": "Annexe non trouvée",
	"rech-asset-Caselaw": "Arrêts",
	"rech-asset-CaselawReview": "Commentaires d'arrêt",
	"rech-asset-Essay": "Articles",
	"rech-asset-Commentary": "Commentaires",
	"rech-asset-Book": "Livres",
	"rech-asset-consolidatedVersion": "Versions",
	"rech-asset-versions": "Versions",
	"rech-asset-versions-description": "Afficher d'autres versions du même document.",
	"rech-asset-corrections": "Corrections",
	"rech-asset-original": "Original",
	"rech-asset-abo": "S’abonner",
	"rech-asset-buy": "Acheter",
	"rech-asset-document-stash": "Liste de documents",
	"rech-asset-close": "Fermer",
	"rech-asset-close-tab": "Fermer tab",
	"rech-asset-loading": "Chargement...",
	"rech-asset-error": "L’affichage du document a échoué.",
	"rech-asset-pdf-error": "Erreur lors du téléchargement du document",
	"rech-asset-Dokument": "Document",
	"rech-asset-Urteilsdatum": "Date du jugement",
	"rech-asset-Gericht": "Tribunal",
	"rech-asset-SwisslexKommentar": "Objet",
	"rech-asset-Publikation": "Publication",
	"rech-asset-Rechtsgebiete": "Domaines du droit",
	"rech-asset-Titel": "Titre",
	"rech-asset-SubTitel": "",
	"rech-asset-SerieReihe": "Série / Collection",
	"rech-asset-BuchAutoren": "Auteurs de l'ouvrage",
	"rech-asset-Jahr": "Année",
	"rech-asset-Pages": "Pages",
	"rech-asset-Page": "Page",
	"rech-asset-Author": "Auteur",
	"rech-asset-Autor": "Auteur",
	"rech-asset-Autoren": "Auteurs",
	"rech-asset-BuchTitel": "Titre du livre",
	"rech-asset-Herausgeber": "Éditeur(s)",
	"rech-asset-Redaktion": "Editeur / Rédaction",
	"rech-asset-FrühereHerausgeber": "Anciens éditeurs",
	"rech-asset-ISBN": "ISBN",
	"rech-asset-ISSN": "ISSN",
	"rech-asset-Verlag": "Maison d’édition",
	"rech-asset-KommArtikel": "Commentaire d'article",
	"rech-asset-DocTitle": "Titre du document",
	"rech-asset-KommentarAutoren": "Auteurs du commentaire",
	"rech-asset-Reihe": "Collection",
	"rech-asset-Auflage": "Édition",
	"rech-asset-BesprUrteile": "Commentaire du jugement",
	"rech-asset-DocumentDate": "Date du document",
	"rech-asset-PublicationDate": "Date de publication",
	"rech-asset-EnforcementDate": "En vigueur depuis",
	"rech-asset-InvalidationDate": "Abrogation depuis",
	"rech-asset-Remark": "Remarque",
	"rech-asset-Abkurzung": "Abbréviation",
	"rech-asset-SrCode": "Code RS",
	"rech-asset-Date": "Date",
	"rech-asset-Inkraft": "En vigueur",
	"rech-asset-ArticleNumber": "Article",
	"rech-asset-Aktenzeichen": "Référence du dossier",
	"rech-asset-EcliNummer": "N° Ecli",
	"rech-asset-biblioInfo": "Indications bibliographiques",
	"rech-asset-biblioInfo-description": "Afficher les données bibliographiques du document",
	"rech-asset-StandDatum": "État au",
	"rech-asset-multicitation": "Citation multiple",
	"rech-asset-lawcitation": "Citation de législation",
	"rech-asset-citeFrom": "du",
	"rech-asset-copy-with-citation": "Copier sélection avec citation",
	"rech-asset-copy-without-citation": "Copier sélection",
	"rech-asset-noContent": "Aucun contenu n’a pu être trouvé.",
	"rech-asset-drop-other": "Deuxième fenêtre",
	"rech-asset-previews": "Vous avez cliqué sur une citation multiple. Vous pouvez maintenant choisir le document que vous souhaitez consulter.",
	"rech-asset-preview": "Citation multiple",
	"rech-asset-doctoc-mn": "N",
	"rech-asset-doctoc-followingDocuments": "Dans les documents suivants",
	"rech-asset-doctoc-followingDocument": "Dans le document suivant",
	"rech-asset-following": "Suivant",
	"rech-asset-following-description": "Afficher le document suivant du même ouvrage",
	"rech-asset-preceding": "Précédent",
	"rech-asset-preceding-description": "Afficher le document précédent du même ouvrage",
	"rech-asset-doc": "Document",
	"rech-asset-doc-preceding": "Document Précédent",
	"rech-asset-doc-following": "Document Suivant",
	"rech-asset-save-fav-info": "Favori",
	"rech-asset-save-fav-success": "Ce document a été sauvegardé avec succès en tant que favori.",
	"rech-asset-save-fav-show-dial": "Ne plus afficher cette remarque",
	"rech-asset-save-fav-error": "Erreur lors de la sauvegarde / suppression du document en tant que favori.",
	"rech-asset-load-fav-error": "Erreur lors du chargement des favoris.",
	"rech-asset-details-pdf-yes": "Surligner texte recherché",
	"rech-asset-details-pdf-no": "Ne pas surligner",
	"rech-asset-copy-sucess": "La sélection a été copiée avec succès.",
	"rech-asset-cite-copy-sucess": "La partie du texte que vous avez sélectionnée, citation incluse, a été copiée avec succès.",
	"rech-asset-copy-error": "La sélection n’a pas pu être copiée.",
	"rech-asset-copy-no-cites": "Des citations pour ce type de document n’ont pas encore été implémentées.",
	"rech-asset-court-correction": "Corriger tribunal",
	"rech-asset-fix-missing-court": "Ajouter des informations manquantes sur le tribunal",
	"rech-asset-missing-court": "Tribunal non indiqué dans la base de données",
	"rech-asset-no-access": "Vous ne disposez pas des droits nécessaires pour l’ouverture de ce document.",
	"rech-asset-missing-rights": "Droits faisant défaut: ",
	"rech-asset-too-many-assets-title": "Trop de documents ouverts",
	"rech-asset-too-many-assets-text": "Vous avez atteind le nombre maximum de documents ouverts. Pour ouvrir un nouveau document, veuillez fermer un des documents. <br>",
	"rech-asset-too-many-assets-link": "Cliquez ici pour automatiquement fermer le document le plus ancien.",
	"rech-asset-citation-following": "s.",
	"rech-asset-citation-multiple-following": "ss",
	"rech-asset-link-preview-open-law": "Afficher cette législation",
	"rech-asset-link-preview-open-article": "Afficher cet article",
	"rech-asset-link-preview-open-commentary-law": "Commentaires pour cette législation",
	"rech-asset-link-preview-open-commentary-article": "Commentaires pour cet article",
	"rech-asset-link-no-comment": "Pas de commentaires",
	"rech-asset-aol-description": "art. {{articleNumber}} {{lawAbbr}}",
	"rech-asset-newer-comment": "Il existe un commentaire pour cet article dans une édition plus récente.",
	"rech-asset-open-newer-comment": "Afficher le commentaire le plus récent.",
	"rech-asset-new-edition-toc": "Éditions plus récentes"
};