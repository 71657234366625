import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AccessModule } from '../access';
import { FieldModule } from '../field';

import { AccountService } from './account.service';
import { AccountEffects } from './accountEffects';
import { IntroComponent } from './intro.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        AccessModule,
        RouterModule,
        FieldModule,
        EffectsModule.forFeature([AccountEffects]),
    ],
    exports: [
        TranslateModule,
        IntroComponent,
    ],
    declarations: [
        IntroComponent,
    ],

    providers: [AccountService, TranslateService],
})
export class AccountModule {
    constructor(private account: AccountService) {
        this.account.addTranslation('account-',
            require('./account.de.yaml'),
            require('./account.fr.yaml')
        );
    }
}
