import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AccountService } from '../../../account';

@Component({
    selector: 'about-shareholders',
    templateUrl: './aboutShareholders.component.html',
    styleUrls: ['./aboutShareholders.component.scss'],
})
export class AboutShareholdersComponent implements OnDestroy {

    public language = this.accountService.lang;
    private onLangChangeSubscription: Subscription;

    constructor(public accountService: AccountService) {
        this.onLangChangeSubscription = accountService.onLangChange.subscribe(res => { this.language = this.accountService.lang; });
    }

    ngOnDestroy(): void {
        this.onLangChangeSubscription.unsubscribe();
    }
}
