import { Action } from '@ngrx/store';
import { format } from 'date-fns';
import * as deLocale from 'date-fns/locale/de';

import { enhanceActionsObject, Job, JobsState, SlxAction } from '../models';

export const JobsActions = {
    load_jobs(state: JobsState, { payload, result }: { payload: Job[], result: string }) {
        return result ? {
            ...state,
            jobList: payload.map(j => ({...j, publishDate: format(j.publishDate, 'DD.MM.YYYY', { locale: deLocale })})),
        } : state;
    },

    set_current_job(state: JobsState, { payload: currentJob } : { payload: Job }) {
        return {
            ...state,
            currentJob,
        };
    },

    load_current_job(state: JobsState, { payload : currentJob, result } : { payload: Job, result: string }) {
        if (result) {
            if (currentJob.iFrameUrl != null && !currentJob.iFrameUrl.match(/^http:/)) {
                currentJob.jobDescriptionType = 'iFrame';
            } else if (currentJob.pdfImageUrl != null) {
                currentJob.jobDescriptionType = 'pdfImage';
            } else {
                currentJob.jobDescriptionType = 'text';
            }
            return {
                ...state,
                currentJob,
            };
        } else {
            return state;
        }
    },

    load_top_jobs(state: JobsState, { payload, result }) {
        return result ? {
            ...state,
            topJobList: (payload as Job[]).map(job => ({...job, publishDate: format(job.publishDate, 'DD.MM.YYYY', { locale: deLocale })})),
        } : state;
    },
};
enhanceActionsObject(JobsActions);
