const debug = require('debug')('enum');

export enum StoreBackupType {
    'CustomBackup' = 1,
    'ExceptionBackup' = 2,
}

export enum BiblioType {
    Journals = 'journals',
    PublishedCaseLaws = 'publishedCaseLaws',
    UnpublishedCaseLaws = 'unpublishedCaseLaws',
    Books = 'books',
    Commentaries = 'commentaries',
    Serials = 'serials',
    BooksUni = 'booksUni',
}

export enum HitType {
    'Undefined' = 0,
    'Document' = 1,
    'Book' = 2,
    'PeriodicalCollectionAsset' = 3,
    'PeriodicalPublication' = 4,
    'CaseLaw' = 5,
    'Law' = 6,
    'MissingDocument' = 7,
}

export enum TransactionType {
    'Unknown' = -1,
    'Search' = 0,
    'DocView' = 1,
    'TocView' = 2,
    'LawView' = 3,
    'EuSearch' = 4,
    'EuDocView' = 5,
}

export enum ExtendedTransactionType {
    'Search' = 100,
    'Find' = 200,
    'DocView' = 300,
    'TocView' = 400,
    'Newsletter' = 500,
    'LawView' = 600,
    'Login' = 700,
    'ExternalLink' = 800,
    'RefineSearch' = 900,
    'RefineFind' = 1000,
    'EuSearch' = 7100,
    'EuFind' = 7200,
    'EuDocView' = 7300,
    'EuRefineSearch' = 7900,
    'EuRefineFind' = 8000,
}

export enum NewsType {
    'Search' = 3,
    'Journal' = 1,
    'PracticeArea' = 2,
    'CaseLaw' = 5,
}

export enum LanguageEnum {
    'undefined' = 0,
    'De' = 1,
    'Fr' = 2,
    'It' = 3,
    'En' = 4,
    'Rm' = 5,
}

export enum DocScrollType {
    'FirstSearchTerm',
    'CitationAnchor',
    'ReferringCitationId',
    'EdocTitleElement',
    'EdocTitleElementTocOnly',
    'DocumentStart',
}

export enum AssetTypeGroup {
    'undefined' = 0,
    'Caselaw' = 1,
    'CaselawReview' = 2,
    'OfficialPub' = 3,
    'Commentary' = 4,
    'EssayAndBook' = 5,
    'Caselawcollection' = 6,
    'EssayNonBook' = 7,
    'EssayOnlyBook' = 8,
}

export enum LegalActTypeGroup {
    'undefined' = 0,
    'Treaty' = 1,
    'InternationalAgreement' = 2,
    'Legislation' = 300,
    'LegislationAll' = 399,
    'LegislationRegulation' = 301,
    'LegislationDirective' = 302,
    'LegislationDecision' = 303,
    'LegislationNonOpposition' = 304,
    'ComDocuments' = 5,
    'CaseLaw' = 6,
    'EftaDocuments' = 7,
}

export enum DrilldownType {
    'undefined' = 0,
    'FilterType' = 1,
    'FilterLang' = 2,
    'SortDate' = 3,
    'SortRelevance' = 4,
    'ResetFilterType' = 5,
    'ResetFilterLang' = 6,
}

export namespace LegalAct {
    export function getLegislations() {
        return [
            LegalActTypeGroup.LegislationAll,
            LegalActTypeGroup.LegislationDecision,
            LegalActTypeGroup.LegislationDirective,
            LegalActTypeGroup.LegislationNonOpposition,
            LegalActTypeGroup.LegislationRegulation,
            LegalActTypeGroup.Legislation,
        ];
    }
}

export function checkIfExistsInStringEnum(enumType, enumValue: string): boolean {
   const result =  (<any>Object).values(enumType).includes(enumValue);
   return result;
}

export function enumToString(enumType, enumValue): string {
    for (const enumMember in enumType) {
        if (enumType[enumMember] === enumValue) return enumMember;
    }
}

export enum CustomerProfileGoBackType {
    'AllUsers' = 'allUsers',
    'AllDepartments' = 'allDepartments',
    'SingleDepartment' = 'singleDepartment',
}

export enum PlaceHolderEnum {
    PageBreak = '$PAGE_BREAK$',
    MarginNumber = '$MARGIN_NUMBER$',
    ConsNumber = '$CONS_NUMBER$',
}


export enum SourceDetail {
    History = 'history',
    Favorites = 'favorites',
    SearchHitlist = 'hitlist-search',
    FindAuthorHitlist = 'hitlist-find-author',
    FindBookHitlist = 'hitlist-find-book',
    FindCaseCollectionHitlist = 'hitlist-find-case-collection',
    FindCommentaryHitlist = 'hitlist-find-commentary',
    FindJournalHitlist = 'hitlist-find-journal',
    FindLawHitlist = 'hitlist-find-law',
    NewsHitlist = 'hitlist-news',
    Biblio = 'biblio',
    EuBiblio = 'biblio-eu',
    BooksBiblio = 'biblio-books',
    BooksUniBiblio = 'biblio-books-uni',
    SerialsBiblio = 'biblio-serials',
    CommentariesBiblio = 'biblio-commentaries',
    CaseCollectionBiblio = 'biblio-casecollections',
    JorunalBiblio = 'biblio-journals',
    DeepLink = 'deep-link',
    Document = 'document',
    DocumentCitedIn = 'document-citedin',
    DocumentLink = 'document-link',
    DocumentTocForward = 'document-toc-forward',
    DocumentTocBackward = 'document-toc-backward',
    DocumentTopLink = 'top-viewed-docs',
    DocumentGraphLink = 'top-viewed-docs-graph',
    BookTocDocumentLink = 'book-toc-document-link',
    PeriodicalTocDocumentLink = 'periodical-toc-document-link',
    EuFindHitlist = 'hitlist-find-eu',
    EuSearchHitlist = 'hitlist-search-eu',
    EuTocDocumentLink = 'eu-toc-document-link',
    EuDocumentFromCelex = 'eu-from-celex',
}

export enum AssetRefOptions {
    // citation
    'CitedDocument' = 'citeddoc',
    'DocumentCites' = 'doccites',
    'EdocTitleRef' = 'titleref',
    'EdocTitleRefTocOnly' = 'titlereftoc',
    'assetLanguage' = 'asasetlang',
    'sourceDetail' = 'source',
}

export enum AssetDisplayType {
    'Undefined' = 'undefined',

    // Documents
    'MissingDocument' = 'missing',
    'CommentaryDocument' = 'commentarydoc',
    'OfficialPupDocument' = 'official',
    'CaseLawReviewDocument' = 'clawrev',
    'CaseLawDocument' = 'claw',
    'LawText' = 'law',
    'BookDocument' = 'bookdoc',
    'EssayDocument' = 'essay',
    'LawDocument' = 'lawdoc',
    'ArticleOfLawDocument' = 'aol',
    'LegacyLawDocument' = 'legacylaw',
    'UnknownDocument' = 'unknown',
    'Folder' = 'folder',

    //EuDocuments
    'EuDoc' = 'eu',
    'EuCaseLawDocument' = 'claweu',
    'EuLawDocument' = 'laweu',

    //Preview
    'Previews' = 'previews',
    'PreviewLaw' = 'previewlaw',
    'PreviewDoc' = 'previewdoc',

    // Collections
    'PeriodicalCollection' = 'collection',
    'Book' = 'book',
    'Commentary' = 'commentary',

    // Publication
    'PeriodicalPublication' = 'publication',
    'UnknownPublication' = 'unknownpup',

    //EuColletions
    'EuToc' = 'eutoc',
}

export enum HitlistType {
    'Search' = 'search',
    'JournalSearch' = 'journal',
    'CaseLawPubSearch' = 'casepub',
    'CaseLawNonPubSearch' = 'casenonpub',
    'CommetarySearch' = 'commentary',
    'BookSearch' = 'books',
    'AuthorSearch' = 'author',
    'LawSearch' = 'law',
    'EuSearch' = 'eulaws',
    'EuDirectSearch' = 'eudirect',
    'News' = 'news',
}

export type RefType = HitlistType | AssetDisplayType | AssetRefOptions;

export namespace RefType {
    export function isHitlist(refType: RefType): boolean {
        switch (refType) {
            case HitlistType.Search:
            case HitlistType.JournalSearch:
            case HitlistType.CaseLawPubSearch:
            case HitlistType.CaseLawNonPubSearch:
            case HitlistType.CommetarySearch:
            case HitlistType.BookSearch:
            case HitlistType.AuthorSearch:
            case HitlistType.LawSearch:
            case HitlistType.EuSearch:
            case HitlistType.EuDirectSearch:
            case HitlistType.News:
                return true;
            default:
                return false;
        }
    }
}

export namespace AssetDisplayType {
    export function canHaveCitations(assetType: AssetDisplayType) {
        switch (assetType) {
            case AssetDisplayType.BookDocument:
            case AssetDisplayType.CaseLawDocument:
            case AssetDisplayType.CaseLawReviewDocument:
            case AssetDisplayType.CommentaryDocument:
            case AssetDisplayType.EssayDocument:
            case AssetDisplayType.OfficialPupDocument:
            case AssetDisplayType.UnknownDocument:
                return true;
            default:
                return false;
        }
    }
}

