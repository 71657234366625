import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AccountService } from '../../../account';
import { LanguageEnum } from '../../../models';

@Component({
    selector: 'slx-about-board',
    templateUrl: './about-board.component.html',
    styleUrls: ['./about-board.component.scss'],
})
export class AboutBoardComponent implements OnInit, OnDestroy {
    public language;
    public languageEnum = LanguageEnum;
    private onLangChangeSubscription: Subscription;

    constructor(private accountService: AccountService) {

        this.language = accountService.langEnum;

        this.onLangChangeSubscription = accountService.onLangChange.subscribe(res => {
            this.language = accountService.langEnum;
        });

     }

    ngOnInit() { }

    ngOnDestroy(): void {
        this.onLangChangeSubscription.unsubscribe();
    }
}

