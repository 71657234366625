import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';

import { euRetrievalPdfUrl, getNewsContent, getUserFavoritesUrl, retrievalPdfUrl, searchHistoryUrl, searchUrls, SlxHttp, userSearchFiltersUrl } from '../access';
import { AccountService } from '../account';
import { AppActions } from '../appActions';
import { AssetActions, AssetService } from '../asset';
import * as config from '../config';
import { ImmerFieldActions } from '../field';
import {
    AppState, AssetDisplayType, AssetRef, AssetRefOptions, AssetTypeGroup, checkIfExistsInStringEnum, HitlistType,
    isActionInProgress, LegalActTypeGroup, makeAction, MulticheckItem, NewsType, RechercheState, RefType, ResultsViewState, SearchTransactionInfo, SearchType, SlxAction, SourceDetail, TransactionInfoBase, TransactionType
} from '../models';
import { AlertType } from '../models/state/commonApp';
import { FrontendCache } from '../utility/frontendCache';
import { routingDebug } from '../utility/utilityFunctions';

import { SearchActions } from './searchActions';

export { RechercheState };

const debug = require('debug')('search');
const maxAgeCacheInHours = 12;
interface SearchResult {
    assetTypeFacet: Array<object>;
    errors: Array<any>;
    euLegalActFacet: any;
    hits: Array<any>;
    languageFacet: Array<object>;
    numberOfHits: number;
    numberOfHitsWithPremium: number;
    searchType: number;
    spellSuggestion: string;
    transactionID: number;
}

// interface SearchNeeds {
//     history: boolean; userFavorites: boolean; pubFilters: boolean;
// }

@Injectable()
export class SearchService {

    static instance: SearchService;

    // shortcut
    public actions = SearchActions;

    public state: Observable<RechercheState> = this.store.select('recherche');

    private lastHitlistID: number | string | null;
    private lastHitlistSubscription: Subscription;

    public newsCache: FrontendCache<string, any[]>;

    private profilePrefs;

    constructor(
        private slxHttp: SlxHttp,
        public store: Store<AppState>,
        private accountService: AccountService,
        private assetService: AssetService,
        private translateService: TranslateService,
        private dialog: MatDialog
    ) {

        this.newsCache = new FrontendCache<string, any[]>(maxAgeCacheInHours, (url: string) => {
            return this.slxHttp.get(url);
        });


        if (SearchService.instance) { return SearchService.instance; }
        SearchService.instance = this;

        // When logged in + on recherche tab, get Info
        const initialInfoSubscription = this.store.select((state: AppState) => {
            const loggedIn = state.access.loggedIn;
            const loggedOut = state.access.loggedOut;
            const relevantMainTab = (state.home.mainTab === 'rech' || state.home.mainTab === 'news');
            this.profilePrefs = state.customerProfile.profilePrefs;

            return (relevantMainTab && loggedIn && !loggedOut);
        }).pipe(filter(triggerGetInfo => triggerGetInfo), withLatestFrom(this.store.select('recherche') as Observable<RechercheState>))
            .subscribe(([triggerGetInfo, rechState]) => {
                this.getHistory(rechState);
                this.getUserSearchFilters();
                this.getUserFavorites();
                this.getPublicationFilterOptions();
                this.getPracticeAreaFilters();
                this.getDocumentCategoryFilters();
                this.dispatch({ type: AppActions.load_profile_prefs.name });
            });

        this.lastHitlistSubscription = this.store.select((state: AppState) => state.recherche.hitlist && state.recherche.hitlist.id || state.recherche.transactionID).subscribe(value => {
            this.lastHitlistID = value;
        });
    }

    // public loggedIn = this.store.select(state => state.access.loggedIn);

    // general, drilldown, paging in progress
    public fullSearchInProgress = this.store.select((state) => typeof state.recherche.searchInProgress === 'boolean' && state.recherche.searchInProgress);
    public searchBoxInProgress = this.store.select((state) => !state.recherche.searchCollapsed && (state.recherche.searchInProgress || state.recherche.assetInProgress.length));
    public drilldownDateInProgress = this.store.select((state) => state.recherche.searchInProgress && state.recherche.searchInProgress.sortOrder === 3);
    public drilldownRelevanceInProgress = this.store.select((state) => state.recherche.searchInProgress && state.recherche.searchInProgress.sortOrder === 1);
    public pagingInProgress = this.store.select((state) => state.recherche.searchInProgress && state.recherche.searchInProgress.hasOwnProperty('page') && !state.recherche.searchInProgress.newHitlistSize);
    public hitlistSizeChangeInProgress = this.store.select((state) => state.recherche.searchInProgress && state.recherche.searchInProgress.hasOwnProperty('page') && state.recherche.searchInProgress.newHitlistSize);

    public searchError = this.store.select((state) => state.recherche.error);
    public searchCollapsed = this.store.select((state) => state.recherche.searchCollapsed);
    public hits = this.store.select((state) => state.recherche.hitlist.hits);
    public hitlist = this.store.select((state) => state.recherche.hitlist);
    public isSearchHitlist = this.store.select((state) => state.recherche.hitlist && state.recherche.hitlist.type === HitlistType.Search);
    public isEuSearchHitlist = this.store.select((state) => state.recherche.hitlist && state.recherche.hitlist.type === HitlistType.EuSearch);
    public isNewsHitlist = this.store.select((state) => state.recherche.hitlist && state.recherche.hitlist.type === HitlistType.News);
    public histlistEmpty = this.store.select((state) => state.recherche.hitlist.numberOfHits === 0);
    public numberOfHits = this.store.select((state) => state.recherche.hitlist.numberOfHits);
    public hitlistOpen = this.store.select((state) => state.recherche.hitlistOpen);
    public sidebarSize = this.store.select((state) => state.recherche.sidebarSize);
    public verticalHitlist = this.store.select((state) => state.recherche.verticalHitlist);
    public filterOpen = this.store.select((state) => state.recherche.filterOpen);
    public hitlistPdfLoading = this.store.select((state) => state.recherche.hitlistPdfLoading);
    public drilldownfilterLoading = this.store.select((state) => state.recherche.drilldownfilterLoading);
    public fontSizeInSideBar = this.store.select((state) => state.recherche.fontSizeInSideBar);

    public hitlistSize = this.store.select(state => state.customerProfile.profilePrefs.hitlistSize);

    // Current recherchetab
    public activeRechercheTab = this.store.select((state) => state.recherche.rechTab);

    // Language drilldown
    public languageFacet = this.store.select((state) => state.recherche.languageFacet);
    public languageFacetNonEmpty = this.store.select((state) => state.recherche.languageFacet && state.recherche.languageFacet.length > 0);
    public resetLanguageShown = this.store.select((state) => !state.recherche.effectiveDrillDownFilter.languageValue ? false : state.recherche.effectiveDrillDownFilter.languageValue !== 0);

    // Asset Type drilldown
    public assetTypeFacet = this.store.select((state) => state.recherche.assetTypeFacet);
    public assetTypeFacetNonEmpty = this.store.select((state) => state.recherche.assetTypeFacet && state.recherche.assetTypeFacet.length > 0);
    public resetAssetTypeShown = this.store.select((state) => !state.recherche.effectiveDrillDownFilter.assetTypeGroupValue ? false : state.recherche.effectiveDrillDownFilter.assetTypeGroupValue !== 0);

    // Transaction lists in search
    public recentTransactions = this.store.select(state => state.recherche.rechTab === 'eulaws' || state.recherche.rechTab === 'eudirect' ? state.recherche.recentEuTransactions : state.recherche.recentTransactions);
    public olderTransactions = this.store.select(state => state.recherche.rechTab === 'eulaws' || state.recherche.rechTab === 'eudirect' ? state.recherche.olderEuTransactions : state.recherche.olderTransactions);

    // User Search Filters
    public userSearchFilters = this.store.select((state) => state.recherche.userSearchFilters);

    // User Favorites
    public userFavorites = this.store.select((state) => state.recherche.userFavorites ? state.recherche.userFavorites : []);

    public practiceAreaFilters = this.store.select((state) => state.recherche.practiceAreaFilters || []);
    public documentCategoryFilters = this.store.select((state) => state.recherche.documentCategoryFilters);
    public legalActCategoryFilters = this.store.select((state) => state.recherche.legalActCategoryFilters);

    public advancedSearch = this.store.select((state) => state.recherche.advancedSearch);

    public resultsViewState = this.store.select((state) => ({
        hitlist: !state.recherche.hitlist,
        primary: state.recherche.primaryTabState.assetTabs && state.recherche.primaryTabState.assetTabs.length > 0,
        secondary: state.recherche.secondaryTabState.assetTabs && state.recherche.secondaryTabState.assetTabs.length > 0,
        both: state.recherche.primaryTabState.assetTabs && state.recherche.primaryTabState.assetTabs.length > 0 && state.recherche.secondaryTabState.assetTabs && state.recherche.secondaryTabState.assetTabs.length > 0,
        split: state.recherche.assetSplit,
        hitlistAndDetails: state.recherche.hitlist && (
            state.recherche.primaryTabState.assetTabs && state.recherche.primaryTabState.assetTabs.length > 0 ||
            state.recherche.secondaryTabState.assetTabs && state.recherche.secondaryTabState.assetTabs.length > 0
        ),
    } as ResultsViewState));
    public searchIsExpanded = this.store.select((state) => !state.recherche.searchCollapsed);
    public primaryTabState = this.store.select((state) => state.recherche.primaryTabState);
    public secondaryTabState = this.store.select((state) => state.recherche.secondaryTabState);
    public searchFormRetrieval = this.store.select((state) => state.recherche.search);

    public collectionTab = this.store.select((state) => state.recherche.collectionTab);

    // hasInfo : SeachNeed = {};
    // needBaseInformation(needs: SearchNeed) {
    //     for(const n in needs) {
    //         if (!this.hasInfo[n]) {
    //             this.requestInfo(n);
    //             //this[`get_${n}`]();
    //             this.hasInfo[n] = true;
    //         }
    //     }
    // }
    // requestInfo(key) {
    //     switch(key) {
    //         case 'history': this.getHistory(); break;
    //         case 'userFavorites': this.getUserFavorites(); break;
    //         case 'userSearchFilters': this.getUserSearchFilters(); break;
    //     }
    // }


    dropdownSearch(url: string): Observable<any> {
        return this.slxHttp.get(url);
    }

    isActionInProgress(...types): Observable<boolean> {
        return this.store.select(state => isActionInProgress(state, ...types));
    }

    dispatch(action) {
        this.store.dispatch(makeAction(action as SlxAction));
    }

    prepareSearch(state: AppState) {
        const url = searchUrls[state.recherche.search.type];
        const paging = { CurrentPage: state.recherche.paging.currentPage, HitsPerPage: state.customerProfile.profilePrefs.hitlistSize };
        const searchParams = { ...state.recherche.search.parameters, paging, drillDownFilter: state.recherche.drillDownFilter };
        debug('Prepared search', searchParams, url);
        return {
            url, searchParams,
        };
    }

    searchResultToAction(payload, state: AppState) {
        //for NEWS: the numberOfHits is limited (backend newsletterHitlistPagingSize) to 100 result max
        let alert = undefined;

        if (payload.type.toLowerCase() === HitlistType.News) {
            if (payload.numberOfHits > config.newsletterHitlistPagingSize) {
                //payload.numberOfHits = config.newsletterHitlistPagingSize;
                alert = { key: 'rech-hits-limit-news', limit: config.newsletterHitlistPagingSize, duration: 10, id: 'recherche-search-result', icon: 'info' };
            }
            if (payload.configuration) {
                //payload.configuration.searchFilter.dateFrom = this.setDateNewsForSearchForm(payload.configuration);
                this.dispatch({ type: SearchActions.search_fields_loaded_with_news.name, payload: { searchFilter: payload.configuration.searchFilter, searchType: SearchType.Retrieval, hitlistType: HitlistType.News, overwriteSearchForm: payload.configuration.type.value === NewsType.Search } });
            }
        }

        if (payload.numberOfHits > 0) {
            return { type: SearchActions.search_result.name, payload: { ...payload, hitlistSize: state.customerProfile.profilePrefs.hitlistSize }, alert };
        }

        alert = { type: AlertType.Warning, key: 'rech-no-result', duration: 10, id: 'recherche-search-result' };
        if (payload.numberOfHits === 0 && payload.spellSuggestion && payload.spellSuggestion.length > 0) {
            // console.log(payload.spellSuggestion);
            const actionpayload = {
                type: state.recherche.search.type,
                parameters: {
                    ...state.recherche.search.parameters,
                    searchText: payload.spellSuggestion,
                },
                forceFormSearchTextFromSearch: true,
            };
            alert = {
                type: AlertType.Info,
                key: 'rech-no-result-but-spellSuggestion', actions: [
                    { type: SearchActions.search_fields_submit.name, injectX: 'spellSuggestion', linkText: payload.spellSuggestion, payload: actionpayload },
                ],
            };
        }
        return { type: SearchActions.search_has_no_results.name, alert };
    }

    public getNews(id: string): Observable<any> {
        const url = `${getNewsContent}${id}&language=${this.accountService.langEnum}`;
        return this.newsCache.getContent(url);
    }
    public forceReloadNews(id: string) {
        const url = `${getNewsContent}${id}&language=${this.accountService.langEnum}`;
        return this.newsCache.forceReload(url);
    }

    setDateNewsForSearchForm(configuration) {
        const dateFrom = new Date();
        dateFrom.setDate(dateFrom.getDate() - configuration.deliveryConfiguration.timeWindow);
        dateFrom.setHours(10, 0, 0, 0);
        return dateFrom.toISOString();
    }

    prepareHistoryUpdate(forEu: boolean, state: RechercheState) {
        const action = forEu ? 'GetEuTransactionsSince' : 'GetTransactionsSince';
        const currentTransactions = forEu ? state.recentEuTransactions.concat(state.olderEuTransactions) : state.recentTransactions.concat(state.olderTransactions);
        const sinceID = currentTransactions && currentTransactions.length > 0 ? currentTransactions[0].id : 0;
        return `${searchHistoryUrl}/${action}?maxNumberOfDistinctTransactions=${config.historyMaxNumberOfTransactions}&sinceID=${sinceID}&lang=${this.accountService.lang}`;
    }

    private getHistory(state: RechercheState) {
        forkJoin(
            this.slxHttp.get(this.prepareHistoryUpdate(false, state)),
            this.slxHttp.get(this.prepareHistoryUpdate(true, state)))
            .subscribe(([recentTrans, recentEuTrans]) => {
                this.dispatch({ type: SearchActions.recent_transactions.name, payload: recentTrans });
                this.dispatch({ type: SearchActions.recent_eu_transactions.name, payload: recentEuTrans });
            });
    }

    public getUserSearchFilters() {
        this.slxHttp.get(userSearchFiltersUrl)
            .subscribe(payload => this.dispatch({ type: SearchActions.user_search_filters.name, payload }));
    }

    public getUserFavorites() {
        this.slxHttp.get(`${getUserFavoritesUrl}?language=${this.accountService.lang}`)
            .subscribe(payload => this.dispatch({ type: AssetActions.user_favorites.name, payload: { favorites: payload } }));
    }

    private getPublicationFilterOptions() {
        this.dispatch({ fetch: SearchActions.get_publication_filter_options.name });
    }

    public getPracticeAreaFilters() {
        this.dispatch({ fetch: SearchActions.get_practice_areas_for_filter.name });
    }
    public getDocumentCategoryFilters() {
        const payload = {};
        this.dispatch({ fetch: SearchActions.get_document_categories_for_filter.name, payload });
    }

    public getLegaACtCategoryFilters() {
        const payload = {};
        this.dispatch({ fetch: SearchActions.get_legal_act_categories_for_filter.name, payload });
    }
    public getDocumentCategoriesForFilter() {

        const documentCategories: Array<MulticheckItem> = [];

        this.addOption(documentCategories, AssetTypeGroup.Caselaw.toString(), 'rech-asset-type-group-Caselaw');
        this.addOption(documentCategories, AssetTypeGroup.CaselawReview.toString(), 'rech-asset-type-group-CaselawReview');
        this.addOption(documentCategories, AssetTypeGroup.Commentary.toString(), 'rech-asset-type-group-Commentary');
        this.addOption(documentCategories, AssetTypeGroup.EssayAndBook.toString(), 'rech-asset-type-group-EssayAndBook');
        this.addOption(documentCategories, AssetTypeGroup.OfficialPub.toString(), 'rech-asset-type-group-OfficialPub');

        return of(documentCategories);
    }

    public getLegalActCategoriesForFilter() {

        const legalActCategories: Array<MulticheckItem> = [];

        this.addOption(legalActCategories, LegalActTypeGroup.CaseLaw.toString(), 'rech-hitlist-eu-CaseLaw');
        this.addOption(legalActCategories, LegalActTypeGroup.Treaty.toString(), 'rech-hitlist-eu-Treaty');
        this.addOption(legalActCategories, LegalActTypeGroup.InternationalAgreement.toString(), 'rech-hitlist-eu-InternationalAgreement');
        this.addOption(legalActCategories, LegalActTypeGroup.Legislation.toString(), 'rech-hitlist-eu-Legislation');
        this.addOption(legalActCategories, LegalActTypeGroup.LegislationRegulation.toString(), 'rech-hitlist-eu-LegislationRegulation');
        this.addOption(legalActCategories, LegalActTypeGroup.LegislationDirective.toString(), 'rech-hitlist-eu-LegislationDirective');
        this.addOption(legalActCategories, LegalActTypeGroup.LegislationDecision.toString(), 'rech-hitlist-eu-LegislationDecision');
        this.addOption(legalActCategories, LegalActTypeGroup.LegislationNonOpposition.toString(), 'rech-hitlist-eu-LegislationNonOpposition');
        this.addOption(legalActCategories, LegalActTypeGroup.ComDocuments.toString(), 'rech-hitlist-eu-ComDocuments');
        this.addOption(legalActCategories, LegalActTypeGroup.EftaDocuments.toString(), 'rech-hitlist-eu-EftaDocuments');

        return of(legalActCategories);
    }

    private addOption(cat: Array<MulticheckItem>, optKey: string, optTranslate: string) {
        const opt = {
            key: optKey,
            value: '',
            translate: optTranslate,
        };
        cat.push(opt);
    }

    prepareSavePdf(source: string = null, state: AppState) {
        // Error 500 on Server at the time of commit
        const searchType = state.recherche.search.type;
        const baseUrl = (searchType === SearchType.Retrieval) ? retrievalPdfUrl : euRetrievalPdfUrl;

        const lastSearchParams = this.prepareSearch(state).searchParams;
        const language = this.accountService.lang;
        const url = `${baseUrl}?transactionId=${state.recherche.transactionID}&source=${source}&language=${language}&tryHighlight=${this.profilePrefs.pdfHitListTryHighlight}`;

        return { url };
    }

    savePdfLocal(response) {
        // const fileBlob = response.blob();
        const blob = new Blob([response], { type: 'application/pdf' });
        const hitlistPdf = this.translateService.instant('rech-hitlist-pdf') + '.pdf';
        FileSaver.saveAs(blob, hitlistPdf);
    }

    isEuSearchType(searchType): boolean {
        switch (searchType) {
            case SearchType.EuRetrieval: return true;
            case SearchType.EuCaseReference: return true;
            case SearchType.EuTreaty: return true;
            case SearchType.EuInternationalAgreement: return true;
            case SearchType.EuLegislation: return true;
            case SearchType.EuCelexNumber: return true;

            default:
                return false;
        }
    }

    public resolveRoute(routeInformation) {
        // routingDebug('search.service','resolveRoute', 'NO NAVIGATION');
        const id = routeInformation.id ? (routeInformation.id as string).toLowerCase() : null;
        const subId = routeInformation.subid ? (routeInformation.subid as string).toLowerCase() : null;
        const option = routeInformation.option;
        const source = routeInformation.source && checkIfExistsInStringEnum(SourceDetail, routeInformation.source)
            ? routeInformation.source : SourceDetail.DeepLink;
        const queryParams = routeInformation.queryParams;
        if (id) {
            if (id !== 'new') {
                if (String(this.lastHitlistID) !== id) {
                    // next tick => queryparams updated
                    Promise.resolve(null).then(() => this.redoPreviousAction(routeInformation.subtype as RefType, id, null, source, subId, option, queryParams));
                }
            }
            else {
                routingDebug('search.service', 'resolveRoute', 'Action: set_rech_tab', 'payload:', routeInformation.subtype);
                // just set the correct rechTab
                this.store.dispatch({ type: SearchActions.set_rech_tab.name, payload: routeInformation.subtype });
            }
            // this.dispatch({ type: SearchActions.set_collection_tab.name, payload: 'hitlist' });
        }

        // TODO OtherCollectionTabResolver()
        // history / favorites / bookmarks / ..
        else {
            routingDebug('search.service', 'resolveRoute', 'Action: set_collection_tab', 'payload:', routeInformation.subtype);
            this.dispatch({ type: AppActions.set_collection_tab.name, payload: { collectionTab: routeInformation.subtype, preventUrlFromStore: true } });
        }
    }

    public rerunTransaction(transactionInformation, event: MouseEvent, source: string = '') {
        let openHistoryCollectionTab = true;

        switch (transactionInformation.type as TransactionType) {
            case TransactionType.DocView:
                this.redoPreviousAction(AssetDisplayType.UnknownDocument, transactionInformation.assetID, event, source);
                break;
            case TransactionType.TocView:
                this.redoPreviousAction(AssetDisplayType.UnknownPublication, transactionInformation.assetID, event, source);
                break;
            case TransactionType.Search:
                openHistoryCollectionTab = false;
                this.redoPreviousAction(HitlistType.Search, transactionInformation.id, event, source);
                break;
            case TransactionType.EuSearch:
                openHistoryCollectionTab = false;
                this.redoPreviousAction(HitlistType.EuSearch, transactionInformation.id, event, source);
                break;
            case TransactionType.EuDocView:
                this.redoPreviousAction(AssetDisplayType.EuDoc, transactionInformation.assetID, event, source);
                break;
            case TransactionType.LawView:
                transactionInformation.aolID ?
                    this.redoPreviousAction(AssetDisplayType.ArticleOfLawDocument, transactionInformation.aolID, event, source, transactionInformation.lawID) :
                    this.redoPreviousAction(AssetDisplayType.LawDocument, transactionInformation.lawID, event, source);
                break;
            default:
                openHistoryCollectionTab = false;
                break;
        }

        if (openHistoryCollectionTab) {
            this.dispatch({ type: AppActions.set_collection_tab.name, payload: { collectionTab: 'history' } });
        }
    }

    public createTransactionAssetsRefs(transactionInformation): AssetRef {
        switch (transactionInformation.type) {
            case TransactionType.DocView:
                return AssetRef.create(AssetDisplayType.UnknownDocument, transactionInformation.assetID);
            case TransactionType.EuDocView:
                return AssetRef.create(AssetDisplayType.UnknownDocument, transactionInformation.assetID);
            case TransactionType.EuSearch:
                return [HitlistType.EuSearch, transactionInformation.id];
            case TransactionType.LawView:
                return transactionInformation.aolID ?
                    AssetRef.create(AssetDisplayType.ArticleOfLawDocument, transactionInformation.aolID, { publicationId: transactionInformation.lawID }) :
                    AssetRef.create(AssetDisplayType.LawDocument, transactionInformation.lawID);
            case TransactionType.Search:
                return [HitlistType.Search, transactionInformation.id];
            case TransactionType.TocView:
                return AssetRef.create(AssetDisplayType.UnknownPublication, transactionInformation.assetID);
            default:
                return [];
        }
    }

    public switchToMainTab() {
        this.store.dispatch({ type: AppActions.set_main_tab.name, payload: 'rech' });
    }

    private redoSearch(refType: RefType, id: string, queryParams?: Params) {
        if (refType === HitlistType.News) {
            this.store.dispatch({ type: SearchActions.load_newsletter.name, payload: { id } });
        }
        else {
            switch (id) {
                case 'external':

                    this.store.dispatch(makeAction({
                        type: SearchActions.search_fields_loaded_with_submit.name,
                        payload: {
                            searchFilter : {
                                commentaryArticleNumber: queryParams['article'],
                                commentaryLaw: queryParams['law'],
                                sourceDetails: queryParams['source'],
                            },
                            searchType: SearchType.Commentary,
                            language: this.accountService.langEnum,
                        },
                    }));

                    break;
                default:
                    this.store.dispatch({
                        type: SearchActions.rerun_search_transaction.name, payload: {
                            id, type: TransactionType.Search,
                            data: { doSearch: true, isEuSearch: refType === HitlistType.EuSearch || refType === HitlistType.EuDirectSearch },
                        },
                    });
            }

        }
    }

    private getTransactionInformation(refType: RefType, transactionInformation, event: MouseEvent) {
        this.store.dispatch({
            type: SearchActions.get_transaction_info.name, payload: {
                refType, transactionInformation, type: TransactionType.Search, event,
                data: { doSearch: true, isEuSearch: refType === HitlistType.EuSearch || refType === HitlistType.EuDirectSearch },
            },
        });
    }

    public redoStatisticsTransaction(transactionInfo, event: MouseEvent, transactionDetails?: TransactionInfoBase) {
        transactionInfo.type = transactionDetails.transactionType;
        switch (transactionDetails.transactionType) {
            case TransactionType.TocView:
                transactionInfo.assetID = transactionInfo.tocRequestedGuid;
                break;
            default:
                transactionInfo.assetID = transactionInfo.docAssetGuid;
        }
        this.rerunTransaction(transactionInfo, event);
        this.switchToMainTab();
    }

    public redoPreviousAction(refType: RefType, id: string, event: MouseEvent, source: string = '', subid?: string, option?: string, queryParams?: Params) {

        if (RefType.isHitlist(refType)) {
            this.redoSearch(refType, id, queryParams);
        }
        else {

            if (refType === AssetDisplayType.LegacyLawDocument) {
                this.store.dispatch({ type: AssetActions.handle_legacy_law_documents.name, payload: { lawSr: id, articleNumber: subid } });
            }
            else {
                if (RefType.isHitlist(option as RefType)) {
                    const ref = AssetRef.create(refType as AssetDisplayType, id, { source });
                    this.assetService.openAsset(ref, true, event);
                    this.redoSearch(option as RefType, subid);
                }
                else {
                    const ref = option ? AssetRef.create(refType as AssetDisplayType, id, { citationInfo: { type: AssetRefOptions.CitedDocument, id: subid }, source })
                        : AssetRef.create(refType as AssetDisplayType, id, { publicationId: subid, source });
                    this.assetService.openAsset(ref, true, event);
                }

            }
            // this.accountService.showTipIfNecessary('/doc');
        }
    }

    initialValuesForEuMultiCheck = this.store.select(state => this.getInitialValuesForEuMultiCheck(state.recherche));

    getInitialValuesForEuMultiCheck(state: RechercheState) {
        const tempLegalAct = [];
        state.legalActCategoryFilters.forEach(element => {
            tempLegalAct.push(element.key);
        });
        return {
            legalActTypeGroupsCriteria: tempLegalAct,
        };
    }

    initialValuesForMultiChecks = this.store.select(state => this.getInitialValuesForMultiChecks(state.recherche));

    getInitialValuesForMultiChecks(state: RechercheState) {
        const tempDoc = [];

        (state.documentCategoryFilters || []).forEach(element => {
            tempDoc.push(element.key);
        });

        const tempPracticeArea = [];
        (state.practiceAreaFilters || []).forEach(element => {
            tempPracticeArea.push(element.key);
        });

        return {
            practiceAreaGroupsCriteria: tempPracticeArea,
            assetTypeGroupsCriteria: tempDoc,
        };
    }

    getRerunSearchTransactionActions(transactionInfo: SearchTransactionInfo, transactionID) {

        if (transactionInfo.transactionType === TransactionType.Unknown) {
            //return makeAction({ type: SearchActions.search_error.name, 'rech-rerunError', payload => ({ payload }), { duration: 8, id: 'searchError', icon: 'search' })
            return [{
                type: SearchActions.no_transaction_info.name, alert: { type: AlertType.Warning, key: 'rech-no-transaction-info', duration: 5 },
            }];
        }

        const actions = [
            makeAction({
                type: SearchActions.search_fields_loaded_with_submit.name,
                payload: { ...transactionInfo, transactionID },
            }),
        ];
        if (transactionInfo.searchFilter.thesaurusType === 2) {
            actions.push(makeAction({ result: ImmerFieldActions.load_thesaurus_information.name, payload: transactionInfo.searchFilter.thesaurusInformation }));
        }
        return actions;
    }

    public createSearchAbo(searchForm) {
        this.dispatch({ type: SearchActions.create_search_abo.name, payload: searchForm, navigate: ['/news'] });
    }
}
