import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AppActions } from '../../appActions';
import { makeAction, StoreBackup } from '../../models';
import { AccessActions } from '../accessActions';
import { LoginService } from '../login.service';


@Component({
    selector: 'slx-hitlist',
    templateUrl: './storeRestore.component.html',
    styleUrls: ['./storeRestore.component.scss'],
})
export class StoreRestoreComponent implements OnInit, OnDestroy {

    private loginSubscription: Subscription;

    constructor(private route: ActivatedRoute, public loginService: LoginService) {
    }

    ngOnInit(): void {
        const idOrKey = this.route.snapshot.queryParamMap.get('idOrKey');
        this.loginSubscription = this.loginService.loggedIn.pipe(filter((loggedIn) => loggedIn)).subscribe(() => {
            const action = idOrKey ? { type: AppActions.restore_store.name, payload: idOrKey } : makeAction({ type: AccessActions.load_custom_store_backups.name });
            this.loginService.dispatch(action);
        });
    }

    public restoreStore(id: string) {
        this.loginService.dispatch({ type: AppActions.restore_store.name, payload: id });
    }

    ngOnDestroy(): void {
        this.loginSubscription.unsubscribe();
    }
}
