import { Component } from '@angular/core';

import { LoginService } from '../../access';
import { AccountService } from '../../account';
import { unCryptMailto } from '../../field';

@Component({
    selector: 'slx-home-tiles',
    templateUrl: './homeTiles.component.html',
    styleUrls: ['./homeTiles.component.scss'],
})
export class HomeTilesComponent {
    public noInfoMdFound = true;

    constructor(public accountService: AccountService, public loginService: LoginService) { }

    onNoMdFound(event) {
        this.noInfoMdFound = event;
    }

    linkToUnCryptMailto(s) {
        location.href = unCryptMailto(s);
    }
}
