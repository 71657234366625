import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseChartDirective } from 'ng2-charts/ng2-charts';
import { Observable ,  Subscription } from 'rxjs';

import { AccountService } from '../../account';
import { AppActions } from '../../appActions';
import { AssetService } from '../../asset';
import { AssetDisplayType, AssetRef, AssetRefOptions, DrilldownType, emptyHitlist, Hitlist, HitlistType, LanguageEnum } from '../../models';
import { SearchService } from '../search.service';
import { SearchActions } from '../searchActions';

import { HitlistSizeOptions, NewsHitlist } from './../../models/research';

// corresponds to colors in all-deep-css.scss
const COLOR = {
    Caselaw: '#6bc5ff',
    CaselawReview: '#e4d42e',
    Commentary: '#d56d7a',
    EssayOnlyBook: '#f3a536',
    EssayNonBook: '#a3c251',
    OfficialPub: '#275699',
    EssayAndBook: '#025123',
};

const COLOR_EU = {
    Treaty: '#275699',
    InternationalAgreement: '#e4d42e',
    Legislation: '#02ccb4',
    LegislationRegulation: '#d56d7a',
    LegislationDirective: '#f3a536',
    LegislationDecision: '#a3c251',
    LegislationNonOpposition: '#000000',
    ComDocuments: '#025123',
    CaseLaw: '#6bc5ff',
    EftaDocuments: '#a50998',
    Undefined: '#c1c1c1',
};

@Component({
    selector: 'slx-hitlist',
    templateUrl: './hitlist.component.html',
    styleUrls: ['./hitlist.component.scss'],
})
export class HitlistComponent implements OnDestroy {
    @ViewChild('chart') chart: BaseChartDirective;
    @ViewChild('hitlistViewchild') hitlistViewchild: ElementRef;

    private searchResultSubscription: Subscription;

    public hitlist: Hitlist = emptyHitlist;
    public hitlistSizeOptions = HitlistSizeOptions;
    public pagination: any = {};
    public searchInProgress: any = false;
    public drillDownFilter: any;
    public searchTypeIsEu: boolean;

    public currentPrimaryAssetID: string;
    public currentSecondaryAssetID: string;
    public tocItems: any[];

    public assetTypeFilterCounts: number[];
    public assetTypeFilterDescriptions: string[];
    public assetTypeFilterValues: any[];
    public datasets: any[];
    public colors: any[] = [{ backgroundColor: [] }];
    public assetTypeFilterColors = [];

    private recentTransactions = [];
    private olderTransactions = [];

    private doScrollTop: boolean;

    public hitlistPdfLoading: Observable<boolean>;
    public drilldownType = DrilldownType;
    public selectedDrilldownElement;

    public options: any = {
        cutoutPercentage: '88',
        animation: { animationRotate: true, animateScale: true },
        responsive: true,
    };

    appActions = AppActions;

    constructor(
        public searchService: SearchService, public assetService: AssetService, public translate: TranslateService, private accountService: AccountService) {

        this.hitlistPdfLoading = this.searchService.isActionInProgress(SearchActions.save_hitlist_pdf.name);

        this.searchResultSubscription = searchService.state.subscribe(
            state => {
                this.searchInProgress = state.searchInProgress;
                this.drillDownFilter = state.drillDownFilter;

                this.tocItems = state.tocItems ? state.tocItems : null;

                this.currentPrimaryAssetID = state.primaryTabState.currentAssetTab ? state.primaryTabState.currentAssetTab.assetID : null;
                this.currentSecondaryAssetID = state.secondaryTabState.currentAssetTab ? state.secondaryTabState.currentAssetTab.assetID : null;

                if (state.hitlist !== this.hitlist) {
                    // hitlist has changed
                    this.hitlist = state.hitlist;
                    this.searchTypeIsEu = this.hitlist.type === HitlistType.EuDirectSearch || this.hitlist.type === HitlistType.EuSearch;

                    if(state.hitlist.type === HitlistType.News && !(state.hitlist as NewsHitlist).configuration){
                        this.searchService.dispatch({ type: SearchActions.get_news_config_by_id.name, payload: state.hitlist.id });
                    }

                    this.pagination = state.paging;

                    this.assetService.updateRecentlyViewed(this.hitlist);

                    if (state.assetTypeFacet && state.assetTypeFacet.length > 0) {
                        this.initAssetTypeList(state.assetTypeFacet, this.searchTypeIsEu);

                    } else {
                        this.initDefaultChart(this.hitlist.numberOfHits);
                    }
                    if (this.doScrollTop) {
                        this.doScrollTop = false;
                        Promise.resolve(null).then(() => this.hitlistViewchild.nativeElement.scrollTop = 0);
                    }
                }
                else if (state.olderTransactions !== this.olderTransactions || state.recentTransactions !== this.recentTransactions) {
                    // transactions changed
                    this.olderTransactions = state.olderTransactions;
                    this.recentTransactions = state.recentTransactions;
                    this.assetService.updateRecentlyViewed(this.hitlist);
                }
                // when executing a search (searching/sorting/paging) force the scrollTop
                if (state.searchInProgress) {
                    this.doScrollTop = true;
                }
            });
    }


    // updateRecentlyViewed(state) {
    //     const viewTransactions = [...state.recentTransactions, ...state.olderTransactions].filter(t => t.assetID);
    //     this.hitlist.hits.forEach(h => {

    //         if (viewTransactions.find(vt => vt.assetID === h.targetID)) {
    //             h.recentlyViewed = true;
    //         }
    //     });
    // }

    ngOnDestroy() {
        this.searchResultSubscription.unsubscribe();
    }

    public asNewsHitlist (hitlist: Hitlist): NewsHitlist{
        return hitlist as NewsHitlist;
    }

    public resetCharts() {
        this.assetTypeFilterCounts = [];
        this.assetTypeFilterDescriptions = [];
        this.assetTypeFilterValues = [];
        this.assetTypeFilterColors = [];
        this.datasets = [{
            data: [],
            borderWidth: [],
            borderColor: [],
            options: {},
        }];
    }

    public initAssetTypeList(assetTypeFilterList, isEuSearchType) {
        this.resetCharts();


        this.assetTypeFilterCounts = assetTypeFilterList.map((element: any) => element.count);
        this.assetTypeFilterValues = assetTypeFilterList.map((element: any) => element.assetTypeGroup.value);
        if (isEuSearchType) {
            this.assetTypeFilterDescriptions = assetTypeFilterList.map((element: any) => this.translate.instant('rech-hitlist-eu-' + element.assetTypeGroup.description));
            this.assetTypeFilterColors = assetTypeFilterList.map((element: any) => COLOR_EU[element.assetTypeGroup.description]);
        } else {
            this.assetTypeFilterDescriptions = assetTypeFilterList.map((element: any) => this.translate.instant('rech-hitlist-' + element.assetTypeGroup.description));
            this.assetTypeFilterColors = assetTypeFilterList.map((element: any) => COLOR[element.assetTypeGroup.description]);
        }

        this.colors = [{ backgroundColor: this.assetTypeFilterColors }];

        // we have to make this trick beacuse the Charts component does not update [labels] and [colors].
        if (this.chart) {
            this.chart.chart.config.data.labels = this.assetTypeFilterDescriptions;
            this.chart.chart.config.data.datasets[0].backgroundColor = this.assetTypeFilterColors;
        }

        this.datasets = [{
            data: this.assetTypeFilterCounts,
            hoverBorderWidth: '5',
            hoverBorderColor: '#fafafa',
        }];
    }

    public initDefaultChart(numberOfHits) {
        this.resetCharts();

        this.assetTypeFilterCounts = [numberOfHits];
        this.assetTypeFilterDescriptions = [this.translate.instant('rech-hitlist-hits')];
        this.assetTypeFilterValues = [];
        this.assetTypeFilterColors = ['#275699'];

        this.colors = [{ backgroundColor: this.assetTypeFilterColors }];

        this.datasets = [{
            data: this.assetTypeFilterCounts,
            hoverBorderWidth: '5',
            hoverBorderColor: '#fafafa',
        }];
        if (this.chart) {
            this.chart.chart.config.data.labels = this.assetTypeFilterDescriptions;
            this.chart.chart.config.data.datasets[0].backgroundColor = this.assetTypeFilterColors;
        }
    }

    public chartClicked(e) {
        if (e.active[0]) {
            this.doDrilldownfilter({ assetTypeGroupValue: this.assetTypeFilterValues[e.active[0]._index], drilldownType: this.drilldownType.FilterType, selectedDrilldownElement: this.assetTypeFilterValues[e.active[0]._index]});
        }
    }

    public openAsset(item, event: MouseEvent) {
        const assetID = AssetRef.toAssetID(item.ref);
        if (item.targetType !== AssetDisplayType.MissingDocument) {
            const ref = item.languages && item.languages.length > 0 ? this.addLangOptions(item.ref,this.getLanguagesForAsset(item.languages)) : item.ref;
            this.assetService.openAsset(ref, true, event);
        }
    }

    private addLangOptions(ref: AssetRef, languages: AssetRef): AssetRef {
        const sourceIndex = ref.findIndex(r => r === AssetRefOptions.sourceDetail);
        if (sourceIndex < 0) {
            return [ ...ref, ...languages];
        }
        const source = ref.splice(sourceIndex);
        return [...ref, ...languages, ...source];
    }

    private getLanguagesForAsset(languages): AssetRef {
        if (!languages.find(lang => lang === this.accountService.langEnum)) {
            return [AssetRefOptions.assetLanguage, LanguageEnum[languages[0]].toLowerCase()];
        }
        return [];
    }

    public toggleSubhits(item) {
        item.subHitsToggled = !item.subHitsToggled;
    }

    public toggleResize() {
        this.searchService.dispatch({ type: SearchActions.toggle_hitlist_open.name });
    }

    public toggleFilterResize() {
        this.assetService.dispatch({ type: SearchActions.toggle_filter_open.name });
    }

    public hitlistSizeChanged(hitlistSize: Number) {
        this.searchService.dispatch({type: AppActions.save_profile_prefs.name, payload: { hitlistSize}});
        this.searchService.dispatch({type: SearchActions.hitlist_page_submit.name, payload: { currentPage: 1, hitlistSize }});
    }

    public setPage(page: number) {
        this.searchService.dispatch({ type: SearchActions.hitlist_page_submit.name, payload: { currentPage: page }});
    }

    public doDrilldownfilter(payload) {
        this.selectedDrilldownElement = payload.selectedDrilldownElement;
        this.searchService.dispatch({ type: SearchActions.hitlist_drilldown_submit.name, payload });
    }

    public getPdf() {
        this.searchService.dispatch({ save: SearchActions.save_hitlist_pdf.name });
    }
}
