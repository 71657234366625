import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { DateAdapter, MatInput } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { format, isDate, isValid } from 'date-fns';

import { toDate, toISODateString } from '../../utility/date-format';
import { getValidationMessage } from '../validators';

const debug = require('debug')('slx-date');

@Component({
    selector: 'slx-date',
    template: `
  	<mat-form-field [class.slx-field-error]="showError" >
        <input matInput [matDatepicker]="picker" #input
            [placeholder]="placeholder"
            [matDatepickerFilter]="myFilter"
            (keypress)="onKeypress($event)"
            (keyup.enter)="onChange($event)"
            (dateChange)="onChange($event)"
            (input)="inputDate($event)"
            (blur)="checkValidationOnBlur($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker (selectedChanged)="calenderPicked($event)" #picker startView="month"></mat-datepicker>
        <mat-hint *ngIf="showError">{{ getValidationMessage(formControl, translate) }}</mat-hint>
    </mat-form-field>
	`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DateComponent),
            multi: true,
        },
    ],
})

export class DateComponent implements ControlValueAccessor, OnInit, Validator {

    public getValidationMessage: (formControl: any, translate: any) => string = getValidationMessage;

    @Input() name: string;
    // @Input() value: any;
    @Input('placeholder') _placeholder: string;
    @Input() disabled: boolean;
    @Input() formControlName: String;
    @Input() minDate: any;
    @Input() maxDate: any;
    @Input() isRequired = false;

    @ViewChild(MatInput) inputEl: MatInput;

    private innerValue = '';
    public formControl: FormControl;
    public showValidationResults = false;

    constructor(private formBuilder: FormBuilder, public translate: TranslateService) {
    }

    ngOnInit() { }

    get placeholder() {
        if (this._placeholder) {
            return this.isRequired ? `${this.translate.instant(this._placeholder)} *` : this.translate.instant(this._placeholder);
        } else {
            return null;
        }
    }

    private propagateChange = (_: any) => { };

    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    get value(): string {
        return this.innerValue;
    }

    set value(v: string) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.propagateChange(this.innerValue);
        }
    }

    public onKeypress(event) {
        if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'Enter' || event.keyCode === 46 /* . and . from numlock (key === 'Del' in IE11)*/) {
            return true;
        }

        if (!/[0-9\.:\s]/.test(event.key)) {
            event.preventDefault();
            return false;
        }
    }

    public validate(c: FormControl) {
        this.formControl = c;
        if (c.value === null) {
            this.showValidationResults = false;
        }
        return {};
    }

    get showError() {
        return this.formControl.invalid && (this.showValidationResults || this.formControl.touched);
    }

    public checkValidationOnBlur(event) {
        this.showValidationResults = event.target.value.length > 0;
    }

    public inputDate(event) {
        const { value } = event.target;
        const date = toDate(value, { strict: true });
        debug('input', 'value=', value, date, format(date, 'DD.MM.YYYY'));
        if (isDate(date) && isValid(date)) {
            this.value = toISODateString(date); // set/propogate cleaned up value (string)
        }
        else {
            this.value = ''; // set/propogate nothing to avoid adding a dateValidator to all dates
        }
    }

    onChange(event) {
        //const date = this.inputEl.value; // instanceof Date
        if (this.inputEl.value) {
            const date = toDate(this.inputEl.value, { strict: true });
            debug('change', 'value=', date, event);
            if (isDate(date) && isValid(date)) {
                //   this.inputEl.value = date;
                this.value = toISODateString(date);
                this.inputEl.value = this.value;
            }
        }
    }

    calenderPicked(event) {
        this.propagateChange(toISODateString(event));
    }


    public writeValue(str: string | Date) {
        if (str) {
            this.value = String(str);
            const date = toDate(<string>str, { strict: true });
            if (isDate(date) && isValid(date)) {
                // this.inputEl.value = date;
                this.inputEl.value = this.value;
            }
        }
        // reset value
        else {
            this.value = '';
            this.inputEl.value = '';
        }
    }

    myFilter = (date: Date): boolean => {
        let result = true;
        if (this.minDate) {
            result = date >= this.minDate;
        }
        if (this.maxDate) {
            result = result && date < this.maxDate;
        }
        return result;
    }

    // not used yet, touch support
    public registerOnTouched() {
    }
}
