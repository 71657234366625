import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatInputModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';

import { NotFoundResolver } from '../access';
import { AccountModule, AccountService } from '../account';
import { FieldModule } from '../field';

import { HomeModule } from './../home';
import { CurrentJobsComponent } from './currentJobs/currentJobs.component';
import { JobsComponent } from './jobs.component';
import { JobsResolver } from './jobs.resolver';
import { JobsService } from './jobs.service';
import { JobsEffects } from './jobsEffects';
import { SingleJobComponent } from './singleJob/singleJob.component';
import { TopJobsComponent } from './topJobs/topJobs.component';

export const jobsRoute = {
    path: 'jobs',
    component: JobsComponent,
    children: [
        { path: '', redirectTo: 'current', pathMatch: 'full' },

        {
            path: 'current', component: CurrentJobsComponent, resolve: {
                jobs: JobsResolver,
            },
        },
        {
            path: 'single/:id', component: SingleJobComponent, resolve: {
                jobs: JobsResolver,
            },
        },
        {
            path: 'single', component: SingleJobComponent, resolve: {
                jobs: JobsResolver,
            },
        },
        {
            path: '**', component: CurrentJobsComponent, resolve: {
                jobs: JobsResolver,
                notFound: NotFoundResolver,
            },
        },
    ],
};

@NgModule({
    imports: [
        CommonModule,
        AccountModule,
        ReactiveFormsModule,
        RouterModule,
        FieldModule,
        MatButtonModule,
        MatInputModule,
        HomeModule,
        EffectsModule.forFeature([JobsEffects]),
    ],
    declarations: [
        JobsComponent,
        CurrentJobsComponent,
        SingleJobComponent,
        TopJobsComponent,
    ],
    providers: [
        JobsService,
        JobsResolver,
    ],
})
export class JobsModule {
    constructor(private account: AccountService) {
        this.account.addTranslation('jobs-',
            require('./jobs.de.yaml'),
            require('./jobs.fr.yaml')
        );
    }
}
