export  interface Alert {
    type?: AlertType;
    key?: string;
    text?: string;
    actions?: any[];
    fromAction?: string;
    appendActions?: any[];
    injectActions?: any;
    duration?: number;
    dismissAt?: number; // millis
    transientFrom?: number;
    data?: any;
    issueId?: string;
    id?: string;
    noLinkMargin?: boolean;
    /**
     * If flagged sticky it will not be considered transient and remain on interactions.
     */
    sticky?: boolean;
}

export enum AlertType {
    Info = 'info',
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
}

export enum ActionState {
    InProgress = 'inProgress',
    Success = 'success',
    Error = 'error',
    Cleared = 'cleared',
}

export interface CommonAppState {
    lastStateOfActions: {};
    appErrors: any[];
    storeRestoreKey: string;
    alerts: Alert[];
    browserWindowSize: number;
}

export const defaultCommonAppState: CommonAppState = {
    lastStateOfActions: {},
    appErrors: [],
    alerts: [],
    storeRestoreKey: null,
    browserWindowSize: null,
};



let uniqueAlertId = 1111;

export function withAddedAlert(state: CommonAppState, payload, key?, extra?) {
    const newAlert = coerceAlert(typeof key === 'string' ? { ...extra, key, more: payload } : payload);
    const alerts = [newAlert].concat(state.alerts.filter(alert => (alert.id !== newAlert.id)).map((alert) => ({ ...alert })));
    return {
        ...state,
        alerts,
    };
}

function coerceAlert(a) {
    const now = Date.now();
    let dismissAt;
    if (a.duration) {
        dismissAt = now + a.duration * 1000;
    }
    const actions = (a.actions || []).map((action) => ({ ...action, id: uniqueAlertId++ }));
    const appendActions = actions.filter(action => !action.inject);
    const injectActions = actions.reduce((injectActions, action) => {
        if (action.inject) {
            injectActions[action.inject] = action;
        }
        return injectActions;
    }, {});
    let transientFrom;
    /* auto dismiss on interaction alerts unless
       error, sticky, warning without timeout
     */
    if (!a.sticky && a.type !== AlertType.Error && !(a.type === AlertType.Warning && !dismissAt)) {
        transientFrom = now + 1000; // will be transient after 1sec
    }

    return { ...a, id: a.id || uniqueAlertId++, dismissAt, transientFrom, actions, appendActions, injectActions, fromAction: a.fromAction };
}

export function isAlertOutdated(alert: Alert) {
    if (!alert.dismissAt) return false;

    const now = Date.now();
    if ((alert.dismissAt as any).getTime) {
        return (alert.dismissAt as any).getTime() < now;
    } else {
        return alert.dismissAt < now;
    }
}
