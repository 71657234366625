module.exports = {
	"seo-title-default": "Swisslex",
	"seo-title-recherche": "Recherche",
	"seo-title-recherche-history": "Verlauf",
	"seo-title-recherche-favorites": "Favoriten",
	"seo-title-recherche-search-new": "Allgemein",
	"seo-title-recherche-casepub-new": "Publizierte Rechtsprechung",
	"seo-title-recherche-casenonpub-new": "Nicht publizierte Rechtsprechung",
	"seo-title-recherche-journal-new": "Zeitschriften",
	"seo-title-recherche-commentary-new": "Kommentare",
	"seo-title-recherche-book-new": "Bücher",
	"seo-title-recherche-author-new": "Autor",
	"seo-title-recherche-law-new": "Erlasse des Bundes",
	"seo-title-recherche-eulaws-new": "Suche EU-Recht",
	"seo-title-recherche-eudirect-new": "Direkzugriff EU-Recht",
	"seo-title-doc": "{{assetTitle}} | Recherche",
	"seo-title-toc": "{{assetTitle}} | Recherche",
	"seo-title-news": "News",
	"seo-title-biblio": "Bibliothek",
	"seo-title-biblio-casecollections-published": "Rechtsprechung Bund und Kantone (publiziert)",
	"seo-title-biblio-casecollections-unpublished": "Rechtsprechung Bund und Kantone (nicht publiziert)",
	"seo-title-biblio-journals": "Zeitschriften",
	"seo-title-biblio-commentaries": "Kommentare",
	"seo-title-biblio-books": "Bücher",
	"seo-title-biblio-serials": "Reihen",
	"seo-title-biblio-eu": "EU-Recht",
	"seo-title-jobs": "Jobbörse",
	"seo-title-jobs-single": "{{jobTitle}}",
	"seo-title-events": "Veranstaltungen",
	"seo-title-events-current": "Aktuelle Veranstaltungen",
	"seo-title-events-register": "Veranstaltung eintragen",
	"seo-title-events-details": "{{eventTitle}}",
	"seo-title-account-forgotpassword": "Passwort vergessen",
	"seo-title-customerprofile": "Kundenprofil",
	"seo-title-customerprofile-userprofile": "Benutzerprofil",
	"seo-title-customerprofile-preferences": "Einstellungen",
	"seo-title-customerprofile-customer": "Kundendaten",
	"seo-title-customerprofile-departments": "Abteilungen",
	"seo-title-customerprofile-users": "Benutzer",
	"seo-title-customerprofile-statistics": "Nutzungsstatistik",
	"seo-title-service": "Abo & Services",
	"seo-title-service-standard": "Swisslex Standard",
	"seo-title-service-premium": "Swisslex Premium",
	"seo-title-service-abofees": "Abonnementsgebühren",
	"seo-title-service-business": "Geschäftskunden",
	"seo-title-service-students": "Universitätsangehörige",
	"seo-title-service-lawexamcandidates-rak-subscription": "Kandidaten Rechtsanwaltsprüfung",
	"seo-title-service-training": "Schulungsangebot",
	"seo-title-service-agb": "AGB",
	"seo-title-service-disclaimer": "Haftungsausschluss",
	"seo-title-about": "Über Swisslex",
	"seo-title-about-company": "Unternehmen",
	"seo-title-about-shareholders": "Aktionäre",
	"seo-title-about-team": "Team",
	"seo-title-about-direction": "Lageplan",
	"seo-title-about-contact": "Kontakt",
	"seo-title-faq": "FAQ",
	"seo-title-faq-common": "Allgemein",
	"seo-title-faq-workspace": "Arbeitsbereich",
	"seo-title-faq-search": "Suchfilter",
	"seo-title-faq-view": "Newsletter",
	"seo-title-faq-wizard": "Newsletter-Assistent",
	"seo-description-default": "Swisslex - Mit Recht die Nr. 1 der Schweiz! Jetzt das neue Swisslex 4 Wochen kostenlos testen.",
	"seo-description-news": "Immer auf dem aktuellsten Stand der Rechtsprechung! Neue Inhalte eidgenössischer & kantonaler Gerichte, sowie Fach- und Onlinezeitschriften in Swisslex oder per Mail.",
	"seo-description-biblio-casecollections-published": "Rechtsprechung, Zeitschriften, Kommentare, Bücher, Reihen & EU-Recht kompakt als Bibliothek aufbereitet und durchsuchbar.",
	"seo-description-biblio-casecollections-unpublished": "Nicht publizierte Inhalte der Rechtsprechung Bund und Kantone, sortier- und durchsuchbar u.a nach Kanton, Publikationstitel, Gericht / Herausgeber oder Datum.",
	"seo-description-biblio-journals": "Alle 79 Fach- und Onlinezeitschriften auf Swisslex im Überblick. Durchsuchen, sortieren oder direkt einen persönlichen Newsletter erstellen.",
	"seo-description-biblio-commentaries": "Übersichtliche Darstellung von mehr als 350 Kommentaren aus dem Swisslex-Archiv, geordnet nach Rechtsgebiet, durchsuch- und sortierbar.",
	"seo-description-biblio-books": "Jetzt Überblick über in Swisslex verfügbare Bücher gewinnen. Aufbereitet nach Rechtsgebiet und mit Such- und Sortiermöglichkeit.",
	"seo-description-biblio-serials": "Von Abhandlungen zum Schweizerischen Recht bis zu den Zürcher Studien zum Privatrecht, verschaffen Sie sich Überblick über verfügbare Reihen in Swisslex.",
	"seo-description-biblio-eu": "EU-Recht: Verträge, Int. Abkommen, Rechtsvorschriften, Rechtsprechung, COM - Legislativvorschläge der Kommission und EFTA-Dokumente, als Übersicht aufbereitet.",
	"seo-description-jobs-current": "Finden oder inserieren Sie Jobs im juristischen Bereich mit der Swisslex Jobbörse. Kooperation mit der NZZ, zahlreiche Angebote, aktuell und durchsuchbar.",
	"seo-description-events-current": "Aktuelle Events im juristischen Bereich entdecken oder dort eintragen, wo sich Juristen aufhalten. Zuordnung nach Rechtsgebiet, Kooperation mit Schulthess.",
	"seo-description-service-standard": "Swisslex Standard: Nutzen Sie intelligente Suchwerkzeuge, um auf über 560.000 juristische Dokumente im ständig wachsenden Swisslex-Archiv zuzugreifen.",
	"seo-description-service-premium": "Swisslex Premium: Suchen und finden in Rechtsliteratur, Fachzeitschriften & Praxisgebieten. Aktualitätsservice für den aktuellsten Stand der Rechtsprechung.",
	"seo-description-service-abofees": "Faire Gebühren für den Zugriff auf das umfangreiche Swisslex-Archiv. EU-Recht, Standard-Tax und Swisslex-Premium sind zusätzlich buchbar.",
	"seo-description-service-business": "Registrierungsformular ausfüllen und für einen kostenlosen Testmonat Zugriff auf das gesamte Swisslex-Archiv mit über 560.000 juristischen Dokumenten erhalten.",
	"seo-description-service-students": "Swisslex Standard & Swisslex Premium, kostenlos für immatrikulierte Studenten.",
	"seo-description-service-lawexamcandidates-rak-subscription": "Vergünstigter Zugang (CHF 34.- / Monat, exkl. MwSt.) zu Swisslex für Anwaltsprüfungskandidaten mit abgeschlossenem Praktikum.",
	"seo-description-service-training": "Mit einer kostenlosen Schulung Swisslex-Kentnisse auffrischen, Portalfunktionen besser kennenlernen und noch effizienter Suchen. Jetzt Termin vereinbaren!",
	"seo-description-service-agb": "Allgemeine Geschäftsbedingungen (AGB) der Swisslex - Schweizerische Juristische Datenbank AG.",
	"seo-description-service-disclaimer": "Haftungsausschluss der Swisslex - Schweizerische Juristische Datenbank AG.",
	"seo-description-about-company": "Swisslex - Mit Recht die Nr. 1 der Schweiz. Anbieterin der führenden Rechtsinformationsplattform der Schweiz. Informationen suchen, sicher finden und vernetzt anwenden.",
	"seo-description-about-shareholders": "Schulthess Juristische Medien AG, die Stämpfli Verlag AG und Genfer Rechtsanwalt Me Jacques Python sind Hauptaktionäre der Swisslex juristischen Datenbank AG.",
	"seo-description-about-team": "Swisslex Geschäftsleitung und Team im Überblick. Sehen Sie Geschäftsführer und Teammitglieder von Swisslex auf einen Blick.",
	"seo-description-about-direction": "Sie finden Swisslex in 8050 Zürich, Schaffhauserstrasse 418, oder in 1201 Genf, Rue du Mont-Blanc 21.",
	"seo-description-about-contact": "Haben Sie Fragen oder möchten Kontakt aufnehmen? Sie erreichen unser Swisslex Hotline-Team an Werktagen von 8.30 - 17.00 Uhr unter 0800 587 463 oder per Mail."
};