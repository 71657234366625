import { Component } from '@angular/core';

import { LoginService } from '../access/';

import { HomeService } from './../home/';


@Component({
  selector: 'slx-customer-profile',
  templateUrl: './customerProfile.component.html',
  host: { 'class': 'sidebar-layout' },
  styleUrls: ['./customerProfile.component.scss'],
})
export class CustomerProfileComponent {

  constructor(public loginService: LoginService, public homeService: HomeService) { }
}
