import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { LoginService } from '../access';
import { HomeService } from '../home';


@Component({
  selector: 'slx-bibliothek',
  templateUrl: './bibliothek.component.html',
  styleUrls: ['./bibliothek.component.scss'],
})
export class BibliothekComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();
  public hasUserTeachingBook: boolean;
  
  constructor(private route: ActivatedRoute, private router: Router, public homeService: HomeService, private loginService: LoginService) { }

  ngOnInit() {
    const params = { queryParams: { 'categorieInitial': this.route.snapshot.queryParams['categorieInitial'] }};

    this.subscription
    .add(this.loginService.hasUserTeachingBook.subscribe(hasUserTeachingBook =>
        this.hasUserTeachingBook = hasUserTeachingBook
    ));

    switch (this.route.snapshot.queryParams['tabInital']) {
      case '2':
        this.router.navigate(['/biblio/books'], params);
        break;
      case '3':
        this.router.navigate(['/biblio/commentaries'], params);
        break;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
