import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions, RowClickedEvent } from 'ag-grid-community';
import { differenceInMilliseconds } from 'date-fns';

import { toDate } from '../../utility/date-format';
import { createHrefWrapperForCellrender } from '../../utility/utilityFunctions';
import { JobsService } from '../jobs.service';

@Component({
    selector: 'slx-current-jobs',
    template: `
        <slx-grid [options]="gridOptions" [columnDefs]="columnDefs" [title]="'jobs-current'" [rowTranslatePrefix]="'jobs-grid'"
            [rowData]="jobsService.jobListForGrid"  (rowClicked)="onRowClicked($event)">
        </slx-grid>
    `,
    styles: [`
        :host{
            display: block;
            width: 95%;
            max-width: 1600px;
            height: 100%;
            margin: 0 auto;
        }
    `],
})
export class CurrentJobsComponent {

    public gridOptions: GridOptions;
    public columnDefs: any[];

    constructor(public jobsService: JobsService, public router: Router) {

        this.gridOptions = <GridOptions>{
            headerHeight: 50,
            rowHeight: 50,
        };

        this.columnDefs = [
            {
                field: 'publishDate',
                sort: 'desc',
                sortingOrder: ['desc', 'asc'],
                minWidth: 110,
                maxWidth: 110,
                comparator: this.dateComparator,
                suppressMenu: true,
            },
            {
                field: 'employerName',
                minWidth: 250,
                maxWidth: 350,
                suppressMenu: true,
                cellRenderer: this.hrefCellRenderer,
            },
            {
                field: 'title',
                minWidth: 300,
                suppressMenu: true,
                cellRenderer: this.hrefCellRenderer,
            },
            {
                field: 'workplace',
                minWidth: 150,
                maxWidth: 250,
                suppressMenu: true,
            },
        ];
    }

    //-------------------------------------------------------------------------------------
    // Formatters, Comperators, CellRenderers Header Translations
    //-------------------------------------------------------------------------------------

    private dateComparator(date1: string, date2: string): number {
        return differenceInMilliseconds(toDate(date1), toDate(date2));
    }

    private hrefCellRenderer(params: any): HTMLAnchorElement {
        return createHrefWrapperForCellrender(params.value, `/jobs/single/${params.data.id}`);
    }

    //-------------------------------------------------------------------------------------
    // Events & Listeners
    //-------------------------------------------------------------------------------------

    public onRowClicked(rowEvent: RowClickedEvent): void {
        const mouseEvent = rowEvent.event as MouseEvent;
        if (!mouseEvent || !mouseEvent.ctrlKey) {
            this.router.navigate(['jobs/single', rowEvent.data.id]);
        }
    }
}
