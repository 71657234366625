import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoginService } from '../../../access';
import { AccountService } from '../../../account';
import { HomeService } from '../../home.service';
import { HomeActions } from '../../homeActions';

@Component({
    selector: 'slx-faq',
    templateUrl: './faq.component.html',
    host: { 'class': 'sidebar-layout' },
    styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit, OnDestroy {

    private subscription: Subscription = new Subscription();
    private fragment: string;

    public faq;
    private formData: any;
    public isAuthorized: Observable<boolean>;

    constructor(public homeService: HomeService, private account: AccountService, private route: ActivatedRoute, private router: Router, private translate: TranslateService, public loginService: LoginService) { }

    ngOnInit() {
        this.formData = this.route.snapshot.data;
        this.isAuthorized = this.loginService.isSuperUser.pipe(map(isSuperUser => {
            const requiresAutherized = '/faq/entry';
            const isAuthorized = isSuperUser || !(this.router.url.substring(0, requiresAutherized.length) === requiresAutherized);
            return isAuthorized;
        }));

        this.subscription
            .add(this.homeService.faq.subscribe(faq => {
                if (faq) {
                    this.faq = faq[this.getFaqTopicByID(this.formData.topicID)];
                    this.tryScroll();
                }
            }))
            .add(this.route.fragment.subscribe(fragment => {
                if (fragment && fragment.length > 0) {
                    this.fragment = fragment;
                    this.tryScroll();
                }
            }))
            .add(this.account.onLangChange.subscribe(() => {
                if (this.formData.topicID) {
                    this.homeService.dispatch({ fetch: HomeActions.get_faq_by_topic_id.name, payload: { topicID: this.formData.topicID } });
                }
            }));
    }

    private tryScroll() {
        setTimeout(() => {
            const anchor = document.getElementById(this.fragment);
            if (anchor) {
                anchor.scrollIntoView();
            }
        });
    }

    private getFaqTopicByID(topicID: string): string {
        switch (topicID) {
            case 'd08049f6-935a-4ca3-b2fe-38b6d9d6853d':
                return 'common';
            case '0e70e583-57e9-4810-9add-6d718cfbee3f':
                return 'workspace';
            case 'a7215660-fa73-4341-8d9e-277445948b5c':
                return 'search';
            case 'c597f7a0-1322-45c4-b23d-99093950f89d':
                return 'view';
            case '4e2a777f-2bca-40c6-a99d-69f570a323cc':
                return 'wizard';
            case '7edd4830-82a1-4eec-b28e-2fdebecb21b0':
                return 'settings';
            default:
                return '';
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
