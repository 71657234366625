import { Asset, BiblioState, enhanceActionsObject } from '../models';

export const BibliothekActions = {

    load_eu_verzeichnisse(state: BiblioState, { payload, result }) {
        if(result){
            const asset = new Asset();
            asset.id = undefined;
            asset.content = {
                ...payload.content,
                idToOpen: undefined,
                referringAssetID: undefined,
            };

            return {
                ...state,
                euTocModel: asset,
            };
        }
        return state;
    },

    create_news_abo(state: BiblioState, { payload }) {
        return state;
    },

};
enhanceActionsObject(BibliothekActions);
