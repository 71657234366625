module.exports = {
	"rech-search": "Général",
	"rech-journal": "Revues",
	"rech-caseCollection": "Jurisprudence",
	"rech-casepub": "Jurisprudence publiée",
	"rech-casenonpub": "Jurisprudence non publiée",
	"rech-commentary": "Commentaires",
	"rech-book": "Livres",
	"rech-author": "Auteur",
	"rech-decree": "Législation fédérale",
	"rech-eulaws": "Droit UE",
	"rech-searchError": "Erreur – critères de recherche invalides",
	"rech-rerunError": "Erreur – critères de recherche invalides",
	"rech-historyError": "Une erreur est survenue lors du téléchargement du processus de recherche.",
	"rech-newsletterError": "Une erreur est survenue lors du téléchargement des contenus.",
	"rech-newFolder": "Nouveau dossier",
	"rech-enter": "Enregistrer",
	"rech-defer": "Réinitialiser la saisie",
	"rech-bookmark": "Marque-pages",
	"rech-searchHistory": "Historique",
	"rech-sidebar-actions": "Actions",
	"rech-favorites": "Favoris",
	"rech-hitlist": "Résultat(s)",
	"rech-last-hours": "Dernières {{recentHours}} heures",
	"rech-older-hours": "Plus ancien que {{recentHours}} heures",
	"rech-emptySearch": "Sans texte de recherche",
	"rech-no-favorites-yet": "Aucun favori n’a encore été créé.",
	"rech-delete-complete-folder": "Supprimer le dossier et son contenu?",
	"rech-delete-complete-folder-text": "La suppression d’un dossier inclus la suppression de son <strong>contenu</strong>! <br> Pas d’annulation possible.",
	"rech-delete-complete-folder-link": "J’ai compris, ne plus afficher ce message.",
	"rech-no-history-entry": "Aucune action pouvant être affichée dans l’historique n’a encore été effectuée (recherche / ouvertures de documents).",
	"rech-to-statistics": "Vers la statistique d’utilisation",
	"rech-searchtext": "Texte de recherche",
	"rech-law": "Loi",
	"rech-lawTitle": "Titre de l'acte législatif",
	"rech-arcticle-short": "art.",
	"rech-paragraph-short": "al.",
	"rech-subparagraph-short": "ch./let.",
	"rech-dateFrom": "Date du",
	"rech-dateUntil": "Date au",
	"rech-reference": "Référence",
	"rech-clear": "Supprimer la saisie",
	"rech-searching": "Rechercher",
	"rech-show-search": "Afficher la recherche",
	"rech-display": "Afficher",
	"rech-year": "Année",
	"rech-case-abbreviation": "Abréviation / nom du recueil de jurisprudence",
	"rech-case-year": "Année / Vol.",
	"rech-case-page": "Page / numéro du jugement",
	"rech-pub-authors": "Auteur/Éditeur",
	"rech-pub-edition": "Édition",
	"rech-pub-yearofpublication": "Année",
	"rech-pub-publisher": "maison d'édition",
	"rech-pub-abbreviation": "Abréviation / nom de la revue",
	"rech-pub-year": "Année",
	"rech-pub-issue": "Fascicule / N° de volume",
	"rech-pub-page": "Page",
	"rech-pages": "Pages",
	"rech-published": "Publiée",
	"rech-unpublished": "Non publiée",
	"rech-dossiernumber": "N° de dossier",
	"rech-series": "Collection",
	"rech-title": "Titre",
	"rech-article": "Article",
	"rech-directAccess": "Accès direct",
	"rech-language": "Langue",
	"rech-treaty": "Traités / contrats",
	"rech-interAgreement": "Accords internationaux",
	"rech-legislation": "Prescriptions juridiques",
	"rech-celexNumber": "Accès par numéro Celex",
	"rech-casenumber": "Numéro de l'affaire",
	"rech-agreementcopy": "Intitulé de l'accord",
	"rech-number": "Numéro",
	"rech-celexnr": "Numéro Celex",
	"rech-legislation-type": "Type",
	"rech-legislation-LegislationAll": "Tous",
	"rech-legislation-Legislation": "Prescriptions juridiques",
	"rech-legislation-LegislationRegulation": "Ordonnances",
	"rech-legislation-LegislationDirective": "Directives",
	"rech-legislation-LegislationDecision": "Décisions",
	"rech-legislation-LegislationNonOpposition": "Arrêts",
	"rech-treaty-important": "Traités principaux",
	"rech-treaty-others": "Autres traités",
	"rech-no-result": "Aucun résultat pour votre recherche",
	"rech-no-result-but-spellSuggestion": "Aucun résultat pour votre recherche, mais vouliez vous dire:",
	"rech-advanced-allselected": "tous sélectionnés",
	"rech-advanced-selected": "sélectionnés",
	"rech-advanced-more": "Plus de critères de recherche",
	"rech-advanced-less": "Moins de critères de recherche",
	"rech-practice-area-filter": "Filtrer par domaines du droit",
	"rech-multioptions-all": "Tout selectionner",
	"rech-multioptions-num-of-nums": "{{num}} sur {{nums}} sélectionnés",
	"rech-no-transaction-info": "Cette transaction n’a pas pu être chargée",
	"rech-document-category-filter": "Filtrer par catégories de documents",
	"rech-publication-filter": "Filtrer par publications",
	"rech-asset-type-group-Caselaw": "Arrêts",
	"rech-asset-type-group-CaselawCollection": "Recueil de jurisprudence",
	"rech-asset-type-group-CaselawReview": "Commentaires d'arrêt",
	"rech-asset-type-group-Commentary": "Commentaires",
	"rech-asset-type-group-EssayAndBook": "Articles / Livres",
	"rech-asset-type-group-OfficialPub": "Documents officiels",
	"rech-asset-type-group-Undefined": "Non défini",
	"rech-thesaurus": "Thésaurus de traduction",
	"rech-thesaurus-off": "non",
	"rech-thesaurus-on": "oui",
	"rech-thesaurus-manual": "manuel",
	"rech-thesaurus-type-Undefined": "non défini",
	"rech-thesaurus-type-Unknown": "inconnu",
	"rech-thesaurus-type-Noun": "substantif",
	"rech-thesaurus-type-AdjAdverbPrep": "adjectif/adverbe/préposition",
	"rech-thesaurus-type-Verb": "verbe",
	"rech-thesaurus-type-Phrase": "phrase",
	"rech-thesaurus-type-Pronoun": "pronom",
	"rech-thesaurus-type-Adjective": "adjectif",
	"rech-thesaurus-type-Adverb": "adverbe",
	"rech-thesaurus-type-Preposition": "préposition",
	"rech-thesaurus-type-Article": "Article",
	"rech-thesaurus-tooltip-start": "La",
	"rech-thesaurus-tooltip-base-from": "forme de base",
	"rech-thesaurus-tooltip-tranlsation": "traduction",
	"rech-thesaurus-tooltip-find": "trouve",
	"rech-thesaurus-tooltip-not-find": "ne trouve pas d'autres formes pour le mot",
	"rech-tooltip-title-SearchStandard": "Recherche standard",
	"rech-tooltip-title-EuSearchStandard": "Recherche standard UE",
	"rech-tooltip-title-SearchExtended": "Recherche avancée",
	"rech-tooltip-title-FindAuthor": "Recherche d'un auteur",
	"rech-tooltip-title-FindBook": "Recherche d'un livre",
	"rech-tooltip-title-FindPeriodical": "Recherche d'une revue",
	"rech-tooltip-title-FindJurisprudence": "Recherche d’une jurisprudence",
	"rech-tooltip-title-FindCommentary": "Recherche d'un commentaire",
	"rech-tooltip-title-LawView": "Législation fédérale",
	"rech-tooltip-title-EuDocViewStandard": "Document",
	"rech-tooltip-title-EuDocViewByToc": "Document",
	"rech-tooltip-title-EuFindLegalAct": "Recherche d’autres actes juridiques",
	"rech-tooltip-title-EuFindAgreement": "Recherche d’accords internationaux",
	"rech-tooltip-title-EuFindCaseLaw": "Recherche d’une jurisprudence UE",
	"rech-tooltip-title-EuFindTreaty": "Recherche de traités UE",
	"rech-tooltip-title-EuFindCelexNumber": "Recherche de traités UE",
	"rech-tooltip-title-EuRefineDocType": "Recherche affinée - Langues",
	"rech-tooltip-title-EuRefindeDocLanguage": "Recherche affinée - Type",
	"rech-tooltip-title-EuRefineHitlistOrder": "Recherche affinée - Trier",
	"rech-tooltip-Bücher": "Livres",
	"rech-tooltip-Dokumentenkategorie": "Catégorie de documents",
	"rech-tooltip-Filter_Aktenzeichen": "Document",
	"rech-tooltip-Filter_CelexNr": "Numéro Celex",
	"rech-tooltip-Filter_Dokumententyp": "Filtre par type des documents",
	"rech-tooltip-Filter_Gericht": "Tribunal",
	"rech-tooltip-Filter_TitleBetreff": "Titre / Objet",
	"rech-tooltip-Filter_Urteilsdatum": "Date du jugement",
	"rech-tooltip-Filter_Vertrag": "Traité",
	"rech-tooltip-Filterung_kombiert": "Filtrage combiné",
	"rech-tooltip-Praxisgebietsfilter": "Filtre par domaine juridique",
	"rech-tooltip-Publikationsfilter": "Filtre par publication",
	"rech-tooltip-Rechtssprechung": "Jurisprudence",
	"rech-tooltip-Sprache_Dokumente": "Langue des documents",
	"rech-tooltip-Suchsprache": "Langue de recherche",
	"rech-tooltip-Suchtext": "Texte de recherche",
	"rech-tooltip-Thesaurus": "Thésaurus de traduction",
	"rech-tooltip-aus": "non",
	"rech-tooltip-manuell": "manuel",
	"rech-tooltip-verschiedene": "divers",
	"rech-tooltip-verschiedene_publikationen": "diverses publications",
	"rech-tooltip-verschiedene_Bücher": "divers livres",
	"rech-tooltip-verschiedene_Rechstprechungen": "diverses jurisprudences",
	"rech-tooltip-verschiedene_Zeitschriften": "diverses revues",
	"rech-tooltip-Zeitschrifetn": "Revues",
	"rech-searchfilter-title": "Filtres de recherche",
	"rech-searchfilter-use": "Appliquer filtre de recherche",
	"rech-searchfilter-none": "Absence de filtre de recherche",
	"rech-searchfilter-manage": "Gérer",
	"rech-searchfilter-delete": "Le filtre de recherche a été supprimé.",
	"rech-searchfilter-delete-cancel": "Annuler la suppression",
	"rech-searchfilter-undelete": "Restauration du filtre de recherche",
	"rech-searchfilter-create": "Enregistrer",
	"rech-searchfilter-create-new": "Enregistrer en tant que nouveau filtre de recherche",
	"rech-searchfilter-create-overwrite": "Mettre à jour un filtre de recherche existant",
	"rech-searchfilter-create-placeholder": "Nom du filtre de recherche",
	"rech-searchfilter-save-error": "Erreur lors de la sauvegarde du filtre de recherche",
	"rech-searchfilter-load-error": "Erreur lors du téléchargement des filtres de recherche",
	"rech-searchfilter-apply-error": "Erreur lors de l'application du filtre de recherche",
	"rech-searchfilter-delete-error": "Erreur lors de la suppression du filtre de recherche",
	"rech-pub-type": "",
	"rech-pub-description": "Titre",
	"rech-pub-practiceAreas": "Domaines du droit",
	"rech-pub-location": "Lieu",
	"rech-pup-series": "Séries",
	"rech-pub-parentGroup": "Parent",
	"rech-pup-caseCollections": "Jurisprudences",
	"rech-pup-showAll": "Toutes les publications",
	"rech-pup-selection": "Sélection",
	"rech-pup-selection-num": "{{num}} sur {{nums}}",
	"rech-pup-apply": "Appliquer",
	"rech-pup-saveAsFilter": "Enregistrer en tant que filtre de recherche",
	"rech-pup-selectAll": "Sélectionner tous",
	"rech-pup-selectAllBooks": "Sélectionner tous les livres",
	"rech-pup-selectAllCaseCollections": "Sélectionner toutes les jurisprudences",
	"rech-pup-selectAllJournals": "Sélectionner tous les revues",
	"rech-pup-selectAllSerials": "Sélectionner tous les séries",
	"rech-pup-selectAllCommentaries": "Sélectionner tous les commentaires",
	"rech-pup-reapplySelection": "Rétablir la sélection",
	"rech-pup-deselectAll": "Désélectionner tous",
	"rech-pup-deselectAllBooks": "Désélectionner tous les livres",
	"rech-pup-deselectAllCaseCollections": "Désélectionner toutes les jurisprudences",
	"rech-pup-deselectAllJournals": "Désélectionner toutes les revues",
	"rech-pup-deselectAllSerials": "Désélectionner toutes les séries",
	"rech-pup-deselectAllCommentaries": "Désélectionner tous les commentaires",
	"rech-pup-resetSelection": "Réinitialiser la sélection",
	"rech-pup-no-serial-commentary": "Commentaires sans série",
	"rech-info-thesaurus-help": "Thésaurus de traduction",
	"rech-info-eu_filter-help": "Types de documents UE",
	"rech-info-eu_case-law-help": "Jurisprudence",
	"rech-info-eu_treaty-help": "Traités / contrats",
	"rech-info-eu_agreement-help": "Accords internationaux",
	"rech-info-eu_legislation-help": "Prescriptions juridiques",
	"rech-info-eu_celex-help": "Numéro Celex",
	"rech-suchfilter-delete": "Le filtre de recherche a été supprimé.",
	"rech-suchfilter-cancel": "Annuler la suppression",
	"rech-hits-limit-news": "Seulement les {{limit}} dérniers résultats sont affichés.",
	"rech-tip-of-the-day": "Astuce du jour",
	"rech-hide-tip-of-the-day": "Ne plus afficher «Astuce du jour»",
	"rech-hide-welcome": "Ne plus afficher «Bienvenue sur Swisslex 4.0»",
	"rech-tip-previous": "Astuce précédente",
	"rech-tip-next": "Astuce suivante",
	"rech-toc-year": "Année",
	"rech-toc-issue": "Fascicule",
	"rech-validation-commentarySerial": "Collection",
	"rech-validation-commentaryTitle": "Titre",
	"rech-validation-commentaryLaw": "Loi",
	"rech-validation-law": "Loi",
	"rech-validation-lawTitle": "Titre de l'acte législatif",
	"rech-validation-author": "Auteur",
	"rech-validation-title": "Titre",
	"rech-validation-serial": "Collection",
	"rech-validation-assetTitle": "Titre",
	"rech-validation-authorName": "Auteur",
	"rech-validation-abbreviation": "Abréviation",
	"rech-validation-caseNumber": "Numéro Celex",
	"rech-search-choose-one": "Sélectionnez au moins un des champs suivants : ",
	"rech-no-toc": "Aucune table des matières n’est disponible pour cette publication (Législation fédérale, arrêt non publié).",
	"rech-no-toc-title": "Aucune table des matières",
	"rech-button-desc-form-reset": "Réinitialiser le formulaire de saisie sur des valeurs standard",
	"rech-button-desc-empty-workspace": "Réinitialiser l’ensemble de l’espace travail, y compris la liste des résultats, les documents ouverts, etc.",
	"rech-button-desc-more-searchcriteria": "Ouvrir en dessous plus de critères de recherche tels que date et filtres spécifiques",
	"rech-button-desc-add-law-artcicle": "Ajouter d’autres articles de loi",
	"rech-button-desc-remove-law-artcicle": "Supprimer ces articles de loi",
	"rech-button-desc-searchfilter-save": "Enregistrer des critères de recherche sélectionnés en tant que filtres de recherche",
	"rech-button-desc-searchfilter-manage": "Classer, renommer ou supprimer des filtres de recherche existants",
	"rech-sidebaractions-workbench": "Espace de travail",
	"rech-sidebaractions-close-all-documents": "Fermer tous les documents",
	"rech-sidebaractions-close-primary-documents": "Fermer les documents de la fenêtre principale",
	"rech-sidebaractions-close-secondary-documents": "Fermer les documents de la fenêtre secondaire",
	"rech-sidebaractions-merge-documents": "Joindre tous les documents dans la fenêtre principale",
	"rech-sidebaractions-same-size-for-docs": "Organiser les fenêtres",
	"rech-sidebaractions-misc": "Autres choses",
	"rech-more-nav": "Autres liens",
	"rech-asset-open": "Ouvrir document dans Swisslex",
	"rech-top-docs-practiceAreas": "Les documents les plus lus au cours des 7 derniers jours ordonnés selon les domaines du droit",
	"rech-tip": "Indication",
	"rech-tip-info": "Cliquez sur les cercles pour voir les informations du documents",
	"rech-practiceArea-order": "Domaines du droit <br>selon nombres de vues",
	"rech-load-pubselect-error": "Erreur lors du chargement du filtre par publications"
};