import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable ,  of } from 'rxjs';
import { map } from 'rxjs/operators';

import { LanguageEnum, LemmaType, ThesaurusToggleInformation, ThesaurusToggleItem, ThesaurusTogglePayload, ThesaurusWord, TooltipData } from '../../models';
import { FieldService } from '../field.service';
import { ImmerFieldActions } from '../fieldActions';


@Component({
    selector: 'slx-thesaurus',
    templateUrl: './thesaurus.component.html',
    styleUrls: ['./thesaurs.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ThesaurusComponent),
            multi: true,
        },
    ],
})
export class ThesaurusComponent implements OnInit, OnDestroy, ControlValueAccessor {

    @Input() name: string;
    @Output() thesaursStateChanged = new EventEmitter<number>();


    public selected = 1;
    public options = [
        { value: 1, translate: 'rech-thesaurus-on' },
        { value: 0, translate: 'rech-thesaurus-off' },
        { value: 2, translate: 'rech-thesaurus-manual' },
    ];

    public thesaurusIsLoading: Observable<boolean>;

    constructor(public fieldService: FieldService, private translateService: TranslateService) {
        this.thesaurusIsLoading = this.fieldService.isActionInProgress(ImmerFieldActions.load_thesaurus_information.name);
        this.tooltipCreator = this.tooltipCreator.bind(this);
    }

    ngOnDestroy() {
    }

    ngOnInit() {
        this.propagateChange(this.selected);
    }

    public toggleThesaurusInformation(toggleInfo: ThesaurusToggleInformation, toggleItem: ThesaurusToggleItem) {
        const payload = { ...toggleInfo, toggleItem} as ThesaurusTogglePayload;
        this.fieldService.dispatch({ type: ImmerFieldActions.thesaurus_toggle_information.name, payload });
    }

    public tooltipInformation(word: ThesaurusWord, translateType: string) {
        return { word, translateType};
    }

    public tooltipCreator(translateInfo : {word: ThesaurusWord, translateType: string}) : Observable<TooltipData> {
        return of(translateInfo).pipe(map( ({ word, translateType }) => {
            const lastPart = this.translateService.instant('rech-thesaurus-tooltip-find');
            const tooltipTitle = `${this.translateService.instant('rech-thesaurus-tooltip-start')} ${this.translateService.instant(translateType)} <strong>${word.word}</strong> ${lastPart}`;
            const tooltipBody =word.flexisons.length > 0 ? word.flexisons.join(', ') : this.translateService.instant('rech-thesaurus-tooltip-not-find');
            return { tooltipBody, tooltipTitle };
        }));
    }


    public getLemmaTypeTranslation(type: LemmaType) {
        return `rech-thesaurus-type-${LemmaType[type]}`;
    }

    public getLanguageEnumString(lang: LanguageEnum) {
        return LanguageEnum[lang].toUpperCase();
    }

    private propagateChange = (_: any) => { };

    valueChanged() {
        this.propagateChange(this.selected);
    }

    writeValue(nr: any): void {
        if (nr || nr === 0) {
            this.selected = nr;
        }
        else {
            nr = 1;
        }
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void { }

    setDisabledState(isDisabled: boolean): void { }

    public typeSelected($event,value) {
        this.selected = value;
        this.propagateChange(this.selected);
        this.thesaursStateChanged.emit(this.selected);
        $event.preventDefault();
    }

    public thesaurusStatusChanged($event) {
        this.selected = $event;
        this.propagateChange(this.selected);
        this.thesaursStateChanged.emit(this.selected);
    }

}
