import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';

import { ActiveTocTab, AssetTab, AssetTabstrip, DocumentContent, makeAction } from '../../models';
import { isEmtpyGuid } from '../../utility/utilityFunctions';
import { AssetService } from '../asset.service';
import { AssetActions } from '../assetActions';

const debug = require('debug')('doc');

@Component({
    selector: 'slx-docview',
    templateUrl: './docview.component.html',
    styleUrls: ['./docview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocviewComponent implements OnInit, OnDestroy, OnChanges {

    @Input() assetTab: AssetTab;
    @Input() tabArea: AssetTabstrip;
    @Input() tabIndex: number;

    private scrollEndTimeout: NodeJS.Timer;
    private edocTitleElements: NodeList;
    private scrollListener;
    private clickListener;
    private otherAssetID: string;
    private subscription = new Subscription();
    private activeTab: ActiveTocTab;
    private docContent: DocumentContent;

    public citationInformationSelector: Observable<any>;

    @ViewChild('docContent') docContentElement: ElementRef;
    @HostBinding('class.doc-navigation') docNavigation = false;
    @HostBinding('class.newer-comment') newerComment = false;

    constructor(public assetService: AssetService, private elementRef: ElementRef, private renderer: Renderer2, private changeDetectorRef: ChangeDetectorRef) {
        this.onScroll = this.onScroll.bind(this);
        this.checkTitleElements = this.checkTitleElements.bind(this);
    }

    private onScroll(e: Event) {
        clearTimeout(this.scrollEndTimeout);
        this.scrollEndTimeout = setTimeout(() => {
            this.checkTitleElements();
            //  this.setActiveTocTap();
        }, 100);
    }

    readEdDocTitles() {
        if (!this.edocTitleElements || this.edocTitleElements.length === 0) {
            this.edocTitleElements = this.docContentElement.nativeElement.querySelectorAll('a[name^="edoctitle_"]');
        }
    }

    setActiveTocTap() {
        const tocTab = this.tabArea.isPrimary ? ActiveTocTab.Primary : ActiveTocTab.Secondary;
        if (tocTab !== this.activeTab && this.docContent && this.docContent.navigationInfo && this.docContent.navigationInfo.canShowToc) {
            this.assetService.dispatch({ type: AssetActions.set_active_tab_for_toc.name, payload: tocTab });
        }
    }

    checkTitleElements() {
        this.readEdDocTitles();
        if (!this.edocTitleElements) {
            return;
        }

        const srcElement = this.docContentElement.nativeElement.parentNode;
        const scrollTop = srcElement.scrollTop - 30; // width of tools = 30
        const docHeight = srcElement.offsetHeight;

        //IE compatible way of looping through a NodeList
        let item = Array.prototype.find.call(this.edocTitleElements, function (item) {
            return item.offsetTop > scrollTop && item.offsetTop < scrollTop + docHeight;
        });

        if (!item) {
            // no edocTitle in the viewport => find closest one to the top
            const itemTooFarIndex = Array.prototype.findIndex.call(this.edocTitleElements, function (item) {
                return item.offsetTop > scrollTop;
            });
            item = itemTooFarIndex === -1 ? this.edocTitleElements[this.edocTitleElements.length - 1] : this.edocTitleElements[itemTooFarIndex - 1];
        }

        if (item && item.name && this.tabArea.currentAssetTab.currentEdocTitleElement !== item.name) {
            this.assetService.dispatch(makeAction({
                type: AssetActions.set_current_edoc_title.name, payload: { edocTitle: item.name, inPrimary: this.tabArea.isPrimary },
            }));
        }
    }

    ngOnInit(): void {
        this.subscription
            .add(this.assetService.collectionTab.subscribe(ct => {
                if (ct === 'toc' && !this.scrollListener) {
                    debug('added listeners');
                    this.clickListener = this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => this.checkTitleElements());
                    this.scrollListener = this.renderer.listen(this.elementRef.nativeElement, 'scroll', (event) => this.onScroll(event));
                    this.checkTitleElements();
                }
                else if (ct !== 'toc' && this.scrollListener) {
                    debug('removed listeners');
                    this.scrollListener();
                    this.clickListener();
                    this.scrollListener = null;
                    this.clickListener = null;
                }
            }))
            .add(this.assetService.activeAssetTab.subscribe(activeTab => { this.activeTab = activeTab; }))
            .add(this.assetService.getAsset(this.assetTab.assetRef).subscribe(asset => {
                this.docContent = asset && asset.content ? asset.content as DocumentContent : null;
                this.docNavigation = this.docContent && this.docContent.navigationInfo && (!isEmtpyGuid(this.docContent.navigationInfo.followingAssetID) || !isEmtpyGuid(this.docContent.navigationInfo.precedingAssetID));
                if (this.tabArea.isPrimary) {
                    this.setActiveTocTap();
                }
                this.changeDetectorRef.detectChanges();
            }));

    }

    ngOnChanges(): void {
        this.newerComment = !!this.assetTab.newerComment;
    }

    ngOnDestroy(): void {
        if (this.scrollListener) {
            debug('destroyed => remove listeners');
            this.scrollListener();
            this.clickListener();
        }
        this.subscription.unsubscribe();
    }
}
