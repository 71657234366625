import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { FieldService } from './field.service';

@Component({
    selector: 'slx-markdown',
    template: `<div *ngIf="htmlContent" [innerHTML]="htmlContent | safeHtml" class="static-content"></div>`,
    styles: [':host { display: block; }'],
})
export class MarkdownComponent implements OnInit, OnChanges, OnDestroy {
    @Input() mdfilename: string;
    @Input() currentLanguage: string;
    @Input() foldername: string;

    @Output() noMdFound: EventEmitter<boolean> = new EventEmitter();

    private subscription: Subscription;
    public htmlContent: string;

    constructor(private fieldService: FieldService, private changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit() {}

    ngOnChanges() {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }

        this.subscription = this.fieldService.getMarkdown(this.mdfilename, this.currentLanguage, this.foldername)
            .subscribe(result => {
                if (!result) {
                    this.noMdFound.emit(true);
                } else {
                    this.htmlContent = result;
                    this.noMdFound.emit(false);
                    this.changeDetectorRef.markForCheck();
                }
            }, error => {
                this.noMdFound.emit(true);
            });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
