import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import {
    activateOrDeactivateDepartment, activateOrDeactivateUser, customerProfileDataUrl, departmentsDataUrl, exportUsersDataToExcelUrl,
    getAddNewDepartmentDataUrl, getTips, getUserPreferencesUrl, getUserTipsViewed, getWelcome, LoginService,
    portalUserDataUrl, postAddNewDepartmentDataUrl, postAddNewUserDataUrl, postChangePasswordDataUrl, postCustomerProfileDataUrl, postDepartmentDataUrl,
    postSearchTransactionsStatistics, postSendEmailPasswordForgot, postUpdateUserPreferencesUrl, postUpdateUserTipsUrl, postUserProfileDataUrl, sendAllUserLogin, sendUserLogin, SlxHttp, usersDataUrl
} from '../access';
import { AccountService } from '../account';
import { AppActions } from '../appActions';
import { catchErrorForAction, getResultNameFromAction, makeAction, SlxAction } from '../models';
import { AlertType } from '../models/state/commonApp';

import { CustomerProfileActions } from './customerProfileActions';

@Injectable()
export class CustomerProfileEffects {

    @Effect() changePassword = this.actions$.pipe(
        ofType(CustomerProfileActions.change_password.name),
        mergeMap((action: SlxAction) =>
            this.slxHttp.post(postChangePasswordDataUrl, action.payload, false).pipe(
                map(payload => makeAction({
                    result: CustomerProfileActions.change_password.name, payload,
                    infoAlert: { key: payload.translationKey || 'account-successful' },
                })),
                catchErrorForAction(action, { type: AlertType.Warning, alertKey: null })
            ))
    );

    @Effect() emailForgot = this.actions$.pipe(
        ofType(CustomerProfileActions.save_send_email_password_forgot.name),
        mergeMap((action: SlxAction) =>
            this.slxHttp.post(postSendEmailPasswordForgot, action.payload, false).pipe(
                map(payload => makeAction({ result: CustomerProfileActions.save_send_email_password_forgot.name, payload, infoAlert: { key: payload.translationKey } })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'home-send-mail-error' })
            )
        )
    );

    @Effect() loadUserProfile = this.accountGetRequest(CustomerProfileActions.load_user_profile_data.name, () => `${portalUserDataUrl}?language=${this.accountService.langEnum}`);

    @Effect() postUserProfile = this.actions$.pipe(
        ofType(CustomerProfileActions.save_user_profile.name),
        withLatestFrom(this.loginService.userId),
        mergeMap(([action, userId]) => this.slxHttp.post(`${postUserProfileDataUrl}?language=${this.accountService.langEnum}`, (action as SlxAction).payload).pipe(
            map(payload => {
                const originalPayload = (action as SlxAction).payload;
                if (userId === originalPayload.id) {
                    this.translate.use(originalPayload.language.toLowerCase());
                }
                return makeAction({ result: CustomerProfileActions.save_user_profile.name, payload: originalPayload });
            }),
            catchErrorForAction(action, { type: AlertType.Error, alertKey: null }))
        )
    );

    @Effect() addDepartment = this.actions$.pipe(
        ofType(CustomerProfileActions.save_add_department.name),
        withLatestFrom(this.accountService.accountData),
        mergeMap(
            ([action, state]) => this.slxHttp.post(postAddNewDepartmentDataUrl, (action as SlxAction).payload).pipe(
                map(payload => makeAction({ result: CustomerProfileActions.save_add_department.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' }))
        )
    );

    // save_add_department
    @Effect() userActivation = this.actions$.pipe(
        ofType(CustomerProfileActions.activate_or_deactivate_user.name),
        mergeMap(
            (action: SlxAction) => this.slxHttp.post(activateOrDeactivateUser, action.payload)
                .pipe(
                    map(payload => makeAction({ result: CustomerProfileActions.user_data_change.name, payload, alert: { key: 'account-successful', duration: 5 } })),
                    catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' }))
        )
    );

    @Effect() userLogin = this.accountPostRequest(CustomerProfileActions.send_login_user.name, action => sendUserLogin, payload => ({ key: payload.translationKey }));

    @Effect() allLogin = this.accountGetRequest(CustomerProfileActions.send_all_login_user.name, action => `${sendAllUserLogin}${action.payload}`, payload => ({ key: payload.translationKey, value: payload.value }));

    @Effect() departmentActivation = this.actions$.pipe(
        ofType(CustomerProfileActions.activate_or_deactivate_department.name),
        mergeMap(
            (action: SlxAction) => this.slxHttp.post(activateOrDeactivateDepartment, action.payload)
                .pipe(
                    map(payload => makeAction({ result: CustomerProfileActions.activate_or_deactivate_department.name, payload, alert: { key: 'account-successful', duration: 10 } })),
                    catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' }))
        )
    );

    @Effect() loadAddUser = this.accountPostRequest(CustomerProfileActions.add_user.name, action => postAddNewUserDataUrl);

    @Effect() loadProfile = this.accountGetRequest(CustomerProfileActions.load_customer_profile.name, () => customerProfileDataUrl);

    @Effect() postCustomerProfile = this.actions$.pipe(
        ofType(CustomerProfileActions.save_customer_profile.name),
        withLatestFrom(this.accountService.customerProfile),
        mergeMap(
            ([action, state]) => this.slxHttp.post(postCustomerProfileDataUrl, state.customerProfileData)
                .pipe(
                    map(payload => makeAction({ result: CustomerProfileActions.save_customer_profile.name, payload })),
                    catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' }))
        )
    );

    @Effect() loadPreferences = this.accountRequest(AppActions.load_profile_prefs.name,
        (http: SlxHttp) => http.get(getUserPreferencesUrl),
        (type, payload) => {
            let alert = null;
            const ua = navigator.userAgent;
            const matchIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
            if (!payload.hideIEWarning && matchIE) {
                const externUrl = 'https://browser-update.org/' + this.translate.getBrowserLang().toLowerCase() + '/update.html?force_outdated=true';

                alert = {
                    type: AlertType.Warning, text: 'old-browser-dectected', duration: 20,
                    actions: [
                        {
                            injectX: externUrl,
                            externUrlKey: 'old-browser-more-info',
                        },
                        {
                            type: AppActions.save_profile_prefs.name,
                            linkKey: 'rech-asset-save-fav-show-dial',
                            payload: { hideIEWarning: true },
                        }],
                };
            }
            return makeAction({ result: type, payload, alert });
        }
    );

    @Effect() postPreferences = this.actions$.pipe(
        ofType(AppActions.save_profile_prefs.name),
        withLatestFrom(this.accountService.profilePrefs),
        mergeMap(
            ([action, currentPrefs]) => {
                const prefs = { ...currentPrefs, ...(action as SlxAction).payload };
                return this.slxHttp.post(postUpdateUserPreferencesUrl, prefs)
                    .pipe(
                        map(payload => makeAction({ result: AppActions.save_profile_prefs.name, payload })),
                        catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' }));
            }
        )
    );

    @Effect() loadUserTipsViewed = this.actions$.pipe(
        ofType(AppActions.load_user_tips.name),
        switchMap((action) =>
            this.slxHttp.get(getUserTipsViewed).pipe(
                map(payload => makeAction({ result: AppActions.load_user_tips.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-tips-error' })
            )
        )
    );

    @Effect() loadTips = this.actions$.pipe(
        ofType(AppActions.load_tips.name),
        switchMap((action) =>
            this.slxHttp.get(`${getTips}${this.accountService.langEnum}`, false).pipe(
                map(payload => makeAction({ result: AppActions.load_tips.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-tips-error' })
            )
        )
    );

    @Effect() loadWelcome = this.actions$.pipe(
        ofType(AppActions.load_welcome.name),
        switchMap((action) =>
            this.slxHttp.get(`${getWelcome}${this.accountService.langEnum}`, false).pipe(
                map(payload => makeAction({ result: AppActions.load_welcome.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-tips-error' })
            )
        )
    );

    @Effect() postTipsUser = this.actions$.pipe(
        ofType(AppActions.save_user_tips.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.post(postUpdateUserTipsUrl, action.payload).pipe(
                map(payload => makeAction({ result: AppActions.save_user_tips.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-tips-error' })
            )
        )
    );

    @Effect() moveToPrimaryAssets = this.actions$.pipe(
        ofType(getResultNameFromAction(AppActions.save_profile_prefs)),
        filter(action => !(action as SlxAction).payload.isParallelDocView),
        map(action => ({ type: AppActions.move_secondary_to_primary_assets.name }))
    );

    //this.searchService.dropdownSearch(`${euTreatyDropdownBaseUrl}${this.language}`).subscribe(res => {

    @Effect() loadDepartments = this.accountGetRequest(CustomerProfileActions.load_departments.name, action => `${departmentsDataUrl}${this.accountService.langEnum}`);

    @Effect() loadAddNewDepartment = this.accountGetRequest(CustomerProfileActions.load_add_department.name, action => getAddNewDepartmentDataUrl);

    @Effect() loadUsers = this.accountGetRequest(CustomerProfileActions.users_data.name, action => `${usersDataUrl}${this.accountService.langEnum}`);

    @Effect() trxStatistics = this.actions$.pipe(
        ofType(CustomerProfileActions.trx_statistics.name),
        mergeMap((action: SlxAction) => this.slxHttp.post(`${postSearchTransactionsStatistics}${this.translate.currentLang}`, action.payload)
            .pipe(
                map(payload => {
                    this.accountService.transactionData.next(payload);
                    return makeAction({ result: CustomerProfileActions.trx_statistics.name });
                }),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' }))
        )
    );

    @Effect() saveExcel = this.actions$.pipe(
        ofType(CustomerProfileActions.export_user_excel.name),
        mergeMap(action => this.slxHttp.getExcel(`${exportUsersDataToExcelUrl}${this.accountService.langEnum}`).pipe(
            map((payload) => {
                const blob = new Blob([payload], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver.saveAs(blob, 'UserExport.xlsx');
                return makeAction({ result: CustomerProfileActions.export_user_excel.name, alert: { key: 'account-excel-export-successful', duration: 10 } });
            }),
            catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' }))
        )
    );

    @Effect() postDepartment = this.actions$.pipe(
        ofType(CustomerProfileActions.save_single_department_data.name),
        mergeMap((action: SlxAction) => this.slxHttp.post(postDepartmentDataUrl, action.payload)
            .pipe(
                map(payload => makeAction({ result: CustomerProfileActions.save_single_department_data.name, payload })),
                catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' }))
        )
    );

    accountRequest(type: string, req: (http: SlxHttp, action: SlxAction) => Observable<any>, mapper = (type, payload) => makeAction({ result: type, payload })) {
        return this.actions$.pipe(
            ofType(type),
            mergeMap(
                action => req(this.slxHttp, action)
                    .pipe(
                        map(payload => mapper.call(this, type, payload)),
                        catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' }))
            )
        );
    }

    accountGetRequest(type: string, url: (action: SlxAction) => string, infoAlert = payload => undefined) {
        return this.actions$.pipe(
            ofType(type),
            withLatestFrom(this.accountService.store),
            mergeMap(
                ([action, state]) => this.slxHttp.get(url(action))
                    .pipe(
                        map(payload => makeAction({ result: type, payload, infoAlert: infoAlert(payload) })),
                        catchErrorForAction(action, { type: AlertType.Error, alertKey: 'account-undefined-error' }))
            )
        );
    }

    accountPostRequest(type: string, url: (action: any) => string, infoAlert = payload => undefined) {
        return this.actions$.pipe(
            ofType(type),
            mergeMap(
                (action: SlxAction) => this.slxHttp.post(url(action), action.payload)
                    .pipe(
                        map(payload => makeAction({ result: type, payload, infoAlert: infoAlert(payload) })),
                        catchErrorForAction(action, { type: AlertType.Error, alertKey: null }))
            )
        );
    }

    constructor(private actions$: Actions, private slxHttp: SlxHttp, private accountService: AccountService, private translate: TranslateService, private loginService: LoginService) {
    }
}
