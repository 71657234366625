import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AssetList, AssetListGeneric } from '../../../models';

@Component({
    selector: 'slx-asset-list',
    templateUrl: 'asset-list.component.html',
    styleUrls: ['asset-list.component.scss'],
})
export class AssetListComponent<T> {
    @Input() assetList: AssetListGeneric<T>[];
    @Output() entryClicked = new EventEmitter();

    public tooltipDelay = 150;

    public onEntryClicked(entry: T,$event: MouseEvent) {
        this.entryClicked.emit({ entry, $event });
    }

    public toggleItem(entry: AssetListGeneric<T>): void {
        entry.toggled = !entry.toggled;
        entry.maxHeight = entry.toggled ? entry.listEntries.length * 26 : 0;
    }

}
