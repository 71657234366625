import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AssetService } from '../../../asset';
import { HomeService } from '../../../home/home.service';
import { AssetDisplayType, AssetRef, SourceDetail, TooltipData } from '../../../models';

@Component({
    selector: 'slx-top-docs-tile',
    templateUrl: './topDocsTile.component.html',
    styleUrls: ['./topDocsTile.component.scss'],
})
export class TopDocsTileComponent implements OnInit {


    constructor(public homeService: HomeService, public assetService: AssetService) { }

    ngOnInit() {
        this.homeService.getTopDocuments();
    }

    openDoc(doc, event: MouseEvent) {
        this.assetService.openAsset(AssetRef.create(AssetDisplayType[doc.type as string], doc.id, { source: SourceDetail.DocumentTopLink }), true, event);
    }

    createTooltipData(doc): Observable<TooltipData> {
        return of({tooltipTitle: doc.title, tooltipBody: doc.description});
    }

}
