import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { allJobsUrl, currentJobUrl, SlxHttp, topJobsUrl } from '../access';
import { catchErrorForAction, makeAction, SlxAction } from '../models';
import { AlertType } from '../models/state/commonApp';

import { JobsActions } from './jobsActions';

@Injectable()
export class JobsEffects {
    constructor(private actions$: Actions, private slxHttp: SlxHttp) {}

    @Effect() loadCurrentJob = this.actions$.pipe(
        ofType(JobsActions.load_current_job.name),
        switchMap((action: SlxAction) =>
            this.slxHttp.get(`${currentJobUrl}${action.payload}`, false).pipe(
                map(payload => makeAction({ result: JobsActions.load_current_job.name, payload })),
                catchErrorForAction(action, {type: AlertType.Error, alertKey: 'jobs-undefined-error'})
            )
        )
    );

    @Effect() loadJobs = this.actions$.pipe(
        ofType(JobsActions.load_jobs.name),
        switchMap((action) =>
            this.slxHttp.get(`${allJobsUrl}`, false).pipe(
                map(payload => makeAction({ result: JobsActions.load_jobs.name, payload })),
                catchErrorForAction(action, {type: AlertType.Error, alertKey: 'jobs-undefined-error'})
            )
        )
    );

    @Effect() loadTopJobs = this.actions$.pipe(
        ofType(JobsActions.load_top_jobs.name),
        switchMap((action) =>
            this.slxHttp.get(`${topJobsUrl}`, false).pipe(
                map(payload => makeAction({ result: JobsActions.load_top_jobs.name, payload })),
                catchErrorForAction(action, {type: AlertType.Error, alertKey: 'jobs-undefined-error'})
            )
        )
    );
}
