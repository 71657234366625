import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/internal/operators/first';

import { AppActions } from '../../../../appActions';
import { checkboxRequiredValidator, getValidationMessage, requiredValidator } from '../../../../field';
import { ActionState, AlertType } from '../../../../models/state/commonApp';
import { markFormAsTouched } from '../../../../utility/utilityFunctions';
import { AboRegistrationService } from '../../abo-services.service';
import { AboServicesActions } from '../../abo-servicesActions';

@Component({
    selector: 'slx-registration-rak',
    templateUrl: './rak-subscription.component.html',
    styleUrls: ['./rak-subscription.component.scss'],
})
export class RakSubscriptionComponent implements OnInit, OnDestroy {
    private paypal: any;
    private paramsSubscription: Subscription;
    private subscription: Subscription;
    public getValidationMessage: (formControl: any, translate: any) => string = getValidationMessage;

    public rakActivationForm: FormGroup;

    constructor(private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, public aboRegistrationService: AboRegistrationService, public translate: TranslateService) {

        this.rakActivationForm = this.formBuilder.group({
            loginName: ['', requiredValidator()],
            password: ['', requiredValidator()],
            rakActivationCode: ['', requiredValidator()],
            agb: ['', checkboxRequiredValidator('service-reg-agb-notvalid-val-txt')],
        });
    }

    ngOnInit() {

        this.paramsSubscription = this.route.queryParams.subscribe(params => {
            this.paypal = params['paypal'];
            if (this.paypal === 'successful') {
                this.aboRegistrationService.dispatch({ type: AppActions.alert.name, payload: { type: AlertType.Success, key: 'service-reg-rak-paypal-pay-successful', duration: 10 } });
            }
            if (this.paypal === 'unsuccessful') {
                this.aboRegistrationService.dispatch({ type: AppActions.alert.name, payload: { type: AlertType.Error, key: 'service-reg-rak-paypal-pay-unsuccessful', duration: 10 } });
            }
        });
    }

    public addNewRakUser() {
        this.router.navigate(['service/lawexamcandidates/add-new-rak-user']);
    }

    public resetForm() {
        this.rakActivationForm.reset();
        Object.keys(this.rakActivationForm.controls).forEach(
            (key) => {
                // Set errors to null to delete all erros/hints, then let control update itself (check for required etc.)
                this.rakActivationForm.controls[key].setErrors(null);
                this.rakActivationForm.controls[key].updateValueAndValidity();
            });
    }

    public onSubmit(rakActivationForm) {
        if (!rakActivationForm.valid) {
            markFormAsTouched(rakActivationForm);
            return;
        }

        const rakActivation = {
            rakActivationCode: rakActivationForm.value.rakActivationCode,
            agb: rakActivationForm.value.agb,
            portalUser: {
                loginName: rakActivationForm.value.loginName,
                password: rakActivationForm.value.password,
            },
        };
        this.aboRegistrationService.dispatch({ save: AboServicesActions.activate_rak_user.name, payload: rakActivation });

        this.subscription = this.aboRegistrationService.stateOfAction(AboServicesActions.activate_rak_user.name)
            .pipe(first(res => res === ActionState.Success))
            .subscribe(() => {
                setTimeout(() => {
                    this.router.navigate(['service/lawexamcandidates/rak-paypal']);
                    this.subscription.unsubscribe();
                }, 1000);
            });
    }

    ngOnDestroy() {
        this.paramsSubscription.unsubscribe();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}



