
import {
    ActiveTocTab, Asset, AssetDisplayType, AssetRef,
    AssetTab, CommentaryForLawPayload, defaultPublicationCriteria, defaultRechercheState, DocScrollInfo, DocScrollType, enhanceActionsObject, filterExistingAssetsFn,
    Hitlist, HitlistPaging, markHitlistInProgress, modifiedTabState, RechercheState, RelatedAssetsModel, SearchType
} from '../models';
import { isEmtpyGuid } from '../utility/utilityFunctions';

function replaceAssetInTabs(existingAssetTabs: Array<AssetTab>, existingInProgress: Array<AssetTab>, existingCurrentAssetTab: AssetTab, asset: Asset, scrollInfo: DocScrollInfo) {
    let currentAssetTab = existingCurrentAssetTab;
    const assetTabs = existingAssetTabs.map(entry => {
        if (entry.assetID === asset.id) {
            return (currentAssetTab = {
                ...entry,
                assetID: asset.id || entry.assetID,
                ...AssetTab.create(asset.ref, entry.favorite, asset),
                isLoading: false,
                manualReload: false,
                scrollInfo: scrollInfo || entry.scrollInfo,
            });
        }
        return entry;
    });
    const inProgress = existingInProgress.filter(entry => entry.assetID !== asset.id);

    return { assetTabs, currentAssetTab, inProgress };
}

// change current asset tab based on assetID/index
function modifiedCurrentTab(openInPrimary, state, assetID, tabIndex) {
    return modifiedTabState(openInPrimary, state, (tabState) => {
        const currentAssetTab = assetID ? tabState.assetTabs.find((tab) => tab.assetID && tab.assetID === assetID) : tabState.assetTabs[tabIndex];
        return [{
            ...tabState,
            currentAssetTab,
            detailsShown: false,
        }, null/* other not changed */];
    });
}

function modifiedAssetsInBothStrips(state, asset, scrollInfo) {
    return modifiedTabState(true, state, (tabState, otherState) => {
        return [{
            ...tabState,
            ...replaceAssetInTabs(tabState.assetTabs, tabState.inProgress, tabState.currentAssetTab, asset, scrollInfo),
        }, {
            ...otherState,
            ...replaceAssetInTabs(otherState.assetTabs, otherState.inProgress, otherState.currentAssetTab, asset, scrollInfo),
        }];
    });
}

export const AssetActions = {

    save_current_asset_tab_state(state: RechercheState) {
        return {
            ...state,
            secondaryAssetsBackup: state.secondaryTabState.assetTabs.map(tab => tab.assetID),
        };
    },

    set_active_tab_for_toc(state: RechercheState, { payload }: { payload: ActiveTocTab }) {
        return {
            ...state,
            activeTabForToc: payload,
        };
    },

    set_sidebar_toc_ref(state: RechercheState, { payload }: { payload: AssetRef }) {
        return {
            ...state,
            sidebarTocRef: [...payload],
        };
    },

    restore_asaset_tab_state(state: RechercheState) {
        const primaryAssetTabs = state.primaryTabState.assetTabs.filter(tab => !state.secondaryAssetsBackup.find(backup => backup === tab.assetID));
        const secondaryAssetTabs = state.primaryTabState.assetTabs.filter(tab => state.secondaryAssetsBackup.find(backup => backup === tab.assetID));
        return state.secondaryAssetsBackup.length ? {
            ...state,
            primaryTabState: {
                ...state.primaryTabState,
                assetTabs: primaryAssetTabs,
                currentAssetTab: primaryAssetTabs.find(primary => primary.assetID === state.primaryTabState.currentAssetTab.assetID) ? state.primaryTabState.currentAssetTab : primaryAssetTabs[0],
            },
            secondaryTabState: {
                ...state.secondaryTabState,
                assetTabs: secondaryAssetTabs,
                currentAssetTab: secondaryAssetTabs[0],
            },
            secondaryAssetsBackup: [],
        } : state;
    },

    clear_assets(state: RechercheState, { payload }) {
        const { primary, secondary } = payload;
        return {
            ...state,
            ...modifiedTabState(true, state, (primaryTabState, secondaryTabState) => {
                return [
                    primary ? defaultRechercheState.primaryTabState : primaryTabState,
                    secondary ? defaultRechercheState.secondaryTabState : secondaryTabState,
                ];
            }),
        };
    },

    clear_recherche_results(state: RechercheState) {

        const defaultSearchForms = {};

        for (const searchType in SearchType) {
            if (!Number(searchType) && searchType !== '0') {
                defaultSearchForms[searchType] = {};
            }
        }

        return {
            ...state,
            hitlist: defaultRechercheState.hitlist,
            paging: defaultRechercheState.paging,
            selectedSearchFilter: null,
            selectedPublications: { ...defaultPublicationCriteria },
            primaryTabState: defaultRechercheState.primaryTabState,
            secondaryTabState: defaultRechercheState.secondaryTabState,
            collectionTab: null,
            searchCollapsed: false,
            activeTabForToc: ActiveTocTab.None,
            transactionID: 0,
            searchForms: defaultSearchForms,
            search: {},
        };
    },

    handle_legacy_law_documents(state: RechercheState) {
        return state;
    },

    load_eu_doc_based_on_celex_nr(state: RechercheState) {
        return state;
    },

    load_asset(state: RechercheState, { payload, result }) {
        if (result) {
            const ref = payload.ref;

            const assetInProgress = state.assetInProgress.filter(r => (r[0] !== ref[0] || r[1] !== ref[1]));

            const asset: Asset = payload.asset;
            const scrollInfo = asset.citationAnchor ? { scrollType: DocScrollType.CitationAnchor, value: asset.citationAnchor } : AssetRef.determineScrollInfo(ref);
            const modifiedState = modifiedAssetsInBothStrips(state, asset, scrollInfo);

            return {
                ...state,
                searchCollapsed: true,
                euTocCollapsed: true,
                assetInProgress,
                error: null,
                hitlist: markHitlistInProgress(state.hitlist, assetInProgress),
                ...modifiedState,
            };
        } else {
            const assetInProgress = state.assetInProgress.concat([payload.ref]).filter(filterExistingAssetsFn(state.primaryTabState, state.secondaryTabState));
            return {
                ...state,
                assetInProgress,
                hitlist: markHitlistInProgress(state.hitlist, assetInProgress),
                error: null,
            };
        }
    },

    // DO NOT RENAME THIS ACTION => only works because we automatically attach "_error" to a action
    load_asset_error(state: RechercheState, { payload }) {
        const ref = payload.ref;
        const assetInProgress = state.assetInProgress.filter(r => (r[0] !== ref[0] || r[1] !== ref[1]));
        return {
            ...closeAssetTab(state, AssetRef.toAssetID(ref), payload.isPrimary),
            assetInProgress,
            hitlist: markHitlistInProgress(state.hitlist, assetInProgress),
        };
    },

    remove_oldest_document(state: RechercheState) {
        const oldestPrimaryAsset = getOldestAsset(state.primaryTabState.assetTabs);
        const oldestSecondaryAsset = getOldestAsset(state.secondaryTabState.assetTabs);
        const isSecondary = oldestSecondaryAsset && oldestSecondaryAsset.loadedTime < oldestPrimaryAsset.loadedTime;
        const oldestAsset = isSecondary ? oldestSecondaryAsset : oldestPrimaryAsset;

        return {
            ...state,
            ...closeAssetTab(state, oldestAsset.assetID, !isSecondary),
        };
    },

    get_citation_information(state: RechercheState, { payload, result }) {
        return result ? {
            ...state,
            citationInformation: {
                ...state.citationInformation,
                [payload.assetID]: payload.citationInformation,
            },
        } : state;
    },

    set_hitlist(state: RechercheState, { payload }: { payload: Hitlist }) {
        const hitlistOpen = !!payload, filterOpen = !!payload;
        return {
            ...state,
            paging: new HitlistPaging(payload.numberOfHits, 1, payload.numberOfHits), // has no paging as this is for now only used for setting from NEWS
            hitlistOpen,
            filterOpen,
            assetTypeFacet: [],
            languageFacet: [],
            area: '',
            hitlist: payload,
        };
    },

    update_asset_law(state: RechercheState, { }) {
        return {
            ...state,
            primaryTabState: { ...state.primaryTabState, detailsShown: false },
            secondaryTabState: { ...state.secondaryTabState, detailsShown: false },
        };
    },

    update_asset_from_url(state: RechercheState, { payload: { tabIndex, assetID, openInPrimary } }) {
        //TODO phase out tabIndex

        return {
            ...state,
            searchCollapsed: true,
            ...modifiedCurrentTab(openInPrimary, state, assetID, tabIndex),
        };
    },

    load_attachment_error(state: RechercheState, { payload: { isPrimary, fileID } }) {
        return {
            ...state,
            ...modifiedTabState(isPrimary, state, (tabState) => {
                const attachmentsLoading = { ...tabState.attachmentsLoading };
                delete attachmentsLoading[fileID];
                return [{
                    ...tabState,
                    attachmentsLoading,
                }];
            }),
        };
    },

    clear_newer_comment(state: RechercheState, { payload: { isPrimary, assetID} } : { payload: { isPrimary: boolean, assetID: string}} ) {
        return {
            ...state,
            ...modifiedTabState(isPrimary, state, (tabState) =>
                [
                    {
                        ...tabState,
                        assetTabs: tabState.assetTabs.map(tab => {
                            if (tab.assetID !== assetID) {
                                return tab;
                            }
                            return { ...tab, newerComment: null };
                        }),
                        currentAssetTab: tabState.currentAssetTab.assetID === assetID ?
                            { ...tabState.currentAssetTab, newerComment: null } : tabState.currentAssetTab,
                    }, null]
            ),
        };
    },

    load_commentaries_for_law(state: RechercheState, { payload, result }: { payload: CommentaryForLawPayload, result: any }) {
        if (result) {
            const commentariesForLaw = payload.commentariesForLaw;
            const newerComment = payload.newerComment;
            return {
                ...state,
                ...modifiedTabState(payload.isPrimary, state, (tabState) =>
                    [
                        {
                            ...tabState,
                            assetTabs: tabState.assetTabs.map(tab => {
                                if (tab.assetID !== payload.commentariesForLaw.assetID) {
                                    return tab;
                                }
                                return { ...tab, commentariesForLaw, newerComment };
                            }),
                            currentAssetTab: tabState.currentAssetTab && tabState.currentAssetTab.assetID === payload.commentariesForLaw.assetID ?
                                { ...tabState.currentAssetTab, commentariesForLaw, newerComment } : tabState.currentAssetTab,
                        }, null]
                ),
            };
        }
        return state;
    },

    load_citing(state: RechercheState, { payload, result }) {
        if (result) {
            const assetCitedIn = payload.assetCiting as RelatedAssetsModel;
            const isPrimary = payload.isPrimary;

            return {
                ...state,
                ...modifiedTabState(isPrimary, state, (tabState) =>
                    [
                        {
                            ...tabState,
                            assetTabs: tabState.assetTabs.map(tab => {
                                if (tab.assetID !== assetCitedIn.assetID) {
                                    return tab;
                                }
                                return { ...tab, assetCitedIn };
                            }),
                            currentAssetTab: tabState.currentAssetTab && tabState.currentAssetTab.assetID === assetCitedIn.assetID ?
                                { ...tabState.currentAssetTab, assetCitedIn } : tabState.currentAssetTab,
                        }, null]
                ),
            };
        }
        return state;
    },

    set_citing_anchor(state, { payload: { setInPrimary, anchor } }) {
        return {
            ...state,
            ...modifiedTabState(setInPrimary, state, (tabState) => {
                const currentAssetTab = { ...tabState.currentAssetTab, scrollInfo: { scrollType: DocScrollType.CitationAnchor, value: anchor } };
                return [{
                    ...tabState,
                    currentAssetTab,
                    assetTabs: tabState.assetTabs.map(tab => tab.assetID === currentAssetTab.assetID ? currentAssetTab : tab),
                }, null];
            }),
        };
    },

    remove_scroll_element(state, { payload: { removeInPrimary } }) {
        return {
            ...state,
            ...modifiedTabState(removeInPrimary, state, (tabState) => {
                const currentAssetTab = { ...tabState.currentAssetTab, scrollInfo: null, assetRef: [...tabState.currentAssetTab.assetRef].splice(0, 2) };
                return [{
                    ...tabState,
                    currentAssetTab,
                    assetTabs: tabState.assetTabs.map(tab => tab.assetID === currentAssetTab.assetID ? currentAssetTab : tab),
                }, null];
            }),
        };
    },

    set_current_edoc_title(state, { payload }) {
        return {
            ...state,
            ...modifiedTabState(payload.inPrimary, state, (tabState) => {
                return [{
                    ...tabState,
                    currentAssetTab: {
                        ...tabState.currentAssetTab,
                        currentEdocTitleElement: payload.edocTitle,
                    },
                }, null];
            }),
        };
    },


    publication_in_toc_opened(state: RechercheState, { payload }) {
        const isPrimary = payload;
        return {
            ...state,
            ...modifiedTabState(isPrimary, state, (tabState) => {
                const currentAssetTab = {
                    ...tabState.currentAssetTab, assetRef: [...tabState.currentAssetTab.assetRef].splice(0, 2),
                };
                return [{
                    ...tabState,
                    currentAssetTab,
                    assetTabs: tabState.assetTabs.map(tab => tab.assetID === currentAssetTab.assetID ? currentAssetTab : tab),
                }, null];
            }),
        };
    },

    load_toc_items(state: RechercheState, { payload: { items, itemId, targetId, isPrimary }, result }) {
        return result ? {
            ...state,
            tocItems: isEmtpyGuid(targetId) ? { ...state.tocItems } : { ...state.tocItems, [targetId]: { isOpenInPrimary: isPrimary, isOpenInSecondary: !isPrimary } },
            loadedTocItem: { items, itemId },
        } : state;
    },

    toggle_toc_items(state: RechercheState, { payload: { itemId, isOpen, isPrimary } }) {
        const tocItem = isPrimary ? { ...state.tocItems[itemId], isOpenInPrimary: isOpen } : { ...state.tocItems[itemId], isOpenInSecondary: isOpen };

        return {
            ...state,
            tocItems: { ...state.tocItems, [itemId]: tocItem },
        };
    },

    toc_closed(state: RechercheState, { payload }) {
        const isPrimary = payload;
        const tocItems = {};
        Object.keys(state.tocItems).forEach(key => {
            if (isPrimary) {
                tocItems[key] = { ...state.tocItems[key], isOpenInPrimary: false };
            }
            else {
                tocItems[key] = { ...state.tocItems[key], isOpenInSecondary: false };
            }
        });
        return {
            ...state,
            tocItems,
        };
    },

    asset_error(state, { payload }) {
        return {
            ...state,
            assetInProgress: [], //TODO remove the one in progress
            error: payload,
        };
    },

    save_doc_pdf(state: RechercheState, { payload, result }) {
        if (result) {
            return {
                ...state,
                ...modifiedTabState(payload.isPrimary, state, (tabState) => {
                    return [{
                        ...tabState,
                        pdfIsLoading: false,
                    }];
                }),
            };
        } else {
            return {
                ...state,
                ...modifiedTabState(payload.isPrimary, state, (tabState) => {
                    return [{
                        ...tabState,
                        pdfIsLoading: true,
                    }];
                }),
            };
        }
    },

    doc_pdf_error(state, { payload }) {
        return {
            ...state,
            ...modifiedTabState(payload.isPrimary, state, (tabState) => {
                return [{
                    ...tabState,
                    pdfIsLoading: false,
                }];
            }),
            error: payload,
        };
    },

    save_doc_attachment(state: RechercheState, { payload: { isPrimary, fileID }, result }) {
        if (result) {
            return {
                ...state,
                ...modifiedTabState(isPrimary, state, (tabState) => {
                    const attachmentsLoading = { ...tabState.attachmentsLoading };
                    if (fileID) {
                        delete attachmentsLoading[fileID];
                    }
                    return [{
                        ...tabState,
                        attachmentsLoading,
                    }];
                }),
            };
        } else {
            return {
                ...state,
                ...modifiedTabState(isPrimary, state, (tabState) => {
                    return [{
                        ...tabState,
                        attachmentsLoading: fileID ? { ...tabState.attachmentsLoading, [fileID]: true } : { ...tabState.attachmentsLoading },
                    }];
                }),
            };
        }
    },

    toggle_docview_fontsize(state, { payload: { isPrimary, size, inSideBar } }) {

        if (inSideBar) {
            return {
                ...state,
                fontSizeInSideBar: size,
            };
        }

        return {
            ...state,
            ...modifiedTabState(isPrimary, state, (tabState) => {
                return [{
                    ...tabState,
                    fontSize: size,
                }];
            }),
        };
    },

    toggle_docviewtool(state: RechercheState, { payload: { isPrimary, toggleValue, togglePropertyName } }) {
        return {
            ...state,
            ...modifiedTabState(isPrimary, state, (tabState) => {
                return [{
                    ...tabState,
                    [togglePropertyName]: toggleValue,
                }];
            }),
        };
    },

    set_asset_split(state: RechercheState, { payload }) {
        const assetSplit = payload;
        return {
            ...state,
            assetSplit,
        };
    },

    new_asset_tab_changed(state: RechercheState, { payload: { isPrimary, ref } }) {
        const assetID = AssetRef.toAssetID(ref);
        return {
            ...state,
            ...modifiedTabState(isPrimary, state, (tabState) => {

                const tabs = tabState.assetTabs.map(tab => tab.assetID === assetID ? { ...tab, scrollInfo: AssetRef.determineScrollInfo(ref), assetRef: ref } : tab);
                return [{
                    ...tabState,
                    assetTabs: tabs,
                    currentAssetTab: tabs.find(tab => tab.assetID === assetID),
                }, null];
            }),
            searchCollapsed: true,
        };
    },

    close_tab(state: RechercheState, { payload: { isPrimary, tabIndex, assetID } }) {
        return closeAssetTab(state, assetID, isPrimary, tabIndex);
    },

    preview_assets(state: RechercheState) {
        return {
            ...state,
        };
    },

    // ##### Favorites ##### //
    toggle_favorite(state: RechercheState) {
        return {
            ...state,
        };
    },

    update_order_favorites(state: RechercheState, { payload, result }) {
        return result ? state : { ...state, favorites: payload };
    },

    update_favorite(state: RechercheState) {
        return {
            ...state,
        };
    },

    user_favorites(state: RechercheState, { payload }) {
        const favorites = payload.favorites.map(f => ({ ...f, targetType: AssetDisplayType[f.targetType] }));
        return {
            ...state,
            userFavorites: favorites,
            primaryTabState: markFavorites(state.primaryTabState, favorites),
            secondaryTabState: markFavorites(state.secondaryTabState, favorites),
        };
    },

    user_favorites_error(state: RechercheState, { }) {
        return state;
    },

    rearrange_tab(state: RechercheState, { payload: { isPrimary, insertPoint, sourceTab, sourceTabIndex, drag, intitalScrollInformation } }) {
        //  #debugger;
        sourceTab.intitalScrollInformation = intitalScrollInformation;
        const newState = {
            ...state,
            ...modifiedTabState(isPrimary, state, (tabState, otherTabState) => {
                const assetTabs = tabState.assetTabs.filter(tab => tab !== sourceTab);
                if (drag) {
                    const otherAssetTabs = otherTabState.assetTabs.concat();
                    otherAssetTabs.splice(insertPoint === -1 ? otherTabState.assetTabs.length : insertPoint, 0, sourceTab);

                    return [{
                        ...tabState,
                        assetTabs,
                        currentAssetTab: assetTabs[sourceTabIndex ? sourceTabIndex - 1 : assetTabs.length - 1],
                    }, {
                        ...otherTabState,
                        assetTabs: otherAssetTabs,
                        currentAssetTab: { ...sourceTab, intitalScrollInformation },
                    }];

                } else {
                    if (insertPoint > sourceTabIndex) {
                        assetTabs.splice(insertPoint - 1, 0, sourceTab);
                    } else {
                        assetTabs.splice(insertPoint, 0, sourceTab);
                    }

                    return [{
                        ...tabState,
                        assetTabs,
                        currentAssetTab: { ...sourceTab, intitalScrollInformation },
                    }];
                }

            }),
        };

        return {
            ...newState,
            activeTabForToc: determineActiveTabForToc(newState),
        };
    },
};
enhanceActionsObject(AssetActions);


function closeAssetTab(state: RechercheState, assetID: string, isPrimary: boolean, tabIndex = 0): RechercheState {
    let assetIdToClose;
    const newState = {
        ...state,
        ...modifiedTabState(isPrimary, state, (tabState, otherTabState) => {
            assetIdToClose = assetID ? assetID : tabState.assetTabs[tabIndex].assetID;
            const assetTabs = tabState.assetTabs.filter(tab => tab.assetID !== assetIdToClose);
            const newTabState = {
                ...tabState,
                assetTabs,
                currentAssetTab: assetIdToClose === tabState.currentAssetTab.assetID ? assetTabs[assetTabs.length - 1] : tabState.currentAssetTab,
            };
            return [newTabState, { ...otherTabState }];
        }),
    };

    const newCitationInformation = { ...newState.citationInformation };
    delete newCitationInformation[assetIdToClose];

    return {
        ...state,
        ...newState,
        activeTabForToc: determineActiveTabForToc(newState),
        citationInformation: newCitationInformation,
    };
}

function getOldestAsset(assetTabs: AssetTab[]): AssetTab {
    if (assetTabs.length === 0) {
        return null;
    }
    const oldestAsset = assetTabs.reduce((oldest, current) => current.loadedTime < oldest.loadedTime ? current : oldest);
    return oldestAsset;
}

function determineActiveTabForToc(state: RechercheState): ActiveTocTab {
    if (state.primaryTabState.currentAssetTab && state.secondaryTabState.currentAssetTab) {
        return state.activeTabForToc;
    }

    if (state.primaryTabState.currentAssetTab) {
        return ActiveTocTab.Primary;
    }

    if (state.secondaryTabState.currentAssetTab) {
        return ActiveTocTab.Secondary;
    }

    return ActiveTocTab.None;
}

function markFavorites(tabState, userFavorites) {
    return {
        ...tabState,
        currentAssetTab: markTab(tabState.currentAssetTab), // new object created, object identity comparison can no longer be used between tabs
        assetTabs: tabState.assetTabs.map(markTab),
    };

    function markTab(tab) {
        if (!tab) { return null; }
        const favorite = userFavorites.find((fav) => fav.targetID === tab.assetID);
        return {
            ...tab,
            favorite,
        };
    }
}

